@import '../../../styles/shared.scss';

.opportunity-plan-header {
  background-color: $darkest;
  padding: 64px 64px 40px;
  color: white;
  position: relative;

  @include md-down {
    padding: 32px 24px 24px;
  }

  .active-plan {
    .background-fade {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 60%;
      pointer-events: none;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      .background-gradient {
        position: absolute;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0, $darkest 100%);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
      }
    }

    .main {
      @extend %flex-row-spread;
      gap: 60px;

      @include md-down {
        flex-direction: column;
        gap: 24px;
      }

      .summary-container {
        @extend %flex-col;
        gap: 16px;
        max-width: 400px;
        z-index: 1;

        @include md-down {
          max-width: unset;
        }

        .summary-title {
          font-size: 36px;
          color: white;
          font-weight: bold;
          font-family: $font-header;
        }

        .summary {
          font-size: 16px;
          line-height: 1.4;

          // Ellipsis after 5 lines
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;

          &.showing-full-summary {
            -webkit-line-clamp: unset;
          }
        }
      }

      .stat-containers {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 48px;
        z-index: 1;

        @include sm-down {
          grid-template-columns: 1fr;
          gap: 12px;
        }

        .stat-container {
          @extend %flex-col;
          gap: 8px;
          .stat-label {
            @extend %label;
            font-size: 12px;
            font-weight: bold;

            &.clickable {
              @extend %btn-basic;
              @extend %flex-row-middle;
              gap: 4px;

              svg {
                font-size: 10px;
              }
            }
          }
          .stat-value {
            font-size: 56px;
            font-weight: bold;
            font-family: $font-header;
            white-space: nowrap;

            &.small {
              font-size: 28px;
            }
          }
        }
      }
    }
    .plan-tabs {
      @extend %flex-row-middle;
      flex-wrap: wrap;
      margin-top: 24px;
      gap: 8px;
      z-index: 1;
      position: relative;

      .plan-tab {
        padding: 12px 20px;
        border-radius: 10px;
        border: $border-transparent;
        cursor: pointer;
        transition: all 0.2s ease;
        position: relative;

        &:hover {
          border: $border-dark;
          background-color: rgba(255, 255, 255, 0.1);
          .actions {
            opacity: 1;
          }

          .remove-icon {
            opacity: 1;
            pointer-events: auto;
          }
        }

        &.active {
          border: $border-white;
          background-color: rgba(255, 255, 255, 0.2);
        }

        &.empty:not(.active) {
          color: $light;
        }

        .plan-label {
          @extend %label;
          font-size: 12px;
        }

        .title {
          font-size: 14px;
          margin-left: 4px;
        }

        .remove-icon {
          @extend %btn-basic;
          @extend %flex-center;
          position: absolute;
          top: -6px;
          right: -6px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          font-size: 10px;
          border: $border-white;
          background-color: white;
          color: $dark;
          opacity: 0;
          transition: all 0.2s ease;

          &:hover {
            color: $light;
            border: $border-dark;
          }
        }

        .actions {
          @extend %flex-center;
          position: absolute;
          top: 0;
          height: 100%;
          right: 4px;
          opacity: 0;

          svg {
            font-size: 12px;
            margin-left: 4px;
          }
        }
      }
    }
  }
}
