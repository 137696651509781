@import '../../styles/shared.scss';

.spotlight-element-wrapper {
  z-index: 10001;
  position: relative;

  .arrow {
    position: absolute;
    right: calc(100% + 4px);
    top: calc(50% - 8px);
    height: 16px;
    color: white;
    white-space: nowrap;

    svg {
      transform: scaleX(1.2);
      animation: bounce 2s infinite;
      animation-delay: 1.5s;
    }
  }
}

.spotlight-fade {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.on {
    opacity: 1;
  }

  .clear-btn {
    @extend %btn-basic;
    position: absolute;
    padding: 16px;
    top: 0;
    right: 0;

    svg {
      color: white;
      font-size: 24px;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0) scaleX(1.2);
  }
  25% {
    transform: translateX(-10px) scaleX(1.2);
  }
  50% {
    transform: translateY(0) scaleX(1.2);
  }
  65% {
    transform: translateX(-6px) scaleX(1.2);
  }
  80% {
    transform: translateY(0) scaleX(1.2);
  }
  100% {
    transform: translateX(0) scaleX(1.2);
  }
}
