@import '../../../styles/shared.scss';

.opportunity-leaderboard-controls {
  @extend %flex-row-middle;
  gap: 8px;

  @include md-down {
    flex-wrap: wrap;
  }

  .search-container {
    input {
      @extend %input-shared;
      border: $border-lighter;
      font-size: 14px;
      &:focus {
        border: $border-lighter;
      }
    }

    .search-icon {
      @extend %btn-basic;
      @extend %flex-center;
      border-radius: 50%;
      background-color: white;
      border: $border-lighter;
      color: $dark;
      width: 36px;
      height: 36px;
      font-size: 12px;
    }
  }

  .ai-toggle-container {
    @extend %flex-center;
    width: 36px;
    height: 36px;
  }

  .sort-container {
    position: relative;

    .sort-label {
      @extend %label;
      position: absolute;
      left: 0;
      bottom: 100%;
      padding-bottom: 4px;
      color: $lighter;
      font-size: 10px;

      @include md-down {
        display: none;
      }
    }

    &.message {
      .shopmy-select__control {
        min-width: 100px;
        cursor: pointer;
        box-shadow: none;
        border-color: $lighter;

        .shopmy-select__value-container {
          @extend %flex-center;
        }

        .shopmy-select__placeholder {
          @extend %label;
          color: $darkest;
          font-size: 12px;
        }
        .shopmy-select__indicators {
          display: none;
        }
      }

      .shopmy-select__menu {
        min-width: 240px;
      }
    }

    .shopmy-select__control {
      min-width: 240px;

      .shopmy-select__single-value {
        @extend %label;
        color: $primary;
        font-size: 12px;
      }
    }
  }

  .download-btn,
  .bulk-msg-btn {
    @extend %btn-white-new;
    position: relative;
    padding: 8px 20px;
    color: $darkest;
    font-size: 12px;
    height: 36px;
    border-color: $lighter;

    @include md-down {
      display: none;
    }

    &.downloading {
      border-color: $lighter;
      color: transparent;
    }

    .loader {
      @extend %flex-center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .control {
    @extend %btn-white-new;
    padding: 8px 20px;
    color: $darkest;
    font-size: 12px;
    border-color: $darkest;
  }
}
