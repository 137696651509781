@import '../../../styles/shared.scss';

.latest-section.products {
  @extend %flex-row;
  flex-wrap: wrap;
  gap: 24px;

  .product {
    @extend %flex-col-center;
    gap: 12px;
    width: 100%;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background-color: $white;
    padding: 24px;
    position: relative;
    color: $darkest;

    @include md {
      width: calc(50% - 24px / 2);
    }

    @include lg {
      flex-direction: row;
    }

    .image-container {
      max-width: 240px;
      aspect-ratio: 1;

      img {
        object-fit: contain;
        width: 100%;
      }
    }

    .data-container {
      flex: 1;

      .brand {
        @extend %label;
        color: $darker;
        margin-bottom: 6px;
        font-size: 12px;
      }

      .title {
        font-family: $font-header;
        font-size: 28px;
        font-weight: bold;
        color: $darkest;
        margin-bottom: 16px;
      }

      .subtitle {
        @extend %paragraph-sm;
        color: $dark;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &.expanded {
          -webkit-line-clamp: 30;
        }
      }

      .action-btns {
        @extend %flex-row-middle;
        gap: 12px;
        margin-top: 24px;

        .action-btn {
          @extend %btn-darkest-new;
          font-size: 13px;
          padding: 12px 24px;
        }
      }
    }
  }
}
