@import '../../../styles/shared.scss';

.home-animations-outer-container {
  background-color: $whynotusewhite;

  &.dark-mode {
    background-color: $whynotuseblack;
  }

  @include sm-down {
    padding: 40px 0 120px;
  }

  @include sm {
    padding: 40px 0 120px;
  }

  @include md {
    padding: 54px 0 120px;
  }

  @include lg {
    padding: 54px 0 120px;
  }
  @include xl {
    padding: 60px 0 120px;
  }

  @include xxl {
    padding: 60px 0 120px;
  }

  .home-animations-inner-container {
    @extend %flex-col-center;

    .main-statement {
      @extend %flex-col-center;
      padding: 0 5vw;
      transition: opacity 0.3s ease-in-out;

      &.animating {
        .badge,
        .title {
          opacity: 0;
        }

        .title-fixed {
          opacity: 1;
        }
      }

      .badge {
        @extend %label;

        @include sm-down {
          font-size: 12px;
          margin: 0 0 12px;
        }

        @include sm {
          font-size: 12px;
          margin: 0 0 12px;
        }

        @include md {
          font-size: 14px;
          margin: 0 0 12px;
        }

        @include lg {
          font-size: 14px;
          margin: 0 0 24px;
        }
        @include xl {
          font-size: 15px;
          margin: 0 0 30px;
        }

        @include xxl {
          font-size: 15px;
          margin: 0 0 30px;
        }
      }
      .title {
        font-family: $font-header;
        line-height: 1.3;
        margin: 0;
        font-weight: 300;
        text-align: center;
        transition: all 0.2s ease-in-out;

        @include sm-down {
          font-size: 32px;
        }

        @include sm {
          font-size: 40px;
        }

        @include md {
          font-size: 48px;
        }

        @include lg {
          font-size: 50px;
        }
        @include xl {
          font-size: 56px;
          max-width: 940px;
        }

        @include xxl {
          font-size: 64px;
          max-width: 1024px;
        }
      }
    }

    .sections {
      @extend %flex-col;
      width: 100%;

      @include md-down {
        padding: 60px 0 0px; // ALLEY_LEFT
      }

      @include md {
        padding: 60px 20px 120px; // ALLEY_LEFT
      }

      @include lg {
        padding: 60px 24px 120px; // ALLEY_LEFT
      }
      @include xl {
        padding: 60px 40px 120px; // ALLEY_LEFT
      }

      @include xxl {
        padding: 60px 60px 120px; // ALLEY_LEFT
      }

      &.hide-numbers {
        @include md {
          margin-top: -160px; // Since we don't have a header or numbers
        }
      }

      .section {
        @extend %flex-row;
        margin: 0 auto;

        @include md-down {
          flex-direction: column;
        }
        &:first-child {
          .panel.left .video-container.not-fixed {
            background-color: black;
          }
          .panel.left {
            @include md-down {
              padding-top: 40px;
            }

            @include md {
              padding-top: 100px;
            }

            @include lg {
              padding-top: 180px;
            }
            @include xl {
              padding-top: 240px;
            }

            @include xxl {
              padding-top: 300px;
            }
          }
          .panel.right {
            @include md-down {
              padding-top: 20px;
            }

            @include md {
              padding-top: 200px;
            }

            @include lg {
              padding-top: 280px;
            }
            @include xl {
              padding-top: 400px;
            }

            @include xxl {
              padding-top: 480px;
            }
          }
        }

        &:last-child {
          .panel.left .video-container.not-fixed {
            background-color: black;
          }
          .panel.right {
            @include md {
              padding-bottom: 200px;
            }
          }
        }

        .panel {
          @extend %flex-col;

          @include sm-down {
            width: calc(100vw - 20px * 2); // ALLEY_LEFT
            min-width: calc(100vw - 20px * 2); // ALLEY_LEFT
          }

          @include sm {
            width: calc(100vw - 20px * 2); // ALLEY_LEFT
            min-width: calc(100vw - 20px * 2); // ALLEY_LEFT
          }

          @include md {
            width: calc(50vw - 20px); // ALLEY_LEFT
            min-width: calc(50vw - 20px); // ALLEY_LEFT
          }

          @include lg {
            width: calc(50vw - 24px); // ALLEY_LEFT
            min-width: calc(50vw - 24px); // ALLEY_LEFT
          }
          @include xl {
            width: calc(50vw - 40px); // ALLEY_LEFT
            min-width: calc(50vw - 40px); // ALLEY_LEFT
          }

          @include xxl {
            width: calc(50vw - 60px); // ALLEY_LEFT
            min-width: calc(50vw - 60px); // ALLEY_LEFT
          }

          &.left {
            align-items: flex-end;

            @include md-down {
              &:not(.hide-numbers) {
                border-left: $border-lightest;
              }
            }

            .video-container,
            .image-container {
              margin: 12px;
              aspect-ratio: 1;
              border-radius: 40px;
              overflow: hidden;

              @include sm-down {
                width: 80vw; // VIDEOWIDTH
                margin: 0 auto 40px;
              }

              @include sm {
                width: 30vh; // VIDEOWIDTH
                margin: 0 auto 40px;
              }

              @include md {
                width: 324px; // VIDEOWIDTH
                margin-right: 40px; // PADDINGINTERIOR
              }

              @include lg {
                width: 420px; // VIDEOWIDTH
                margin-right: 48px; // PADDINGINTERIOR
              }

              @include xl {
                width: 500px; // VIDEOWIDTH
                margin-right: 80px; // PADDINGINTERIOR
              }

              @include xxl {
                width: 620px; // VIDEOWIDTH
                margin-right: 120px; // PADDINGINTERIOR
              }

              &.transitioning-idxs {
                &.fixed {
                  // background-color: black;
                }
                img {
                  opacity: 0 !important;
                }
              }

              &.not-fixed {
                opacity: 1;
                position: relative;

                video {
                  width: 100%;
                  height: 100%;
                }

                img {
                  width: 100%;
                  height: 100%;
                }

                &.animating {
                  opacity: 0;

                  @include md-down {
                    opacity: 1;
                  }
                }
              }

              &.fixed {
                position: fixed;
                opacity: 0;
                pointer-events: none;
                margin: 0 auto;
                z-index: 1;

                @include sm-down {
                  top: 80px; // VIDEOWIDTH
                  left: calc((100vw - 80vw) / 2); // VIDEOWIDTH PADDINGINTERIOR
                }

                @include sm {
                  top: 80px; // VIDEOWIDTH
                  left: calc((100vw - 30vh) / 2); // VIDEOWIDTH PADDINGINTERIOR
                }

                @include md {
                  top: calc(50vh - 324px / 2); // VIDEOWIDTH
                  left: calc(50vw - 324px - 40px); // VIDEOWIDTH PADDINGINTERIOR
                }

                @include lg {
                  top: calc(50vh - 420px / 2); // VIDEOWIDTH
                  left: calc(50vw - 420px - 48px); // VIDEOWIDTH PADDINGINTERIOR
                }

                @include xl {
                  top: calc(50vh - 500px / 2); // VIDEOWIDTH
                  left: calc(50vw - 500px - 80px); // VIDEOWIDTH PADDINGINTERIOR
                }

                @include xxl {
                  top: calc(50vh - 620px / 2); // VIDEOWIDTH
                  left: calc(50vw - 620px - 120px); // VIDEOWIDTH PADDINGINTERIOR
                }

                video {
                  opacity: 0;
                  width: 100%;
                  height: 100%;
                }

                img {
                  opacity: 0;
                  width: 100%;
                  height: 100%;
                }

                &.animating {
                  opacity: 1;

                  img {
                    transition: opacity 0.2s ease-in-out;
                  }

                  @include md-down {
                    opacity: 0;
                  }

                  &.active {
                    z-index: 2;
                    video {
                      opacity: 1;
                    }
                    img {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }

          &.right {
            @extend %flex-col-middle;
            align-items: flex-start;
            border-left: $border-lightest;
            padding-bottom: 120px;

            &.hide-numbers {
              border-left: none;
            }

            @include md-down {
              padding-bottom: 120px;
            }

            @include md {
              padding-bottom: 280px;
            }

            @include lg {
              padding-bottom: 340px;
            }
            @include xl {
              padding-bottom: 340px;
            }

            @include xxl {
              padding-bottom: 400px;
            }

            .content {
              @extend %flex-row;
              transition: opacity 0.3s ease-in-out;

              &.active {
                .dot {
                  background-color: $darkest;
                }
                .text {
                  opacity: 1;
                  &.dark-mode {
                    opacity: 1;
                  }
                }
              }

              &.hide-numbers {
                .dot {
                  display: none;
                }
              }

              .dot {
                @extend %flex-center;
                min-width: 56px;
                min-height: 56px;
                width: 56px;
                height: 56px;
                border: 10px solid $whynotusewhite;
                border-radius: 50%;
                transform: translateX(calc(-56px / 2)) translateY(-10px);
                background-color: $lightest;
                color: white;
                transition: background-color 0.3s ease-in-out;

                @include md-down {
                  background-color: $darkest;
                }
              }

              .text {
                @extend %flex-col;
                transition: opacity 0.3s ease-in-out;
                opacity: 0.1;
                flex: 1;

                @include md-down {
                  opacity: 1;
                }

                &.dark-mode {
                  opacity: 0.2;

                  @include md-down {
                    opacity: 1;
                  }

                  .title {
                    color: white;
                  }
                  .description {
                    color: $offwhite;
                  }
                }

                .title {
                  @extend %header-fancy;
                  font-size: 52px;
                  font-weight: bold;
                  margin: 0;
                  margin-bottom: 32px;
                  color: $darkest;
                }
                .description {
                  @extend %paragraph;
                  color: $darker;
                  line-height: 1.4;
                  margin: 0;
                  font-weight: 400;
                  font-size: 20px;
                }

                .learn-more-btn {
                  @extend %btn-darkest-new;
                  align-self: flex-start;
                }

                @include sm-down {
                  .title {
                    font-size: 32px;
                    margin-bottom: 16px;
                  }
                  .description {
                    font-size: 16px;
                  }

                  .learn-more-btn {
                    padding: 12px 30px;
                    font-size: 14px;
                    margin-top: 24px;
                  }
                }

                @include sm {
                  .title {
                    font-size: 40px;
                    margin-bottom: 16px;
                  }
                  .description {
                    font-size: 18px;
                  }

                  .learn-more-btn {
                    padding: 12px 30px;
                    font-size: 14px;
                    margin-top: 24px;
                  }
                }

                @include md {
                  .title {
                    font-size: 32px;
                    margin-bottom: 16px;
                  }
                  .description {
                    max-width: calc(100% - 12px);
                    font-size: 16px;
                  }

                  .learn-more-btn {
                    padding: 12px 30px;
                    font-size: 14px;
                    margin-top: 24px;
                  }
                }

                @include lg {
                  .title {
                    font-size: 40px;
                    margin-bottom: 24px;
                  }
                  .description {
                    max-width: calc(100% - 12px);
                    font-size: 16px;
                  }

                  .learn-more-btn {
                    padding: 12px 30px;
                    font-size: 14px;
                    margin-top: 36px;
                  }
                }

                @include xl {
                  .title {
                    font-size: 44px;
                    margin-bottom: 28px;
                  }
                  .description {
                    max-width: 480px;
                    font-size: 18px;
                  }

                  .learn-more-btn {
                    padding: 16px 36px;
                    font-size: 15px;
                    margin-top: 48px;
                  }
                }

                @include xxl {
                  .title {
                    font-size: 48px;
                    margin-bottom: 32px;
                  }
                  .description {
                    max-width: 540px;
                    font-size: 19px;
                  }

                  .learn-more-btn {
                    padding: 20px 42px;
                    font-size: 16px;
                    margin-top: 60px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .start-animation-line,
  .end-animation-line {
    height: 2px;
    width: 100%;
  }
}
