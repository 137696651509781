@import '../../styles/shared.scss';

.earnings-portal-header-outer {
  .earnings-portal-header-inner {
    @extend %flex-row;
    gap: 50px;
    align-items: center;

    @include lg-down {
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
    }

    .earnings-portal-text-container {
      .earnings-portal-text-container-label {
        @extend %label;
        @extend %flex-row;
        gap: 10px;
        font-size: 10px;
      }

      .earnings-portal-text-container-header {
        font-family: $font-header;
        font-size: 56px;
        font-weight: 700;
        line-height: 60px;
      }

      .earnings-portal-text-container-subheader {
        font-size: 14px;
        font-weight: 400;
        color: $medium;
        margin-top: 5px;

        span {
          color: $dark;
          font-weight: bold;
        }
      }

    }

    .earnings-portal-header-summary-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 40px;
      justify-content: space-evenly;
      align-items: center;
      padding: 60px;
      border-radius: 25px;
      background: $white;
      text-align: center;
      flex: 1;

      @include lg-down {
        padding: 50px;
      }

      @include md-down {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
      }

      .skeleton-number {
        height: 35px;
        width: 120px;
        border-radius: 5px;
        background: $prettymuchwhite;
        animation: skeleton-fade 3s infinite;
        margin: 0 auto;
      }

      .summary-number {
        font-size: 35px;
        font-weight: 700;
        font-family: $font-header;

        @include lg-down {
          font-size: 30px;
        }

        @include sm-down {
          font-size: 25px;

        }
      }

      .summary-label {
        font-size: 14px;
        font-weight: 400;
        color: $medium;
        margin-top: 5px;
      }
    }
  }
}

@keyframes skeleton-fade {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
