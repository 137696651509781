@import '../../styles/shared.scss';

.brand-setup-redirect-outer {
  width: 100%;

  .brand-setup-redirect-inner {
    width: min(90%, 700px);
    padding: 100px 0;
    margin: 0 auto;
    text-align: center;

    .brand-setup-redirect-title h1 {
      font-size: 48px;
      font-family: $font-header;
    }

    .brand-setup-redirect-subtitle {
      font-size: 16px;
      font-family: $font-body;
    }
  }
}
