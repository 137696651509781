@import '../../styles/shared.scss';

.empty-link-results-outer-container {
  .nothing-found-msg-container {
    @extend %flex-col;
    align-items: flex-start;
    max-width: 1240px;
    width: 90vw;
    margin: 32px auto;
    padding: 32px 24px;
    color: $dark;
    border-radius: 10px;

    .nothing-found-header {
      font-family: $font-header;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .nothing-found-body {
      font-size: 16px;

      a {
        color: $dark;
        font-weight: bold;
      }
    }
  }

  .add-new-link-msg-container {
    @extend %flex-col-center;
    max-width: 1240px;
    width: 90vw;
    margin: 32px auto;
    padding: 32px 24px;
    color: $dark;
    border-radius: 10px;

    .add-link-btn {
      @extend %btn-darker-new;
      padding: 12px 16px;
      max-width: 320px;
      margin: 24px auto 0;
      font-size: 14px;
    }
  }
}
