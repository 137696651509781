@import '../../styles/shared.scss';

.discover-sidebar-group {
  @extend %flex-row-middle;
  @extend %btn-basic;
  @extend %label;
  font-size: 15px;
  padding: 8px 12px;
  color: $light;
  border-radius: 8px;
  text-align: right;

  .new-badge {
    @extend %label;
    font-size: 10px;
    padding: 2px 4px;
    margin-right: 8px;
    border-radius: 4px;
    background-color: $primary;
    color: white;
  }

  &:hover {
    opacity: 1;
  }

  &.active {
    background-color: black;
    opacity: 1;
    color: white;

    .new-badge {
      background-color: $primary;
      color: white;
    }
  }

  &:hover {
    .action-icons {
      > div:not(.always-show) {
        display: flex;
      }
    }
  }
  .action-icons {
    @extend %flex-row-middle;
    gap: 6px;
    margin-right: 12px;
    font-size: 10px;

    > div:not(.always-show) {
      display: none;
    }
  }
}
