@import '../../styles/shared.scss';

.contract-progress-outer-container {
  margin: 32px 0 0;

  @include md-down {
    margin: 16px 0 0;
  }

  .steps {
    @extend %flex-row;

    @include md-down {
      flex-wrap: wrap;
    }

    .tab {
      position: relative;
      color: $light;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      border-bottom: 2px solid transparent;
      text-transform: uppercase;
      margin: 0 16px 0 0;

      @include md-down {
        margin: 6px 8px 0 0;
      }

      &.link {
        @extend %flex-center;
        text-decoration: none;
        svg {
          margin-left: 6px;
          font-size: 10px;
        }
      }

      &:hover {
        opacity: 0.8;
      }

      &.active {
        border-bottom: 2px solid $darkest;
        color: $darker;
      }

      @include md {
        margin-right: 24px;
      }

      .display {
        @extend %flex-row-middle;
        font-size: 15px;
        display: flex;
        font-weight: bold;
        white-space: nowrap;

        @include md-down {
          display: none;
          font-size: 13px;
        }

        .count {
          @extend %flex-center;
          font-size: 12px;
          margin-left: 4px;
        }

        &.short {
          display: none;
          @include md-down {
            display: flex;
          }
        }
      }
    }

    .step {
      flex: 1;
      padding-right: 16px;
      padding-bottom: 12px;
      position: relative;
      opacity: 0.3;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        opacity: 0.8;
      }

      @include md {
        padding-right: 24px;
      }

      &.active,
      &.past {
        opacity: 1;
      }

      .badge {
        font-size: 11px;
        color: $dark;
        text-transform: uppercase;

        .step-label {
          @include md-down {
            display: none;
          }
        }
      }

      .display {
        display: none;
        font-size: 13px;
        color: $darker;
        font-weight: bold;
        white-space: nowrap;

        @include md {
          display: flex;
          font-size: 15px;
        }

        &.short {
          display: flex;

          @include md {
            display: none;
          }
        }
      }

      .meter {
        position: absolute;
        bottom: -1px;
        width: calc(100% - 12px);
        left: 12px;
        height: 2px;
        background: $lighter;

        &.active {
          background: linear-gradient(90deg, $medium, $lighter, $lightest);
        }

        &.past {
          background: $darker;
        }
      }

      .status-circle {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        bottom: -6px;
        left: 0;
        background: $lighter;

        &.active,
        &.past {
          background: $darker;
        }
      }
    }
  }
}
