@import '../../../styles/shared.scss';

.collection-settings-outer-container {
  @extend %flex-center;
  pointer-events: none;
  opacity: 0;
  position: fixed;
  z-index: $zindex-outmost-modal;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transition: opacity 0.1s ease-in-out;
  background: rgba(0, 0, 0, 0.4);
  font-family: $font-body;

  &.visible {
    pointer-events: auto;
    opacity: 1;
  }

  .collection-settings-inner-container {
    background: white;
    position: relative;
    width: 100%;
    height: 100vh;
    padding-top: 0px;
    padding-bottom: 48px;
    overflow: auto;

    @include md {
      max-width: 880px;
      max-height: 880px;
      height: 80vh;
      border-radius: 16px;
    }

    .tabs {
      @extend %flex-row;
      position: sticky;
      width: 100%;
      max-width: 100%;
      overflow: scroll;
      border-bottom: 1px solid $offwhite;
      padding: 20px 32px 0;

      @include md-down {
        padding: 20px 64px 0 24px;
      }

      .exit-button {
        position: absolute;
        right: 0;
        background-color: white;
        cursor: pointer;
        opacity: 0.5;
        transition: opacity 0.5s ease;

        &:hover {
          opacity: 1;
        }
      }

      .tab {
        color: $light;
        border-bottom: solid 2px transparent;
        cursor: pointer;
        white-space: nowrap;
        font-size: 16px;
        transition: opacity 0.3s ease, transform 0.15s ease-in-out;
        padding: 0 16px 10px;
        font-weight: normal;
        transform: scale(0.9);

        @include md-down {
          font-size: 14px;
          padding: 0 12px 8px;
        }

        &:hover {
          opacity: 1;
        }

        &.selected {
          color: $darkest;
          border-bottom: solid 2px $darkest;
          opacity: 1;
          transform: scale(1);
          font-weight: bold;
        }
      }
    }

    .tab-content {
      padding: 32px;

      @include md-down {
        padding: 24px;
      }

      .section {
        @extend %flex-col;
        align-items: flex-start;
        margin-bottom: 20px;
        padding-bottom: 20px;

        &:not(:last-child) {
          border-bottom: $border-offwhite;
        }

        .section-header {
          @extend %header-fancy;
          letter-spacing: 0.5px;
          font-size: 18px;
          color: $darkest;
          margin-bottom: 2px;
        }

        .section-subheader {
          font-size: 13px;
          color: $darker;
          margin-bottom: 1rem;
        }

        .radio-btns {
          @extend %flex-row-middle;
          border-radius: 4px;
          gap: 8px;
          flex-wrap: wrap;

          .radio-btn {
            cursor: pointer;
            padding: 4px 12px;
            font-size: 14px;
            background: $white;
            color: $darkest;
            white-space: nowrap;
            position: relative;
            border: $border-lighter;
            border-radius: 24px;

            &.active {
              background: $darkest;
              border: $border-darkest;
              font-weight: bold;
              color: white;
            }

            &.disabled {
              background: $prettymuchwhite;
              color: $lighter;
            }

            &.changing {
              background: $darkest;
            }

            .loader {
              position: absolute;
              top: -8px;
              left: 0;
              width: 100%;
              height: 44px;
            }
          }
        }

        iframe {
          width: calc(100% + 48px);
          margin-left: -24px;
          height: 340px;
          border: $border-offwhite;
        }

        .copy-section {
          width: 100%;
          margin: 8px 0;

          .copy-section-label {
            font-size: 14px;
            font-weight: bold;
            color: $darkest;
            text-transform: uppercase;
            margin-bottom: 8px;
          }

          .copy-section-content {
            background: $whynotusewhite;
            border: $border-dark;
            border-radius: 6px;
            position: relative;
            width: 100%;
            font-size: 12px;
            max-height: 240px;
            padding: 16px 44px 16px 16px;
            overflow: auto;
            color: $dark;

            .caption-product-link {
              margin-bottom: 1px;

              a {
                color: $darkest;
              }
            }

            .copy-btn {
              @extend %btn-darkest;
              padding: 2px 8px;
              position: absolute;
              top: 6px;
              right: 6px;
            }
          }

          .qr-code {
            margin-top: 12px;
          }
        }
      }
    }

    .close-icn-container {
      @extend %btn-basic;
      @extend %flex-center;
      position: absolute;
      background: white;
      padding: 12px;
      border-radius: 24px;
      top: 8px;
      right: 0;
      height: 40px;
      width: 48px;
      padding-right: 8px;
      z-index: 2;

      .close-icn {
        font-size: 24px;
      }
    }
  }
}
