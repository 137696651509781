@import '../../../styles/shared.scss';

.discover-tag-groups-container {
  width: 100%;

  .sections {
    @extend %flex-col;
    align-items: flex-start;
    gap: 24px;
    color: white;

    .empty-results {
      @extend %flex-col-center;
      background-color: $darker;
      opacity: 0.6;
      border-radius: 8px;
      width: 100%;
      padding: 24px 12px;

      color: white;
      font-size: 12px;
      font-weight: normal;

      .header {
        @extend %label;
      }

      .subheader {
        text-align: center;
        font-size: 10px;
        margin-top: 2px;
        max-width: 200px;
      }
    }

    .section {
      @extend %flex-col;
      width: 100%;

      &.featured {
        background-color: $darker;
        padding: 16px;
        border: $border-dark;
        border-radius: 8px;
        width: calc(100% + 32px);
        transform: translateX(-16px);
      }

      .section-header-container {
        @extend %flex-row-middle-spread;
        gap: 12px;

        .section-header {
          font-size: 20px;
          font-family: $font-header;
          text-transform: uppercase;
          font-weight: 500;
          position: relative;
        }

        .disclaimer-icon {
          font-size: 14px;
          cursor: pointer;

          svg {
            color: $lighter;
          }
        }
      }

      .show-less-header {
        @extend %btn-basic;
        @extend %flex-row-middle;
        @extend %label;
        font-size: 10px;
        font-weight: 500;
        transition: opacity 0.2s ease-in-out;
        color: $light;
        margin-top: 2px;
        svg {
          margin-left: 4px;
        }
      }
      .tags {
        @extend %flex-col;
        gap: 2px;
        margin-top: 8px;
        max-height: 300px;
        overflow: auto;

        .tag {
          @extend %flex-row-middle-spread;
          @extend %label;
          font-size: 12px;
          font-weight: 400;
          color: $lightest;
          transition: opacity 0.2s ease-in-out;
          position: relative;
          cursor: pointer;
          border-radius: 4px;

          &:hover:not(.active) {
            color: white;
            font-weight: bold;
          }

          &.active {
            background-color: $dark;
            font-weight: bold;
            color: white;
            width: calc(100% + 10px);
            margin-left: -5px;
            padding: 0 5px;

            .loader-container {
              right: -3px;
            }
          }

          &.loading {
            .count {
              opacity: 0;
            }
            .loader-container {
              opacity: 0.3;
            }
          }

          .count {
            font-size: 8px;
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
          }

          .loader-container {
            position: absolute;
            right: -8px;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
          }
        }
      }

      .more {
        @extend %btn-basic;
        @extend %label;
        font-size: 12px;
        font-weight: 500;
        transition: opacity 0.2s ease-in-out;
        padding-top: 8px;
        color: $light;
      }
    }
  }

  .discover-tag-group-section-header {
  }
}
