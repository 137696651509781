@import '../../../styles/shared.scss';

.contract-content-card-outer-container {
  .analytics {
    @extend %flex-row;
    margin: 0 0 0 48px;

    @include md-down {
      width: 100%;
      margin: 16px 0 0 0;
    }

    .tile {
      @extend %flex-col-center;
      flex: 1;

      &:not(:last-child) {
        margin-right: 32px;
      }

      .value {
        font-size: 24px;
        min-height: 32px;
        min-width: 48px;
        line-height: 32px;
        font-weight: bold;
        position: relative;
        text-align: center;

        &.empty {
          font-weight: 300;
          color: $lighter;
          font-size: 32px;
        }

        .loader {
          position: absolute;
          top: 0;
          transform: scale(2);
        }
      }

      .label {
        text-transform: uppercase;
        font-size: 12px;
      }
    }
  }

  .content-type-section {
    &:not(:first-child) {
      margin-top: 24px;
    }

    .empty-msg {
      text-align: center;
      text-transform: uppercase;
      color: $light;
      font-size: 14px;
      margin: 12px 0;
    }

    .type-header {
      font-size: 12px;
      color: $dark;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .type-group {
    }

    .contract-content-element {
      @extend %flex-row-middle-spread;

      &:not(:first-child) {
        margin-top: 24px;
        @include md-down {
          margin-top: 32px;
        }
      }

      @include md-down {
        flex-direction: column;
        align-items: stretch;

        .secondary {
          margin-top: 12px;
        }
      }

      .main {
        @extend %flex-row-middle;

        &.has-shareable-link {
          @include md {
            width: 50%;
          }
        }

        @include md-down {
          width: 100%;
        }

        .image-container {
          position: relative;
          border-radius: 4px;
          overflow: hidden;
          height: 60px;
          width: 60px;
          min-height: 60px;
          min-width: 60px;
          margin-right: 12px;

          .empty-img {
            height: 60px;
            width: 60px;
            background: $offwhite;
          }
          @include md-down {
            height: 80px;
            width: 80px;

            .empty-img {
              height: 80px;
              width: 80px;
            }
          }

          img {
            object-fit: contain;
            height: 100%;
            width: 100%;
          }
        }

        .data {
          @extend %flex-col;
          color: $dark;
          overflow: hidden;

          textarea,
          input {
            @extend %input-inline;
            resize: none;

            &.title {
              font-size: 14px;
            }
            &.url {
              font-size: 12px;
            }
          }
          .title {
            font-size: 14px;
            font-weight: bold;
            color: $darker;
          }
          .performance-analytics {
            font-size: 12px;
            color: $darker;

            .bullet {
              margin: 0 4px;
            }

            &.error {
              color: $light;
            }
          }

          .see-more {
            @extend %btn-basic;
            font-size: 10px;
            color: $darker;
            margin-top: 4px;

            svg {
              font-size: 8px;
              margin-left: 4px;
            }
          }
          .date {
            font-size: 11px;
            color: $dark;
          }

          a {
            @extend %link;
            color: $dark;
          }

          .url {
            font-size: 11px;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
      .secondary {
        @extend %flex-row-middle-spread;
        margin-left: 24px;

        @include md-down {
          margin-left: 0;
          justify-content: space-between;
        }

        .short-link {
          @extend %flex-row;
          align-items: stretch;
          font-weight: bold;
          flex: 1;
          margin-right: 12px;

          .link {
            @extend %flex-row;
            border: 1px solid $light;
            border-right-width: 0;
            padding: 10px 12px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            font-size: 12px;
            white-space: nowrap;
            color: $dark;
            flex: 1;
            text-decoration: none;

            @include md {
              flex: 0;
              font-size: 11px;
              padding: 6px 12px;
            }
          }
          .copy-btn {
            @extend %flex-center;
            padding: 6px 12px;
            background: $darker;
            color: white;
            border-radius: 4px;
            cursor: pointer;
            font-size: 14px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            text-transform: uppercase;
          }
        }

        .link-actions {
          @extend %flex-row-middle;
          color: $dark;
          font-size: 14px;

          @include md-down {
            justify-content: flex-end;

            &:first-child {
              width: 100%;
            }
          }

          .link-action {
            @extend %flex-row;
            @extend %btn-basic;

            &:not(:first-child) {
              margin-left: 8px;
            }
            &.edit {
            }
            &.delete {
            }
          }

          a {
            color: $darker;
          }
        }
      }
    }
  }

  .additional-content-actions {
    @extend %flex-row;
    flex-wrap: wrap;

    .panel {
      &:not(:first-child) {
        margin-right: 24px;
        margin-top: 16px;

        @include md-down {
          margin-top: 12px;
        }
      }

      &:not(:last-child) {
        margin-right: 24px;
      }

      .panel-header-container {
        margin-bottom: 10px;

        .panel-header {
          font-size: 13px;
          font-weight: bold;
          color: $darker;
          text-transform: uppercase;
        }
        .panel-subheader {
          font-size: 11px;
          color: $dark;
          font-style: italic;
        }
      }
      .actions {
        @extend %flex-row;
        flex-wrap: wrap;

        .action {
          @extend %flex-row-middle-spread;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          border-radius: 8px;
          padding: 4px 12px 4px 8px;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          text-transform: uppercase;
          font-weight: bold;
          color: $darker;
          font-size: 13px;
          white-space: nowrap;
          margin-bottom: 12px;

          &:not(:last-child) {
            margin-right: 12px;
          }

          .social-icn {
            width: 20px;
            height: 20px;
            object-fit: contain;
            margin-right: 4px;
          }

          svg {
            font-size: 11px;
            margin-right: 4px;
          }

          &:hover {
            background: $offwhite;

            .actions {
              svg {
                color: $dark;
              }
            }
          }
        }
      }
    }
  }
}
