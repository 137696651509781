@import '../../../../styles/shared.scss';

.notification-settings-panel-outer {
  .notification-settings-panel-inner {
    .option-groups {
      @extend %flex-col;
      gap: 32px;
      margin-top: 32px;

      .option-group {
        .option-group-title {
          @extend %label;
          color: $medium;
          font-size: 10px;
          font-weight: 500;
          margin-bottom: 12px;
        }

        .options {
          @extend %flex-col;
          gap: 12px;

          @include md-down {
            gap: 20px;
          }

          .option {
            @extend %flex-row-middle-spread;
            gap: 24px;

            @include md-down {
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
            }

            .main {
              flex: 1;

              .display {
                font-weight: 500;
                font-size: 14px;
                color: $darkest;
                margin-bottom: 4px;

                &.disabled {
                  color: $light;
                }
              }

              .subdisplay {
                font-weight: normal;
                font-size: 12px;
                color: $medium;

                &.disabled {
                  color: $lighter;
                }
              }
            }

            .select-container {
              @extend %flex-row-middle;
              flex: 1;
              position: relative;
              align-self: stretch;

              .recommended-alert {
                @extend %flex-col;
                font-size: 14px;
                position: absolute;
                right: 100%;
                top: 0;
                height: 100%;

                svg {
                  height: 100%;
                  width: 14px;
                  margin-right: 8px;
                  color: $error;
                }
              }

              .shopmy-select {
                flex: 1;
              }

              &.disabled {
                .shopmy-select.without-styling .shopmy-select__control {
                  border-color: $lightest;
                  background-color: $prettymuchwhite;

                  .shopmy-select__single-value {
                    color: $light;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
