@import '../../../styles/shared.scss';

.home-header-brands-outer-container {
  @extend %flex-col;
  justify-content: center;
  background-color: $whynotuseblack;
  min-height: 85vh;
  padding-top: 140px;

  .home-header-brands-inner-container {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto 10vh;

    // Match this to the header
    @include md-down {
      padding: 0 20px;
    }

    @include md {
      padding: 0 60px;
    }

    @include lg {
      padding: 0 40px;
    }

    @include xl {
      padding: 0 12px;
    }

    @include xxl {
      max-width: 1440px;
      padding: 0;
    }

    .main-container {
      .title {
        font-family: $font-header;
        line-height: 1.3;
        color: white;
        font-weight: bold;
        margin: 0;

        @include md-down {
          font-size: 40px;
        }

        @include md {
          font-size: 40px;
          background-color: transparent;
          max-width: 480px;
        }

        @include lg {
          font-size: 46px;
          max-width: 640px;
        }

        @include xl {
          font-size: 52px;
          max-width: 800px;
          width: 60%;
        }

        @include xxl {
          font-size: 64px;
        }
      }

      .actions {
        @extend %flex-row;
        gap: 12px;
        margin-top: 44px;

        @include md-down {
          flex-direction: column;
          align-items: flex-start;
        }

        .action {
          &.primary {
            @extend %btn-white-outline;
            @extend %label;
            padding: 12px 32px;
            font-size: 14px;

            @include md {
              padding: 18px 36px;
              font-size: 16px;
            }

            @include xl {
              padding: 20px 44px;
              font-size: 18px;
            }
          }

          &.secondary {
            @extend %flex-row-middle;
            @extend %btn-basic;
            color: white;
            font-weight: bold;
            font-size: 18px;
            padding: 20px 20px;
            text-decoration: none;

            svg {
              margin-left: 12px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
