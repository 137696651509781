@import '../../../styles/shared.scss';

.about-department-outer {
  .about-department-inner {
    .department-name {
      font-size: 1.4rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: normal;
      margin-bottom: 15px;
    }

    .department-members {
      @extend %flex-row;
      gap: 30px;
      flex-wrap: wrap;
    }
  }
}
