@import '../../../../styles/shared.scss';

.lookbook-report-stats-outer {
  .lookbook-report-stats-inner {
    padding: 30px 40px;
    background-color: black;
    border-radius: 16px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    color: white;
    row-gap: 20px;

    @include md-down {
      grid-template-columns: repeat(3, 1fr);
    }

    .stat-container {
      @extend %flex-col;
      gap: 5px;

      @include md-down {
        &.less-important {
          display: none;
        }
      }

      .stat-title {
        @extend %label;
        font-size: 0.7rem;
        font-weight: normal;
      }

      .stat-value {
        font-family: $font-header;
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
  }
}
