@import '../../styles/shared.scss';

.links-outer-container {
  @extend %flex-col;
  width: 100%;
  font-family: $font-body;
  background-color: $prettymuchwhite;
  padding-bottom: 120px;
  min-height: 100vh;

  h1.header {
    text-align: center;
    font-size: 28px;
    display: block;
    margin: 24px 0 0;

    @include md {
      display: none;
    }
  }

  .links-inner-container {
    @extend %flex-col;
    width: 100%;
    max-width: 1440px;
    padding: 12px 0;
    margin: 0 auto;
    position: relative;
    padding-bottom: 40px;

    &.super-admin {
      max-width: 1800px;
    }

    @include md {
      padding: 24px;
    }
  }
}
