@import '../../../styles/shared.scss';

.add-pin-modal-final-tile-outer-container {
  @extend %flex-row-center;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  font-family: $font-body;

  .collection-final-tile {
    @extend %flex-col;
    flex: 1;
    position: relative;
    padding-top: 16px;
    border: $border-prettymuchwhite;
    border-radius: 8px;
    background: white;
    transition: all 0.35s ease-in-out;
    overflow: hidden;

    @include lg {
      max-width: 340px;
      min-width: 240px;
    }

    .collection-product-img-wrapper {
      width: 200px;
      height: 200px;
      overflow: hidden;
      position: relative;
      min-height: 200px;
      margin: 0 auto 0;

      &.modifying {
        img {
          filter: blur(4px);
          opacity: 0.4;
        }

        .loading-container {
          @extend %flex-center;
          position: absolute;
          z-index: 2;
          top: 0;
          width: 100%;
          height: 100%;

          .loader {
            position: absolute;
            transform: translateY(-8px);
          }

          .message {
            transform: translateY(8px);
            font-size: 9px;
            color: $medium;
            text-transform: uppercase;
          }
        }
      }

      .transform-image-btns {
        @extend %flex-row;
        position: absolute;
        border-bottom-left-radius: 6px;
        overflow: hidden;
        top: 0px;
        right: 0px;

        .upload-basic-container {
          font-size: 12px;
          background: rgba(255, 255, 255, 0.45);
          cursor: pointer;

          &:hover {
            color: $darkest;
            background: rgba(240, 240, 240, 0.7);
          }
        }

        .upload-image-btn,
        .prettify-image-btn,
        .rotate-image-btn {
          font-size: 12px;
          background: rgba(255, 255, 255, 0.45);
          cursor: pointer;
          color: $dark;
          padding: 6px;

          &.force {
            color: $darkest;
          }

          &:hover {
            color: $darkest;
            background: rgba(240, 240, 240, 0.7);
          }
        }
      }

      .ReactCrop__rule-of-thirds-vt::before,
      .ReactCrop__rule-of-thirds-vt::after,
      .ReactCrop__rule-of-thirds-hz::before,
      .ReactCrop__rule-of-thirds-hz::after {
        background: $lightest;
      }

      .ReactCrop {
        width: 100%;
        height: 100%;

        & > div {
          width: 100%;
          height: 100%;
        }

        .ReactCrop__image {
          align-self: center;
          width: 100%;
          height: 100%;
          min-width: 200px;
          min-height: 200px;
          object-fit: contain;

          &.empty {
            background: $prettymuchwhite;
            border-radius: 4px;
          }
        }
      }
    }

    .product-image-controls {
      @extend %flex-center;
      padding: 12px 0;
      max-width: 200px;
      align-self: center;
      margin: 0 auto;

      .btn {
        font-size: 12px;
        padding: 6px 0;
        letter-spacing: 4px;
        flex: 1;

        &.apply-crop-btn {
          @extend %btn-medium;
        }

        &.remove-crop-btn {
          @extend %btn-text;
        }
      }
      .apply-crop-msg {
        font-size: 10px;
        text-align: center;
        background: white;
      }
    }

    .change-image {
      text-align: center;
      padding: 4px 0 12px;
      font-size: 12px;
    }

    .meta-container {
      @extend %flex-col;
      padding: 8px 16px 120px;
      background: white;
      border-top: $border-offwhite;
      position: relative;
      flex: 1;

      #title {
        font-size: 16px;
        font-weight: bold;
      }

      .toggle-link {
        @extend %flex-row-middle;
        justify-content: flex-end;
        margin: 4px 0;

        span {
          font-size: 12px;
          margin-right: 4px;
        }

        svg {
          font-size: 12px;
          margin: 0 4px 1px 0;
        }

        .switch {
          margin-left: 4px;
          pointer-events: none;
        }

        &.disabled {
          opacity: 0.2;
        }
      }

      .input-header {
        font-size: 10px;
        padding-bottom: 2px;
        color: $medium;
      }

      .input-action-btn-group {
        @extend %flex-row-middle;

        .icn {
          margin-left: 8px;
          cursor: pointer;
          color: $dark;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      input,
      textarea {
        width: 100%;
        border-radius: 4px;
        border: $border-lightest;
        padding: 4px 8px;
        margin-bottom: 4px;
        font-size: 16px;
        outline: none;
        font-family: $font-body;
        letter-spacing: 0.5px;
        transition: all 0.25s ease-in-out;
      }

      textarea {
        margin-bottom: 2px;
        resize: none;
      }
      .actions {
        margin-top: 4px;
        .preview-link-btn {
          text-decoration: underline;
        }

        .preview-link-btn,
        .link-advanced-settings {
          @extend %btn-basic;
          text-align: right;
          font-size: 14px;
          font-weight: bold;
          color: $darker;
          margin-bottom: 2px;

          svg {
            font-size: 11px;
            margin-left: 4px;
          }
        }
      }

      .submit-btn {
        @extend %btn-dark;
        padding: 12px 16px;
        align-self: stretch;
        margin-top: 12px;
        position: relative;
        transition: all 0.1s ease-in-out;

        &.adding {
          cursor: default;
          color: transparent;
          &:hover {
            opacity: 1;
          }

          .loader {
            position: absolute;
            height: 100%;
            margin-top: -22px;
          }
        }
      }
    }
  }
}
