@import '../../../styles/shared.scss';

.opportunity-header-title-container {
  flex: 1;
  .title {
    font-size: 48px;
    font-weight: 600;
    font-family: $font-header;
    border: $border-transparent;

    @include md-down {
      font-size: 40px;
    }

    &.empty {
      background-color: $offwhite;
      border-radius: 4px;
      color: transparent;
      max-width: 600px;
    }
  }

  &.empty {
    textarea {
      background-color: transparent;
    }
  }

  textarea {
    @extend %input-inline;
    width: 100%;
    background-color: $white;
    border: $border-offwhite;
    padding: 12px 12px 12px 12px;
    transform: translateX(-11px) translateY(-11px);
    margin-bottom: -27px;
    resize: none;
    border-radius: 10px;

    &::placeholder {
      color: $lighter;
    }
  }
}
