@import '../../../styles/shared.scss';

.cost-reasoning-overlay {
  @extend %flex-col;
  background-color: $offwhite;
  border: $border-lightest;
  box-shadow: $shadow-lightest;
  border-radius: 10px;
  padding: 20px;
  border-bottom-right-radius: 0;
  text-align: left;
  width: 320px;
  max-width: 320px;

  .cr-sections {
    @extend %flex-col;
    gap: 12px;

    .cr-section-title {
      @extend %label;
      font-size: 10px;
      margin-bottom: 4px;
      color: $medium;
    }

    .cr-items {
      @extend %flex-col;
      gap: 2px;

      .cr-item {
        @extend %flex-row-middle-spread;
        justify-content: space-between;
        align-items: center;
        gap: 24px;

        &.intermittent {
          margin-left: 4px;
          .cr-title {
            color: $medium;
            font-size: 10px;
          }
          .cr-value {
            font-size: 10px;
            color: $medium;
            font-weight: normal;
          }
        }

        .cr-title {
          font-size: 11px;
          color: $dark;

          .cr-title-extension {
            font-size: 9px;
            color: $medium;
            margin-left: 2px;
          }
        }

        .cr-value {
          font-size: 12px;
          font-weight: bold;
          color: $darkest;
        }
      }
    }
  }
}
