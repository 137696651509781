@import '../../../styles/shared.scss';

.opportunity-plan-talent-and-backups {
  .opportunity-plan-talent {
    padding: 24px 64px 40px;
    background-color: white;
    border-radius: 0 0 20px 20px;

    @include md-down {
      padding: 24px 24px 40px;
    }

    .results-controls {
      @extend %flex-row-middle-spread;
      margin-bottom: 12px;

      .sort-container {
        @extend %flex-row-middle;
        gap: 12px;

        .sort-label {
          @extend %label;
          font-size: 12px;
          color: $dark;
        }
        .shopmy-select {
          min-width: 240px;

          .shopmy-select__single-value {
            @extend %label;
            color: $primary;
            font-size: 12px;
          }
        }
      }
    }

    .talent-results {
      @extend %flex-col;
      gap: 24px;

      @include md-down {
        gap: 36px;
      }

      .force-added {
        @extend %flex-col;
        gap: 12px;
        padding: 24px;
        background-color: $darkest;
        border-radius: 8px;
        color: white;

        .force-added-title-container {
          .force-added-title {
            font-size: 16px;
            font-weight: bold;
          }
          .force-added-subtitle {
            font-size: 12px;
          }
        }

        .force-added-creators {
          @extend %flex-col;
          gap: 12px;

          .force-added-creator {
            @extend %flex-row-middle-spread;
            font-size: 12px;
            color: white;
            cursor: pointer;

            .force-added-creator-data {
              .name {
                font-weight: bold;
                font-size: 16px;
              }
              .name {
                font-size: 12px;
              }
            }

            .force-added-creator-remove {
              @extend %btn-outline-new;
              padding: 4px 8px;
              font-size: 10px;
            }
          }
        }
        .force-added-actions {
          @extend %flex-row-middle;
          gap: 12px;

          .action {
            @extend %btn-primary-new;
            padding: 12px 24px;
            font-size: 10px;
          }
        }
      }
    }

    .actions {
      @extend %flex-row-middle;
      margin-top: 48px;
      justify-content: flex-end;
      gap: 12px;

      .action {
        font-size: 12px;
        position: relative;

        &.primary {
          @extend %btn-primary-new;
          padding: 12px 24px;
        }

        &.secondary {
          @extend %btn-outline-new;
          padding: 12px 24px;
        }

        &.disabled {
          background-color: $offwhite;
          color: $light;
          border-color: $offwhite;
          cursor: default;
        }

        &.running {
          color: transparent;

          .loader {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }

  .opportunity-plan-backups {
    padding: 40px 64px 40px;

    @include md-down {
      padding: 40px 24px 40px;
    }

    .backup-title-container {
      @extend %flex-col;
      gap: 4px;
      margin-bottom: 24px;

      .backup-title {
        font-size: 24px;
        font-weight: bold;
        font-family: $font-header;
      }

      .backup-subtitle {
        font-size: 14px;
        color: $dark;
      }
    }

    .backup-results {
      @extend %flex-col;
      gap: 24px;

      @include md-down {
        gap: 36px;
      }
    }
  }
}
