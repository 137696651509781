@import '../../styles/shared.scss';

.add-catalog-group-modal {
  width: 50vw !important;
  max-height: 320px;

  .add-catalog-group-modal-content {
    @extend %flex-col;
    height: 100%;
    position: relative;

    .modal-header {
      font-family: $font-header;
      font-size: 24px;
      font-weight: 600;
      color: $darkest;
      margin-bottom: 20px;

      svg {
        margin-right: 8px;
        cursor: pointer;
      }
    }

    .back-btn {
      font-size: 14px;
      color: $darkest;
    }

    .modal-add-options {
      display: flex;
      flex-direction: column;
      padding: 10px 0;
    }

    .add-option-container {
      cursor: pointer;
      width: 100%;
      height: 70px;
      border: 1px solid $lightest;
      border-radius: 10px;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      transition: border 0.25s ease-in-out;

      .img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;

        img {
          width: 60%;
          height: 60%;
          transition: width 0.25s ease-in-out, height 0.25s ease-in-out;
        }
      }

      .add-option-details {
        margin-left: 6px;

        .add-option-title {
          color: $darkest;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 0.25px;
        }

        .add-option-description {
          color: $medium;
          font-size: 12px;
          margin-top: 4px;
        }
      }

      &:hover {
        border: 1px solid $darkest;

        img {
          width: 62%;
          height: 62%;
        }
      }
    }

    .shopify-collection-catalog-group-container {
      display: flex;
      flex-direction: column;
      height: 100%;

      .integration-select-container {
        margin-bottom: 12px;

        .description {
          font-size: 14px;
          color: $medium;
          margin-bottom: 4px;
        }
      }

      .shopify-add-group-content {
        height: 80%;
      }

      .shopify-add-group-footer {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .action-button {
          @extend %btn-dark-new;
          height: 50px;
          font-size: 14px;
          text-transform: uppercase;
          transform: opacity 0.25s ease-in-out;

          &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
