@import '../../styles/shared.scss';

.artist-modal-orders-container {
  padding: 12px 24px;

  .artist-modal-orders {
    .order {
      @extend %flex-row-middle-spread;
      margin-bottom: 16px;
      border-radius: 16px;
      padding: 12px;
      background: white;
      border: $border-lightest;

      .main {
        @extend %flex-row-middle;

        .image-container {
          width: 48px;
          height: 48px;
          margin-right: 12px;
          border-radius: 4px;
          img,
          .empty {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .empty {
            background: $offwhite;
          }
        }

        .data {
          @extend %flex-col;
          .created {
            @extend %label;
            font-size: 10px;
            font-weight: normal;
          }
          .content {
            font-size: 13px;
            font-weight: bold;
            color: $darkest;

            svg {
              margin-left: 4px;
              font-size: 8px;
            }
          }
          .title {
            font-size: 10px;
            font-weight: bold;
            color: $dark;
            margin-top: 2px;
          }

          .merchant {
            @extend %label;
            align-self: flex-start;
            background: $primary;
            color: white;
            font-size: 10px;
            padding: 2px 8px;
            border-radius: 12px;
            margin-top: 4px;
          }
        }
      }

      .blocked-stats {
        @extend %btn-basic;
        @extend %flex-col;
        align-items: flex-end;
        .msg {
          font-size: 14px;
          font-weight: bold;
        }
        .learn-more {
          font-size: 9px;
          text-transform: uppercase;
        }
      }

      .stats {
        @extend %flex-row-middle;
        margin-left: 24px;
        gap: 24px;

        .stat {
          @extend %flex-col-center;
          .value {
            font-size: 20px;
            text-align: center;
            font-family: $font-header;
            color: $darkest;
            font-weight: bold;
          }
          .metric {
            @extend %label;
            margin-top: 2px;
            text-align: center;
            font-weight: normal;
            font-size: 9px;
            color: $dark;
          }
        }
      }
    }
  }
}
