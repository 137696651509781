@import '../../Components/variables.scss';
@import '../../styles/shared.scss';

body {
  &.modal-visible {
    overflow: hidden;
  }
}

.root {
  height: 100vh;

  &.app {
    .content-container {
      .main-container-children {
        margin-top: 0;
      }
    }
  }

  &.home {
    .content-container {
      .main-container-children {
        margin-top: 0;
      }
    }
  }

  .content-container {
    @extend %flex-col;

    &.dark {
      background-color: $darkest;
    }

    .main-container-children {
      @extend %flex-col;
      min-height: 100vh;

      &.public {
        margin-top: 0;
      }

      &.embed {
        margin-top: 0;
      }
    }
  }

  @media (min-width: $xs) and (max-width: $md) {
    .content-container {
      .main-container-children {
        margin-top: calc(#{$mobileNavHeight} - 4px);
        width: 100%;
      }
    }
  }

  @media (min-width: $md) {
    .content-container {
      .main-container-children {
        margin: calc(#{$desktopNavHeight} - 1px) 0 0 0;
      }
    }
  }
}

.hide-under-sm {
  @include sm-down {
    display: none !important;
  }
}

.hide-over-sm {
  @include sm {
    display: none !important;
  }
}

.hide-under-md {
  @include md-down {
    display: none !important;
  }
}

.hide-over-md {
  @include md {
    display: none !important;
  }
}

.desktop-only {
  display: none !important;

  @include lg {
    display: inherit !important;
  }
}

.mobile-only {
  @include lg {
    display: none !important;
  }
}

.desktop-only {
  display: none !important;

  @include lg {
    display: inherit !important;
  }
}

.lg-only {
  display: none !important;

  @include lg {
    display: inherit !important;
  }
}

.xl-only {
  display: none !important;

  @include xl {
    display: inherit !important;
  }
}

.xxl-only {
  display: none !important;

  @include xxl {
    display: inherit !important;
  }
}

// Custom styling for cogo-toast
// cogo toast has inline styling, !important likely necessary
#ct-container {
  z-index: $zindex-toast;

  .ct-toast {
    font-family: $font-body;
    min-height: 36px;
    color: $darkest;

    &.ct-toast-success {
      border-color: $primary !important;

      svg path {
        fill: $primary !important;
      }
    }

    &.ct-toast-error {
      border-color: $error !important;

      svg path {
        fill: $error !important;
      }
    }
  }
}

// Custom styling for confirm alert
.react-confirm-alert-overlay {
  animation: react-confirm-alert-fadeIn 0.25s 0.1s forwards;
  background: rgba(0, 0, 0, 0.7);
  z-index: 102;
  font-size: 14px;
  margin: 0 0 8px;

  .react-confirm-alert-body {
    font-family: $font-body;
    color: $medium;
    box-shadow: $shadow-light;

    @include md {
      &:not(.confirm-prompt-outer-container) {
        min-width: 480px;
        padding: 48px;
      }
    }

    @include md-down {
      align-items: flex-start;
      padding-top: 10vh;

      .react-confirm-alert-body {
        max-width: calc(100% - 48px);
        margin: 0 auto;
      }
    }

    h1 {
      font-family: $font-header;
      margin: 0 0 8px;
      line-height: 1.2;
      color: $darkest;
    }

    b {
      color: $darkest;
    }

    .react-confirm-alert-button-group {
      flex-wrap: wrap;
      margin-top: 24px;
      gap: 12px;
    }

    button {
      @extend %btn-darker-new;
      padding: 10px 20px;
      margin: 0;

      &.secondary {
        @extend %btn-offwhite-new;
        padding: 10px 20px;
      }

      &.cancel {
        @extend %btn-offwhite-new;
        padding: 10px 20px;
      }

      &.danger {
        @extend %btn-danger;
      }
    }
  }
}

.intercom-lightweight-app,
.intercom-lightweight-app-launcher {
  z-index: 10000 !important; // let us overlay on top
}

.intercom-lightweight-app-launcher {
  @include md-down {
    display: none;
  }
}

.tippy-tooltip {
  font-family: $font-body;
}
