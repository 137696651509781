@import '../../styles/shared.scss';

.error-banner {
  @extend %flex-row-middle;
  padding: 0 35px;
  height: 80px;
  background: $lightpink;
  color: $black;
  font-size: 14px;
  margin-top: 16px;

  .icon-wrapper {
    margin-right: 15px;
    font-size: 25px;
    color: $error;
  }

  a {
    color: $black;
  }
}
