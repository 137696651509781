@import '../../../styles/shared.scss';

.section-template.editorial {
  @extend %flex-col;
  gap: 16px;
  overflow: hidden;

  .collection {
    @extend %flex-row-middle;
    position: relative;
    padding: 0 20px;

    .image {
      margin-right: 12px;
      height: 40px;
      width: 40px;
      background: $offwhite;
      border-radius: 4px;
    }

    .data {
      flex: 1;

      .title {
        height: 10px;
        width: 100%;
        border-radius: 2px;
        background: $offwhite;
      }
      .subtitle {
        height: 8px;
        width: 70%;
        margin-top: 4px;
        border-radius: 2px;
        background: $offwhite;
      }
    }
  }
}
