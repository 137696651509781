@import '../../../styles/shared.scss';

.content-upload-drop-container {
  .upload-drop-zone-outer {
    @extend %flex-center;
    border: 2px dashed $lighter;
    margin: 12px 0;
    padding: 20px 0;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 4px;
    color: $medium;

    > div {
      width: 100%;

      .upload-drop-zone {
        @extend %flex-col-center;
        width: 100%;

        .progress-meter {
          background: $offwhite;
          height: 6px;
          margin-bottom: -6px;
          border-radius: 3px;
          transform: translateY(4px);
          width: 80%;
          max-width: 240px;

          .progress-meter-complete {
            height: 100%;
            background: $darker;
            border-radius: 3px;
            transition: width 0.1s ease-in-out;
          }
        }
      }
    }
  }

  .upload-via-url-btn {
    @extend %flex-row-middle;
    @extend %btn-basic;
    justify-content: flex-end;
    font-weight: bold;
    color: $dark;
    font-size: 11px;
    text-transform: uppercase;
    margin-right: 4px;

    svg {
      margin-left: 8px;
    }
  }
}
