@import '../../styles/shared.scss';

.shop-sections-outer-container {
  background: white;
  height: 100%;
  padding: 24px 20px 48px;
  border-right: $border-lightest;
  overflow: auto;

  @include lg {
    padding: 56px 40px;
  }

  .section-header {
    @extend %header-fancy-sm;
    margin-bottom: 32px;
  }
  .shop-sections-sections {
  }

  .shop-sections-add-new-section {
    margin-top: 40px;

    .add-new-section-header-container {
      margin-bottom: 12px;
      .add-new-section-header {
        @extend %header-fancy-sm;
        margin-bottom: 32px;
      }
      .add-new-section-subheader {
        font-size: 12px;
        color: $dark;
      }
    }

    .section-options {
      @extend %flex-col;
      gap: 12px;

      .section-option {
        @extend %flex-row-middle-spread;
        @extend %btn-basic;
        border: $border-lightest;
        border-radius: 8px;
        padding: 8px 16px;
        background: white;

        .title {
          @extend %label;
          font-size: 12px;
          font-weight: normal;
        }
        .actions {
          @extend %flex-row-middle;
          gap: 12px;

          .action {
            position: relative;
            svg {
              font-size: 12px;
            }

            &.adding {
              .add-icon {
                opacity: 0;
              }
            }
            .loader {
              position: absolute;
              top: 0;
              left: 0;
              margin-top: -10px;
            }
          }
        }
      }
    }
  }
}

// Needs to be outside main part in order to rearrange
.shop-section-section {
  @extend %flex-row-middle-spread;
  @extend %btn-basic;
  margin-bottom: 12px;
  border: $border-lightest;
  border-radius: 8px;
  padding: 8px 8px 8px 16px;
  z-index: 200; // To ensure it stands over the modal
  background: white;
  color: $darkest;

  .title {
    @extend %label;
    font-size: 12px;
    font-weight: normal;

    &.empty {
      color: $lighter;
    }
  }

  &.hidden {
    color: $medium;
  }

  &.selected {
    border: $border-darkest;
    color: $darkest;
  }

  .actions {
    @extend %flex-row-middle;
    gap: 8px;

    .delete,
    .edit {
      font-size: 11px;
    }

    .action {
      font-size: 12px;
    }
  }
}
