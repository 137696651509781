@import '../../styles/shared.scss';

.products-warning-message,
.products-footer-message {
  @extend %flex-row;
  border: $border-lighter;
  border-radius: 8px;
  padding: 8px 12px;
  margin: -12px auto 24px;
  font-size: 13px;
  color: $dark;
  svg {
    margin-right: 12px;
  }

  a {
    @extend %link;
  }
}

.products-footer-message {
  margin-top: 48px;
}

.collection-products-outer-container {
  @extend %flex-row;
  flex-wrap: wrap;
  padding-bottom: 240px;
  max-width: 1024px;
  font-family: $font-body;
  padding: 0 20px;

  &.quick {
    margin-top: 24px;
  }

  &.horizontal {
    flex-wrap: nowrap;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 160px;
    overflow-x: auto;
    overflow-y: visible;

    @include sm {
      overflow-x: hidden;
      overflow-y: visible;
      flex-wrap: wrap;
    }
    .products-list-add-new-btn-container {
      @include sm-down {
        min-width: 50vw;
        margin-left: 25vw;
      }
    }
    .products-list-wrapper {
      flex-wrap: nowrap;
      width: 100%;
      padding-top: 12px;

      @include sm {
        flex-wrap: wrap;
      }
    }
  }

  &.grid {
    padding: 0 8px;

    @include md-down {
      padding: 0 20px;
    }

    .products-list-add-new-btn-container {
      @include sm-down {
        width: 50%;
        box-sizing: border-box;
        padding: 12px;
        min-height: 288px;
        cursor: pointer;
      }
    }
  }

  &.longform {
    max-width: 800px;
    width: 100%;
    .products-list-add-new-btn-container {
      width: 100%;
    }
  }

  .products-list-wrapper {
    @extend %flex-row;
    flex-wrap: wrap;
    width: 100%;
  }

  .products-list-add-new-btn-container {
    @extend %flex-center-vertical;
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    min-height: 288px;
    cursor: pointer;

    @include sm {
      width: 50%;
    }

    @include md {
      width: 33%;
    }

    &:hover {
      .products-list-add-new-btn-icn {
        background: $darkest;
      }

      .products-list-add-new-btn {
        color: $darkest;
      }
    }

    .products-list-add-new-btn-icn {
      @extend %flex-center;
      font-size: 16px;
      width: 32px;
      height: 32px;
      background: $dark;
      color: white;
      border-radius: 16px;
      transition: all 0.2s ease-in-out;
    }

    .products-list-add-new-btn {
      margin-top: 8px;
      color: $dark;
      transition: all 0.2s ease-in-out;
    }
  }
}
