$border-primary: 1px solid $primary;
$border-primaryLight: 1px solid $primaryLight;
$border-primaryDark: 1px solid $primaryDark;
$border-white: 1px solid $white;
$border-black: 1px solid $black;
$border-prettymuchblack: 1px solid $prettymuchblack;
$border-dark: 1px solid $dark;
$border-darker: 1px solid $darker;
$border-darkest: 1px solid $darkest;
$border-medium: 1px solid $medium;
$border-light: 1px solid $medium;
$border-lighter: 1px solid $lighter;
$border-lightest: 1px solid $lightest;
$border-offwhite: 1px solid $offwhite;
$border-prettymuchwhite: 1px solid $prettymuchwhite;
$border-transparent: 1px solid transparent;
$border-error: 1px solid $error;
