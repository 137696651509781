@import '../../styles/shared.scss';

.collaborations-explainer-outer-container {
  @extend %flex-col;
  max-width: 800px;
  margin: 0 auto 24px;
  width: 100%;

  .explainer-header-container {
    @extend %flex-center-vertical;
    margin: 24px 0 48px;
    width: 100%;

    .explainer-badge {
      font-size: 15px;
      text-transform: uppercase;
      // font-weight: bold;
      color: $dark;

      @include md-down {
        font-size: 13px;
      }
    }
    .explainer-header {
      font-size: 30px;
      text-transform: uppercase;
      font-weight: bold;
      color: $darkest;

      @include md-down {
        font-size: 24px;
      }
    }
    .explainer-subheader {
      font-size: 16px;
      text-align: center;
      color: $dark;
      width: 100%;

      @include md-down {
        font-size: 14px;
      }

      svg {
        margin-right: 4px;
        font-size: 10px;
      }
    }
    .explainer-external-link {
      @extend %flex-center;
      align-self: center;
      font-size: 16px;
      background: $darker;
      padding: 8px 24px;
      white-space: nowrap;
      border-radius: 4px;
      margin-top: 24px;
      font-weight: bold;
      text-transform: uppercase;
      color: white;
      text-decoration: none;

      svg {
        margin-left: 12px;
        font-size: 14px;
      }
    }

    .explainer-external-link-secondary {
      font-size: 12px;
      white-space: nowrap;
      border-radius: 8px;
      margin-top: 8px;
      text-transform: uppercase;
      color: $dark;
    }
  }

  .explainer-body-container {
    @extend %flex-col;
    margin: 32px auto 24px;
    max-width: 600px;
    width: 100%;

    .explainer-body {
      .step {
        @extend %flex-row-middle-spread;
        margin: 24px 0 36px;
        opacity: 0.15;

        @include md-down {
          flex-direction: column;
          align-items: flex-start;
          margin: 24px 0 48px;
        }

        &.active {
          opacity: 1;
        }

        .content {
          .badge {
            font-size: 14px;
            text-transform: uppercase;
            color: $darkest;
          }
          .header {
            font-size: 28px;
            font-weight: bold;
            color: $darkest;
          }
          .subheader {
            font-size: 14px;
            color: $darkest;
          }
        }
        .actions {
          margin: 0 0 0 12px;
          @include md-down {
            margin: 12px 0 0 0;
          }
          .action {
            @extend %btn-darker-new;
          }
        }
      }
    }

    .explainer-body {
      font-size: 14px;

      a {
        @extend %link;
      }
    }
    .get-started-btn {
      margin-top: 12px;
      @extend %btn-darker-new;
      align-self: center;
      padding: 8px 24px;
    }
  }
}
