@import '../../../styles/shared.scss';

.user-lookbook-order-details-items-outer {
  .user-lookbook-order-details-items-inner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    justify-content: center;
  }
}
