@import '../../../styles/shared.scss';

.recommendation-outer {
  border-radius: 10px;
  background-color: white;
  padding: 23px;
  background-color: white;

  &.is-refreshing {
    .fade-on-refresh {
      opacity: 0.2;
    }
  }

  .fade-on-refresh {
    transition: opacity 0.25s ease-in-out;
  }

  .recommendation-inner {
    @extend %flex-row;
    width: 100%;

    .recommendation-image-wrapper {
      @extend %flex-row-middle;
      margin-right: 20px;
      width: 20%;

      .recommendation-image {
        height: 200px;
        width: 200px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        background-color: $darkest;

        &.is-refreshing {
          img {
            opacity: 0;
          }
        }

        .recommendation-image-action-overlay {
          @extend %flex-row-end;
          position: absolute;
          bottom: 0;
          width: 100%;
          background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), transparent);
          border-radius: 0 0 16px 16px;
          padding: 10px;

          .recommendation-image-action-btn {
            color: white;
            cursor: pointer;
          }
        }

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          transition: opacity 0.25s ease-in-out;
        }
      }
    }

    .recommendation-data-wrapper {
      @extend %flex-row;
      width: 80%;
    }

    .recommendation-data {
      @extend %flex-col;
      width: 80%;
      margin-right: 20px;

      .header-info {
        display: flex;
        flex-direction: row;

        @include md-down {
          flex-direction: column;
          gap: 8px;
        }

        margin-bottom: 16px;

        .user-name {
          @extend %header-fancy;
          margin-right: 10px;
          font-size: 24px;
          font-weight: 600;
        }
      }

      .recommendation-header {
        @extend %label;
        font-size: 11px;
        font-weight: 500;
        margin-bottom: 5px;
        color: $darker;
      }

      .recommendation-sections {
        @extend %flex-col;
        gap: 20px;

        .recommendation-section {
          @extend %flex-col;

          .recommendation-reasoning-text {
            font-size: 12px;
            line-height: 18px;
            color: $darker;

            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;

            &.extended {
              -webkit-line-clamp: unset;
              display: unset;
            }
          }
        }
      }

      .recommendation-products {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .similar-product {
        @extend %flex-row;

        .product-image {
          border-radius: 10px;
          overflow: hidden;
          margin-right: 8px;

          img {
            object-fit: cover;
            height: 40px;
            aspect-ratio: 1;
          }
        }

        .product-info {
          @extend %flex-col-middle;
          font-size: 12px;
          color: $dark;
          flex: 1;

          .product-brand {
            @extend %label;
            font-size: 9px;
            margin-bottom: 2px;
          }
          .product-title {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }

    .recommendation-actions {
      @extend %flex-col;
      justify-content: space-between;
      width: 20%;

      .recommendation-actions-top {
        @extend %flex-col;
        gap: 8px;
      }

      .recommendation-action-btn {
        @include md-down {
          white-space: pre-wrap !important;
        }

        &.primary {
          @extend %btn-darkest-new;
          padding: 12px 22px;
        }

        &.secondary {
          @extend %btn-inverted-new;
          padding: 12px 22px;
        }

        &.hide-rec {
          @extend %btn-text-new;
          text-transform: none;
        }

        gap: 5px;
        text-transform: uppercase;
        font-size: 12px;
        padding: 12px 22px;
        font-weight: bold;
        white-space: nowrap;
      }
    }
  }
}
