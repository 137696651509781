@import '../../../styles/shared.scss';

.login-form-wrapper {
  @extend %flex-col-stretch;

  .auth-header {
    @extend %header-fancy-md;
    color: $darkest;
    margin-bottom: 16px;
  }

  form {
    @extend %flex-col;
    .input-container {
      @extend %flex-col;
    }
  }

  .login-input {
    @extend %input-shared;
    font-family: $font-body-new;
    height: 50px;
    padding-left: 30px;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .login-btn-container {
    position: relative;
    margin-top: 16px;

    .login-btn {
      @extend %btn-darkest;
      width: 100%;
      padding: 12px 0;
      font-size: 16px;

      &.loading {
        color: transparent;
      }
    }

    .loader {
      position: absolute;
      top: -12px;
      background: transparent;
    }
  }
}
