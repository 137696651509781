@import '../../../styles/shared.scss';

.lookbook-item-images-container {
  width: 100%;
  height: 100%;

  .lookbook-item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1;
    border-radius: 16px 16px 0 0;

    &.no-image {
      background-color: $light;
      display: grid;
      place-content: center;
      @extend %label;
      font-size: 0.8rem;
      color: $medium;
    }

    &.original {
      aspect-ratio: unset;
    }

    &.cover {
      object-fit: cover;
    }

    &.contain {
      object-fit: contain;
    }
  }

  .no-image {
    width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
    border-radius: 16px 16px 0 0;
  }

  &:hover {
    .arrows {
      opacity: 1;

      .arrow {
        pointer-events: all;
      }
    }
  }

  .dots {
    @extend %flex-center;
    gap: 4px;
    width: 100%;
    position: absolute;
    bottom: 12px;
    pointer-events: auto;

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $lighter;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      transform: scale(0.8);

      &.active {
        background-color: $darkest;
        transform: scale(1.2);
      }
    }
  }

  .arrows {
    @extend %flex-row-middle-spread;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 8px;
    pointer-events: none;
    transition: all 0.2s ease-in-out;

    @include md {
      opacity: 0;
    }

    .arrow {
      @extend %btn-basic;
      @extend %flex-center;
      border-radius: 24px;
      width: 40px;
      height: 40px;
      font-size: 16px;
      color: $white;
      background-color: $darkest;
      pointer-events: all;

      svg {
        transform: scaleY(1.2);
      }
    }
  }
}
