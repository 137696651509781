@import '../../styles/shared.scss';

.codes-modal {
  z-index: 101; // Always top

  .codes-modal-inner {
    height: auto !important;
    position: relative;
    top: auto;
    width: 65vw;

    .codes-modal-content {
      @extend %flex-row;
      flex-direction: row !important;
      padding: 0 !important;
      gap: 24px;

      .content-wrapper {
        @extend %flex-col;
        gap: 24px;
        flex: 1;
        padding: 24px;

        .content-header {
          font-weight: bold;
          font-size: 24px;
        }

        .body-sections {
          @extend %flex-col;
          gap: 16px;
          max-height: 75vh;
          overflow-y: scroll;
          padding: 2px;
        }

        .section {
          @extend %flex-col;

          .header-container {
            @extend %flex-row-middle;
            margin-bottom: 16px;

            .header {
              font-weight: bold;
              color: $darker;
              font-size: 16px;
            }

            .tooltip-outer-container {
              display: block !important;
              margin-left: 4px;
              height: 17px;
            }
          }

          .select-field {
            width: 100%;

            .title {
              font-size: 14px;
            }
          }

          .section-fields {
            @extend %flex-row-middle;
            justify-content: space-between;

            .section-field {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              position: relative;
              border: 1px solid $lighter;
              border-radius: 5px;
              padding: 10px;
              width: 48%;

              .field-label {
                text-wrap: nowrap;
                margin-right: 4px;
                color: $light;

                &.active {
                  color: $black;
                }
              }

              .field-input {
                flex-grow: 1;

                input {
                  @extend %input-shared;
                  text-align: right;
                  border: none;
                  padding-left: 0;
                  padding-right: 0;
                  width: 100%;
                  font-weight: bold;
                  font-size: 16px;
                  color: $black;

                  &:focus {
                    border: none;
                  }

                  &::placeholder {
                    color: $lighter;
                  }
                }
              }

              .apply-placeholder {
                @extend %flex-row-middle;
                @extend %btn-basic;
                @extend %label;
                gap: 2px;
                position: absolute;
                right: 0;
                bottom: 1px;
                right: 1px;
                font-size: 9px;
                font-weight: bold;
                padding: 2px 4px;
              }

              .preferred-format-explanation {
                gap: 2px;
                position: absolute;
                right: 0;
                top: calc(100% + 4px);
                right: 1px;
                font-size: 8px;
                color: $light;
                text-align: right;
              }

              @include xl-down {
                flex-direction: column;
                align-items: flex-start;

                .field-input {
                  input {
                    text-align: left;
                  }
                }
              }
            }
          }

          textarea {
            @extend %input-shared;
          }

          .radio-select-row {
            @extend %flex-row-middle;
            margin-bottom: 16px;
            margin-right: auto;

            span {
              cursor: pointer;
              margin-left: 8px;
            }

            &.disabled span {
              cursor: default;
              opacity: 0.5;
            }

            .code-type-radio-input {
              margin-top: 0;
              margin-right: 8px;
              cursor: pointer;
              accent-color: $primary;
              height: 20px;
              width: 20px;
            }

            &.disabled .code-type-radio-input {
              cursor: default;
            }
          }

          .subsection-expand {
            @extend %flex-col;
            margin-top: 12px;
            gap: 10px;
            border-radius: 10px;
            background-color: $offwhite;
            height: 0;
            transition: height 0.3s ease-out;

            &.expanded {
              overflow: visible;
              padding: 25px;
              height: 150px;
            }

            .subheader {
              font-weight: bold;
              color: $darkest;
            }

            .sublabel {
              font-size: 12px;
              color: $medium;
            }

            .infinite-scroll-select__control {
              border: none;
            }

            .infinite-scroll-select__value-container {
              font-size: 14px;
            }
          }

          .warning-label {
            font-size: 12px;
            color: $error;
            margin-bottom: 8px;
          }
        }

        .actions {
          @extend %flex-row-middle;
          align-self: flex-start;
          gap: 12px;

          .action {
            font-size: 12px;

            &.primary {
              @extend %btn-darkest-new;
            }

            &.secondary {
              @extend %btn-outline-new;
            }

            &.disabled {
              opacity: 0.5;
              cursor: not-allowed;

              &:hover {
                opacity: 0.5;
              }
            }
          }
        }

        .text-section {
          @extend %flex-col;
          gap: 4px;
          font-size: 14px;
          color: $medium;

          .header {
            font-weight: bold;
            color: $darker;
            font-size: 16px;
          }

          .text-section-list {
            @extend %flex-col;
            color: $darker;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;

            .text-section-list-item {
              @extend %flex-row-middle;
              gap: 4px;

              input {
                margin-left: 0;
              }

              &.disabled {
                opacity: 0.5;
              }
            }
          }
        }
      }

      .codes-modal-templates {
        background-color: $offwhite;
        padding: 24px;
        max-width: 320px;
      }
    }
  }
}
