@import '../../../../styles/shared.scss';

.social-sharing-big-pin-outer-container {
  .title-container {
    margin-top: 12px;
    text-align: center;
  }

  .big-pin-container {
    width: 100%;
    position: relative;

    .pin-image {
      margin: auto;
      aspect-ratio: 1;
      width: 100%;
      object-fit: contain;
      border-radius: 10px;
    }
  }
}
