@import '../../styles/shared.scss';

.partner-portal-request-outer {
  position: relative;
  cursor: pointer;

  .full-image-overlay-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @include md-down {
      display: flex;
    }

    .full-image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .partner-portal-request-inner {
    padding: 10px;
    height: 150px;
    width: 100%;
    border: $border-lightest;
    border-radius: 16px;
    position: relative;
    color: white;

    .dismiss-container {
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;
      z-index: 1;

      svg {
        height: 15px;
        width: 15px;
        color: white;
        opacity: 0.5;
        transition: opacity 0.2s ease-out;

        &:hover {
          opacity: 1;
        }
      }
    }

    .text-container {
      padding: 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      @extend %flex-row;
      justify-content: space-between;
      align-items: flex-end;
      gap: 5px;
      width: 100%;

      .request-details {
        @extend %flex-col;

        .request-heading {
          font-size: 1.5rem;
          font-weight: 600;
        }

        .request-subheading {
          @extend %flex-row;
          gap: 5px;
          font-size: 0.7rem;

          @include md-down {
            flex-direction: column;

            .spacer {
              display: none;
            }
          }
        }
      }

      .request-status-details {
        > div {
          @extend %flex-row;
          gap: 4px;
          align-items: center;
          // font-size: 0.7rem;
          font-size: 0.6rem;
          font-weight: 600;

          svg {
            height: 12px;
            width: 12px;
          }
        }

        .accepted-status-container {
          padding: 5px 10px;
          background-color: white;
          color: $primary;
          border-radius: 500px;
        }

        .view-request-container,
        .lookbook-status-container {
          @extend %label;
        }

        .actions {
          @extend %flex-row;
          gap: 5px;

          .action {
            @extend %btn-primary-new;

            svg {
              height: 10px;
              width: 10px;
            }
          }
        }
      }
    }
  }
}
