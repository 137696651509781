@import '../../../styles/shared.scss';

.bulk-talent-modal-talent {
  @extend %flex-row-middle-spread;
  position: relative;
  background: white;
  border-radius: 16px;
  padding: 8px;
  height: 56px;
  overflow: hidden;
  margin-bottom: 8px;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, height 0.25s ease-in-out;

  &:hover {
    .disabled-overlay {
      opacity: 0;
    }
  }

  &.success {
    height: 24px;
    border: $border-lightest;
    border-radius: 4px;
    margin-bottom: 4px;

    .main {
      .image-container {
        opacity: 0;
        pointer-events: none;
      }

      .meta {
        margin-left: -52px;

        &.talentIsNotOnPlatform {
          margin-left: 0;
        }

        .name {
          @extend %btn-basic;
          font-weight: normal;
          font-size: 10px;

          svg {
            margin-right: 4px;
            font-size: 9px;
          }
        }

        .email {
          font-size: 0.7rem;
          color: $dark;
          display: none;
        }

        .stats-and-actions,
        .error {
          display: none;
        }
      }
    }
  }

  .main {
    @extend %flex-row-middle;

    .image-container {
      height: 40px;
      width: 40px;
      margin-right: 12px;
      border-radius: 12px;
      color: white;
      font-size: 18px;
      font-family: $font-header;
      font-weight: bold;
      border-radius: 8px;
      overflow: hidden;

      img,
      .empty {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .empty {
        @extend %flex-center;
        background: $darkest;
      }
    }
    .meta {
      &.talentIsNotOnPlatform {
        padding-left: 10px;
      }

      .name {
        @extend %flex-row-middle;
        font-size: 14px;
        color: $darkest;
        font-weight: bold;

        span.off-platform {
          margin-left: 4px;
          font-size: 10px;
          color: $medium;
          font-weight: normal;
        }
      }

      .email {
        font-size: 0.7rem;
        color: $dark;
      }

      .stats-and-actions {
        @extend %flex-row-middle;
        gap: 4px;

        .stat {
          font-size: 10px;
          color: $dark;
          text-transform: uppercase;
        }
        .action {
          @extend %btn-basic;
          font-size: 10px;
          color: $dark;
          text-transform: uppercase;
        }
      }
      .error {
        font-size: 11px;
        color: $error;
        font-weight: bold;
      }
    }
  }
  .action-btn {
    @extend %btn-basic;
    padding: 4px 16px;
    position: relative;

    svg {
      margin-right: -4px;
    }

    .loading-container {
      position: absolute;
      right: -4px;
      top: -20px;
    }

    &.success {
      color: $primary;
      font-size: 12px;
    }
  }

  .disabled-overlay {
    @extend %flex-center;
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    height: 100%;
    transition: opacity 0.2s ease-in-out;

    .message {
      @extend %label;
      padding: 2px 8px;
      border-radius: 4px;
      font-size: 10px;
      font-weight: bold;
      background: $offwhite;
      color: $darkest;
      border: $border-lightest;
    }
  }
}
