@import '../../styles/shared.scss';

.landing-examples-panel-outer-container {
  @extend %flex-col-center;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-top: 10px solid white;
  color: white;
  background: $darker;
  font-family: $font-body-new;

  .landing-examples-panel-container {
    @extend %flex-col-center;
    width: 100%;
    max-width: 1240px;
    position: relative;
    height: 100%;
    padding: 64px 24px 120px;

    @include md {
      padding: 72px 24px 120px;
    }

    @include xl {
      padding: 100px 0 164px;
    }

    .landing-examples-header-container {
      @extend %flex-col-center;
      margin: 0 0 64px;

      h3 {
        font-size: 20px;
        line-height: 1.15;
        text-align: left;
        max-width: 900px;
        margin: 12px 0 12px;
        letter-spacing: -0.4px;
        text-align: center;

        @include md {
          font-size: 32px;
          margin: 0 0 22px;
        }
      }

      @include md {
        margin: 24px 0 36px;
      }

      @include xl {
        margin: 24px 0 48px;
      }
    }

    .examples-container {
      @extend %flex-center-vertical;
      width: 100%;

      @include md {
        flex-direction: row;
      }

      .example-container {
        &:not(:last-child) {
          margin-bottom: 48px;
        }

        @include md {
          flex-direction: row;
          margin-bottom: 0;
          min-width: inherit;

          &:not(:last-child) {
            margin-right: 48px;
            margin-bottom: 0;

            @include lg {
              margin-right: 104px;
            }
          }
        }

        .link-wrapper {
          @extend %flex-col-center;
          text-decoration: none;
          color: white;

          &:hover {
            img.artist-img {
              transform: scale(1.02) translateY(-4px);
            }

            .data-container {
              text-decoration: underline;
            }
          }

          img.artist-img {
            width: 200px;
            height: 200px;
            border-radius: 150px;
            z-index: 1;
            transition: all 0.3s ease-in-out;

            @include md {
              width: 160px;
              height: 160px;
              border-radius: 120px;
            }

            @include lg {
              width: 240px;
              height: 240px;
              border-radius: 120px;
            }
          }

          .data-container {
            @extend %flex-col-center;
            color: white;

            .name-container {
              @extend %flex-row-middle;
              margin-top: 16px;

              @include md {
                margin-top: 36px;
              }

              .name {
                font-size: 20px;
                line-height: 1;
                white-space: nowrap;

                @include md {
                  font-size: 18px;
                }
                @include lg {
                  font-size: 22px;
                }
              }
              .seperator {
                font-size: 16px;
                line-height: 1;
                margin: 0 8px;
                color: $medium;
                white-space: nowrap;
                @include md {
                  font-size: 14px;
                  margin: 0 4px;
                }
                @include lg {
                  font-size: 18px;
                  margin: 0 8px;
                }
              }
              .tagline {
                font-size: 14px;
                line-height: 1;
                text-transform: uppercase;
                @include md {
                  font-size: 12px;
                }
                @include lg {
                  font-size: 16px;
                }
              }
            }
            .joined {
              margin-top: 4px;
              font-size: 13px;
              @include md {
                font-size: 12px;
              }
              @include lg {
                font-size: 14px;
              }
            }
          }
        }

        .social-links {
          @extend %flex-center;
          margin: 0 auto;
          margin-top: 12px;

          .social-link {
            @extend %flex-row-middle;
            color: $white;
            filter: brightness(0) invert(1);
            text-decoration: none;
            margin-left: 4px;

            img {
              height: 16px;
              margin-right: 2px;

              @include md {
                height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
