@import '../../../styles/shared.scss';

.opportunity-leaderboard-card-inactive {
  @extend %flex-row-middle;
  background-color: white;
  background-color: $white;
  border-radius: 10px;
  transition: border 0.2s ease;
  border: 2px solid transparent;
  padding: 12px 30px;

  &.dismissed,
  &.expired {
    background-color: $offwhite;
    padding: 4px 30px;
  }

  .media-container {
    @extend %flex-row;
    position: relative;
    height: 60px;
    width: 60px;
    margin-right: 16px;

    .user-image-container {
      @extend %btn-basic;
      @extend %flex-center;
      position: relative;
      width: 60px;
      height: 60px;
      border-radius: 50%;

      &.dismissed,
      &.expired {
        img {
          filter: grayscale(100%);
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .main-container {
    @extend %flex-row-spread;
    flex: 1;
    gap: 24px;

    .user-info {
      @extend %flex-col-middle;

      .section-data {
        @extend %flex-col;

        .name {
          @extend %btn-basic;
          font-size: 18px;
          font-weight: bold;
          color: $darkest;

          // Overflow with ellipsis
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .metadata {
          font-size: 12px;
          color: $dark;
        }
      }
    }

    .actions {
      @extend %flex-row-middle;
      gap: 12px;

      .action {
        @extend %btn-darkest-new;
        font-size: 12px;
        padding: 8px 24px;

        &.icon {
          padding: 8px 8px;
          background: white;
          color: $darkest;
          border: none;
          font-size: 15px;

          &.inactive {
            svg {
              color: $lighter;
            }
          }
        }

        &.primary {
          background-color: $primary;
          border-color: $primary;
        }

        &.secondary {
          background-color: $white;
          color: $dark;
          border-color: $lightest;
        }

        &.tracking-ended {
          background-color: $lightest;
          border-color: $lightest;
          color: $light;
        }
      }
    }
  }
}
