@import '../../styles/shared.scss';

.simulated-user-control-outer-container {
  @extend %flex-row-middle;
  gap: 2px;
  position: fixed;
  bottom: 10px;
  left: 10px;
  background: white;
  z-index: 1000;
  border-radius: 8px;
  padding: 2px 8px;
  border: $border-dark;
  font-size: 12px;
  font-weight: bold;
  color: $darkest;
  font-family: $font-body;

  .username {
    @extend %btn-basic;

    &:hover {
      color: $dark;
    }
  }

  .mode-toggle {
    @extend %btn-basic;

    &:hover {
      color: $primary;
    }
  }
}
