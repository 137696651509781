@import '../../../styles/shared.scss';

.talent-report-audience-stats {
  .stats-row {
    &.blocked {
      filter: blur(2px);
    }

    .bar-container {
      @extend %flex-row-middle;
      .bar {
        flex: 1;
        height: 6px;
        background-color: $offwhite;
        border-radius: 3px;
        position: relative;
        overflow: hidden;

        .active-bar {
          position: absolute;
          height: 100%;
          background-color: $darkest;
        }
      }
      .percentage {
        @extend %label;
        background-color: $darkest;
        color: white;
        font-size: 10px;
        padding: 4px 12px;
        border-radius: 4px;
        margin-left: 12px;
      }
    }
    .display {
      margin-top: -4px;
      font-size: 12px;
      color: $darkest;
    }
  }
}
