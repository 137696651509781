@import '../../../styles/shared.scss';

.onboarding-panel-terms-container {
  .policy-rows {
    @extend %flex-col;
    margin-top: 28px;
    border: 1px solid $lightest;
    border-radius: 8px;
    padding: 12px;
  }

  .policy-review-row {
    @extend %flex-col;
    padding: 12px;
    gap: 12px;
    position: relative;

    .new-policy-marker {
      position: absolute;
      right: 12px;
      top: 12px;
      font-style: italic;
    }

    .open-policy-container {
      .policy-name {
        font-weight: bold;
      }

      a {
        @extend %link;
        color: $primary;
      }
    }
  }
}
