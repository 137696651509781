@import '../../../styles/shared.scss';

.listing-payment-tiers-container {
  .public-but-no-tiers-warning {
    @extend %flex-col;
    align-items: flex-start;
    background-color: $offwhite;
    border-radius: 10px;
    padding: 24px;

    .warning {
      font-size: 14px;
      color: $darker;
      margin-bottom: 16px;
    }

    .edit-btn {
      @extend %btn-darker-new;
      font-size: 12px;
      padding: 8px 16px;
    }
  }

  .opportunity-payment-tiers-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-radius: 10px;
    border: $border-lightest;
    border-bottom: none;
    overflow: hidden;

    &.cols-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    .cell {
      @extend %label;
      padding: 8px 12px;
      text-align: center;
      font-size: 11px;
      background-color: $white;
      border-bottom: $border-offwhite;
      font-weight: 500;

      &.header {
        background-color: $lightest;
        color: $darkest;
        font-weight: bold;
      }

      .input-wrapper {
        input {
          @extend %label;
          @extend %input-inline;
          font-size: 12px;
          text-align: center;
          background-color: transparent;

          &::placeholder {
            color: $lighter;
          }
        }
      }

      .actions {
        @extend %flex-center;

        .action {
          @extend %btn-darker-new;
          padding: 4px 8px;
          font-size: 10px;
          margin: 0 4px;

          &.disabled {
            opacity: 0.2;
            cursor: default;
          }
        }
      }
    }
  }

  .add-new-tier {
    @extend %btn-prettymuchwhite-new;
    position: relative;
    border: $border-lightest;
    margin-top: 8px;
    padding: 12px 16px;
    font-size: 12px;

    &:hover {
      border-color: $light;
    }

    &.needs-tier {
      background-color: $primary;
      color: white;
    }

    &.adding {
      color: transparent;
      border: $border-lighter;
    }
    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    svg {
      margin-right: 8px;
      font-size: 10px;
    }
  }
}
