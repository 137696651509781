/*
  Use these mixins to adapt the styling for various screen sizes.

  In general, we want to develop for mobile phones, and adjust the styling
  upwards as we scale to larger screens. This keeps us in the mobile first
  mentality, a more common practice than the desktop first approach.

  Example:
    .container {
        background: red;    
        @include sm { background: orange; }
        @include md { background: yellow; }
        @include lg { background: green; }
        @include xl { background: blue; }
    }

  ** We recommend to start by designing for mobile, then copy pasting the above into the 
  container to help yourself debug and craft the experience for larger screen sizes.

  *** If designing for brands, you can instead design for desktop, then use the *-down breakpoints
  in order to specify mobile designs in a cleaner manner
*/

$screen-xs-min: 400px !global; // Most phones
$screen-sm-min: 576px !global; // Small tablets and large smartphones (landscape view)
$screen-md-min: 768px !global; // Small tablets (portrait view)
$screen-lg-min: 992px !global; // Tablets and small laptops
$screen-xl-min: 1240px !global; // Large tablets and laptops
$screen-xxl-min: 1600px !global; // Large laptops and desktops

@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}
@mixin xs-down {
  @media (max-width: #{$screen-xs-min}) {
    @content;
  }
}

@mixin sm-down {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md-down {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg-down {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl-down {
  @media (max-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin xxl-down {
  @media (max-width: #{$screen-xxl-min}) {
    @content;
  }
}

@mixin custom_breakpoint($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}
