@import '../../styles/shared.scss';

.select-application-type-container {
  @extend %flex-row;

  @include md-down {
    flex-direction: column;
  }

  .panel {
    flex: 1;
    padding: 40px 20px;

    @include sm {
      padding: 56px 40px;
    }
    @include md {
      padding: 80px 40px;
    }
    &.left {
    }
    &.right {
      background: $offwhite;
    }

    .header {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 8px;
      font-family: $font-header;
      font-weight: 500;

      @include xl {
        font-size: 56px;
        line-height: 60px;
        margin-bottom: 24px;
      }
    }

    .subheader {
      font-size: 16px;
      line-height: 24px;
    }

    .actions {
      @extend %flex-row;
      margin-top: 24px;

      @include xl {
        margin-top: 56px;
      }
    }
  }
}
