@import '../../../styles/shared.scss';

.section-template-container {
  aspect-ratio: 0.75;
  border: $border-lightest;
  position: relative;
  border-radius: 16px;
  background: white;
  transition: border 0.2s ease-in-out;
  overflow: hidden;

  &.selected {
    cursor: default;
    border: $border-primary;

    &:hover {
      border: $border-primary;
    }
  }

  &:hover {
    cursor: pointer;
    border: $border-dark;
  }

  @include md-down {
    width: calc(100%);
    min-width: 200px;
  }

  @include md {
    width: calc(50% - 12px); // Keep in sync with gap
  }

  @include lg {
    width: calc(33% - 12px); // Keep in sync with gap
  }

  @include xl {
    width: calc(33% - 12px); // Keep in sync with gap
  }

  .section-template-header-container {
    min-height: 64px;
    padding: 20px 16px;
    .section-template-header {
      font-family: $font-header;
      font-size: 18px;
      color: $darkest;
      font-weight: bold;
    }
    .section-template-subheader {
      font-size: 11px;
      color: $dark;
    }
  }

  .selected-badge {
    @extend %flex-center;
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 4px;
    border-radius: 12px;
    height: 18px;
    width: 18px;
    background: $primary;

    svg {
      font-size: 10px;
      color: white;
    }
  }
}
