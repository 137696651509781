@import '../../../styles/shared.scss';

.brand-lookbook-outer {
  font-family: $font-body-new;
  width: 100%;
  min-height: 100vh;

  .brand-lookbook-inner {
    margin: 0 auto;

    .gifting-portal-header {
      font-size: 3rem;
      font-weight: 600;
      font-family: $font-header;
      margin: 1.5rem 0;
    }

    .clickable {
      @extend %btn-basic;
    }
  }
}
