@import '../../styles/shared.scss';

.artist-modal-controls {
  margin-top: 24px;
  @extend %flex-col;

  input {
    @extend %input-inline;
    font-size: 14px;
  }

  .action {
    background: white;
    font-size: 14px;
  }

  .duration-action {
    .loader {
      position: absolute;
      top: 0;
      left: 0;
      max-height: 100%;
      transform: translateY(-12px);
    }

    &.loading,
    &.saving {
      pointer-events: none;
      color: transparent !important;
      border: $border-lightest !important;
    }
  }

  .actions {
    @extend %flex-col;
    gap: 12px;
    width: 100%;

    @include md-down {
      flex-direction: column;
    }

    .commission {
      @extend %flex-row-middle;
      padding: 0 12px;
      border: $border-dark;
      border-radius: 4px;
      position: relative;

      &.has-value {
        .percentage-container {
          color: $dark;
        }
      }

      .save-btn {
        @extend %flex-center;
        @extend %label;
        position: absolute;
        height: 100%;
        background: $darkest;
        color: white;
        right: 0;
        transition: all 0.3s ease-in-out;
        transform: translateX(48px);
        width: 60px;
        opacity: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: none;

        &.visible {
          transform: translateX(0);
          opacity: 1;
        }
      }

      .label {
        flex: 1;

        &.btn {
          @extend %btn-darkest-new;
        }
      }

      .rate {
        @extend %flex-row-middle;
        transition: all 0.3s ease-in-out;

        &.needs-save {
          transform: translateX(-60px);
        }

        input {
          padding: 8px 0 8px 12px;
          max-width: 48px;
          text-align: center;
        }

        .percentage-container {
          @extend %flex-col-middle;
          color: $medium;
          .percentage {
            // padding-right: 12px;
          }
        }
      }
    }

    .custom-code,
    .custom-rate {
      @extend %btn-basic;
      border: $border-dark;
      padding: 8px 12px;
      border-radius: 4px;
      font-size: 14px;
      position: relative;

      &.has-code {
        @extend %flex-row-middle-spread;
        padding: 8px 12px;
      }

      svg {
        margin-left: 8px;
        font-size: 12px;
      }

      .custom-code-label {
        max-width: 80%;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .custom-code-label-multiple {
        border: 1px solid black;
        border-radius: 11px;
        padding: 4px;
        font-size: 10px;
        font-weight: bold;
      }
    }

    .gifting {
      @extend %btn-basic;
      border: $border-dark;
      padding: 8px 16px;
      border-radius: 4px;
      font-size: 14px;
      position: relative;

      &.progress {
        pointer-events: none;
        border: $border-lighter;
        color: $light;
        text-align: center;
      }

      &.accepted {
        pointer-events: none;
        background: $prettymuchwhite;
        border: $border-lightest;
        color: $light;
        text-align: center;
      }
    }

    .first-action-btn {
      @extend %label;
      text-align: center;
      color: $white;
      background: $darkest;
      font-weight: bold;
    }
  }

  .save-btn {
    @extend %btn-basic;
    background: transparent;
    padding: 8px 16px;
    border-radius: 4px;
    position: relative;
    color: $white;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;

    &.needs-save {
      background: $white;
      color: $darkest;
    }
  }
}
