@import '../../styles/shared.scss';

.brand-products-outer-container {
  .brand-products {
    @extend %flex-row;
    flex-wrap: wrap;
    gap: 12px;
    max-width: 800px;

    .product {
      padding: 12px;
      width: 100%;

      @include md {
        width: calc(50% - 12px / (2 / 2));
      }

      @include xl {
        width: calc(33% - 12px / (3 / 2));
      }

      &.empty {
        background-color: $offwhite;
        border-radius: 10px;
        min-width: 200px;
        min-height: 200px;
      }

      .image-container {
        width: 100%;
        aspect-ratio: 1;
        background-color: white;
        border-radius: 10px;
        padding: 30px;
        position: relative;

        img {
          @extend %animation-fade;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }

        .external-link {
          position: absolute;
          bottom: 0px;
          right: 0px;
          padding: 8px;
          color: $medium;
          font-size: 12px;
        }
      }

      .data {
        padding: 12px 10px;

        .title {
          font-size: 16px;
          color: $darker;
          text-align: center;
        }
      }
    }
  }
}
