@import '../../styles/shared.scss';

.bulk-talent-modal-sidebar {
  @extend %flex-col;
  .header-controls {
    @extend %flex-row-middle;
    gap: 8px;
  }

  .talent-results {
    margin-top: 12px;
    @extend %flex-col;

    .show-more-container {
      display: flex;
      justify-content: center;

      .show-more {
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: $white;
        font-size: 12px;
        border: 1px solid $lighter;
        border-radius: 100px;
        padding: 8px 16px;
        cursor: pointer;

        .show-more-count {
          font-size: 10px;
          background-color: $darkest;
          color: $white;
          padding: 2px 8px;
          border-radius: 100px;
        }
      }
    }
  }

  .empty-results {
    @extend %flex-center;
    margin-top: 12px;
    padding: 24px;
    background-color: $white;
    border-radius: 10px;

    .message {
      text-transform: uppercase;
      font-size: 11px;
      color: $medium;
    }
  }

  .invite-off-platform-talent {
    display: grid;
    place-content: center;
    padding: 24px;
    border-radius: 10px;
    background-color: $white;
    margin-top: 12px;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }

    .message {
      text-transform: uppercase;
      font-size: 11px;
      color: $medium;

      svg {
        margin-right: 4px;
        height: 10px;
        width: 10px;
      }
    }
  }
}
