@import '../../styles/shared.scss';

.safe-image-container {
  &.use-generic-styles {
    height: 100%;
    width: 100%;
  }

  &.no-image {
    @extend %label;
    color: $medium;
    display: grid;
    place-content: center;
    background-color: $light;
  }
}
.no-image-default {
  @extend %label;
  color: $medium;
  display: grid;
  place-content: center;
  background-color: $light;
}
