@import '../../styles/shared.scss';

.list-header-action-btns {
  @extend %flex-col;
  align-items: flex-end;
  gap: 12px;

  @include md-down {
    align-items: flex-start;
  }

  .action-btns {
    @extend %flex-row-middle;
    gap: 8px;

    .action-btn {
      @extend %label;
      font-size: 10px;
      padding: 8px 12px;

      &.primary {
        @extend %btn-primary;
      }

      &.secondary {
        @extend %btn-white;
      }
    }
  }

  .radio-btn,
  .select-row {
    @extend %flex-row-middle;

    .label {
      @extend %label;
      font-size: 10px;
      margin-right: 8px;
    }

    .shopmy-select {
      min-width: 180px;
      z-index: 3;
    }

    .sort-direction-icons {
      margin-left: 8px;
    }
  }
}
