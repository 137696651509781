@import '../../styles/shared.scss';

.brand-modal-outer-container {
  .brand-modal-inner-container {
    @include md {
      width: 90vw;
      height: 90vh;
      top: 5vh;
      max-width: 1024px;
      min-height: 320px;
    }

    .brand-modal-content {
      @extend %flex-col-center;
      padding: 48px !important; // Override outer modal
      background-color: $prettymuchwhite;
      gap: 36px;

      .brand-modal-section-header {
        @extend %label;
        font-size: 12px;
        color: $dark;
        margin-bottom: 24px;
        text-align: center;
      }
    }
  }
}
