@import '../../../styles/shared.scss';

.recommended-talent-card {
  @extend %flex-row;
  border: $border-lightest;
  border-radius: 16px;
  background: $white;
  position: relative;
  overflow: hidden;

  &.blocked {
    .body,
    img {
      filter: blur(3px);
    }

    .blocked-by-package-cover {
      @extend %flex-center;
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 5;
    }
  }

  .image-container {
    position: relative;
    height: 100px;

    &.big {
      height: 120px;
    }

    img,
    .empty-img {
      @extend %animation-fade;
      object-fit: cover;
      height: 100%;
      aspect-ratio: 1;
      background: $offwhite;
      border-radius: 10px 0px 0px 10px;
    }

    .empty-img {
      @extend %flex-center;
      background: $darkest;
      font-family: $font-header;
      font-size: 48px;
      color: white;
      font-weight: bold;
    }

    .actions {
      @extend %flex-row-middle;
      position: absolute;
      top: 5px;
      right: 5px;
      gap: 4px;
      color: $prettymuchwhite;

      .action {
        @extend %btn-basic;
        color: $prettymuchwhite;

        svg {
          color: white;
        }
      }
    }
  }

  .body {
    @extend %flex-col;
    flex: 1;
    position: relative;

    .body-inner {
      @extend %flex-row-middle-spread;
      flex: 1;
      box-sizing: border-box;
      padding: 0px 10px;

      .main {
        flex: 1;

        .content {
          @extend %flex-row-middle;
          width: 100%;

          .info {
            &.simple {
              width: 100% !important;
            }

            padding: 10px 12px 10px 8px;
            width: 140px;

            @include lg {
              width: 160px;
            }
            @include xl {
              width: 180px;
            }

            .badge {
              @extend %label;
              font-size: 9px;
              color: $darkest;
              margin-bottom: 2px;
            }
            .name {
              font-family: $font-header;
              font-size: 15px;
              color: $darkest;
              font-weight: bold;
              text-decoration: underline;
              cursor: pointer;
              &:hover {
                opacity: 0.8;
              }
            }
            .description {
              margin-top: 3px;
              font-family: $font-body;
              font-size: 11px;
              line-height: 15px;
              color: $dark;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              &.full {
                -webkit-line-clamp: 30;
              }
            }
            .open-card-btn {
              @extend %btn-basic;
              margin-top: 4px;
              font-size: 8px;
              text-transform: uppercase;
              color: $dark;

              svg {
                transform: translateY(-1px);
                font-size: 6px;
                margin-left: 4px;
              }
            }

            .social-counts {
              margin-top: 12px;
              @extend %flex-row-middle;
              .social-count {
                @extend %flex-row-middle;
                margin-right: 8px;
                text-decoration: none;
                img {
                  height: 11px;
                  margin-right: 4px;
                }

                .count {
                  text-transform: uppercase;
                  font-size: 10px;
                  color: $dark;
                }
              }
            }

            .feedback-container {
              @extend %flex-col;
              margin-top: 10px;
              gap: 10px;
              flex: 1 2;
              font-size: 10px;

              .feedback-result {
                @extend %flex-col;
                max-width: 200px;
                cursor: pointer;

                .feedback-message {
                  color: $light;
                  max-width: 100px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  position: relative;
                  cursor: pointer;
                }
              }

              .feedback-options {
                @extend %flex-row-middle;
                gap: 2px;

                svg {
                  @extend %btn-basic;
                  color: $dark;
                }
              }
            }
          }
        }
      }
      .stats {
        padding: 0 0px;
        margin-left: 16px;
        flex: 1;
      }
      .actions {
        @extend %flex-col;
        padding: 12px;
        font-size: 8px;
        color: $darkest;
        margin-bottom: 4px;
        text-align: right;

        &.details {
          width: 160px;
          max-width: 160px;
        }
      }
    }

    .add-comment-btn {
      @extend %btn-basic;
      position: absolute;
      bottom: 4px;
      right: 4px;
      font-size: 8px;
      color: $lighter;
      text-transform: uppercase;
    }
  }
}
