@import '../../../../styles/shared.scss';

.lookbook-product-modal-results-outer {
  width: 100%;
  .lookbook-product-modal-results-inner {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-content: space-evenly;
    position: relative;
    column-gap: 15px;
    row-gap: 40px;
    padding-top: 50px;
    transition: opacity 0.5s ease-out;

    &.searching {
      opacity: 0.4;
    }

    .skeleton {
      width: 100%;
      min-height: 300px;
      height: fit-content;
      border: $border-lightest;
      border-radius: 16px;
      display: grid;
      place-items: center;
    }

    .lookbook-product-modal-results-loading-container {
      max-height: 100px;
      grid-column: 1 / -1;
    }
  }
}
