@import '../../styles/shared.scss';

.social-count-pills {
  @extend %flex-row-middle;
  gap: 8px;

  .social-count {
    @extend %btn-basic;
    @extend %flex-row-middle;
    text-decoration: none;
    border: 1px solid $lighter;
    border-radius: 15px;
    padding: 3px 8px;

    img {
      margin-right: 4px;
    }

    .count {
      @extend %label;
      color: $darker;
      font-size: 10px;
      font-weight: normal;
    }
  }
}
