@import '../../../styles/shared.scss';

.discover-add-feature-list-container {
  @extend %flex-col;
  color: white;
  gap: 12px;

  .select {
    min-width: 200px;

    .select__control {
      background-color: transparent;
      height: 24px;
      min-height: 24px;

      .select__multi-value {
        background-color: $dark;
        border: $border-dark;
        border-radius: 2px; // Adjust the pill border radius
        margin: 2px; // Adjust the margin between pills

        .select__multi-value__label {
          @extend %label;
          font-size: 10px;
          padding: 2px 2px;
          color: white;
        }
      }

      .select__value-container {
        @extend %flex-row;
        align-items: flex-start;
        padding: 0;
        height: 24px;
        min-height: 24px;

        .select__placeholder {
          @extend %label;
          font-size: 10px;
          padding: 2px 2px;
          color: $lighter;
        }

        [class*='-Input'] {
          margin: 0;
          padding: 0;
          height: 24px;
          min-height: 24px;

          .select__input input {
            @extend %label;
            text-align: left;
            font-size: 10px !important;
            padding: 2px 2px;
            color: $lighter !important;
          }
        }
      }

      .select__indicators {
        height: 24px;

        .select__indicator-separator {
          display: none;
        }
        .select__dropdown-indicator,
        .select__clear-indicator {
          padding: 4px;
        }
      }
    }

    .select__single-value {
      color: white;
      font-size: 12px;
    }
  }
}
