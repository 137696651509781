@import '../../styles/shared.scss';

.app-promotion-banner-outer-container {
  @extend %flex-center;
  position: absolute;
  width: 100%;
  z-index: 10;
  padding: 24px 20px 48px;
  pointer-events: none;
  top: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));

  .app-promotion-banner-inner-container {
    @extend %flex-row-middle;
    position: relative;
    padding: 12px 12px;
    border-radius: 16px;
    width: 100%;
    background: white;
    box-shadow: $shadow-dark;
    pointer-events: auto;

    .image-container {
      margin-right: 12px;

      img {
        width: 54px;
        height: 54px;
        border-radius: 8px;
        object-fit: contain;
      }
    }

    .main {
      @extend %flex-row-middle-spread;
      flex: 1;

      .data {
        .name {
          font-size: 14px;
          font-weight: bold;
          color: $darkest;
        }
        .developer {
          font-size: 10px;
          color: $dark;
        }
        .stars {
          @extend %flex-row-middle;
          margin: 4px 0 4px;
          svg {
            font-size: 8px;
          }
        }
        .app-store {
          font-size: 10px;
          color: $dark;
        }
      }
      .action {
        .view-btn {
          @extend %btn-darkest-new;
          @extend %label;
          padding: 4px 16px;
          font-size: 14px;
        }
      }
    }

    .close-btn {
      @extend %btn-basic;
      position: absolute;
      padding: 8px;
      top: 0;
      right: 0;

      svg {
        font-size: 16px;
      }
    }
  }
}
