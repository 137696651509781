@import '../../../../styles/shared.scss';

.lookbook-report-mentions-outer {
  .lookbook-report-mentions-inner {
    position: relative;

    .view-more-button {
      @extend %btn-basic;
      font-weight: 400;
      padding: 20px 0;
      grid-column: 1 / -1;
      text-align: center;
      font-size: 0.9rem;
      color: black;
      text-decoration: underline;
      background-color: white;
      margin-top: 20px;
    }

    .mention-results-columns {
      @extend %flex-row;
      flex-wrap: wrap;
      width: 100%;
      gap: 16px;

      .mention-results-column {
        @extend %flex-col;
        flex: 1;
        width: calc(25% - 12px);
        gap: 16px;

        @include md-down {
          &:last-child {
            display: none;
          }
        }
      }
    }

    .lookbook-report-mention-button-container {
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(calc(-100% - 25px));
      @extend %flex-row;
      justify-content: flex-end;
      gap: 10px;

      @include md-down {
        display: none;
      }

      .lookbook-report-download-button {
        @extend %btn-basic;
        @extend %label;
        background-color: black;
        color: white;
        font-size: 0.7rem;
        padding: 14px 16px;
      }
    }
  }
}
