@import '../../styles/shared.scss';

.places-autocomplete-loader-container {
  .autocomplete-container {
    position: relative;

    input {
      @include md-down {
        font-size: 16px !important;
      }
    }

    .autocomplete-dropdown-container {
      @extend %flex-col;
      gap: 5px;
      position: absolute;
      background-color: $offwhite;
      border-radius: 5px;
      border: $border-lightest;
      width: 100%;
      z-index: 1000;
      max-height: 200px;
      overflow-y: scroll;
      padding: 10px 0;
      top: calc(100% + 10px);
      transition: opacity 0.2s ease-out;
      padding: 5px;

      &.loading {
        opacity: 0.5;
      }

      .autocomplete-item {
        padding: 6px 12px;
        font-size: 0.7rem;
        cursor: pointer;
        opacity: 0.6;
        transition: opacity 0.2s ease-out;
        background-color: white;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
