@import '../../../styles/shared.scss';

.hero-features-wrapper {
  position: relative;

  .hero-features {
    @extend %flex-row-middle;
    width: 100%;
    height: 360px;
    overflow: auto;
    gap: 48px;

    &.editing {
      max-width: 720px;
    }
  }

  .nav-icn {
    @extend %btn-shared;
    @extend %flex-center;
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 2;
    color: white;
    opacity: 0 !important;
    transition: opacity 0.2s ease-in-out;

    &.visible {
      opacity: 1 !important;
    }

    svg {
      font-size: 20px;
    }

    &.right {
      right: 0;
      padding: 0 12px 0 4px;
    }
    &.left {
      left: 0;
      padding: 0 4px 0 12px;
    }
  }

  .dots {
    @extend %flex-center;
    margin-top: 12px;

    .dot-wrapper {
      cursor: pointer;
      padding: 2px;

      &:hover:not(.active) {
        opacity: 0.8;
      }

      .dot {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background-color: white;
        transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
        transform: scale(0.7);

        &.active {
          background-color: $primary;
          transform: scale(1.1);
        }
      }
    }
  }
}

// Separate to enable sorting
.hero-feature {
  @extend %flex-row-middle-spread;
  background-color: $darker;
  border-radius: 10px;
  padding: 40px;
  color: white;
  height: 100%;
  position: relative;
  gap: 24px;
  width: 100%;

  &.hidden {
    opacity: 0.5;
  }

  .main {
    @extend %flex-col;
    max-width: 60%;
    flex-wrap: wrap;
    align-items: flex-start;

    .title {
      @extend %header-fancy;
      font-size: 40px;
      text-transform: uppercase;
      font-weight: 500;
    }
    .description {
      font-size: 16px;
      margin-top: 8px;
    }

    .view-btn {
      @extend %btn-label;
      border: $border-light;
      color: white;
      background-color: $darkest;
      font-size: 12px;
      padding: 12px 24px;
      text-decoration: none;
      margin-top: 32px;
    }
  }

  .secondary {
    @extend %flex-row;
    justify-content: flex-end;
    flex: 1;
    position: relative;
    height: 280px;
    max-width: calc(160px + 110px);

    @include xl-down {
      height: 260px;
      max-width: calc(160px + 54px);
    }

    @include lg-down {
      height: 240px;
      max-width: calc(160px);
    }

    .image-container {
      height: 100%;
      aspect-ratio: 16 / 24;
      position: relative;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      border-radius: 12px;
      overflow: hidden;
      background-color: transparent;

      img {
        transition: opacity 1.2s ease-in-out;
        opacity: 1;
      }

      &.first {
        &.front {
          background-color: $darkest;
          img {
            @extend %animation-fade;
          }
        }
      }

      &.visible img {
        opacity: 1;
      }

      &:not(.visible) img {
        opacity: 0;
      }

      &.empty {
        background-color: $darkest;
      }

      @include lg-down {
        &.second {
          display: none;
        }
      }
      @include xl-down {
        &.third {
          display: none;
        }
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      &.first {
        transform: translateX(0px);

        &.front {
          z-index: 2;
        }

        &.back {
          z-index: 2;
        }
        img {
          transition-delay: 0ms;
        }
      }

      &.second {
        transform: translateX(60px) scale(0.8);

        &.front {
          z-index: 1;
        }

        &.back {
          z-index: 1;
        }
        img {
          transition-delay: 150ms;
        }
      }

      &.third {
        transform: translateX(120px) scale(0.6);

        &.front {
          z-index: 0;
        }

        &.back {
          z-index: 0;
        }
        img {
          transition-delay: 300ms;
        }
      }
    }
  }
}
