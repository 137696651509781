@import '../../styles/shared.scss';

.chat-overlay-outer-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  font-family: $font-body-new;
  bottom: 0;
  right: 0;
  z-index: 100;

  .background-fade {
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in-out;

    &.visible {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .chat-overlay-inner-container {
    @extend %flex-col;
    pointer-events: auto;
    background: white;
    position: absolute;
    right: 5vw;
    bottom: 36px;
    width: 90vw;
    max-width: 440px;
    border-radius: 16px;
    transform: translateY(calc(100% + 36px));
    overflow: hidden;
    box-shadow: $shadow-dark;
    transition: transform 0.5s ease-in-out;

    &.visible {
      transform: translateY(0);
    }

    .header {
      @extend %flex-row-middle-spread;
      padding: 12px 20px;
      background: $darkest;

      .talent {
        @extend %flex-row-middle;

        .image-container {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 12px;
          overflow: hidden;

          img,
          .empty {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .empty {
            background-color: $offwhite;
          }
        }

        .data {
          .name {
            font-size: 16px;
            color: $white;
            font-weight: bold;
          }
          .view-talent-card {
            @extend %btn-basic;
            font-size: 8px;
            margin-top: 1px;
            color: $white;
            font-weight: bold;
            text-transform: uppercase;

            svg {
              margin-left: 3px;
              font-size: 7px;
            }
          }
        }
      }

      .actions {
        @extend %flex-row-middle;
        gap: 8px;

        .action {
          @extend %btn-basic;
          font-size: 18px;

          svg {
            color: white;
          }
        }
      }
    }

    .body {
      flex: 1;
      background: $white;
      position: relative;
      overflow: scroll;
      max-height: 540px; // HEIGHT_ADJUSTMENTS
      min-height: 540px; // HEIGHT_ADJUSTMENTS

      .loading-chat {
        @extend %flex-center;
        height: 540px;
        background: $offwhite;
      }

      .chat-messages {
        @extend %flex-col;
        flex-direction: column;
        overflow: auto;
        padding: 24px 12px;
        max-height: 540px; // HEIGHT_ADJUSTMENTS

        .message-timestamp {
          text-align: center;
          font-size: 10px;
          color: $medium;
        }

        .chat-message-container {
          .message-block {
            // overflow: hidden; // Show in order to show "sent on behalf of" messages
          }
        }

        .chat-typing-wrapper {
          visibility: hidden;
          opacity: 0;
          height: 0;
          transition: opacity 0.5s; /* Apply opacity transition for both adding and removing the class */
        }

        .chat-typing-wrapper.active {
          visibility: visible;
          opacity: 1;
          height: 60px;
          transition: opacity 0.5s; /* Maintain opacity transition when adding 'active' class */
        }

        /* Apply height transition only when removing the 'active' class */
        .chat-typing-wrapper:not(.active) {
          transition: height 0.5s, opacity 0.5s;
        }
      }

      .no-available-chat {
        @extend %flex-col;
        padding: 44px 24px;

        .actions {
          @extend %flex-col;
          gap: 12px;

          .action {
            @extend %btn-lightest-new;
            text-transform: uppercase;
            padding: 12px 24px;
            font-size: 14px;

            &.disabled {
              cursor: default;
              opacity: 0.1;
            }
          }
        }
      }
    }

    .footer {
      @extend %flex-col;
      background: $lightest;
      padding: 12px 20px;

      form {
        @extend %flex-row;
        width: 100%;
        gap: 8px;

        .disabled-field {
          width: 100%;
        }

        .upload-basic-container {
          max-height: 33px;

          svg {
            color: $medium;
            font-size: 16px;
            margin-left: 0;
          }
        }

        .input-container {
          width: 100%;
          position: relative;

          .on-mobile {
            display: none;
            padding-bottom: 120px;

            @include md-down {
              display: inherit;
            }
          }
          .on-computer {
            display: none;
            @include md {
              display: inherit;
            }
          }

          textarea {
            @extend %input-shared;
            width: 100%;
            resize: none;
            font-size: 13px;

            &[disabled] {
              opacity: 0.4;
            }
          }
        }

        .uploaded-files {
          @extend %flex-row-middle;
          max-height: 33px;
          gap: 8px;

          .file {
            width: 36px;
            height: 36px;
            background-color: $lightest;
            border-radius: 4px;
            position: relative;
            border: $border-offwhite;

            img {
              @extend %animation-fade;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 4px;
            }

            .file-label {
              @extend %flex-center;
              width: 100%;
              height: 100%;
              color: $medium;
              font-size: 12px;
              font-weight: bold;
            }

            &:hover {
              .remove {
                opacity: 1;
              }
            }

            .remove {
              @extend %flex-center;
              cursor: pointer;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              position: absolute;
              right: -8px;
              top: -8px;
              background-color: $dark;
              border: $border-white;
              opacity: 0.8;

              svg {
                color: white;
                font-size: 10px;
              }
            }
          }
        }

        .submit-btn {
          @extend %btn-darkest-new;
          font-size: 13px;
          max-height: 33px;
        }
      }

      .status {
        align-self: flex-end;
        font-size: 9px;
        margin-top: 4px;
        color: $dark;

        &.connecting {
          color: $medium;
        }

        &.failure {
          color: $error;
        }
      }
    }
  }
}
