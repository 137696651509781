@import '../../styles/shared.scss';

.talent-controls-outer-container {
  position: absolute;
  bottom: 100%;
  width: 100%;
  right: 0;

  .talent-controls-inner-container {
    @extend %flex-row-middle-spread;
    justify-content: flex-end;
    padding-bottom: 16px;

    @include md-down {
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
    }

    .search {
      @extend %flex-row;
      flex: 1;

      @include md-down {
        width: 100%;
      }

      input {
        @extend %input-shared;
        flex: 1;
        // background: $offwhite;
        margin-right: 24px;
        max-width: 320px;
        font-size: 13px;
        padding: 6px 12px;
      }
    }

    .actions {
      @extend %flex-row-middle;

      .toggle-container {
        @extend %flex-row-middle;
        margin-right: 12px;

        &.active {
          .toggle-label-container {
            .toggle-label {
              color: $darkest;
              text-decoration: underline;
            }
            .info-tooltip svg {
              color: $darkest;
            }
          }
        }

        .toggle-label-container {
          @extend %flex-row-middle;
          margin-right: 4px;
          gap: 4px;

          .toggle-label {
            font-size: 12px;
            color: $medium;
            white-space: nowrap;
          }

          .info-tooltip svg {
            font-size: 11px;
            color: $medium;
          }
        }
      }
      .sort-controls {
        @extend %flex-row-middle;
        margin-left: 4px;
        font-size: 12px;

        .label {
          font-size: 12px;
          margin-right: 4px;
          color: $dark;
          white-space: nowrap;
        }
        .sort-control {
          min-width: 240px;
          .sort-control__control {
            min-height: 30px;
          }
          .sort-control__indicator {
            padding: 4px 8px;
          }
        }
      }
    }
  }
}
