@import '../../styles/shared.scss';

.empty-shop-intro-outer-container {
  @extend %flex-col;
  border: $border-lighter;
  border-radius: 10px;
  overflow: hidden;
  min-height: 240px;
  width: calc(100% - 32px);
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  color: $darker;

  &.consults {
    max-width: 740px;
  }

  .intro-header-container {
    padding: 24px 24px;

    .intro-header {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }
    .intro-subheader {
      font-size: 14px;
    }
  }

  .intro-body {
    .how-it-works {
      font-size: 12px;
      padding: 0 24px 8px;
      font-weight: bold;
      color: $medium;
    }
    .intro-section {
      @extend %flex-col;
      border-bottom: 10px solid white;
      background: $whynotusewhite;
      overflow: hidden;
      padding: 64px 24px 0;
      min-height: 180px;

      @include md {
        flex-direction: row;
        align-items: center;
        padding: 32px 24px 0;
      }

      &:nth-child(1) {
        background: $whynotusewhite;
        img {
          animation-duration: 250ms;
        }
      }

      &:nth-child(2) {
        background: $prettymuchwhite;
        img {
          animation-duration: 500ms;
        }
      }

      &:nth-child(3) {
        background: $offwhite;
        img {
          animation-duration: 750ms;
        }
      }

      .section-main {
        @extend %flex-row-middle;
        font-weight: bold;
        flex: 1;
        margin: 0 0 48px 0;

        @include md {
          margin: 0 24px 0 0;
        }

        .section-count {
          font-size: 24px;
          margin-right: 16px;
          opacity: 0.25;
          min-width: 24px;
          text-align: center;
        }
        .section-text {
          @extend %flex-col;
          .section-headline {
            font-size: 20px;
            letter-spacing: -0.5px;
            line-height: 22px;
            color: $darker;
          }

          .section-example-link {
            font-size: 13px;
            line-height: 1;
            margin-top: 12px;
            color: $medium;

            svg {
              font-size: 10px;
              margin-left: 6px;
              transform: translateY(-1px);
            }
          }
        }
      }
      .section-image {
        @extend %animation-slide-up;
        align-self: center;
        margin-bottom: -8px;

        @include md {
          align-self: flex-end;
        }

        img {
          @extend %animation-fade;
          width: 100%;
          max-width: 100%;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          box-shadow: $shadow-light;

          @include sm {
            width: 300px;
            max-width: 300px;
          }
        }
      }
    }
  }

  .get-started-btns-top {
    @extend %flex-row-middle;
    position: absolute;
    top: 8px;
    right: 8px;
    gap: 4px;

    .get-started-btn-top {
      @extend %btn-text;
      align-self: center;
      padding: 6px 12px;
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.5px;
    }
  }

  .footer-container {
    @extend %flex-center;
    background: $dark;
    padding: 24px 0;

    .get-started-btn {
      @extend %btn-white;
      align-self: center;
      padding: 12px 36px;
      font-size: 16px;
      text-align: center;
      font-family: $font-body;
      cursor: pointer;
      letter-spacing: 0.5px;
      max-width: 240px;
      width: 100%;
    }
  }
}
