@import '../../../../styles/shared.scss';

.lookbook-order-modal-cart-item-outer {
  flex: 1;
  position: relative;

  .lookbook-order-modal-cart-item-inner {
    @extend %flex-row-middle;
    gap: 20px;
    padding: 12px;
    background-color: white;
    border-radius: 16px;
    position: relative;

    @include sm-down {
      // flex-direction: column;
      gap: 10px;
    }

    .image-container {
      height: 60px;
      width: 60px;
      border-radius: 10px;
      overflow: hidden;

      @include md {
        height: 80px;
        width: 80px;
      }

      @include lg {
        height: 100px;
        width: 100px;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .text-container {
      @extend %flex-row;
      justify-content: space-between;
      flex: 1;

      .product-details {
        @extend %flex-col;
        justify-content: center;

        .brand {
          @extend %label;
          color: $medium;
          font-size: 10px;
          margin-bottom: 2px;
        }

        .title {
          // font-family: $font-header;
          font-weight: 600;
          font-size: 1.1rem;
          color: $darkest;
        }
        .subtitle {
          font-size: 12px;
          color: $darkest;
        }

        .details {
          @extend %flex-row;
          align-items: center;
          font-size: 0.8rem;
          text-decoration: none;
          color: $light;
          gap: 0.5rem;
          margin-top: 4px;

          svg {
            height: 10px;
            width: 10px;
            margin-left: 5px;
            cursor: pointer;

            &:hover {
              color: $dark;
            }
          }
        }

        .url {
          @extend %flex-row;
          align-items: center;
          font-size: 0.7rem;
          text-decoration: none;
          opacity: 0.3;
          transition: opacity 0.2s ease-out;
          color: $dark;

          &:hover {
            opacity: 1;
          }

          svg {
            margin-left: 5px;
            height: 7px;
            width: 7px;
          }
        }
      }
    }

    .addition-and-subtraction-buttons-container {
      @extend %flex-col;
      justify-content: center;
      text-align: center;
      margin-left: 10px;

      .addition-and-subtraction-buttons {
        @extend %flex-col;
        background-color: $primary;
        border-radius: 5px;
        overflow: hidden;
        color: white;
        font-size: 0.8rem;

        button {
          @extend %btn-basic;
          display: grid;
          place-items: center;
          background-color: $primary;
          border: none;
          outline: none;
          height: 35px;
          width: 35px;
          color: white;

          &.cartIsAtMaxCapacity {
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }
  }
}
