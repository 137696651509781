@import '../../../styles/shared.scss';

.home-cta-outer-container {
  width: 100vw;
  padding: 64px 0;
  background-color: $white;

  @include lg {
    padding: 120px 0;
  }
  @include xxl {
    padding: 140px 0;
  }

  .home-cta-inner-container {
    .main-statement {
      @extend %flex-col-center;
      padding: 0 5vw;
      transition: opacity 0.3s ease-in-out;

      .title {
        font-family: $font-header;
        line-height: 1.3;
        margin: 0;
        font-weight: 300;
        text-align: center;
        transition: all 0.2s ease-in-out;
        margin-bottom: 60px;

        @include sm-down {
          font-size: 32px;
        }

        @include sm {
          font-size: 40px;
        }

        @include md {
          font-size: 48px;
        }

        @include lg {
          font-size: 50px;
        }
        @include xl {
          font-size: 56px;
          max-width: 940px;
          margin-bottom: 80px;
        }

        @include xxl {
          font-size: 64px;
          max-width: 1024px;
        }
      }
    }

    .secondary {
      @extend %flex-col-center;

      .badge {
        @extend %label;
        margin: 0 0 24px;

        @include sm-down {
          font-size: 12px;
        }

        @include sm {
          font-size: 12px;
        }

        @include md {
          font-size: 14px;
        }

        @include lg {
          font-size: 14px;
        }
        @include xl {
          font-size: 15px;
        }

        @include xxl {
          font-size: 15px;
        }
      }
      .ctas {
        @extend %flex-col-middle;
        gap: 20px;
        width: 100%;
        padding: 0 20px;

        @include md {
          flex-direction: row;
          padding: 0;
          width: auto;
        }

        .cta {
          @extend %btn-outline-new;
          flex: 1;
          padding: 16px 30px;

          @include xl {
            min-width: 210px;
          }

          &:hover {
            img {
              transform: translateX(2px);
            }
          }

          img {
            margin-left: 16px;
            width: 20px;
            transition: transform 0.3s ease-in-out;
          }
        }
      }

      .or {
        @extend %label;
        margin: 24px 0;
      }

      .spacer {
        height: 64px;
      }

      .demo-btn {
        @extend %btn-darkest-new;
        padding: 16px 24px;

        svg {
          margin-left: 16px;
        }
      }
    }
  }
}
