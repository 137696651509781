@import '../../../styles/shared.scss';

.mention-video-container {
  @extend %flex-center;
  position: relative;
  width: 100%;
  height: 100%;

  video {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &:hover {
    .action-btns {
      opacity: 1;
    }
  }
  .action-btns {
    @extend %flex-center;
    gap: 6px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    background-color: radial;

    .action-btn {
      @extend %flex-center;
      position: relative;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      transition: background-color 0.3s ease;
      background: radial-gradient(circle at center, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }

      svg {
        color: white;
        font-size: 16px;
        transition: color 0.3s ease;
      }

      &:hover svg {
        color: #ffffff;
      }
    }
  }
  .progress-bar-container {
    width: 100%;
    height: 6px; // Keep this in sync with the content bottom
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $dark;
    transition: transform 0.25s ease-in-out;
    cursor: pointer;

    &:hover {
      .progress-bar {
        background-color: $offwhite;
      }
    }

    .progress-bar {
      height: 100%;
      background-color: #ffffff;
      transition: width 0.2s linear; // Keep in sync with sampling on interval of MentionVideo.js
      z-index: 2;
      pointer-events: none;
    }
  }
}
