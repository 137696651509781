@import '../../styles/shared.scss';

.shop-sections-modal-outer-container {
  @extend %flex-center;

  .modal-content {
    padding: 0 !important;
  }

  .shop-sections-modal-inner-container {
    @extend %flex-row;
    align-items: stretch;
    height: 100%;

    .sidebar {
      @extend %flex-col;
      flex: 1;
      background: $white;

      @include md-down {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;

        &.section-selected {
          display: none;
        }
      }
    }
    .main-panel {
      height: 100%;
      flex: 2;
      @extend %flex-col;
      overflow: hidden;
      background: $offwhite;
    }
  }
}
