@import '../../styles/shared.scss';

.oauth-landing-wrapper {
  @extend %flex-row-center;
  font-family: $font-body-new;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;

  .header-logo {
    height: 45px;
  }

  .content-wrapper {
    background-color: white;
    padding: 40px 30px;
    border-radius: 15px;
    width: 30%;

    @include md-down {
      width: 100%;
    }

    .apply-auth-wrapper {
      .apply-auth-header {
        @extend %header-fancy-md;
        color: $darkest;
      }

      .apply-auth-subheader {
        font-size: 16px;
        color: $darker;
        margin-bottom: 16px;
      }
    }
  }

  .connect-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .connect-logos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 130px;
      margin-bottom: 16px;

      .logo-img {
        height: 40px;
        width: 40px;

        &.app {
          background-color: $darkest;
          border-radius: 50%;
          padding: 4px;
        }
      }

      .initials {
        @extend %flex-center;
        height: 40px;
        width: 40px;
        object-fit: cover;
        border-radius: 50%;
        background: $darkest;
        color: white;
        font-family: $font-header;
        font-size: 12px;
        font-weight: bold;
        margin-right: 8px;
      }
    }

    .connect-page-header {
      font-size: 20px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
    }
  }

  .sign-up-footer {
    color: white;

    button {
      text-decoration: underline;
      color: white;
      background: none;
      border: none;
      font: inherit;
      padding: 0;
      cursor: pointer;
    }
  }
}
