%input-shared {
  padding: 8px 12px;
  border-radius: 4px;
  outline: none;
  border: $border-lightest;
  font-family: $font-body-new;
  font-size: 16px;

  &:focus {
    border: $border-light;
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
    color: $medium;
  }
}

%input-outline-inverted {
  @extend %input-shared;
  border: $border-white;
  background: transparent;
  color: white;
  font-weight: bold;

  &:focus {
    border: $border-lightest;
  }

  &::placeholder {
    color: $white;
    font-weight: bold;
    text-transform: uppercase;
  }
}

%input-inline {
  @extend %input-shared;
  padding: 0;
  outline: none;
  font-size: 14px;
  border: none;

  &:focus {
    border: none;
  }

  &::placeholder {
    color: $medium;
  }
}

%input-error {
  border-color: $error;
  background-color: $errorLightest;
}

%input-inline-parent-subtle {
  /* 
    use this is you want a indication that the field is editable. It will add a subtle
    border around the input field.

    <div className='parent'> <-- put this class on the parent div
      <textarea/>
    </div>
  */
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: calc(16px / -2);
    left: calc(16px / -2);
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    border: $border-offwhite;
    border-radius: 4px;
    pointer-events: none;
  }
}

%input-mobile-friendly {
  @include md-down {
    font-size: 16px;
  }
}

%input-inline-with-outline {
  @extend %input-shared;
  outline: none;
  font-size: 14px;
  border: $border-transparent;

  &:focus {
    border: $border-offwhite;
  }

  &::placeholder {
    color: $medium;
  }
}
