@import '../../styles/shared.scss';

.brand-budget-modal-outer {
  z-index: 1e6; // Opens no other modals, can be absolute top

  .brand-budget-modal-inner {
    width: 90vw !important;
    max-width: 640px !important;
    min-height: 360px !important;
    height: auto !important;

    .brand-budget-modal-content {
      @extend %flex-col;
      position: relative;
      background-color: white;
      padding: 24px;
      border-radius: 10px;

      .modal-header {
        @extend %flex-col;
        margin-bottom: 30px;
        padding-right: 48px;

        .header {
          font-size: 24px;
          font-weight: 600;
          color: $darkest;
        }
        .subheader {
          font-size: 14px;
          color: $darker;
        }
      }

      .sections {
        @extend %flex-col;
        gap: 24px;

        .section {
          @extend %flex-col;

          .label {
            @extend %label;
            font-size: 13px;
            font-weight: 600;
            color: $darkest;
          }

          .sublabel {
            font-size: 11px;
            color: $dark;
            font-style: italic;
          }

          .value {
            margin-top: 8px;
            font-family: $font-header;
            font-size: 36px;
            font-weight: 600;
            color: $darker;

            &--sm {
              font-weight: normal;
              font-size: 24px;
            }
          }

          input {
            @extend %input-shared;
            margin-bottom: 12px;
            margin-top: 8px;
          }
        }
      }

      .actions {
        @extend %flex-row;
        justify-content: flex-end;
        margin-top: 24px;

        .action {
          margin-left: 12px;

          &.secondary {
            @extend %btn-text-new;
            padding: 12px 24px;
          }

          &.primary {
            @extend %btn-darkest-new;
            padding: 12px 24px;
          }

          &.disabled {
            pointer-events: none;
            opacity: 0.3;
          }
        }
      }

      .close-icon {
        @extend %btn-basic;
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 30px;
      }
    }
  }
}
