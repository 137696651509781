@import '../../styles/shared.scss';

.blog-post-elements-outer-container {
  @extend %flex-row-middle;
  align-items: stretch;
  flex-wrap: wrap;
  color: $darker;
  font-family: $font-body;
  max-width: 800px;
  position: relative;

  .blog-post-elements-sortable-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-content: center;
    gap: 32px;
    position: relative;
    width: 100%;

    .blog-post-element-skeleton {
      background: $offwhite;
      width: 100%;
      flex: 1;
      grid-column: 1/3;
      border-radius: 5px;

      animation: skeleton-fade 3s infinite;
      @for $i from 1 through 100 {
        &:nth-child(#{$i}n) {
          animation-delay: #{$i * 0.25}s;
        }
      }

      &.small {
        height: 50px;
      }

      &.medium {
        height: 100px;
      }

      &.large {
        height: 200px;
      }
    }
  }

  .add-component-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 400px;
    z-index: 10;
    padding: 20px;
    padding-bottom: 200px;
    overflow-y: scroll;

    background: $offwhite;

    .add-component-header {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .add-component-subheader {
      font-size: 14px;
      margin-bottom: 30px;
    }

    .admin-buttons-container {
      @extend %flex-row;
      gap: 10px;
      margin: 20px 0;

      .admin-button {
        @extend %btn-basic;
        @extend %label;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 13px;
        background-color: white;
        width: max-content;
      }
    }

    .add-component-options-container {
      @extend %flex-col;
      gap: 30px;

      .add-component-option-group {
        @extend %flex-col;
        gap: 7px;

        .add-component-option-group-title {
          font-size: 18px;
          font-weight: 700;
        }

        .add-component-options {
          @extend %flex-col;
          gap: 5px;

          .add-component-option {
            @extend %btn-basic;
            padding: 10px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 13px;
            background-color: white;
          }
        }
      }
    }
  }
}

@keyframes skeleton-fade {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
