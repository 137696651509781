@import '../../styles/shared.scss';

.lookbooks-outer {
  font-family: $font-body-new;

  .lookbooks-inner {
    background-color: $prettymuchwhite;

    .loading-container {
      display: grid;
      place-items: center;
      min-height: 100vh;
    }
  }
}
