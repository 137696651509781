@import '../../styles/shared.scss';

.consult-image-selection-outer-modal {
  max-width: calc(600px + 200px) !important;

  .consult-image-selection-modal {
    @extend %flex-row;
    flex-direction: row !important;
    padding: 0 !important;
    overflow: hidden !important;
    color: $darker;

    .section-selection {
      @extend %flex-col;
      flex: 1;
      background: $offwhite;
      border-right: $border-lightest;
      max-width: 200px;
      display: none;
      padding: 24px;

      @include md {
        display: flex;
      }

      .filter {
        @extend %btn-basic;
        padding-bottom: 4px;
        margin-bottom: 4px;
        color: $dark;

        &.selected {
          font-weight: bold;
          color: $darkest;
        }
      }
    }

    .sections {
      flex: 1;
      max-width: 600px;
      padding: 24px;
      overflow: auto;

      .header {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 24px;
      }

      .filter-section {
        .filter-section-header {
          font-size: 13px;
          font-weight: bold;
          margin: 24px 0 12px;
          text-transform: uppercase;
        }

        .image-options {
          @extend %flex-row;
          flex-wrap: wrap;

          .image-option {
            border: $border-offwhite;
            padding: 8px;
            border-radius: 4px;
            cursor: pointer;
            height: 140px;
            min-height: 140px;
            width: calc(100% / 2 - 8px);
            min-width: calc(100% / 2 - 8px);
            margin: 4px;

            @include sm {
              height: 120px;
              min-height: 120px;
              width: calc(100% / 3 - 4px);
              min-width: calc(100% / 3 - 4px);
              margin: 2px;
            }

            &.selected {
              border: $border-dark;
              cursor: default;

              &:hover {
                border: $border-dark;
              }
            }

            &:hover {
              border: $border-lighter;
            }

            &.empty {
              @extend %flex-center;
              text-transform: uppercase;
              color: $dark;
              font-size: 12px;
              background: $prettymuchwhite;
              border: $border-lighter;

              &:hover {
                border: $border-lighter;
              }

              &.selected {
                border: $border-dark;
                cursor: default;

                &:hover {
                  border: $border-dark;
                }
              }
            }

            img {
              background: white;
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
