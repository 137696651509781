@import '../../styles/shared.scss';

.catalog-variant-container {
  @extend %flex-col;
  align-items: center;
  background-color: $white;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  max-width: 250px;
  position: relative;
  cursor: pointer;
  transition: transform 0.25s ease-in-out;

  &:hover {
    transform: scale(0.99);
  }

  .catalog-variant {
    height: 100%;
    width: 100%;

    &.unavailable {
      opacity: 0.4;
    }
  }

  .variant-image {
    margin-bottom: 14px;
    height: 200px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      transition: transform 0.25s ease-in-out;
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1;
    }

    &.skeleton {
      @extend %animation-skeleton-fade;
      background: $prettymuchwhite;
    }
  }

  .variant-meta {
    text-align: center;
    font-size: 12px;
    color: $darkest;
    display: flex;
    flex-direction: column;
    padding: 0 10px 16px;
    height: 80px;
    width: 100%;

    .variant-brand.skeleton {
      @extend %animation-skeleton-fade;
      border-radius: 4px;
      align-self: center;
      width: 50%;
      height: 15px;
      background: $prettymuchwhite;
    }

    .variant-title {
      margin-top: 6px;
      font-size: 14px;
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      &.skeleton {
        @extend %animation-skeleton-fade;
        border-radius: 4px;
        height: 20px;
        background: $prettymuchwhite;
      }
    }

    .variant-info {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 6px;

      .sibling-count {
        text-wrap: nowrap;
      }

      .variant-external-link-container {
        display: flex;

        .variant-domain {
          text-decoration: underline;
          color: $primary;
        }

        .variant-external-link {
          color: $darkest;
          font-size: 8px;
          margin-left: 4px;
        }
      }

      .separator {
        margin: 0 4px;
      }
    }
  }

  .out-of-stock-overlay {
    position: absolute;
    top: 0;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .out-of-stock-pill {
      position: absolute;
      top: 110px;
      box-shadow: $shadow-dark;
      background: $white;
      border-radius: 20px;
      padding: 6px 12px;
      font-size: 12px;
    }
  }
}
