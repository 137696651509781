@import '../../styles/shared.scss';

.list-mentions-container {
  @extend %flex-col;

  .mention-results-columns {
    @extend %flex-row;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;

    .mention-results-column {
      @extend %flex-col;
      flex: 1;
      width: calc(25% - 12px);
      gap: 16px;
    }
  }

  .view-more-mention-results {
    @extend %btn-basic;
    @extend %label;
    @extend %flex-center;
    width: 100%;
    font-size: 10px;
    color: $lighter;
    margin-top: 96px;

    svg {
      font-size: 9px;
      margin-left: 8px;
    }
  }
}
