@import '../../../styles/shared.scss';

.contract-propose-overview-card {
  .overview {
    width: 100%;
    font-size: 14px;
    color: $dark;
    white-space: pre-line;
  }

  textarea {
    @extend %input-inline;
    resize: none;

    &::placeholder {
      font-style: italic;
    }

    @include md-down {
      font-size: 16px;
    }
  }
}

.past-contracts-container {
  padding: 16px 0 8px;
  .past-contracts-container-header {
    @extend %flex-row-middle;
    @extend %btn-basic;
    justify-content: flex-end;
    font-weight: bold;
    color: $dark;
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 12px;
    margin-right: 4px;

    .header {
    }

    svg {
      margin-left: 8px;
    }
  }
  .past-contracts {
    padding: 12px 24px 32px;
    .past-contract {
      @extend %flex-row-middle-spread;
      margin-bottom: 16px;
      .main {
        margin-right: 16px;
        .title {
          color: $medium;
          font-size: 11px;

          svg {
            @extend %btn-basic;
            font-size: 10px;
            color: $dark;
            margin-left: 4px;
          }
        }

        .description {
          font-size: 13px;
          color: $darker;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .copy-btn {
        @extend %btn-dark-new;
        font-size: 12px;
        padding: 2px 8px;

        &.active {
          @extend %btn-offwhite-new;
          pointer-events: none;
        }

        svg {
          margin-right: 4px;
          color: white;
          font-size: 10px;
        }
      }
    }
  }

  .show-more,
  .show-less {
    @extend %btn-basic;
    text-align: center;
    font-size: 12px;
    color: $light;
    text-transform: uppercase;
  }
}
