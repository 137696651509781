@import '../../../styles/shared.scss';

.brand-lookbook-products-outer {
  margin-top: 40px;

  .brand-lookbook-products-inner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    place-content: center;
    justify-content: space-around;
    row-gap: 50px;
    column-gap: 30px;

    .add-product-container {
      @extend %flex-col;
      @extend %btn-basic;
      @extend %label;
      width: 100%;
      min-height: 300px;
      gap: 10px;
      justify-content: center;
      align-items: center;
      color: $light;
    }
  }
}
