@import '../../styles/shared.scss';

.links-pagination-container {
  @extend %flex-row-middle;
  justify-content: center;
  width: 100%;
  background-color: $prettymuchwhite;
  padding-top: 16px;

  .ellipsis {
    font-size: 16px;
    margin: -8px 4px 0;
  }

  .page-num {
    border: $border-offwhite;
    padding: 6px 10px;
    border-radius: 4px;
    margin: 0 4px;
    color: $dark;
    font-size: 14px;
    cursor: pointer;
    background: $white;

    &.selected {
      font-weight: bold;
      background: $darkest;
      color: white;
      cursor: default;
    }
  }
}
