@import '../../styles/shared.scss';

.consult-submission-modal {
  @extend %flex-col;
  max-width: 600px !important;
  color: $darker;
  overflow: auto;
  max-height: 100vh;

  @include md {
    height: auto !important;
  }

  .submission-header {
    font-size: 24px;
    font-weight: bold;
    color: $darker;
    margin-bottom: 2px;
    line-height: 1;
  }
  .submission-subheader {
    font-size: 14px;
    font-weight: bold;
    color: $dark;
    line-height: 1;
    margin-bottom: 24px;
    margin-left: 2px;
  }

  .section {
    @extend %flex-col;
    align-items: stretch;
    margin-bottom: 16px;

    .section-header {
      font-weight: bold;
      font-size: 13px;
      line-height: 1;
      margin-bottom: 12px;
      text-transform: uppercase;
      color: $dark;
    }
    .input-wrapper {
      position: relative;
      margin-bottom: 8px;

      input {
        @extend %input-shared;
        width: 100%;
        max-width: 400px;
      }

      .reset-btn {
        @extend %flex-center;
        position: absolute;
        right: 8px;
        bottom: 0;
        height: 100%;
        font-size: 10px;
        text-transform: uppercase;
        cursor: pointer;

        svg {
          margin-left: 4px;
          font-size: 11px;
        }
      }
    }

    .order-confirmation-card {
      @extend %flex-col;
      margin-top: 12px;

      .line-item {
        @extend %flex-row-middle-spread;
        margin-bottom: 16px;
        color: $dark;

        .desc {
          font-weight: bold;
          position: relative;
          flex: 1;
          font-size: 14px;
          line-height: 1;

          .desc-explainer {
            font-weight: normal;
            font-size: 10px;
            padding-top: 2px;
          }
        }
        .value {
          font-weight: bold;
          position: relative;
          font-size: 16px;
          line-height: 1;
        }
      }

      .credit-card-payment {
        margin-top: 16px;

        .StripeElement {
          padding: 8px 12px;
          border-radius: 4px;
          outline: none;
          border: $border-medium;
          font-family: $font-body;
          font-size: 16px;
          color: $dark;
          background: white;
        }

        .StripeElement--focus {
          border: $border-light;
        }

        .StripeElement--invalid {
          border-color: #fa755a;
        }

        .StripeElement--webkit-autofill {
          background-color: #fefde5 !important;
        }
      }
      .submit-btns {
        margin-top: 16px;
        @extend %flex-row;
        align-self: flex-end;

        .submit-btn-card {
          @extend %btn-dark;
          text-transform: uppercase;
          padding: 8px 12px;
          align-self: flex-end;

          &.sms-perk {
            @extend %btn-primary;
          }
        }

        .btn {
          &:not(:last-child) {
            margin-right: 12px;
          }
        }
      }
    }

    .submit-btn-general {
      @extend %btn-dark;
      padding: 10px 24px;
      margin-top: 8px;
      font-size: 16px;
      text-transform: uppercase;
    }

    .or-container {
      @extend %flex-center;
      width: 100%;
      margin: 12px 0 24px;
      border-bottom: $border-offwhite;
      background: $offwhite;
      height: 1px;

      .or {
        text-align: center;
        background: white;
        padding: 0 24px;
        color: $light;
        font-size: 12px;
        font-weight: bold;
      }
    }

    .wallet-container {
      position: relative;
      .submission-protection {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
