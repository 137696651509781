@import '../../styles/shared.scss';

.partner-action-templates-list {
  @extend %flex-col;
  max-height: 400px;
  padding: 0 0 0;

  .tabs {
    @extend %flex-row;
    gap: 8px;
    padding: 0 0px 18px;

    .tab {
      @extend %btn-basic;
      @extend %label;
      position: relative;
      padding: 6px 8px;
      border-radius: 4px;
      background: white;
      color: $darkest;
      border: $border-offwhite;
      font-size: 10px;

      &.active {
        border: $border-darkest;
      }
      &.loading {
        color: $offwhite;
      }

      .loader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        margin-top: -6px;
      }
    }
  }

  .body {
    overflow-y: scroll;

    .templates-section {
      margin-bottom: 16px;
      .templates-header {
        font-size: 12px;
        font-weight: bold;
      }
      .templates {
        .template-container {
          @extend %flex-col;
          margin-top: 12px;
          background: $whynotusewhite;
          border: $border-offwhite;
          border-radius: 10px;
          padding: 12px 16px 16px; // Leave room for Copy
          position: relative;

          .template-header {
            font-size: 8px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            margin-bottom: 4px;
          }

          .template {
            @extend %flex-col;

            .description {
              font-size: 12px;
              color: $darkest;
              white-space: pre-line;
            }
          }
        }

        .copy-btn {
          position: absolute;
          bottom: 8px;
          right: 8px;
          @extend %btn-dark-new;
          padding: 2px 6px;
          font-size: 10px;
        }
      }
    }

    .messages-section {
      @extend %flex-col;
      gap: 12px;

      .message {
        @extend %flex-col;
        font-size: 12px;
        background: $whynotusewhite;
        border: $border-offwhite;
        border-radius: 10px;
        color: $darkest;
        padding: 12px 16px;

        &.automated {
          background: transparent;
          padding: 4px 16px;
        }

        .author {
          @extend %label;
          font-weight: bold;
        }

        .date {
          font-size: 10px;
          margin-bottom: 4px;
        }

        .text {
          color: $darkest;
        }
      }
    }

    .empty-section {
      @extend %flex-center;
      width: 100%;
      background: white;
      border-radius: 8px;
      padding: 24px 24px;
      font-size: 12px;
      color: $dark;
      text-align: center;
    }
  }
}
