@import '../../styles/shared.scss';

.talent-result-outer-container {
  @extend %flex-row-middle;
  background: white;
  box-shadow: $shadow-light;
  border-radius: 16px;
  margin-bottom: 12px;
  border: 2px solid transparent;
  overflow: hidden;

  &.alert {
    border: 2px solid $darkest;
  }

  .image-container {
    position: relative;
    width: 64px;
    height: 64px;
    margin: 4px;
    align-self: stretch;
    border-radius: 14px;
    overflow: hidden;

    &.details {
      width: 104px;
      height: 104px;
      margin: 6px 4px;
    }

    img,
    .empty-img {
      @extend %animation-fade;
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      background: $offwhite;
    }

    .empty-img {
      @extend %flex-center;
      font-family: $font-header;
      background: $darkest;
      font-size: 36px;
      font-weight: bold;
      color: white;
    }

    .action-btns {
      @extend %flex-row-middle;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 16px 4px 2px 0;
      gap: 4px;
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), transparent);
      border-radius: 0 0 16px 16px;
      width: 100%;
      justify-content: flex-end;

      .action-btn {
        cursor: pointer;
        color: $white;
        font-size: 14px;
      }
    }
  }

  .body {
    @extend %flex-col;
    flex: 1;
    position: relative;

    .body-inner {
      @extend %flex-row-middle-spread;
      flex: 1;

      .main {
        @extend %flex-row-middle-spread;
        flex: 1;

        .content {
          @extend %flex-row-middle;
          .info {
            @extend %flex-col;
            padding: 10px 12px 10px 8px;

            &.details {
              width: 200px;

              @include lg {
                width: 180px;
              }
              @include xl {
                width: 200px;
              }
            }

            .badge {
              @extend %label;
              font-size: 9px;
              color: $darkest;
              margin-bottom: 2px;
            }
            .name {
              font-family: $font-header;
              font-size: 16px;
              color: $darkest;
              font-weight: bold;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }
            .open-card-btn {
              @extend %btn-basic;
              margin-top: 4px;
              font-size: 8px;
              text-transform: uppercase;
              color: $dark;

              svg {
                transform: translateY(-1px);
                font-size: 6px;
                margin-left: 4px;
              }
            }

            .badges {
              @extend %flex-col;
              align-items: flex-start;
              margin-top: 8px;
              gap: 4px;

              .badge-container {
                @extend %label;
                background-color: white;
                border: $border-lighter;
                color: $dark;
                font-size: 9px;
                font-weight: bold;
                padding: 2px 6px;
                border-radius: 4px;
              }
            }

            .social-count-pills {
              margin-top: 10px;
            }
          }
        }
      }
      .stats {
        padding: 12px 0;
        flex: 1;
      }
      .actions {
        @extend %flex-col;
        padding: 12px;
        font-size: 10px;
        color: $darkest;
        margin-bottom: 4px;
        text-align: right;

        &.details {
          width: 160px;
          max-width: 160px;
        }
      }
    }

    .body-footer {
      @extend %flex-row-middle;
      justify-content: flex-end;
      padding: 4px 12px 12px;
      align-self: flex-end;
      max-width: 80%;

      .comment {
        font-size: 12px;
        text-align: right;
        border: $border-lightest;
        padding: 8px 12px;
        border-radius: 8px;
        white-space: pre-line;
        cursor: pointer;
        transition: border-color 0.2s ease-in-out;

        &:hover {
          border: $border-light;
        }
      }

      .comment-author {
        margin-left: 12px;
        min-width: 24px;
        min-height: 24px;
        width: 24px;
        height: 24px;

        img,
        .logo {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }

        .logo {
          @extend %flex-center;
          font-family: $font-header;
          background-color: $darkest;
          font-size: 16px;
          font-weight: bold;
          color: white;
        }
      }
    }

    .add-comment-btn {
      @extend %btn-basic;
      position: absolute;
      bottom: 4px;
      right: 4px;
      font-size: 8px;
      color: $lighter;
      text-transform: uppercase;
    }
  }
}
