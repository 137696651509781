@import '../../styles/shared.scss';

.careers-outer-container {
  width: 100%;
  margin: 0 auto;
  font-family: $font-body-new;

  .rounded-button {
    @extend %label;
    @extend %flex-center;
    border: $border-dark;
    border-radius: 5px;
    padding: 15px;
    font-size: 0.8rem;
    font-weight: 600;
    color: $dark;
    background: $white;
    width: max-content;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-family: inherit;
  }

  .careers-about-container {
    @extend %flex-col-middle;
    gap: 40px;
    width: clamp(300px, 85%, 1000px);
    min-height: 60vh;
    margin: 0 auto;
    padding-top: 100px;
    text-align: center;

    h1 {
      @extend %label;
      font-weight: 600;
      font-size: 0.9rem;
      margin: 0;

      @include md-down {
        font-size: 0.8rem;
      }
    }

    h2 {
      font-family: $font-header;
      font-weight: 100;
      font-size: 52px;
      margin: 0;
      line-height: 130%;

      @include md-down {
        font-size: 32px;
      }
    }

    p.company-description {
      font-size: 0.9rem;
      font-weight: normal;
      margin: 0;
      margin: 0 auto;
      line-height: 24px;

      @include lg {
        width: 80%;
      }
    }
  }

  .careers-image-container {
    // @extend %flex-row;
    // align-items: center;
    margin-top: 100px;
    overflow: hidden;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @include md-down {
      margin-top: 50px;
      grid-template-columns: repeat(2, 1fr);
    }

    img {
      // height: clamp(200px, 50vw, 350px);
      width: 100%;
      aspect-ratio: 9/12;
      object-fit: cover;

      @include xl-down {
        aspect-ratio: 9/13;
      }

      @include lg-down {
        aspect-ratio: 9/15;
      }

      @include md-down {
        aspect-ratio: 9/10;
      }

      @include xs-down {
        aspect-ratio: 9/15;
      }
    }
  }

  .shopmy-perks-container {
    padding: 100px 0;
    background: $prettymuchwhite;

    @include md-down {
      padding: 50px 0;
    }

    .shopmy-perks-container-inner {
      width: min(85%, 800px);
      margin: 0 auto;

      h2.perks-header {
        font-family: $font-header;
        font-size: 2.6rem;
        font-weight: 500;
        text-align: center;

        @include md-down {
          font-size: 2.1rem;
        }
      }

      .perk-list {
        @extend %flex-col;
        margin-top: 80px;

        @include md-down {
          grid-template-columns: repeat(2, 1fr);
          margin-top: 40px;

          .perk {
            .perk-title {
              margin: 16px 0;
            }
          }
        }

        @include xs-down {
          grid-template-columns: repeat(1, 1fr);
        }

        .perk {
          .perk-icon {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            border: $border-dark;
            display: grid;
            place-items: center;
            display: none;

            svg {
              height: 40%;
              width: 40%;
            }
          }

          .perk-title {
            font-size: 1.6rem;
            margin: 32px 0;
          }

          .perk-summary {
            font-size: 0.9rem;
            color: $dark;
          }
        }
      }
    }
  }

  .careers-jobs-container {
    padding: 75px 0;


    .all-jobs {
      width: min(85%, 750px);
      margin: 0 auto;

      .departments {
        @extend %flex-col;
        margin: 24px 0;
        gap: 100px;

        .department {
          .section-header {
            font-family: $font-header;
            font-size: 52px;
            font-weight: 300;
          }

          .job-listing-container {
            @extend %flex-col;

            .job-listing {
              font-family: $font-body-new;
              padding: 32px 0;
              text-decoration: none;

              .job-title {
                font-size: 18px;
                font-weight: 600;
                margin: 0;
                color: $darkest;

                &:hover {
                  text-decoration: underline;
                }
              }

              .job-location {
                @extend %label;
                font-size: 12px;
                color: $medium;
                margin-top: 4px;
                font-weight: 300;
              }

              &+.job-listing {
                border-top: $border-lightest;
              }
            }
          }
        }

        .new-job {
          display: grid;
          place-items: center;
          padding: 20px;
          width: 100%;
          border-radius: 16px;
          border: $border-lightest;
          background-color: $white;
          box-shadow: $shadow-light;
          cursor: pointer;
        }
      }
    }
  }

  .backup-option-container {
    background: $prettymuchwhite;
    padding: 110px 0;

    .backup-option {
      @extend %flex-col;
      gap: 40px;
      width: min(85%, 750px);
      margin: 0 auto;
      text-align: center;

      h2.backup-option-title {
        text-align: center;
        /* ShopMy 3.0/Base/Heading L */
        font-family: $font-header;
        font-size: 52px;
        font-style: normal;
        font-weight: 300;
        line-height: 130%;
        margin: 0;
      }

      p.backup-option-description {
        color: $dark;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin: 0;
      }

      a.backup-option-button {
        @extend %btn-basic;
        @extend %label;
        background: black;
        color: white;
        border-radius: 4px;
        outline: none;
        border: none;
        padding: 16px 24px;
        margin: 0 auto;
        width: max-content;
        text-decoration: none;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }
    }

  }
}
