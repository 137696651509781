@import '../../../../styles/shared.scss';

.home-partner-example {
  transition: all 0.2s ease-in-out;

  &:not(.not-clickable):hover {
    transform: scale(1.02);
    cursor: pointer;
  }

  &:last-child {
    padding-right: 48px;
  }

  .image-container {
    position: relative;
    overflow: hidden;
    border-radius: 30px;
    width: 300px;
    height: 240px;
    position: relative;

    @include md {
      width: 380px;
      height: 320px;
    }

    img.primary {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    img.overlay {
      position: absolute;
      width: 60%;
      left: 20%;
      height: 30%;
      top: 30%;
      object-fit: contain;
    }

    .cover {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
    }
    .data {
      position: absolute;
      padding: 0 24px 24px;
      color: white;
      bottom: 0;
      left: 0;
      gap: 8px;

      &.shop {
        @extend %flex-row-middle;

        .label {
          @extend %label;
          font-size: 16px;
          line-height: 1;
        }

        .name {
          font-family: $font-social;
          letter-spacing: 0.5px;
          font-size: 20px;
          font-weight: 500;
          line-height: 1;
          margin-top: -3px;

          &.long {
            font-size: 18px;
          }
        }
      }

      &.commission {
        @extend %flex-row-middle-spread;
        width: 100%;

        .commission {
          @extend %label;
          font-size: 15px;
          line-height: 1;
        }

        .rate {
          font-family: $font-social;
          letter-spacing: 0.5px;
          @extend %label;
          font-size: 24px;
          line-height: 1;
          font-weight: 500;
        }
      }
    }
  }
}
