@import '../../styles/shared.scss';

.tiktok-player-outer-container {
  margin-top: 24px;
  .video-loader-container {
    background: $darker;
    border-radius: 10px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    height: calc(1920px / 3) - 70px;
    width: calc(1080px / 3) - 48px;
    overflow: hidden;

    .tiktok-video-shell {
      display: none;
      transform: translateY(-18px);

      &.loaded {
        display: flex;
      }
    }

    .loader-container {
      @extend %flex-center;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
