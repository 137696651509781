@import '../../../styles/shared.scss';

.collection-product.horizontal {
  @extend %flex-col;
  flex: 1;
  position: relative;
  padding: 0;
  transition: all 0.35s ease-in-out;
  border: $border-prettymuchwhite;

  @include sm-down {
    // padding: 0 0 12px;
    border: none;
  }

  @include lg {
    max-width: 340px;
    min-width: 240px;
  }

  &.is-editing {
    border-radius: 8px;
  }

  &.hidden {
    pointer-events: none;
    .collection-product-img-wrapper {
      position: relative;

      .hidden-cover {
        @extend %flex-center;
        width: 100%;
        height: 100%;
        top: 0;
        pointer-events: none;
        left: 0;
        position: absolute;
        background: rgba(255, 255, 255, 0.8);

        .badge {
          text-align: center;
          white-space: nowrap;
          background: white;
          padding: 4px 12px;
          border-radius: 4px;
          font-weight: bold;
          color: $darker;
          box-shadow: $shadow-dark;
        }
      }
    }
    .action-btn-container {
      pointer-events: auto;
    }
  }

  .collection-product-img-wrapper {
    width: min(50vw, 240px);
    height: min(50vw, 240px);
    min-width: min(50vw, 240px);
    min-height: min(50vw, 240px);
    margin: 0 auto 12px;
    transition: transform 0.2s ease-in-out;

    &.focused {
      z-index: 1;

      .product-image {
        @include sm-down {
          transform: scale(1.2) translateX(calc(min(25vw, 100px) / 1.2)); // SIZES
        }
        transform-origin: middle right;
      }
    }

    &.smooth {
      .product-image {
        @extend %animation-fade;
        animation-duration: 400ms;
      }
    }

    .product-image {
      align-self: center;
      width: 100%;
      height: 100%;
      min-width: 100%;
      min-height: 100%;
      transition: all 0.35s ease-in-out;
      cursor: pointer;
      object-fit: contain;

      @include sm-down {
        transform: scale(1.05) translateX(calc(min(25vw, 100px) / 1.05)); // SIZES
      }

      &.empty {
        background: $prettymuchwhite;
        border-radius: 4px;
      }

      &:hover {
        margin-top: -4px;
      }
    }
  }

  .adjusting-product-loader {
    @extend %flex-center-vertical;
    height: calc(100% + 40px);
    width: 100%;
    margin-top: -40px;
    font-size: 14px;
    font-weight: bold;
    color: $darkest;
    transition: all 0.2s ease-in-out;
    position: absolute;
    z-index: 4;
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.02);
  }

  .reorder-icn {
    position: absolute;
    top: 8px;
    right: 8px;
    color: $lighter;
    opacity: 1;
    cursor: grab;
    z-index: 2;

    @include sm-down {
      transform: translateX(calc(min(50vw, 200px) / 1.6));
    }

    @include sm {
      opacity: 0;
    }
  }
}

.meta-container-horizontal {
  @extend %flex-col-center;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  border-top: 12px;
  padding: 12px 24px 0;
  top: 100%;
  left: 0;
  transition: opacity 0.2s ease-in-out;
  border: $border-prettymuchwhite;
  transform: translateX(12px); // SIZES

  @include sm-down {
    position: absolute;
    opacity: 0;
    width: 100%;
    padding-top: 16px;
    border: none;
  }

  &.focused {
    opacity: 1;
  }

  .action-btn-container {
    @extend %flex-row;
    font-size: 18px;
    color: $medium;
    font-weight: bold;
    padding: 0 0 4px;
    align-self: flex-end;

    @include sm-down {
      padding: 24px 0 0;
      align-self: center;
    }

    svg {
      margin-left: 6px;
    }
  }

  a {
    text-decoration: none;
  }

  .title-container {
    @extend %flex-col;
    align-items: center;

    .title {
      font-size: 14px;
      padding: 0 4px;
      font-weight: normal;
      text-align: center;
      color: $darker;
      letter-spacing: 0.47px;
      margin: 0;
      font-family: $font-body;

      @include sm-down {
        text-decoration: underline;
        font-weight: bold;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .code-container {
      @extend %flex-row-middle;
      margin: 12px 0 4px;
      font-size: 12px;
      color: $dark;
      font-weight: bold;

      svg {
        margin-right: 4px;
        font-size: 10px;
      }

      .code {
        .display {
          font-weight: bold;
        }
      }
    }

    @include sm {
      margin-bottom: 32px;
    }
  }

  .description {
    font-size: 12px;
    padding: 0 2px;
    font-weight: normal;
    text-align: center;
    color: $darker;
    margin-top: 4px;
    font-family: $font-body;
  }
}
