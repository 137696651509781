@import '../../styles/shared.scss';

.checkbox-outer-container {
  @extend %flex-row-middle;
  gap: 8px;
  margin-bottom: 4px;
  margin-right: auto;
  cursor: pointer;

  &.selected {
    .checkbox {
      background: $primary;
      border-color: $primary;

      svg {
        color: $white;
      }
    }

    .checkbox-label-container {
      .checkbox-label-container-label {
        color: $black;
        font-weight: 500;
      }
      .checkbox-label-container-sublabel {
        color: $darkest;
      }
    }
  }

  .checkbox {
    @extend %flex-center;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background: $offwhite;
    border: $border-lightest;

    svg {
      font-size: 10px;
      color: $offwhite;
    }
  }

  .checkbox-label-container {
    @extend %flex-col;
    flex: 1;

    .checkbox-label-container-label {
      font-size: 14px;
      color: $dark;
      font-weight: 400;
    }

    .checkbox-label-container-sublabel {
      font-size: 10px;
      color: $light;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;

    .check {
      cursor: default;
    }
  }
}
