@import '../../styles/shared.scss';

.confirm-delete-container {
  @extend %flex-col-center;
  font-family: $font-body;
  justify-content: space-between;
  text-align: center;
  background: $white;
  border-radius: 10px;
  // height: 35vh;
  width: 350px;
  padding: 48px 24px 24px;
  gap: 24px;

  svg {
    color: $error;
    font-size: 48px;
  }

  .confirm-delete-message {
    @extend %flex-col-center;

    .confirm-delete-header {
      font-size: 24px;
      line-height: 28px;
      color: $darkest;
      width: 80%;
    }

    .confirm-delete-subheader {
      font-size: 14px;
      color: $darker;
      width: 90%;
      margin-top: 12px;
    }
  }

  .action-btns {
    @extend %flex-col;
    align-items: stretch;
    gap: 12px;
    width: 100%;

    .action-btn {
      height: 40px;
      &.primary {
        @extend %btn-darkest-new;
      }
      &.secondary {
        @extend %btn-outline-new;
      }

      &.text {
        @extend %btn-text-new;
      }
    }
  }
}
