@import '../../styles/shared.scss';

.modal-outer-container.connect-instagram-modal-container {
  .connect-instagram-modal-inner-container {
    max-width: 1240px;

    .connect-instagram-modal-content-container {
      @extend %flex-row-middle;
      flex-direction: row;
      padding: 48px;
      gap: 96px;

      @include md-down {
        flex-direction: column;
      }

      .header-container {
        flex: 1;
        width: 100%;

        @include md {
          width: 400px;
          max-width: 440px;
        }

        .warning-icon {
          margin-bottom: 12px;
          font-size: 40px;
          color: $error;
        }

        .header {
          font-size: 36px;
          font-family: $font-header;
          font-weight: 600;
          color: $darkest;
          line-height: 1.3;
          max-width: 440px;
          margin-bottom: 8px;
        }
        .subheader {
          font-size: 16px;
          line-height: 1.3;
          color: $dark;
        }
      }

      .sections {
        @extend %flex-col;
        flex: 1;
        gap: 30px;
        align-self: flex-start;

        .section {
          @extend %flex-col;

          .section-header {
            font-size: 18px;
            color: $darkest;
            margin-bottom: 12px;
            font-weight: bold;
            margin-bottom: 16px;
          }

          .section-body {
            @extend %flex-col;

            .section-btn {
              @extend %btn-darkest-new;
              @extend %flex-row-middle;
              align-self: flex-start;
              padding: 16px 20px;
              font-size: 16px;

              svg {
                font-size: 12px;
                margin-left: 12px;
              }
            }

            .videos {
              @extend %flex-col;
              gap: 12px;

              .video-container {
                border-radius: 12px;
                overflow: hidden;

                video {
                  width: 100%;
                  max-height: 320px;
                  border-radius: 16px;
                  margin-bottom: 12px;
                  overflow: hidden;
                }
              }
            }
          }
        }
      }
    }
  }
}
