@import '../../../../styles/shared.scss';

.lookbook-order-modal-cart-outer {
  background-color: $prettymuchwhite;
  height: 100%;
  flex: 1;
  overflow: scroll;

  .lookbook-order-modal-cart-inner {
    @extend %flex-col;
    gap: 15px;
    padding: 12px 20px;

    &:last-child {
      padding-bottom: 300px;

      @include md-down {
        padding-bottom: 400px;
      }
    }
  }
}
