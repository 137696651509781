@import '../../styles/shared.scss';

.blog-post-modal-outer {
  height: 100%;
  position: relative;

  .blog-post-modal-inner {
    height: 100%;
    @extend %flex-col;
    gap: 10px;
    position: relative;

    .blog-post-modal-title {
      font-family: $font-header;
      font-weight: bold;
      letter-spacing: 0.7px;
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 10px;
    }

    .blog-post-buttons {
      @extend %flex-row;
      justify-content: flex-end;
      position: absolute;
      bottom: 0;
      width: 100%;
      gap: 10px;

      > button {
        @extend %btn-basic;
        @extend %label;
        font-size: 12px;
        padding: 14px 16px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        outline: none;
        border: none;
        display: grid;
        place-items: center;

        &.create {
          background-color: $primary;
          color: white;
        }

        &.hide-post {
          background-color: #9d0d0d;
          color: white;
        }

        &.isSaving {
          filter: brightness(0.6);
          cursor: default;
          pointer-events: none;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .blog-post-container {
      display: flex;
      gap: 60px;
      width: 100%;

      @include sm-down {
        flex-direction: column;
        align-items: center;
      }

      @include md {
        flex-direction: row;
        align-items: flex-start;
      }

      .blog-cover-image-container {
        max-width: 340px;
        width: 100%;
        overflow: hidden;

        .blog-cover-image {
          width: 100%;
          border-radius: 16px;
          object-fit: cover;
          aspect-ratio: 16 / 11;
        }

        .visibility-disclaimer-container {
          margin-top: 10px;
          color: $medium;
          font-size: 12px;
          font-style: italic;
          position: relative;
          opacity: 1;
          display: block;
        }
      }

      .blog-post-info {
        flex: 1;
        @extend %flex-col;
        gap: 10px;
        width: 100%;
        max-height: 400px;
        overflow: scroll;

        .product-section-container {
          @extend %flex-row;
          gap: 20px;
          width: 100%;
          margin: 0;
          padding: 0;
          height: max-content;

          > div {
            width: 100%;
            margin: 0;
            padding: 0;
            height: max-content;

            .product-section-header {
              @extend %label;
              @extend %flex-row;
              font-size: 14px;
              width: 100%;
              align-items: center;

              span {
                @extend %btn-basic;
                font-size: 12px;
                margin-left: 10px;
                background-color: black;
                color: white;
                padding: 4px 8px;
                font-weight: bold;
                border-radius: 4px;
              }
            }

            textarea,
            input {
              border: none;
              outline: none;
              font-family: $font-body-new;
              width: 100%;
              font-size: 14px;
              padding: 10px 0;
              resize: none;
              display: block;
              margin: 0;
              width: 100%;

              &.advanced-settings-input {
                border: $border-lightest;
                border-radius: 5px;
                margin-top: 10px;
                padding: 10px 15px;
              }

              &::placeholder {
                color: $lighter;
              }

              &#title {
                font-family: $font-header;
                font-size: 28px;
                font-weight: 600;
              }
            }

            .select-tabs-container {
              @extend %flex-row;
              gap: 10px;
              margin-top: 10px;
              flex-wrap: wrap;

              .tab {
                @extend %btn-basic;
                background-color: $white;
                color: $dark;
                border: $border-lightest;
                font-size: 12px;
                padding: 6px 12px;
                border-radius: 500px;
                align-items: center;
                display: grid;
                place-items: center;
                text-transform: capitalize;

                &.isSelected {
                  background-color: $dark;
                  color: white;
                }

                &.isHidden {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
    }

    .disclaimer {
      font-size: 12px;
      color: $light;
    }
  }
}
