@import '../../styles/shared.scss';

.chat-outer-container {
  @extend %flex-col;
  width: 100%;
  min-height: 100vh;
  padding: 80px 24px 0;
  position: relative;
  font-family: $font-body;
  background: $offwhite;

  @include md-down {
    padding: 0 0 0;
    height: 100vh;
    max-height: 100vh;
    width: 100%;
    max-width: 1024px;
    margin: 120px auto;
    transform: translateY(-120px);
    background: white;

    .chat-inner-container {
      .container {
        border-radius: 0 !important;
      }
    }
  }

  .chat-inner-container {
    @extend %flex-row;
    height: 80vh;
    max-height: 960px;
    width: 100%;
    max-width: 1240px;
    margin: 120px auto;
    position: relative;
    transform: translateY(-120px);

    @include md-down {
      height: 100vh;
      max-height: calc(100vh - 60px);
      margin: 120px auto 0;
      transform: translateY(-120px);
    }

    .chat-connection-status {
      position: absolute;
      top: -24px;
      right: 0;
      font-size: 10px;
      color: $light;

      svg {
        font-size: 9px;
      }

      &.connected {
      }
      &.failed {
        color: $error;
        font-weight: bold;
      }
      &.connecting {
        color: $light;
      }
    }

    .container {
      @extend %flex-col;
      width: 100%;
      margin: 0 auto;
      position: relative;
      background: white;
      border-radius: 10px;
      // border: $border-lightest;

      &.chat-chat-container {
        width: 80%;
        flex: 2;

        @include md-down {
          border: none;
        }
      }

      .loader-container {
        @extend %flex-center-vertical;
        width: 100%;
        height: 100%;
      }
    }
  }
}
