@import '../../styles/shared.scss';

.subscription-offer-tile-basic {
  @extend %flex-row-middle-spread;
  border-radius: 12px;
  gap: 24px;
  position: relative;
  color: white;
  cursor: pointer;

  .content {
    padding: 12px;
    &.subscribed {
      padding: 36px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      color: $prettymuchwhite;
      margin-bottom: 2px;
    }

    .description {
      font-family: $font-body;
      font-size: 10px;
      color: $lightest;

      svg {
        margin-right: 4px;
        font-size: 9px;
      }
    }

    .alert-msg {
      margin-top: 12px;
      font-size: 10px;
      font-weight: bold;

      svg {
        margin-right: 4px;
      }
    }

    .actions-ellipsis-container {
      @extend %flex-center;
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;

      .actions-eliipsis {
        color: white;
      }
    }
  }
}

.subscription-offer-tile {
  @extend %flex-row-middle-spread;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  gap: 24px;
  position: relative;
  height: 280px;

  @include md-down {
    height: auto;
  }

  &.subscribed {
    height: 140px;
  }

  .main {
    padding: 24px 24px 24px 48px;
    min-width: 480px;
    width: 480px;

    @include md-down {
      padding: 24px;
      min-width: 100%;
      width: 100%;
    }

    &.subscribed {
      padding: 36px;
    }

    .title {
      // font-family: $font-header;
      font-size: 22px;
      font-weight: bold;
      color: $darkest;
      margin-bottom: 8px;
    }

    .description {
      font-family: $font-body;
      font-size: 12px;
      line-height: 1.4;
      color: $dark;
    }

    .learn-more-section {
      @extend %flex-row-middle;
      gap: 12px;
      margin-top: 24px;

      .offers-trial-btn {
        @extend %label;
        @extend %btn-basic;
        padding: 12px 24px;
        border: $border-primary;
        color: $primary;
        font-size: 10px;
      }
      .learn-more {
        @extend %flex-row-middle;
        @extend %btn-darkest-new;
        font-size: 11px;
        padding: 12px 24px;
        font-size: 10px;
        color: white;

        img {
          margin-left: 4px;
          width: 12px;
          filter: invert(1);
        }
      }
    }

    .alert-msg {
      margin-top: 12px;
      font-size: 10px;
      font-weight: bold;

      svg {
        margin-right: 4px;
      }
    }
  }
  .image-container {
    flex: 1;
    height: 100%;

    @include md-down {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      // start in bottom left
      // object-position: 100% 0;
    }
  }

  .actions-eliipsis {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}
