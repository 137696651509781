@import '../../styles/shared.scss';

.blog-post-element-outer-container {
  @extend %flex-col;
  font-family: $font-body-new;
  // margin-bottom: 8px;
  width: 100%;
  grid-column: span 2;
  flex: 1;
  overflow: hidden;

  &.half {
    grid-column: span 1;
    flex: 1;

    @include sm-down {
      grid-column: span 2;
    }
  }

  &.isEditing {
    background: $offwhite;
    border-radius: 10px;
    padding: 10px;
  }

  // @include sm {
  //   width: 50%;
  // }

  a {
    color: $darkest;
  }

  &.full {
    width: 100%;
  }

  .controls-header {
    @extend %flex-row;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 5px;

    svg {
      @extend %btn-basic;
    }
  }

  .block {
    /***********************************************************************
    ***************************** General Search ***************************
    ***********************************************************************/
    .search-results-container {
      width: 100%;
      z-index: 10;

      .search-results-panel {
        position: absolute;
        background: $dark;
        width: 100%;
        border-top: none;
        margin-top: -2px;
        border-radius: 0 0 10px 10px;

        .search-result {
          cursor: pointer;
          color: $white;
          // padding: 12px;

          .main {
            font-size: 14px;
            font-weight: bold;
          }

          .secondary {
            font-size: 12px;
          }

          .tertiary {
            font-size: 12px;
          }

          &:hover {
            background: $darker;
          }
        }
      }
    }

    /***********************************************************************
    ***************************** Paragraphs *******************************
    ***********************************************************************/
    &.paragraph {
      // margin: 0 0 8px;
      padding: 0;
      margin: 0;
      letter-spacing: 0;

      textarea {
        @extend %input-inline;
        font-family: $font-body-new;
        width: 100%;
        font-size: 16px;
        line-height: 22px;
        color: $darkest;
        font-family: $font-body-new;
      }

      &.live {
        font-size: 16px;
        line-height: 22px;
        color: $darkest;
        font-weight: 400;
      }
    }

    /***********************************************************************
    ***************************** Headers **********************************
    ***********************************************************************/
    &.header {
      width: 100%;
      margin: 0;
      // margin: 4px 0 8px;
      letter-spacing: -0.5px;

      &.large {
        font-family: $font-header;
        letter-spacing: 0px;

        &.live,
        input {
          font-size: 24px;
        }
        // margin: 4px 0 12px;
      }

      input {
        @extend %input-inline;
        width: 100%;
        font-size: 18px;
        font-weight: bold;
        color: $darker;
      }

      &.live {
        font-size: 20px;
        font-weight: bold;
        line-height: 33.8px;
        color: $darkest;
      }
    }

    /***********************************************************************
    ************************ Banner Images *********************************
    ***********************************************************************/

    &.banner-image {
      // margin: 24px -24px;

      @include md {
        // margin: 24px 0;
      }

      .banner-image-wrapper {
        position: relative;
      }

      &.instagram {
        @extend %flex-col-center;
        min-height: 260px;
        object-fit: contain;
        border-radius: 5px;
        overflow: hidden;

        .banner-image-wrapper {
          max-width: 400px;
        }
      }

      img {
        width: 100%;
        min-height: 260px;
        object-fit: contain;

        @include md {
          min-height: 320px;
          border-radius: 4px;
        }

        @include xl {
          min-height: 360px;
        }
      }

      &.live {
        @extend %animation-fade;
      }

      .empty {
        @extend %skeleton-box;
        background: $offwhite;
      }

      input {
        @extend %input-shared;
        width: 100%;
        font-size: 14px;
        color: $darker;
        // margin-top: 12px;
      }

      .disclaimer {
        z-index: 1;
        text-align: center;
        color: $medium;
        font-size: 14px;
        width: 100%;
        font-weight: 400;
        margin-top: 14px;
      }
    }

    /***********************************************************************
    ***************************** Quotes ***********************************
    ***********************************************************************/
    &.quote {
      // margin: 16px 0;
      background: white;
      border-left: $border-dark;
      padding-left: 20px;

      input,
      textarea {
        @extend %input-inline;
        font-family: $font-body-new;
        width: 100%;
        // margin-top: 8px;

        &.search-result-input {
          @extend %input-shared;
        }

        + textarea {
          margin-top: 8px;
        }
      }

      &.half {
        @extend %flex-col-middle;
        // padding-right: 24px;
        height: 100%;
      }

      &.live {
        .user-container {
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      .user-container {
        @extend %flex-row-middle;
        // margin-bottom: 20px;
        text-decoration: none;
        cursor: default;

        img,
        .empty {
          width: 58px;
          height: 58px;
          border-radius: 50%;
          background: $offwhite;
          // margin-right: 10px;
        }

        .user-data {
          flex: 1;

          .name {
            font-family: $font-header;
            font-style: italic;
            font-weight: bold;
            color: $darker;
            font-size: 20px;
          }

          .subtext {
            color: $darker;
            font-size: 15px;
          }
        }
      }

      .quote-body {
        color: $darker;
        font-size: 30px;
        line-height: 42px;
        font-family: $font-header;
        letter-spacing: -0.5px;
      }

      .quote-author {
        @extend %label;
        font-size: 12px;
        margin-top: 30px;
      }
    }

    /***********************************************************************
    ****************************** Shop ************************************
    ***********************************************************************/
    &.shop {
      // margin: 16px 0;
      background: white;

      .see-all-link {
        // font-size: 14px;
        // line-height: 17px;
        // font-weight: bold;
        // margin-top: 8px;
        // color: $darker;
        text-decoration: none;
        // text-transform: uppercase;
        // cursor: pointer;

        input,
        textarea {
          @extend %input-inline;
          font-family: $font-body-new;
          width: 100%;
          // margin-top: 8px;

          &.search-result-input {
            @extend %input-shared;
          }
        }

        &.half {
          @extend %flex-col-middle;
          // padding-right: 24px;
          height: 100%;
        }

        &.live {
          .user-container {
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }
          }
        }

        .shop-user-container {
          @extend %flex-row;
          // margin-bottom: 20px;
          text-decoration: none;
          cursor: default;
          gap: 30px;

          img,
          .empty {
            width: 150px;
            height: 150px;
          }

          .user-data {
            flex: 1;
            @extend %flex-col;
            justify-content: space-between;

            .name {
              font-family: $font-header;
              font-weight: bold;
              color: $darkest;
              font-size: 34px;
              font-weight: 500;
            }

            .subtext {
              color: $darkest;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              font-size: 14px;
              letter-spacing: 0.06px;
              line-height: 18px;
              margin-top: 6px;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }

            .view-shop-link {
              @extend %label;
              font-size: 12px;
            }
          }

          @include sm-down {
            gap: 20px;
            align-items: center;

            img,
            .empty {
              width: 100px;
              height: 100px;
              // margin-right: 16px;
            }

            .user-data {
              .name {
                font-size: 26px;
              }

              .view-shop-link {
                display: none;
              }
            }
          }
        }

        .quote-body {
          color: $darker;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }

    /***********************************************************************
    ******************************* Pins ***********************************
    ***********************************************************************/
    &.pin {
      @extend %flex-col-center;
      background: white;
      width: 100%;
      align-self: center;
      // margin: 32px auto;

      input {
        @extend %input-shared;
        width: 100%;
        // margin-top: 8px;
      }

      &.half {
        height: 100%;

        @include sm {
          max-width: 280px;
          // padding-right: 24px;
        }

        .pin-container {
          .image-container {
            img,
            .empty {
              width: 196px;
              height: 196px;
              // margin-bottom: 16px;
              object-fit: contain;
            }
          }
        }
      }

      .pin-container {
        @extend %flex-col-center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        align-items: center;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          opacity: 0.9;
        }

        @include sm {
          max-width: 320px;
        }

        .pin-product {
          width: 100%;
          @extend %flex-col;
          align-items: center;

          .image-container {
            @extend %flex-col-center;
            // margin-bottom: 12px;
            // padding-bottom: 4px;
            // border-bottom: 2px solid $darker;
            width: 100%;
            width: 240px;
            height: 240px;
            object-fit: contain;
          }

          .title {
            color: $light;
            font-size: 12px;
            line-height: 18px;
            width: 75%;
            text-align: center;
            margin: 0 auto;
            margin-top: 12px;
          }
        }

        .shop-now-btn {
          @extend %btn-offwhite;
          align-self: stretch;
          // margin: 24px 0 0;
          // padding: 10px 0;
        }
      }
    }

    /***********************************************************************
    ************************ Collection Shelf ******************************
    ***********************************************************************/
    &.collection {
      @extend %flex-col-center;
      background: white;
      width: calc(100% + 48px);
      align-self: center;
      // margin: 0;

      @include sm {
        // margin: 0 auto 0;
        width: 100%;
      }

      input {
        @extend %input-shared;
        width: 100%;
        // margin-top: 8px;
      }
    }

    /***********************************************************************
    ******************************* Youtube ********************************
    ***********************************************************************/
    &.youtube {
      background: $prettymuchwhite;
      width: 100%;
      // margin: 32px auto;
      align-self: center;
      min-height: 240px;
      border-radius: 4px;
      overflow: hidden;

      @include md {
        min-height: 320px;
      }

      input {
        @extend %input-shared;
        width: 100%;
      }
    }

    /***********************************************************************
    ****************************** Disclaimer ******************************
    ***********************************************************************/
    &.disclaimer {
      padding-top: 15px;
      border-top: 2px solid $lightest;
      color: $medium;
      font-size: 12px;
      width: 100%;
      letter-spacing: 0.5px;
      font-weight: 400;

      textarea {
        @extend %input-inline;
        font-family: $font-body-new;
        width: 100%;
        font-size: 12px;
        color: $darkest;
        font-family: $font-body-new;
        letter-spacing: 0.5px;
        font-weight: 400;
      }
    }

    /***********************************************************************
    ******************************* General ******************************
    ***********************************************************************/
    &.spacer {
      height: 32px;
      background: $prettymuchwhite;
      width: 100%;

      &.live {
        background: white;
      }

      &.small {
        height: 16px;
      }
    }

    &.line {
      width: 100%;
      // margin-top: 16px;
      // margin-bottom: 16px;
      height: 1px;
      background: $offwhite;
    }
  }
}
