@import '../../styles/shared.scss';

.partner-portal-opportunities-outer {
  .partner-portal-opportunities-inner {
    .opportunity-sections {
      @extend %flex-col;
      gap: 64px;

      .opportunity-section {
        .opportunity-section-title-container {
          margin-bottom: 20px;
          .opportunity-section-title {
            @extend %flex-row-middle;
            gap: 8px;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;

            .count {
              @extend %flex-center;
              border-radius: 50%;
              background-color: $darkest;
              padding: 2px 8px;
              color: $white;
              font-size: 10px;
            }
          }
        }

        .opportunity-requests {
          display: grid;
          gap: 24px;
          grid-template-columns: repeat(1, 1fr);

          &.grid-3 {
            @include md {
              grid-template-columns: repeat(3, 1fr);
            }
          }
        }
      }
    }

    .no-opportunities-container {
      @extend %flex-center;
      background-color: $white;
      border-radius: 10px;
      font-size: 14px;
      height: 240px;
      padding: 24px;
      text-align: center;
      color: $medium;

      a {
        @extend %link;
      }
    }
  }
}
