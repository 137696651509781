@import '../../../styles/shared.scss';

.shop-my-intelligence-toggle {
  &.badge {
    /*
      You must apply sizing information to the parent element, the badge will take up the entire
      space of the parent element.
    */
    height: 100%;
    width: 100%;
    border-radius: 50%;
    position: relative;
    overflow: visible;

    .icon {
      @extend %flex-center;
      font-size: 20px;
      font-family: $font-header;
      font-weight: bold;
      line-height: 1;
      transform: translateY(1px);
      background: $dark;
      opacity: 0.2;
      color: $white;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      transition: opacity 0.2s ease-in-out;
      cursor: pointer;

      .tooltip-outer-container {
        width: 100%;

        svg {
          color: white;
          margin-top: 2px;
        }
      }

      &.active {
        opacity: 1;
        background: $intelligence-gradient;
        background-size: 300% 100%;
      }
    }
  }
}
