@import '../../../../styles/shared.scss';

.lookbook-product-modal-images-container {
  max-width: 340px;
  height: 100%;
  width: 100%;

  .product-image-container {
    aspect-ratio: 1;
    background-color: $lighter;
    max-width: 340px;
    border-radius: 16px;
    display: grid;
    place-items: center;
    overflow: hidden;
    position: relative;

    @include sm-down {
      width: 100%;
    }

    &.original {
      aspect-ratio: unset;
    }

    &.has-image {
      background-color: transparent;
    }

    .no-image {
      @extend %flex-col;
      align-content: center;
      gap: 20px;

      svg {
        margin: 0 auto;
        color: $light;
        height: 70px;
        width: 70px;
      }

      div {
        @extend %btn-basic;
        background-color: white;
        color: black;
        font-size: 1rem;
        padding: 12px 24px;
        border-radius: 4px;
        display: flex;
        gap: 10px;
        cursor: pointer;

        svg {
          color: black;
          height: 15px;
          width: 15px;
        }
      }
    }

    svg.edit-icon {
      @extend %btn-basic;
      position: absolute;
      top: 10px;
      right: 10px;
      color: $light;
      height: 15px;
      width: 15px;
    }

    .product-image {
      width: 100%;
      height: 100%;
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;

      &.no-image {
        background-color: $lighter;
        color: $light;
        display: grid;
        place-content: center;
      }

      &.original {
        aspect-ratio: unset;
      }

      &.cover {
        object-fit: cover;
      }

      &.contain {
        object-fit: contain;
      }
    }
  }

  .secondary-images {
    @extend %flex-row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 12px;
  }
}

// Need on outside for reorder
.lookbook-sibling-image-container {
  width: calc(25%);
  aspect-ratio: 1;
  border-radius: 8px;
  overflow: hidden;
  padding: 3px;
  position: relative;
  z-index: 100;

  &.original {
    aspect-ratio: unset;
  }

  &.selected {
    .lookbook-sibling-image {
      border: 2px solid $dark;
    }
  }

  .lookbook-sibling-image {
    @extend %btn-basic;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
    border: 2px solid $offwhite;
    display: grid;
    place-content: center;
    background-color: $lighter;
    color: $light;
    font-size: 0.7rem;

    &.cover {
      object-fit: cover;
    }

    &.contain {
      object-fit: contain;
    }
  }

  &.add-new {
    @extend %btn-basic;
    @extend %flex-col;
    padding: 3px;
    color: $light;

    .main {
      @extend %flex-col-middle;
      flex: 1;
      background-color: $lightest;
      border-radius: 8px;
      svg {
        margin: 0 auto;
      }

      .drag-explainer {
        font-size: 8px;
        text-transform: uppercase;
        text-align: center;
        margin-top: 4px;
      }
    }
  }

  .loader {
    transform: scale(0.5);
  }

  &:hover {
    .actions {
      opacity: 1;
    }
  }

  .actions {
    @extend %flex-row-middle;
    gap: 8px;
    position: absolute;
    top: 6px;
    right: 6px;
    padding: 2px 4px;
    font-size: 12px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 4px 8px 4px 4px;
    border-bottom: $border-offwhite;
    border-left: $border-offwhite;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    .remove {
      @extend %btn-basic;
    }

    .sortable-handle {
      font-size: 10px;
    }
  }
}
