@import '../../styles/shared.scss';

.contract-tasks-tab-outer-container {
  background: white;
  padding: 12px 32px 0;

  @include md-down {
    padding: 12px 16px 0;
  }
}
