@import '../../styles/shared.scss';

.brand-sales-outer-container {
  .brand-sales {
    @extend %flex-row-center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;

    .sale {
      @extend %flex-col;
      background-color: $darkest;
      border-radius: 12px;
      padding: 16px 24px;
      max-width: 100%;
      color: white;
      gap: 12px;
      min-width: 240px;

      @include md {
        max-width: calc(50% - 12px);
      }

      .date {
        @extend %flex-row-middle;
        margin-top: 12px;
        font-size: 14px;
        color: $white;
        font-weight: bold;

        svg {
          margin-right: 4px;
          font-size: 14px;
        }
      }

      .description {
        font-size: 14px;
      }

      .action-btn {
        @extend %flex-row-middle;
        align-self: flex-start;
        background: $white;
        color: $darkest;
        border-radius: 4px;
        cursor: pointer;
        padding: 6px 12px;
        margin-top: 8px;

        &.copying {
          background-color: $primary;
          color: $white;
        }

        &.disabled {
          opacity: 0.2;
          pointer-events: none;
        }

        .display {
          @extend %label;
          font-size: 12px;
        }

        svg {
          margin-left: 4px;
          font-size: 11px;
        }
      }
    }
  }
}
