@import '../../../styles/shared.scss';

.latest-section.rates {
  @extend %flex-row;
  flex-wrap: wrap;
  gap: 24px;

  .rate {
    @extend %flex-row;
    width: calc(100%);
    position: relative;
    border-radius: 12px;
    background-color: $darkest;
    transition: transform 0.2s ease-in-out;
    color: white;
    padding: 20px 24px;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      transform: scale(0.98);
    }

    @include md {
      width: calc(50% - 24px / 2);
    }

    @include xl {
      width: calc(33% - 24px / 2);
    }

    .percentage {
      @extend %flex-center;
      border-right: $border-light;
      padding-right: 24px;
      min-width: 80px;
      .value {
        font-size: 32px;
        font-family: $font-header;
        color: white;
        font-weight: bold;
      }
    }

    .data-container {
      flex: 1;
      padding-left: 24px;

      .brand {
        font-size: 24px;
        font-family: $font-header;
        font-weight: bold;
        color: $white;
      }
      .domain {
        @extend %btn-basic;
        font-size: 14px;
        color: $offwhite;
        text-decoration: none;

        svg {
          font-size: 8px;
          margin-left: 4px;
          transform: translateY(-1px);
        }
      }
      .description {
        margin-top: 12px;
        font-size: 13px;
        line-height: 17px;
        color: $lightest;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &:hover {
          color: $lighter;
        }

        &.expanded {
          -webkit-line-clamp: 30;
        }
      }
    }
  }
}
