@import '../../../styles/shared.scss';

.opportunity-setup-guide-intro-outer {
  @extend %flex-center;
  background-color: $offwhite;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 24px;
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
  transition-delay: 400ms;

  &.closing {
    opacity: 0;

    .welcome-section {
      opacity: 0;
    }

    .about-section {
      opacity: 0;
    }

    .setup-actions {
      opacity: 0;
    }
  }

  .opportunity-setup-guide-intro-inner {
    @extend %flex-center-vertical;
    max-width: 440px;
    gap: 46px;
    text-align: center;

    .welcome-section {
      @extend %flex-col;
      gap: 18px;
      transition: opacity 600ms ease-in-out;

      .welcome-badge {
        @extend %label;
        font-size: 12px;
      }

      .welcome-message {
        font-size: 40px;
        font-weight: bold;
        line-height: 1.2;
      }
    }

    .about-section {
      @extend %flex-col;
      gap: 40px;
      transition: opacity 400ms ease-in-out;

      .about-question {
        font-size: 16px;
        font-weight: bold;
      }

      .about-answer {
        font-size: 12px;
        line-height: 1.4;
        color: $medium;
      }
    }

    .setup-actions {
      transition: opacity 200ms ease-in-out;
      .setup-action {
        @extend %btn-outline-new;
        padding: 16px 30px;
        font-size: 14px;
      }
    }
  }
}
