@import '../../styles/shared.scss';

.request-result {
  background: $prettymuchwhite;

  &.needs-action {
    opacity: 1;
    border: 2px solid $primary;
  }
  .cell {
    &.name {
      @extend %link;
    }

    &.action {
      color: $dark;
      position: relative;

      &.taking-action {
        color: transparent;
      }

      &.needs-action {
        font-weight: bold;
        color: $darkest;
      }

      .loader {
        position: absolute;
      }

      &.enabled {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
