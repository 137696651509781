@import '../../../styles/shared.scss';

.artist-modal-pin-mention {
  @extend %flex-row-middle;
  background: white;
  border-radius: 16px;
  padding: 24px 24px;
  border: $border-lightest;

  .data {
    flex: 1;
    margin-right: 24px;

    .details {
      @extend %flex-row-middle;

      .date {
        @extend %label-xs;
        font-size: 10px;
        color: $light;
      }
      .competitor {
        @extend %label;
        background: $primary;
        color: white;
        font-size: 10px;
        padding: 2px 8px;
        border-radius: 12px;
        margin-left: 8px;
      }
    }
    .title {
      font-family: $font-header;
      font-weight: bold;
      font-size: 20px;
      margin-top: 8px;
    }
    .subtitle {
      font-weight: normal;
      font-size: 12px;
      margin-top: 8px;
      color: $light;
    }
    .view-more-btns {
      @extend %flex-col;
      margin-top: 8px;
      gap: 4px;

      .view-more-btn {
        @extend %btn-basic;
        @extend %flex-row-middle;
        @extend %label;
        font-size: 10px;
        color: $darkest;
        text-decoration: none;

        svg {
          font-size: 9px;
          margin-left: 4px;
        }
      }
    }
  }

  .images {
    @extend %flex-row-middle;
    // transform: translateX(120px);
    justify-content: flex-end;
    width: 1;
    width: 180px;
    height: 120px;
    position: relative;

    .image-container {
      margin-right: 12px;
      border-radius: 10px;
      background: white;
      position: relative;
      box-shadow: $shadow-light;
      width: 120px;
      min-width: 120px;
      aspect-ratio: 1;
      position: absolute;

      &.post {
        padding: 0;
      }

      &.pin {
        padding: 4px;
        border: $border-lightest;
      }

      &:nth-child(1) {
        z-index: 3;
        left: 0;
      }
      &:nth-child(2) {
        transform: scale(0.8);
        z-index: 2;
        left: 50px;
        @include md-down {
          display: none;
        }
      }
      &:nth-child(3) {
        transform: scale(0.6);
        z-index: 1;
        left: 90px;
        @include md-down {
          display: none;
        }
      }

      .image {
        width: 100%;
        height: 100%;
        border-radius: 10px;

        &.pin {
          object-fit: contain;
        }
        &.post {
          object-fit: cover;
        }
      }

      .social-icon {
        @extend %flex-col;
        position: absolute;
        bottom: 8px;
        right: 6px;
        justify-content: flex-end;

        img {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }

      .competitor-badge {
        position: absolute;
        top: 100%;
        width: 100%;
        font-size: 10px;
        text-align: center;
        padding-top: 4px;
      }
    }
  }
}
