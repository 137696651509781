@import '../../styles/shared.scss';

.partner-portal-controls-outer {
  .partner-portal-controls-inner {
    font-family: $font-body-new;
    @extend %flex-row;
    gap: 10px;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-bottom: 2rem;

    @include xl {
      flex-direction: row;
    }

    .control-bar-section {
      @extend %flex-row;
      gap: 0.5rem;
      position: relative;

      @include sm-down {
        flex-wrap: wrap;
      }

      &#search-and-filter {
        position: relative;

        .filter-container {
          position: relative;

          .filter-label {
            @extend %flex-row;
            @extend %btn-basic;
            @extend %label;
            align-items: center;
            gap: 10px;
            height: 100%;
            padding: 10px 15px;
            // display: grid;
            // place-items: center;
            border: $border-lightest;
            border-radius: 5px;
            font-size: 12px;
            color: $darkest;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            @include md-down {
              font-size: 0.65rem;
            }

            &:hover {
              border: $border-dark;
            }

            svg {
              @extend %btn-basic;
              height: 10px;
              width: 10px;
            }
          }

          .filter-dropdown {
            @extend %flex-col;
            gap: 10px;
            position: absolute;
            top: calc(100% + 10px);
            left: 50%;
            transform: translateX(-50%);
            background-color: $prettymuchwhite;
            border: $border-lightest;
            padding: 10px;
            border-radius: 5px;
            z-index: 2;

            @include md-down {
              left: 0;
              transform: translateX(0);
            }

            .filter-option {
              @extend %label;
              background-color: white;
              padding: 10px 15px;
              border-radius: 5px;
              font-size: 11px;
              cursor: pointer;
              border: 1px solid transparent;

              @include md-down {
                font-size: 0.65rem;
              }

              &.active {
                border: $border-primary;
              }
            }
          }
        }

        .search-container {
          @extend %flex-row;
          align-items: center;
          background-color: white;
          height: 100%;
          border: $border-lightest;
          border-radius: 500px;
          padding: 10px 15px;

          input {
            border: none;
            outline: none;
            font-family: $font-body-new;
            font-size: 1rem;

            @include sm-down {
              font-size: 0.8rem;
            }
          }
        }
      }

      &.tabs {
        .tab-container {
          @extend %label;
          height: 100%;
          border-radius: 0;
          font-size: 12px;
          color: $darkest;
          border-bottom: 4px solid transparent;
          transition: all 0.2s ease-in-out;
          font-weight: bold;
          position: relative;

          &.active {
            border-bottom: 4px solid $primary;
            color: $darkest;
            cursor: default;
          }

          &.active > .tab {
            border: $border-dark;
            background-color: white;
          }

          .count {
            @extend %flex-center;
            background-color: $lightest;
            border-radius: 4px;
            font-size: 9px;
            margin-left: 5px;
            padding: 3px 6px;
            min-width: 16px;
            font-weight: bold;

            &.new {
              background-color: $primary;
              color: $white;
            }
          }

          .tab {
            @extend %flex-row-middle;
            cursor: pointer;
            padding: 8px 12px;
            border-radius: 4px;
            margin-bottom: 8px;
            transition: all 0.2s ease-in-out;
            border: $border-lighter;

            &.active {
              cursor: default;
            }

            &:hover {
              border: $border-dark;
            }
          }
        }
      }
    }
  }
}
