@import '../../../styles/shared.scss';

.contract-propose-schedule-card {
  .tasks {
    .task {
      @extend %flex-row-middle-spread;

      @include md-down {
        flex-direction: column;
        align-items: stretch;
      }

      &:not(:last-child) {
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: $border-lightest;
      }

      &.total {
        font-weight: bold;
        margin-top: 24px;
      }

      .main {
        @extend %flex-col;
        flex: 1;
        .title {
          font-size: 14px;
          font-weight: bold;
          color: $darker;
        }

        .subtitle {
          font-size: 10px;
          color: $dark;
          text-transform: uppercase;
        }

        .description {
          font-size: 12px;
          color: $dark;

          textarea {
            @extend %input-inline;
            width: 100%;
            resize: none;
            font-size: 11px;
            color: $dark;
            margin-top: 4px;

            &::placeholder {
              font-style: italic;
            }

            @include md-down {
              font-size: 16px;
            }
          }
        }
      }
      .secondary {
        @extend %flex-col;
        align-items: flex-end;

        @include md-down {
          margin-top: 12px;
        }

        .date {
          @extend %btn-basic;
          @extend %flex-row;
          font-size: 12px;
          color: $light;
          font-style: italic;
          text-align: right;

          @include md-down {
            white-space: pre-wrap;
          }

          .text {
            @extend %flex-row-middle;
          }

          &.set {
            color: $dark;
            font-weight: bold;
            font-style: normal;
          }

          &.required {
            color: $dark;
            font-weight: bold;
            font-style: normal;
          }

          &:not(:first-child) {
            margin-top: 4px;
          }

          .current {
            font-size: 13px;
            color: $dark;

            &.active {
              font-weight: bold;
              color: $darkest;
            }
          }

          .edit-actions {
            @extend %flex-row-middle;
            margin-top: -1px;

            .action {
              @extend %btn-basic;
              margin-left: 8px;
              svg {
                font-size: 10px;
                color: $light;
              }
              &.delete {
                margin-right: -24px;
                width: 16px;
              }
            }
          }
          .action {
            @extend %btn-basic;
          }
        }
      }
    }
  }
}
