@import '../../styles/shared.scss';

.blog-tab-modal-outer {
  .blog-tab-modal-title {
    font-family: $font-header;
    font-weight: bold;
    letter-spacing: 0.7px;
    // font-size: 28px;
    font-size: 2rem;
    line-height: 36px;
    margin-bottom: 10px;
  }

  .blog-tab-modal-tabs,
  .blog-tab-modal-add-container {
    @extend %flex-col;
    gap: 10px;
    padding: 1rem 0;
  }
}
