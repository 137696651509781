@import '../../styles/shared.scss';

.mentions-outer-container {
  @extend %flex-col;
  width: 100%;
  position: relative;
  font-family: $font-body-new;
  background: $offwhite;
  min-height: 100vh;

  .mentions-inner-container {
    @extend %flex-row-center;
    position: relative;
    max-width: 1240px;
    width: calc(100vw - 48px);
    margin: 120px auto;
  }
}
