@import '../../styles/shared.scss';

.artist-modal-talent-report-container {
  padding: 12px 24px;

  .report-section {
    margin-bottom: 24px;
    border: $border-lightest;
    padding: 24px;
    border-radius: 16px;
    position: relative;

    .section-header-section {
      margin-bottom: 8px;

      .section-header {
        @extend %label;
        font-size: 11px;
      }
      .section-subheader {
        font-size: 9px;
        font-style: italic;
        max-width: 60%;
      }
    }

    .section-header-btns {
      @extend %flex-row-middle;
      gap: 8px;
      position: absolute;
      top: 16px;
      right: 24px;
      width: min-content;

      .custom-badge {
        @extend %label;
        font-size: 8px;

        svg {
          margin-right: 4px;
        }
      }

      .section-header-btn {
        @extend %btn-outline-new;
        display: flex;
        justify-content: space-between;
        font-size: 11px;
        gap: 2px;

        &.save {
          color: $primary;
          border-color: $primary;
        }
        svg {
          margin-right: 2px;
        }
      }
    }
    .section-body {
      .analysis {
        @extend %paragraph-xs;

        &.unsubscribed {
          filter: blur(2px);
        }

        .description-summary-textarea {
          resize: none;
          width: 100%;
          font: inherit;
        }
      }

      .brands,
      .tags {
        @extend %flex-row;
        flex-wrap: wrap;
        gap: 8px;

        .brand,
        .tag {
          @extend %flex-row-middle;
          gap: 4px;
          font-size: 11px;
          padding: 3px 8px;
          border: $border-dark;
          border-radius: 12px;

          .count {
            font-size: 10px;
            margin-left: 4px;
          }

          &.hidden {
            filter: blur(2px);
          }

          &.bold {
            // background-color: $darker;
            // color: $offwhite;
          }
        }
      }

      .requests {
        @extend %flex-col;
        gap: 8px;

        .request {
          font-size: 12px;
          color: $darkest;

          .title {
            font-size: 14px;
            color: $darkest;
            font-weight: bold;
          }
          .status {
            font-size: 10px;
            color: $dark;
          }
        }
      }
    }
  }
}
