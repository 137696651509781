/*
  To add a shimmer effect over an item.

  For example:
    &.empty {
      @extend %skeleton-box-overlay;
      position: relative;
    }
*/
%skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: $prettymuchwhite;
  transition: all 0.8s linear;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba($offwhite, 0) 0, rgba($offwhite, 0.2) 20%, rgba($offwhite, 0.5) 60%, rgba($offwhite, 0));
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  &.loaded {
    pointer-events: none;
    background-color: transparent;

    &::after {
      opacity: 0;
    }

    .el {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .el {
    transition: all 0.8s linear;
    opacity: 0;
  }
}

%skeleton-box-offwhite {
  @extend %skeleton-box;

  background: $offwhite;
  &::after {
    background-image: linear-gradient(90deg, rgba($lighter, 0) 0, rgba($lighter, 0.2) 20%, rgba($lighter, 0.5) 60%, rgba($lighter, 0));
  }
}

%skeleton-box-overlay {
  @extend %skeleton-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

%skeleton-box-overlay-offwhite {
  @extend %skeleton-box-overlay;
  @extend %skeleton-box-offwhite;
}

%skeleton-box-whynotusewhite {
  @extend %skeleton-box;
  background: $whynotusewhite;
}

%skeleton-box-overlay-delay {
  @extend %skeleton-box-overlay;
  transition-delay: 0.4s;

  .el {
    transition-delay: 0.4s;
  }
}
