@import '../../../styles/shared.scss';

.latest-section.collections {
  @extend %flex-col;
  gap: 12px;

  .collection {
    text-decoration: none;
    transition: box-shadow 0.2s ease-in-out;
    position: relative;
    cursor: pointer;

    &:hover {
      .pin-previews {
        .pin-preview {
          transform: translateY(-4px);

          &:nth-child(1) {
            transition-delay: 50ms;
          }
          &:nth-child(2) {
            transition-delay: 100ms;
          }
          &:nth-child(3) {
            transition-delay: 150ms;
          }
          &:nth-child(4) {
            transition-delay: 200ms;
          }
          &:nth-child(5) {
            transition-delay: 250ms;
          }
          &:nth-child(6) {
            transition-delay: 300ms;
          }
        }
      }
    }

    .pin-previews {
      @extend %flex-row-middle-spread;
      gap: 20px;
      padding: 24px 48px 0;
      background-color: white;
      border-radius: 12px;

      .pin-preview {
        transition: transform 0.2s ease-in-out;
        padding: 0 8px;
        flex: 1;

        img {
          width: 100%;
          aspect-ratio: 1;
          object-fit: contain;
          border-radius: 8px;
        }

        &:nth-child(1) {
          transition-delay: 50ms;
        }
        &:nth-child(2) {
          transition-delay: 100ms;
        }
        &:nth-child(3) {
          transition-delay: 150ms;
        }
        &:nth-child(4) {
          transition-delay: 200ms;
        }
        &:nth-child(5) {
          transition-delay: 250ms;
        }
        &:nth-child(6) {
          transition-delay: 300ms;
        }
        &:nth-child(3),
        &:nth-child(4) {
          @include md-down {
            display: none;
          }
        }
        &:nth-child(5),
        &:nth-child(6) {
          @include lg-down {
            display: none;
          }
        }
      }
    }

    .meta {
      @extend %flex-col;
      background-color: white;
      padding: 24px;
      transform: translateY(-16px);
      border-radius: 0 0 12px 12px;
      position: relative;

      .shelf-shadow {
        width: 100%;
        height: 10px;
        position: absolute;
        left: 0;
        top: -24px;
        height: 24px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), transparent);
      }

      .view-btn {
        @extend %flex-row-middle;
        @extend %label;
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 12px;
        color: $darkest;
        text-decoration: none;

        svg {
          margin-left: 6px;
          font-size: 10px;
        }

        @include md-down {
          display: none;
        }
      }

      .user {
        @extend %flex-row-middle;
        font-family: $font-header;
        font-size: 20px;
        color: $darkest;
        font-weight: bold;
        text-decoration: none;

        img {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 4px;
        }

        .name {
          margin-left: 3px;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .title {
        font-size: 14px;
        color: $darkest;
        font-weight: 500;
        text-decoration: none;
        margin-top: 8px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
