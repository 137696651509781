@import '../../styles/shared.scss';

.pro {
  .footer-old-outer-shell {
    margin-top: 120px;
    font-family: $font-body-new;
    background: white;
    padding: 24px 24px 64px;

    &.home {
      margin-top: 0;
      // background: linear-gradient(165deg, rgba(0, 0, 0, 0.8), black);
      padding: 120px 24px 80px;
      border-top: 10px solid white;

      .footer-outer-container {
        .footer-home-link,
        .footer-btns {
          // color: white;
          font-weight: normal;
        }
      }
    }

    .footer-disclaimer-container {
      width: 100%;
      margin: 0 auto;
      max-width: 1024px;
      border-top: 2px solid $lightest;
      margin-top: 80px;

      .footer-disclaimer {
        color: $medium;
        font-size: 12px;
        width: 100%;
        padding: 24px 26px 48px;

        @include sm {
          width: 60%;
          padding: 24px 26px 48px;
        }
      }
    }

    .footer-outer-container {
      @extend %flex-col-middle;
      width: 100%;
      z-index: 5;

      @include sm {
        padding: 24px 24px;
      }

      .footer-home-link {
        @extend %flex-col-center;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        color: $dark;

        .footer-public-logo-header {
          font-size: 12px;
          margin-bottom: -2px;
        }

        .footer-logo {
          font-size: 20px;
          font-family: $font-header;
          font-weight: bold;
        }

        &:hover {
          opacity: 0.6;
        }
      }

      .footer-btns {
        @extend %flex-center;
        margin-top: 88px;
        font-size: 13px;
        color: $dark;
        max-width: 100%;
        flex-wrap: wrap;

        .footer-btn {
          margin: 8px 8px;
          cursor: pointer;
          text-decoration: none;
          color: white;
        }
      }
    }
  }
}
