@import '../../../styles/shared.scss';

.accept-lookbook-invite-modal-outer-container {
  .accept-lookbook-invite-modal-inner-container {
    width: 80vw;
    max-width: 400px;
    border-radius: 20px;
    overflow: hidden;
    height: auto;
    top: auto;

    .accept-lookbook-invite-modal {
      padding: 36px 36px 24px !important;

      .invite-header {
        margin-bottom: 12px;

        .header {
          margin: 0 0 12px;
          font-size: 32px;
          line-height: 1.3;
          font-family: $font-header;
        }
        .subheader {
          margin: 0;
          font-size: 16px;
          font-weight: 400;
        }
      }

      .actions {
        @extend %flex-col;
        margin-top: 12px;
        gap: 8px;

        .action {
          font-size: 14px;

          &.primary {
            @extend %btn-darkest-new;
            padding: 12px 24px;
          }

          &.secondary {
            @extend %btn-text-new;
            padding: 8px 24px;
          }
        }
      }
    }
  }
}
