@import '../../styles/shared.scss';

.bulk-request-modal-search-outer {
  .bulk-request-modal-search-inner {
    color: white;
    border-radius: 5px;
    width: 100%;

    &.isFocused {
      transition: background-color 0.3s ease-out;
      background-color: black;
    }

    .generate-invite-container {
      padding: 20px;
      border: $border-lightest;
      border-radius: 16px;
      margin-top: 1rem;
      color: black;

      .generate-invite-container-title {
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .generate-invite-container-subtitle {
        font-size: 0.75rem;
        margin-bottom: 15px;
      }

      .generated-links-container {
        @extend %flex-col;
        margin: 20px 0;
        gap: 5px;

        .generated-link {
          @extend %flex-row;
          @extend %btn-basic;
          align-items: center;
          gap: 10px;
          font-size: 0.75rem;
          font-weight: 600;
          padding: 4px 9px;
          border-radius: 5px;
          background-color: $offwhite;
          width: max-content;

          svg {
            width: 10px;
            height: 10px;
          }
        }
      }

      .generate-invite-link-button {
        @extend %btn-basic;
        @extend %label;
        background-color: black;
        color: white;
        font-size: 0.7rem;
        padding: 9px 13.5px;
        border-radius: 5px;
        outline: none;
        border: none;
        width: max-content;

        &.done-button {
          background-color: $primary;
          margin-left: 10px;
        }
      }
    }

    .bulk-request-modal-search-input-outer {
      width: 250px;
      background-color: $offwhite;
      position: relative;
      border-radius: 4px;
      color: $darkest;

      .generate-invite-link-filler-text {
        margin: 0 1rem;
      }

      .generate-invite-link-button {
        @extend %btn-basic;
        @extend %label;
        background-color: white;
        color: black;
        font-size: 0.8rem;
        padding: 12px 14px;
        border-radius: 5px;
        outline: none;
        border: $border-dark;
      }

      &.no-results {
        background-color: black;

        input {
          &::placeholder {
            color: white;
          }
        }
      }

      input {
        padding: 14px 16px;
        background-color: transparent;
        border: none;
        font-size: 0.9rem;
        outline: none;
        width: 100%;
        font-family: $font-body-new;
        color: white;
      }

      &.isFocused {
        width: 100%;
        transition: width 0.3s ease-out;
        background-color: black;
      }

      &:not(.isFocused) {
        white-space: nowrap;
        -webkit-appearance: none;
        appearance: none;

        input {
          @extend %btn-basic;
          @extend %label;
          font-size: 0.8rem;
          text-align: center;
          cursor: pointer;
        }
      }

      .bulk-request-modal-search-buttons {
        @extend %flex-row;
        gap: 10px;
        position: absolute;
        right: 10px;
        top: 10px;
        align-items: center;
        opacity: 0;
        pointer-events: none;

        &.isFocused {
          opacity: 1;
          pointer-events: all;
          transition: opacity 0.3s ease-out;
          transition-delay: 0.3s;
        }

        .bulk-request-modal-search-button {
          @extend %btn-basic;
          @extend %label;
          background-color: white;
          color: black;
          font-size: 0.8rem;
          padding: 12px 14px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          outline: none;
          cursor: pointer;

          &.done {
            background-color: $primary;
            color: white;
          }
        }
      }
    }
  }
}
