@import '../../../styles/shared.scss';

.additional-actions-container {
  align-self: flex-end;
  position: relative;

  &:hover {
    .additional-actions-overlay {
      pointer-events: auto;
      opacity: 1;
    }
  }

  .clear-talent-hover {
    @extend %flex-row-middle;
    @extend %label;
    padding-top: 12px;
    gap: 4px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 10px;
    color: $darker;
  }

  .additional-actions-overlay {
    position: absolute;
    top: 24px;
    right: 8px;
    background-color: white;
    box-shadow: $shadow-dark;
    padding: 12px;
    border-radius: 8px 0 8px 8px;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    width: 240px;
    max-height: 320px;
    overflow: auto;

    .additional-actions {
      @extend %flex-col;
      gap: 20px;

      .additional-action-group {
        .group-title {
          @extend %label;
          font-size: 9px;
          color: $light;
          margin-bottom: 4px;
        }

        .group-actions {
          @extend %flex-col;
          gap: 4px;

          .additional-action {
            @extend %flex-row-middle-spread;
            font-weight: bold;
            font-size: 11px;
            color: $darker;
            letter-spacing: 0.2px;
            padding: 4px 6px;
            border-radius: 4px;
            border: $border-lighter;
            gap: 8px;
            cursor: pointer;
            position: relative;

            &.disabled {
              color: $light;
              font-weight: normal;
              border: $border-offwhite;

              .count {
                font-weight: normal;
                color: $light;
                font-size: 10px;
              }
            }

            &:hover:not(.disabled) {
              background-color: $prettymuchwhite;
              color: $darkest;

              .count {
                opacity: 0;
              }

              .clear-overlay {
                opacity: 1;
              }
            }

            .count {
              cursor: pointer;
              font-weight: bold;
              min-width: 8px;
              opacity: 1;
            }

            .clear-overlay {
              @extend %flex-center;
              position: absolute;
              pointer-events: none;
              right: 6px;
              height: 100%;
              font-size: 10px;
              color: $dark;
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
