@import '../../styles/shared.scss';

.list-similar-lists-container {
  .lists {
    @extend %flex-row;
    flex-wrap: wrap;
    gap: 16px;

    .list {
      background-color: black;
      border: $border-white;
      border-radius: 4px;
      width: calc(50% - 16px / 2);
      padding: 8px 12px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      text-decoration: none;
      position: relative;

      &:hover {
        border: $border-light;
      }

      .title {
        @extend %label;
        text-align: center;
        color: white;
        font-size: 18px;
      }

      .delete-list {
        color: white;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 12px;
        padding: 8px;
      }
      .sortable-handle {
        color: white;
        position: absolute;
        right: 12px;
        top: 0;
        font-size: 12px;
        padding: 8px;
      }
    }
  }
}
