@import '../../styles/shared.scss';

.catalog-groups-container {
  .add-group-button {
    justify-self: flex-end;
    @extend %btn-primary-new;
    padding: 6px 12px;
    width: min-content;
    height: 35px;
    text-wrap: nowrap;
    font-size: 10px;
    display: flex;
    gap: 4px;
    margin-bottom: 14px;
  }

  .connected-groups-list {
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;
  }

  .catalog-groups-disabled {
    font-size: 14px;
    text-transform: uppercase;
    color: $medium;
    text-align: center;
  }

  .no-results {
    font-size: 14px;
    color: $medium;
    text-align: center;
    width: 100%;
  }

  .catalog-groups-setup {
    width: 100%;
    background: $white;
    padding: 60px 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    .setup-header {
      font-family: $font-header;
      font-size: 24px;
    }

    .setup-subheader {
      font-size: 14px;
      color: $light;
    }
  }
}
