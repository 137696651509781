@import '../../../../styles/shared.scss';

.shopify-integration-panel-outer {
  .shopify-integration-panel-inner {

    .integration-select-container {
      font-size: 14px;

      .description {
        font-family: $font-body-new !important;
        margin-bottom: 8px;
      }
    }


    .shopify-integration-select-field {
      .title {
        font-size: 14px;
      }
    }

    .secondary-checkboxes {
      margin-left: 32px;
    }

    .location-info-container {
      margin-left: 22px;

      .location-select {
        .location-infinite-scroll-select {
          font-size: 12px;
        }
      }
    }
  }
}
