@import '../../styles/shared.scss';

.discover-users-result-outer-container {
  min-height: 320px;
  padding: 8px;
  width: 100%;

  @include sm {
    width: 50%;
  }

  @include md {
    width: 33%;
  }

  @include xl {
    width: 25%;
  }

  @include xxl {
    width: 25%;
    width: 20%;
  }

  .discover-users-result-inner-container {
    @extend %flex-col;
    background: white;
    border-radius: 16px;
    box-shadow: $shadow-light;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .image-section {
      position: relative;

      .image {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        max-height: 200px;
        min-height: 200px;
        &.profile-image {
          @extend %animation-fade-with-delay;
          object-fit: cover;
          background: $offwhite;
        }

        &.empty {
          @extend %flex-center;
          height: 100%;
          width: 100%;
          background: $darkest;
          font-family: $font-header;
          font-size: 48px;
          color: white;
        }
      }

      .header-controls-background {
        transition: opacity 0.3s;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 50%;
        width: 100%;
        background-image: linear-gradient(195deg, #000, transparent, transparent);
        opacity: 0.25;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }

      .header-controls {
        @extend %flex-row-middle-spread;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;

        .chat {
          @extend %btn-basic;
          padding: 6px 8px 0;
          color: white;
        }
        .social-links {
          @extend %flex-row;
          padding: 6px 4px 0;
          border-radius: 4px;
          align-items: flex-start;
          justify-content: flex-end;

          a.social-link {
            margin: 0 2px;
            max-width: 15px;

            svg {
              font-size: 14px;
              margin-bottom: 3px;
              color: $white;
            }

            img {
              color: $dark;
              height: 15px;
              width: 15px;
              object-fit: cover;
              border-radius: 3px;
              overflow: hidden;
            }

            svg,
            img {
              cursor: pointer;

              &:hover {
                opacity: 0.6;
              }
            }
          }
        }
      }

      .footer-stats {
        @extend %flex-row-middle-spread;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 8px 8px;
        .footer-stat {
          color: white;
          font-weight: bold;
        }
      }
      .footer-stats-background {
        transition: opacity 0.3s;
        content: '';
        display: block;
        position: absolute;
        bottom: 4px;
        right: 0;
        height: 50%;
        width: 100%;
        background-image: linear-gradient(0deg, #000, transparent, transparent);
        opacity: 0.25;
      }
    }
    .main-section {
      @extend %flex-col;
      flex: 1;
      justify-content: space-between;
      padding: 12px;

      .profile-section {
        color: $dark;
        .name {
          text-transform: uppercase;
          color: $darker;
          font-size: 16px;
          line-height: 1;
          margin-bottom: 4px;
          font-weight: bold;
        }
        .description {
          color: $darker;
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
      .tags-section {
        margin-top: 12px;

        .tags {
          @extend %flex-row-middle;
          flex-wrap: wrap;
          margin-bottom: 4px;
          .tag {
            @extend %btn-basic;
            font-size: 9.5px;
            padding: 2px 4px;
            margin: 0 3px 3px 0;
            border-radius: 12px;
            text-transform: uppercase;
            border: $border-lighter;

            &.highlighted {
              background: $dark;
              color: white;
              border: $border-dark;
              font-weight: bold;
            }

            svg {
              margin: 0px 0 -0.5px 4px;
            }
          }
        }

        .primary-actions {
          @extend %flex-row-middle;
          margin-top: 12px;

          .select-btn {
            @extend %btn-basic;
            @extend %flex-center;
            flex: 1;
            padding: 8px 12px;
            background: $darkest;
            color: white;
            border-radius: 4px;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.4px;
          }

          .select-list-btn {
            @extend %btn-basic;
            margin-left: 8px;
            color: $lighter;
            position: relative;

            svg {
              font-size: 18px;
            }

            &.active {
              color: $darkest;
            }

            .list-count-badge {
              @extend %flex-center;
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              font-size: 9px;
              font-weight: bold;
              color: white;
              margin-top: -1px;
            }
          }
        }

        .secondary-actions {
          @extend %flex-col;
          margin-top: 10px;
          .action {
            @extend %btn-basic;
            text-align: center;
            text-transform: uppercase;
            font-size: 10px;
            color: $medium;
            font-weight: bold;
            &:not(:last-child) {
              margin-bottom: 6px;
            }
          }
        }

        .recommend-btn {
          @extend %btn-basic;
          @extend %flex-center;
          flex: 1;
          padding: 6px 8px;
          background: white;
          color: $darker;
          border: $border-dark;
          border-radius: 4px;
          font-size: 12px;
          font-weight: bold;
          margin-top: 4px;

          &.recommended {
            border: $border-lighter;
            color: $light;

            svg {
              margin-left: 4px;
            }
          }
        }

        .make-top {
          @extend %btn-basic;
          @extend %flex-center;
          flex: 1;
          padding: 6px 2px;
          margin-left: 4px;
          background: white;
          color: $darker;
          border: $border-dark;
          border-radius: 4px;
          font-size: 12px;
          font-weight: bold;
          margin-top: 4px;
        }

        .btn-with-action {
          @extend %flex-row-middle;
          flex-wrap: nowrap;
        }
      }
    }
  }
}
