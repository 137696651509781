@import '../../styles/shared.scss';

.codes-outer-container {
  @extend %flex-col;
  width: 100%;
  padding: 40px 24px 0;
  position: relative;
  font-family: $font-body-new;
  background: $offwhite;

  .codes-inner-container {
    @extend %flex-col;
    min-height: 800px;
    width: 100%;
    max-width: 1024px;
    margin: 120px auto;
    transform: translateY(-120px);

    .codes-header {
      @extend %flex-row-middle-spread;

      .search-input {
        @extend %flex-row-middle;
        max-width: 400px;
        width: 100%;
        margin-bottom: 12px;

        input {
          flex: 1;
          @extend %input-shared;
        }
      }

      .actions {
        @extend %flex-row-middle;
        gap: 12px;
        margin-left: 24px;

        .sort-control {
          min-width: 200px;
          font-size: 12px !important;
        }

        .action {
          @extend %btn-darkest-new;
          padding: 6px 12px;

          svg {
            margin-right: 6px;
            font-size: 14px;
          }
        }
      }
    }

    .empty-results {
      @extend %flex-center;
      background: white;
      border-radius: 16px;
      padding: 24px;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
      color: $medium;
    }
  }
}
