@import '../../styles/shared.scss';

.consult-header-outer-container {
  margin-top: 48px;

  .management-btns {
    @extend %flex-row;
    position: absolute;
    top: 24px;
    right: 16px;

    .live-view-switch {
      @extend %flex-row-middle;
      padding: 8px 12px;
      color: $dark;
      font-size: 11px;
      margin-left: 12px;

      .switch {
        margin: -1px 0 0 4px;
      }
    }

    .view-as-public-switch {
      @extend %flex-row-middle;
      padding: 8px 12px;
      color: $dark;
      font-size: 11px;

      .switch {
        margin: -1px 0 0 4px;
      }
    }

    .share-btn {
      @extend %btn-outline;
      text-transform: uppercase;
      padding: 6px 12px;
    }
  }

  .consult-header-container {
    margin-bottom: 32px;
    .back-link {
      margin-bottom: 24px;
      &.btn {
        @extend %btn-text;

        svg {
          margin-right: 12px;
        }
      }

      text-decoration-line: none;
      font-family: $font-body;
      font-size: 14px;
      font-weight: bold;
      color: $dark;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .back {
        width: 16px;
        height: 18px;
        margin-right: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $dark;
      }
    }

    .consult-header-inner-container {
      @extend %flex-col;
      padding-bottom: 12px;
      // border-bottom: 2px solid $offwhite;

      .consult-title {
        font-family: $font-header;
        font-size: 40px;
        line-height: 1.2;
        letter-spacing: -0.27px;
        font-weight: bold;
        color: $darker;
        margin: 0;
        white-space: pre-line;
        margin-bottom: 12px;
      }

      .consult-description {
        font-family: $font-body;
        font-size: 14px;
        line-height: 1.2;
        font-weight: normal;
        color: $darker;
        margin: 0;
        letter-spacing: -0.27px;
        white-space: pre-line;
      }

      .title-input-container {
        @extend %input-inline;
        font-weight: bold;
        margin-bottom: 0px;
        background-color: transparent;
        font-family: $font-header;
        letter-spacing: -0.27px;
        font-size: 40px;
        line-height: 1.2;
        margin-bottom: 12px;
        color: $darker;
        width: 100%;
        resize: none;
      }

      .description-input-container {
        @extend %input-inline;
        font-weight: normal;
        margin-bottom: 0px;
        background-color: transparent;
        font-family: $font-body;
        letter-spacing: -0.27px;
        font-size: 16px;
        line-height: 1.2;
        width: 100%;
        resize: none;
      }
    }

    .meta-data-container {
      @extend %flex-col;
      align-items: flex-start;
      align-self: flex-start;
      flex-wrap: wrap;

      @include md {
        flex-direction: row;
      }

      .section {
        @extend %flex-row-middle;
        padding: 0 0 6px;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 1;
        color: $darker;
        min-height: 16px;
        width: 100%;

        @include md {
          padding: 0 12px;
          width: auto;
          border-right: $border-dark;

          &:last-child {
            border-right: none;
            padding: 0 12px;
          }

          &:first-child {
            padding: 0 12px 0 0;
          }
        }

        .edit-icon {
          cursor: pointer;
        }
      }
    }
  }
}
