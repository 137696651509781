@import '../../styles/shared.scss';

.consult-preview-outer-container {
  padding: 0px 16px;

  @include md {
    padding: 0;
    border-radius: 0;
  }

  .consult-preview-container {
    @extend %flex-col;
    width: 100%;
    margin-bottom: 32px;
    position: relative;
    font-family: $font-body;
    transition: box-shadow 0.15s ease-in-out;
    border-bottom: $border-lighter;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    overflow: hidden;

    @include sm {
      border-bottom: none;
    }

    &:hover,
    &:active {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }

    .consult-preview-inner-container {
      overflow: hidden;
      padding: 16px 20px 16px;
      min-height: 124px;
      position: relative;
      text-decoration: none;
      cursor: pointer;
      background: $white;
      color: $dark;

      &:hover {
        .background-image {
          transform: translateY(-2px);
        }
      }

      &.hidden {
        box-shadow: none;

        &:hover {
          box-shadow: none;
        }
        .content {
          opacity: 0.4;
        }

        .background-image {
          opacity: 0.4;
        }
      }
      .content {
        width: calc(100%);
        z-index: 2;

        @include sm {
          &.has-image {
            padding-bottom: 0;
            width: calc(100% - 208px);
          }
        }

        .headline {
          @extend %flex-row-middle;
          font-size: 13px;
          font-weight: bold;
          color: $dark;
          text-transform: uppercase;
          margin-bottom: 4px;

          .el {
            margin-right: 4px;
            padding-right: 4px;
            border-right: $border-dark;

            &:last-child {
              border-right: none;
            }
          }
        }
        .title {
          color: $darker;
          font-family: $font-header;
          font-size: 20px;
          letter-spacing: 0.08px;
          line-height: 27px;
          margin: 0;
        }
        .subtitle {
          color: $dark;
          font-size: 13px;
          letter-spacing: -0.04px;
          line-height: 15px;
          font-weight: normal;
          margin: 4px 0 0;
          white-space: pre-line;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          font-size: 14px;
          letter-spacing: 0.06px;
          margin-top: 4px;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;

          &.empty {
          }
        }

        .get-started-link {
          @extend %flex-row-middle;
          font-size: 12px;
          font-weight: bold;
          color: $dark;
          text-transform: uppercase;
          text-decoration: underline;
          margin-top: 48px;

          @include sm {
            margin-top: 24px;
          }

          svg {
            margin-left: 4px;
            font-size: 10px;
            color: $medium;
          }
        }
      }

      .background-image-container {
        position: relative;
        position: absolute;
        height: calc(120px * 0.75);
        width: calc(200px * 0.75);
        min-height: calc(120px * 0.75);
        min-width: calc(200px * 0.75);
        bottom: -18px;
        right: 2px;

        @include sm {
          height: calc(120px * 1.1);
          width: calc(200px * 1.1);
        }

        &:hover {
          .background-image:not(.public) {
            opacity: 0.25;
          }
          .update-background-image-label-container {
            opacity: 1;
          }
        }

        .background-image {
          height: 100%;
          width: 100%;
          object-fit: contain;
          transition: all 0.2s ease-in-out;

          &.public {
            @extend %animation-fade;
          }

          &.empty {
            @extend %flex-center-vertical;
            background: $whynotusewhite;
            border: $border-offwhite;
            border-radius: 10px;
            padding-bottom: 16px;
            color: $lighter;
            font-size: 12px;
            font-weight: bold;

            svg {
              font-size: 32px;
              color: $lightest;
              padding-bottom: 6px;
            }
          }
        }

        .update-background-image-label-container {
          @extend %flex-center;
          pointer-events: none;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: opacity 0.2s ease-in-out;

          .update-background-image-label {
            @extend %flex-center-vertical;
            border-radius: 4px;
            padding: 16px;
            color: $darker;
            font-size: 12px;
            font-weight: bold;

            svg {
              font-size: 32px;
              color: $dark;
              padding-bottom: 6px;
            }
          }
        }
      }

      .icn-show {
        position: relative;
        cursor: pointer;
        color: $medium;
        position: absolute;
        right: 8px;
        bottom: -6px;
        font-size: 18px;
        font-weight: bold;

        .show-action-items {
          padding: 0 4px 4px;
          font-size: 22px;
          svg {
            path {
              stroke: white;
              stroke-width: 32px;
            }
          }
        }

        .action-items-container {
          @extend %flex-col;
          background: white;
          box-shadow: $shadow-dark;
          border-radius: 4px;
          position: absolute;
          bottom: 28px;
          right: 20px;
          z-index: 1;
          padding: 4px 0;
          border-radius: 8px;
          border-bottom-right-radius: 0;

          .item {
            color: $dark;
            cursor: pointer;
            font-size: 14px;
            white-space: nowrap;
            padding: 4px 12px;

            &:hover {
              opacity: 0.4;
            }
          }
        }
      }
    }

    .tag-container {
      @extend %flex-center;
      position: absolute;
      top: 48px;
      width: 100%;
      z-index: 2;
      pointer-events: none;

      .notification-tag {
        text-align: center;
        white-space: nowrap;
        background: white;
        padding: 4px 12px;
        border-radius: 4px;
        font-weight: bold;
        color: $darker;
        box-shadow: $shadow-dark;
      }
    }

    .analytics-preview-container {
      top: 8px;
    }
  }
}
