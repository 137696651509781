@import '../../styles/shared.scss';

.opportunity-request-progress-outer-container {
  background-color: white;
  border-radius: 20px;
  padding: 24px;

  @include md-down {
    border-radius: 0;
  }

  .progress-alert-container {
    @extend %flex-row-middle;
    padding: 16px 24px;
    border: $border-lightest;
    border-radius: 10px;
    background-color: $darkest;
    position: relative;
    gap: 16px;
    padding: 16px 24px;

    @include md-down {
      gap: 12px;
      margin-bottom: 24px;
    }

    .progress-alert-speaker {
      @extend %flex-center;
      min-width: 36px;
      min-height: 36px;
      height: 36px;
      border-radius: 50%;
      color: $darkest;
      background-color: $white;
      font-weight: bold;
      font-size: 24px;
      font-family: $font-header;
      border: $border-white;

      @include md-down {
        display: none;
      }

      .progress-alert-speaker-inner {
        transform: translateY(2px);
      }
    }

    .progress-alert-body {
      @extend %flex-col;
      gap: 8px;
      flex: 1;

      border-radius: 12px 12px 12px 12px;
      position: relative;

      .progress-alert {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.4;
        color: white;

        @include md-down {
          font-size: 14px;
        }

        span.focus {
          color: $white;
          padding: 2px 12px;
          margin: 4px 2px;
          border-radius: 2px;
          background-color: $primary;

          @include md-down {
            padding: 0px 6px;
          }
        }
      }
    }

    .small-parts {
      position: absolute;
      top: 100%;
      right: 0;
      padding-top: 8px;
      color: $medium;
      font-size: 11px;
      align-self: flex-end;

      @include md-down {
        font-size: 10px;
      }
    }
  }

  .ftc-guidelines-outer {
    .ftc-guidelines-inner {
      font-size: 10px;
      color: $light;
      text-align: right;

      a {
        color: $medium;
        cursor: pointer;
        font-weight: 500;
      }
    }
  }
}
