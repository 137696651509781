@import '../../styles/shared.scss';

.shop-results-outer-container {
  @extend %flex-col;
  flex: 1;

  .results-header {
    @extend %flex-row-middle-spread;
    text-transform: uppercase;
    color: $dark;
    margin: 0 16px 16px;

    .show-more {
      display: none;
      cursor: pointer;
    }

    @include md {
      margin: 0 16px 16px;

      .show-more {
        font-weight: bold;
        display: flex;
      }
    }
  }

  .shop-results-top-artists-container {
    width: 100%;
    margin-bottom: 16px;

    .results-row {
      @extend %flex-row;
      justify-content: space-between;
      overflow: auto;
      padding: 0 16px 16px;

      @include md {
        overflow: hidden;
        padding: 0 16px 16px;
        margin-left: 0;
        width: 100%;
      }
    }
  }

  .shop-results-container {
    @extend %flex-row;
    flex-wrap: wrap;
  }

  .long-description-header {
    padding: 0 24px 16px;
    padding-top: 54px;
    margin-top: 54px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    border-top: $border-offwhite;
  }

  .long-description-container {
    justify-content: space-between;
    overflow: auto;
    padding: 0 24px 16px;
    width: 100%;
    color: $darker;
    font-family: $font-body;
    white-space: pre-line;

    @include md {
      overflow: hidden;
      padding: 0 24px 16px;
      margin-left: 0;
      // width: 100%;
      align-self: stretch;
    }

    a {
      xdisplay: inline;
      font-weight: bold;
      color: $darker;
    }
  }
}
