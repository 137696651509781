@import '../../styles/shared.scss';

.blog-outer {
  // max-width: 1240px;
  margin: 0 auto;
  width: min(100%, 1240px);
  font-family: $font-body-new;
  scroll-behavior: smooth;

  .blog-inner {
    padding: 32px 64px;
    margin: 0 auto;

    @include md-down {
      padding: 32px 24px;
    }

    @include xs-down {
      padding: 32px 15px;
    }

    .blog-admin-button {
      @extend %flex-row-middle;
      gap: 2px;
      position: fixed;
      bottom: 40px;
      left: 10px;
      background: white;
      z-index: 1000;
      border-radius: 8px;
      padding: 2px 8px;
      border: $border-dark;
      font-size: 12px;
      font-weight: bold;
      cursor: pointer;
      color: $darkest;
      font-family: $font-body;

      &:hover {
        color: $dark;
      }

      + .blog-admin-button {
        transform: translateY(-30px);
      }
    }

    .blog-header {
      margin-bottom: 2rem;
      @extend %flex-row;
      justify-content: space-between;

      @include sm-down {
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }

      .blog-title {
        font-family: $font-social;
        font-weight: 700;
        text-decoration: none;
        color: $darkest;
        font-size: 100px;
        cursor: pointer;

        @include xxl-down {
          font-size: 100px;
        }

        @include xl-down {
          font-size: 72px;
        }

        @include md-down {
          font-size: 52px;
        }
      }

      .blog-button-container {
        @extend %flex-col;
        justify-content: center;
        align-items: flex-end;

        .blog-search-container {
          font-size: 28px;
          @extend %flex-row;
          align-items: center;

          @include xl-down {
            font-size: 24px;
          }

          @include md-down {
            font-size: 20px;
          }

          .blog-search-input {
            @extend %input-shared;
            flex: 1;
            font-size: 12px;
            margin-right: 15px;
            width: 200px;

            @include sm-down {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
