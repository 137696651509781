@import '../../../styles/shared.scss';

.home-faq-outer-container {
  width: 100vw;
  padding: 64px 0;
  background-color: $prettymuchwhite;

  @include lg {
    padding: 120px 0;
  }
  @include xxl {
    padding: 140px 0;
  }

  .home-faq-inner-container {
    @extend %flex-col;
    width: calc(100% - 20px * 2);
    margin: 120px auto;

    @include md-down {
      flex-direction: column;
      gap: 48px;
      margin: 24px auto;
    }

    @include md {
      gap: 40px;
      width: 90vw;
    }

    @include lg {
      flex-direction: row;
      align-items: flex-start;
      gap: 80px;
    }

    @include xl {
      max-width: 1440px;
      margin: 0 auto;
      gap: 120px;
    }
    @include xxl {
      max-width: 1440px;
      gap: 160px;
    }

    .main {
      flex: 1;

      .header {
        font-family: $font-header;
        color: $darkest;
        font-weight: 400;
        margin: 0;

        @include md-down {
          font-size: 64px;
          margin-bottom: 8px;
        }
        @include md {
          font-size: 80px;
          margin-bottom: 8px;
        }

        @include lg {
          font-size: 96px;
        }

        @include xl {
          font-size: 120px;
          margin-bottom: 12px;
        }
        @include xxl {
          font-size: 160px;
        }
      }

      .subheader {
        color: $darker;

        a {
          color: $darker;
          text-decoration: underline;
        }

        @include md-down {
          font-size: 24px;
        }
        @include md {
          font-size: 24px;
        }

        @include lg {
          font-size: 26px;
        }

        @include xl {
          font-size: 26px;
        }
        @include xxl {
          font-size: 28px;
        }
      }
    }

    .faqs {
      flex: 1;

      .faq {
        @extend %flex-col;
        padding-bottom: 12px;
        margin-bottom: 12px;

        &:not(:last-child) {
          border-bottom: $border-lightest;
        }

        @include md {
          padding-bottom: 14px;
          margin-bottom: 14px;
        }

        @include xl {
          padding-bottom: 18px;
          margin-bottom: 18px;
        }

        .question {
          @extend %flex-row-middle-spread;
          cursor: pointer;

          .text {
            font-size: 18px;
            font-weight: normal;
            margin: 0;

            @include lg {
              font-size: 22px;
            }

            @include xxl {
              font-size: 24px;
            }
          }

          .icon {
            font-size: 18px;

            @include lg {
              font-size: 22px;
            }

            @include xxl {
              font-size: 24px;
            }
          }
        }

        .answer {
          font-size: 16px;
          color: $dark;
          white-space: pre-wrap;

          @include lg {
            font-size: 16px;
          }

          @include xxl {
            font-size: 16px;
          }
        }
      }
    }
  }
}
