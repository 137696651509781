@import '../../styles/shared.scss';

.collection-embed-outer-container {
  width: 100%;
  position: relative;
  overflow: hidden;

  .collection-title-container {
    padding: 20px 20px 0;
    .collection-title {
      margin: 0;
      font-family: $font-header;
      font-size: 20px;
      color: $darkest;
    }
    .collection-subtitle {
      margin: 0;
      font-family: $font-body;
      font-size: 13px;
      font-weight: normal;
      color: $dark;
    }
  }

  .collection-embed-inner-container {
    @extend %flex-row;
    padding: 20px 20px 14px;
    width: 100%;
    height: 100%;
    overflow: auto;
    overflow-y: hidden;

    &.showtitle {
      padding-top: 0px;
    }

    .loader {
      @extend %flex-center;
      width: 100%;
      height: 100%;
      height: 340px;
      min-height: 340px;
    }

    .pin {
      @extend %flex-col;
      justify-content: space-between;
      text-decoration: none;
      min-width: calc(100% / 1.6);
      max-width: calc(100% / 1.6);

      &.mobileonly {
        border: $border-lightest;
        margin-right: 12px;
        border-radius: 10px;
        padding: 0 10px 10px;

        @include md-down {
          min-width: calc(100% / 2.2);
          max-width: calc(100% / 2.2);
        }
        &.sm {
          min-width: calc(100% / 3.2);
          max-width: calc(100% / 3.2);

          .image-container,
          img {
            height: 120px;
          }
          .title {
            font-size: 11px;
            padding: 0 4px;
          }
          .link-to-product-btn {
            background-color: $darkest;
            color: white;
            margin: 12px 0 0;
            padding: 6px 8px;
            font-size: 12px;
          }
        }
      }

      @include sm {
        min-width: calc(100% / 2.8);
        max-width: calc(100% / 2.8);
      }
      @include md {
        min-width: calc(640px / 3);
        max-width: calc(640px / 3);
      }
      @include lg {
        min-width: calc(800px / 4);
        max-width: calc(800px / 4);
      }

      .image-container {
        @extend %flex-row;
        height: 200px; // HEIGHT
        align-items: flex-start;
        justify-content: center;
        @extend %animation-slide-up;
        animation-duration: 800ms;

        &:nth-child(1) {
          .collection-preview-image {
            transition-delay: 0s;
          }
        }
        &:nth-child(2) {
          .collection-preview-image {
            transition-delay: 0.1s;
          }
        }
        &:nth-child(3) {
          .collection-preview-image {
            transition-delay: 0.2s;
          }
        }
        &:nth-child(4) {
          .collection-preview-image {
            transition-delay: 0.3s;
          }
        }
      }

      &:hover {
        color: $dark;
        img {
          transform: translateY(-2px);
        }
        .title {
          text-decoration: underline;
        }
        .link-to-product-btn {
          text-decoration: none;
          color: $darkest;
        }
      }

      img {
        width: 82%;
        height: 200px; // HEIGHT
        padding-bottom: 12px;
        transition: all 0.3s ease-in-out;
        object-fit: contain;
        @extend %animation-fade;
        animation-duration: 800ms;
        border-radius: 8px;

        @include md {
          height: 200px;
          padding-bottom: 0px;
        }
      }

      .title {
        font-size: 14px;
        padding: 0 16px;
        font-weight: normal;
        text-align: center;
        color: $darkest;
        letter-spacing: 0.47px;
        margin: 0;
        font-family: $font-body;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        margin-top: 4px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .link-to-product-btn {
        @extend %btn-offwhite;
        align-self: stretch;
        margin: 24px 8px 0;
        padding: 10px 0;
        font-size: 14px;
        z-index: 2;
        font-weight: bold;
        border-top: $border-offwhite;
      }
    }
  }
  .scroll-arrow-visible {
    @extend %flex-center;
    @extend %animation-fade;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: 12px;
    opacity: 0.01;

    &.visible {
      opacity: 1;
      svg {
        cursor: pointer;
      }
    }

    svg {
      font-size: 20px;
      color: $medium;
    }

    &.left {
      left: 0;
      right: auto;

      svg {
        margin-left: 24px;
      }
    }
  }
}
