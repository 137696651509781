@import '../../../../styles/shared.scss';

.payment-accounts-panel-outer {
  .payment-accounts-panel-inner {
    font-size: 52px;

    .connect-payments-btns {
      @extend %flex-row;
      gap: 8px;

      button.stripe,
      a.paypal,
      button.paypal {
        @extend %flex-row;
        flex: 1;
        gap: 8px;
        justify-content: center;
        align-items: center;

        img {
          height: 18px;
          width: 18px;
        }

        &.connected {
          color: white !important;
          background: $primary !important;
          border: $border-primary !important;

          img {
            display: none;
          }
        }
      }
    }
  }
}
