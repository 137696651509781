@import '../../styles/shared.scss';

.partner-tile {
  @extend %flex-col-center;
  position: relative;
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  background-color: $darkest;

  &:hover {
    .cover-container {
      .cover-fade {
        opacity: 0.2;
      }
    }
    .logo-container {
      transform: scale(1.05);
    }
  }

  img.cover {
    @extend %animation-fade;
    width: 100%;
    object-fit: cover;
    opacity: 0;
    aspect-ratio: 356 / 120;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }

  .cover-container {
    @extend %flex-col-center;
    position: absolute;
    width: 100%;
    height: 100%;

    .cover-fade {
      background: $darkest;
      opacity: 0.4;
      transition: opacity 0.2s ease-in-out;
      width: 100%;
      height: 100%;
    }

    .logo-container {
      @extend %flex-center;
      position: absolute;
      width: 100%;
      height: 100%;
      flex: 1;
      transition: transform 0.2s ease-in-out;

      .logo {
        width: 130px;
        height: 80px;
        object-fit: contain;
        filter: brightness(0) invert(1);
      }
    }

    .footer-container {
      @extend %flex-row-middle-spread;
      position: absolute;
      bottom: 12px;
      width: calc(100% - 24px);

      .partner-badge-container {
        @extend %flex-row-middle;
        font-size: 12px;
        color: white;

        img {
          width: 14px;
          height: 14px;
          margin-right: 6px;
        }
      }

      .rate-container {
        font-family: $font-header;
        font-size: 18px;
        color: white;
        font-weight: bold;
      }
    }
  }
}
