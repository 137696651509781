@import '../../styles/shared.scss';

.partner-portal-requests-outer {
  .partner-portal-requests-inner {
    @extend %flex-col;
    gap: 20px;

    .empty-results-message {
      text-align: center;
      font-size: 1.2rem;
      color: $light;
      padding-top: 20px;

      .text {
        @extend %flex-col;
        gap: 1rem;

        .clear-filters-button {
          @extend %btn-basic;
          @extend %label;
          width: max-content;
          padding: 10px 16px;
          color: white;
          background-color: black;
          border: none;
          outline: none;
          border-radius: 6px;
          margin: 0 auto;
        }
      }
    }

    .requests-grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
      gap: 20px;
      margin-bottom: 100px;

      @include xs-down {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      }
    }

    .results-section-header {
      @extend %flex-row;
      gap: 10px;
      align-items: center;
      margin-top: 1rem;

      .text {
        // @extend %label;
        @extend %flex-row;
        gap: 0.5rem;
        font-size: 1.5rem;
        font-weight: bold;
        align-items: center;

        span {
          background-color: black;
          color: white;
          height: 22px;
          border-radius: 100px;
          padding: 0 12px;
          display: grid;
          place-content: center;
          font-size: 0.8rem;
        }
      }

      .spacer {
        height: 1px;
        flex: 1;
        background-color: $lighter;
      }

      .download-button {
        font-size: 0.8rem;
        cursor: pointer;

        button {
          cursor: pointer;
          border: none;
          outline: none;
          background-color: transparent;
          @extend %label;
          margin-left: 5px;
        }
      }
    }
  }
}
