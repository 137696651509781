@import '../../styles/shared.scss';

.move-pin-modal-outer-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  z-index: 10;
  font-family: $font-body;

  &.fullscreen {
    .move-pin-modal-background-fade {
      height: 100vh;
      pointer-events: auto;
      background: rgba(0, 0, 0, 0.3);
    }
    .move-pin-modal-inner-container {
      height: 70vh;
      transform: translateY(15vh);
    }
  }

  &:not(.fullscreen) {
    .move-pin-modal-background-fade {
      pointer-events: none;
      background: linear-gradient(0, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0) 35%);
    }
    .move-pin-modal-inner-container {
      height: 32vh;
      transform: translateY(calc(100vh - 32vh - 24px));

      @include md-down {
        height: 20vh;
        transform: translateY(calc(100vh - 20vh - 48px));
      }
    }
  }

  &.visible {
    .move-pin-modal-background-fade {
      opacity: 1;
    }
    .move-pin-modal-inner-container {
      pointer-events: auto;
    }
  }

  &:not(.visible) {
    .move-pin-modal-background-fade {
      // opacity: 1;
    }
    .move-pin-modal-inner-container {
      // pointer-events: auto;
      transform: translateY(calc(100vh));
    }
  }

  .move-pin-modal-background-fade {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .move-pin-modal-inner-container {
    max-width: 960px;
    width: 90vw;
    background: white;
    position: relative;
    border-radius: 10px;
    margin: 0 auto 24px;
    box-shadow: $shadow-dark;
    transform: translateY(120%);
    transition: transform 0.5s ease-in-out;
    height: 33.3vh;
    min-height: 150px;

    &.confirming {
      .sections {
        filter: blur(4px);
      }
    }

    .modal-actions {
      @extend %flex-row-middle;
      position: absolute;
      top: 8px;
      right: 12px;

      .action-icn {
        @extend %btn-basic;
        font-size: 18px;
        margin-left: 12px;
      }
    }

    .confirmation-overlay {
      @extend %flex-center-vertical;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.95);
      color: $darker;

      .confirmation-msg {
        font-size: 14px;
        margin-bottom: 12px;
        max-width: 400px;
        text-align: center;
        font-weight: bold;
      }
      .confirmation-actions {
        @extend %flex-row;
        .confirmation-action {
          text-transform: uppercase;
          padding: 6px 12px;
          &:not(:first-child) {
            margin-left: 8px;
          }
          &.cancel {
            @extend %btn-basic;
          }
          &.confirm {
            @extend %btn-dark;
            padding: 6px 12px;
          }
        }
      }
    }
    .main-modal-action {
      @extend %flex-center;
      position: absolute;
      bottom: 100%;
      width: 100%;

      .action {
        @extend %flex-row;
        cursor: pointer;
        margin: 0 auto;
        font-size: 12px;
        font-weight: bold;
        background: $dark;
        color: $white;
        padding: 4px 8px;
        text-transform: uppercase;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        svg {
          margin-left: 4px;
        }

        @include md-down {
          padding: 6px 12px;
        }
      }
    }

    .sections {
      @extend %flex-row-middle;
      height: 100%;

      @include md-down {
        flex-direction: column;
        align-items: stretch;
        overflow: auto;
      }
      .section {
        padding: 12px;

        @include md {
          height: 100%;
          overflow: auto;
          flex: 1;
        }

        .header {
          @extend %flex-row;
          font-size: 13px;
          margin-bottom: 12px;
          font-weight: bold;
          text-transform: uppercase;
          color: $light;

          .input-container {
            @extend %flex-row;
            flex: 1;
            margin-right: 24px;
            position: relative;
            input {
              @extend %input-inline;
              @extend %input-mobile-friendly;
              flex: 1;

              &::placeholder {
                text-transform: uppercase;
              }
            }
            .clear-input {
              @extend %flex-row-middle;
              @extend %btn-basic;
            }
          }
        }

        .collection-actions {
          @extend %flex-row-middle;
          justify-content: space-around;
          width: 100%;
          padding: 0 4px;

          .collection-action {
            @extend %flex-row-middle;
            @extend %btn-basic;
            margin-right: 4px;
            font-size: 12px;
            text-transform: uppercase;

            svg {
              margin-right: 4px;
            }
          }
        }

        &.separator {
          @extend %flex-center;
          margin: auto;
          max-width: 12px;
          max-height: 12px;
          svg {
            display: none;
          }
          .down {
            @include md-down {
              display: flex;
            }
          }

          .right {
            @include md {
              display: flex;
            }
          }
        }

        &.pins,
        &.collections {
          padding: 12px 12px 12px;

          .pin,
          .collection {
            @extend %flex-row-middle-spread;
            background: $white;
            padding: 12px;
            width: 100%;
            border-radius: 8px;
            box-shadow: $shadow-light;

            &:not(:last-child) {
              margin-bottom: 12px;
            }

            .main {
              @extend %flex-row-middle;
              flex: 1;

              img,
              .empty-img {
                height: 48px;
                width: 48px;
                margin-right: 12px;
                object-fit: contain;
              }

              .empty-img {
                @extend %flex-center;
                background: $prettymuchwhite;
                border-radius: 8px;

                svg {
                  font-size: 24px;
                  color: $lighter;
                }
              }
              .data {
                @extend %flex-col;
                flex: 1;
                align-items: stretch;

                input {
                  @extend %input-inline;
                  font-size: 16px;
                  flex: 1;

                  &::placeholder {
                    text-transform: uppercase;
                  }
                }
                .title {
                  font-size: 13px;
                  line-height: 13px;
                  font-weight: bold;
                  color: $darker;
                  text-transform: uppercase;
                  text-decoration: none;

                  &.normal-case {
                    text-transform: none;
                  }
                }
                .subtitle {
                  font-size: 11px;
                  font-weight: normal;
                  color: $medium;
                  margin-top: 2px;

                  svg {
                    margin-left: 4px;
                    font-size: 9px;
                    color: $light;
                  }
                }
              }
            }

            .no-actions-msg {
              font-weight: bold;
              font-size: 9px;
              text-transform: uppercase;
              color: $light;
              flex-wrap: nowrap;
            }

            .actions {
              @extend %flex-row-middle;
              .action {
                @extend %flex-col-center;
                @extend %btn-basic;
                min-width: 12px;

                &:not(:last-child) {
                  margin-right: 6px;
                }
                svg {
                  font-size: 14px;
                }

                .label {
                  font-size: 9px;
                  flex-wrap: wrap;
                  margin-top: 4px;
                }
              }
            }
          }
        }
        .select-more-msg {
          @extend %btn-basic;
          font-size: 12px;
          color: $medium;
          margin-top: 18px;
          text-transform: uppercase;
          text-align: center;

          svg {
            font-size: 10px;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
