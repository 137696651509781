@import '../../styles/shared.scss';

.link-results-outer-container {
  background-color: white;

  .results-warning {
    @extend %flex-center;
    padding: 12px 12px;
    border-bottom: 2px solid $offwhite;
    font-size: 12px;
    color: $medium;

    .main {
      font-weight: bold;
      color: $darker;
    }
  }

  .row {
    width: 100%;

    &.header {
      @extend %flex-row-middle-spread;
      background: $darkest;
      border-radius: 0;
      color: white;

      @include md {
        border-radius: 10px 10px 0px 0px;
      }

      &.hide-on-mobile {
        display: none;

        @include lg {
          display: flex;
        }
      }
      .cell {
        @extend %flex-row;
        padding: 16px 4px;
        justify-content: center;
        font-weight: bold;
        justify-content: center !important;
        white-space: nowrap;
        gap: 4px;
        font-size: 11px;
        color: white;
        position: relative;

        @include md {
          font-size: 12px;
        }

        .icon-toggle {
          @extend %btn-basic;
          color: $lighter;

          &.active {
            color: $white;
          }
        }

        .metric {
          background-color: $dark;
          padding: 1px 4px;
          border-radius: 10px;
          position: relative;
          color: white;
          font-size: 11px;
          transition: all 0.2s ease-in-out;

          &.fetching {
            color: transparent;
            background-color: $darker;
          }

          .loader {
            position: absolute;
            width: 100%;
            height: 100%;
            top: -4px;
            left: 0;
          }
        }

        &:hover {
          .sort {
            opacity: 1;
          }
        }

        .sort {
          opacity: 0;
          width: 12px;
          margin-left: -12px;

          &.active {
            opacity: 1;
          }
        }

        &.clickable {
          @extend %btn-basic;
        }
      }
    }

    .cell {
      @extend %flex-row-middle;
      justify-content: center;
      text-overflow: ellipsis;
      margin: 0 16px;
      color: $darker;
      flex: 2;

      @include md {
        margin: 0 8px;
      }

      &.xs {
        max-width: 24px;
      }

      &.sm {
        flex: 1;
      }

      &.md {
        flex: 2;
        max-width: 220px;
      }

      &.lg {
        flex: 3;
      }

      &.xl {
        flex: 5;
        max-width: 360px;

        @include md-down {
          flex: 3;
        }
      }
    }
  }

  .body {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    &.fetching {
      opacity: 0.4;
    }

    &.transitioning {
      transform: scaleY(0); // To hide while allowing opacity
    }
  }

  .loading-msg {
    @extend %flex-center;
    min-height: 240px;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
  }
}
