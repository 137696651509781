@import '../../../../styles/shared.scss';

.lookbook-product-modal-customize-product-outer {
  height: 100%;
  position: relative;

  .lookbook-product-modal-customize-product-inner {
    height: 100%;
    @extend %flex-col;
    gap: 10px;
    position: relative;

    .back-button {
      display: flex;
      gap: 15px;
      align-items: center;
      @extend %label;
      @extend %btn-basic;
      font-size: 0.7rem;
      margin-bottom: 20px;
      cursor: pointer;

      svg {
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }
    }

    .buttons {
      @extend %flex-row;
      justify-content: flex-end;

      .create {
        @extend %btn-basic;
        @extend %label;
        background-color: $primary;
        color: white;
        font-size: 0.9rem;
        padding: 14px 16px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        outline: none;
        border: none;
        display: grid;
        place-items: center;

        &.saving {
          filter: brightness(0.6);
          cursor: default;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .product-container {
      display: flex;
      gap: 60px;
      width: 100%;

      @include sm-down {
        flex-direction: column;
        align-items: center;
      }

      @include md {
        flex-direction: row;
        align-items: flex-start;
      }

      .product-info {
        flex: 1;
        @extend %flex-col;
        gap: 10px;
        width: 100%;
        max-height: 400px;
        overflow: scroll;

        .settings-back-button {
          display: flex;
          gap: 15px;
          align-items: center;
          @extend %label;
          @extend %btn-basic;
          font-size: 0.7rem;
          margin-bottom: 20px;
          cursor: pointer;

          svg {
            width: 10px;
            height: 10px;
            margin-right: 0px;
          }
        }

        .product-section-container {
          @extend %flex-row;
          gap: 20px;
          width: 100%;
          margin: 0;
          padding: 0;
          height: max-content;

          .advanced-settings-button {
            @extend %btn-basic;
            font-size: 0.8rem;
            margin-top: 10px;
            border-radius: 5px;
            background-color: $white;
            color: $medium;

            svg {
              margin-left: 5px;
              font-size: 0.6rem;
            }
          }

          > div {
            width: 100%;
            margin: 0;
            padding: 0;
            height: max-content;

            .product-section-header {
              @extend %label;
              @extend %flex-row;
              font-size: 0.9rem;
              width: 100%;
              align-items: center;

              optional {
                font-family: $font-body-new;
                text-transform: none;
                font-size: 0.6rem;
                margin-left: 5px;
                color: $light;
              }

              span {
                @extend %btn-basic;
                font-size: 12px;
                margin-left: 10px;
                background-color: black;
                color: white;
                padding: 4px 8px;
                font-weight: bold;
                border-radius: 4px;
              }
            }

            .product-section-subheader {
              @extend %flex-row;
              font-size: 0.8rem;
              width: 100%;
              align-items: center;
              max-width: 500px;
              color: $medium;
            }

            textarea,
            input {
              border: none;
              outline: none;
              font-family: $font-body-new;
              width: 100%;
              font-size: 0.9rem;
              padding: 10px 0;
              resize: none;
              display: block;
              margin: 0;
              width: 100%;

              &.advanced-settings-input {
                border: $border-lightest;
                border-radius: 5px;
                margin-top: 10px;
                padding: 10px 15px;
              }

              &::placeholder {
                color: $lighter;
              }

              &#title {
                font-family: $font-header;
                font-size: 2rem;
                font-weight: 600;
              }
            }

            .siblings-container {
              @extend %flex-row;
              gap: 10px;
              flex-wrap: wrap;
              margin-top: 10px;

              .sibling {
                @extend %btn-basic;
                background-color: $white;
                color: $dark;
                border: $border-lightest;
                font-size: 0.7rem;
                padding: 6px 12px;
                border-radius: 500px;
                align-items: center;
                display: flex;

                &.selected {
                  border: $border-dark;
                  font-weight: bold;
                }

                svg {
                  margin-left: 6px;
                }

                &.disconnected {
                  border-color: $error;
                  color: $error;

                  svg {
                    color: $error;
                  }

                  &.selected {
                    background-color: rgba(255, 0, 0, 0.1);
                  }
                }
              }
            }
          }
        }
      }
    }

    .disclaimer {
      font-size: 0.7rem;
      color: $light;
    }
  }
}
