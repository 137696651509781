@import '../../styles/shared.scss';

.product-top-artists-outer-container {
  .top-artist {
    @extend %flex-row-middle;
    margin: 24px 0 36px;
    text-decoration: none;
    cursor: default;

    img,
    .empty {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      background: $offwhite;
      margin-right: 16px;
    }

    .user-data {
      flex: 1;

      .name {
        font-family: $font-header;
        font-weight: bold;
        color: $darkest;
        font-size: 20px;
      }

      .description {
        color: $dark;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        font-size: 14px;
        letter-spacing: 0.06px;
        line-height: 17px;
        margin-top: 4px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .see-all-link {
        font-size: 14px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 8px;
        color: $darker;
        text-decoration: underline;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }
}
