@import '../../styles/shared.scss';

.commission-controls-outer {
  .commission-controls-inner {
    margin-top: 50px;
    padding: 12px 0;
    @extend %flex-row;
    justify-content: space-between;
    gap: 5px;

    .main-inputs {
      flex: 1;

      .search-container {
        flex: 1;
        max-width: 480px;
        position: relative;

        @include md-down {
          margin-right: 12px;
          min-width: 60vw;
        }

        input {
          @extend %input-shared;
          margin-right: 12px;
          width: 100%;
          padding-left: 32px;

          @include md {
            display: inherit;
          }
        }

        svg {
          position: absolute;
          top: 12px;
          left: 12px;
          font-size: 13px;
          color: $light;
        }
      }
    }

    .secondary-inputs {
      @extend %flex-row;
      justify-content: space-between;
      gap: 10px;

      .invoice-select {
        min-width: 240px;

        .shopmy-select__control {
          border: $border-lightest;

          .shopmy-select__value-container {
            @extend %flex-row-middle;
            padding: 0;

            .shopmy-select__placeholder {
              padding: 2px 2px;
              color: $lighter;
            }

            [class*='-Input'] {
              margin: 0;
              padding: 0 16px;

              .shopmy-select__input input {
                text-align: left;
                padding: 2px 2px;
              }
            }
          }

          .shopmy-select__indicators {
            .shopmy-select__indicator-separator {
              display: none;
            }
            .shopmy-select__dropdown-indicator,
            .shopmy-select__clear-indicator {
              padding: 4px;
            }
          }
        }

        .shopmy-select__single-value {
          font-family: $font-body;
          padding: 4px 16px;
          font-size: 14px;
        }
      }

      .date-picker {
        @extend %flex-row;
        font-family: $font-body !important;
        margin-left: 12px;
        display: none;

        @include md {
          display: inherit;
        }

        .DateRangePicker {
          align-self: stretch;
          border-radius: 4px;
          border: $border-lightest;

          .DateRangePickerInput {
            border-radius: 8px;
            padding-left: 4px;

            .DateInput {
              width: 112px;
              input {
                font-family: $font-body;
                padding: 8px 16px;
                font-size: 14px;
                height: 100%;
              }
            }

            .DateInput_input__focused {
              border-color: $darker;
            }
          }

          .DateRangePickerInput_arrow_svg {
            width: 16px;
            height: 16px;
            margin-top: -3px;
          }
        }
        // NOTE: the order of these styles DO matter (this comes directly from the react-dates CSS recommendations)
        // Will edit everything selected including everything between a range of dates
        .CalendarDay__selected_span {
          background: $dark;
          color: white;
          border: $border-darker;
        }

        // Will edit selected date or the endpoints of a range of dates
        .CalendarDay__selected {
          background: $darker;
          border-color: $darkest;
          color: white;
        }

        // Will edit when hovered over. _span style also has this property
        .CalendarDay__selected:hover {
          background: $medium;
          color: white;
        }

        // Will edit when the second date (end date) in a range of dates
        // is not yet selected. Edits the dates between your mouse and said date
        .CalendarDay__hovered_span:hover,
        .CalendarDay__hovered_span {
          background: $dark;
          border-color: $dark;
          color: white;
        }
      }

      .action {
        @extend %btn-darkest-new;
        padding: 5px 12px;
        font-size: 0.7rem;
        height: 100%;

        svg {
          margin-left: 6px;
          font-size: 12px;
        }
      }
    }
  }
}
