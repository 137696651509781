@import '../../styles/shared.scss';

.requests-portal-outer {
  font-family: $font-body-new;
  padding: 75px 0 120px;
  width: 100%;
  min-height: 100vh;
  background-color: $prettymuchwhite;

  .requests-portal-inner {
    width: min(85%, 1024px);
    margin: 0 auto;

    .requests-portal-header {
      font-size: 3rem;
      font-weight: 600;
      font-family: $font-header;
      margin: 1.5rem 0;
    }

    .results-container {
      &.isLoading {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .clickable {
      cursor: pointer;
    }
  }
}
