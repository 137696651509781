@import '../../../../styles//shared.scss';

.manager-roster-panel-outer {
  .manager-roster-panel-inner {

    .manager-roster-controls-container {
      @extend %flex-row;
      justify-content: space-between;
      margin-top: 30px;
      margin-bottom: 16px;

      @include md-down {
        >div {
          flex: 1;

          input.manager-roster-search {
            flex: 1;
            width: 100%;
          }

          &:last-child {
            display: none;
          }
        }
      }

      >div {
        position: relative;

        button.manager-roster-refresh {
          @extend %btn-basic;
          @extend %label;
          @extend %flex-row;
          gap: 5px;
          position: absolute;
          top: -20px;
          left: 0;
          outline: none;
          border: none;
          font-size: 9px;
          background: transparent;
        }

        input.manager-roster-search {
          height: 40px;
          min-width: 250px;
          padding: 10px 20px;
          padding-left: 40px;
          border-radius: 500px;
          border: $border-lightest;
          outline: none;
          font-size: 12px;
        }

        .manager-roster-search-icon {
          position: absolute;
          top: 50%;
          left: 18px;
          transform: translateY(-50%);
          width: 12px;
          height: 12px;
        }

        .manager-roster-sort {
          height: 40px;
          width: 200px;
          font-size: 12px;
        }
      }
    }

    .manager-roster-users {
      .managed-user-outer {
        cursor: pointer;
        border: solid 1px transparent;
        border-radius: 10px;
        transition: border 0.2s ease-out;

        &:hover {
          border: $border-lightest;

          .managed-user-inner {
            .managed-user-actions {
              .managed-user-actions-buttons {
                .managed-user-actions-buttons-last-logged-in {
                  opacity: 0;
                }
              }

              .managed-user-actions-buttons-hidden {
                opacity: 1;
              }
            }
          }
        }

        &.isSelected {
          border: $border-primary;
        }

        .managed-user-inner {
          padding: 18px;
          @extend %flex-row;
          justify-content: space-between;

          @include md-down {
            flex-wrap: wrap;
            gap: 10px;
          }

          .managed-user-profile {
            @extend %flex-row;
            gap: 15px;
            /* align-items: center; */

            .managed-user-image-container {
              position: relative;

              .managed-user-notification-count {
                position: absolute;
                top: -3px;
                right: -3px;
                height: 18px;
                width: 18px;
                background: $darkest;
                color: white;
                font-size: 8px;
                display: grid;
                place-items: center;
                border-radius: 50%;
              }

              img {
                border-radius: 50%;
                height: 55px;
                width: 55px;
                object-fit: cover;
              }
            }

            .no-image {
              border-radius: 50%;
              height: 55px;
              width: 55px;
              font-size: 10px;
            }

            .managed-user-profile-data {
              @extend %flex-col;
              gap: 2px;
              width: 150px;

              .managed-user-profile-bold {
                font-weight: 700;
                font-size: 14px;

              }

              .managed-user-profile-regular {
                font-weight: 400;
                font-size: 10px;
              }

              .managed-user-profile-light {
                font-weight: 400;
                font-size: 10px;
                color: $medium;
              }
            }
          }

          .managed-user-metrics {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
            row-gap: 6px;
            height: max-content;
            /* align-self: center; */
            padding-top: 2px;

            .managed-user-metrics-header {
              grid-column: 1 / -1;
              font-weight: 400;
              font-size: 10px;
              align-items: flex-end;
              line-height: 1;
              height: max-content;
            }

            .managed-user-metrics-metric {
              @extend %flex-row;
              gap: 4px;
              align-items: center;
              font-weight: 400;
              font-size: 10px;
              opacity: 0.3;
              height: max-content;

              svg {
                width: 8px;
                height: 8px;
              }

              &.active {
                opacity: 1;
              }
            }
          }

          .managed-user-actions {
            @extend %flex-col;
            justify-content: space-between;
            position: relative;

            .managed-user-actions-buttons-hidden {
              @extend %flex-col;
              gap: 5px;
              align-items: flex-end;
              position: absolute;
              opacity: 0;
              bottom: 0;
              right: 0;
              background: white;
              transition: opacity 0.2s ease-out;

              .managed-user-actions-buttons-hidden-button {
                @extend %btn-basic;
                @extend %label;
                font-size: 9px;
                color: $darkest;
                border: none;
                outline: none;
                transition: all 0.2s ease-out;
                margin: 0;
                padding: 0;
                background: transparent;
              }
            }

            .managed-user-actions-buttons {
              position: relative;

              .managed-user-actions-buttons-last-logged-in {
                color: $light;
                font-size: 10px;
                opacity: 1;
                transition: opacity 0.2s ease-out;
              }

              .managed-user-actions-buttons-login {
                @extend %btn-basic;
                @extend %label;
                font-size: 9px;
                color: $darkest;
                border: none;
                outline: none;
                background: white;
                opacity: 0;
                position: absolute;
                bottom: 0;
                right: 0;
                transition: opacity 0.2s ease-out;
                margin: 0;
                padding: 0;
              }
            }

            .managed-user-actions-tier {
              @extend %flex-col;
              align-items: flex-end;

              .managed-user-actions-tier-name {
                font-weight: 700;
                font-size: 14px;
              }

              .managed-user-actions-tier-score {
                color: $light;
                font-size: 10px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}
