@import '../../styles/shared.scss';

.consult-templates-outer-modal {
  max-width: calc(600px + 200px) !important;

  .consult-templates-modal {
    @extend %flex-row;
    flex-direction: row !important;
    padding: 0 !important;
    overflow: hidden !important;
    color: $darker;

    .section-selection {
      @extend %flex-col;
      flex: 1;
      background: $offwhite;
      border-right: $border-lightest;
      max-width: 200px;
      display: none;
      padding: 24px;

      @include md {
        display: flex;
      }

      .filter {
        @extend %btn-basic;
        padding-bottom: 4px;
        margin-bottom: 4px;
        color: $dark;

        &.selected {
          font-weight: bold;
          color: $darkest;
        }
      }
    }

    .sections {
      flex: 1;
      max-width: 600px;
      padding: 0;
      overflow: auto;

      @include sm {
        padding: 24px;
      }

      &.adding {
        filter: blur(3px);
      }
      .header {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 12px;
        padding: 16px;

        @include sm {
          padding: 0;
          margin-bottom: 24px;
        }
      }

      .filter-section {
        &:last-child {
          padding-bottom: 120px;
        }

        .filter-section-header {
          font-size: 13px;
          font-weight: bold;
          margin-bottom: 12px;
          text-transform: uppercase;
          padding: 0 16px;

          @include md {
            padding: 0;
          }
        }
      }
    }
  }

  .adding-new-loader {
    @extend %flex-center;
    position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);

    @include md {
      width: calc(100% - 200px);
      left: 200px;
    }
  }
}
