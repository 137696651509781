@import '../../../../styles/shared.scss';

.general-profile-settings-panel-outer {
  .general-profile-settings-panel-inner {

    .general-profile-settings-panel-image {
      @extend %flex-row;
      align-items: center;
      gap: 24px;

      .general-profile-settings-panel-profile-image {
        height: 64px;
        width: 64px;
        border-radius: 50%;
        object-fit: cover;
      }

      .general-profile-settings-panel-image-details-name {
        font-size: 16px;
        font-weight: 700;

      }

      .general-profile-settings-panel-image-details-button {
        @extend %btn-basic;
        @extend %label;
        font-family: $font-body-new;
        font-size: 8px;
        /* font-weight: 00; */
        border: none;
        outline: none;
        background: transparent;
        padding: 5px;
        margin: 0;
        background-color: $lightest;
        margin-top: 3px;
      }
    }

    .general-profile-settings-panel-split-input {
      @extend %flex-row;
      gap: 5px;

      .general-profile-settings-panel-split-input-password {
        flex: 1;
        position: relative;

        input {
          width: 100%;
        }

        svg {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
    }

    .settings-button {
      width: 121px;
    }
  }
}
