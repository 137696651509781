@import '../../styles/shared.scss';

.toggle-analytics-container {
  @extend %flex-col;
  align-items: flex-end;
  z-index: 2;
  position: absolute;
  top: 16px;
  right: 16px;

  .toggle-analytics-label {
    @extend %flex-col;
    align-items: flex-end;
    color: $white;
    font-size: 14px;
    font-family: $font-body;
    letter-spacing: 0.5px;
    padding: 4px 12px;
    border-radius: 4px;
    background: $lighter;
    cursor: pointer;
    &:hover {
      background: $lighter;
    }

    &.active {
      background: $primary;
    }

    svg {
      font-size: 10px;
      margin-left: 4px;
    }

    .toggle-analytics-label-metadata {
      padding: 8px 0;

      .toggle-analytics-label-subheader {
        text-align: center;
        font-weight: bold;
      }
    }
  }
}

.analytics-preview-container {
  background: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  padding: 2px 8px;
  color: $white;
  background: $dark;
  border-radius: 4px;
  font-family: $font-body;
  text-align: right;
  margin: 4px 0;
  position: absolute;
  top: -32px;
  right: 8px;
  margin: 0;
  transition: top 0.3s ease-in-out;

  &.loaded {
    top: 0px;
    z-index: 4;
  }

  .header {
    font-size: 14px;
    font-weight: bold;
  }
}
