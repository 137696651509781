@import '../../styles/shared.scss';

.social-auth-outer-container {
  @extend %flex-center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: $darkest;
  color: white;
  font-size: 18px;
  font-family: $font-body;
  text-transform: uppercase;
  font-weight: bold;

  .loader {
    margin-top: -24px;
  }
}
