@import '../../../styles/shared.scss';

.lookbook-previews-outer {
  .lookbook-previews-inner {
    .lookbook-previews-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      gap: 1rem;
      margin-bottom: 1rem;

      .add-new-lookbook-placeholder {
        @extend %btn-basic;
        display: grid;
        place-content: center;
        width: 100%;
        height: 100%;
        min-height: 250px;
        border: $border-lighter;
        border-radius: 16px;

        .text-container {
          @extend %flex-col;
          @extend %label;
          font-size: 0.8rem;
          gap: 10px;
          color: $light;

          svg {
            height: 25px;
            width: 25px;
            margin: 0 auto;
          }
        }
      }
      // @media (max-width: $breakpoint-sm) {
      //   grid-template-columns: repeat(2, 1fr);
      // }

      // @media (max-width: $breakpoint-xs) {
      //   grid-template-columns: 1fr;
      // }
    }
    .get-demo-placeholder {
      @extend %flex-col-spread;
      width: 100%;
      height: 100%;
      min-height: 250px;
      background-color: white;
      border-radius: 16px;
      padding: 36px;

      .header-container {
        color: $darkest;

        .header {
          font-size: 28px;
          font-family: $font-header;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .subheader {
          font-size: 15px;
          color: $dark;
        }
      }

      .button {
        @extend %label;
        @extend %btn-primary;
        align-self: flex-start;
        padding: 10px 20px;
        margin-top: 1rem;
        font-size: 13px;
      }
    }
  }
}
