@import '../../../../styles/shared.scss';

.lookbook-order-modal-shipping-outer {
  height: 100%;
  overflow: scroll;

  .lookbook-order-modal-shipping-inner {
    @extend %flex-col;
    justify-content: space-between;
    padding: 30px;
    height: 100%;
    width: 400px;

    @include lg-down {
      width: 250px;
    }

    @include md-down {
      width: 100%;
    }

    .form {
      @extend %flex-col;
      gap: 40px;

      .form-group {
        @extend %flex-col;

        .form-header {
          @extend %header-md;
          // padding-bottom: 10px;
          font-size: 16px;
        }

        .form-group-items {
          @extend %flex-col;
          border-radius: 10px;
          border: $border-lightest;
          overflow: hidden;

          .form-group-item {
            + .form-group-item {
              border-top: $border-lightest;
            }

            > input,
            textarea,
            span,
            div {
              &.address {
                @extend %flex-col;
                cursor: pointer;
                position: relative;

                span {
                  display: block;
                  padding: 0;
                  margin: 0;
                }

                svg {
                  @extend %btn-basic;
                  height: 10px;
                  width: 10px;
                  position: absolute;
                  top: 10px;
                  right: 10px;
                }

                .button {
                  @extend %flex-center;
                  @extend %btn-darkest-new;
                  align-self: flex-start;
                  background-color: $darkest;
                  color: white;
                  width: 160px;
                  height: 32px;
                }
              }

              font-family: $font-body-new;
              font-size: 0.8rem;
              padding: 15px 15px;
              border: none;
              border-radius: 6px;
              outline: none;
              width: 100%;
              resize: none;

              @include md-down {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    .shipping-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top: border-lightest;

      @include md-down {
        display: none;
      }

      .submit-order-button {
        @extend %btn-basic;
        @extend %label;
        padding: 14px 16px;
        background-color: black;
        color: white;
        outline: none;
        border: none;
      }
    }
  }
}
