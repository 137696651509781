@import '../../../styles/shared.scss';

.contract-task-container {
  .contract-task-title-container {
    margin-bottom: 12px;
    .contract-task-title {
      font-size: 14px;
      font-weight: bold;
      color: $darker;
      text-transform: uppercase;
    }
    .contract-task-subtitle {
      font-size: 12px;
      color: $dark;
      text-transform: uppercase;
    }
  }

  .phases {
    @extend %flex-col;

    .phase {
      @extend %flex-row-middle-spread;
      padding: 6px 0;
      border-radius: 4px;

      @include sm-down {
        flex-direction: column;
        align-items: stretch;
      }

      &:not(:last-child) {
        margin-bottom: 2px;
      }

      &.active:not(.complete) {
        background: $prettymuchwhite;
        border: $border-lightest;
        width: calc(100% + 24px);
        margin-left: -12px;
        padding: 6px 12px;

        &.mine {
          background: $prettymuchwhite;
          border: $border-dark;
        }
      }

      &.upcoming {
        opacity: 0.2;
      }

      .main {
        @extend %flex-row-middle;
        .checkbox {
          @extend %flex-center;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background: $darker;
          overflow: hidden;
          border: $border-dark;

          svg {
            color: white;
            font-weight: bold;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .initials {
            color: white;
            font-weight: bold;
            font-size: 10px;
            font-family: $font-header;
          }
        }

        .content {
          margin-left: 12px;

          .title {
            font-weight: bold;
            font-size: 16px;
          }
          .date {
            font-size: 12px;
          }
        }
      }
      .actions {
        @extend %flex-row-middle;

        @include sm-down {
          margin-top: 12px;
        }
        .action {
          @extend %btn-dark-new;
          padding: 4px 8px;
          text-transform: uppercase;
          font-size: 12px;

          @include sm-down {
            flex: 1;
          }

          &:not(:last-child) {
            margin: 0 0 0 4px;

            @include sm-down {
              margin: 0 0 4px 0;
            }
          }
          &.link {
            @extend %btn-text-new;
          }
        }
      }
    }
  }
}
