@import '../../styles/shared.scss';

.bulk-request-selected-users {
  @extend %flex-row;
  gap: 10px;
  flex-wrap: wrap;
  margin: 20px 0;

  .bulk-request-selected-user {
    // @extend %btn-basic;
    @extend %flex-row;
    gap: 12px;
    border: $border-light;
    border-radius: 5px;
    align-items: center;
    height: 45px;
    width: max-content;
    // overflow: hidden;
    padding-right: 10px;
    position: relative;
    cursor: pointer;

    &.isDisabled {
      opacity: 0.3;
    }

    &.off-platform {
      padding-left: 10px;
    }

    &.invalidLocation {
      border-color: $error;
      border-width: 2px;
      background-color: rgba($error, 0.1);
    }

    .bulk-request-selected-user-loading-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #0007;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s linear;
      display: grid;
      place-content: center;

      &.isLoading {
        opacity: 1;
        pointer-events: all;
      }
    }

    .bulk-request-selected-user-image {
      height: 100%;
      aspect-ratio: 1;
      object-fit: cover;
      background-color: $dark;
      display: grid;
      place-items: center;
      font-family: $font-header;
      font-weight: bold;
      font-size: 1.2rem;
      color: white;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    .bulk-request-selected-user-text-container {
      .bulk-request-selected-user-name {
        @extend %label;
        font-size: 0.8rem;
      }

      .bulk-request-selected-user-subtitle {
        font-size: 0.7rem;
        color: $medium;

        span.view-talent {
          color: $light;
          cursor: pointer;
          transition: color 0.3s ease-out;

          &:hover {
            color: black;
          }
        }
      }
    }

    .bulk-request-selected-user-remove {
      @extend %btn-basic;
      @extend %label;
      background-color: $light;
      color: $medium;
      font-size: 0.8rem;
      padding: 12px 14px;
      border-radius: 50%;
    }
  }

  .bulk-request-icon-container {
    @extend %flex-row;
    align-items: center;
    gap: 5px;

    .bulk-request-remove-user {
      @extend %label;
      background-color: $medium;
      color: white;
      border-radius: 50%;
      display: grid;
      place-items: center;
      height: 14px;
      width: 14px;

      &.isSuccess {
        background-color: $primary;
      }

      &.isErrored {
        background-color: $error;
      }

      svg {
        height: 8px;
        width: 8px;
      }
    }

    .message-container {
      position: relative;

      svg {
        height: 14px;
        width: 14px;
      }

      &:hover {
        .message {
          opacity: 1;
        }
      }

      .message {
        position: absolute;
        bottom: 200%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4px;
        background-color: $dark;
        padding: 10px;
        color: white;
        width: 200px;
        font-size: 0.7rem;
        text-align: center;
        opacity: 0;
        transition: opacity 0.3s ease-out;
        pointer-events: none;
      }
    }
  }
}
