@import '../../../../styles/shared.scss';

.lookbook-order-modal-register-container {
  @extend %flex-col-spread;
  width: 100%;
  background-color: $darkest;
  overflow: auto;

  @include md-down {
    padding-bottom: 360px;
    height: 100vh;
  }

  @include md {
    width: 500px;
  }

  .main-container {
    padding: 48px 32px;
    .title-container {
      color: white;
      margin-bottom: 36px;

      .title {
        @extend %header-fancy;
        font-size: 40px;
        line-height: 1;
        margin-bottom: 12px;
      }

      .subtitle {
        font-size: 16px;
        line-height: 20px;

        .login {
          font-weight: bold;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .registration-form {
      width: 100%;

      @include md {
        max-width: 360px;
      }
    }
  }

  .cover-image {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
