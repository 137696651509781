@import '../../styles/shared.scss';

.report-section-card {
  @extend %flex-col;
  gap: 24px;
  width: 100%;
  background-color: white;
  border-radius: 24px;
  padding: 24px;

  .header-container {
    @extend %flex-row-middle-spread;
    width: 100%;

    .header-info-left {
      @extend %flex-col;
      gap: 4px;
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-family: $font-header;
      font-weight: bold;
    }

    .section-score {
      @extend %flex-row-middle;
      gap: 12px;
      font-size: 16px;

      &.compare-worse {
        .path-secondary-progress {
          stroke: $black;
        }
      }

      &.compare-better {
        .path-progress {
          stroke: $black;
        }

        .path-secondary-progress {
          stroke: $error;
        }
      }
    }

    .score {
      font-size: 16px;

      span {
        font-weight: bold;
        margin-right: 4px;
      }

      &.meta {
        font-size: 14px;
        color: $medium;
      }
    }
  }

  .sections-container {
    @extend %flex-col;
    width: 100%;
    gap: 24px;
  }

  .subsection-container {
    @extend %flex-col;
    border: 1px solid $offwhite;
    border-radius: 12px;
    padding: 12px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    .subsection-header {
      @extend %flex-row-middle-spread;

      .subsection-title {
        font-size: 16px;
        font-weight: bold;
      }
    }

    &.expanded {
      cursor: default;
      overflow: visible;

      .subsection-header {
        margin-bottom: 20px;
        cursor: pointer;
      }
    }

    &.isOnlySection {
      border: none;
      padding: 0;
    }

    .components-container {
      @extend %flex-col;
      width: 100%;
      gap: 30px;
    }

    .subsection-score-bar-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      border-radius: 4px;

      .subsection-score-bar {
        position: absolute;
        height: 100%;
        border-radius: 4px;
        background-color: $primary;
        z-index: 1;

        &.declined {
          background-color: $darkest;
        }

        &.empty {
          background-color: $light;
        }

        &.improved:not(.compare) {
          background-color: $primary;
          z-index: 0;
        }

        &.compare {
          background-color: $error;
          z-index: 0;
        }

        &.improved.compare {
          background-color: $darkest;
          z-index: 1;
        }
      }
    }
  }
}
