@import '../../../styles/shared.scss';

.contract-search-links-outer-container {
  margin: 16px 0 12px;
  @extend %flex-col;
  .search-input-container {
    @extend %flex-col;
    input {
      @extend %input-shared;
    }
  }

  .results {
    margin-top: 12px;
  }

  .searching-loader {
  }
  .empty-results-msg {
    margin: 32px 0 24px;
    text-align: center;
    font-size: 12px;
    color: $dark;
    text-transform: uppercase;
  }
}
