@import '../../../styles/shared.scss';

.opportunity-expectations-panel {
  padding: 48px 24px;

  @include md-down {
    padding: 24px 0;
  }

  .expectations-sections {
    @extend %flex-col;
    gap: 24px;

    .expectations-section {
      @extend %flex-col;
      gap: 16px;

      .section-title-container {
        @extend %flex-row-middle-spread;
        gap: 12px;

        @include md-down {
          flex-direction: column;
          align-items: flex-start;
        }

        .section-title-main {
          @extend %flex-row-middle;
          gap: 12px;

          @include md-down {
            flex-direction: column;
            align-items: flex-start;
          }

          .progress-badge {
            @extend %flex-row-middle;
            @extend %label;
            gap: 8px;
            border: $border-dark;
            padding: 4px 8px;
            border-radius: 24px;
            font-size: 12px;

            svg {
              margin-left: 4px;
            }

            &.completed {
              background-color: $primaryLightest;
              color: $primary;
              border-color: $primary;
            }
          }
          .title-container {
            .title {
              @extend %flex-row-middle;
              @extend %label;
              gap: 8px;
              font-weight: 500;
              font-size: 14px;

              svg {
                font-size: 12px;
                color: $dark;
              }
            }

            .disclaimer {
              font-size: 12px;
              color: $medium;
            }
          }
        }

        .section-title-actions {
          @extend %flex-row-middle;
          gap: 8px;

          .action {
            &.btn {
              @extend %btn-offwhite-new;
              padding: 6px 12px;
              font-size: 9px;
            }

            &.text {
              @extend %btn-basic;
              font-size: 12px;
              color: $light;
            }
          }
        }
      }

      .section-warning-container,
      .section-error-container {
        @extend %flex-row-middle;
        gap: 12px;
        padding: 24px;
        border-radius: 10px;
        border: $border-error;
        background-color: $error;
        color: $white;
        font-weight: bold;
        font-size: 14px;

        a {
          color: $white;
          text-decoration: underline;
        }

        svg {
          font-size: 16px;
        }
      }

      .expectations {
        @extend %flex-row;
        gap: 12px;
        flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @include md {
          grid-template-columns: repeat(3, 1fr);
        }
        @include lg {
          grid-template-columns: repeat(4, 1fr);
        }

        @include xl {
          grid-template-columns: repeat(5, 1fr);
        }

        a {
          text-decoration: none;

          &:hover {
            img {
              transform: scale(1.04);
            }
          }

          img {
            transition: transform 0.2s;
          }
        }

        .expectation {
          @extend %flex-col;
          flex: 1;
          border-radius: 10px;
          overflow: hidden;
          border: $border-offwhite;
          position: relative;
          transition: background-color 0.4s ease;

          &:hover {
            .remove-expectation {
              opacity: 1;
            }

            .copy-pin {
              opacity: 1;
            }

            .image-container {
              transform: scale(1.02);
            }
          }

          &.invalid {
            &:hover {
              border: $border-lightest;
            }

            .media-container,
            .data-container {
              filter: blur(1px);

              img {
                filter: grayscale(100%);
              }
            }
          }

          .invalid-expectation-container {
            @extend %flex-center;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(200, 200, 200, 0.2);
            z-index: 2;
            pointer-events: none;

            .invalid-expectation {
              @extend %label;
              background-color: $darkest;
              color: white;
              padding: 4px 8px;
              border-radius: 4px;
              gap: 4px;
              font-size: 10px;
            }
          }

          .remove-expectation {
            @extend %btn-basic;
            @extend %flex-center;
            background-color: $darkest;
            position: absolute;
            top: 4px;
            right: 4px;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            color: $white;
            font-size: 10px;
            border: $border-white;
            opacity: 0;
            transition: opacity 0.2s;
            z-index: 1;
          }

          .media-container {
            &.link {
              .image-container {
                aspect-ratio: 1;
                object-fit: cover;

                img {
                  aspect-ratio: 1;
                }
              }
            }

            .image-container {
              aspect-ratio: 9 / 16;
              transition: transform 0.2s ease-in-out;

              img {
                @extend %animation-fade;
                width: 100%;
                object-fit: cover;
                aspect-ratio: 9 / 16;
              }

              &.incomplete {
                @extend %flex-center;
                background-color: $offwhite;
                color: $lighter;
                font-size: 20px;
                font-family: $font-header;
              }

              &.empty {
                @extend %flex-center;
                color: $white;
                background-color: $darkest;
                font-size: 20px;
                font-family: $font-header;
              }
            }

            &.loading {
              background-color: $whynotusewhite;
              border: $border-offwhite;
              @extend %flex-center;
              justify-content: center;
              height: 100%;
              opacity: 0.5;
            }

            &.missing-but-counts {
              background-color: red;
            }

            .mention-image,
            .link-image {
              width: 100%;
              height: 100%;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }

              &.empty {
                background-color: $darkest;
              }
            }
          }

          .data-container {
            @extend %flex-col-spread;
            gap: 12px;
            flex: 1;
            padding: 12px 16px;
            position: relative;

            .title {
              font-size: 12px;
              line-height: 1.4;
              color: $darkest;

              &.empty {
                color: $lightest;
                font-style: italic;
              }
            }

            .metadata {
              font-size: 10px;
              color: $medium;
            }
          }

          .copy-pin {
            @extend %btn-basic;

            position: absolute;
            bottom: 8px;
            right: 8px;
            opacity: 0;
            border: 0;
            background: none;
            color: $medium;
          }
        }
      }

      .toggle-show-all {
        @extend %btn-basic;
        @extend %label;
        @extend %flex-center;
        width: 100%;
        font-size: 12px;
        color: $medium;
        margin-top: 24px;
      }
    }
  }
}
