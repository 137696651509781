@import '../../styles/shared.scss';

.tag-type-input-outer {
  .tag-type-input-inner {
    .tag-type-container {

      .tag-type-description {
        font-size: 12px;
        color: $medium;
        margin-bottom: 4px;
      }

      .tag-input-inline {
        @extend %flex-row-middle;
        flex-wrap: wrap;
        gap: 8px;
        min-height: 24px;
        font-size: 10px;

        .type-input {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          @extend %label;
          text-transform: none;
          border: $border-lightest;
          border-radius: 500px;
          min-width: 100px;
          outline: none;
          height: 32px;
          text-align: left;

          &.placeholder {
            color: $medium;
          }

          &.dark {
            background: $darkest;
            color: $white;
            border: $border-darkest;
          }
        }
      }
    }
  }
}
