@import '../../styles/shared.scss';

.requires-brand-login-outer-container {
  font-family: $font-body;
  .requires-brand-login-inner-container {
    @extend %flex-col-center;
    width: 90vw;
    max-width: 800px;
    margin: 128px auto 0;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
  }
}
