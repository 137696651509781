@import '../../styles/shared.scss';

.profile-element-preview-shell-container.posts {
  width: calc(50vw - 24px);
  aspect-ratio: 1;
  margin-right: 12px;
  margin-bottom: 12px;

  &:nth-child(2n + 2) {
    margin-right: 0;
  }

  &.post_horizontal {
    aspect-ratio: 1.78;
  }

  &.post_vertical {
    aspect-ratio: 0.754;
  }

  @include md {
    width: 248px;
    margin-right: 20px;
    margin-bottom: 20px;

    &:nth-child(2n + 2) {
      margin-right: 20px;
    }
    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    &.post_horizontal {
      width: calc(248px * 1.5);
      margin-right: 20px;
      margin-bottom: 20px;

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }

  .profile-element-preview-inner-container {
    height: 100%;
    overflow: hidden;
  }
}

.collection-post-preview-container {
  @extend %flex-row;
  margin-bottom: 24px;
  max-width: 100%;
  aspect-ratio: 1;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  background: $prettymuchwhite;
  align-self: stretch;
  justify-self: flex-start;

  &.post_horizontal {
    aspect-ratio: 1.78;
  }

  &.post_vertical {
    aspect-ratio: 0.754;
  }

  @include md {
    border-radius: 8px;
  }

  .analytics-preview-container {
    top: 8px;
  }

  // The following matches the code at the top of the page, as a result of the re-arranging
  // posts component
  &.no-edit {
    &:not(.post_horizontal) {
      width: calc(50vw - 24px);
      margin-right: 12px;
      margin-bottom: 12px;
      @include md {
        width: 248px;
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(2n + 2) {
          margin-right: 20px;
        }
        &:nth-child(3n + 3) {
          margin-right: 0;
        }
      }
    }

    &.post_horizontal {
      width: calc(100vw - 40px);
      margin-bottom: 20px;
      @include md {
        width: 372px;
        margin-right: 20px;
        margin-bottom: 20px;

        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &.can-edit {
    width: 100%;
    height: 100%;
  }

  .collection-preview-post-image-container {
    width: 100%;
    height: 100%;
    position: relative;
    @extend %animation-fade;
    animation-duration: 400ms;

    &.hidden {
      .collection-preview-post-image {
        opacity: 0.4;
      }
    }

    &.youtube {
      .collection-preview-post-image {
        margin-top: -40px;
        height: calc(100% + 80px);
      }
    }

    .collection-preview-post-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .collection-preview-post-name {
      position: absolute;
      z-index: 2;
      bottom: 0px;
      padding: 8px;
      color: white;
      opacity: 0;
      transform: translateY(4px);
      z-index: 3;
      font-weight: bold;
      font-size: 14px;
      pointer-events: none;
    }

    .tag-container {
      @extend %flex-center;
      position: absolute;
      top: calc(50% - 12px);
      width: 100%;
      z-index: 2;
      pointer-events: none;

      .notification-tag {
        text-align: center;
        white-space: nowrap;
        background: white;
        padding: 4px 12px;
        border-radius: 4px;
        font-weight: bold;
        color: $darker;
        box-shadow: $shadow-dark;
      }
    }

    &:hover {
      &:before {
        opacity: 0.35;
      }

      .collection-preview-post-name {
        opacity: 1;
        transform: translateY(0);
        transition: all 0.2s ease-in-out;
      }
    }

    &:before {
      transition: opacity 0.3s;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(0deg, #000, rgba(0, 0, 0, 0.4) 18%, transparent 50%);
      opacity: 0;
      z-index: 2;
    }
  }

  .icn-show {
    position: relative;
    cursor: pointer;
    color: white;
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 16px;
    z-index: 3;

    .show-action-items {
      padding: 8px 8px 4px;
    }
  }
}
