@import '../../styles/shared.scss';

.onboarding-modal-outer-container {
  @extend %flex-center;
  position: fixed;
  font-family: $font-body-new;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  color: $darkest;

  .onboarding-modal-inner-container {
    @extend %flex-col;
    position: relative;
    overflow: auto;
    border-radius: 12px;
    z-index: 1;
    background: white;

    width: 90vw;
    max-width: 780px;
    height: 90vh;
    max-height: 680px;

    @include md-down {
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      border-radius: 0;
    }

    &.shrunk {
      transition: background 0.3s linear, transform 0.3s linear, border-radius 0.2s linear, width 0.3s linear, height 0.3s linear;
      transform: translate(calc(50vw - 120px), calc(50vh - 50px));
      border-radius: 50%;
      background: $darker;
      width: 60px;
      height: 60px;

      @include md-down {
        transform: translate(calc(-50vw + 46px), calc(50vh - 50px));
      }

      .modal-body,
      .modal-footer {
        display: none;
      }
    }

    .modal-body {
      @extend %flex-col;
      overflow: auto;
      flex: 1;
      padding: 60px 20px;

      @include md {
        padding: 60px 40px;
      }

      @include xl {
        padding: 60px 90px;
      }

      .onboarding-header {
        @extend %header-fancy-md;
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        margin-bottom: 8px;
      }

      .onboarding-subheader {
        @extend %paragraph-md;
        text-align: center;
        max-width: 540px;
        margin: 0 auto 8px;

        a {
          @extend %link;
        }
      }
    }

    .modal-footer {
      @extend %flex-row-middle-spread;
      position: absolute;
      padding: 12px 24px;
      bottom: 0;
      left: 0;
      width: 100%;
      background: white;
      border-top: $border-offwhite;

      @include md-down {
        padding-bottom: 84px;
      }

      .dots {
        @extend %flex-row;
        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 10px;
          background: $lightest;
          transition: transform 0.4s ease-in-out, background 0.4s ease-in-out;

          &.active {
            background: $primary;
            transform: scale(2);
          }
        }
      }

      .nav-actions {
        @extend %flex-row;

        .arrow-btn {
          font-size: 12px;
        }
      }
    }
  }
}
