@import '../../styles/shared.scss';

.profile-content {
  @extend %flex-col;
  width: 100%;
  font-family: $font-body;
  padding-bottom: 120px;

  .profile-content-inner-container {
    display: flex;
    padding-bottom: 80px;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    min-height: 100%;
    align-items: center;
    background: white;
    flex-direction: column;
    position: relative;

    .back-link {
      top: 16px;
      left: 32px;
      position: absolute;
      text-decoration-line: none;
      font-family: $font-body;
      font-size: 14px;
      font-weight: bold;
      color: $dark;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .back {
        width: 16px;
        height: 18px;
        margin-right: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $dark;
      }
    }

    .profile-header {
      @extend %flex-center;
      width: 100%;
      margin: 44px 0 40px;
      max-width: 1024px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .profile-data {
      @extend %flex-col-middle;
      width: 100%;
      position: relative;

      .view-more-container {
        @extend %flex-col-center;
        margin-top: 48px;
        .view-more-explainer {
          font-size: 12px;
          color: $light;
          text-transform: uppercase;
        }
        .view-more-of-shop-links {
          @extend %flex-center;
          padding-top: 12px;
          flex-wrap: wrap;

          .view-more-of-shop-link {
            @extend %btn-basic;
            margin: 0 12px 12px;
            font-size: 12px;
            text-transform: uppercase;
            color: $darker;
            font-weight: bold;
          }
        }
      }

      .add-from-social-actions-wrapper {
        margin: 0 auto;
        width: 100%;
        max-width: 720px;

        @include md-down {
          padding: 0 16px;
        }

        .explainer-header-container {
          @extend %flex-center-vertical;
          margin: 24px 0 24px;
          text-align: center;

          @include md-down {
            width: 100%;
            padding: 0 8px;
          }

          .explainer-header {
            font-size: 22px;
            text-transform: uppercase;
            font-weight: bold;
            color: $darkest;

            @include md-down {
              font-size: 14px;
            }
          }
          .explainer-subheader {
            font-size: 13px;
            color: $dark;
            white-space: normal;

            .button {
              @extend %link;
            }

            svg {
              margin-right: 4px;
              font-size: 10px;
            }
          }
        }
        .add-from-social-actions {
          @extend %flex-row;
          flex-wrap: wrap;

          .action-container {
            width: 50%;
            padding: 4px 4px;

            @include md-down {
              width: 100%;
              padding: 4px 4px 8px;
            }

            .action {
              @extend %flex-row-middle-spread;
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
              border-radius: 8px;
              padding: 12px 12px;
              cursor: pointer;
              transition: all 0.2s ease-in-out;

              &.inactive {
                &:hover {
                  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.7);
                  background: $prettymuchwhite;

                  svg {
                    color: $darkest;
                  }
                }
              }
              &.active {
                &:hover {
                  svg {
                    color: $darkest;
                  }
                }
              }

              .main {
                @extend %flex-row-middle;
                font-size: 16px;
                text-transform: uppercase;
                font-weight: bold;

                .badge {
                  font-weight: bold;
                  font-size: 10px;
                  background: $darker;
                  padding: 1px 3px;
                  border-radius: 2px;
                  color: white;
                  margin-left: 8px;
                }

                img {
                  width: 20px;
                  height: 20px;
                  object-fit: contain;
                  margin-right: 8px;
                }
              }

              .actions {
                @extend %flex-row-middle;
                margin-left: 8px;

                svg {
                  transition: all 0.2s ease-in-out;
                  margin-left: 8px;
                  color: $light;
                }
              }
            }
          }
        }
      }

      .add-element-btn-container {
        margin-top: 32px;
        align-self: center;

        .add-element-btn {
          @extend %btn-darker;
          padding: 12px 36px;
          font-size: 16px;
          text-align: center;
          font-family: $font-body;
          cursor: pointer;
          margin-bottom: 12px;
          letter-spacing: 0.5px;
          text-transform: uppercase;

          svg {
            margin: 0 0 -1px 8px;
          }
        }
      }

      .toggle-archived-btn {
        @extend %btn-text;
        align-self: center;
        padding: 12px 36px;
        font-size: 16px;
        text-align: center;
        font-family: $font-body;
        cursor: pointer;
        margin-top: 24px;
        letter-spacing: 0.5px;

        svg {
          margin: 0 0 -1px 8px;
        }
      }
    }

    .profile-element-block {
      @extend %flex-col-center;
      justify-content: center;

      > div:not([class*='-']) {
        width: 100%;
      }

      > div:not(.consult-preview-container):not(.user-collaborations-panel-outer-container),
      &.posts {
        @extend %flex-row;
        flex-wrap: wrap;
        justify-content: center;
      }

      &.consults > div {
        max-width: 600px;
      }
      &.collaborations > div {
        max-width: 720px;
      }

      .must-be-logged-in-msg {
        text-transform: uppercase;
        color: $medium;
        margin-top: 24px;
      }

      &.consults > div,
      &.collaborations > div > div,
      &.editorial > div {
        @extend %flex-col;
        width: 100%;

        .profile-element-preview-shell-container {
          @extend %flex-col;
          align-items: stretch;
          width: 100%;

          &:hover {
            .reorder-icn {
              display: inherit;
            }
          }

          .reorder-icn {
            top: 12px;
            right: 24px;

            @include md {
              display: none;
              right: 12px;
            }
          }
        }
      }

      &.editorial > a,
      &.editorial > div {
        max-width: 680px;

        .profile-element-preview-shell-container {
          .reorder-icn {
            top: 24px;
            right: 24px;

            @include md {
              display: none;
            }
          }
        }
      }
    }
  }
}

// It must be here because the draggable component pulls it to a fixed outer div while dragging.
.profile-element-preview-shell-container {
  position: relative;
  width: 100%;

  @include sm {
    width: auto;
  }

  &.posts {
    .reorder-icn {
      top: 8px;
      right: 8px;
      color: white;
    }
  }

  &.editorial {
    padding: 0 16px;
  }

  .reorder-icn {
    position: absolute;
    top: 0px;
    right: 8px;
    color: $lighter;
    cursor: grab;
    z-index: 4;

    svg {
      pointer-events: none;
    }
  }
}
