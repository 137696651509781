@import '../../styles/shared.scss';

.uploader-display {
  position: relative; // Changed from absolute to relative
  width: 100%; // Ensures it takes full width of its parent
  height: 100%; // Ensures it takes full height of its parent

  .dropzone-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
    z-index: 1000;

    &.active {
      opacity: 0.85;
      pointer-events: auto;
    }
  }
}
