@import '../../styles/shared.scss';

.code-results {
  gap: 12px;

  .row {
    @extend %flex-row-spread;
    background: white;
    margin-bottom: 12px;
    border-radius: 12px;

    &:first-child {
      background: $darkest;
      width: 100%;
      font-size: 20px;
      font-weight: bold;
      color: $white;
    }

    &.empty {
      @extend %flex-center;
      padding: 32px 0;
      font-size: 12px;
      color: $darker;
      text-transform: uppercase;
    }

    a {
      @extend %link;
    }

    .cell {
      @extend %flex-center;
      padding: 8px 20px;
      font-size: 12px;
      text-align: center;
      min-width: 120px;
      flex: 1;
      overflow: hidden;

      @include md-down {
        min-width: 64px;
        font-size: 10px;
      }

      &.with-icon {
        @extend %flex-center;

        svg {
          margin-left: 4px;
          font-size: 12px;
        }
      }

      &.multi-line {
        @extend %flex-col;
        align-items: flex-start;
        text-align: left;
        font-size: 12px;

        > div {
          max-width: 360px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .more {
          font-size: 12px;
          color: $dark;
          font-style: italic;
        }
      }

      img {
        width: 54px;
        height: 54px;
        object-fit: contain;
      }
    }
  }
}
