@import '../../styles/shared.scss';

.custom-login-outer-container {
  @extend %flex-col-middle;
  font-family: $font-header-sl;
  width: 100%;
  height: calc(100vh + $navHeight);
  margin-top: calc($navHeight * -1);

  &.referral {
    background: $darkest;
  }

  &.promote {
    transition: background 1s ease-in-out;

    .custom-login-inner-container {
      &.loading {
        background: $lighter;
      }
    }
  }
}
