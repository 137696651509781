@import '../../styles/shared.scss';

.add-pin-modal-final-outer-container {
  @extend %flex-row;
  flex-wrap: wrap;
  background: white;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow: hidden;

  .header-container {
    @extend %flex-row-spread;
    align-items: center;
    z-index: 2;
    position: absolute;
    width: 100%;
    background: white;
    padding: 11px 12px 4px;
    font-size: 18px;
    color: $dark;

    .icn {
      top: 16px;
      left: 16px;
      color: $dark;
      z-index: 2;
      font-size: 24px;
      cursor: pointer;

      &.back-icn {
        font-size: 20px;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .panel {
    width: 100%;
    position: absolute;
    background: white;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    padding: 48px 0;

    @include md {
      max-height: 80vh;
    }
  }

  .final-product-tile-container {
    @include md {
      width: 320px;
      left: 0;
      height: 100%;
    }
  }

  .final-product-advanced-selection-container {
    flex: 2;
    display: none;
    right: 0;
    pointer-events: none;

    @include md {
      width: calc(100% - 320px);
      right: 0;
      pointer-events: auto;
      height: 100%;
    }

    &.visible {
      opacity: 1;
      pointer-events: auto;
      position: absolute;
      display: flex;

      @include sm {
        position: inherit;
      }
    }

    @include sm {
      display: flex;
    }
  }

  .footer-container {
    @extend %flex-row-middle-spread;
    align-items: center;
    z-index: 2;
    height: 56px;
    bottom: 0;
    width: 100%;
    position: fixed;
    background: $prettymuchwhite;
    padding: 8px 12px;
    font-size: 18px;
    color: $dark;

    @include md {
      position: absolute;
    }

    .footer-warning {
      @extend %flex-row-middle;
      font-size: 12px;
      line-height: 1.1;
      padding-right: 12px;

      @include md {
        font-size: 14px;
      }

      .clickable {
        @extend %btn-basic;
        font-weight: bold;
        text-decoration: underline;
      }

      svg {
        margin-right: 8px;
        font-size: 16px;

        @include md {
          font-size: 20px;
        }
      }

      .web-risk-warning {
        a {
          color: $dark;
          text-decoration: underline;
        }
      }
    }

    .submit-btn {
      @extend %btn-dark;
      padding: 12px 16px;
      align-self: stretch;
      position: relative;
      transition: all 0.1s ease-in-out;

      &.adding {
        cursor: default;
        color: transparent;

        &:hover {
          opacity: 1;
        }

        .loader {
          position: absolute;
          height: 100%;
          margin-top: -16px;
        }
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}
