@import '../../../styles/shared.scss';

.home-partner-tiles-outer-container {
  @extend %flex-row-middle;
  background-color: $prettymuchwhite;

  @include lg-down {
    flex-direction: column;
  }

  .container {
    flex: 1;
  }

  .main-container {
    align-self: stretch;
    @extend %flex-center;
    justify-content: flex-end;
    background-image: linear-gradient(to bottom, $offwhite 0%, $prettymuchwhite 10%, $prettymuchwhite 90%, $offwhite 100%);

    .main-container-inner {
      max-width: 1240px;
      width: 100%;
      padding: 96px 48px;

      @include lg-down {
        padding: 64px 24px;
      }

      .header {
        font-family: $font-header;
        font-size: 40px;
        line-height: 1.3;
        color: $darkest;
        font-weight: 300;
        margin-bottom: 48px;

        @include sm {
          font-size: 40px;
        }

        @include md {
          font-size: 40px;
        }

        @include lg {
          font-size: 52px;
        }

        @include xl {
          font-size: 52px;
        }

        @include xxl {
          font-size: 64px;
        }
      }

      .group-toggle {
        @extend %flex-col;
        border-left: $border-dark;
        gap: 32px;
        padding: 8px 32px;

        @include md-down {
          gap: 20px;
          padding: 8px 24px;
        }
        @include md {
          gap: 20px;
          padding: 8px 24px;
        }
        @include lg {
          gap: 24px;
          padding: 8px 28px;
        }
        @include xl {
          gap: 32px;
          padding: 8px 32px;
        }
        @include xxl {
          gap: 32px;
          padding: 8px 32px;
        }

        .active-title {
          font-family: $font-header;
          font-size: 64px;
          height: 64px;
          line-height: 1;
          text-transform: uppercase;
          color: $darkest;

          @include md-down {
            font-size: 48px;
            height: 48px;
          }

          @include md {
            font-size: 68px;
            height: 68px;
          }

          @include lg {
            font-size: 68px;
            height: 68px;
          }

          @include xl {
            font-size: 84px;
            height: 84px;
          }

          @include xxl {
            font-size: 112px;
            height: 112px;
          }

          @keyframes fadeIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
          @keyframes slideUpFar {
            0% {
              transform: translateY(32px);
            }
            100% {
              transform: translateY(-0);
            }
          }

          @keyframes slideDownFar {
            0% {
              transform: translateY(-32px);
            }
            100% {
              transform: translateY(-0);
            }
          }

          &.transitioning {
            .text {
              display: none;
            }
          }

          &.transitioning-up {
            .text {
              animation: fadeIn 0.8s ease-in-out forwards, slideUpFar 0.6s ease-in-out forwards;
            }
          }
          &.transitioning-down {
            .text {
              animation: fadeIn 0.8s ease-in-out forwards, slideDownFar 0.6s ease-in-out forwards;
            }
          }
        }

        .scroll {
          @extend %btn-basic;
          font-size: 24px;
        }
      }
    }
  }

  .tiles-container {
    @extend %flex-row;
    flex-wrap: wrap;
    background-color: $darkest;

    @include xl {
      max-width: calc(100vh * 3 / 4 + 120px); // Offer small overflow at top and bottom
    }

    .tile {
      position: relative;
      width: 33.3333%;
      aspect-ratio: 1;
      overflow: hidden;
      background-color: $darkest;

      // Every other
      &:nth-child(2n) {
        background-color: $black;
      }

      @include lg-down {
        width: 33.333vw;
        min-height: 33.333vw;
      }

      @include md-down {
        width: 50vw;
        min-height: 50vw;
      }

      // Animated delay more for each

      @for $i from 1 through 12 {
        &:nth-child(#{$i}) {
          img.primary {
            opacity: 0;
            animation: fadeIn 0.8s ease-in-out forwards;
            animation-delay: #{$i * 0.03 + 0.2}s;
            animation-fill-mode: forwards;
          }
          img.logo {
            opacity: 0;
            animation: fadeIn 0.5s ease-in-out forwards;
            animation-delay: #{$i * 0.03}s;
            animation-fill-mode: forwards;
          }
        }
      }

      img.transitioning {
        display: none;
      }

      // Only show 6 on smallest screens
      @include md-down {
        &:nth-child(n + 7) {
          display: none;
        }
      }
      // Only show 9 on smaller screens
      @include lg-down {
        &:nth-child(n + 10) {
          display: none;
        }
      }

      img.primary {
        @extend %animation-fade;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      img.logo {
        @extend %animation-fade;
        position: absolute;
        width: 80%;
        left: 10%;
        height: 40%;
        top: 30%;
        object-fit: contain;
        // filter: invert(1);
      }

      .cover {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
