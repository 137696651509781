@import '../../styles/shared.scss';

.snapshop-explainer-panel-outer-container {
  @extend %flex-row-middle;
  height: 100%;

  &.full-panel {
    img.preview {
      max-width: 50%;
    }
  }

  img.preview {
    max-width: 40%;
  }

  @include md-down {
    flex-direction: column;

    img.preview {
      max-width: 100% !important;
    }
  }

  .description-container {
    @extend %flex-col;
    margin: 0 0 0 24px;

    @include md-down {
      flex-direction: column;
      margin: 24px 0 0 0;

      img.preview {
        max-width: 80%;
      }
    }

    .header-wrapper {
      @extend %flex-row;

      .header {
        font-size: 18px;
        font-weight: bold;
        color: $darkest;
      }

      .new-badge {
        @extend %flex-center;
        font-size: 11px;
        padding: 3px 8px;
        border-radius: 12px;
        border: $border-darkest;
        margin-left: 4px;
        font-family: $font-social;
        background: white;
      }
    }

    .subheader {
      margin-top: 12px;
      font-size: 12px;
      color: $dark;
    }

    .bullets {
      margin-top: 12px;
      font-size: 12px;
      color: $dark;

      .bullet {
        @extend %flex-row;

        .number {
          font-weight: bold;
          margin-right: 4px;
          width: 12px;
          text-align: center;
        }
      }
    }

    .login-btn {
      @extend %flex-row;
      @extend %btn-basic;
      margin-top: 12px;
      padding: 10px 16px;
      border-radius: 6px;
      background: black;
      color: white;
      width: max-content;
      font-size: 14px;
      text-decoration: none;
    }

    .drag-container {
      @extend %flex-row;
      margin-top: 12px;
      align-self: flex-start;
      padding: 4px;
      border: 2px dashed $lighter;
      transition: all 0.2s ease-in-out;
      border-radius: 6px;
      text-decoration: none;

      &:hover {
        border: 2px dashed $darkest;
      }

      .drag-label {
        font-size: 12px;
        text-transform: uppercase;
        border-radius: 6px;
        background: $darkest;
        color: white;
        font-weight: bold;
        letter-spacing: 0.1;
        padding: 12px 18px;
      }
    }

    .need-help {
      font-size: 10px;
      margin-top: 10px;
      color: $dark;

      a {
        @extend %link;
      }
    }
  }
}
