@import '../../../styles/shared.scss';

.contract-links-card {
  .contract-past-links {
    margin-top: 16px;
  }

  .past-container-header {
    @extend %flex-row-middle;
    @extend %btn-basic;
    justify-content: flex-end;
    font-weight: bold;
    color: $dark;
    font-size: 11px;
    text-transform: uppercase;
    margin-right: 4px;

    .header {
    }

    svg {
      margin-left: 8px;
    }
  }
  .show-more,
  .show-less {
    @extend %btn-basic;
    text-align: center;
    font-size: 12px;
    color: $light;
    text-transform: uppercase;
  }
}
