@import '../../styles/shared.scss';

.consult-element-picker-outer-modal {
  max-width: calc(600px + 200px) !important;

  .consult-element-picker-modal {
    @extend %flex-row;
    flex-direction: row !important;
    padding: 0 !important;
    overflow: hidden !important;
    color: $darker;

    .section-selection {
      @extend %flex-col;
      flex: 1;
      background: $offwhite;
      border-right: $border-lightest;
      max-width: 200px;
      display: none;
      padding: 24px;

      @include md {
        display: flex;
      }

      .filter {
        @extend %btn-basic;
        padding-bottom: 4px;
        margin-bottom: 4px;
        color: $dark;

        &.selected {
          font-weight: bold;
          color: $darkest;
        }
      }
    }

    .sections {
      flex: 1;
      max-width: 600px;
      padding: 0;
      overflow: auto;

      @include sm {
        padding: 24px;
      }

      &.adding {
        filter: blur(3px);
      }
      .header {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 12px;
        padding: 16px;

        @include sm {
          padding: 0;
        }
      }

      .spacer-container {
        height: 1px;
        width: 100%;
        background: $lighter;
        position: relative;
        margin: 48px 0 16px;

        &.top {
          margin: 32px 0 24px;
        }

        .label {
          position: absolute;
          top: -8px;
          margin: 0 auto;
          width: 220px;
          left: calc(50% - (220px / 2));
          white-space: nowrap;
          font-size: 12px;
          background: white;
          text-align: center;
          padding: 0 12px;
          font-weight: bold;
          color: $dark;
        }
      }

      .filter-section {
        @extend %flex-col;

        &:last-child {
          padding-bottom: 120px;
        }

        .filter-section-header {
          font-size: 13px;
          font-weight: bold;
          margin: 12px 0;
          text-transform: uppercase;
          padding: 0 16px;

          @include md {
            padding: 0;
          }
        }
      }

      .element-container {
        @extend %flex-row-middle-spread;
        padding: 12px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        cursor: pointer;
        margin: 0 24px 16px;

        @include sm {
          margin: 0 0 16px;
        }

        &:hover {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        }

        .main {
          @extend %flex-row-middle;

          svg.display {
            margin-right: 8px;
            font-size: 20px;
            min-width: 32px;
          }

          .name {
            font-size: 16px;
          }
        }

        svg.add {
          margin-right: 8px;
          font-size: 20px;
          width: 32px;
          color: $light;
        }
      }
    }
  }
}
