@import '../../styles/shared.scss';

.landing-promotion-panel-outer-container {
  @extend %flex-col-center;
  width: 100%;
  background: var(--background-color);
  min-height: calc(100vh + 240px);
  margin-top: -240px;
  padding-top: 240px;
  position: relative;
  overflow: hidden;
  font-family: $font-body;
  @extend %animation-fade;

  .landing-promotion-panel-container {
    @extend %flex-row;
    width: calc(100% - 36px);
    max-width: 1240px;
    position: relative;
    height: 100%;

    @include md {
      padding: 24px;
    }

    @include xl {
      padding: 24px 0;
    }

    .landing-promotion-panel-left {
      @extend %flex-col;
      padding-top: 12vh;
      z-index: 2;

      @include md {
        max-width: 50vw;
        padding-top: 12vh;
      }

      @include xl {
        max-width: 680px;
      }
      @include xxl {
        max-width: 680px;
        padding-top: 20vh;
      }

      &.invite {
        @include md {
          padding-top: 13vh;
        }

        @include xxl {
          padding-top: 15vh;
        }
      }

      h1 {
        font-size: 30px;
        line-height: 1.1;
        font-weight: 700;
        letter-spacing: -0.6px;
        color: white;

        font-family: $font-header-sl;
        margin: 0;

        @include md {
          font-size: 34px;
        }

        @include xl {
          font-size: 44px;
        }
      }

      h2 {
        margin: 12px 0 0;
        line-height: 1.1;
        font-weight: 400;
        font-size: 16px;
        color: white;

        @include md {
          font-size: 18px;
          max-width: 440px;
        }

        @include xl {
          font-size: 20px;
          max-width: 100%;
        }
      }

      .invitation-container {
        @extend %flex-row-middle;
        margin-top: 12px;
        font-size: 14px;
        color: white;
        font-weight: bold;
        text-transform: uppercase;

        a {
          @extend %flex-row-middle;
          color: white;
          text-decoration: none;

          .user {
            text-decoration: underline;
            margin-left: 4px;
          }

          .back-icn {
            @extend %flex-center;
            margin-right: 6px;
            height: 14px;
            width: 14px;
            color: white;
          }
        }

        img {
          margin-right: 8px;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .referral-methods-container {
        margin-top: 24px;

        .referral-method-container {
          @extend %flex-col;
          color: $white;
          margin-top: 24px;

          .label-container {
            .label {
              font-size: 12px;
              text-transform: uppercase;
            }
            .sublabel {
              font-size: 20px;
              font-weight: bold;
            }
          }

          .additional-msg {
            font-size: 12px;
            margin-top: 12px;

            .btn {
              @extend %btn-basic;
              font-weight: bold;
              text-decoration: underline;
            }
          }

          .qr-holder {
            margin-top: 12px;
            background-color: white;
            width: max-content;
            padding: 5px;
            border-radius: 4px;
            cursor: pointer;
          }

          .copy-container {
            @extend %flex-row;
            margin-top: 12px;
            border: $border-white;
            align-self: flex-start;
            border-radius: 4px;

            .link {
              @extend %flex-center;
              padding: 6px 12px;
              font-size: 12px;
              font-weight: bold;
              color: white;
              text-decoration: none;
            }
            .copy-btn {
              @extend %btn-basic;
              padding: 8px 12px;
              background: $white;
              border-radius: 0;
              color: $darker;
            }
          }

          form {
            @extend %flex-col;
            margin-top: 12px;

            textarea {
              @extend %input-shared;
              background: transparent;
              border: $border-white;
              font-family: $font-body;
              color: white;
              font-weight: bold;
              max-width: 400px;

              &:focus {
                border: $border-white;
              }

              &::placeholder {
                color: $lighter;
                font-weight: normal;
                font-size: 14px;
              }
            }

            .send-emails-btn {
              @extend %btn-white-new;
              margin-top: 12px;
              align-self: flex-start;
              font-weight: bold;
              background: transparent;
              color: $white;
              border: $border-white;
              padding: 8px 24px;
              text-transform: uppercase;
            }

            .successful-sent-msg {
              @extend %btn-white-new;
              color: white;
              font-size: 12px;
              margin-top: 12px;
              align-self: flex-start;
              font-weight: bold;
              background: transparent;
              color: $white;
              border: $border-dark;
              padding: 8px 24px;
              text-transform: uppercase;
            }
          }
        }
      }

      .register-form {
        @extend %flex-col;
        margin-top: 32px;
        max-width: 440px;

        &.referral {
          input {
            background: transparent;
            border: $border-light;
            font-family: $font-body;
            color: white;
            font-weight: bold;

            &:focus {
              border: $border-white;
            }

            &::placeholder {
              color: $lighter;
              font-weight: normal;
            }
          }
        }

        input {
          @extend %input-shared;
          margin-bottom: 4px;
          font-family: $font-body;
          font-size: 16px;
        }

        > [class*='-select'] {
          margin-bottom: 4px;
        }

        .register-btn-container {
          align-self: flex-start;

          .register-btn {
            @extend %btn-outline-new;
            font-weight: bold;
            background: transparent;
            color: $white;
            border: $border-white;
            padding: 8px 24px;
          }
        }
      }
    }
  }

  .landing-promotion-panel-floating-products {
    @extend %flex-row;
    height: calc(80vh + 240px);
    top: 0px;
    position: absolute;
    right: -20vw;
    width: 80vw;
    display: none;

    @include md {
      display: inherit;
      right: -20vw;
      width: 60vw;
    }

    @include lg {
      right: -8vw;
      width: 54vw;
    }

    @include xl {
      right: -5vw;
      width: 46vw;
    }

    .floating-products-col {
      align-self: stretch;
      flex: 1;

      &.left {
        margin-right: 12px;

        @include md {
          margin-right: 24px;
        }

        .floating-product {
          transform: translateY(-240%);

          &.moving {
            transform: translateY(200px);
          }
        }
      }

      &.right {
        .floating-product {
          transform: translateY(200px);

          &.moving {
            transform: translateY(-240%);
          }
        }
      }

      .floating-product {
        @extend %flex-center;
        width: 100%;
        height: calc(80vw / 2);
        width: calc(80vw / 2);
        margin-bottom: 12px;
        background: white;
        border-radius: 10px;
        transition: transform 40s linear;
        box-shadow: $shadow-dark;
        overflow: hidden;

        @include md {
          height: calc(60vw / 2);
          width: calc(60vw / 2);
          margin-bottom: 24px;
        }

        @include lg {
          height: calc(54vw / 2);
          width: calc(54vw / 2);
        }

        @include xl {
          height: calc(46vw / 2);
          width: calc(46vw / 2);
        }

        &:nth-child(1) {
          img {
            transition-delay: 0.1s;
          }
        }
        &:nth-child(2) {
          img {
            transition-delay: 0.2s;
          }
        }
        &:nth-child(3) {
          img {
            transition-delay: 0.3s;
          }
        }
        &:nth-child(4) {
          img {
            transition-delay: 0.4s;
          }
        }
        &:nth-child(5) {
          img {
            transition-delay: 0.5s;
          }
        }
        &:nth-child(6) {
          img {
            transition-delay: 0.6s;
          }
        }
        &:nth-child(7) {
          img {
            transition-delay: 0.7s;
          }
        }
        &:nth-child(8) {
          img {
            transition-delay: 0.8s;
          }
        }
        &:nth-child(9) {
          img {
            transition-delay: 0.9s;
          }
        }
        &:nth-child(10) {
          img {
            transition-delay: 1s;
          }
        }

        img {
          width: 64%;
          height: 64%;
          position: absolute;
          left: 18%;
          top: 18%;
          object-fit: contain;
          transition: opacity 1.2s cubic-bezier(0.16, 0.33, 0.24, 1.22);
          opacity: 0;

          &.active {
            opacity: 1;
            transition: opacity 1.2s cubic-bezier(0.53, -0.01, 0.87, -0.01);
          }

          &.removePadding {
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
