@import '../../styles/shared.scss';

.search-content-modal-outer-container {
  @extend %flex-col;
  max-width: 640px !important;

  .input-container {
    margin-right: 12px;
    input {
      @extend %input-shared;
      flex: 1;
      width: 100%;
    }
  }

  .results-container {
    margin-top: 24px;
    .results {
      .result {
        @extend %flex-row-middle-spread;
        padding: 6px 12px 6px 6px;
        border-radius: 6px;
        border: $border-lightest;

        &:not(:first-child) {
          margin-top: 12px;
        }

        &.users {
          .main {
            .image-container {
              img {
                object-fit: cover;
              }
            }
          }
        }

        .main {
          @extend %flex-row-middle;
          flex: 1;

          .image-container {
            position: relative;
            border-radius: 4px;
            overflow: hidden;
            height: 60px;
            width: 60px;
            margin-right: 12px;

            .empty-img {
              height: 60px;
              width: 60px;
              background: $offwhite;
            }
            @include md-down {
              height: 80px;
              width: 80px;

              .empty-img {
                height: 80px;
                width: 80px;
              }
            }

            img {
              object-fit: contain;
              height: 100%;
              width: 100%;
            }
          }

          .data {
            @extend %flex-col;
            flex: 1;
            color: $dark;

            .title {
              font-size: 14px;
              line-height: 16px;
              font-weight: bold;
              color: $darker;
            }
            .domain {
              @extend %btn-basic;
              font-size: 11px;
              color: $dark;
              svg {
                font-size: 8px;
                margin-left: 4px;
              }
            }
            .date {
              margin-top: 4px;
              font-size: 10px;
              color: $medium;
            }
          }
        }
        .secondary {
          @extend %flex-row-middle-spread;
          margin-left: 24px;

          @include md-down {
            margin-left: 0;
            justify-content: space-between;
          }

          .link-actions {
            @extend %flex-row-middle;
            color: $dark;
            font-size: 14px;
            .link-action {
              @extend %btn-darkest-new;
              padding: 8px 12px;
              font-size: 11px;

              &.active {
                @extend %btn-text-new;
                pointer-events: none;
                padding: 8px 0;
              }

              svg {
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
    .empty-msg {
      margin: 24px 0;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
      color: $dark;
    }
  }
}
