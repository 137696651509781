@import '../../styles/shared.scss';

.shopmy-select.with-styling {
  width: 100%;

  .shopmy-select__control {
    background-color: transparent;
    height: 24px;
    min-height: 24px;
    border: $border-white;
    box-shadow: none;

    .shopmy-select__multi-value {
      background-color: $dark;
      border: $border-dark;
      border-radius: 2px; // Adjust the pill border radius
      margin: 2px; // Adjust the margin between pills

      .shopmy-select__multi-value__label,
      .shopmy-select__value__label {
        @extend %label;
        font-size: 10px;
        padding: 2px 2px;
        color: white;
      }
    }

    .shopmy-select__value-container {
      @extend %flex-row-middle;
      padding: 0;
      height: 24px;
      min-height: 24px;

      .shopmy-select__placeholder {
        @extend %label;
        font-size: 10px;
        padding: 2px 2px;
        color: $lighter;
      }

      [class*='-Input'] {
        margin: 0;
        padding: 0;
        height: 24px;
        min-height: 24px;

        .shopmy-select__input {
          @extend %flex-center;
          display: flex !important;
          padding-left: 4px;
          height: 100%;

          input {
            @extend %label;
            text-align: left;
            font-size: 10px !important;
            padding: 2px 2px;
            color: $lighter !important;
          }
        }
      }
    }

    .shopmy-select__indicators {
      height: 24px;

      .shopmy-select__indicator-separator {
        display: none;
      }
      .shopmy-select__dropdown-indicator,
      .shopmy-select__clear-indicator {
        padding: 4px;
      }
    }
  }

  .shopmy-select__single-value {
    @extend %label;
    margin-top: -1px;
    font-size: 10px;
    padding: 0px 2px;
    color: white;
  }

  &.is-dark {
    .shopmy-select__control {
      border: $border-darkest;

      .shopmy-select__multi-value {
        background-color: $darkest;
        border: $border-darkest;

        .shopmy-select__multi-value__label,
        .shopmy-select__value__label {
          color: $dark;
        }
      }

      .shopmy-select__value-container {
        .shopmy-select__placeholder {
          color: $dark;
        }

        [class*='-Input'] {
          .shopmy-select__input input {
            color: $darkest !important;
          }
        }
      }
    }

    .shopmy-select__single-value {
      @extend %label;
      margin-top: -1px;
      font-size: 10px;
      padding: 0px 2px;
      color: $darkest;
    }
  }
}

.shopmy-select.without-styling {
  .shopmy-select__control {
    box-shadow: none;
    border-color: $lightest;
    height: 40px;
    min-height: 40px;

    .shopmy-select__value-container {
      height: 40px;
      min-height: 40px;
      .shopmy-select__placeholder {
        color: $lighter;
      }

      [class*='-Input'] {
        height: 40px;
        min-height: 40px;
        .shopmy-select__input {
          input {
          }
        }
      }
    }
    .shopmy-select__single-value {
      margin-top: -1px;
      padding: 0px 2px;
      font-size: 14px;
      color: $darker;
    }
  }
}
