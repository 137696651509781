@import '../../../styles/shared.scss';

.opportunity-setup-guide-finalizing-outer {
  @extend %flex-center;
  background-color: $offwhite;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  .opportunity-setup-guide-finalizing-inner {
    @extend %flex-col-center;
    padding: 48px;

    .media-container {
      @extend %flex-center;
      width: 240px;
      height: 240px;
      border-radius: 24px;
      margin-bottom: 48px;
      transition: background-color 0.5s;

      &.complete {
        background-color: white;
        color: $primary;
        font-size: 84px;

        svg {
          @extend %animation-fade;
        }
      }

      &.failed {
        background-color: $white;
        color: $darkest;
        font-size: 84px;

        svg {
          @extend %animation-fade;
        }
      }
    }

    .content-container {
      @extend %flex-col-center;
      text-align: center;
      gap: 8px;
      max-width: 640px;
      height: 100px;

      &.transitioning {
        .header {
          opacity: 0;
          transform: translateY(6px);
        }
        .subheader {
          transition: all 0.4s;
          opacity: 0;
          transition-delay: 0s;
        }
      }

      .header {
        font-size: 24px;
        font-weight: bold;
        transition: all 0.8s;
      }

      .subheader {
        font-size: 16px;
        line-height: 1.4;
        color: $medium;
        transition: all 0.8s;
        transition-delay: 0.3s;
      }
    }
    .actions {
      @extend %flex-row-middle;
      height: 40px;
      margin-top: 12px;
      gap: 12px;

      &.complete,
      &.failed {
        .action {
          opacity: 1;
          transform: scale(1);

          &.secondary {
            transition-delay: 0.2s;
          }
        }
      }

      .action {
        opacity: 0;
        transform: scale(0.9);

        &.primary {
          @extend %btn-primary-new;
          padding: 16px 36px;
          font-size: 12px;
          transition: all 1s ease;
        }

        &.secondary {
          @extend %btn-outline-new;
          padding: 16px 36px;
          font-size: 12px;
          transition: all 1s ease;
        }
      }
    }
  }
}
