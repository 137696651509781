@import '../../styles/shared.scss';

.bulk-request-modal-outer {
  height: 100%;

  .bulk-request-modal-inner {
    max-width: 900px;

    .bulk-request-modal {
      flex: 1;
      padding: 20px 20px 240px 20px;

      .bulk-request-title-container {
        @extend %flex-row;
        justify-content: space-between;

        .bulk-request-title {
          font-family: $font-header;
          font-weight: bold;
          letter-spacing: 0.7px;
          font-size: 2rem;
          line-height: 36px;
          margin-bottom: 10px;
        }

        .gifting-remaining-container {
          @extend %flex-col;
          gap: 5px;
          font-family: $font-body-new;
          align-items: flex-end;

          .gifting-remaining-label {
            @extend %label;
            font-size: 0.7rem;
            color: black;
          }

          .gifting-remaining {
            font-size: 0.7rem;
            font-weight: bold;
            color: white;
            border-radius: 500px;
            background-color: black;
            width: max-content;
            padding: 5px 10px;

            svg {
              color: white;
            }
          }
        }
      }

      .bulk-request-section {
        margin-top: 35px;

        .bulk-request-section-title {
          font-size: 1.1rem;
          margin-bottom: 10px;

          .bulk-request-section-subtitle {
            font-weight: normal;
            font-size: 0.8rem;
            color: $medium;
          }
        }

        span.bulk-request-section-title-disclaimer {
          font-size: 0.65rem;
          color: $medium;
          margin-left: 10px;
          max-width: 550px;
        }

        .bulk-request-set-expiration-container {
          @extend %flex-row-middle;
          gap: 10px;

          .clear-expiration {
            @extend %btn-offwhite-new;
            font-size: 10px;
            padding: 8px 16px;
          }

          .set-expiration-from-last-selected {
            @extend %btn-offwhite-new;
            background-color: $prettymuchwhite;
            font-size: 10px;
            padding: 4px 8px;
            color: $medium;

            svg {
              margin-right: 4px;
            }
          }
        }

        .bulk-request-subsection {
          @extend %flex-row;
          align-items: center;

          .select-request-options {
            @extend %flex-col;
            min-width: 300px;
            gap: 14px;

            .subsection-with-label {
              @extend %flex-col;

              .select-inactive {
                padding: 8px 24px;
                border-radius: 4px;
                border: $border-lighter;
                font-size: 12px;
                min-width: unset;
                color: $light;
                align-self: flex-start;
              }
            }

            .select-label.small {
              font-size: 0.8rem;
              color: $medium;
              margin-bottom: 4px;

              .clickable {
                cursor: pointer;
                color: $primary;
                font-weight: bold;
                text-decoration: underline;
              }
            }
          }
        }
      }

      .bulk-request-location-specific {
        @extend %flex-row-middle;
        gap: 4px;
        font-size: 12px;
        color: $light;
        margin-top: 8px;

        &.active {
          color: $dark;

          svg {
            color: $medium;
          }
        }

        svg {
          font-size: 10px;
          margin-right: 4px;
          color: $light;
        }

        span {
          margin-right: 4px;
        }
      }
    }

    .bulk-request-footer {
      @extend %flex-row;
      justify-content: space-between;
      gap: 30px;
      position: sticky;
      bottom: 0;
      background-color: white;
      border-top: $border-lighter;
      padding: 20px 20px;
      width: calc(100% + 48px);
      margin-left: -24px;
      transform: translateY(24px);

      .disclaimer-container {
        .disclaimer {
          @extend %flex-row-middle;
          font-size: 0.8rem;
          color: $medium;
          gap: 8px;

          svg {
            font-size: 0.7rem;
          }

          &.error {
            color: $error;
          }

          .clickable {
            cursor: pointer;
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }

      .bulk-request-footer-buttons {
        @extend %flex-row;
        justify-content: flex-end;
        gap: 10px;

        .bulk-request-footer-button {
          @extend %btn-basic;
          @extend %label;
          background-color: white;
          color: black;
          font-size: 0.9rem;
          padding: 14px 16px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          outline: none;
          border: $border-dark;
          display: grid;
          place-items: center;

          &.send-requests-button {
            background-color: $primary;
            color: white;
            border: none;

            &.inactive {
              background-color: $offwhite;
              color: $lighter;
              pointer-events: none;
            }
          }

          &.warn-button {
            background-color: $error;
            color: white;
            border: none;
          }
        }
      }
    }
  }
}
