@import '../../../../styles/shared.scss';

.lookbook-report-talent-outer {
  .lookbook-report-talent-inner {
    position: relative;

    .lookbook-report-talent-search-container {
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(calc(-100% - 25px));
      width: 450px;
      @extend %flex-row;
      gap: 10px;

      @include md-down {
        display: none;
      }

      .bulk-message-talent-container {
        position: relative;

        @include lg-down {
          display: none;
        }

        .displayed-text-container {
          @extend %btn-basic;
          @extend %label;
          background-color: white;
          color: $darkest;
          font-size: 0.7rem;
          padding: 14px 16px;
        }

        .talent-select-dropdown-container {
          position: absolute;
          top: calc(100% + 5px);
          right: 0;
          width: max-content;
          background-color: $prettymuchwhite;
          border: $border-lightest;
          border-radius: 5px;
          z-index: 100;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          @extend %flex-col;
          gap: 5px;
          padding: 10px;

          .talent-select-dropdown-option {
            @extend %btn-basic;
            background-color: white;
            color: $darkest;
            padding: 5px 16px;
            cursor: pointer;
            width: 200px;
            text-wrap: wrap;

            .talent-select-dropdown-option-label {
              font-size: 0.8rem;
              position: relative;
              @extend %flex-row;
              align-items: center;
              gap: 5px;
              color: $darkest;
              margin-bottom: 2px;

              span {
                background: $dark;
                color: white;
                border-radius: 500px;
                font-size: 0.5rem;
                height: 100%;
                padding: 1px 4px;
                display: grid;
                place-content: center;
              }
            }

            .talent-select-dropdown-option-sublabel {
              font-size: 0.7rem;
              color: $medium;
            }
          }
        }
      }

      .lookbook-report-download-button {
        @extend %btn-basic;
        @extend %label;
        background-color: black;
        color: white;
        font-size: 0.7rem;
        padding: 14px 16px;
      }

      input.lookbook-report-talent-search {
        font-size: 0.8rem;
        padding: 12px 15px;
        border-radius: 5px;
        border: $border-lightest;
        outline: none;
        color: $dark;
        font-weight: 400;
        width: 100%;
        margin: 0 auto;
        display: block;
      }
    }

    .lookbook-report-talent-table {
      width: 100%;
      border-radius: 16px;
      overflow: hidden;
      @extend %flex-col;
      gap: 4px;

      .lookbook-report-talent-table-row {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
        background-color: white;
        color: black;

        @include md-down {
          grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
        }

        &.header {
          font-size: 0.8rem;
          background-color: black;
          color: white;
          padding: 15px 10px;
          font-weight: 600;
        }

        .lookbook-report-talent-table-cell {
          padding: 5px 10px;
          @extend %flex-col;
          justify-content: center;
          align-items: center;
          gap: 2px;
          font-size: 12px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $darker;

          &.header {
            @extend %flex-row;
            justify-content: center;
            align-items: center;
            gap: 2px;
            color: white;
            font-size: 0.8rem;
            font-weight: 600;
            cursor: pointer;

            svg {
              opacity: 0.3;
              transition: opacity 0.2s ease-out;
              margin-left: 5px;

              &:hover {
                opacity: 0.5;
              }

              &.active {
                opacity: 1;
              }
            }
          }

          @include md-down {
            &.less-important {
              display: none;
            }
          }

          &.show-all-button {
            @extend %btn-basic;
            font-weight: 400;
            padding: 20px 0;
            grid-column: 1 / -1;
            text-align: center;
            font-size: 0.8rem;
            color: black;
            text-decoration: underline;
          }

          &.profile-cell {
            @extend %flex-row;
            gap: 10px;
            justify-content: unset;

            .profile-image {
              width: 44px;
              height: 44px;
              border-radius: 50%;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                background-color: black;
                object-fit: cover;
              }

              .loading-image {
                background-color: $offwhite;
                width: 100%;
                height: 100%;
              }
            }

            .profile-image .profile-initials {
              width: 44px;
              height: 44px;
              border-radius: 50%;
              background-color: black;
              color: white;
              font-size: 1.3rem;
              font-weight: 400;
              display: grid;
              place-items: center;
              font-family: $font-header;
            }

            .profile-text {
              @extend %flex-col;
              gap: 2px;
              justify-content: center;

              .placeholder {
                background-color: $offwhite;
                border-radius: 4px;
                color: transparent !important;
              }

              .profile-name {
                @extend %animation-fade;
                font-size: 14px;
                letter-spacing: 0;
                font-weight: 500;
                cursor: pointer;

                &:hover {
                  text-decoration: underline;
                }

                &.placeholder {
                  width: 120px;
                }
              }

              .request-date-last-action {
                @extend %animation-fade;
                font-size: 10px;
                color: $dark;
                font-style: italic;

                &.placeholder {
                  width: 60px;
                }
              }

              .request-date-last-action {
                @extend %flex-row-middle;
                gap: 4px;

                .profile-action {
                  @extend %btn-basic;
                  text-transform: uppercase;
                  font-size: 10px;
                }
              }
              .profile-order-items {
                @extend %flex-row;
                gap: 5px;

                .profile-order-item {
                  position: relative;

                  img {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: black;
                    object-fit: cover;
                  }
                }
              }

              .profile-url {
                @extend %btn-basic;
                font-size: 0.75rem;
                font-weight: normal;
                color: $light;
                text-decoration: none;

                svg {
                  margin-left: 3px;
                  height: 10px;
                }
              }
            }
          }

          &.selections-cell {
            .selections {
              @extend %flex-row;
              flex-wrap: wrap;
              gap: 4px;

              .selection {
                .selection-image {
                  border-radius: 4px;
                  object-fit: contain;
                  width: 32px;
                  height: 32px;
                  display: grid;
                  place-items: center;
                  background-color: $lightest;
                  color: $light;
                  border-radius: 4px;
                  font-size: 0.6rem;
                  pointer-events: none;

                  &.original {
                    aspect-ratio: unset;
                    width: 32px;
                  }

                  &.cover {
                    object-fit: cover;
                    width: 32px;
                    height: 32px;
                  }

                  &.contain {
                    object-fit: contain;
                    width: 32px;
                    height: 32px;
                  }
                }
              }
            }
          }

          .lookbook-report-promoting-badge {
            display: grid;
            place-items: center;
            padding: 4px 8px;
            text-align: center;
            border-radius: 500px;
            color: white;
            background-color: $primary;
            font-size: 11px;
            border: $border-primary;
          }

          .lookbook-report-expired-badge {
            cursor: pointer;
            display: grid;
            place-items: center;
            padding: 4px 8px;
            text-align: center;
            border-radius: 500px;
            color: $medium;
            background-color: $offwhite;
            font-size: 11px;
          }

          .lookbook-report-order-status-badge {
            display: grid;
            place-items: center;
            padding: 4px 8px;
            text-align: center;
            border-radius: 500px;
            color: white;
            font-size: 11px;

            // These are all of the statuses from react lookbook_helpers.js
            &.delivered {
              background-color: #11835a22;
              border: $border-primary;
              color: #11835a;
            }

            &.cancelled,
            &.failure,
            &.unknown {
              background-color: #d3000022;
              border: #d30000 solid 1px;
              color: #d30000;
            }

            &.accepted,
            &.shipped,
            &.submitted,
            &.pending,
            &.ready_for_pickup,
            &.out_for_delivery,
            &.attempted_delivery {
              background-color: white;
              border: $border-primary;
              color: $primary;
            }
          }

          .lookbook-report-declined-badge,
          .lookbook-report-pending-badge {
            @extend %flex-col;
            gap: 4px;
            text-align: center;

            .badge-text {
              font-size: 0.8rem;
              font-weight: bold;
            }

            .chat-button {
              @extend %label;
              @extend %btn-basic;
              font-size: 0.6rem;
              font-weight: 400;
            }
          }

          .lookbook-report-pending-badge .badge-text {
            color: $primary;
          }

          .lookbook-report-declined-badge .badge-text {
            color: grey;
          }
        }
      }
    }
  }
}
