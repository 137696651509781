@import '../../../styles/shared.scss';

.contract-propose-task-calendar-selection-container {
  @extend %flex-col;
  height: auto !important;
  .contract-propose-task-calendar-selection-modal-content {
    padding-bottom: 80px;

    .section {
      @extend %flex-col;
      min-height: 480px;
      .selection-type-section {
        &:not(:first-child) {
          margin-top: 12px;
        }

        .selection-type-option {
          @extend %flex-row-middle;
          font-size: 13px;

          &.selected {
            .selection-type-option-checkbox {
              background: $dark;
              color: $white;
              border: $border-dark;
            }
          }

          .selection-type-option-checkbox {
            @extend %btn-basic;
            @extend %flex-center;
            height: 16px;
            width: 16px;
            border-radius: 2px;
            background: $offwhite;
            margin-right: 8px;
            color: transparent;
            border: $border-lightest;
          }
        }
        .date-picker {
          @extend %flex-row;
          font-family: $font-body !important;
          align-self: stretch;
          margin-left: 12px;
          margin-top: 12px;

          input {
            &.days-after {
              @extend %input-shared;
              min-width: 320px;
            }
          }

          .DateRangePicker,
          .SingleDatePicker {
            align-self: stretch;
            border-radius: 4px;
            border: $border-lightest;

            .DateRangePickerInput,
            .SingleDatePickerInput {
              border-radius: 8px;
              padding-left: 4px;

              .DateInput {
                width: 112px;
                input {
                  font-family: $font-body;
                  padding: 8px 16px;
                  font-size: 14px;
                  height: 100%;
                }
              }

              .DateInput_input__focused {
                border-color: $darker;
              }
            }

            .DateRangePickerInput_arrow_svg,
            .SingleDatePickerInput_arrow_svg {
              width: 16px;
              height: 16px;
              margin-top: -3px;
            }
          }
          // NOTE: the order of these styles DO matter (this comes directly from the react-dates CSS recommendations)
          // Will edit everything selected including everything between a range of dates
          .CalendarDay__selected_span {
            background: $dark;
            color: white;
            border: $border-darker;
          }

          // Will edit selected date or the endpoints of a range of dates
          .CalendarDay__selected {
            background: $darker;
            border-color: $darkest;
            color: white;
          }

          // Will edit when hovered over. _span style also has this property
          .CalendarDay__selected:hover {
            background: $medium;
            color: white;
          }

          // Will edit when the second date (end date) in a range of dates
          // is not yet selected. Edits the dates between your mouse and said date
          .CalendarDay__hovered_span:hover,
          .CalendarDay__hovered_span {
            background: $dark;
            border-color: $dark;
            color: white;
          }
        }
      }
    }

    .modal-footer {
      position: absolute;
      background: $offwhite;
      bottom: 0;
      left: 0;
      padding: 12px 24px;
      width: 100%;
      .footer-actions {
        @extend %flex-row-middle;
        justify-content: flex-end;

        .footer-action {
          @extend %btn-dark-new;
          margin-left: 12px;
          &.cancel {
            @extend %btn-new;
            background: transparent;
            border: none;
            color: $dark;
          }
        }
      }
    }
  }
}
