@import '../../styles/shared.scss';

.shop-outer-container {
  @extend %flex-col;
  width: 100%;
  font-family: $font-body;

  .shop-body-container {
    @extend %flex-col;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    position: relative;
    transition: opacity 0.2s ease-in-out;

    @include md {
      flex-direction: row;
      padding: 44px 16px;
    }

    @include xl {
      padding: 44px 0;
    }

    &.fetching {
      opacity: 0.4;
    }
  }
  .see-other-lists-btn {
    position: fixed;
    bottom: 0;
    width: calc(100%);
    margin: 0;
    padding: 16px 0 32px;
    z-index: 1;
    border-radius: 0;
    text-transform: uppercase;
    background: $offwhite;
    text-align: center;
    font-weight: bold;

    @include md {
      display: none;
    }
  }
}
