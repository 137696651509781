@import '../../styles/shared.scss';

.lookbook-order-details-outer {
  background-color: $offwhite;
  min-height: 100vh;
  font-family: $font-body-new;

  .lookbook-order-details-inner {
    width: 100%;
    position: relative;

    .lookbook-order-details-no-permission {
      width: 100%;
      display: grid;
      place-content: center;
      margin: 100px 0;
      padding: 0 60px;
    }

    .lookbook-header-outer {
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .cover-image-background {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.9) 100%);
        z-index: 1;
      }

      .lookbook-header-inner {
        height: 400px;
        width: 87%;
        max-width: 1200px;
        margin: 0 auto;
        @extend %flex-col;
        justify-content: flex-end;
        padding-bottom: 20px;
        position: relative;
        border-bottom: $border-lightest;
        padding-top: 75px;
        z-index: 2;

        .lookbook-header-text-container {
          padding-bottom: 50px;
          @extend %flex-col;
          gap: 15px;
          justify-content: flex-end;

          .backlink {
            @extend %label;
            @extend %flex-row;
            gap: 5px;
            font-size: 0.7rem;
            color: white;
            @extend %btn-basic;

            svg {
              margin: 0 4px 0 0;
              transform: translateY(1px);
            }

            a {
              text-decoration: none;
              color: white;
            }
          }
        }

        .lookbook-title {
          @extend %input-inline;
          background-color: transparent;
          font-family: $font-header;
          letter-spacing: 0.7px;
          font-size: 2rem;
          font-weight: 500;
          color: white;
          resize: none;

          @include xs {
            min-width: 200px;
          }

          @include sm {
            min-width: 200px;
          }

          @include md {
            min-width: 400px;
          }

          @include lg {
            min-width: 500px;
            font-size: 2.4rem;
          }

          @include xl {
            min-width: 700px;
          }

          @include xxl {
            min-width: 700px;
            font-size: 3rem;
          }
        }

        #backlink-and-title {
          @extend %flex-col;
          gap: 15px;
          justify-content: flex-end;

          .backlink {
            @extend %label;
            @extend %flex-row;
            gap: 5px;
            font-size: 0.7rem;
            color: black;
            @extend %btn-basic;

            svg {
              margin: 0 4px 0 0;
              transform: translateY(1px);
            }

            a {
              text-decoration: none;
              color: black;
            }
          }
        }
      }
    }

    .order-container {
      width: 87%;
      max-width: 1200px;
      margin: 0 auto;
      margin-top: -50px;
      position: relative;
      background-color: transparent;
      @extend %flex-col;
      gap: 60px;
      padding-bottom: 100px;
      z-index: 2;

      .order-status-container {
        @extend %flex-row;
        gap: 10px;
        width: 100%;
        border-radius: 16px;
        background-color: white;
        padding: 40px 60px;
        justify-content: space-between;

        @include lg-down {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          row-gap: 40px;
        }

        @include md-down {
          display: flex;
          flex-direction: column;
          gap: 30px;
          padding: 24px;
        }

        .order-status-container-header {
          font-weight: 600;
          font-size: 1.1rem;
          color: black;
          margin-bottom: 10px;
        }

        .order-status-container-subheader {
          font-weight: 600;
          font-size: 0.85rem;
          color: black;
          margin-top: 7px;
        }

        .order-status-container-value {
          font-size: 0.8rem;
          color: black;

          + .order-status-container-value {
            margin-top: 5px;
          }

          &.order-update {
            @extend %label;
            font-size: 0.8rem;
            border: $border-lightest;
            border-radius: 5px;
            padding: 5px;
            cursor: default;
            width: max-content;

            svg {
              height: 12px;
              width: 12px;
              margin-right: 5px;
            }
          }

          &.label {
            @extend %label;
            font-size: 0.8rem;
          }

          a {
            @extend %flex-row;
            text-decoration: none;
          }

          .order-status-container-button {
            @extend %btn-basic;
            @extend %label;
            align-self: flex-start;
            font-size: 0.75rem;
            padding: 8px 16px;
            border-radius: 500px;
            background-color: black;
            color: white;
            border: none;
            outline: none;
            text-decoration: none;
          }

          svg {
            height: 10px;
            width: 10px;
            margin-right: 5px;
          }

          &#shopify-order-id {
            @extend %label;
            font-size: 0.8rem;
            margin-top: 10px;
          }

          &#shopify-order-id-value {
            @extend %btn-basic;

            svg {
              margin-left: 5px;
            }
          }

          &#order-name {
            @extend %label;
            font-size: 0.8rem;
          }
        }
      }

      .next-steps {
        padding: 0 60px;

        @include md-down {
          padding: 0;
        }

        .next-steps-title {
          font-family: $font-header;
          font-weight: 600;
          font-size: 36px;
          color: black;
          margin-bottom: 10px;

          @include md-down {
            font-size: 30px;
          }
        }

        .next-steps-subtitle {
          font-size: 1rem;
          margin-bottom: 20px;
        }
      }

      .other-actions {
        display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        padding: 0 60px;

        @include md-down {
          padding: 0;
        }

        @include lg-down {
          grid-template-columns: 1fr;
        }

        > div {
          padding: 60px;
          border-radius: 20px;
          background-color: white;

          @include lg-down {
            padding: 30px;
          }

          .other-action-title {
            font-family: $font-header;
            font-weight: 600;
            font-size: 36px;
            color: $darkest;

            @include md-down {
              font-size: 30px;
            }
          }

          .other-action-body {
            margin: 15px 0;
            font-size: 1rem;
          }

          .other-action-button {
            @extend %btn-basic;
            @extend %label;
            font-size: 0.8rem;
            padding: 16px 24px;
            border-radius: 5px;
            margin-top: 24px;
            display: flex;
            align-items: center;
            outline: none;
            border: none;
            display: grid;
            place-items: center;
            background-color: black;
            color: white;
            text-decoration: none;
            width: max-content;
          }
        }
      }
    }
  }
}
