@import '../../styles/shared.scss';

.consult-settings-modal {
  @extend %flex-col;
  max-width: 600px !important;
  color: $darker;

  .section {
    @extend %flex-col;
    align-items: stretch;
    margin-bottom: 16px;
    .section-header-container {
      margin-bottom: 12px;
      .section-header {
        font-weight: bold;
        font-size: 16px;
      }
      .section-subheader {
        font-size: 12px;
      }
    }

    .input-wrapper {
      position: relative;

      input {
        @extend %input-shared;
        width: 100%;
      }

      .reset-btn {
        @extend %flex-center;
        position: absolute;
        right: 8px;
        bottom: 0;
        height: 100%;
        font-size: 10px;
        text-transform: uppercase;
        cursor: pointer;

        svg {
          margin-left: 4px;
          font-size: 11px;
        }
      }
    }
  }

  .save-btn {
    @extend %btn-dark;
    padding: 6px 24px;
    align-self: flex-start;
  }
}
