@import '../../styles/shared.scss';

.link-results-disclaimer-outer-container {
  margin-bottom: 12px;
  background-color: $offwhite;
  border: $border-lightest;
  border-radius: 10px;
  padding: 12px 16px;

  .link-results-disclaimer-inner-container {
    @extend %flex-row-middle;
    gap: 12px;

    .speaker {
      @extend %flex-center;
      min-width: 30px;
      width: 30px;
      min-height: 30px;
      height: 30px;
      background-color: $darkest;
      border-radius: 50%;

      .icon {
        color: white;
        font-family: $font-header;
        line-height: 24px;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: -6px;
      }
    }

    .body {
      .disclaimer {
        font-size: 12px;
        font-weight: medium;
        color: $darkest;
      }
    }
  }
}
