@import '../../styles/shared.scss';

.mobile-landing-outer-container {
  @extend %flex-col-middle;
  font-family: $font-body;
  background: $darkest;
  padding-top: 80px;

  @include md {
    height: calc(100vh + $navHeight);
    margin-top: calc($navHeight * -1);
    padding-top: 0;
  }

  .mobile-landing-inner-container {
    @extend %flex-row-middle;
    width: 90vw;
    max-width: 1240px;
    margin: 0 auto;

    @include md-down {
      flex-direction: column;
    }

    .left-panel {
      flex: 2;
      .header-section {
        @extend %flex-col;
        max-width: 600px;

        h1 {
          @extend %header-fancy-lg;
          margin: 0;
          color: white;

          @include md-down {
          }
        }
        h2 {
          @extend %header-sm;
          margin: 0;
          margin-top: 8px;
          color: white;
          font-weight: normal;
        }

        .app-store-btn {
          margin-top: 48px;
          img {
            max-width: 180px;
          }
        }

        .coming-soon-btn {
          @extend %btn-outline-new;
          margin-top: 32px;
          align-self: flex-start;
          text-transform: uppercase;
          background: transparent;
          color: white;
          border: $border-white;
        }
      }
    }

    .right-panel {
      flex: 3;

      @include md-down {
        margin: 48px 0;
      }

      img {
        width: 100%;
      }
    }
  }
}
