@import '../../../styles/shared.scss';

.shop-result-outer-container.empty-shop-result-outer-container {
  .shop-result-container {
    .cover {
      @extend %skeleton-box;
      height: 100%;
      width: 100%;
      position: absolute;
    }

    .empty-img {
      border-radius: 4px;
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      .cover {
        @extend %animation-fade;
        @extend %skeleton-box;
        height: 100%;
        width: 100%;
      }
    }

    .product-wrapper {
      .product-data {
        margin-top: 24px;
        .brand,
        .title {
          position: relative;
        }

        .brand {
          height: 12px;
          margin-bottom: 2px;
          width: 44px;
          margin-top: 24px;
        }

        .title {
          height: 13px;
          margin-bottom: 2px;
          width: 96px;
        }
      }
    }
  }
}
