@import '../../styles/shared.scss';

.discover-controls-container {
  @extend %flex-row-spread;
  align-items: flex-end;
  bottom: 100%;
  padding-bottom: 20px;
  position: absolute;
  width: 100%;
  z-index: 4;

  .main {
    max-width: 60%;
    flex: 1;

    .selected-tags {
      @extend %flex-row-middle;
      gap: 4px;
      overflow: auto;

      .selected-tag {
        @extend %btn-basic;
        @extend %flex-row-middle;
        @extend %label;
        color: white;
        font-size: 10px;
        padding: 2px 8px;
        border-radius: 24px;
        border: $border-white;

        svg {
          margin-left: 4px;
        }
      }
    }
  }

  .secondary {
    @extend %flex-row;
    align-items: flex-end;
    gap: 12px;
    margin-left: 8px;

    .toggle-set {
      @extend %flex-col;
      align-items: flex-end;
      gap: 4px;
    }

    .control {
      @extend %flex-row-middle;
      .label {
        @extend %label;
        color: white;
        font-size: 10px;
        margin-right: 4px;
        opacity: 0.5;
        transition: opacity 0.2s ease-in-out;

        &.active {
          opacity: 1;
        }
      }
      .switch {
        width: 20px;
      }

      .select-container {
        width: 160px;

        @include xl {
          width: 180px;
        }
      }
    }
  }
}
