@import '../../styles/shared.scss';
@import '../variables.scss';

.manage-users-menu-fade-container {
  position: fixed;
  width: 100%;
  height: 120vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
  z-index: 2;

  &.active {
    opacity: 1;
    pointer-events: auto;
    overflow: hidden;
  }
}

.manage-users-menu-outer-container {
  @extend %flex-col;
  position: absolute;
  background: $white;
  box-shadow: $shadow-dark;
  list-style: none;
  overflow: hidden;
  overflow: auto;
  top: calc(100% - 4px);
  pointer-events: none;
  min-width: 320px;
  border-radius: 10px;
  display: none;
  z-index: 3;
  transition: transform 0.2s ease-in-out;

  &.active {
    pointer-events: auto;
    display: flex;
  }

  @include md-down {
    position: fixed;
    top: 0;
    right: 0;
    border-radius: 0;
    width: 80vw;
    height: calc(100vh + 240px);
    max-width: 320px;
    padding: 112px 32px 352px;
    display: inherit;
    transition: transform 0.2s ease-in-out;
    transform: translateX(80vw);

    &.active {
      transform: translateX(0);
    }
  }

  @include md {
    right: 112px;
    padding: 24px 24px;
    max-height: 60vh;

    &.scrolled {
      transform: translate(-5px, -5px);
    }
  }

  @include lg {
    right: 94px;

    &.scrolled {
      transform: translate(-5px, -5px);
    }
  }

  @include xl {
    right: 68px;

    &.scrolled {
      transform: translate(-5px, -5px);
    }
  }

  .manages-menu-container {
    @extend %flex-col;
    align-items: stretch;
    width: 100%;
    color: $darkest;
    background: white;

    .search-input {
      @extend %input-inline;
      margin-bottom: 24px;
    }

    .user-container {
      @extend %flex-row-middle-spread;
      transition: transform 0.2s ease-in-out;
      cursor: pointer;

      &:not(:last-child) {
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: $border-offwhite;
      }

      &.disabled {
        pointer-events: none;
      }

      &.selected {
        cursor: default;
      }

      &:not(.selected) {
        &.one-filtered-result {
          border: 2px solid $primary;
          padding: 8px;
          border-radius: 4px;

          .action {
            font-size: 10px !important;
          }
        }

        &:hover {
          opacity: 0.8;

          .action {
            opacity: 0.8;
          }
        }
      }

      .main {
        @extend %flex-row-middle;

        img,
        .initials {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          margin-right: 12px;
          object-fit: cover;
        }

        .initials {
          @extend %flex-center;
          background: $darkest;
          color: $white;
          font-weight: bold;
          font-size: 16px;
          font-family: $font-header;
        }

        .data {
          .name {
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
          }

          .date {
            font-size: 10px;
            color: $dark;
          }
        }
      }

      .actions {
        .notification-count {
          @extend %label-xs;
          @extend %flex-center;
          background: $darkest;
          padding: 1px 7px;
          color: white;
          border-radius: 3px;
          margin-left: 12px;
          letter-spacing: -0.4px;
          text-decoration: none !important;
        }

        .action {
          @extend %btn-basic;
          text-transform: uppercase;
          font-size: 11px;
          color: $medium;
          transition: opacity 0.2s ease-in-out;

          &.selected {
            color: $primary;
            font-size: 13px;
            font-weight: bold;
          }

          &.switching-to-other {
            opacity: 0.2;
          }
        }
      }
    }
  }

  .close-menu-icon {
    @extend %btn-basic;
    position: absolute;
    top: -4px;
    right: -2px;
    color: $darkest;
    font-size: 18px;
    padding: 12px;

    @include md-down {
      font-size: 24px;
      padding: 24px;
    }
  }
}
