@import '../../styles/shared.scss';

.earnings-portal-payment-accounts-outer {
  .earnings-portal-payment-accounts-inner {
    .earnings-portal-section-subtitle {
      a {
        color: $dark;
      }
    }

    .paypal-link-btn-cell {
      @extend %flex-row-center;
      position: relative;
      width: 100%;
      height: 100%;

      .paypal-link-btn {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0.01;
      }
    }
  }
}
