@import '../../styles/shared.scss';

.catalog-group-container {
  display: flex;
  width: 100%;
  font-size: 12px;
  background-color: $white;
  border-radius: 8px;
  padding: 8px;

  .catalog-group-images-container {
    width: 100px;
    height: 100px;
    margin-right: 24px;
    border-radius: 10px;
    background-color: $lightest;

    img {
      border-radius: 10px;
      transition: transform 0.25s ease-in-out;
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1;
    }

    &.skeleton {
      @extend %animation-skeleton-fade;
      background: $prettymuchwhite;
      border-radius: 10px;
    }
  }

  .catalog-group-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .catalog-group-meta {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .group-type {
      margin-bottom: 8px;
      color: $darkest;
      font-weight: 500;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      background-color: $lightest;
      width: min-content;
      text-wrap: nowrap;
      padding: 0 8px;

      img {
        margin-right: 4px;
        width: 15px;
        height: 15px;
      }

      &.primary {
        background-color: $primaryLightest;
      }

      &.skeleton {
        @extend %animation-skeleton-fade;
        width: 100px;
        background: $prettymuchwhite;
      }
    }

    .group-name {
      font-family: $font-header;
      font-size: 20px;
      margin-bottom: 4px;

      &.skeleton {
        @extend %animation-skeleton-fade;
        width: 200px;
        height: 30px;
        border-radius: 16px;
        background: $prettymuchwhite;
      }
    }

    .group-data {
      color: $light;

      .bullet {
        margin: 0 4px;
      }

      .clickable-group-data {
        cursor: pointer;
        text-decoration: underline;
        transition: color 0.2s ease-in-out;

        &:hover:not(.disabled) {
          color: $darkest;
        }

        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }

  .catalog-group-actions {
    .action-text {
      text-wrap: nowrap;
      text-align: right;
    }

    &.empty {
      color: $error;

      input::placeholder {
        color: $error;
        opacity: 0.5;
      }
    }
  }
}
