@import '../../styles/shared.scss';

.talent-results-outer-container {
  &.cannot-view {
    filter: blur(2px);
    pointer-events: none;
  }

  .empty-results {
    @extend %flex-center-vertical;
    width: 100%;
    text-align: center;
    height: 120px;
    background: $white;
    border: $border-lightest;
    border-radius: 12px;

    .explanation {
      color: $light;
      font-size: 12px;
      letter-spacing: 0.5px;
      margin: 0 24px;
      max-width: 440px;
    }

    .action-btn {
      @extend %btn-inverted-new;
      margin-top: 12px;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  .show-more-btn {
    @extend %flex-center;
    @extend %btn-basic;
    margin-top: 24px;
    font-size: 14px;
    color: $medium;
    text-transform: uppercase;
  }
}
