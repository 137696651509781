@import '../../styles/shared.scss';

.contract-header-outer-container {
  width: 100%;

  .status {
    font-size: 16px;
    text-transform: uppercase;
    color: $medium;

    @include md-down {
      font-size: 13px;
    }
  }

  textarea {
    @extend %input-inline;
    font-weight: bold;
    margin-bottom: 0px;
    background-color: transparent;
    font-family: $font-header;
    width: 100%;
    resize: none;

    @include md-down {
      font-size: 20px;
    }
  }
  .title {
    font-size: 24px;
    margin: 0;
    color: $darker;
    font-family: $font-header;
    margin-bottom: 4px;
    letter-spacing: -0.27px;
    line-height: 1.15;

    @include md-down {
      font-size: 20px;
    }
  }

  .info-row {
    @extend %flex-row-middle;
    text-transform: uppercase;
    color: $dark;
    font-size: 12px;

    @include md-down {
      font-size: 11px;
    }

    .pictures {
      @extend %flex-row-middle;
      .image {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        object-fit: cover;
        background: $white;

        @include md-down {
          width: 14px;
          height: 14px;
        }

        &:last-child {
          margin-left: -8px;
        }

        &.empty {
          @extend %flex-center;
          background: $dark;
          color: white;
          font-size: 6px;
          font-weight: bold;
          text-transform: uppercase;
        }

        &.brand {
          object-fit: contain;
          background: $offwhite;
        }
      }
    }

    .bullet {
      margin: 0 4px;
      opacity: 0.4;
    }
    .date {
      text-transform: uppercase;
    }
  }
}
