@import '../../styles/shared.scss';

.chat-potential-partner-outer-container {
  width: 100%;
  height: 100%;

  .shop-preview {
    @extend %flex-center-vertical;
    width: 100%;
    height: 100%;

    @include md-down {
      padding-top: 64px;
      justify-content: flex-start;
    }

    &.is-potential-brand {
      .shop-image-container {
        @extend %flex-center;
        border: $border-offwhite;
        border-radius: 10px;
        margin-bottom: 32px;
        overflow: hidden;

        img.shop-image {
          width: 100px;
          height: 100px;
          object-fit: contain;
          border-radius: 0;
        }
      }
    }

    .shop-image-container {
      margin-bottom: 24px;
      width: 120px;
      height: 120px;

      img.shop-image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .metadata {
      @extend %flex-center-vertical;
      max-width: 320px;
      width: 90%;
      text-align: center;
      padding: 0px 12px;
      border: $border-offwhite;
      border-radius: 6px;

      .name {
        font-size: 17px;
        font-weight: bold;
        letter-spacing: -0.45px;
        color: $darker;
        text-transform: uppercase;
        margin-bottom: 8px;
        transform: translateY(-10px);
        background: white;
        padding: 0 12px;
      }
      .domain {
        transform: translateY(-13px);
        font-weight: bold;
        color: $link-blue;
      }
      .description {
        font-size: 11px;
        color: $dark;
        transform: translateY(-6px);
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
      }
      .social-links {
        transform: translateY(5px);
        height: 14px;
        padding: 0 12px;
        background: white;
        color: $dark;

        &.empty {
          padding: 0;
        }

        .social-link {
          font-size: 14px;
          color: $medium;
          margin: 0 3px;

          img {
            height: 14px;
            opacity: 0.8;
            margin-bottom: -2px;
          }
        }
      }
    }

    .actions {
      margin-top: 32px;
      .action {
        @extend %btn-basic;
        padding: 12px 14px;
        text-align: center;
        background: $prettymuchwhite;
        margin-top: 12px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
        color: $dark;
        border: $border-offwhite;

        &.disabled {
          cursor: default;
          color: $lighter;
          opacity: 0.7;
        }
      }
    }
  }
}
