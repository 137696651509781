@import '../../../styles/shared.scss';

.opportunity-planner-controls {
  .main-controls {
    @extend %flex-row-middle-spread;
    gap: 24px;
    .actions-container {
      @extend %flex-row;
      gap: 12px;
      flex: 1;

      .input-container {
        @extend %flex-row;
        flex: 1;
        max-width: 640px;

        input {
          @extend %input-shared;
          flex: 1;
          font-size: 14px;
        }
      }

      .select-container {
        min-width: 240px;
        font-size: 14px;

        .shopmy-select__placeholder {
          color: $light !important;
        }
      }

      .actions {
      }
    }
    .budget-container {
      min-width: 240px;
      text-align: right;

      .budget {
        font-size: 36px;
        font-weight: bold;
        font-family: $font-header;
      }

      .budget-label {
        @extend %label;
        font-size: 12px;
        font-weight: bold;
        color: $medium;
      }
    }
  }

  .filters-container {
    margin-top: 4px;
    .filters {
      @extend %flex-row;
      gap: 4px;

      .filter {
        @extend %flex-row-middle;
        @extend %btn-basic;
        background-color: $offwhite;
        border-radius: 24px;
        padding: 4px 16px;
        gap: 4px;
        cursor: pointer;

        .label {
          font-size: 12px;
          color: $darkest;
          font-weight: bold;
        }

        .close {
          svg {
            font-size: 11px;
          }
        }
      }
    }
  }
}
