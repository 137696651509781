@import '../../../styles/shared.scss';
@import '../../variables.scss';

.more-options-menu-fade-container {
  position: fixed;
  width: 100%;
  height: 120vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
  z-index: 2;

  &.active {
    opacity: 1;
    pointer-events: auto;
    overflow: hidden;
  }
}

.more-options-menu-outer-container {
  @extend %flex-col;
  position: absolute;
  background: $white;
  box-shadow: $shadow-dark;
  list-style: none;
  overflow: hidden;
  overflow: auto;
  top: calc(100% - 12px);
  right: 0;
  pointer-events: none;
  min-width: 240px;
  border-radius: 10px;
  display: none;
  z-index: 3;

  &.active {
    pointer-events: auto;
    display: flex;
  }

  @include md-down {
    position: fixed;
    top: 0;
    right: 0;
    border-radius: 0;
    width: 80vw;
    height: calc(100vh + 240px);
    max-width: 320px;
    padding: 112px 32px 352px;
    display: inherit;
    transition: transform 0.2s ease-in-out;
    transform: translateX(80vw);

    &.active {
      transform: translateX(0);
    }
  }

  @include md {
    right: 72px;
    padding: 24px 24px;

    &.scrolled {
      top: calc(100% - 6px);
    }
  }

  @include lg {
    right: 54px;
  }

  @include xl {
    right: 12px;
  }

  .more-options-menu-container {
    @extend %flex-col;
    align-items: stretch;
    width: 100%;
    background: white;

    .section {
      @extend %flex-col;
      width: 100%;

      &.main {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: $border-dark;

        @include md {
          padding-bottom: 16px;
          margin-bottom: 16px;
        }

        .subpage-container {
          &:not(:last-of-type) {
            margin-bottom: 12px;
          }
        }

        .menu-item-container {
          &:last-child {
            .menu-item {
              .menu-item-text {
                padding-bottom: 0;
              }
            }
          }

          .menu-item {
            &.subpage {
              margin-left: 8px;

              .menu-item-text {
                font-family: $font-body;
                font-size: 20px;
                padding-bottom: 2px;
                font-weight: 500;

                @include md {
                  font-size: 14px;
                }
              }
            }
            .menu-item-text {
              font-size: 28px;
              line-height: 32px;
              padding-bottom: 8px;
              font-weight: bold;

              @include md {
                font-size: 20px;
                line-height: 24px;
              }
            }
          }
        }
      }

      &.secondary {
        padding-bottom: 40px;
        margin-bottom: 40px;

        @include md {
          padding-bottom: 16px;
          margin-bottom: 16px;
        }

        .menu-item-container {
          &:last-child {
            .menu-item {
              .menu-item-text {
                padding-bottom: 0;
              }
            }
          }

          .menu-item {
            .menu-item-text {
              padding-bottom: 8px;
              @extend %label-sm;

              @include md {
                letter-spacing: 0;
                text-transform: none;
                font-weight: normal;
              }
            }
          }
        }
      }

      &.actions {
        .menu-item-container {
          .menu-item {
            @extend %btn-outline-new;
            padding: 18px 0;
            margin-bottom: 8px;

            @include md {
              padding: 10px 0;
            }

            .menu-item-text {
              @extend %label-sm;
              color: $darkest;
            }
          }
        }
      }

      .menu-item-container {
        @extend %flex-row;
        cursor: pointer;

        &:hover {
          .label {
            text-decoration: underline;
          }
          a {
            color: $darkest;
          }
        }

        a {
          text-decoration: none;
        }

        .menu-item {
          @extend %flex-col;
          text-decoration: none;
          width: 100%;

          .menu-item-text {
            @extend %flex-row;
            position: relative;
            cursor: pointer;
            color: $darkest;

            .new-badge,
            .count-badge {
              @extend %label-xs;
              @extend %flex-center;
              background: $darkest;
              padding: 2px 6px;
              color: white;
              border-radius: 3px;
              margin-left: 12px;
              text-decoration: none !important;
              transition: all 0.2s ease-in-out;
            }

            .count-badge {
              letter-spacing: -0.05em;
              font-size: 9px;
              font-weight: bold;
              background: $error;
              border-radius: 50%;
              height: 16px;
              width: 16px;
              transform: translateX(-12px) translateY(-4px);
              text-decoration: none;

              .count {
                margin-left: -0.05em;
                text-decoration: none !important;
              }
            }
          }

          &:first-child {
            .menu-item {
              padding: 16px 16px 10px;
            }
          }

          &:last-child {
            .menu-item {
              padding: 10px 16px 16px;
            }
          }
        }
      }
    }

    .close-menu-icon {
      @extend %btn-basic;
      position: absolute;
      top: 0;
      right: 0;
      color: $darkest;
      font-size: 18px;
      padding: 12px;

      @include md-down {
        font-size: 24px;
        padding: 24px;
      }
    }
  }
}
