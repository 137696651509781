@import '../../styles/shared.scss';

.basic-outer-container {
  @extend %flex-col;
  position: relative;
  padding: 24px 0 48px;
  font-family: $font-body-new;

  .basic-header-label {
    font-weight: bold;
  }

  h1 {
    font-weight: normal;
    margin: 0;
  }

  @include md {
    padding: 64px 0 48px;
  }

  .narrow {
    width: calc(100% - 64px);
    margin: 64px auto 0;

    @include sm {
      width: 80%;
      max-width: 640px;
    }
  }

  .medium {
    width: calc(100% - 64px);
    margin: 64px auto 0;

    @include sm {
      width: 90%;
      max-width: 880px;
    }
  }

  .basic-header-container {
    border: $border-lightest;
    padding: 24px 16px 12px;
    position: relative;
    border-radius: 4px;

    .basic-header-title-badge-container {
      @extend %flex-center;
      position: absolute;
      width: 100%;
      top: -12px;
      left: 0;

      .basic-header-title-badge {
        background: white;
        padding: 0 12px;
        font-size: 24px;
        margin: 0;
        color: $darker;
        font-family: $font-header;
        font-weight: bold;
        letter-spacing: 0.6px;
      }
    }

    .basic-header-body {
      color: $darkest;
      font-size: 16px;
      margin: 0;
      font-weight: normal;

      &.simple {
        text-align: center;
        padding: 24px 0;
      }

      .email {
        font-weight: bold;
      }

      a {
        color: $darkest;
        font-weight: bold;
      }
    }
  }

  .basic-header-label {
    align-self: center;
    border: $border-lightest;
    padding: 18px 52px;
    position: relative;
    border-radius: 4px;
    font-family: $font-header;
    font-size: 24px;
    color: $darker;
    letter-spacing: 0.6px;
  }

  .basic-body-container {
    .basic-body-section {
      color: $darker;
      padding-bottom: 64px; // required because of narrow class

      &:not(:first-child) {
        padding-top: 64px;
      }

      &.dark {
        background: $offwhite;
      }

      .basic-body-header {
        text-align: center;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0.6px;
      }

      .basic-body-desc {
        @extend %flex-col;
        margin-top: 44px;

        .basic-body-desc-header {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 8px;
        }

        .basic-body-desc-body {
          font-size: 18px;
          line-height: 1.3;
        }

        .link-to-directory {
          @extend %btn-outline;
          align-self: center;
          margin: 48px auto 0;
          padding: 12px 48px;
          background: white;
        }
      }
    }
  }
}
