@import '../../../styles/shared.scss';
.listing-header-container {
  @extend %flex-row;
  gap: 60px;

  @include md-down {
    flex-direction: column;
    gap: 40px;
  }

  .listing-image-container {
    width: 220px;
    min-width: 220px;
    height: 300px;
    min-height: 300px;
    background-color: $darkest;
    border-radius: 20px;
    position: relative;

    @include md-down {
      width: calc(100% + 48px);
      min-width: calc(100% + 48px);
      margin: -24px 0 0 -24px;
      height: unset;
      aspect-ratio: 16/9;
      min-height: 300px;
      border-radius: 0;
      background-color: $offwhite;
    }

    &.editing {
      &:hover {
        cursor: pointer;

        &.image-set {
          .cover-image {
            opacity: 0.3;
          }
          .upload-image-overlay-description {
            opacity: 1;
          }
        }

        &:not(.image-set) {
          .cover-image {
            opacity: 0.3;
          }

          .upload-image-overlay-description .description-text {
            opacity: 1;
          }
        }
      }
    }

    .cover-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
      transition: opacity 0.3s;

      @include md-down {
        border-radius: 0;
      }

      &.fallback {
        opacity: 0.3;
      }
    }

    &.image-set {
      .upload-image-overlay-description {
        opacity: 0;
      }
    }

    .upload-image-overlay-description {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 0.3s;

      .description-text {
        color: $white;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        transition: opacity 0.3s;
      }
    }
  }

  .listing-data-container {
    @extend %flex-col-spread;
    flex: 1;
    gap: 12px;

    @include md-down {
      gap: 24px;
    }

    .listing-main {
      @extend %flex-col;
      gap: 12px;

      &.editing {
        .type-selection-container {
          order: 2;
        }
      }

      .section {
        .section-label {
          font-size: 18px;
          font-weight: 700;
          color: $darkest;
        }
      }

      .header-data-container {
        @extend %flex-col;
        gap: 10px;

        .header {
          font-family: $font-header;
          font-size: 56px;
          line-height: 60px;
          font-weight: 600;
          color: $darkest;
          margin: 0;

          @include xl-down {
            font-size: 48px;
            line-height: 52px;
          }

          @include md-down {
            font-size: 40px;
            line-height: 44px;
          }
        }

        .header-input-container textarea.header {
          font-family: $font-header;
        }

        .header-input-container {
          textarea {
            @extend %input-shared;
            width: 100%;
            font-size: 40px;
            line-height: 44px;
            resize: none;

            &::placeholder {
              color: $lighter;
            }
          }
        }
      }

      .type-selection-container {
        @extend %flex-col;
        gap: 10px;

        .type-options {
          @extend %flex-row;
          gap: 12px;

          .type-option {
            @extend %label;

            &.public {
              font-size: 12px;
            }

            &.editing {
              @extend %btn-basic;
              border-radius: 24px;
              padding: 8px 16px;
              border: $border-lighter;
              color: $lighter;
              font-size: 12px;

              &.active {
                background-color: $darkest;
                color: $white;
                pointer-events: none;
              }
            }
          }
        }

        .type-description {
          font-size: 12px;
          color: $medium;
        }
      }
    }

    .listing-secondary {
      @extend %flex-row-spread;
      align-items: flex-end;
      gap: 12px;

      @include md-down {
        gap: 24px;
        flex-direction: column;
        align-items: flex-start;
      }

      .location-and-tracking {
        @extend %flex-col;
        gap: 8px;
        flex: 1;

        .container {
          @extend %flex-row-middle;
          gap: 12px;

          &.empty.public {
            .icon img {
              opacity: 0.3;
            }
            .value {
              color: $lighter;
            }
          }

          .icon {
            @extend %flex-center;
            margin-top: -1px;
            img {
              width: 18px;
              height: 18px;
              object-fit: contain;
            }
          }

          .value {
            font-size: 16px;
            color: $darkest;
            flex: 1;

            &.empty {
              color: $lighter;
            }

            &.clickable {
              @extend %btn-basic;
              flex: inherit;

              &.required.empty {
                @extend %label;
                @extend %flex-row-middle;
                gap: 8px;
                font-size: 12px;
                padding: 8px 12px;
                background-color: $primary;
                color: white;

                svg {
                  font-size: 10px;
                  color: white;
                }

                img {
                  width: 12px;
                  height: 12px;
                  object-fit: contain;
                  filter: invert(1);
                }
              }
            }
          }

          textarea {
            @extend %input-inline;
            resize: none;
          }
        }
      }

      .payment-overview {
        @extend %flex-row;
        gap: 36px;

        .overview-stat {
          @extend %flex-col;

          &.is-single {
            .value {
              font-size: 30px;
              line-height: 36px;
            }
          }

          .value {
            font-size: 24px;
            line-height: 30px;
            font-weight: 700;
            font-family: $font-header;
            color: $darkest;
          }
          .stat {
            @extend %label;
            font-size: 12px;
            color: $dark;
            font-weight: bold;
          }
        }
      }
    }
  }
}
