@import '../../../styles/shared.scss';

.update-lookbook-order-modal-outer {
  .update-lookbook-order-modal-inner {
    .update-lookbook-order-modal-content {
      padding: 15px;

      .update-lookbook-order-modal-title {
        font-family: $font-header;
        font-size: 2rem;
        font-weight: 500;

        @include lg-down {
          font-size: 1.7rem;
        }

        @include md-down {
          font-size: 1.5rem;
        }

        @include sm-down {
          font-size: 1.3rem;
        }

        @include xs-down {
          font-size: 1.1rem;
        }
      }

      .update-lookbook-order-modal-subtitle {
        font-size: 0.7rem;
        color: $medium;
      }

      .update-lookbook-order-modal-form {
        @extend %flex-col;
        gap: 10px;
        margin: 20px 0;

        .modal-form-input-container {
          width: 100%;
          .input-container-label {
            font-size: 0.7rem;
            color: $light;
            margin-bottom: 5px;
          }

          .select {
            font-size: 0.7rem;
            color: $dark;
            // width: 200px;
            font-family: $font-body-new;
            box-sizing: border-box;
          }

          input {
            border: $border-lightest;
            outline: none;
            font-family: $font-body-new;
            font-size: 0.7rem;
            border-radius: 5px;
            padding: 11px 15px;
            min-width: 100%;
            box-sizing: border-box;
          }
        }
      }

      .update-lookbook-order-modal-actions {
        @extend %flex-row;
        justify-content: flex-end;
        gap: 20px;

        .update-lookbook-order-modal-action {
          @extend %label;
          @extend %btn-basic;
          font-size: 0.7rem;
          padding: 10px 15px;
          border-radius: 5px;
          outline: none;
          background-color: black;
          color: white;

          &.save {
            background-color: $primary;
          }
        }
      }
    }
  }
}
