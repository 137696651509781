$primaryButtonColor: #4ad7c0;
$primaryButtonHoverColor: #6cf9e2;
$primaryDisabledButtonColor: #baeae1;
$disabledButtonOpacity: 0.5;
$primaryButtonFontColor: #ffffff;
$darkGrey: #181f2f;
$grey: #a1a4aa;
$lightGrey: #d4d5d8;
$defaultCollectionBackground: #fe92a2;
$lightPink: #ffb6c2;

//heights and widths
$desktopNavHeight: 60px;
$mobileNavHeight: 64px;
$desktopSidebarWidth: 0px;

//screen sizes
$xs: 0px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$DMSerifText: 'DM Serif Text', serif;
$SourceSansPro: 'Source Sans Pro', sans-serif;
$NotoSans: 'Noto Sans', sans-serif;
