@import '../../../../styles/shared.scss';

.social-sharing-pin-square-outer-container {
  .title-container {
    margin-top: 12px;
  }

  .pin-image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;

    .pin-image-container {
      background: white;
      border-radius: 6px;
      overflow: hidden;

      img {
        margin: auto;
        aspect-ratio: 1;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
