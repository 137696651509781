@import '../../styles/shared.scss';

.artist-modal-mentions-container {
  padding: 12px 24px;
  position: relative;
  min-height: 100%;

  .artist-modal-mentions {
    @extend %flex-col;
    gap: 12px;
    transition: opacity 0.2s ease-in-out;

    &.fetching {
      opacity: 0.2;
    }
  }

  .header-actions {
    @extend %flex-row-spread;
    padding-bottom: 12px;
    .main {
      .subtabs {
        @extend %flex-row-middle;
        align-items: flex-end;
        overflow-x: auto;
        gap: 12px;
        background: white;
        padding: 0;

        .subtab {
          @extend %label;
          height: 100%;
          border-radius: 0;
          font-size: 10px;
          color: $dark;
          transition: all 0.2s ease-in-out;
          font-weight: bold;

          &.selected {
            color: $darkest;

            .display {
              border: $border-darkest;
            }
          }

          .display {
            @extend %flex-row-middle;
            padding: 4px 8px;
            border-radius: 4px;
            margin-bottom: 8px;
            cursor: pointer;
            border: $border-offwhite;
            transition: all 0.2s ease-in-out;

            &:hover {
              border: $border-light;
            }
          }
        }
      }
    }

    .secondary {
      .analytics-toggle {
        @extend %flex-row-middle;
        justify-content: flex-end;
        font-size: 12px;
        font-size: 12px;
        cursor: pointer;
        color: $dark;
        gap: 4px;

        .label {
          @extend %flex-row-middle;

          svg {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
