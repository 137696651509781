@import '../../styles/shared.scss';

.artist-modal-panel-outer {
  @extend %flex-center;
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  border: $border-lightest;

  .artist-modal-panel-inner {
    color: $darkest;
    font-size: 12px;
  }
}
