@import '../../styles/shared.scss';

.artist-modal-pin-mention-details {
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;

  .header-container {
    @extend %flex-row-middle-spread;
    background: $white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 24px 24px 12px;
    z-index: 5;
    height: 54px;

    .data {
      @extend %btn-basic;
      @extend %flex-row-middle;
      .back-to-mentions-icn {
        @extend %flex-center;
        font-size: 14px;
        margin-right: 12px;
        margin-bottom: -1px;
      }
      .title-container {
        .title {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .actions {
      @extend %flex-col;
      align-items: flex-end;

      .action {
        font-size: 14px;
        color: $dark;
        svg {
          margin-left: 8px;
          font-size: 11px;
        }
      }

      .social-links {
        @extend %flex-row-middle;
        .icon {
          margin-right: 4px;
          img {
            width: 12px;
            height: 12px;
            object-fit: contain;
          }
        }
        .text {
          font-size: 10px;
          @extend %label;
        }
      }
    }
  }

  .collection {
    padding: 24px 24px 180px;
    margin-top: 54px; // To account for header
    background: white;
    overflow: scroll;
    height: 100%;

    .pins {
      @extend %flex-row;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 24px;

      .pin-outer-container {
        @extend %flex-center;

        .pin-inner-container {
          @extend %flex-col-center;
          width: 140px;

          &.from-brand {
            .image-container {
              border: 2px solid $darkest;
            }
            .title {
              color: $darkest;
              font-weight: bold;
            }
          }

          .image-container {
            width: 100px;
            height: 100px;
            border-radius: 16px;
            border: $border-lightest;
            padding: 12px;

            img {
              width: 100%;
              aspect-ratio: 1;
              object-fit: contain;
            }
          }

          .title {
            font-size: 12px;
            text-align: center;
            color: $medium;
            margin-top: 12px;
          }
        }
      }
    }
  }
}
