@import '../../styles/shared.scss';

.shop-result-outer-container {
  @extend %flex-col;
  box-sizing: border-box;
  width: 100%;
  padding: 32px 28px 0;
  min-height: 240px;

  @include sm {
    width: 33.3%;
    padding: 8px;
    padding-top: 8px;
  }

  .shop-result-container {
    @extend %flex-col-center;
    height: 100%;
    justify-content: space-between;
    padding: 24px;
    background: white;
    position: relative;
    text-decoration: none;

    @include sm {
      border-radius: 0;
      padding: 8px 12px 16px;
    }

    .title {
      text-decoration: underline;
    }

    @include md {
      &:not(:hover) {
        .brand,
        .title {
          text-decoration: none;
        }
      }
    }

    .product-wrapper {
      @extend %flex-col-center;
      width: 100%;

      .product-data {
        width: 100%;
        padding-left: 4px;

        .brand {
          color: $darkest;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 14px;
          margin: 0;
          letter-spacing: 0.3px;

          @include sm {
            font-size: 13px;
            line-height: 14px;
          }
        }

        .title {
          font-size: 15px;
          color: $darker;
          font-weight: normal;
          line-height: 16px;
          margin: 0;
          margin-top: 1px;

          @include sm {
            font-size: 13px;
          }
        }

        .price {
          font-size: 11px;
          text-transform: uppercase;
          color: $light;
          font-weight: bold;
          margin-top: 4px;
        }

        .artist-count {
          font-size: 11px;
          color: $medium;
          font-style: italic;
          letter-spacing: 0.2px;
          margin: 8px 0 18px;
          font-weight: normal;
          text-align: center;
        }
      }
    }

    &.selected {
      border: $border-dark;
    }

    &:hover {
      img {
        transform: translateY(-2px);
      }
    }

    img,
    .empty-img {
      @extend %animation-fade;
      animation-duration: 400ms;
      transition: transform 0.2s ease-in-out;
      width: 200px;
      height: 200px;
      max-width: 100%;
      margin: 22px 0 6px;
      object-fit: contain;

      @include sm {
        width: 180px;
        height: 180px;
      }
    }

    .rank-badge {
      @extend %flex-col;
      align-items: flex-end;
      padding: 0 8px;
      font-family: $font-header;
      position: absolute;
      top: 18px;
      right: 18px;

      .ranking {
        @extend %flex-row;
        font-size: 32px;
        line-height: 32px;
        font-weight: bold;
        color: $darker;

        .num {
          padding-top: 4px;
          font-size: 24px;
        }
      }

      .label {
        font-size: 12px;
        color: $dark;
      }

      @include md {
        .ranking {
          font-size: 26px;
          line-height: 28px;
          left: auto;

          .num {
            padding-top: 2px;
          }
        }
        .label {
          font-size: 10px;
        }
      }
    }

    .action-btns-container {
      @extend %flex-col;
      margin-top: 16px;
      width: 100%;
      position: relative;

      .btn {
        @extend %flex-center;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 2px;
        text-align: center;
        color: $dark;
        border: 1px solid $lightest;
        padding: 8px 12px;
        font-size: 14px;
        letter-spacing: 0.7px;
        cursor: pointer;
        background: $offwhite;

        &.learn-more {
          background: white;
          border-bottom: none;
          text-decoration: none;

          &:hover {
            background: $dark;
            color: white;
          }
        }

        &.buy-box {
          &:hover {
            background: $dark;
            color: white;
          }
        }

        &.disabled {
          cursor: default;
          background: $offwhite;
          color: $medium;
          cursor: default;
          &:hover {
            background: $offwhite;
            color: $medium;
          }
        }

        @include sm {
          font-size: 13px;
        }
      }

      .offers-box {
        animation-duration: 240ms;
        animation-name: slideUpOffer;
        background: white;
        box-shadow: $shadow-dark;
        z-index: 13;
        padding: 12px 0 64px;
        color: $darker;
        cursor: default;
        width: 100%;
        max-height: 75vh;
        overflow: auto;
        position: fixed;
        bottom: 0;
        left: 0;

        @keyframes slideUpOffer {
          0% {
            transform: translateY(160px);
          }
          100% {
            transform: translateY(-0);
          }
        }

        @include sm {
          width: 100%;
          top: 100%;
          position: absolute;
          top: 100%;
          left: 0;
          bottom: auto;
          border-radius: 4px;
          padding: 12px 0;
          animation-name: none;
        }

        .header {
          @extend %flex-row-middle-spread;
          padding: 0 16px;

          @include sm {
            padding: 0 12px;
          }

          .label {
            font-weight: bold;
            font-size: 22px;

            @include sm {
              font-size: 16px;
            }
          }
          .cancel-icn {
            cursor: pointer;
            font-size: 20px;
          }
        }

        .offers {
          margin-top: 12px;

          .offer {
            @extend %flex-row-middle-spread;
            text-decoration: none;
            color: $darker;
            padding: 16px 16px;

            @include sm {
              padding: 10px 12px;
            }

            &:not(:last-child) {
              border-bottom: $border-offwhite;
            }

            .retailer {
              font-weight: normal;
              font-size: 20px;
              line-height: 22px;

              @include sm {
                font-size: 16px;
                line-height: 18px;
              }
            }
            .shop-btn {
              background: $lightest;
              color: $dark;
              border-radius: 4px;
              font-weight: bold;
              padding: 8px 14px;
              font-size: 14px;

              @include sm {
                font-size: 12px;
                padding: 4px 10px;
              }

              &:hover {
                opacity: 0.6;
              }
            }

            .price {
              background: $lightest;
              color: $dark;
              border-radius: 4px;
              font-weight: bold;
              padding: 4px 6px;
              font-size: 18px;

              @include sm {
                font-size: 14px;
              }

              &:hover {
                opacity: 0.6;
              }
            }
          }
        }

        .offer-disclaimer {
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          padding: 6px 4px;
          margin: 8px 0 -4px;
          background: $tertiary;
        }

        .empty-msg {
          @extend %flex-center;
          text-align: center;
          padding: 24px 0;
        }
      }
    }
  }

  .offers-box-fade {
    @extend %animation-fade;
    animation-duration: 200ms;
    position: fixed;
    width: 100%;
    height: 100vh;
    min-height: 48px;
    z-index: 12;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
  }
}
