@import '../../../styles/shared.scss';

.multi-choice-container {
  .option-container {
    @extend %flex-row-middle;
    margin-bottom: 12px;

    .option-checkbox {
      @extend %flex-center;
      @extend %clickable-subtle;
      width: 14px;
      height: 14px;
      margin-right: 8px;
      border: $border-medium;
      border-radius: 4px;

      svg {
        font-size: 11px;
        color: $white;
      }

      &:hover {
        svg {
          color: $lighter;
        }
      }

      &.checked {
        border: $border-dark;
        svg {
          font-size: 10px;
          color: $darker;
        }
      }
    }
    input.option {
      @extend %input-inline;
      max-width: 480px;
      width: 90%;
      font-size: 14px;
    }
    .option {
      font-size: 14px;
    }

    .remove-btn {
      @extend %clickable-subtle;
      position: absolute;
      color: $lighter;
      right: 0;
    }
  }

  .answer {
    margin-bottom: 12px;
  }

  .add-new-option-btn,
  .add-new-option-open-btn {
    @extend %flex-row-middle;
    @extend %clickable-subtle;
    flex-wrap: none;
    font-size: 12px;
    color: $medium;
    text-transform: uppercase;
    white-space: nowrap;

    svg {
      margin: -1px 0 0 4px;
      color: $light;
    }
  }
}
