@import '../../styles/shared.scss';

.shop-header-outer-container {
  background: linear-gradient($darkest 50%, #272727);
  margin-top: -80px;
  padding-top: 80px; // For pull downs

  .shop-header-inner-container {
    @extend %flex-col;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 48px 16px 36px;

    @include xl {
      padding: 48px 0 36px;
    }

    .header-title {
      @extend %flex-row-middle;
      margin: 0;
      color: white;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -1.5px;
      text-transform: uppercase;
      font-family: $font-header-bold;
      letter-spacing: 0.7px;
      font-weight: 700;

      @include sm {
        font-size: 36px;
        line-height: 44px;
      }

      .mobile {
        @include md {
          display: none;
        }
      }

      .desktop {
        display: none;

        @include md {
          display: inherit;
        }
      }

      .rot-outer {
        flex: 1;
        position: relative;
        padding-left: 6px;
        height: 28px;
        width: 10px;
        overflow: hidden;

        @include sm {
          height: 44px;
          padding-left: 8px;
        }

        &.transitioning {
          @extend %flex-row;
          .rot-inner {
            &:first-child {
              transform: translateY(-110%);
            }
            &:last-child {
              transform: translateY(0%);
            }
          }
        }

        &.disable-animation {
          .rot-inner {
            transition: none;
          }
        }

        .rot-inner {
          position: absolute;
          top: 0;
          transition: transform 0.5s ease-in-out;
          height: calc(100%);

          &:first-child {
            transform: translateY(0%);
          }

          &:last-child {
            transform: translateY(110%);
          }
        }
      }
    }

    h2 {
      margin: 0;
      font-weight: normal;
      color: white;
      font-size: 14px;
      letter-spacing: 0.1px;
      margin-top: 8px;

      @include md {
        margin-top: 0;
      }
    }
  }
}
