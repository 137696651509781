@import '../../styles/shared.scss';

.unpaid-invoices-modal-outer-container {
  z-index: 100000;
  .unpaid-invoices-modal-content {
    .invoices-sections {
      @extend %flex-col;
      gap: 24px;

      .invoices-section {
        .section-header {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 12px;
          color: $darkest;
        }
        .invoices-table {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          border-radius: 10px;
          overflow: hidden;

          .cell {
            background-color: white;
            padding: 12px 16px;
            font-size: 12px;

            &.warning {
              background-color: #f8f49b;
            }

            &.alert {
              background-color: #fd9491;
            }

            &.header {
              background-color: $darkest;
              color: $white;
              padding: 8px 16px;
              font-weight: bold;
            }

            a {
              @extend %link;

              svg {
                margin-left: 4px;
                font-size: 10px;
              }
            }
          }
        }

        .show-more-container {
          @extend %flex-center;
          gap: 12px;
          width: 100%;
          margin-top: 12px;
          .show-btn {
            @extend %btn-basic;
            font-size: 12px;
            color: $dark;
          }
        }
      }
    }

    .dismiss-btn {
      @extend %btn-offwhite-new;
      position: absolute;
      font-size: 10px;
      top: 12px;
      right: 36px;
    }
  }
}
