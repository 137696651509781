@import '../../styles/shared.scss';

.product-page-outer-container {
  @extend %flex-col;
  width: 100%;
  font-family: $font-body;

  .product-page-inner-container {
    @extend %flex-col;
    width: 100%;
    max-width: 720px;
    padding: 48px 24px;
    position: relative;
    transition: opacity 0.2s ease-in-out;

    @include md {
      padding: 44px 16px;
      margin: 0 auto;
    }

    @include xl {
      padding: 44px 0;
    }

    .content-outer-container {
      position: relative;
      padding-top: 48px;
      margin-top: 48px;

      .content-header-container {
        @extend %flex-col;
        border-top: 1px solid $lightest;
        position: absolute;
        top: 0;
        width: calc(100% + 48px);
        margin-left: -24px;
        height: 1px;

        @include md {
          margin-left: 0;
          width: 100%;
        }

        .content-header {
          font-size: 18px;
          margin: 0 auto;
          text-transform: uppercase;
          text-align: center;
          font-weight: bold;
          margin-top: -11px;
          background: white;
          padding: 0 12px;
          color: $darkest;

          @include md {
            white-space: nowrap;
            padding: 0 24px;
          }
        }
      }
    }

    .show-more-btn {
      text-align: center;
      padding: 12px 0;
      font-size: 14px;
      color: $darker;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      cursor: pointer;
      font-weight: bold;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .extra-data {
    margin-top: 96px;
    border: $border-offwhite;
    color: $darker;
    padding: 12px;
    .admin-header {
      margin-bottom: 12px;
      text-transform: uppercase;
      font-size: 12px;
      color: $dark;
    }
    .product-id {
      // background: ;
    }
  }
}
