@import '../../styles/shared.scss';

.chat-sort-outer-container {
  @extend %flex-col;
  background-color: white;
  position: absolute;
  top: 24px;
  right: 24px;
  width: calc(100% - 48px);
  max-height: calc(100% - 48px);
  border-radius: 10px;
  box-shadow: $shadow-lightest;
  z-index: 12;

  .panel-header {
    @extend %flex-row-middle-spread;
    margin-bottom: 8px;
    padding: 16px 20px 0;
    top: 0;

    .title {
      font-size: 20px;
      font-weight: bold;
    }

    .close-btn {
      @extend %btn-primary-new;
      padding: 8px 12px;
      font-size: 12px;
    }
  }

  .chat-sort-inner-container {
    padding: 0 20px 48px;
    overflow: auto;
    min-height: 240px;
    max-height: 400px;
    max-height: calc(100% - 48px);

    .sort-options {
      @extend %flex-col;
      gap: 8px;

      .sort-option {
        @extend %flex-row-middle-spread;
        @extend %btn-basic;
        align-self: flex-start;
        padding: 9px 12px;
        color: $dark;
        border: $border-lighter;
        border-radius: 10px;
        max-width: 100%;
        // ellipsis for overflow

        &.active {
          background-color: $darkest;
          border: $border-darkest;
          color: white;
          opacity: 1;
        }

        .title {
          flex: 1;
          font-size: 12px;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
