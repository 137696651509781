@import '../../styles/shared.scss';

.edit-shop-section-outer-container {
  height: 100%;
  padding: 20px 20px 200px;
  overflow: scroll;

  .none-selected {
    @extend %flex-center;
    height: 100%;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    color: $medium;
  }

  .back-to-sections-btn-mobile-only {
    @extend %flex-row-middle;
    @extend %label;
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: bold;
    color: $darkest;
    margin-bottom: 24px;

    svg {
      margin-right: 8px;
    }

    @include md {
      display: none;
    }
  }

  .panels {
    @extend %flex-col;
    gap: 24px;

    .panel {
      max-width: 100%;
      .panel-header-container {
        margin-bottom: 12px;
        .panel-header {
          font-family: $font-header;
          font-size: 16px;
          color: $darkest;
          font-weight: bold;
        }
        .panel-subheader {
          font-size: 12px;
          color: $dark;
        }
      }
      .panel-body {
        @extend %flex-col;

        input {
          @extend %input-shared;
          @extend %label;
          width: 100%;
          font-weight: normal;
          max-width: 240px;

          @include md {
            font-size: 14px;
            font-size: 12px;
          }
        }

        .shop-section-templates {
          @extend %flex-row;
          gap: 12px;
          align-self: stretch;

          @include md-down {
            overflow: scroll;
          }

          @include md {
            flex-wrap: wrap;
          }
        }

        .checkbox-wrappers {
          @extend %flex-col;
          gap: 4px;

          .checkbox-wrapper {
            @extend %flex-row-middle;
            @extend %btn-basic;

            .display {
              font-size: 12px;
              color: $dark;

              &.selected {
                color: $darkest;
                font-weight: bold;
              }
            }

            .checkbox {
              height: 12px;
              width: 12px;
              border-radius: 4px;
              background: $white;
              margin-right: 6px;
              border: $border-lightest;

              &.selected {
                @extend %flex-center;
                background: $primary;
                border: $border-primary;

                svg {
                  font-size: 8px;
                  color: white;
                }
              }
            }
          }
        }
      }
    }
  }

  .action-btns {
    @extend %flex-col;
    gap: 12px;
    margin-top: 12;

    .action {
      align-self: flex-start;
      text-transform: uppercase;
      font-size: 14px;

      &.done {
        @extend %btn-darkest-new;
        padding: 8px 48px;
      }
    }
  }

  .saving-indicator {
    background: $primary;
    padding: 2px 8px;
    border-radius: 4px;
    position: absolute;
    top: 16px;
    right: 32px;
    color: white;
    @extend %label;
    font-size: 10px;
  }
}
