@import '../../styles/shared.scss';

.sort-direction-icons {
  @extend %flex-col-middle;
  cursor: pointer;

  .sort-icon {
    opacity: 0.4;

    &.up {
      margin-bottom: -10px;
    }

    &.active {
      opacity: 1;
    }
  }
}
