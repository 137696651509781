@import '../../styles/shared.scss';

.opportunity-request-result {
  @extend %flex-row-middle;
  gap: 36px;
  background-color: white;
  background-color: $white;
  position: relative;
  border-radius: 10px;
  transition: border 0.2s ease;
  border: $border-transparent;
  padding: 0 24px 0 0;
  text-decoration: none;

  @include md-down {
    flex-direction: column;
    gap: 24px;
  }

  &:hover {
    border: $border-offwhite;
  }

  &.featured-card {
    padding: 12px 24px;
  }

  .media-container {
    @extend %flex-row;
    position: relative;
    height: 80px;
    width: calc(80px + 24px * 0); // OFFSET
    margin-right: 24px;

    @include md-down {
      width: 100%;
      min-height: 180px;
      width: calc(100% + 48px);
      margin-right: 0;
      margin-top: -12px;
    }

    &.featured-card {
      height: 120px;
    }

    .single-image-container {
      @extend %flex-center;
      img {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        object-fit: cover;
      }
    }

    .media-img-container {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: white;
      border-radius: 10px;
      overflow: hidden;
      z-index: 3;
      transition: transform 0.25s ease;

      @include md-down {
        border-radius: 10px 10px 0 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.25s ease;
      }

      &:nth-child(2) {
        left: 24px; // OFFSET
        z-index: 2;
        transform: scale(0.8);

        img {
          opacity: 0.5;
        }
      }

      &:nth-child(3) {
        left: calc(24px * 2); // OFFSET
        z-index: 1;
        transform: scale(0.6);

        img {
          opacity: 0.25;
        }
      }
    }
  }

  .main-container {
    @extend %flex-row-spread;
    flex: 1;
    gap: 24px;

    @include md-down {
      width: 100%;
      flex-direction: column;
    }

    .section {
      &.user-info {
        width: 240px;
      }
      &.spend {
        width: 120px;
      }
      &.results {
        flex: 1;
      }
      &.timing {
        width: 120px;
      }

      .section-title {
        @extend %flex-row-middle;
        @extend %label;
        font-size: 10px;
        color: $dark;
        margin-bottom: 12px;

        svg {
          margin-left: 4px;
          font-size: 10px;
        }
      }
      .section-data {
        .name {
          font-size: 18px;
          font-weight: bold;
          color: $darkest;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .metadata {
          @extend %flex-row-middle;
          font-size: 12px;
          color: $dark;

          svg {
            font-size: 10px;
            margin-left: 4px;
          }
        }
      }

      .section-stats {
        @extend %flex-row;
        gap: 30px;
        margin-top: 8px;

        .section-stat {
          @extend %flex-col;
          gap: 4px;

          &.featured {
            .value {
              color: $primary;
            }
            .label {
              color: $primary;
            }
          }

          .value {
            @extend %flex-row;
            font-size: 20px;
            line-height: 20px;
            min-height: 20px;
            color: $darkest;
            position: relative;
            font-weight: bold;

            &.empty {
              color: $light;
              font-size: 18px;
              font-weight: normal;
            }

            .loader {
              @extend %flex-center;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
            }
          }

          .label {
            font-size: 10px;
            color: $dark;
            opacity: 1;

            &.empty {
              color: $light;
            }

            &.fetching {
              color: $lighter;
            }
          }
        }
      }
    }
  }
}
