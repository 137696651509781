@import '../../styles/shared.scss';

.modal-outer-container.subscription-offer-modal-outer-container {
  .subscription-offer-modal-inner-container {
    @extend %flex-center;

    @include md {
      width: 90vw;
      height: 80vh;
      top: 10vh;
      max-width: 800px;
    }
    .subscription-offer-modal-content {
      position: relative;

      .video-overlay {
        @extend %flex-center;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        padding: 24px;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.9);

        .video-container {
          @extend %flex-center;
          left: 0;
          top: 0;
          width: 100%;
          aspect-ratio: 16 / 9;
          background-color: $black;
          border-radius: 16px;
          overflow: hidden;

          video {
            @extend %animation-fade;
          }
        }
      }

      .media-container {
        aspect-ratio: 800 / 500;
        max-height: 30vh;
        background-color: $offwhite;
        position: relative;

        .cover-image {
          @extend %animation-fade;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .play-video-cover {
          @extend %flex-center;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          transition: background-color 0.2s ease-in-out;
          cursor: pointer;

          &:hover {
            background-color: rgba(0, 0, 0, 0.05);

            .play-icon {
              background-color: rgba(0, 0, 0, 0.2);
              svg {
                transform: scale(1.1);
              }
            }
          }

          .play-icon {
            padding: 20px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.5);
            transition: background-color 0.2s ease-in-out;
            svg {
              font-size: 32px;
              color: white;
              transition: transform 0.2s ease-in-out;
            }
          }
        }
      }

      .body-container {
        @extend %flex-row;
        align-items: flex-start;
        padding: 48px;
        gap: 80px;

        @include md-down {
          flex-direction: column;
          padding: 24px;
          gap: 24px;
        }

        .content {
          @extend %flex-col;
          flex: 2;
          gap: 32px;

          .section {
            .subtitles {
              @extend %label;
              font-size: 10px;
              margin-bottom: 12px;
              font-weight: normal;

              &.loading {
                background-color: $prettymuchwhite;
                width: 80px;
                color: transparent;
                border-radius: 4px;
              }
            }

            .title {
              font-family: $font-header;
              font-size: 32px;
              font-weight: bold;
              margin-bottom: 12px;

              &.loading {
                background-color: $prettymuchwhite;
                width: 240px;
                color: transparent;
                border-radius: 4px;
              }
            }

            .description {
              font-size: 12px;
              color: $dark;

              &.loading {
                background-color: $prettymuchwhite;
                width: 100%;
                height: 80px;
                color: transparent;
                border-radius: 4px;
              }
            }
            .section-title {
              font-weight: bold;
              margin-bottom: 12px;
              font-size: 12px;
            }

            &.features-container {
              .features {
                @extend %flex-col;
                gap: 8px;

                .feature {
                  @extend %flex-row-middle;
                  gap: 8px;

                  .icon {
                    @extend %flex-center;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: $primaryLighter;
                    color: white;
                    font-size: 8px;
                  }
                  .text {
                    font-size: 12px;
                  }
                }
              }
            }

            &.used-by {
              .used-by-logos {
                @extend %flex-row-middle;
                flex-wrap: wrap;
                gap: 12px;

                .logo-container {
                  img {
                    max-height: 20px;
                    max-width: 100px;
                  }
                }

                .and-more {
                  font-size: 12px;
                  color: $medium;
                }
              }
            }
          }
        }

        .actions {
          @extend %flex-col;
          max-width: 200px;
          width: 200px;
          gap: 12px;

          .action {
            @extend %btn-primary-new;
            font-size: 10px;
            padding: 16px 30px;
            position: relative;

            &.disabled {
              background-color: $lightest;
              color: $medium;
              border-color: $lighter;
              pointer-events: none;
            }

            .loader {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            }

            &.is-subscribing {
              color: transparent;
              pointer-events: none;
            }

            &.trial {
              color: $primary;
              border-color: $primary;
              background-color: white;

              &.is-subscribing-to-trial {
                color: transparent;
                border-color: $lighter;
                pointer-events: none;
              }
            }
          }

          .disclaimer {
            font-size: 10px;
            color: $medium;
            font-style: italic;

            &--large {
              font-size: 14px;
            }

            &--bold {
              font-weight: bold;
            }
          }

          .error {
            font-size: 10px;
            color: $error;
            font-weight: bold;
          }

          .variations-container {
            margin-top: 24px;

            .variations-header {
              @extend %label;
              font-size: 10px;
              margin-bottom: 8px;
            }
            .variations {
              @extend %flex-col;
              gap: 12px;

              .variation {
                @extend %flex-row-middle-spread;
                gap: 6px;
                font-size: 11px;
                cursor: pointer;

                &.selected {
                  font-weight: bold;

                  .main .radio {
                    background-color: $primary;
                  }
                }

                .main {
                  @extend %flex-row-middle;
                  gap: 8px;

                  .radio {
                    @extend %flex-center;
                    width: 12px;
                    min-width: 12px;
                    height: 12px;
                    min-height: 12px;
                    border-radius: 4px;
                    border: 1px solid $lighter;
                  }
                }

                .price-diff {
                  font-size: 10px;
                  color: $dark;
                }
              }
            }
          }
        }
      }

      .close-icn {
        position: absolute;
        font-size: 30px;
      }
    }
  }
}
