@import '../../styles/shared.scss';

.upload-basic-container {
  @extend %flex-row-middle;
  position: relative;
  cursor: pointer;
  input {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    position: absolute;
    top: -4px;
    left: -4px;
    opacity: 0;
    cursor: pointer;
  }
  svg {
    margin-left: 6px;
  }

  .disabled-cover {
    position: absolute;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    top: -4px;
    left: -4px;
  }
}

.upload-image-outer-container {
  @extend %flex-col;
  align-self: flex-start;
  position: relative;
  cursor: pointer;

  .upload-header {
    @extend %flex-row;
    margin-bottom: 12px;
    color: $darkest;
    font-weight: bold;
    font-size: 18px;

    .header-text {
      margin-right: 12px;
    }

    svg {
      outline: none;
    }
  }
  .upload-image-inner-container {
    @extend %flex-col-middle;
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 100px;
    background: $offwhite;
    overflow: hidden;
    font-size: 12px;
    font-family: $font-body;
    color: $darkest;
    position: relative;
    outline: none;

    input {
      opacity: 0.3;
      width: 140px;
      height: 140px;
      min-width: 140px;
      min-height: 140px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }

    .click-msg {
      @extend %flex-center;
      pointer-events: none;
      width: 100%;
      height: 100%;
      position: absolute;
      background: $darkest;
      color: white;
      font-weight: bold;
      padding: 16px 16px 8px;
      text-align: center;
      top: 0;
    }

    .uploaded-image {
      width: 140px;
      height: 140px;
      object-fit: cover;
      pointer-events: none;
    }

    .uploading-overlay {
      @extend %flex-center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      color: white;
      font-size: 12px;
      font-family: $font-body;
    }
  }

  .upload-image-apply-crop-btn-container {
    @extend %flex-col;
    width: 140px;

    .upload-image-apply-crop-btn {
      @extend %btn-primary;
      margin-top: 16px;
      align-self: center;
      padding: 4px 12px;
      font-size: 12px;
      position: absolute;
      bottom: -32px;
    }
  }
}
