@import '../../styles/shared.scss';

.address-modal-outer {
  .modal-container {
    height: 75vh !important;
  }

  .address-modal-inner {
    padding: 15px;
    width: 100%;
    background-color: white;
    border-radius: 16px;
    border: $border-lightest;
    @extend %flex-col;
    color: black;
    position: relative;

    .heading {
      margin-bottom: 8px;
      .address-header {
        font-family: $font-header;
        font-size: 2rem;
        font-weight: 600;
      }
    }

    .section {
      margin-bottom: 24px;

      .section-header {
        @extend %label;
        margin-bottom: 4px;
        font-size: 10px;
      }
      input {
        font-family: $font-body-new;
        outline: none;
        width: 100%;
        padding: 10px 15px;
        border: $border-lightest;
        border-radius: 5px;

        @include md-down {
          font-size: 16px;
        }
      }

      .form {
        @extend %flex-col;
        gap: 5px;

        .horizontal-form-group {
          @extend %flex-row;
          gap: 5px;
          margin-bottom: 10px;
        }

        .country-select {
          font-size: 0.8rem;
          font-family: $font-body-new;
          border: none;
          outline: none;
        }

        .dropdown-container {
          font-size: 0.8rem;
          font-family: $font-body-new;
        }
        .save-button-inline {
          @extend %btn-darkest-new;
          margin-top: 16px;
          font-size: 12px;
          align-self: flex-start;
          padding: 12px 24px;

          &.disabled {
            opacity: 0.3;
            pointer-events: none;
          }

          @include md {
            display: none;
          }
        }

        .phone-input-formatted.invalid {
          border: 1px solid $error;
        }
      }
    }

    img {
      max-height: 300px;
      max-width: 500px;
      object-fit: cover;
      object-position: center center;
      border-radius: 5px;
    }

    .buttons {
      @extend %flex-row;
      width: 100%;
      justify-content: flex-end;
      gap: 10px;
      position: absolute;
      bottom: 24px;
      right: 24px;

      .clear-button {
        @extend %btn-basic;
        @extend %label;
        background-color: $lightest;
        color: $dark;
        font-size: 0.9rem;
        padding: 10px 16px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        outline: none;
        border: none;
      }

      .save-button {
        @extend %btn-basic;
        @extend %label;
        background-color: black;
        color: white;
        font-size: 0.9rem;
        padding: 10px 16px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        outline: none;
        border: none;

        &.disabled {
          opacity: 0.3;
          pointer-events: none;
        }
      }

      @include md-down {
        display: none;
      }
    }
  }
}
