@import '../../../../styles/shared.scss';

/*************************************************** */
// UserTierPanel 
/*************************************************** */
.user-tier-panel-outer {
  .user-tier-panel-inner {
    position: relative;

    .user-tier-panel-breakdown-container {
      border: $border-lightest;
      border-radius: 4px;
    }

    .title-container {
      .title {
        @extend %flex-row-middle-spread;
        font-size: 14px;
        font-weight: bold;
        color: $darker;
        text-transform: uppercase;
        position: relative;

        .expand-btn {
          @extend %btn-basic;
          position: absolute;
          top: 0;
          right: 0;
          font-size: 12px;
          margin-top: 0px;
          color: $darker;

          svg {
            margin-left: 4px;
          }
        }
      }

      .subtitle {
        font-size: 12px;
        margin-top: 4px;
        color: $darker;

        a {
          @extend %link;
        }
      }

      .warning-msg {
        left: 0;
        font-size: 10px;
        color: $dark;
        margin-top: 4px;

        svg {
          font-size: 8px;
          margin-right: 4px;
        }
      }
    }

    .secondary-title-container {
      margin: 16px 0 12px;

      .title {
        @extend %flex-row-middle-spread;
        font-size: 14px;
        font-weight: bold;
        color: $darker;
        text-transform: uppercase;
      }

      .subtitle {
        font-size: 12px;
        margin-top: 2px;
        color: $darker;

        a {
          @extend %link;
        }
      }
    }

    .unlocked-grid {
      @extend %flex-row;
      margin: 8px 0 0;

      @include md-down {
        flex-direction: column;
        margin: 8px 0 8px 0;
      }

      .unlocked-grid-column {
        padding: 10px 15px;
        border: $border-lightest;
        border-radius: 10px;
        width: 25%;
        margin: 4px;
        font-weight: 400;
        height: max-content;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        @include md-down {
          width: 100%;
          margin: 4px 0;

          &:first-child {
            margin-top: 0;
          }
        }

        &.unlocked {}

        &.current {}

        &.locked {
          opacity: 0.3;
          background: $lighter;
        }

        .cell {
          font-size: 12px;
          color: $darker;
          margin-bottom: 3px;

          .bullet {
            margin-right: 4px;
          }

          &.header {
            font-size: 14px;
            margin-bottom: 5px;
          }
        }
      }
    }

    .expand-footer-btn {
      @extend %flex-center;
      @extend %btn-basic;
      position: absolute;
      bottom: -20px;
      right: 0;
      font-size: 10px;
      margin-top: 0px;
      color: $medium;
      text-transform: uppercase;

      &.expanded {
        bottom: -72px;
      }

      svg {
        margin-left: 4px;
      }
    }
  }
}

/*************************************************** */
// UserTierPanelBreakdown
/*************************************************** */
.user-tier-panel-breakdown-outer {

  .user-tier-panel-breakdown-inner {
    @extend %flex-col;
    gap: 16px;
    padding: 15px;

    .user-tier-panel-breakdown-basic-info {
      @extend %flex-row;
      @extend %btn-basic;
      justify-content: space-between;

      >div {
        @extend %flex-row;
        gap: 8px;

        .user-tier-panel-breakdown-basic-info-score {
          font-size: 18px;
          font-weight: 500;
          font-family: $font-header;
        }

        .user-tier-panel-breakdown-basic-info-title {
          font-size: 14px;
        }
      }
    }

    .user-tier-panel-breakdown-basic-info-description {
      font-size: 12px;
    }

    .profile-meter {
      @extend %flex-col;
      gap: 4px;

      .profile-meter-section {
        @extend %flex-row;
        font-size: 12px;
        color: $light;
        align-items: center;

        svg {
          font-size: 12px;
          margin-right: 8px;
        }

        &.complete {
          color: $darkest;

          svg {
            color: $primary;
          }
        }
      }
    }

  }
}

/*************************************************** */
// UserTierPanelMeter 
/*************************************************** */
.user-tier-panel-meter-outer-container {

  +.user-tier-panel-meter-outer-container {
    border-top: $border-lightest;
  }

  .user-tier-panel-meter-outer {
    .user-tier-panel-meter-inner {
      height: 30px;
      width: calc(100% - 30px);
      margin-left: 12px;
      position: relative;

      &.tiny {
        height: 3px;
        margin-bottom: 0;
      }

      .spine {
        background: $offwhite;
        border-radius: 14px;
        height: 100%;
        width: calc(100% + 30px);
        position: absolute;
        left: -12px;

        &.active {
          background: $primary;
          transform: scaleX(1);
          transition: transform 0.5s ease-in-out;
          transform-origin: left;
        }
      }

      .mark-container {
        position: absolute;
        top: 0;

        &.complete {
          .mark {
            border: 2px solid $primary;
            color: $primaryLighter;
            background: $primaryDark;
          }

          .mark-label {
            opacity: 1;
          }
        }

        .mark {
          @extend %flex-center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: $white;
          border: 2px solid $offwhite;
          font-weight: bold;
          color: $lighter;
          font-size: 10px;

          &.current {
            background: $primaryDark;
            color: $white;
            font-size: 12px;
            border: 2px solid $primary;
            transition: border 0.4s ease-in-out;
          }
        }

        .mark-label {
          position: absolute;
          opacity: 0.4;
          width: 120px;
          top: calc(100% + 6px);
          left: calc(-60px + 12px);
          font-size: 10px;
          line-height: 10px;
          color: $darkest;
          text-transform: uppercase;
          text-align: center;
          white-space: nowrap;
          font-weight: bold;

          &.current {
            color: $darkest;
            opacity: 1;
          }

          svg {
            margin-left: 3px;
          }
        }

        .mark-label-pointer {
          background: $lighter;
          width: 2px;
          height: 12px;
          margin-left: 12px;
        }
      }
    }
  }

  .sections {
    @extend %flex-row;
    width: calc(100% - 24px);
    margin-top: 8px;

    .section {
      text-align: center;
      transform: translateX(12px);

      .label {
        font-size: 10px;
        color: $dark;
      }
    }
  }
}
