@import '../../styles/shared.scss';

.catalog-sitewide-rate-row {
  display: flex;
  border-radius: 8px;
  background: $white;
  padding: 8px;

  .profile-img-wrapper {
    border-radius: 50%;
    height: 70px;
    width: 70px;
    padding: 2px;
    background: $darkest;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 50%;
      filter: invert(1);
    }
  }

  .rate-content {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin-left: 20px;
  }

  .rate-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    .rate-label {
      font-family: $font-header;
      color: $black;
      font-size: 20px;
    }

    .rate-meta {
      color: $light;
      font-size: 12px;
    }
  }

  .rate-action-container {
    @extend %flex-col-middle;
    align-items: flex-end;

    .rate-action-label {
      width: 100%;
      text-align: left;
      font-size: 12px;
    }

    .inline-rate-container {
      display: flex;
      align-items: center;
      gap: 20px;

      .input-rate-action {
        &.delete {
          &:not(.disabled) {
            color: $error;
          }
        }
      }
    }
  }
}
