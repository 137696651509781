@import '../../../styles/shared.scss';

.latest-section.sales {
  @extend %flex-row;
  flex-wrap: wrap;
  gap: 24px;

  .sale {
    @extend %flex-row;
    width: calc(100%);
    position: relative;
    border-radius: 12px;
    background-color: $white;
    transition: transform 0.2s ease-in-out;
    overflow: hidden;
    text-decoration: none;

    @include md {
      width: calc(50% - 24px / 2);
    }

    @include xl {
      width: calc(33% - 24px / 2);
    }

    /*************************************************** */
    // FEATURED DESIGN (brands can pay for a spotlight)
    /*************************************************** */
    &.isFeatured {
      aspect-ratio: 16/9;
      color: white;

      &.ended {
        opacity: 0.3;

        .brand-cover-image {
          img {
            filter: grayscale(100%);
          }
        }
      }

      .brand-cover-image {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 0;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: #0007;
        }

        img {
          width: 100%;
          border-radius: 12px;
          aspect-ratio: 16/9;
          object-fit: cover;
        }
      }

      .data-container {
        @extend %flex-col;
        flex: 1;
        z-index: 1;
        padding: 24px;
        justify-content: space-between;

        .merchant-details {
          @extend %flex-row;
          @extend %label;
          gap: 8px;
          font-size: 12px;

          a {
            color: white;
            text-decoration: none;
          }
        }

        .sale-details {
          .sale-text {
            font-size: 32px;
            font-family: $font-header;
            font-weight: 600;

            @include md-down {
              font-size: 24px;
            }
          }

          .sale-subtext {
            font-size: 14px;
          }

        }

        .sale-date-details {
          @extend %label;
          @extend %flex-row;
          gap: 10px;
          font-size: 12px;
          color: $white;
          font-weight: bold;
          align-items: center;

          .action-btn {
            @extend %flex-row-middle;
            @extend %label;
            align-self: flex-start;
            background: $white;
            color: $darkest;
            border-radius: 4px;
            cursor: pointer;
            font-size: 10px;
            padding: 5px 8px;

            .sale-extra {
              @extend %flex-row-middle;
              gap: 8px;
            }

            svg {
              margin-left: 4px;
              font-size: 11px;
            }
          }


          .sale-date {
            @extend %flex-row-middle;
            gap: 8px;
            font-size: 12px;
          }
        }

        .date {
          @extend %flex-row-middle;
          margin-top: 12px;
          font-size: 14px;
          color: $darkest;
          font-weight: bold;

          svg {
            margin-right: 4px;
            font-size: 14px;
          }
        }

      }
    }

    /*************************************************** */
    // FEATURED DESIGN
    /*************************************************** */
    &.notFeatured {
      &.ended {
        opacity: 0.3;

        .percentage {
          img {
            filter: grayscale(100%);
          }
        }
      }

      .percentage {
        @extend %flex-col;
        width: 130px;
        padding: 16px;
        position: relative;

        img {
          width: 100%;
          border-radius: 12px;
          aspect-ratio: 1;
          object-fit: cover;
        }
      }

      .data-container {
        @extend %flex-col-spread;
        flex: 1;
        padding: 16px;
        padding-left: 4px;

        .main {
          @extend %flex-col;

          .title {
            font-size: 22px;
            font-family: $font-header;
            font-weight: bold;
            color: $darkest;
          }

          .merchant {
            @extend %btn-basic;
            font-size: 11px;
            margin-top: 2px;
            color: $dark;
            text-decoration: none;

            .domain {
              font-weight: bold;
              color: $darkest;
              text-decoration: underline;
            }

            svg {
              font-size: 8px;
              margin-left: 4px;
              transform: translateY(-1px);
            }
          }

          .description {
            margin-top: 8px;
            font-size: 13px;
            line-height: 18px;
            color: $dark;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;

            &.expanded {
              -webkit-line-clamp: 30;
            }
          }
        }

        .secondary-details {
          @extend %flex-row;
          gap: 10px;


          .date {
            @extend %flex-row-middle;
            font-size: 12px;
            color: $darkest;
            font-weight: bold;

            svg {
              margin-right: 4px;
              font-size: 14px;
            }
          }

          .action-btn {
            @extend %flex-row-middle;
            @extend %label;
            align-self: flex-start;
            background: $dark;
            color: $white;
            border-radius: 4px;
            cursor: pointer;
            font-size: 10px;
            padding: 5px 8px;

            .sale-extra {
              @extend %flex-row-middle;
              gap: 8px;
            }

            svg {
              margin-left: 4px;
              font-size: 11px;
            }
          }
        }

      }
    }


  }

}
