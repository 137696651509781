@import '../../styles/shared.scss';

.requires-login-outer-container {
  font-family: $font-body;
  .requires-login-inner-container {
    @extend %flex-col-center;
    width: 90vw;
    max-width: 800px;
    margin: 128px auto 0;
    font-size: 14px;
    color: $dark;

    .login-btn {
      @extend %btn-darkest;
      @extend %label;
      margin-top: 16px;
      padding: 6px 24px;
    }
  }
}
