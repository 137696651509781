@import '../../styles/shared.scss';

.brand-report-container {
  @extend %flex-col;
  gap: 24px;

  .actions-container {
    @extend %flex-row-spread;

    @include md-down {
      flex-direction: column;
      gap: 16px;
    }

    .actions-right {
      @extend %flex-row-middle;
      gap: 12px;
    }

    .action {
      @extend %flex-row-middle;
      font-size: 14px;
      gap: 8px;
      text-wrap: nowrap;

      .refresh-btn {
        @extend %btn-darkest-new;
        font-size: 12px;
      }

      .compare-select {
        width: 20rem;

        @include md-down {
          width: 100%;
        }
      }

      .link {
        @extend %link;
        color: $darkest;
        margin-left: -4px;
      }
    }
  }

  .sections-container {
    display: flex;
    gap: 24px;

    @include md-down {
      flex-direction: column;
    }

    .section-column {
      @extend %flex-col;
      gap: 24px;
      width: 100%;
    }
  }

  .new-brand-message {
    @extend %flex-col-center;
    text-align: center;
    gap: 20px;
    background-color: white;
    border-radius: 24px;
    padding: 48px;
  }
}
