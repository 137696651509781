@import '../../styles/shared.scss';

.collaborations-outer-container {
  @extend %flex-col;
  width: 100%;
  padding: 48px 24px 0;
  position: relative;
  font-family: $font-body;
  background: $offwhite;
  min-height: 100vh;

  @include md-down {
    padding: 32px 16px 0;
  }

  .collaborations-inner-container {
    @extend %flex-col;
    width: 100%;
    margin: 120px auto;
    max-width: 960px;
    transform: translateY(-120px);

    &.all {
      max-width: 800px;
    }

    &.new {
      max-width: 960px;
    }

    .loading-collaborations {
      margin-top: 20vh;
    }

    .tabs-container {
      @extend %flex-center;
      margin-bottom: 24px;

      .tabs {
        @extend %flex-row-middle;

        .tab {
          @extend %btn-basic;
          border-radius: 0;
          padding: 0 4px 4px;
          margin: 0 12px;
          font-size: 16px;
          font-weight: bold;
          color: $light;
          text-transform: uppercase;
          border-bottom: 2px solid transparent;

          @include sm-down {
            font-size: 12px;
          }

          &.active {
            color: $darker;
            border-bottom: 2px solid $darker;
            pointer-events: none;
          }
        }
      }
    }

    .controls-panel-outer {
      @extend %flex-col;
      .controls-panel {
        @extend %flex-row-middle-spread;
        max-width: 800px;
        width: 100%;
        margin: 0 auto;

        .search-input-container {
          flex: 1;
          input {
            @extend %input-shared;
            border-radius: 24px;
            width: 100%;
          }
        }
      }

      .filters {
        @extend %flex-row-middle;
        align-self: center;
        margin-top: 12px;
        flex-wrap: wrap;

        .filter {
          @extend %btn-basic;
          padding: 2px 6px;
          border-radius: 12px;
          border: $border-lighter;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 11px;
          margin: 0 4px 4px;
          color: $medium;

          &.selected {
            background: $dark;
            color: white;
          }
        }
      }
    }

    .collaborations {
      margin-top: 24px;
      .collaborations-section {
        margin-bottom: 36px;

        .section-header {
          @extend %btn-basic;
          @extend %flex-row-middle-spread;
          font-weight: bold;
          color: $darker;
          text-transform: uppercase;
          margin-bottom: 12px;

          .toggle {
            font-size: 12px;

            &.hide {
              color: $lighter;
            }
            &.show {
              color: $darker;
            }
          }
        }

        .show-more {
          @extend %btn-basic;
          text-align: center;
          margin-top: 16px;
          text-transform: uppercase;
          font-size: 12px;
          color: $medium;
        }
      }
    }

    .brand-partners {
      @extend %flex-row;
      flex-wrap: wrap;
      margin-top: 32px;
      align-items: stretch;

      .empty-results-message-container {
        @extend %flex-center;
        width: 100%;
        .empty-results-message {
          max-width: 640px;
          .empty-message {
            font-size: 14px;
            padding: 12px 8px;
            color: $dark;
          }
          .empty-results-send-message {
            @extend %btn-outline-new;
            font-size: 12px;
            border: $border-lighter;
            text-transform: uppercase;
            margin: 0 8px;
          }
        }
      }
    }
  }
}
