@import '../../styles/shared.scss';

.opportunity-listing-outer-container {
  @extend %flex-col;
  gap: 36px;

  .opportunity-listing-section-card {
    @extend %flex-col;
    background-color: white;
    padding: 60px;
    border-radius: 20px;
    gap: 60px;

    @include md-down {
      border-radius: 0;
      padding: 24px 24px 60px;
    }

    .opportunity-listing-section {
      .opportunity-listing-section-title-container {
        margin-bottom: 16px;

        .opportunity-listing-section-title {
          font-family: $font-header;
          font-size: 26px;
          font-weight: 600;

          @include md-down {
            font-size: 24px;
          }
        }

        .opportunity-listing-section-subtitle {
          font-size: 12px;
          font-weight: 400;
          margin-top: 12px;
          color: $medium;
        }
      }

      .opportunity-listing-content {
        .content {
          font-size: 16px;
          line-height: 24px;
          color: $darkest;
          white-space: pre-wrap;

          @include md-down {
            font-size: 14px;
            line-height: 20px;
          }

          &.empty {
            color: $light;
          }
        }
      }
    }
  }

  .opportunity-listing-action-buttons {
    @extend %flex-row-middle;
    justify-content: flex-end;
    gap: 12px;

    @include md-down {
      margin: 0 24px;
    }

    .opportunity-listing-action-button {
      @extend %btn-darkest-new;
      padding: 12px 24px;
      font-size: 12px;

      &.primary {
        background-color: $primary;
        border-color: $primary;
      }

      &.secondary {
        background-color: transparent;
        color: $darker;
        border-color: $darker;
      }

      &.disabled {
        background-color: $light;
        color: $medium;
        border-color: $light;
        pointer-events: none;
        cursor: default;
      }

      &.text {
        background-color: transparent;
        color: $medium;
        border-color: $lightest;
      }
    }
  }
}
