@import '../../styles/shared.scss';

.bulk-request-message-container {
  @extend %flex-col;
  position: relative;

  .message-template {
    textarea {
      @extend %input-shared;
      resize: none;
      width: 100%;
      font-size: 14px;

      &:focus {
        border: $border-lightest;
      }
    }
  }

  .files {
    @extend %flex-col;
    margin-top: 12px;
    align-items: flex-end;

    .file {
      @extend %flex-row-middle;
      gap: 12px;

      .name {
        font-size: 14px;
        color: $dark;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .actions {
        @extend %flex-row-middle;
        gap: 4px;

        .action {
          @extend %btn-basic;
          color: $darkest;

          &.view {
            font-size: 11px;
          }

          &.delete {
            cursor: pointer;
            font-size: 13px;
          }
        }
      }
    }
  }

  .action-btns {
    @extend %flex-row;
    align-self: flex-end;
    gap: 8px;
    margin-top: 12px;

    .add-new-file {
      @extend %flex-center;
      background-color: $offwhite;
      width: 36px;
      color: $darkest;
      border-radius: 4px;
      font-size: 12px;
      min-height: 100%;
      cursor: pointer;

      &:hover {
        background-color: $lightest;
      }

      .upload-progress {
        font-size: 10px;
      }

      svg {
        transform: translateX(-3px);
      }
    }

    .action-btn {
      @extend %btn-darkest-new;
      @extend %label;
      color: white;
      font-size: 12px;
      padding: 10px 24px;

      &.secondary {
        background-color: transparent;
        color: $dark;
      }

      &.disabled {
        background-color: $lightest;
        color: $light;
        pointer-events: none;
        border: $border-lightest;
      }
    }

    .select {
      @extend %flex-row-middle;
      justify-content: flex-end;
      width: 240px;

      > div {
        flex: 1;
        width: 100%;
      }
    }
  }
}
