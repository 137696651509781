@import '../../../../styles/shared.scss';

.social-sharing-offset-pin-grid-outer-container {
  @include md-down {
    max-width: calc(100vw - 48px);
  }

  .background-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
  }

  .title-container {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    text-align: center;
  }

  .pin-image-grid {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .pin-image-column {
      @extend %flex-col;
      gap: 40px;
      position: absolute;
      width: 43%;

      img {
        aspect-ratio: 1;
        width: 100%;
        object-fit: contain;
        background: white;
        border-radius: 10px;
        box-shadow: $shadow-light;
      }

      &.first {
        left: -55px;
        top: 25px;
      }

      &.second {
        left: 50%;
        top: -70px;
        transform: translateX(-50%);
      }

      &.third {
        right: -55px;
        top: 25px;
      }
    }
  }
}
