@import '../../styles/shared.scss';

.brand-header-outer-container {
  @extend %flex-col-center;
  gap: 24px;

  .image-container {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;

    img,
    .empty {
      background-color: $darkest;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    img {
      @extend %animation-fade;
    }
  }

  .title {
    font-family: $font-header;
    font-size: 36px;
    font-weight: bold;
    color: $darkest;

    &.fetching {
      background-color: $prettymuchwhite;
      border-radius: 8px;
      color: transparent;
    }
  }

  .description {
    font-size: 18px;
    line-height: 26px;
    max-width: 600px;
    text-align: center;
    color: $dark;

    &.fetching {
      background-color: $prettymuchwhite;
      color: transparent;
      border-radius: 8px;
      min-height: 60px;
      min-width: 320px;
    }
  }

  .subdisplay {
    @extend %animation-fade-with-delay;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: $darkest;

    &.fetching {
      background-color: $prettymuchwhite;
      color: transparent;
      border-radius: 8px;
    }
  }

  .action-btns {
    @extend %flex-row-middle;
    position: absolute;
    top: 14px;
    right: 64px;

    .action-btn {
      @extend %btn-basic;
      @extend %flex-row-middle;
      font-size: 16px;
      gap: 6px;
      text-decoration: none;
      color: $darkest;

      svg {
        font-size: 14px;
      }
    }
  }
}
