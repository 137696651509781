@import '../../../styles/shared.scss';

.latest-section.shops {
  @extend %flex-row;
  flex-wrap: wrap;
  gap: 24px;
  .shop {
    width: calc(100%);
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    background-color: white;
    text-decoration: none;

    &:hover {
      transform: scale(0.99);
    }

    img {
      width: 100%;
      object-fit: contain;
    }

    .data {
      padding: 24px;
      color: $darkest;

      .title-container {
        .name {
          font-size: 30px;
          font-family: $font-header;
          font-weight: bold;
          margin-bottom: 6px;
        }

        .subtitle {
          font-size: 16px;
        }

        .action-display {
          margin-top: 16px;
          @extend %label;
          font-size: 14px;

          svg {
            margin-left: 4px;
          }
        }
      }
    }

    @include sm {
      width: calc(50% - 24px / 2);

      iframe {
        aspect-ratio: 1/2;
        width: calc(100% * 4);
        transform: scale(calc(1 / 4));
      }
    }
    @include xl {
      width: calc(33% - 24px / 2);

      iframe {
        aspect-ratio: 1/1.5;
        width: calc(100% * 3);
        transform: scale(calc(1 / 3));
      }
    }
  }
}
