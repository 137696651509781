@import '../../styles/shared.scss';

.subscription-tabs-container {
  .subscription-tabs {
    @extend %flex-row;
    gap: 16px;
    margin-top: 12px;
    margin-bottom: 36px;
    overflow: auto;

    @include md-down {
      padding-left: 20px;
    }

    .tab-container {
      padding-bottom: 8px;

      &.active {
        border-bottom: 4px solid $primary;
      }

      .tab {
        @extend %flex-row;
        @extend %label;
        gap: 8px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
        padding: 12px 16px;
        border-radius: 4px;
        background-color: transparent;
        color: $dark;
        border: $border-lightest;
        transition: opacity 0.2s ease-in-out;
        cursor: pointer;

        .alert,
        .warning {
          margin-right: 4px;
          font-size: 12px;
          height: 12px;
          width: 12px;

          svg {
            font-size: 12px;
          }
        }

        &.alert {
          background-color: $error;
          color: white;
        }

        &.active {
          background-color: $white;
          color: $darkest;
          border: $border-darkest;
        }

        &:hover:not(.active) {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }
}
