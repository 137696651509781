@import '../../styles/shared.scss';

.user-collaboration-preview-outer-container {
  margin-bottom: 16px;
  font-family: $font-body;
  @include md {
    margin-bottom: 32px;
  }

  .user-collaboration-preview-container {
    @extend %flex-center-vertical;
    width: 100%;
    position: relative;
    overflow: hidden;

    @include md {
      border-bottom: none;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      border-radius: 8px;
    }

    .user-collaboration-preview-inner-container {
      @extend %flex-col;
      width: 100%;
      position: relative;

      .main {
        @extend %flex-row-spread;
        align-items: stretch;
        overflow: hidden;
        min-height: 80px;
        position: relative;
        background: $white;
        align-self: stretch;
        color: $dark;
        width: 100%;

        @include md {
          min-height: 160px;
        }

        @include sm-down {
          flex-direction: column;
        }

        .content {
          @extend %flex-col-spread;
          position: relative;
          align-self: stretch;
          padding: 16px 20px 10px;
          flex: 1;

          @include md {
            padding: 24px 30px 18px;
          }
          @include sm-down {
            order: 2;
          }

          .header-content {
            @extend %flex-col;

            .title {
              color: $darker;
              font-size: 20px;
              line-height: 27px;
              letter-spacing: 0.08px;
              margin: 0;
            }

            .subtitle {
              font-size: 13px;
              letter-spacing: 0.06px;
              line-height: 15px;
              line-height: 1;
              margin-top: 4px;
              white-space: pre-line;
            }

            textarea.description-input-container {
              @extend %input-inline;
              margin-top: 4px;
              resize: none;
              font-size: 13px;

              @include sm-down {
                font-size: 16px;
              }

              &::placeholder {
                font-style: italic;
              }
            }
          }

          .templates-section {
            margin-top: 4px;

            .section-header {
              font-size: 11px;
              text-transform: uppercase;
              margin: 12px 0 4px;
              font-weight: bold;
              color: $light;
            }

            .empty-selection {
              font-size: 12px;
              color: $darker;
              margin-top: 4px;
              text-transform: uppercase;
            }
            .templates {
              @extend %flex-row;
              flex-wrap: wrap;
              margin-top: 12px;

              .template {
                @extend %flex-row-middle;
                position: relative;
                margin-right: 8px;
                margin-bottom: 6px;
                border: 1px solid $dark;
                border-left: 3px solid $dark;
                background: $prettymuchwhite;
                border-radius: 4px;
                padding: 4px 6px 4px 8px;
                font-size: 14px;
                font-weight: bold;
                color: $darker;

                &.active {
                  cursor: pointer;
                  &:hover {
                    background: $lightest;
                    .add {
                      background: $darkest;
                      svg {
                        color: $offwhite;
                      }
                    }
                  }
                }

                &.editable {
                  .price {
                    cursor: pointer;
                    &.empty {
                      font-size: 10px;
                      background: $darker;
                      padding: 2px 4px;
                      border-radius: 2px;
                      color: white;
                    }

                    &:hover {
                      &.empty {
                        background: $dark;
                        color: $offwhite;
                      }
                    }
                  }
                }

                .bullet {
                  margin: 0 5px;
                }

                .add {
                  @extend %flex-center;
                  background: $dark;
                  color: white;
                  padding: 2px;
                  border-radius: 50%;
                  width: 18px;
                  height: 18px;
                  font-size: 10px;
                  margin-left: 8px;
                }
                .remove {
                  @extend %btn-basic;
                  color: $darkest;
                  font-size: 12px;
                  padding: 1px 2px 1px 8px;
                }
              }
            }
          }

          .edit-btn {
            @extend %btn-basic;
            position: absolute;
            top: 16px;
            right: 20px;
            font-size: 12px;
            font-weight: bold;

            @include md {
              top: 12px;
              right: 16px;
            }
          }
        }
        .image-container {
          width: 180px;
          min-width: 180px;
          max-width: 180px;
          min-height: 100%;
          position: relative;

          @include sm-down {
            width: 100%;
            min-width: 100%;
            max-height: 240px;
            min-height: 240px;
            overflow: hidden;
            .cover-image {
              object-position: center;
            }
          }

          .cover-image {
            @extend %flex-center-vertical;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            position: absolute;
            right: -1px;
            top: 0;
          }

          .uploading-image-loader {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.2);
            position: absolute;
            top: 0;
            left: 0;

            .loader {
              @extend %flex-center-vertical;
              height: 100%;
            }
          }

          .image-change-controls {
            @extend %flex-row-middle;
            position: absolute;
            bottom: 6px;
            right: 6px;
            font-size: 12px;

            .control {
              @extend %flex-center;
              @extend %btn-basic;
              padding: 6px;
              margin-left: 2px;
              transition: all 0.2s ease-in-out;
              color: $darker;

              &.upload {
                @extend %btn-basic;
              }
              svg {
                z-index: 2;
                margin-left: 0px;
              }

              background: rgba(255, 255, 255, 0.9);
              &:hover {
                background: $light;
                color: $darker;
              }
            }
          }
        }
      }

      &:hover {
        .action-icons {
          display: inherit;
        }
      }

      .action-icons {
        @extend %flex-row-middle;
        top: 12px;
        right: 24px;
        position: absolute;
        color: $lighter;

        @include md {
          display: none;
          right: 12px;
        }

        .remove-icn {
          @extend %btn-basic;
          color: white;
          margin-left: 8px;
          font-size: 18px;
          margin-top: -1px;
        }

        .reorder-icn {
          cursor: grab;
        }
        .reorder-alert {
          @extend %btn-basic;
          font-size: 9px;
          font-weight: bold;
        }
      }
    }
  }

  .footer {
    @extend %flex-col;
    padding: 16px 20px 0;

    &.expanded {
      padding: 16px 20px 12px;

      @include sm-down {
        padding: 32px 20px 48px;
      }
    }

    .footer-header {
      @extend %btn-basic;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      color: $darkest;
      text-align: center;
      margin-bottom: 8px;

      svg {
        margin-left: 8px;
        font-size: 12px;
      }
    }
    .templates {
      @extend %flex-row;
      justify-content: center;
      flex-wrap: wrap;

      .template {
        @extend %flex-row-middle;
        padding: 3px 8px;
        margin: 2px 8px 3px 0;
        border-radius: 4px;
        background: $white;
        border: $border-lightest;
        color: $dark;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;

        &.active {
          background: $prettymuchwhite;
          border: $border-darker;
        }

        &:hover {
          border: $border-light;
        }

        svg {
          font-size: 10px;
          margin-left: 4px;
          color: $medium;
        }
      }
    }
  }
}
