@import '../../styles/shared.scss';

.product-reviews-outer-container {
  .reviews-content-container {
    @extend %flex-row;
    flex-wrap: wrap;

    .review {
      width: calc(100% - 48px);
      margin-bottom: 24px;

      @include md {
        width: 50%;
        padding: 12px;

        &.only-one {
          width: 100%;
        }
      }

      &.editing {
        .text-container {
          position: relative;
          .review-input {
            @extend %input-shared;
            width: 100%;
            line-height: 22px;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.06px;
            color: $darker;

            &.inline {
              @extend %input-inline;
            }
          }

          .char-count {
            position: absolute;
            bottom: 8px;
            right: 4px;
            background: white;
            border-radius: 2px;
            background: $dark;
            font-weight: bold;
            font-size: 9px;
            padding: 2px 4px;
            pointer-events: none;

            &.okay {
              background: $offwhite;
              color: $dark;
            }
            &.not-enough {
              background: $offwhite;
              color: $light;
            }
            &.too-many {
              background: $error;
              color: $white;
            }
          }
        }

        .action-btns {
          @extend %flex-row-middle;
          text-transform: uppercase;
          margin-top: 8px;

          .cancel-btn {
            @extend %btn-text;
            padding: 8px 12px;
            letter-spacing: 0px;
            font-weight: normal;
            font-size: 14px;
            margin-right: 16px;
          }

          .save-btn {
            @extend %btn-dark;
            flex: 1;
            padding: 8px 0;

            &.disabled {
              @extend %btn-disabled;
            }
          }
        }
      }

      .user-header {
        @extend %flex-row-middle;
        text-decoration: none;
        margin-bottom: 12px;

        &:hover {
          img {
          }
        }

        &.is-admin {
          pointer-events: none;
        }

        img,
        .empty {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          object-fit: cover;
          background: $offwhite;
          margin-right: 16px;
        }
        .desc {
          .name {
            font-family: $font-header;
            font-weight: bold;
            color: $darkest;
            font-size: 20px;
          }
          .title {
            font-size: 11px;
            color: $light;
          }

          .likes {
            font-size: 12px;
            color: $darker;
            cursor: pointer;
          }
        }
      }

      .content {
        color: $dark;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        font-size: 16px;
        letter-spacing: 0.06px;
        margin-top: 4px;
        white-space: pre-line;
      }
    }
  }
}
