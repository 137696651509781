@import '../../../styles/shared.scss';

.home-partner-examples-outer-container {
  width: 100vw;
  padding: 64px 0;
  // Grey Gradient up top
  background: linear-gradient(180deg, #f7f7f7 0%, #ffffff 100%);

  @include lg {
    padding: 120px 0;
  }
  @include xxl {
    padding: 140px 0;
  }

  .home-partner-examples-inner-container {
    @extend %flex-col;

    .home-partner-examples-title-container {
      align-self: center;

      // Uses .standard-home-header from Home.scss
    }
  }

  .home-partner-examples {
    width: 100vw;
    overflow-x: auto;

    .home-partner-inner-scroll {
      @extend %flex-row;
      padding: 20px 20px;
      transition: transform 1s linear;
      gap: 24px;

      @include md {
        padding: 20px 120px;
        gap: 48px;
      }

      @include lg {
        padding: 20px 120px;
        gap: 60px;
      }

      @include xl {
        &.center {
          justify-content: center;
        }
      }
    }
  }
}
