@import '../../styles/shared.scss';

.brand-invoices-container {
  &.on-dark {
    .brand-invoices {
      .cell.header {
        background-color: $offwhite;
        color: $darkest;
      }
    }
  }

  .brand-invoices {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    border-radius: 10px;
    overflow: hidden;

    .cell {
      background-color: white;
      padding: 12px 16px;
      font-size: 12px;

      &.warning {
        background-color: #f8f49b;
      }

      &.alert {
        background-color: #fd9491;
      }

      &.header {
        background-color: $darkest;
        color: $white;
        padding: 8px 16px;
        font-weight: bold;
      }

      a {
        @extend %link;

        svg {
          margin-left: 4px;
          font-size: 10px;
        }
      }
    }
  }

  .show-more-container {
    @extend %flex-center;
    gap: 12px;
    width: 100%;
    margin-top: 12px;
    .show-btn {
      @extend %btn-basic;
      font-size: 12px;
      color: $dark;
    }
  }
}
