@import '../../styles/shared.scss';

.list-users-container {
  @extend %flex-row;
  align-items: stretch;
  gap: 36px;
  flex-wrap: wrap;
  transition: 0.25s opacity ease-in-out;

  .syncing {
    opacity: 0.8;
  }

  .list-user-outer-container {
    width: calc(33% - ((2 / 3) * 36px));

    @include md-down {
      width: calc(50% - ((1 / 2) * 36px));
    }
    @include sm-down {
      width: calc(100%);
    }
  }
}
