@import '../../../../styles/shared.scss';

.blacklisted-codes-panel-outer {
  .blacklisted-codes-panel-inner {
    .setting-container {
      color: $darker;

      .location-info-container {
        margin-left: 22px;
      }

      .setting-label {
        font-weight: bold;
        font-size: 14px;
      }

      .setting-description {
        font-size: 12px;
        margin-bottom: 12px;
      }
    }
  }
}
