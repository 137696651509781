@import '../../../styles/shared.scss';

.brand-lookbook-body-outer {
  .brand-lookbook-body-inner {
    width: 87%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 48px 0;

    &.showing-emphasized-note-overlay {
      pointer-events: none;

      &::before {
        @extend %animation-fade-quick;
        width: 100%;
        height: 100%;
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.7) 100%);
        z-index: 1;
      }
    }

    .header {
      @extend %flex-row;
      justify-content: space-between;

      @include sm-down {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }

      @include md {
        flex-direction: row;
      }

      #note-from-brand {
        @extend %flex-row;
        gap: 20px;

        .image-container {
          @extend %flex-center;
          height: 30px;
          width: 30px;
          border-radius: 15px;
          background: $darkest;

          img.icon {
            height: 75%;
            width: 75%;
            object-fit: contain;
            filter: brightness(0) invert(1);
          }

          @include sm-down {
            display: none;
          }
        }

        .text-and-controls {
          @extend %flex-col;

          .text {
            @include sm-down {
              text-align: center;
              textarea {
                text-align: center;
              }
            }

            @include md {
              text-align: left;
              textarea {
                text-align: left;
              }
            }

            .intro {
              @extend %label;
              font-size: 0.7rem;
            }

            textarea.brand-note {
              @extend %input-inline;
              background-color: transparent;

              font-family: $font-body;
              letter-spacing: 0.7px;
              font-weight: normal;
              font-size: 0.8rem;
              color: black;
              resize: none;
              min-width: 400px;
              margin-top: 5px;

              @include md-down {
                min-width: 300px;
              }
            }
          }

          .toggle-emphasis-switch-container {
            @extend %flex-row-middle;
            margin-top: 8px;

            @include md-down {
              margin: 0 auto;
              margin-top: 12px;
            }

            &.active {
              .toggle-emphasis-switch-label {
                color: $darkest;
                font-weight: bold;
              }
              .toggle-emphasis-tooltip-icon {
                color: $darkest;
              }
            }

            .toggle-emphasis-switch {
              margin-right: 8px;
            }

            .toggle-emphasis-switch-label {
              @extend %label;
              font-size: 11px;
              color: $light;
              font-weight: 500;
              margin-right: 6px;
              cursor: pointer;
            }
          }

          .toggle-emphasis-tooltip-icon {
            font-size: 13px;
            color: $light;
          }
        }
      }

      #price-and-item-limit {
        @extend %flex-row;
        gap: 25px;
        cursor: pointer;

        > div {
          @extend %btn-basic;
          @extend %flex-col-center;
          gap: 5px;

          .limit-label {
            // @extend %label;
            font-size: 0.7rem;
            // font-weight: 600;
            text-align: right;
            color: $medium;
          }

          .limit-content {
            @extend %flex-row;
            align-items: center;
            gap: 5px;

            font-family: $font-header;
            font-size: 1.6rem;
            font-weight: 600;
            flex-wrap: nowrap;
            white-space: nowrap;

            &.inactive {
              opacity: 0.5;
            }

            svg {
              @extend %btn-basic;
              height: 12px;
              width: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
