@import '../../../../styles/shared.scss';

.social-sharing-template-controls {
  display: flex;
  justify-content: flex-end;
  margin: 0.7rem 0;
  font-size: 0.7rem;
  gap: 0.5rem;
  position: relative;

  .control-button {
    @extend %btn-inverted;
    padding: 6px 12px;
    text-transform: uppercase;
    font-weight: normal;
    color: $darker;
    letter-spacing: 0.2px;

    &.primary {
      @extend %btn-darkest;
    }

    &.disabled {
      pointer-events: none;
    }
  }

  .color-palettes {
    @extend %flex-col;
    position: absolute;
    bottom: calc(100% + 4px);
    right: 100px;
    display: flex;
    background-color: $white;
    border-radius: 8px;
    border: $border-offwhite;
    box-shadow: $shadow-light;
    padding: 6px 8px;
    gap: 4px;

    .palette-section {
      max-width: 180px;

      .palette-section-header {
        margin-bottom: 4px;
        font-size: 10px;
        text-transform: uppercase;
      }
      .colors {
        @extend %flex-row-middle;
        flex-wrap: wrap;
        gap: 6px;

        .color-container {
          transform: scale(0.8);
          transition: transform 0.2s ease-in-out;

          &:hover {
            transform: scale(1);
          }

          &.active {
            transform: scale(1) !important;

            .color {
              border: 1px solid $darkest;
            }
          }
          .color {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 1px solid $dark;
            cursor: pointer;
          }

          .transparent-bar {
            background: $light;
            height: 100%;
            width: 2px;
            position: absolute;
            top: 0;
            left: calc(50% - 1px);
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
