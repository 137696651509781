@import '../../styles/shared.scss';

.brand-tier-overview-container {
  @extend %flex-col-center;
  gap: 20px;
  background-color: white;
  border-radius: 24px;
  padding: 48px;

  @include md-down {
    padding: 24px;
  }

  .overview-header {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }

  .tier-label-container {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    width: 85%;
    justify-content: center;

    .skeleton-tier {
      display: flex;
      justify-content: space-between;

      &.before {
        margin-right: 15%;
      }

      &.after {
        margin-left: 15%;
      }

      &.before > *:only-child {
        margin-left: auto;
      }
    }

    @include md-down {
      display: flex;

      .skeleton-tier {
        display: none;
      }
    }

    .tier-label.skeleton {
      font-size: 16px;
      color: $light;
      transition: color 0.3s ease-in-out, font-size 0.3s ease-in-out;
      text-wrap: nowrap;
      display: flex;
      align-items: center;

      svg {
        margin-right: 4px;
        height: 12px;
        width: 12px;
      }

      &.pointsHovered {
        font-size: 18px;
        color: $darker;
        font-weight: bold;
      }
    }

    .tier-label.main {
      font-size: 48px;
      font-family: $font-header;
      font-weight: bold;
      text-align: center;
    }
  }

  .tier-info {
    @extend %flex-col-center;
    gap: 8px;

    .tier-description {
      font-size: 14px;
      text-align: center;
      width: 70%;
      margin: 16px 0 24px 0;
      color: $darkest;
    }

    @include md-down {
      .tier-description {
        width: 100%;
      }
    }
  }

  .point-bar-container {
    width: 85%;

    @include md-down {
      width: 100%;
    }

    .point-bar {
      display: flex;
      background-color: $lightest;
      border-radius: 18px;
      height: 32px;
      width: 100%;
      position: relative;

      .point-bar-coin {
        $coin-size: 28px;
        @extend %flex-center-vertical;
        position: absolute;
        background-color: $lighter;
        border-radius: 50%;
        width: $coin-size;
        height: $coin-size;
        top: 2px;
        font-weight: bold;
        font-size: 12px;
        cursor: default;
        z-index: 2;

        &.left {
          left: 2px;
        }

        &.mid-left {
          left: calc(30% - #{$coin-size} / 2);
        }

        &.mid-right {
          left: calc(70% - #{$coin-size} / 2);
        }

        &.right {
          left: calc(100% - #{$coin-size} - 2px);
        }

        &.covered:not(.compare) {
          background-color: $primaryDark;
          color: #50a67e;
        }

        &.cur-score {
          background-color: white;
          color: $primary;
          left: 0; // initial left position
          transition: left 0.75s ease-in-out;
        }

        &.cur-score.declined {
          color: $error;
        }
      }

      .point-bar-progress {
        position: absolute;
        border-radius: 16px;
        height: 32px;
        background-color: $primary;
        background-size: 40px 40px;
        width: 0%; // initial width
        transition: width 0.75s ease-in-out;
        z-index: 1;

        &.declined {
          background-color: $darkest;
        }

        &.improved:not(.compare) {
          background-color: $primary;
          z-index: 0;
        }

        &.compare {
          background-color: $error;
          z-index: 0;
        }

        &.improved.compare {
          background-color: $darkest;
          z-index: 1;
        }
      }
    }
  }
}
