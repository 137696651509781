@import '../../styles/shared.scss';

.blog-post-preview-outer {
  text-decoration: none;
  color: $darkest;
  position: relative;
  flex: 1;
  overflow: hidden;

  &.isHidden {
    opacity: 0.5;
  }

  &.large {
    grid-row: 1/3;
    grid-column: 1/2;

    @include lg-down {
      grid-column: 1/3;
      grid-row: 1/2;
    }

    .blog-post-preview-inner {
      flex-direction: column;
      height: 100%;
      position: relative;

      .blog-post-preview-image {
        height: 80%;

        @include md-down {
          height: 100%;
        }
      }

      .blog-post-preview-text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60%;
        background-color: white;
        padding: 30px 50px 0 0;

        @include md-down {
          position: relative;
          width: 100%;
        }

        .blog-post-preview-tag {
          padding-bottom: 10px;
        }
      }
    }
  }

  .blog-post-preview-inner {
    @extend %flex-row;
    gap: 20px;
    position: relative;

    .blog-post-icon-container {
      @extend %flex-row;
      gap: 5px;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 5px 10px;
      background-color: #0001;
      z-index: 1;
      border-radius: 5px;

      svg {
        font-size: 13px;
      }
    }

    @include lg-down {
      flex-direction: column;
    }

    .blog-post-preview-image {
      height: 200px;
      aspect-ratio: 16/11;
      object-fit: cover;

      @include xxl-down {
        aspect-ratio: 16/11;
      }

      @include xl-down {
        aspect-ratio: 1;
      }

      @include lg-down {
        aspect-ratio: 16/9;
      }

      @include md-down {
        height: 300px;
      }

      @include xs-down {
        height: 200px;
      }
    }

    .blog-post-preview-text {
      @extend %flex-col;
      gap: 10px;

      .blog-post-preview-tag {
        @extend %flex-row;
        @extend %label;
        align-items: center;
        font-size: 12px;
        gap: 10px;

        @include sm-down {
          font-size: 10px;
        }

        .line {
          flex: 1;
          height: 1px;
          width: 100%;
          background-color: $darkest;
        }
      }

      .blog-post-preview-title {
        font-family: $font-header;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;

        @include sm-down {
          font-size: 20px;
        }

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
