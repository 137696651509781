@import '../../styles/shared.scss';

.modal-outer-container .modal-container {
  &.opportunity-planner-inner {
    align-items: flex-start;
    max-width: 1240px;
    max-height: 80vh;
  }
  .modal-inner-container {
    .modal-content.opportunity-planner-content {
      @extend %flex-col;
      min-height: 80vh;
      max-height: 80vh;

      @include md-down {
        padding: 24px;
        min-height: 100vh;
        max-height: 100vh;
      }

      .strategy-panel {
        height: 80vh;
        padding: 24px 32px 0;
        overflow: scroll;

        @include lg {
          padding: 40px 48px 0;
        }

        @include xl {
          padding: 48px 60px 0;
        }

        .strategy-panel-header {
          margin-bottom: 24px;

          .header-container {
            margin-bottom: 12px;
            .back-btn {
              @extend %label;
              @extend %btn-basic;
              @extend %flex-row-middle;
              gap: 10px;
              font-size: 12px;
              margin-bottom: 12px;

              svg {
                font-size: 11px;
                margin-top: -1px;
              }
            }
            .header {
              font-size: 36px;
              font-weight: 500;
              color: $darkest;
              font-family: $font-header;
              margin-bottom: 4px;
            }

            .subheader {
              font-size: 14px;
              color: $medium;
            }
          }
        }
      }

      .strategy-selection-panel {
        @extend %flex-col-center;
        padding: 56px 72px;

        .strategy-selection-header {
          @extend %flex-col-center;
          margin-bottom: 44px;

          .header {
            font-size: 36px;
            font-weight: bold;
            color: $darkest;
            font-family: $font-header;
            margin-bottom: 4px;
          }

          .subheader {
            font-size: 14px;
            color: $medium;
          }
        }

        .strategies {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 32px;
          max-width: 800px;

          .strategy {
            border: $border-lightest;
            box-shadow: $shadow-lightest;
            border-radius: 24px;
            overflow: hidden;
            transition: all 0.3s;

            &:hover {
              cursor: pointer;
              border-color: $lighter;
              box-shadow: $shadow-light;
              transform: scale(0.99);
            }

            img {
              width: 100%;
              aspect-ratio: 5 / 2;
              object-fit: cover;
            }

            .data {
              padding: 12px 24px 20px;

              .title {
                font-size: 18px;
                line-height: 1.4;
                letter-spacing: -0.1px;
                font-weight: bold;
                color: $darkest;
              }
              .subtitle {
                color: $medium;
                font-size: 12px;
                margin-top: 2px;
              }
            }
          }
        }
      }

      .close-icn {
        @extend %btn-basic;
        position: absolute;
        top: 36px;
        right: 36px;
        cursor: pointer;

        svg {
          font-size: 24px;
        }
        z-index: 2;
      }
    }
  }
}
