@import '../../styles/shared.scss';

.partner-portal-top-notification {
  background-color: $white;
  margin-bottom: 40px;
  border-radius: 10px;
  border: $border-lightest;
  padding: 40px;

  .header-container {
    .header {
      @extend %label;
    }

    .subheader {
      font-size: 16px;
      color: $dark;
    }
  }

  .actions-container {
    @extend %flex-row;
    gap: 8px;
    margin-top: 24px;

    .action {
      &.primary {
        @extend %btn-darkest-new;
        font-size: 12px;
        padding: 8px 20px;
      }

      &.secondary {
        @extend %btn-lightest-new;
        font-size: 12px;
        padding: 8px 20px;
      }

      &.dismiss {
        @extend %btn-lightest-new;
        font-size: 12px;
        padding: 8px 20px;
      }
    }
  }
}
