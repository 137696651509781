@import '../../../styles/shared.scss';

.opportunity-setup-guide-sidebar {
  .guide-title-container {
    margin-bottom: 24px;

    .guide-title {
      @extend %label;
      font-size: 12px;
    }
  }
  .guide-steps {
    @extend %flex-col;

    .guide-step {
      @extend %flex-row-middle;
      gap: 12px;
      padding: 8px 0;
      border-radius: 4px;
      cursor: default;

      &:hover:not(.active) {
        cursor: pointer;
        .step-circle {
          border: $border-medium;

          &.upcoming {
            border: $border-lighter;
          }
        }

        .step-title {
          color: $darkest;

          &.upcoming {
            color: $light;
          }
        }
      }

      .step-circle {
        @extend %flex-center;
        height: 25px;
        width: 25px;
        background-color: $offwhite;
        border-radius: 50%;
        font-size: 10px;
        font-weight: bold;
        border: $border-transparent;
        color: $darker;
        transition: border-color 0.2s ease;

        &.complete {
          background-color: $primary;
          color: $white;
        }

        &.upcoming {
          background-color: $offwhite;
          color: $lighter;
        }

        &.active {
          background-color: $darkest;
          color: $white;
        }
      }

      .step-title {
        font-size: 14px;
        color: $medium;
        transition: color 0.2s ease;

        &.active {
          font-weight: bold;
          color: $darkest;
        }

        &.upcoming {
          color: $lighter;
        }
      }
    }

    .step-connector {
      margin-left: 12px;
      width: 2px;
      height: 20px;
      background-color: $lightest;
    }
  }
}
