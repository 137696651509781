@import '../../../styles/shared.scss';

.opportunity-header-tabs {
  @extend %flex-row;
  position: relative;
  margin-bottom: 24px;
  gap: 0.5rem;
  flex-wrap: wrap;

  .tab-container {
    @extend %label;
    height: 100%;
    border-radius: 0;
    font-size: 12px;
    color: $darkest;
    border-bottom: 4px solid transparent;
    transition: all 0.2s ease-in-out;
    font-weight: bold;
    position: relative;

    @include md-down {
      font-size: 10px;
      border-bottom: 3px solid transparent;
    }

    &.active {
      border-color: $primary;
      color: $darkest;
      cursor: default;
    }

    &.disabled {
      color: $lighter;
      pointer-events: none;
    }

    &.active > .tab {
      border: $border-dark;
      background-color: white;
      .count {
        background-color: $dark;
        color: $offwhite;
      }
    }

    .new-badge {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(8px, -8px);
      background-color: $primary;
      color: white;
      padding: 2px 5px;
      border-radius: 4px;
      font-size: 0.5rem;
      @extend %label;
    }

    .tab {
      @extend %flex-row-middle;
      cursor: pointer;
      padding: 8px 12px;
      border-radius: 4px;
      margin-bottom: 8px;
      transition: all 0.2s ease-in-out;
      border: $border-lighter;
      gap: 6px;

      @include md-down {
        margin-bottom: 6px;
      }

      .count {
        padding: 1px 4px;
        border-radius: 4px;
        color: $medium;
        background-color: $lightest;
        font-size: 9px;
        font-weight: bold;
        transition: all 0.2s ease-in-out;
      }

      &.active {
        cursor: default;
        background-color: $darkest;
        color: white;
      }

      &:hover {
        border: $border-dark;
      }
    }
  }
}
