@import '../../styles/shared.scss';

.header-logo-outer-container {
  @extend %flex-row-middle;
  position: relative;
  transform-origin: left;
  cursor: pointer;

  img {
    transition: filter 0.3s ease-in-out;
  }

  img.logo-img {
    height: 28px;
  }

  // Larger logos on some pages
  &.home,
  &.referral,
  &.join {
    z-index: 2;
    @include md {
      img.logo-img {
        height: 44px;
      }
    }
  }

  // Handle coloring
  &.dark-elements {
    img.logo-img {
      filter: brightness(0) invert(0);
    }
  }

  &.white-elements {
    img.logo-img {
      filter: brightness(0) invert(1);
    }
  }

  // Customize Brand Logos
  .brand-theme-logo {
    max-height: 40px;
    margin-right: 6px;
    max-width: 160px;
    filter: brightness(0) invert(1);
    object-fit: contain;
  }
}
