@import '../../styles/shared.scss';

.artist-modal {
  max-width: 1024px !important;
  top: auto !important;
  height: 80vh !important;
  max-height: 800px !important;

  &.fetching {
    pointer-events: none;

    .modal-inner-container {
      @extend %flex-center;
      background: transparent !important;

      .artist-inner-modal {
        border: none;
      }
    }
  }

  @include md-down {
    flex-direction: column;
    height: 100vh !important;
    max-height: 100vh !important;
  }

  .artist-inner-modal {
    @extend %flex-row;
    padding: 0 !important;
    flex-direction: row !important;
    overflow: hidden !important;
    border-radius: 10px;

    @include md-down {
      flex-direction: column !important;
      overflow: auto !important;
    }

    .sidebar-container {
      flex: 1;
      overflow: auto;
      max-width: 300px;
      min-width: 300px;
      width: 300px;

      @include md-down {
        overflow: visible;
        flex: auto;
        max-width: 100%;
        min-width: 100%;
        width: 100%;
      }
    }

    .main-container {
      @extend %flex-col;
      padding-top: 64px;
      position: relative;
      flex: 1;

      .body {
        overflow-y: auto;
        flex: 1;
      }

      .body-tabs {
        @extend %flex-row-middle;
        align-items: flex-end;
        overflow-x: auto;
        gap: 12px;
        position: absolute;
        background: white;
        top: 0;
        left: 0;
        width: 100%;
        padding: 16px 64px 0 24px;
        border-bottom: $border-offwhite;

        .body-tab {
          @extend %label;
          height: 100%;
          border-radius: 0;
          font-size: 12px;
          color: $darkest;
          border-bottom: 4px solid transparent;
          transition: all 0.2s ease-in-out;
          font-weight: bold;

          &.selected {
            border-bottom: 4px solid $primary;
            color: $darkest;
          }

          .display {
            @extend %flex-row-middle;
            padding: 8px 12px;
            border-radius: 4px;
            margin-bottom: 8px;
            cursor: pointer;
            border: $border-offwhite;
            transition: all 0.2s ease-in-out;

            &:hover {
              border: $border-dark;
            }

            .new-badge {
              @extend %flex-center;
              margin-left: 6px;
              background: $darkest;
              border-radius: 12px;
              color: white;
              font-weight: bold;
              padding: 6px 6px;
              height: 18px;
            }
          }
        }
      }
    }

    .loading-artist {
      @extend %flex-center;
      height: 100%;
    }
  }
}
