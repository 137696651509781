@import '../../styles/shared.scss';

.chat-outer-container .chat-inner-container .container.chat-search-container {
  width: 20%;
  max-width: 380px;
  flex: 1;
  margin-right: 16px;
  padding: 30px;
  overflow: auto;

  @include md-down {
    margin-right: 0;
    padding: 16px 12px;

    &.expanded-on-mobile {
      position: absolute;
      z-index: 2001;
      min-height: 100%;
      max-height: 100%;
      min-width: 100%;
    }
    &.collapsed-on-mobile {
      max-width: 92px;
      input {
        &::placeholder {
          font-size: 12px;
        }
      }

      .potential-partners-separator-container {
        display: none;
      }

      .chat-cell {
        justify-content: center;

        img {
          margin-right: 0;
        }
        .chat-cell-data,
        .chat-time {
          display: none;
        }
      }
    }
  }

  &.is-showing-filters-or-sort-panel {
    overflow: hidden;
  }

  &.searching {
    .chat-cell {
      opacity: 0.2;
    }

    .section .section-header-container {
      opacity: 0.1;
    }
  }

  .search-section {
    @extend %flex-row-middle;
    gap: 12px;
    .search-result-input-container {
      position: relative;
      margin-bottom: 8px;
      flex: 1;

      .clear-search {
        @extend %flex-center;
        position: absolute;
        right: 8px;
        top: 0;
        height: 100%;
        pointer-events: none;

        svg {
          pointer-events: auto;
          cursor: pointer;
        }
      }

      input {
        @extend %input-shared;
        flex: 1;
        resize: none;
        border-radius: 10px;
        width: 100%;

        @include md {
          font-size: 14px;
          padding: 12px 16px;
        }
      }
    }

    .filters-control,
    .sort-control {
      @extend %flex-row-middle;
      @extend %btn-basic;
      gap: 4px;

      .label {
        font-size: 12px;
      }

      @include md-down {
        display: none;
      }

      svg {
        font-size: 12px;
        color: $medium;
        cursor: pointer;
      }
    }
  }
  .sections {
    @extend %flex-col;
    gap: 16px;

    .section {
      .section-header-container {
        @extend %flex-row-spread;
        transition: opacity 0.2s ease-in-out;

        @include md-down {
          display: none;
        }

        &.clickable {
          @extend %btn-basic;
        }

        .section-header {
          .main {
            @extend %flex-row-middle;
            gap: 8px;
            font-size: 20px;
            .header {
              font-size: 20px;
              line-height: 20px;
              color: $darkest;
              font-weight: bold;
            }
            .count {
              font-size: 10px;
              line-height: 9px;
              color: $dark;
              background-color: $lightest;
              border-radius: 8px;
              padding: 4px 6px;
              font-weight: bold;
            }
          }
          .sort-by-label {
            @extend %btn-basic;
            font-size: 10px;
            color: $medium;
            margin-top: 4px;

            svg {
              margin-left: 2px;
              font-size: 8px;
            }
          }
        }

        .section-actions {
          padding-top: 1px;
          .switch-container {
            @extend %flex-row-middle;

            .switch-label {
              font-size: 12px;
              color: $medium;
              margin-right: 8px;
            }
          }
        }
      }

      &.filters-section {
        margin-top: -8px;
      }
    }
  }

  .is-filtering-or-sorting-overlay {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 180%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .filters-section {
    position: relative;

    @include md-down {
      display: none;
    }

    .active-filters {
      @extend %flex-row;
      flex-wrap: wrap;
      gap: 8px;

      .active-filter {
        @extend %flex-row-middle-spread;
        @extend %btn-basic;
        padding: 9px 12px;
        background-color: $darkest;
        border-radius: 10px;
        max-width: 100%;
        color: white;

        .title {
          flex: 1;
          font-size: 12px;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .count {
          @extend %flex-center;
          font-size: 11px;
          color: $medium;
          font-weight: bold;
          background-color: $offwhite;
          color: $darkest;
          border-radius: 24px;
          margin-left: 4px;
          padding: 2px 6px;
        }

        .remove-icn {
          font-size: 12px;
          color: white;
          margin-left: 8px;
        }
      }
    }
  }

  .results-section {
    @extend %flex-col;
    gap: 12px;

    .show-all-chats-while-filtered-to-new-container {
      @extend %flex-center;
      margin: 8px 0 0;

      .toggle-button {
        @extend %btn-basic;
        text-align: center;
        font-size: 12px;
        transform: translateY(-6px);
        color: $medium;
        text-transform: uppercase;
        background: white;
        padding: 0 8px;

        svg {
          margin-left: 4px;
          margin-top: -1px;
          font-size: 12px;
        }
      }
    }

    .potential-partners-separator-container {
      @extend %flex-center;
      border-top: $border-offwhite;
      margin: 16px 0 0;

      .potential-partners-separator {
        text-align: center;
        font-size: 10px;
        transform: translateY(-6px);
        text-transform: uppercase;
        color: $light;
        background: white;
        padding: 0 8px;
      }
    }

    .chat-cell {
      @extend %flex-row-middle;
      cursor: pointer;
      padding: 12px 12px;
      border-radius: 10px;
      transition: opacity 0.2s ease-in-out;
      position: relative;
      border: 1px solid $white;

      &.new {
        // background: $prettymuchwhite;
        border: 1px solid $darkest;
      }

      &.active {
        background: $prettymuchwhite;
        border: 1px solid $white;
      }

      &.dismissed {
        opacity: 0.4;
        border: $border-white;
      }

      &.unsubscribed-brand {
        filter: blur(2px);
        pointer-events: none;
      }

      &.brand {
        img {
          object-fit: contain;
        }
      }

      img {
        width: 44px;
        height: 44px;
        min-width: 44px;
        min-height: 44px;
        border-radius: 50%;
        margin: 2px 12px 2px 0;
        object-fit: cover;

        @include md-down {
          margin-left: 0;
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      .score {
        position: absolute;
        bottom: 4px;
        left: 4px;
        font-size: 10px;
        color: $light;
      }

      .action-buttons {
        position: absolute;
        top: 6px;
        right: 6px;

        .action-button {
          > svg {
            font-size: 11px;
            color: transparent;
            &.overlay-open {
              color: $dark;
            }
          }
        }
      }

      .show-on-hover {
        display: none;
      }

      &:hover {
        .action-button {
          svg {
            color: $dark;
          }
        }
        .show-on-hover {
          display: flex;
        }
        .hide-on-hover {
          display: none;
        }
      }

      .chat-cell-data {
        flex: 1;
        overflow: hidden;

        .name {
          font-family: $font-header;
          font-size: 17px;
          font-weight: bold;
          color: $darker;
        }

        .last-message,
        .partner-message {
          font-size: 12px;
          line-height: 1.4;
          color: $medium;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-top: 2px;
        }
      }
      .chat-secondary {
        @extend %flex-row-middle;
        padding-left: 4px;
        font-size: 12px;
        color: $medium;
        gap: 2px;
        position: relative;

        @include md-down {
          display: none;
        }

        svg {
          font-size: 13px;
          transition: color 0.2s ease-out;

          &:hover {
            color: $dark;
          }
        }

        .secondary-actions {
          @extend %flex-row-middle;
          gap: 8px;
          position: absolute;
          right: 100%;
          top: -8px;
          background-color: white;
          border: $border-lighter;
          padding: 6px 12px;
          border-radius: 4px;
          color: $dark;

          .action {
            @extend %btn-basic;
          }
        }

        .select-list-btn {
          @extend %flex-center;
          @extend %btn-basic;
          margin-left: 2px;
          color: $dark;
          position: relative;

          svg {
            font-size: 14px;
          }

          &.active {
            color: $darkest;
          }
        }
      }

      .new-badge {
        margin-left: 4px;
        font-size: 9px;
        line-height: 8px;
        font-weight: bold;
        position: absolute;
        top: 8px;
        background: transparent;
        border-radius: 24px;
        border: $border-darkest;
        background-color: $darkest;
        color: white;
        padding: 3px 5px;
        right: 8px;
      }
    }
    .empty-results-message {
      .empty-message {
        font-size: 12px;
        padding: 12px 8px;
        color: $medium;
      }
      .empty-results-send-message {
        @extend %btn-outline-new;
        font-size: 12px;
        border: $border-lighter;
        text-transform: uppercase;
        margin: 0 8px;
      }
    }

    .paging-btns {
      @extend %flex-center;
      gap: 4px;
      margin-top: 12px;

      .paging-btn {
        @extend %label;
        @extend %btn-offwhite-new;
        padding: 4px 10px;
        font-size: 9px;
      }
    }
  }
}
