@import '../../../styles/shared.scss';

.opportunity-plan-user-container {
  @extend %flex-row-middle;
  position: relative;

  @include md-down {
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
  }

  &.already-sent {
    .already-sent-overlay {
      @extend %flex-center;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      transform: translate(-6px, -6px);
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      pointer-events: none;

      .already-sent-text {
        @extend %label;
        background-color: $white;
        padding: 6px 12px;
        border-radius: 4px;
        font-size: 10px;
        color: $darkest;
      }
    }

    .about-section {
      .metadata-container {
        .name {
          color: $light;
        }
        .reasoning,
        .metadata {
          color: $lighter;
        }
      }
    }
  }

  .about-section {
    @extend %flex-row-middle;
    gap: 30px;
    flex: 1;

    .image-container {
      width: 64px;
      height: 64px;
      min-width: 64px;
      min-height: 64px;
      border-radius: 10px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .metadata-container {
      @extend %flex-col;
      align-items: flex-start;
      max-width: 320px;

      .name {
        @extend %btn-basic;
        font-size: 20px;
        font-weight: bold;
        color: $darkest;
        font-family: $font-header;
      }

      .reasoning {
        font-size: 12px;
        line-height: 1.4;
        color: $dark;
      }

      .metadata {
        font-size: 12px;
        line-height: 1.4;
        color: $dark;
      }

      .disclaimer {
        @extend %flex-row-middle;
        gap: 8px;
        background-color: $prettymuchwhite;
        border: $border-lightest;
        border-radius: 4px;
        padding: 4px 8px;
        font-size: 10px;
        line-height: 1.4;
        color: $medium;
        margin-top: 4px;

        svg {
          font-size: 9px;
        }
      }
    }
  }

  .stats-and-actions {
    @extend %flex-row-middle;
    gap: 24px;

    .section-stats {
      @extend %flex-row;
      gap: 30px;
      margin-top: 8px;

      .section-stat {
        @extend %flex-col-center;
        gap: 4px;
        flex: 1;
        max-width: 65px;
        width: 100px;

        &.editable {
          @extend %btn-basic;
        }

        &.featured {
          position: relative;

          .value {
            color: $primary;
            font-weight: bold;
          }
          .label {
            color: $primary;
            font-weight: bold;
            white-space: nowrap;
          }

          .background-highlight {
            left: calc(24px / -2);
            top: calc(24px / -2);
            height: calc(100% + 24px);
            width: calc(100% + 24px);
            background-color: $primaryLightest;
            opacity: 0.5;
            border-radius: 10px;
            position: absolute;
          }
        }

        .value {
          @extend %flex-row;
          font-size: 18px;
          line-height: 18px;
          min-height: 18px;
          color: $darkest;
          position: relative;
          font-weight: 500;
          white-space: nowrap;

          &.small {
            font-size: 14px;
            line-height: 14px;
          }

          &.empty {
            color: $light;
            font-size: 18px;
            font-weight: normal;
          }

          .loader {
            @extend %flex-center;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
          }
        }

        .label {
          font-size: 10px;
          color: $dark;
          opacity: 1;
          white-space: nowrap;

          &.empty {
            color: $light;
          }

          &.fetching {
            color: $lighter;
          }

          .edit-icon {
            font-size: 8px;
            margin-left: 4px;
          }
        }
      }
    }

    .plan-user-actions {
      @extend %flex-row-middle;
      gap: 12px;

      .action {
        @extend %btn-basic;
        font-size: 14px;
        color: $dark;

        &.disabled {
          svg {
            color: $lightest !important;
            cursor: default;
          }
        }
      }
    }
  }
}
