@import '../../styles/shared.scss';

.brand-partner-outer-container {
  @extend %flex-col;
  width: 33.3%;
  padding: 12px 16px 36px;

  @include lg-down {
    width: 50%;
    padding: 12px 12px 24px;
  }

  @include sm-down {
    width: 100%;
    padding: 0 0 48px;
  }

  .brand-partner-inner-container {
    @extend %flex-col;
    flex: 1;
    background: white;
    border-radius: 10px;
    overflow: hidden;

    .image-container {
      @extend %flex-center;
      height: 160px;
      width: 100%;
      background: $darkest;

      img {
        @extend %animation-fade;
        animation-duration: 800ms;
        height: 50%;
        width: 50%;
        object-fit: contain;
        filter: brightness(0) invert(1);
      }
    }

    .main-container {
      @extend %flex-col-spread;
      padding: 12px;
      flex: 1;

      .tags {
        @extend %flex-row;
        flex-wrap: wrap;

        .tag {
          border-radius: 2px;
          padding: 2px 6px;
          background: $offwhite;
          font-size: 10px;
          text-transform: uppercase;
          color: white;
          color: $darker;
          border: $border-lighter;
          white-space: nowrap;
          font-weight: bold;
          margin-bottom: 4px;

          &:not(:last-child) {
            margin-right: 4px;
          }
        }
      }
      .title {
        font-size: 18px;
        line-height: 20px;
        color: $darker;
        font-weight: bold;
        margin-top: 4px;
      }
      .domain {
        margin-top: -4px;
        a {
          font-size: 10px;
          color: $dark;
          font-weight: bold;
        }

        svg {
          font-size: 7px;
          margin-left: 2px;
        }
      }

      .description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        font-size: 12px;
        margin-top: 4px;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;

        &.all {
          -webkit-line-clamp: 40;
        }
      }
      .read-more {
        @extend %btn-basic;
        font-size: 9px;
        text-transform: uppercase;
        color: $dark;
        margin-top: 4px;
      }

      .actions {
        @extend %flex-row;
        margin-top: 32px;

        .action {
          @extend %btn-dark-new;
          text-transform: uppercase;
          flex: 1;
          font-size: 13px;

          &:not(:first-child) {
            margin-left: 8px;
          }

          &.basic {
            @extend %btn-text-new;
          }
        }
      }
    }
  }
}
