@import '../../styles/shared.scss';

.subscriptions-outer-container {
  @extend %flex-col;
  width: 100%;
  position: relative;
  font-family: $font-body;
  background: $prettymuchwhite;
  min-height: 100vh;
  padding-bottom: 120px;

  .subscriptions-inner-container {
    position: relative;
    padding-top: 48px;

    .capped-width {
      max-width: 960px;
      margin: 0 auto;
    }

    .sections {
      @extend %flex-col-center;
      gap: 64px;

      @include md-down {
        gap: 32px;
      }

      .section {
        @extend %flex-col;
        width: 100%;
        max-width: 960px;
        margin: 0 auto;

        @include lg-down {
          padding: 0 20px;
        }

        .section-header-container {
          margin-bottom: 30px;

          .badge {
            @extend %label;
            font-size: 10px;
            margin-bottom: 10px;
          }

          .header {
            font-family: $font-header;
            font-size: 30px;
            color: $darkest;
            font-weight: bold;

            @include md-down {
              font-size: 24px;
            }
          }
        }

        .tiles {
          @extend %flex-col;
          gap: 26px;

          @include md-down {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
}
