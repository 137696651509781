@import '../../styles/shared.scss';

.lookbook-item-outer {
  background-color: white;
  border-radius: 16px;
  height: 100%;

  .lookbook-item-inner {
    @extend %flex-col;
    position: relative;
    width: 100%;
    height: 100%;

    .hidden {
      opacity: 0;
    }

    .shopify-icon-container {
      position: absolute;
      top: 12px;
      right: 36px;

      img,
      svg {
        position: relative;
        color: $lighter;
        font-size: 16px;
        opacity: 1;
        z-index: 1;
        height: 16px;
        width: 16px;
        filter: grayscale(100%);

        &.error {
          color: $error;
          filter: none;
        }

        &.warn {
          color: $gold;
          filter: none;
        }
      }
    }

    .sortable-handle {
      position: absolute;
      top: 12px;
      right: 12px;
      color: $lighter;
      cursor: grab;
      font-size: 16px;
      opacity: 1;
      z-index: 1;
    }

    .primary-container {
      position: relative;
      aspect-ratio: 1;
      width: 100%;

      .creator-count {
        @extend %label;
        position: absolute;
        bottom: 8px;
        right: 8px;
        color: white;
        background-color: $primary;
        padding: 4px 8px;
        border-radius: 12px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 9px;
      }
    }

    .data-container {
      @extend %flex-col;
      padding: 20px;
      flex: 1;
      justify-content: space-between;

      .price {
        @extend %label;
        font-weight: normal;
        font-size: 0.7rem;
        color: $medium;
      }

      .title-container {
        @extend %flex-row;
        width: 100%;
        font-size: 20px;
        line-height: 24px;
        color: black;
        text-decoration: none;
        cursor: pointer;
        position: relative;
        white-space: normal;
        font-weight: 600;
        padding-right: 12px;

        .max-orders {
          font-size: 0.8rem;
          color: $medium;
        }

        .external-link {
          position: absolute;
          top: -8px;
          right: -8px;
          padding: 4px;
          width: max-content;

          &.hideFromPublicCatalog {
            opacity: 0.2;
            cursor: not-allowed;
          }

          svg {
            font-size: 12px;
            color: $darkest;
          }
        }
      }

      .price {
        margin: 4px 0 12px;
      }

      .description {
        font-size: 0.8rem;
        color: $medium;
        margin-bottom: 1rem;

        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .siblings-container {
        margin-top: 12px;

        .siblings-container-section-header {
          @extend %label;
          font-size: 0.6rem;
          margin-top: 1rem;
          margin-bottom: 5px;
        }

        .item-siblings {
          @extend %flex-row;
          gap: 8px;
          flex-wrap: wrap;

          .item-sibling {
            @extend %btn-basic;
            display: flex;
            align-items: center;
            padding: 6px 12px;
            border-radius: 24px;
            font-size: 0.7rem;
            transition: all 0.1s ease-out;
            border: $border-lightest;
            border-width: 0.5px;
            color: $medium;

            &.unavailable {
              border-color: $white;
              color: $lightest;
              text-decoration: line-through;
            }

            &.active {
              border-color: $darkest;
              background: $darkest;
              color: $prettymuchwhite;
              font-weight: bold;
            }

            &.unavailable.active {
              background-color: $lightest;
              color: $lighter;
              border-color: $lightest;
              text-decoration: line-through;
            }

            &.disconnected {
              border-color: $error;
              color: $error;

              svg {
                color: $error;
              }

              &.active {
                background-color: $error;
                color: $prettymuchwhite;

                svg {
                  color: $prettymuchwhite;
                }
              }
            }

            &.show-more {
              border: none;
            }

            svg {
              margin-left: 6px;
            }
          }
        }
      }

      .actions {
        @extend %flex-row-middle;
        gap: 12px;
        margin-top: 24px;

        .primary {
          @extend %btn-basic;
          @extend %label;
          padding: 12px 16px;
          border-radius: 6px;
          font-size: 12px;
          background-color: $darkest;
          color: white;
          text-align: center;

          &.active {
            background-color: $primary;
          }

          &.disabled {
            background-color: $lightest;
            color: $light;
            pointer-events: none;
          }
        }

        .secondary {
          @extend %btn-basic;
        }
      }
    }

    .item-disconnected-overlay {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-color: rgba(241, 220, 225, 0.9);
      border-radius: 16px;
      backdrop-filter: blur(3px);
    }

    .item-disconnected-options-container {
      position: absolute;
      z-index: 2;
      width: 100%;
      padding: 30px 20px;

      .item-disconnected-options {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 10px;
        background: $white;
        border-radius: 16px;
        color: $darkest;
        font-size: 12px;
        gap: 10px;

        .header {
          @extend %label;
          color: $error;
          font-size: 12px;
          font-weight: bold;
        }

        .description {
          text-align: center;
          color: $dark;

          span {
            font-weight: bold;
          }
        }

        svg {
          color: $error;
          font-size: 36px;
        }
      }

      .actions {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 16px;
        width: 80%;

        .action-btn {
          @extend %btn-basic;
          @extend %label;
          padding: 12px 16px;
          border-radius: 4px;
          font-size: 12px;
          background-color: $darkest;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &.secondary {
            background-color: $white;
            border: 1px solid $darkest;
            color: $darkest;
          }

          &.tertiary {
            background-color: $error;
            border: 1px solid $error;
            color: $white;
          }

          svg {
            font-size: 16px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
