@import '../../../styles/shared.scss';

.mention-result-stat-details {
  // Animate in
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-name: slideUp;

  // Make a radial background to show text better
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  padding: 12px;
  width: 100%;
  pointer-events: auto;
  overflow-y: scroll;
  z-index: 2;

  &.square {
    @include md {
      max-height: 120px;
    }
    @include lg {
      max-height: 148px;
    }
    .groups {
      gap: 8px;
    }
  }

  .groups {
    @extend %flex-col;
    gap: 12px;

    .group {
      cursor: default;

      .header {
        @extend %label;
        font-size: 10px;
        margin-bottom: 2px;
        color: $dark;
      }
      .stats {
        @extend %flex-col;
        gap: 2px;

        a {
          font-weight: bold;
        }

        .stat {
          font-size: 12px;
          color: $darkest;
          display: inline;
        }

        .stat-parts {
          @extend %flex-row-middle;
          gap: 3px;

          .part {
            font-size: 12px;
            color: $dark;

            &.number {
              font-weight: bold;
              color: $darkest;
            }

            &.blur {
              filter: blur(3px);
            }
          }
        }
        a {
          svg {
            margin-left: 4px;
            font-size: 8px;
          }
        }
      }
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(10px);
  }
}
