@import '../../../styles/shared.scss';

.popular-features {
  @extend %flex-row;
  flex-wrap: wrap;
  gap: 16px;
}

// In order to maintain on sorting
.popular-feature {
  @extend %flex-center;
  background-color: black;
  border: $border-medium;
  border-radius: 4px;
  width: calc(50% - 16px / 2);
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;

  &.hidden {
    opacity: 0.5;
  }

  &:hover {
    border: $border-white;
  }

  .title {
    @extend %label;
    text-align: center;
    color: white;
    font-size: 16px;
    overflow: hidden;
    text-decoration: none;
    text-align: center;
    width: 100%;
  }
}
