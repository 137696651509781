@import '../../styles/shared.scss';

.catalog-creator-rate-row {
  display: flex;
  border-radius: 8px;
  background: $white;
  padding: 8px;

  .profile-img-wrapper {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    padding: 2px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    &.skeleton {
      @extend %animation-skeleton-fade;
      background: $prettymuchwhite;
      border-radius: 50%;
    }
  }

  .rate-content {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin-left: 12px;
  }

  .rate-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;

    &.skeleton {
      width: 100%;
    }

    .rate-label {
      font-family: $font-header;
      color: $black;
      font-size: 18px;
      font-weight: 600;

      &.skeleton {
        @extend %animation-skeleton-fade;
        background: $prettymuchwhite;
        width: 30%;
        height: 20px;
      }
    }

    .rate-meta {
      display: flex;
      color: $light;
      font-size: 12px;

      .bullet {
        margin: 0 4px;
      }

      .view-talent {
        cursor: pointer;
        text-decoration: underline;
      }

      &.skeleton {
        @extend %animation-skeleton-fade;
        background: $prettymuchwhite;
        width: 20%;
        height: 14px;
      }
    }
  }

  .rate-action-container {
    display: flex;
    align-items: center;
    gap: 20px;

    .rate-container {
      .rate {
        display: flex;
        justify-content: flex-end;
        font-family: $font-header;
        font-size: 22px;
        color: $black;

        span {
          margin-left: 2px;
        }
      }

      .rate-label {
        margin-top: 4px;
        width: 100%;
        text-align: left;
        font-size: 12px;
      }
    }

    .rate-action {
      cursor: pointer;
      letter-spacing: 1px;
      font-weight: bold;
      font-size: 12px;
      text-decoration: underline;
      text-transform: uppercase;

      &.disabled {
        cursor: default;
        color: $lighter;
      }
    }
  }
}
