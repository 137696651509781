@import '../../styles/shared.scss';

.discover-outer-container {
  background-color: $darkest;
  font-family: $font-body-new;
  min-height: calc(100vh + 320px);
  padding-top: 320px; // To get dark background to hold even on pull down
  margin-top: -320px; // To get dark background to hold even on pull down
  padding-bottom: 320px;

  .discover-inner-container {
    @extend %flex-row;
    margin: 64px auto;
    gap: 48px;
    position: relative;
    width: calc(100% - 24px);
    min-width: 800px;

    @include sm {
      width: calc(100% - 32px);
      gap: 16px;
    }

    @include md {
      width: calc(100% - 48px);
      gap: 24px;
    }

    @include lg {
      width: calc(100% - 64px);
      gap: 36px;
    }

    @include xl {
      max-width: 1240px;
      width: calc(100% - 64px);
      gap: 48px;
    }

    .discover-sidebar-container {
      flex: 1;
      min-height: 100vh;
      max-width: 280px;
      width: 280px;
    }

    .discover-main-container {
      flex: 1;
      min-height: 100vh;
      position: relative;
    }
  }
}
