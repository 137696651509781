@import '../../styles/shared.scss';

.collection-preview-container {
  @extend %flex-row;
  max-width: 100%;
  position: relative;
  max-width: 100%;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  padding-bottom: 40px;
  min-height: 100px;

  &.fluid {
    width: 100%;
    .products-shelf-container {
      .collection-preview-product-image-preview {
        @include md {
          min-width: calc(100% / 3);
        }
        @include lg {
          min-width: calc(100% / 4);
        }
      }
    }
  }

  &:hover {
    .collection-preview-image.full {
      margin-top: -4px;
    }
  }

  width: 100%;
  @include sm {
    width: 100%;
  }
  @include md {
    width: 640px;
  }
  @include lg {
    width: 800px;
  }

  &.deleting {
    opacity: 0.3;
  }

  .products-shelf-container {
    @extend %flex-row;
    z-index: 1;
    align-items: flex-start;
    background: white;
    padding-top: 12px;
    overflow: hidden;
    width: 100%;

    &.private,
    &.archived {
      .tag-container {
        @extend %flex-center;
        position: absolute;
        top: 92px;
        width: 100%;
        z-index: 2;
        pointer-events: none;

        .notification-tag {
          text-align: center;
          white-space: nowrap;
          background: white;
          padding: 4px 12px;
          border-radius: 4px;
          font-weight: bold;
          color: $darker;
          box-shadow: $shadow-dark;
        }
      }
    }

    .collection-preview-product-image-preview {
      @extend %flex-row;
      height: 220px;
      align-items: flex-start;
      justify-content: center;
      min-width: calc(100% / 2);

      @include sm {
        min-width: calc(100% / 3);
      }
      @include md {
        min-width: calc(640px / 3);
      }
      @include lg {
        min-width: calc(800px / 4);
      }

      &:nth-child(1) {
        .collection-preview-image {
          transition-delay: 0s;
        }
      }
      &:nth-child(2) {
        .collection-preview-image {
          transition-delay: 0.1s;
        }
      }
      &:nth-child(3) {
        .collection-preview-image {
          transition-delay: 0.2s;
        }
      }
      &:nth-child(4) {
        .collection-preview-image {
          transition-delay: 0.3s;
        }
      }

      &.smooth {
        @extend %animation-slide-up;
        animation-duration: 800ms;

        .collection-preview-image {
          @extend %animation-fade;
          animation-duration: 800ms;
        }
      }

      &.hidden {
        .collection-preview-image {
          opacity: 0.4;
        }
      }

      .collection-preview-image {
        width: 82%;
        height: 200px;
        padding-bottom: 30px;
        transition: all 0.3s ease-in-out;
        object-fit: contain;

        @include md {
          height: 200px;
          padding-bottom: 0px;
        }

        &.empty {
          @extend %flex-center;
          @extend %skeleton-box;
          height: 200px;
          background: $whynotusewhite;
          text-align: center;
          border-radius: 10px;
          font-size: 80px;
          font-weight: 300;
          font-family: $font-header;
          color: $lightest;
          padding: 24px 0;

          @include md {
            height: 200px;
          }
        }

        &.invisible {
          opacity: 0;
        }
      }
    }
  }

  .collection-preview-description {
    background: rgba(255, 255, 255, 1);
    width: 100%;
    height: 120px;
    padding: 12px 24px;
    position: absolute;
    left: 0;
    bottom: 0px;
    z-index: 1;
    box-shadow: $shadow-dark;

    @include md {
      height: 100px;
    }

    .collection-preview-content {
      position: absolute;
      left: 24px;
      width: calc(100% - 24px);
      z-index: 4;

      .collection-preview-tag {
        font-size: 14px;
        font-weight: bold;
        color: $darker;
      }

      .collection-preview-header {
        @extend %flex-row-spread;
        width: 100%;
        color: $darkest;
        font-size: 20px;
        font-weight: bold;
        font-family: $font-header;
        line-height: 1.1;
        padding: 4px 0;

        h2 {
          font-size: 20px;
          margin: 0;
          padding: 0;
          max-width: 90%;
        }

        .collection-private-icon {
          margin-left: 12px;
          color: $offwhite;
          cursor: pointer;

          &.private {
            color: $darkest;
          }

          &:hover  {
            opacity: 0.8;
          }
        }

        .icn-show {
          position: relative;
          cursor: pointer;
          color: $medium;
          position: absolute;
          right: 0;
          top: 0;
          font-size: 16px;

          .show-action-items {
            padding: 0 8px 16px;
          }
        }
      }

      .collection-preview-subheader {
        color: $medium;
        font-size: 14px;
        font-weight: normal;
        font-family: $font-body;

        &.featured {
          color: $darker;
          margin-top: 2px;
          .username {
            text-decoration: underline;
          }
        }
      }
    }

    .collection-preview-description-shade {
      position: absolute;
      width: calc(100% + 30px);
      height: calc(100% + 15px);
      background: rgba(255, 255, 255, 0.9);
      top: 0;
      left: -15px;
    }
  }
}
