@import '../../styles/shared.scss';

.snapshop-outer-container {
  @extend %flex-col-center;
  background: $offwhite;
  font-family: $font-body-new;
  min-height: 100vh;
  padding: 48px 16px;

  .snapshop-inner-container {
    @extend %flex-col;
    width: 100%;
    max-width: 1024px;
    padding: 12px 16px;
    margin-top: 32px;
    position: relative;
    padding-bottom: 40px;
  }
}
