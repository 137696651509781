@import '../../styles/shared.scss';

.fulfillment-modal-outer-container {
  .fulfillment-modal-inner-container {
    .fulfillment-modal-content {
      @extend %flex-col;
      max-width: 800px;
      max-height: 50vh;
      overflow: scroll;

      .title-container {
        margin-bottom: 24px;
        .title {
          font-size: 28px;
          font-family: $font-header;
          font-weight: bold;
          color: $darkest;
          margin-bottom: 5px;
        }
        .subtitle {
          font-size: 0.8rem;
          color: $light;
        }
      }

      .sections {
        @extend %flex-col;
        gap: 40px;

        .section-header-container {
          margin-bottom: 4px;
          .header {
            font-size: 0.8rem;
            font-weight: bold;
            margin-bottom: 10px;
          }
        }

        .address-section {
          .address {
            .user-name {
              font-weight: bold;
              margin-bottom: 5px;
            }
          }
        }

        .note-section {
          font-size: 14px;
          padding: 20px;
          border-radius: 5px;
          background-color: $prettymuchwhite;

          .header {
            font-size: 0.8rem;
            font-weight: bold;
            margin-bottom: 10px;
          }

          .additional-note {
            font-size: 14px;
            padding: 10px;
            border-radius: 5px;
            @extend %flex-row;
            gap: 15px;

            .profile-picture {
              @extend %flex-col;
              border-radius: 50%;
              overflow: hidden;
              position: relative;
              height: 30px;
              width: 30px;

              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
              }

              .initials {
                display: grid;
                place-content: center;
                height: 100%;
                width: 100%;
                background-color: $lighter;
                color: $medium;
                font-size: 0.8rem;
                font-weight: bold;
                font-family: $font-header;
              }
            }

            .text {
              flex: 1;
              font-weight: 400;
              line-height: 1.3;
              color: $dark;
              font-size: 0.75rem;
              max-width: 350px;
            }
          }
        }

        .items-section {
          .items {
            @extend %flex-col;
            gap: 15px;
            margin: 15px 0px;

            .item {
              @extend %flex-row;
              gap: 15px;

              img {
                height: 60px;
                width: 60px;
                object-fit: cover;
                border-radius: 8px;
              }

              .text {
                @extend %flex-col;
                justify-content: center;

                .title {
                  @extend %flex-row;
                  font-size: 16px;
                  line-height: 18px;
                  font-weight: bold;

                  a {
                    @extend %flex-row;
                    align-items: center;

                    svg {
                      @extend %btn-basic;
                      width: 8px;
                      height: 8px;
                      color: $light;
                      margin-left: 5px;
                    }
                  }
                }

                .subtitle {
                  font-size: 0.8rem;
                  color: $light;
                }
              }
            }
          }
        }

        .form-section {
          @extend %flex-col;
          gap: 8px;

          .section-header {
            font-size: 0.8rem;
            font-weight: bold;
            margin-bottom: 10px;
          }

          input,
          textarea {
            @extend %input-shared;
            font-size: 0.7rem;

            &::placeholder {
              color: $light;
            }
          }
        }
      }
    }
    .actions {
      @extend %flex-col;
      gap: 12px;
      margin-top: 30px;
      bottom: 0;
      position: sticky;
      background-color: white;
      transform: translate(-24px, 24px);
      border-top: $border-lightest;
      width: calc(100% + 48px);
      padding: 24px;
      padding-top: 12px;
      background-color: $offwhite;

      .action {
        @extend %label;
        @extend %btn-basic;
        padding: 12px;
        font-size: 0.7rem;
        text-align: center;
        border-radius: 5px;

        &.primary {
          background-color: $darkest;
          color: white;
        }

        &.disabled {
          background-color: $lightest;
          color: $light;
          cursor: default;
        }

        &.secondary {
          background-color: white;
          border: $border-light;
        }
      }
    }
  }
}
