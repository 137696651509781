@import '../../styles/shared.scss';

.consult-outer-container {
  @extend %flex-col;
  width: 100%;
  font-family: $font-body;

  .consult-inner-container {
    @extend %flex-col;
    width: 100%;
    max-width: 800px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 0 24px;
    position: relative;

    @include md {
      padding: 0 16px;
    }

    .next-step-btns {
      @extend %flex-row-middle;
      margin: 32px auto 12px;
      flex-direction: column;

      @include md {
        flex-direction: row;
      }

      .btn {
        &:not(:first-child) {
          margin-top: 12px;
          @include md {
            margin-left: 12px;
            margin-top: 0;
          }
        }
      }

      .dark-btn,
      .add-element-btn,
      .view-public-btn,
      .submit-result-btn {
        @extend %btn-dark;
        position: relative;
        align-self: center;
        padding: 12px 36px;
        font-size: 16px;
        text-align: center;
        font-family: $font-body;
        letter-spacing: 0.5px;

        &.disabled {
          @extend %btn-disabled;
        }

        svg {
          margin: 0 0 -1px 8px;
        }

        .already-completed-alert {
          position: absolute;
          top: 100%;
          padding-top: 10px;
          color: $dark;
          font-size: 12px;
        }
      }

      .dark-btn {
        @extend %btn-dark;
      }

      .outline-btn {
        @extend %btn-outline;
        padding: 12px 36px;
      }

      .basic-btn {
        @extend %btn-text;
        padding: 0 12px;
      }
    }
  }

  .consult-disclaimer {
    @extend %flex-col;
    width: 100%;
    max-width: 800px;
    margin: 80px auto 120px;
    padding: 32px 24px 0;
    position: relative;
    font-size: 12px;
    color: $medium;
    line-height: 1.4;
    border-top: 2px solid $offwhite;

    @include md {
      padding: 16px 16px 0;
    }
  }
}
