@import '../../styles/shared.scss';

.code-result {
  background: $prettymuchwhite;

  &.needs-action {
    opacity: 1;
    border: 2px solid $primary;
  }
  .cell {
    &.name {
      @extend %link;
    }

    &.shopify-link {
      @extend %link;
    }

    &.action {
      @extend %btn-basic;
      color: $dark;
      position: relative;

      .loader {
        position: absolute;
      }
    }
  }
}
