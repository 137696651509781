@import '../../../styles/shared.scss';

.home-brand-features-outer-container {
  @extend %flex-col;
  justify-content: center;
  background-color: black;
  min-height: 85vh;

  .home-brand-features-inner-container {
    @extend %flex-col;
    align-items: center;
    gap: 24px;
    margin: 5vh 24px 5vh;
    color: white;

    // Match this to the header
    @include md-down {
      padding: 0 20px;
    }

    @include md {
      padding: 0 60px;
    }

    @include lg {
      padding: 0 40px;
    }

    @include xl {
      padding: 0 12px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    @include xxl {
      max-width: 1440px;
      padding: 0;
      margin: 5vh auto 5vh;
    }

    .features-content {
      order: 3;

      @include xl {
        order: 1;
      }

      .features-title {
        @extend %header-fancy;
        color: white;
        line-height: 1.3;
        font-weight: bold;

        @include md-down {
          font-size: 28px;
        }

        @include md {
          font-size: 28px;
        }

        @include lg {
          font-size: 40px;
        }

        @include xl {
          font-size: 40px;
        }

        @include xxl {
          font-size: 52px;
        }
      }

      .features {
        @extend %flex-col;
        gap: 24px;
        flex-wrap: wrap;

        @include md {
          flex-direction: row;
        }

        .feature {
          @extend %btn-basic;
          @extend %flex-row-middle-spread;
          background-color: $prettymuchblack;
          padding: 20px;
          border-radius: 16px;
          width: 100%;
          font-size: 16px;

          @include md {
            width: calc(50% - 24px);
          }
          &:hover {
            background-color: $darkest;
            color: white;
          }
        }
      }
    }

    .feature-animation {
      order: 2;

      video {
        width: 100%;

        @include xl {
          width: calc(800px * 0.8);
          height: calc(900px * 0.8);
        }

        @include xxl {
          width: 800px;
          height: 900px;
        }
      }
    }
  }
}
