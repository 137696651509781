@import '../../../styles/shared.scss';

.mention-results-summary-container {
  .summary-items {
    @extend %flex-col;
    gap: 4px;

    .summary-item {
      @extend %flex-row-middle-spread;
      .summary-item-title {
        font-weight: normal;
        font-size: 12px;
        color: $dark;
        margin-right: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .summary-item-value {
        font-size: 10px;
        font-weight: bold;
        color: $darker;
      }
    }
  }
}
