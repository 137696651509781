@import '../../styles/shared.scss';

.partner-portal-code-outer {
  .partner-portal-code-inner {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    display: grid;
    place-content: center;
    aspect-ratio: 16/7;
    color: white;
    cursor: pointer;

    &:hover {
      .background-overlay {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    .background-image-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }
    }

    .background-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background-color: rgba(0, 0, 0, 0.5);
      transition: background-color 0.2s ease-out;
      z-index: 2;
    }

    .code-text-container {
      z-index: 3;
      @extend %flex-col;
      justify-content: center;

      img {
        margin: 0 auto;
        height: 80px;
        width: 130px;
        object-fit: contain;
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
      }

      .code-text {
        text-align: center;

        .code-text-header {
          @extend %flex-row;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          font-weight: 600;

          svg {
            height: 0.7rem;
            width: 0.7rem;
            margin-left: 5px;
          }
        }

        .code-text-subheader {
          @extend %flex-row;
          align-items: center;
          justify-content: center;
          font-size: 0.7rem;
          color: white;

          svg {
            height: 10px;
            width: 10px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
