@import '../../styles/shared.scss';

.report-outer-container {
  @extend %flex-col;
  width: 100%;
  position: relative;
  font-family: $font-body-new;
  background: $offwhite;
  min-height: 100vh;

  .report-inner-container {
    position: relative;
    max-width: 1024px;
    width: calc(100vw - 48px);
    margin: 120px auto;

    @include md-down {
      margin: 40px auto;
    }
  }
}
