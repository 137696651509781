@import '../../../styles/shared.scss';

.contract-link {
  @extend %flex-row-middle-spread;

  &:not(:first-child) {
    margin-top: 12px;
  }

  &.template {
    .main {
      @extend %flex-row-middle;
      flex: 1;

      .image-container {
        position: relative;
        border-radius: 4px;
        height: 40px;
        width: 40px;

        img {
        }

        .empty-img {
          height: 100%;
          width: 100%;
          margin: 0;
          background: $offwhite;
        }
      }

      .data {
        @extend %flex-col;
        flex: 1;
        margin-left: 8px;

        .title {
          font-size: 12px;
          font-weight: bold;
          color: $darker;
        }
        .url {
          font-size: 10px;
          color: $dark;

          svg {
            font-size: 10px;
            margin-left: 4px;
          }
        }
        .description {
        }
      }
    }
  }

  .main {
    @extend %flex-row-middle;
    flex: 1;

    .image-container {
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      height: 60px;
      width: 60px;

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }

      .empty-img {
        height: 60px;
        width: 60px;
        background: $offwhite;
      }

      &:hover {
        .edit-overlay {
          opacity: 1;
          pointer-events: auto;
        }
      }

      .edit-overlay {
        @extend %flex-center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-transform: uppercase;
        font-size: 9px;
        font-weight: bold;
        background: rgba(255, 255, 255, 0.8);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease-in-out;
        cursor: pointer;
      }
    }

    .data {
      @extend %flex-col;
      flex: 1;
      margin-left: 8px;
      color: $dark;

      textarea,
      input {
        @extend %input-inline;
        resize: none;

        &.title {
          font-size: 14px;
        }
        &.url {
          font-size: 12px;
        }
        &.description {
          font-size: 11px;
          font-weight: normal;
        }
      }
      .title {
        font-size: 14px;
        font-weight: bold;
        color: $darker;
      }

      a {
        @extend %link;
      }

      .url {
        font-size: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .description {
        margin-top: 4px;
        font-size: 11px;
        font-weight: normal;
      }
    }
  }
  .secondary {
    margin-left: 24px;

    .link-actions {
      @extend %flex-row-middle;
      color: $dark;
      font-size: 14px;

      .link-action {
        @extend %flex-row;
        @extend %btn-basic;

        &:not(:first-child) {
          margin-left: 8px;
        }
        &.edit {
        }
        &.delete {
        }
      }
    }

    .add-from-template-btn {
      @extend %btn-dark-new;
      font-size: 12px;
      svg {
        margin-right: 4px;
      }
    }
  }
}
