@import '../../styles/shared.scss';

.variant-siblings-container {
  position: relative;
  height: 100%;
  font-size: 12px;
  overflow: hidden;
  padding-bottom: 24px;

  @include md-down {
    display: none;
  }

  .header-row {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: $darkest;
    height: 25px;
  }

  .table-content {
    margin-top: 25px;
    height: 100%;
    overflow-y: scroll;

    .sibling-rate-container {
      .rate-input-action-container {
        display: flex;
      }
    }
  }

  .sibling-row {
    display: flex;
    height: 100px;
  }

  .table-cell {
    display: flex;
    align-items: center;
    padding: 6px;

    .product-title {
      margin-left: 10px;
    }

    &.lg {
      width: 220px;
    }

    &.md {
      width: 100px;
    }

    &.sm {
      width: 90px;
    }

    &.header {
      color: $white;
    }

    &.center {
      justify-content: center;
      text-align: center;
    }

    img {
      height: 80px;
      width: 80px;
      border-radius: 10px;
      object-fit: cover;
      aspect-ratio: 1;
    }
  }
}
