@import '../../styles/shared.scss';

.add-pin-modal-outer-container {
  @extend %flex-center;
  z-index: 11;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;

  .add-pin-modal-container {
    z-index: 11;
    font-family: $font-body;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;

    &.add-mode,
    &.edit-mode {
      @extend %flex-row-center;
      pointer-events: auto;
      opacity: 1;
      box-sizing: border-box;
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;
      padding: 0;

      @include md {
        top: 10%;
        height: 80%;
        max-height: 800px;
        left: 0;
        right: 0;
        width: 680px;
        // left: calc((100% - 680px) / 2);
      }

      @include lg {
        max-height: 880px;
        width: 900px;
        // left: calc((100% - 828px) / 2);
      }

      .add-pin-modal-inner-container {
        z-index: 2;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        overflow: auto;
        border-radius: 0;

        &.autocomplete-visible {
          overflow: hidden;
        }

        @include md {
          border-radius: 8px;
        }
      }
    }

    .add-pin-modal-inner-container {
      cursor: default;
      position: relative;
      background: white;

      &:hover {
        .product-image {
          color: $dark;
          svg {
            color: $dark;
          }
          .add-product-plus-btn {
            color: $dark;
          }
          &.is-adding {
            color: $lighter;
            svg {
              color: $lighter;
            }
          }
        }
      }

      .add-product-button {
        @extend %flex-center;
        position: absolute;
        top: -12px;
        right: -12px;
        width: 32px;
        height: 32px;
        padding: 12px;
        border-radius: 24px;
        background: $darkest;
        cursor: pointer;
        color: white;
        transition: all 0.1s ease-in-out;

        &:hover {
          opacity: 0.8;
        }
      }

      .quick-add-el {
        bottom: 6px;
        right: 8px;
        position: absolute;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 10px;

        svg {
          margin-right: 4px;
        }

        &.in-collection-loader {
          @extend %flex-center;
          height: 12px;
          width: 48px;
        }

        &.in-collection-label {
          @extend %flex-center;
        }

        &.quick-add-label {
          @extend %flex-center;
          color: $light;
          cursor: pointer;
          &:hover {
            opacity: 0.4;
          }
        }
      }

      .auto-complete-fade {
        background: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5; // AUTOCOMPLETE_FADE
      }

      .add-product-input-container-wrapper {
        @extend %flex-row;
        transition: all 0.3s ease-in-out;
        position: absolute;
        top: 0;
        width: 100%;
        height: 48px;
        top: -48px;
        background: $offwhite;
        box-shadow: none;
        z-index: 3;
        padding: 6px 0px;
        z-index: 6; // AUTOCOMPLETE_FADE

        &.visible {
          top: 0;
        }

        .icn {
          @extend %flex-center;
          padding: 0 12px;

          svg {
            color: $dark;
            cursor: pointer;
            font-size: 20px;

            &:hover {
              color: $darkest;
            }
          }

          &.go-back-icn {
            padding: 0 12px 0 12px;
          }

          &.close-icn svg {
            font-size: 24px;
          }
        }

        .add-product-input-container {
          position: relative;
          flex: 1;

          input {
            width: 100%;
            border-radius: 8px;
            padding: 0px 12px;
            background: white;
            height: 100%;
            border: none;
            outline: none;
            font-size: 16px;
            border: $border-white;

            &:focus {
              &.has-autocomplete {
                border: $border-lighter;
                border-bottom-left-radius: 0;
              }
            }
          }

          .enter-to-search-btn {
            position: absolute;
            top: 12px;
            right: 8px;
            font-size: 12px;
            color: $light;
            margin-left: 8px;
            margin-bottom: -1px;
          }

          .auto-complete-results {
            @extend %flex-col;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            border: $border-lighter;
            border-top: 0;
            position: absolute;
            top: calc(100% - 1px);
            min-width: 200px;
            left: 0;
            background: rgba(255, 255, 255, 1);
            cursor: pointer;

            .auto-complete-result {
              @extend %flex-row-middle-spread;
              max-width: 480px;
              padding: 2px 12px;
              color: $dark;
              font-size: 15px;

              .enter-to-select-btn {
                font-size: 8px;
                color: $light;
                display: none;
                margin-left: 8px;
                margin-bottom: -1px;
              }

              &:first-child {
                margin-top: 6px;
              }
              &:last-child {
                padding-bottom: 6px;
              }

              &:hover {
                background: $prettymuchwhite;
              }

              &.highlighted {
                font-weight: bold;
                background: $prettymuchwhite;
                color: $darker;

                .enter-to-select-btn {
                  display: flex;
                }
              }
            }
          }
        }
      }

      .product-results-outer-container {
        @extend %flex-col;
        transition: all 0.3s ease-in-out;
        cursor: default;
        overflow-y: auto;
        width: 100%;
        min-height: 100%;
        padding: 56px 16px;
        margin-bottom: 32px;
        top: 0;
        background: rgba(255, 255, 255, 1);
        position: absolute;
        z-index: 2;

        &.autocomplete-visible {
          overflow: hidden;
        }

        .merchant-payout-panel-container {
          @extend %flex-row-middle;
          align-self: stretch;
          border: $border-lighter;
          background: $offwhite;
          padding: 8px 16px;
          margin-bottom: 16px;

          .merchant-payout-logo {
            padding-right: 16px;
            max-height: 100px;
            max-width: 100px;
            object-fit: contain;
          }
        }
      }

      .search-loader-container {
        @extend %flex-center-vertical;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        pointer-events: none;

        .force-failure-btn {
          @extend %btn-offwhite;
          padding: 4px 12px;
          font-size: 12px;
          margin-top: -16px;
          pointer-events: auto;
        }
      }

      .empty-search-results-container {
        .empty-search-results-header {
          font-family: $font-header;
          margin: 8px 0;
          color: $medium;
          font-size: 24px;
        }

        .empty-search-results-example-subheader {
          font-family: $font-body;
          font-size: 16px;
          color: $medium;
          margin: 16px 0 4px;
        }

        .empty-search-results-example {
          font-family: $font-body;
          color: $medium;
          font-size: 18px;

          @include md {
            font-size: 16px;
          }
        }
      }
    }

    .adding-item-fade-overlay {
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.45);
      position: fixed;
      width: 100%;
      height: 100%;
      transition: opacity 0.35s ease-in-out;
      opacity: 0;
      pointer-events: none;

      &.active {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
