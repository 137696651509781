@import '../../../styles/shared.scss';

.about-member-outer {
  color: inherit;
  text-decoration: none;

  &:hover {
    .about-member-inner {
      .admin-controls-container {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .about-member-inner {
    position: relative;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.01);
    }

    .admin-controls-container {
      @extend %flex-row;
      gap: 8px;
      position: absolute;
      top: 12px;
      right: 12px;
      display: flex;
      z-index: 2;
      padding: 6px;
      border-radius: 4px;
      transition: background 0.2s ease-in-out;

      .sortable-handle {
        color: $lighter;
        cursor: grab;
        font-size: 13px;
        opacity: 1;
        z-index: 2;
      }

      .edit-member-button {
        @extend %btn-basic;
        color: $lighter;
        font-size: 13px;
        opacity: 1;
        z-index: 2;
      }
    }

    .person-image-container {
      position: relative;
      border-radius: 12px;
      height: 280px;
      aspect-ratio: 1;
      overflow: hidden;

      @include sm-down {
        height: 90vw;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        margin: 0;
        padding: 0;
      }

      .name {
        position: absolute;
        bottom: 20px;
        left: 20px;
        color: white;
        font-weight: bold;
        z-index: 2;
      }

      .image-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.5;
        z-index: 1;
      }
    }

    .data {
      text-align: center;

      p {
        margin: 0.5rem 0;
        padding: 0;
      }

      .badge {
        font-size: 0.8rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .title {
        font-size: 0.8rem;
      }
    }
  }
}
