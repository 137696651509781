@import '../../styles/shared.scss';

.collection-analytics-outer-container {
  @extend %flex-center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(3px);
  z-index: 2;
  top: 0;

  &.compact.mobile {
    opacity: 1;
    pointer-events: auto;
    .collection-analytics-date-selection {
      display: none;
    }
    .collection-analytics-details-cell {
      padding: 4px 12px !important;
    }
  }

  .loader {
    @extend %flex-center;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .collection-analytics-details-container {
    margin: 24px 32px;
    font-family: $font-body;
    min-width: 240px;
    max-width: 320px;

    .collection-analytics-date-selection {
      background: white;
      border-radius: 4px;
      margin-bottom: 8px;
      padding: 4px 12px;
      border: $border-lighter;
      box-shadow: $shadow-light;
      color: $darkest;
      position: relative;

      .header {
        @extend %flex-row-middle;
        cursor: pointer;
        font-size: 14px;

        &:hover {
          color: $dark;
        }

        .calendar-icon {
          font-size: 12px;
          margin-right: 8px;
        }

        .chevron-icon {
          font-size: 8px;
          margin-left: 4px;
        }
      }

      .range-options {
        padding-top: 8px;
        position: absolute;
        background: $white;
        border-radius: 4px;
        width: 100%;
        left: 0;
        padding: 12px;

        :not(:last-child) {
          border-bottom: $border-offwhite;
        }

        .range-option {
          cursor: pointer;
          padding: 4px 0;
          font-size: 14px;
          &:hover {
            color: $medium;
          }

          &.selected {
            color: $secondary-md;
          }
        }
      }
    }

    .collection-analytics-details-rows {
      background: white;
      border-radius: 4px;
      overflow: hidden;
      border: $border-lighter;
      box-shadow: $shadow-light;

      .collection-analytics-details-row {
        @extend %flex-row-middle;

        &:not(:last-child) {
          border-bottom: 1px solid $lighter;
        }

        &.bold {
          background: $secondary;
        }

        .collection-analytics-details-cell {
          @extend %flex-row-middle-spread;
          width: 50%;
          padding: 8px 12px;
          white-space: nowrap;
          font-size: 12px;

          &:not(:last-child) {
            border-right: 1px solid $lighter;
          }
        }
      }
    }
  }
}
