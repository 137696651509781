@import '../../styles/shared.scss';

.no-permission-outer-wrapper {
  .no-permission-wrapper {
    filter: blur(5px) !important;
    pointer-events: none;
  }
  .no-permission-msg-wrapper {
    @extend %flex-center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.2);

    .no-permission-msg {
      color: dark;
      font-size: 14px;
      font-weight: bold;
      max-width: 400px;
      width: 90vw;
      text-align: center;
    }
  }
}
