@import '../../../styles/shared.scss';

.user-lookbook-order-details-item-outer {
  height: 100%;

  .user-lookbook-order-details-item-inner {
    padding: 20px;
    background-color: white;
    border-radius: 16px;
    position: relative;

    .user-promoting-badge {
      @extend %label;
      @extend %flex-row;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(10px, -10px);
      align-items: center;
      gap: 5px;
      font-size: 0.7rem;
      color: white;
      background-color: $primary;
      border-radius: 5px;
      padding: 6px 10px;

      svg {
        height: 10px;
        width: 10px;
      }
    }

    .image-container {
      width: 100%;

      .lookbook-order-item-image {
        width: 100%;
        aspect-ratio: 1;
        object-fit: cover;
        border-radius: 10px;
        background-color: $light;
        color: $medium;
        display: grid;
        place-content: center;
        @extend %label;
      }
    }

    .details-container {
      @extend %flex-col;
      gap: 15px;
      margin-top: 10px;

      .title-container {
        position: relative;
        .title {
          font-size: 15px;
          white-space: normal;
          font-weight: 500;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
        }

        .subtitle {
          font-weight: normal;
          font-size: 0.7rem;
          color: $medium;
        }

        .external-link {
          color: $light;
          transition: color 0.2s ease-in-out;
          font-size: 12px;

          &:hover {
            color: $dark;
          }

          svg {
            height: 8px;
            width: 8px;
            margin-left: 5px;
          }
        }

        .not-available {
          font-size: 12px;
          color: $light;
        }
      }

      .estimated-value-container {
        .estimated-value {
          font-family: $font-header;
          font-weight: 600;
          font-size: 1.5rem;
          margin-bottom: 5px;
        }

        .estimated-value-subtitle {
          font-weight: normal;
          font-size: 0.7rem;
          color: $light;
        }
      }

      .generate-link-button {
        @extend %label;
        @extend %btn-basic;
        background-color: $primary;
        color: white;
        font-size: 0.7rem;
        padding: 9px 14px;
        border-radius: 5px;
        width: 100%;
        text-align: center;

        &.copyButton {
          background-color: black;
          color: white;
        }
      }
    }
  }
}
