@import '../../styles/shared.scss';

.result-pins-container {
  margin: 44px 0 128px;
  color: $dark;

  .result-pins-header {
    h1 {
      margin: 0;
      font-size: 24px;
      color: $darker;
    }
    h2 {
      margin: 0;
      font-size: 16px;
      font-weight: normal;
    }
  }

  .result-response-container {
    background: white;
    border: $border-lightest;
    margin-top: 32px;

    .edit-result-response {
      padding: 24px;
      textarea {
        @extend %input-inline;
        resize: none;
        width: 100%;
      }
    }

    .view-result-response {
      padding: 24px;
      white-space: pre-line;
      font-size: 14px;
      position: relative;
      padding-bottom: 68px; // To handle image

      @include md {
        padding-bottom: 24px;
      }

      .quote {
        position: absolute;
        z-index: 2;
        height: 25px;
        opacity: 0.3;

        &.first {
          top: -14px;
          left: -12px;
        }
        &.last {
          bottom: -14px;
          right: -12px;
          transform: rotateZ(180deg);
        }
      }

      .profile-image {
        position: absolute;
        border-radius: 50%;
        object-fit: cover;
        bottom: 8px;
        left: 8px;
        height: 44px;
        width: 44px;

        @include md {
          bottom: -48px;
          left: -54px;
          top: inherit;
          height: 52px;
          width: 52px;
        }
      }
    }
  }

  .result-pins {
    margin-top: 64px;

    .result-pin {
    }
  }
}
