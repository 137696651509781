@import '../../styles/shared.scss';

.discover-features-container {
  .loading {
    margin-top: 48px;
  }

  .discover-features {
    @extend %flex-col;
    gap: 48px;
  }

  .feature {
    &:hover {
      .sortable-handle {
        opacity: 1;
      }
      .discover-feature-actions-container {
        display: flex;
      }
    }

    .sortable-handle {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
    }

    .discover-feature-actions-container {
      display: none;
    }
  }
}
