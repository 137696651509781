@import '../../../styles/shared.scss';

.discover-list {
  @extend %flex-row-middle-spread;
  background-color: $darker;
  padding: 16px 16px;
  border-radius: 4px;
  position: relative;

  &:hover {
    .show-on-hover {
      opacity: 1;
    }
  }
  .show-on-hover {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .main {
    @extend %flex-row-middle;
    gap: 12px;

    .user-previews {
      @extend %flex-row-middle;
      width: calc(30px * 2 + 8px); // SIZING
      height: 30px;
      position: relative;

      .user-preview {
        position: absolute;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $black;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &:first-child {
          left: 0;
          background-color: $dark;
        }
        &:nth-child(2) {
          left: calc(30px / 2 + 8px / 2); // SIZING
          background-color: $darkest;
        }
        &:nth-child(3) {
          left: calc(30px + 8px); // SIZING
          background-color: $black;
        }
      }
    }

    .title-container {
      .count {
        @extend %label;
        color: $lighter;
        font-size: 9px;
        margin-bottom: 2px;
      }
      .title {
        font-family: $font-header;
        color: white;
        font-size: 18px;
        font-weight: bold;
      }
      .description {
        color: $offwhite;
        font-size: 12px;
      }
    }
  }

  .actions {
    @extend %flex-row-middle;
    gap: 8px;

    .action {
      font-size: 10px;

      &.icon {
        @extend %btn-basic;
        font-size: 16px;
        color: white;
      }

      &.primary {
        @extend %btn-primary-new;
      }
      &.secondary {
        @extend %btn-outline-new;
      }
    }
  }

  .secondary-actions {
    @extend %flex-row;
    position: absolute;
    top: 0;
    right: 4px;
    transition: opacity 0.2s ease-in-out;
    gap: 6px;

    svg {
      height: 100%;
      font-size: 12px;
    }
  }
  .sortable-handle svg {
    color: white !important;
  }
}
