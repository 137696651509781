@import '../../styles/shared.scss';

.sl {
  .action-row {
    @extend %flex-center;
    background: $offwhite;
    margin-top: 2px;
    border-top: 1px solid $lightest;
    border-bottom: $border-lightest;
    width: 100%;

    .action-row-inner-container {
      @extend %flex-row-center;
      font-family: $font-body;
      letter-spacing: 0.5px;
      padding: 12px 24px;
      width: 100%;
      max-width: 1024px;
      overflow-x: auto;

      @include md {
        overflow-x: hidden;
      }

      .btn {
        font-size: 13px;
        padding: 0 12px;
        font-weight: 500;
        text-decoration: none;
        border-right: none;
        color: $darkest;
        cursor: pointer;
        white-space: nowrap;
        border-right: 1px solid $lighter;
        border-bottom: none;
        padding: 0 12px;

        @include sm {
          font-size: 13px;
          padding: 2px 16px;
        }

        @include md {
          font-size: 13px;
          padding: 2px 16px;
        }

        &:hover {
          opacity: 0.6;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
