@import '../../styles/shared.scss';

.link-controls-outer-container {
  margin-top: 24px;

  .tabs {
    @extend %flex-row;
    gap: 16px;
    margin-top: 12px;
    margin-bottom: 24px;
    overflow: auto;

    @include md-down {
      padding-left: 20px;
    }

    .tab-container {
      padding-bottom: 8px;

      &.active {
        border-bottom: 4px solid $primary;
      }
      .tab {
        @extend %label;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
        padding: 12px 16px;
        border-radius: 4px;
        background-color: transparent;
        color: $dark;
        border: $border-lightest;
        transition: opacity 0.2s ease-in-out;
        cursor: pointer;

        &:hover:not(.active) {
          opacity: 0.8;
        }

        &.active {
          background-color: $white;
          color: $darkest;
          border: $border-darkest;
        }
      }
    }
  }

  .link-controls-container {
    @extend %flex-row-middle-spread;
    padding: 0 16px 16px;
    gap: 12px;
    position: relative;

    @include md {
      padding: 0 0 16px;
    }

    .main {
      @extend %flex-row-middle;
      flex: 1;

      @include md {
        display: inherit;
      }

      .input-container {
        flex: 1;
        max-width: 480px;
        position: relative;

        @include md-down {
          margin-right: 12px;
          min-width: 60vw;
        }

        input {
          @extend %input-shared;
          margin-right: 12px;
          width: 100%;
          padding-left: 32px;

          @include md {
            display: inherit;
          }
        }

        svg {
          position: absolute;
          top: 12px;
          left: 12px;
          font-size: 13px;
          color: $light;
        }
      }
    }

    .secondary {
      @extend %flex-row-middle;

      .new-link-btn {
        @extend %btn-darker;
        padding: 8px 12px;
        width: 90%;
        max-width: 240px;
        margin: 8px auto;

        @include md {
          width: auto;
          margin-left: 12px;
        }
      }

      .download-csv-btn {
        @extend %btn-lightest;
        padding: 8px 12px;

        @include md {
          padding: 8px 12px;
          margin-left: 12px;
        }

        svg {
          margin-right: 8px;
        }
      }

      .list-select {
        min-width: 240px;

        .shopmy-select__control {
          border: $border-lightest;

          .shopmy-select__value-container {
            @extend %flex-row-middle;
            padding: 0;

            .shopmy-select__placeholder {
              padding: 2px 2px;
              color: $lighter;
            }

            [class*='-Input'] {
              margin: 0;
              padding: 0 16px;

              .shopmy-select__input input {
                text-align: left;
                padding: 2px 2px;
              }
            }
          }

          .shopmy-select__indicators {
            .shopmy-select__indicator-separator {
              display: none;
            }
            .shopmy-select__dropdown-indicator,
            .shopmy-select__clear-indicator {
              padding: 4px;
            }
          }
        }

        .shopmy-select__single-value {
          font-family: $font-body;
          padding: 4px 16px;
          font-size: 14px;
        }
      }

      .date-picker {
        @extend %flex-row;
        font-family: $font-body !important;
        margin-left: 12px;
        display: none;

        @include md {
          display: inherit;
        }

        .DateRangePicker {
          align-self: stretch;
          border-radius: 4px;
          border: $border-lightest;

          .DateRangePickerInput {
            border-radius: 8px;
            padding-left: 4px;

            .DateInput {
              width: 112px;
              input {
                font-family: $font-body;
                padding: 8px 16px;
                font-size: 14px;
                height: 100%;
              }
            }

            .DateInput_input__focused {
              border-color: $darker;
            }
          }

          .DateRangePickerInput_arrow_svg {
            width: 16px;
            height: 16px;
            margin-top: -3px;
          }
        }
        // NOTE: the order of these styles DO matter (this comes directly from the react-dates CSS recommendations)
        // Will edit everything selected including everything between a range of dates
        .CalendarDay__selected_span {
          background: $dark;
          color: white;
          border: $border-darker;
        }

        // Will edit selected date or the endpoints of a range of dates
        .CalendarDay__selected {
          background: $darker;
          border-color: $darkest;
          color: white;
        }

        // Will edit when hovered over. _span style also has this property
        .CalendarDay__selected:hover {
          background: $medium;
          color: white;
        }

        // Will edit when the second date (end date) in a range of dates
        // is not yet selected. Edits the dates between your mouse and said date
        .CalendarDay__hovered_span:hover,
        .CalendarDay__hovered_span {
          background: $dark;
          border-color: $dark;
          color: white;
        }
      }
    }

    .show-other-retailers-switch {
      @extend %flex-row-middle;
      color: $dark;
      font-size: 11px;
      position: absolute;
      right: 0;
      bottom: calc(100% + 24px);

      .switch {
        margin: -1px 0 0 4px;
      }

      .list-select {
        min-width: 180px;

        .shopmy-select__menu {
          z-index: 4;
        }
      }
    }
  }
}
