@import '../../styles/shared.scss';

.simple-auth-panel-outer-container {
  form {
    @extend %flex-col;

    .input-sections {
      .input-section {
        @extend %flex-row-middle;
        border: $border-medium;
        border-bottom: none;

        &:first-child {
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
        }
        &:last-child {
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
          border-bottom: $border-medium;
        }

        .label {
          @extend %label;
          font-size: 10px;
          width: 120px;
          padding: 12px;
          border-right: $border-medium;
        }

        input {
          @extend %input-shared;
          width: 100%;
          outline: none;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-color: transparent;

          &:focus {
            border-color: transparent;
          }
        }

        &.inverted {
          .label {
            color: white;
          }
          input {
            background-color: transparent;
            color: white;

            ::placeholder {
              color: $lighter;
            }
          }
        }
      }
    }
  }
  .auth-buttons {
    @extend %flex-col;
    margin-top: 16px;
    gap: 4px;

    .auth-button {
      @extend %btn-darkest-new;
      padding: 16px 24px;
      position: relative;
      font-size: 14px;

      &.inverted {
        background-color: $primary;
      }

      &.text {
        background-color: transparent;
      }

      &.registering {
        color: transparent;
        pointer-events: none;
      }

      .loader {
        position: absolute;
        height: 100%;
        margin-top: -18px;
      }
    }
  }
}
