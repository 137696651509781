@import '../../styles/shared.scss';

.contract-propose-rates-codes-card {
  .contract-rates-codes {
    @extend %flex-row;

    &.locked {
      .contract-rate-code {
        border: none;
        padding: 0;
      }
    }

    .contract-rate-code {
      flex: 1;
      border: 2px solid $dark;
      padding: 8px;
      border-radius: 4px;
      position: relative;

      @include md {
        padding: 16px;
        border-radius: 8px;
      }

      .edit-btn {
        @extend %btn-basic;
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: 12px;

        &.save {
          @extend %btn-dark-new;
        }

        @include md {
          top: 16px;
          right: 16px;
        }
      }

      &:not(:last-child) {
        margin-right: 12px;
      }

      &.old {
        border-color: $offwhite;
      }

      .header {
        font-size: 14px;
        font-weight: bold;
        color: $darker;
        margin-bottom: 8px;
        white-space: nowrap;

        @include md {
          font-size: 16px;
        }
      }

      .section {
        margin-top: 8px;

        .section-header {
          font-size: 10px;
          font-weight: bold;
          color: $dark;
          text-transform: uppercase;
          white-space: nowrap;

          @include md {
            font-size: 10px;
          }
        }
        .section-value {
          font-size: 16px;
          font-weight: bold;
          color: $darker;

          @include md {
            font-size: 20px;
          }
        }
        .section-edit {
          @extend %flex-col;
          input {
            @extend %input-shared;
            margin-top: 8px;
          }
          @include md {
            font-size: 20px;
          }
        }
      }
    }
  }
}
