@import '../../styles/shared.scss';

.pro .nav-top-old {
  width: 100%;
  z-index: 5;
  position: fixed;
  transition: all 0.15s ease-in-out;
  font-family: $font-body;

  &.scrolled {
    transform: translateY(-20px);

    &.home {
      transform: none;
    }

    @include md {
      transform: none;
    }
  }

  &.ncea {
    .nav-top-outer-container.home {
      background: white !important;
      &:before {
        background: transparent !important;
      }

      .nav-hamburger-wrapper svg {
        transform: translateY(6px);
      }
      &.scrolled {
        .nav-hamburger-wrapper svg {
          color: $dark !important;
        }
      }
      .brand-theme-logo {
        @include md {
          height: 60px !important;
          transform: translateY(10px);
          filter: none !important;
          margin-top: 12px;
        }
      }
    }
  }

  .nav-top-outer-container {
    width: 100%;
    background: $darker;
    z-index: 10;
    position: relative;
    transition: border-bottom 0.2s ease-in-out;
    border-bottom: $border-transparent;

    &.home {
      background: transparent;
      border-bottom: $border-transparent;
      transition: background 0.2s ease-in-out;
      padding-bottom: 24px;

      &.scrolled {
        &:before {
          opacity: 1;
        }
      }

      &:before {
        background: $primaryPro;
        transition: opacity 0.3s;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 64px;
        opacity: 0;
        z-index: 2;
        pointer-events: none;
      }
      .nav-top-inner-container.home .header-secondary-container.home {
        .nav-hamburger-wrapper {
          .nav-hamburger {
            &.scrolled {
              transform: none;
            }
          }
        }
      }
    }

    .nav-top-inner-container {
      @extend %flex-row-middle-spread;
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;
      padding: 0 16px;
      height: 60px;
      color: white;
      position: relative;

      @include xl {
        padding: 0 0;
      }

      &.home {
        max-width: 1240px;

        .header-secondary-container.home {
          z-index: 3;

          .nav-hamburger-wrapper svg {
            @include md {
              color: $darkest;
            }
          }

          &.scrolled {
            .nav-hamburger-wrapper svg {
              color: $white;
            }
          }
        }

        .more-options-menu-outer-container {
          z-index: 3;
        }
      }

      .mobile {
        min-width: 24px;

        @include md {
          display: none;
        }
      }

      .desktop {
        display: none;

        @include md {
          display: inherit;
        }
      }

      .header-main-container {
        @extend %flex-row;
        width: 100%;

        @include md {
          justify-content: flex-start;
        }

        .logo-text {
          @extend %flex-row;
          align-items: flex-end;
          font-size: 14px;
          font-family: $font-header-sl;
          color: $white;
          position: relative;
          font-weight: 300;
          cursor: pointer;
          letter-spacing: -0.5px;
          transition: all 0.15s ease-in-out;
          font-style: italic;

          .brand-theme-logo {
            height: 40px;
            margin-right: 6px;
            max-width: 160px;
            filter: brightness(0) invert(1);
            object-fit: contain;
          }

          &.home {
            font-size: 16px;
            color: $white;
            z-index: 2;
            transform: none !important;

            .em {
              font-size: 22px;
            }
          }

          .em {
            @extend %flex-row-middle;
            font-weight: 600;
            font-style: normal;
            font-size: 20px;
            margin: -1px 6px 0 0;
            font-family: $font-header;
          }

          &.scrolled {
            transform: translateY(9px) scale(0.9);

            @include md {
              transform: none;
              img {
                transform: none;
              }
            }
          }
        }

        .nav-tabs {
          @extend %flex-row;
          align-items: stretch;
          margin-left: 40px;
          display: none;
          margin-bottom: -16px;
          font-family: $font-body;
          z-index: 3;

          @include md {
            display: flex;
          }

          .nav-tab {
            margin-right: 12px;
            color: white;
            font-size: 15px;
            font-weight: bold;
            padding: 8px 12px;
            border-bottom: 5px solid transparent;
            text-decoration: none;
            text-transform: uppercase;

            &.active {
              border-color: white;
            }
          }
        }
      }

      button {
        background: transparent;
        appearance: none;
        font: inherit;
        border: none;
        padding: 0;
        font-size: inherit;
        color: inherit;
        outline: none;
      }

      .header-secondary-container {
        @extend %flex-row-middle;

        .action-btn {
          @extend %btn-basic;
          margin-right: 16px;
          text-transform: uppercase;
          padding: 2px 8px;
          border: $border-white;
          border-radius: 4px;
          font-size: 12px;
          font-weight: bold;
          transform: translateY(2px);

          @include md-down {
            display: none;
          }
        }

        .nav-hamburger-wrapper {
          position: relative;

          .nav-hamburger {
            font-size: 20px;
            cursor: pointer;
            transition: all 0.15s ease-in-out;
            margin-bottom: -4px;
            min-width: 24px;
            color: $white;

            &.close {
              transform: scale(1.1);
            }

            &.scrolled {
              transform: translateY(9px) scale(0.9);

              @include md {
                transform: none;
              }
            }

            path {
              pointer-events: none; // to enable click away
            }
          }

          .notification-count {
            @extend %flex-center;
            position: absolute;
            top: -6px;
            right: -6px;
            background: $error;
            transition: all 0.15s ease-in-out;
            font-size: 12px;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            font-weight: bold;
            pointer-events: none;

            &.scrolled {
              transform: translateY(9px) scale(0.9);

              @include md {
                transform: none;
              }
            }
          }
        }
      }
    }
  }
}
