@import '../../styles/shared.scss';

.directory-outer-container {
  margin-top: 0;
  font-family: $font-body;

  .directory-header-outer-container {
    background: linear-gradient($darker 30%, #272727);

    .directory-header-container {
      max-width: 1024px;
      margin: 0 auto;

      .directory-header-inner-container {
        padding-left: 24px;
        padding: 50px 28px 24px;
        margin-bottom: 48px;
        color: white;

        @include md {
          padding: 64px 16px 24px;
        }

        .directory-header-tag {
          font-size: 24px;
          line-height: 28px;
          font-family: $font-header;
          font-style: italic;
          text-transform: none;
          font-weight: normal;
          letter-spacing: -1.3px;
        }

        h1.directory-header-title {
          font-size: 32px;
          line-height: 1.1;
          font-family: $font-header-bold;
          font-weight: bold;
          text-transform: uppercase;
          color: white;
          letter-spacing: 1.2px;
          margin-top: 12px;
          max-width: 800px;

          @include sm {
            font-size: 36px;
          }

          @include md {
            font-size: 36px;
            letter-spacing: 2.1px;
            margin-top: 12px;
          }
        }

        .directory-header-line {
          background: $white;
          height: 2px;
          width: 62%;
          min-width: 240px;
          max-width: 440px;
          margin-top: 12px;

          @include md {
            margin-top: 16px;
            height: 3px;
          }
        }

        h2.directory-header-subtitle {
          font-size: 16px;
          line-height: 18px;
          margin-top: 20px;
          letter-spacing: 0.3px;
          color: white;
          font-weight: normal;
          max-width: 800px;

          @include md {
            margin-top: 24px;
          }
        }

        .directory-insider-type-selection-container {
          @extend %flex-row;
          flex-wrap: wrap;
          margin: 24px 0 36px;

          .directory-insider-type-selection {
            @extend %flex-center;
            padding: 2px 0 0;
            cursor: pointer;

            .type-text {
              font-size: 16px;
              font-weight: bold;
              letter-spacing: 1.1px;
              color: white;
              transition: all 0.2s ease-in-out;
              pointer-events: none;
              margin-right: 12px;
              padding: 6px 0 2px;
              color: $light;
              border-bottom: 2px solid transparent;

              @include sm {
                font-size: 16px;
              }

              @include md {
                padding: 6px 16px;
                margin-right: 0;
                color: white;
                border-radius: 24px;
              }

              @include lg {
              }

              @include xl {
                font-size: 16px;
              }

              &.active {
                color: white;
                // background: $lighterpurple;
                border-bottom: 2px solid white;

                @include md {
                  background: $lighterpurple;
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .directory-body-container {
    max-width: 880px;
    margin: 0 auto;

    .directory-insiders {
      @extend %flex-row;
      flex-wrap: wrap;
      padding: 0 12px;

      @include md {
        padding: 0;
      }

      .directory-insider {
        @extend %flex-col-center;
        width: 50%;
        margin-bottom: 18px;
        text-decoration: unset;
        transition: all 0.2s ease-in-out;
        position: relative;

        &.inactive {
          display: none;
        }

        &:hover {
          transform: scale(1.035); // rotateY(180deg);

          .insider-name {
            color: $light !important;
          }
        }

        @include sm {
          width: 50%;
          margin-bottom: 18px;
        }

        @include md {
          width: 33%;
          margin-bottom: 20px;
        }

        @include lg {
          width: 25%;
        }

        @include xl {
          width: 20%;
        }

        .user-img-wrapper {
          width: calc(100% - 16px);
          position: relative;
          border-radius: 4px;
          overflow: hidden;

          @include md {
            width: calc(100% - 24px);
            border-radius: 10px;
          }

          .user-img {
            width: 100%;
            height: 100%;
            min-height: 140px;
            object-fit: cover;
            background: $prettymuchwhite;
            transition: all 0.4s ease-in-out;

            &.empty {
              background: $offwhite;
              height: 160px;
            }
          }

          .cover {
            transition: opacity 0.3s;
            content: '';
            display: block;
            position: absolute;
            top: 60%;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(0deg, #000, transparent);
            opacity: 0.5;
          }
        }

        .user-name {
          position: absolute;
          bottom: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          border-radius: 1px;
          width: calc(100% - 16px);
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          padding: 10px 6px;
          text-align: right;
          color: white;
          text-transform: uppercase;
          margin-top: 4px;
          font-size: 12px;
          min-height: 34px;
          font-weight: bold;
          white-space: nowrap;
          border-radius: 4px;

          @include md {
            padding: 10px 12px !important;
            width: calc(100% - 24px);
            border-radius: 10px;

            &.very-long {
              font-size: 11px;
              padding: 10px 4px;
            }
          }
        }
      }
    }
  }
}
