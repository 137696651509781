@import '../../styles/shared.scss';

.mention-controls-outer-container {
  position: absolute;
  width: 100%;
  bottom: 100%;
  right: 0;
  padding-bottom: 18px; // Keep this in sync with MentionFilers.scss Search Input
  z-index: 3;
  pointer-events: none;

  .mention-controls-container {
    @extend %flex-row;
    justify-content: flex-end;

    .secondary {
      @extend %flex-row-middle;
      pointer-events: auto;
      gap: 12px;

      .toggle-switch {
        @extend %flex-row-middle;
        margin-right: 4px;
        gap: 4px;

        .toggle-label {
          font-size: 12px;
          color: $medium;
          white-space: nowrap;
        }

        .info-tooltip svg {
          font-size: 11px;
          color: $medium;
        }

        .switch {
          margin: -1px 0 0 4px;
        }
      }
    }

    .download-btn {
      @extend %flex-center;
      @extend %label;
      @extend %btn-basic;
      align-self: stretch;
      background-color: white;
      border: $border-lighter;
      columns: $dark;
      font-weight: normal;
      font-size: 10px;
      border-radius: 4px;
      padding: 4px 12px;
      position: relative;

      svg {
        margin-right: 6px;
      }

      &.downloading {
        color: transparent;
        background-color: $prettymuchwhite;
        border: $border-lightest;
      }

      .loader {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
