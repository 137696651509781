@import '../../../styles/shared.scss';

.opportunity-listing-expectations-section {
  .expectation-type-sections {
    @extend %flex-col;
    gap: 30px;

    .expectation-type-section {
      @extend %flex-row;
      align-items: flex-start;
      gap: 20px;

      .quantity {
        @extend %flex-row-middle;
        gap: 4px;
        margin-top: 4px;

        .adjust-quantity {
          @extend %btn-basic;
          padding: 4px;
          font-size: 12px;

          &.inactive {
            color: $lighter;
          }

          &.disabled {
            color: $lightest;
          }
        }

        .counter-number {
          @extend %flex-center;
          font-family: $font-header;
          font-size: 24px;
          font-weight: bold;
          width: 16px;
          text-align: center;

          &.inactive {
            color: $lighter;
          }
        }
      }

      .expectation-type {
        &.inactive {
          .type {
            color: $lighter;
          }
          .explainer {
            color: $lighter;
          }
        }

        .type-container {
          @extend %flex-row;
          gap: 8px;

          .type {
            @extend %flex-row-middle;
            gap: 4px;
            font-size: 18px;
            font-weight: bold;

            .days-disclaimer {
              font-size: 14px;
              font-weight: normal;
            }
          }

          .warning {
            @extend %flex-row-middle;
            gap: 4px;
            color: $error;
            font-size: 10px;
            font-weight: 500;

            svg {
              font-size: 10px;
            }
          }
        }

        .explainer {
          font-size: 12px;
          color: $medium;
          margin-top: 2px;
        }

        .additional-options {
          @extend %flex-col;
          margin-top: 16px;
          gap: 8px;
          .additional-option {
            @extend %flex-row;
            gap: 4px;

            @include md-down {
              flex-direction: column;
              gap: 8px;
            }

            .display {
              color: $light;
              font-size: 14px;
              white-space: nowrap;
              margin-top: 2px;
              min-width: 150px;
            }

            .enums {
              @extend %flex-row;
              gap: 8px;
              flex-wrap: wrap;

              .enum {
                @extend %btn-basic;
                @extend %flex-row-middle;
                @extend %label;
                gap: 4px;
                padding: 4px 12px;
                border-radius: 24px;
                font-size: 10px;
                font-weight: bold;
                background-color: $white;
                border: $border-darkest;
                color: $darkest;

                &.selected {
                  background-color: $darkest;
                  color: $white;
                }

                &.add {
                  border-color: $lighter;
                  color: $lighter;
                }
              }
            }
          }
        }
      }
    }
  }
}
