@import '../../styles/shared.scss';

.contract-preview-outer-container {
  text-decoration: none;
  color: $darker;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  .contract-preview-container {
    @extend %flex-row;
    width: 100%;
    min-height: 68px;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    border: $border-lightest;

    &.requires-response,
    &.has-warnings {
      border: 2px solid $dark;
    }

    .image-container {
      @extend %flex-center;
      background: black;
      color: white;
      font-family: $font-header;
      font-size: 26px;
      font-weight: bold;

      &.brand {
        background: $darker;
        padding: 8px;
        width: 90px;
        max-width: 90px;
        max-height: 90px;
        align-self: stretch;

        img {
          object-fit: contain;
          filter: brightness(0) invert(1);
        }
      }
      &.user {
        width: 68px;
        max-width: 68px;
        max-height: 80px;
        align-self: stretch;

        img {
          object-fit: cover;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .main {
      @extend %flex-row-middle-spread;
      padding: 12px;
      flex: 1;

      @include md-down {
        flex-direction: column;
        align-items: stretch;
      }

      .content {
        .title {
          font-size: 16px;
          font-weight: bold;
          color: $darker;
        }
        .subtitle {
          font-size: 12px;
          color: $dark;

          .bullet {
            margin: 0 4px;
          }
        }

        .warnings-and-errors {
          @extend %flex-col;
          align-items: flex-start;
          margin-top: 4px;

          .warning,
          .error {
            @extend %flex-row-middle;
            font-size: 13px;
            font-weight: bold;
            color: $darkest;
            background: $yield;
            padding: 3px 6px;
            border-radius: 4px;

            &:not(:first-child) {
              margin-top: 4px;
            }

            svg {
              font-size: 9px;
              margin-right: 4px;
              margin-top: -2px;
            }
          }

          .warning {
            background: $yield;
          }

          .error {
            background: $error;
            color: white;
          }
        }
      }
      .actions {
        @extend %flex-row;
        margin: 0 0 0 12px;

        @include md-down {
          margin: 12px 0 0 0;
        }

        .action {
          @extend %btn-text-new;
          text-transform: uppercase;
          font-size: 12px;

          &:last-child {
            @extend %btn-dark-new;
          }
        }
      }
    }
  }
}
