@import '../../../styles/shared.scss';

.opportunity-listing-explanation-section {
  .opportunity-listing-content {
    &.editing {
      .content-input-wrapper {
        position: relative;

        textarea {
          @extend %input-shared;
          width: 100%;
          resize: vertical;
          font-size: 14px;
          line-height: 18px;
          color: $darkest;

          &::placeholder {
            color: $light;
          }
        }

        .char-count {
          position: absolute;
          right: 8px;
          bottom: 8px;
          font-size: 10px;
          font-weight: 500;
          color: $dark;

          &.warning {
            color: $error;
          }
        }
      }
    }

    .content {
      ul {
        padding-inline-start: 20px;
      }
    }

    .loading-content {
      color: $lighter;
      min-height: 100px;
    }

    .warning-alert {
      @extend %flex-row-middle;
      margin-top: 12px;
      background-color: $yield;
      border: 1px solid $yield;
      border-radius: 4px;
      padding: 12px 24px;
      gap: 12px;

      .text {
        font-size: 12px;
        color: $darkest;
      }
    }
  }

  .additional-perk-actions {
    @extend %flex-row;
    justify-content: flex-end;
    margin-top: 12px;
    gap: 12px;

    .action-select {
      @extend %flex-row-middle;
      gap: 12px;

      &.is-active {
        .action-label {
          color: $primary;
          font-weight: 500;
        }
      }

      .action-label {
        @extend %flex-row-middle;
        gap: 4px;
        font-size: 11px;
        white-space: nowrap;
        color: $dark;

        svg {
          &.check {
            color: $primary;
          }
        }
      }

      .shopmy-select {
        min-width: 220px;
      }
    }
  }

  .opportunity-listing-auto-send-lookbook {
    @extend %flex-row-middle;
    background-color: $prettymuchwhite;
    border: $border-offwhite;
    padding: 12px;
    border-radius: 4px;
    margin-top: 12px;
    transition: opacity 0.3s;
    font-size: 12px;

    a {
      @extend %link;
      color: $darkest;
    }

    &.editing {
      opacity: 0.3;
    }

    svg {
      margin-right: 8px;
    }
  }
}
