@import '../../styles/shared.scss';

.earnings-portal-outer {
  width: 100%;
  background: $offwhite;

  .earnings-portal-inner {
    font-family: $font-body-new;
    width: min(90%, 1200px);
    margin: 0 auto;
    padding: 100px 0;
    @extend %flex-col;
    gap: 50px;

    .earnings-portal-section-header {
      @extend %flex-row;
      justify-content: space-between;
      margin-bottom: 20px;
      align-items: center;

      @include sm-down {
        margin-bottom: 10px;
      }

      .earnings-portal-section-title {
        @extend %label;
        font-size: 20px;
        color: $darkest;

        @include sm-down {
          font-size: 16px;
        }
      }

      .earnings-portal-section-subtitle {
        font-size: 16px;
        color: $dark;

        @include sm-down {
          font-size: 12px;
        }
      }

      .earnings-portal-section-button {
        @extend %btn-basic;
        @extend %label;
        background: $darkest;
        border-radius: 4px;
        padding: 10px 20px;
        color: $white;
        border: none;
        outline: none;
        text-decoration: none;

        @include sm-down {
          font-size: 12px;
        }
      }
    }

    .earnings-portal-table {
      width: 100%;
      border-radius: 25px 25px 10px 10px;
      overflow: hidden;

      .earnings-portal-table-header {
        display: grid;
        background: $darkest;
        color: $white;
        padding: 15px 20px 10px 40px;
        font-size: 16px;
        overflow-x: scroll;

        .earnings-portal-table-cell {
          flex: 1;
          padding: 5px;
          text-align: center;
          justify-content: center;

          @include md-down {
            font-size: 12px;
          }

          &:first-child,
          &.left {
            text-align: left;
            justify-content: flex-start;
          }
        }
      }

      .earnings-portal-table-body {
        @extend %flex-col;
        gap: 20px;
        background: $white;
        padding: 20px 0;
        overflow-x: scroll;

        .earnings-portal-empty-message {
          font-size: 12px;
          color: $light;
          text-align: center;

          @include md-down {
            font-size: 10px;
          }
        }

        .earnings-portal-table-row {
          display: grid;
          justify-content: space-between;
          padding: 0 20px 0 40px;
          font-size: 14px;
          font-weight: 400;
          gap: 5px;


          .earnings-portal-table-cell {
            @extend %flex-row;
            gap: 5px;
            text-align: center;
            justify-content: center;
            align-items: center;
            text-wrap: wrap;
            overflow: none;
            color: $darkest;
            font-weight: 400;

            @include md-down {
              font-size: 12px;
            }

            &:first-child,
            &.left {
              text-align: left;
              justify-content: flex-start;
              position: relative;
            }

            &.skeleton-cell {
              width: 50%;
              margin: 0 auto;
              height: 15px;
              background: $prettymuchwhite;

              animation: skeleton-fade 3s infinite;

              @for $i from 1 through 6 {
                &:nth-child(#{$i}n) {
                  animation-delay: #{$i * 0.5}s;
                }
              }

              &:first-child {
                margin: 0;
              }
            }



            &.btn {
              @extend %btn-basic;
              color: $darkest;
              font-weight: 700;
            }

            &.not-loaded {
              background: rgba(255, 0, 0, 0.2);
            }
          }

          .show-more {
            @extend %btn-basic;
            text-align: center;
            justify-content: center;
            color: $medium;
            width: 100%;
          }
        }
      }
    }

  }
}

@keyframes skeleton-fade {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
