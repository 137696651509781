@import '../../styles/shared.scss';

.progress-circle {
  .path-background {
    stroke: $lightest;
    fill: transparent;
  }

  .path-progress {
    stroke: $primary;
    fill: transparent;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  .path-secondary-progress {
    stroke: $secondary;
    fill: transparent;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
}
