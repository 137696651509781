@import '../../styles/shared.scss';

.opportunity-leaderboard-container {
  @include md-down {
    padding: 0 24px;
  }

  .leaderboard-sections {
    @extend %flex-col;
    gap: 48px;
    .leaderboard-section {
      .leaderboard-section-header-container {
        @extend %flex-row-middle-spread;
        margin-bottom: 24px;
        gap: 24px;

        @include md-down {
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
        }

        .title-container {
          @extend %flex-col;
          flex: 1;

          .title {
            font-size: 36px;
            line-height: 36px;
            font-weight: bold;
            color: $darkest;
            font-family: $font-header;

            @include md-down {
              font-size: 24px;
              line-height: 24px;
            }
          }

          .subtitle {
            font-size: 10px;
            color: $light;
            font-style: italic;
          }
        }
      }

      .leaderboard-section-timeline-container {
        @extend %flex-row-middle;
        flex: 1;
        gap: 12px;
        margin-bottom: 32px;

        .status-badge-container {
          @extend %flex-row-middle;
          gap: 8px;

          .badge-outer {
            width: 11px;
            height: 11px;
            background-color: $primary;
            border-radius: 50%;
            position: relative;
            animation: pulse 2.5s infinite;

            .badge-inner {
              width: 3px;
              left: 4px;
              height: 3px;
              top: 4px;
              background-color: $white;
              border-radius: 50%;
              position: absolute;
            }
          }

          .status {
            font-size: 12px;
            border-radius: 24px;
            color: $primary;
            font-weight: bold;
          }
        }

        .timeline-spine-container {
          flex: 1;
          height: 1px;
          position: relative;

          .timeline-spine {
            background-color: $darkest;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;

            &.pending {
              width: 100%;
              background-color: $lightest;

              &.not-started {
                background-color: $offwhite;
              }
            }
          }

          .timeline-spine-current-marker {
            position: absolute;
            top: -4px;
            height: 9px;
            width: 9px;
            background-color: $darkest;
            border-radius: 50%;
          }
        }

        .timeline-label {
          color: $dark;
          font-size: 12px;

          &.active {
            color: $darkest;
          }

          &.not-started {
            color: $light;
          }
        }
      }
      .results-container {
        @extend %flex-col;
        gap: 24px;
      }

      .no-results-container {
        @extend %flex-center;
        background-color: white;
        padding: 48px;
        font-size: 12px;
        color: $light;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  20% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    opacity: 0.4;
    transform: scale(1.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
