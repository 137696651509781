@import '../../styles/shared.scss';

.has-actions {
  position: relative;
  .input-actions-container {
    @extend %flex-center;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: 0 8px;

    &.hidden {
      display: none;
    }

    .icn {
      @extend %flex-center;
      background: rgba(255, 255, 255, 1);
      border-radius: 50%;
      width: 20px;
      height: 20px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      color: $dark;
      font-size: 12px;

      &:hover {
        color: $darkest;
      }
    }
  }
}
