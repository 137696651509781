@import '../../../../styles/shared.scss';

.home-creator-animation-steps-outer-container {
  padding-top: 36px;

  @include md {
    padding-top: 240px;
  }
  @include lg {
    padding-top: 280px;
  }
  @include xl {
    padding-top: 300px;
  }
  @include xxl {
    padding-top: 360px;
  }

  .steps {
    @extend %flex-col;
    gap: 100px;

    @include md {
      gap: 240px;
    }

    @include lg {
      gap: 240px;
    }

    @include xl {
      gap: 240px;
    }
    @include xxl {
      gap: 400px;
    }

    .step {
      min-height: 200px;

      &:last-child {
        @include md-down {
          margin-bottom: 120px;
        }
      }

      .image-container {
        width: 100%;
        aspect-ratio: 390 / 800;

        @include md {
          display: none;
        }
        img {
          position: absolute;
          width: 100vw;
          transform: translateX(-20px);
        }
      }

      .title-container {
        position: relative;
        padding-left: 32px;

        @include md {
          padding-left: 40px;
          padding-right: 40px;
        }

        @include xl {
          padding-left: 60px;
          padding-right: 60px;
        }

        .fade-in-on-active {
          transition: opacity 0.3s ease-in-out;

          @include md {
            opacity: 0.1;
          }

          &.focused {
            opacity: 1;
          }
        }

        .step-number {
          @extend %flex-center;
          position: absolute;
          top: 0;
          left: 0;
          font-weight: 700;
          color: $darker;
          min-width: 48px;
          min-height: 48px;
          width: 48px;
          height: 48px;
          border: 10px solid $white;
          border-radius: 50%;
          background-color: $darkest;
          transform: translateX(calc(-48px / 2)) translateY(-10px);
          color: white;

          @include md {
            background-color: $offwhite;
            transition: background-color 0.3s ease-in-out;
          }

          @include md {
            min-width: 56px;
            min-height: 56px;
            width: 56px;
            height: 56px;
            transform: translateX(calc(-56px / 2)) translateY(-10px);
          }

          &.focused {
            opacity: 1;
            background-color: $darkest;
          }
        }

        .title {
          margin: 0;
          font-family: $font-header;
          font-weight: bold;
          line-height: 1.3;
          font-size: 28px;
          color: $darkest;
          margin-bottom: 16px;

          @include sm {
            font-size: 40px;
          }

          @include md {
            font-size: 32px;
          }
          @include lg {
            font-size: 40px;
          }
          @include xl {
            font-size: 44px;
          }
          @include xxl {
            font-size: 48px;
          }
        }
        .subtitle {
          margin: 0;
          line-height: 1.4;
          font-size: 16px;
          font-weight: 400;
          color: $dark;
          margin-bottom: 32px;

          @include sm {
            font-size: 18px;
          }

          @include md {
            font-size: 16px;
          }
          @include lg {
            font-size: 16px;
          }
          @include xl {
            font-size: 18px;
          }
          @include xxl {
            font-size: 20px;
          }
        }
        .action {
          @extend %btn-basic;
          @extend %flex-row-middle;
          gap: 8px;
          text-decoration: none;
          color: $darkest;
          font-size: 14px;
          text-transform: none;
          font-weight: bold;

          &:hover {
            img {
              transform: translateX(4px);
            }
          }

          img {
            margin-left: 6px;
            width: 20px;
            transition: transform 0.3s ease-in-out;
          }

          @include xl {
            font-size: 16px;
          }
        }
      }
    }
  }
}
