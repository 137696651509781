@import '../../styles/shared.scss';

.requests-control-bar {
  @extend %flex-row;
  gap: 10px;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;

  @include lg {
    flex-direction: row;
  }

  .control-bar-section {
    @extend %flex-row;
    gap: 0.5rem;
    position: relative;

    @include md-down {
      flex-wrap: wrap;
    }

    &#search-and-filter {
      position: relative;

      .select-and-search-container {
        @extend %flex-row-middle;
        gap: 12px;
        margin-top: -3px;

        .select-container {
          min-width: 180px;

          .shopmy-select__single-value {
            font-size: 12px;
          }
        }

        .search-container {
          @extend %flex-row;
          align-items: center;
          border: $border-lightest;
          border-radius: 500px;
          padding: 10px 12px;
          background-color: white;

          input {
            border: none;
            outline: none;
            font-family: $font-body-new;
            font-size: 14px;
          }
        }
      }

      .add-button {
        @extend %flex-row;
        @extend %label;
        align-items: center;
        color: white;
        background-color: black;
        padding: 12px 16px;
        border-radius: 4px;
        gap: 5px;
        cursor: pointer;
        font-size: 12px;

        &.isLoading {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .filter-container {
        position: relative;
        background-color: white;

        .filter-label {
          @extend %flex-row;
          @extend %btn-basic;
          @extend %label;
          align-items: center;
          gap: 10px;
          height: 100%;
          padding: 10px 15px;
          // display: grid;
          // place-items: center;
          border: $border-lightest;
          border-radius: 5px;
          font-size: 12px;
          color: $darkest;
          cursor: pointer;
          transition: all 0.2s ease-in-out;

          &:hover {
            border: $border-dark;
          }

          svg {
            @extend %btn-basic;
            height: 10px;
            width: 10px;
          }
        }

        .filter-dropdown {
          @extend %flex-col;
          gap: 10px;
          position: absolute;
          top: calc(100% + 10px);
          left: 50%;
          transform: translateX(-50%);
          background-color: white;
          border: $border-lightest;
          padding: 10px;
          border-radius: 5px;
          z-index: 2;

          .filter-option {
            @extend %label;
            background-color: white;
            padding: 10px 15px;
            border-radius: 5px;
            font-size: 11px;
            cursor: pointer;
            border: $border-lightest;

            &.active {
              border: $border-primary;
            }
          }
        }
      }
    }

    &#tabs {
      .tab-container {
        @extend %label;
        height: 100%;
        border-radius: 0;
        font-size: 12px;
        color: $darkest;
        border-bottom: 4px solid transparent;
        transition: all 0.2s ease-in-out;
        font-weight: bold;
        position: relative;

        &.active {
          border-bottom: 4px solid $primary;
          color: $darkest;
        }

        &.active > .tab-container-tab {
          border: $border-dark;
          background-color: white;
        }

        .new-badge {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(8px, -8px);
          background-color: $primary;
          color: white;
          padding: 2px 5px;
          border-radius: 4px;
          font-size: 0.5rem;
          @extend %label;
        }

        .tab-container-tab {
          @extend %flex-row-middle;
          padding: 8px 12px;
          border-radius: 4px;
          margin-bottom: 8px;
          cursor: pointer;
          border: $border-lighter;
          transition: all 0.2s ease-in-out;

          &:hover {
            border: $border-dark;
          }
        }
      }
    }

    .recommendation-actions {
      @extend %flex-row-middle;
      gap: 20px;

      .recommendation-control-switch {
        @extend %flex-row-middle;
        gap: 5px;
      }

      .recommendation-control-label {
        @extend %label;
        @extend %flex-row-middle;
        gap: 5px;
        font-size: 10px;
      }

      .recommendation-control-button {
        @extend %label;
        @extend %btn-inverted-new;
        height: 100%;

        svg {
          font-size: 12px;
          margin-right: 8px;
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}
