@import '../../styles/shared.scss';

.partner-portal-outer {
  font-family: $font-body-new;
  background-color: $prettymuchwhite;
  padding-top: 80px;
  width: 100%;
  min-height: 100vh;

  @include md-down {
    padding-top: 48px;
  }

  .partner-portal-inner {
    width: min(85%, 1100px);
    margin: 0 auto;
    padding-bottom: 200px;

    .partner-portal-header {
      font-size: 3rem;
      font-weight: 600;
      font-family: $font-header;
      margin: 1.5rem 0;

      @include md-down {
        font-size: 36px;
      }
    }

    .tabs-container {
      &.isLoading {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .clickable {
      cursor: pointer;
    }
  }
}
