@import '../../../styles/shared.scss';

.section-template.post {
  @extend %flex-row;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 12px;
  padding: 0 20px;

  .collection {
    width: calc(50% - 6px);
    aspect-ratio: 1;
    background: $offwhite;
    border-radius: 4px;
  }

  &.horizontal {
    .collection {
      aspect-ratio: 1.78;
    }
  }
  &.vertical {
    .collection {
      aspect-ratio: 0.754;
    }
  }
}
