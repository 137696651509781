@import '../../../../styles/shared.scss';

.skeleton-panel-outer {
  .skeleton-panel-inner {
    @extend %flex-col;
    gap: 10px;

    .skeleton-panel-title {
      height: 24px;
      width: 200px;
      background: $offwhite;
      border-radius: 10px;
    }

    .skeleton-panel-subtitle {
      background: $offwhite;
      border-radius: 10px;
    }

    .skeleton-panel-input {
      height: 51px;
      width: 100%;
      border: $border-lightest;
      border-radius: 5px;
    }

    .skeleton-panel-checkboxes {
      @extend %flex-col;
      gap: 14px;

      .skeleton-panel-checkbox {
        height: 21px;
        border-radius: 500px;
        width: 250px;
        background: $offwhite;
      }
    }

    .skeleton-panel-split-buttons {
      @extend %flex-row;
      gap: 8px;

      .skeleton-panel-split-button {
        flex: 1;
        height: 51px;
        border-radius: 5px;
        background: $offwhite;
      }
    }
  }
}
