@import '../../styles/shared.scss';

.triple-dots-modal-container {
  position: relative;
  z-index: 3;

  .overlay {
    position: absolute;
    top: 100%;
    right: 100%;
    border-radius: 8px 0px 8px 8px;
    box-shadow: $shadow-light;
    border: $border-offwhite;
    background-color: white;
    cursor: default;
    z-index: 10;

    &.open-up {
      top: auto;
      bottom: 100%;
      border-radius: 8px 8px 0 8px;
    }

    .padding {
      box-sizing: border-box;
      padding: 8px 10px;

      .overlay-button-container {
        @extend %flex-col;
        gap: 3px;
        font-size: 11px;
        font-weight: bold;
        background-color: white;
        color: $darker;

        .overlay-button {
          @extend %flex-row-middle;
          @extend %btn-basic;
          gap: 4px;
          padding: 4px 0;
          white-space: nowrap;
          transition: all 0.15s ease-in-out;

          &.is-active.highlight-on-active:not(.is-performing-action) {
            color: $primary;
          }
          &.is-performing-action {
            .display-container {
              opacity: 0.5;
            }
          }

          &.disabled {
            cursor: default;
            opacity: 0.7;
          }

          .icon-container {
            position: relative;

            &.is-performing-action {
              .icon {
                opacity: 0;
              }

              .loader {
                width: 100%;
                height: 100%;
                position: absolute;
                top: -5px;
              }
            }

            .icon {
              min-width: 12px;
              transition: opacity 0.2s ease-in-out;
            }
          }
          .display-container {
            .display {
            }
            .subdisplay {
              font-size: 9px;
              color: $dark;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}
