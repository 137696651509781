@import '../../../../styles/shared.scss';

.shopify-integration-panel-outer {
  .shopify-integration-panel-inner {
    .integration-select-container {
      margin-bottom: 24px;
    }

    .checkboxes {
      @extend %flex-col;
      gap: 10px;
      margin: 12px 0;

      .secondary-checkboxes {
        @extend %flex-col;
        gap: 10px;
        margin-left: 24px;
      }
    }

    .shopify-integration-select-field {
      .title {
        font-size: 14px;
      }
    }

    .setting-sections {
      @extend %flex-col;
      gap: 12px;
      margin-top: 12px;

      .sublabel {
        font-size: 14px;
        color: $darkest;
        font-weight: bold;
      }

      .description {
        font-size: 12px;
        margin-bottom: 12px;
      }
    }

    .setting-container {
      color: $darker;

      .location-info-container {
        margin-left: 22px;
      }

      .setting-label {
        font-weight: bold;
        font-size: 14px;
      }

      .setting-description {
        font-size: 12px;
        margin-bottom: 12px;
      }
    }
  }
}
