@import '../../styles/shared.scss';

.your-subscription-offers-container {
  background-color: $prettymuchblack;
  padding: 24px 24px 0;
  border-radius: 10px;

  .container-title {
    color: white;
    padding: 12px 12px 0;

    .badge {
      @extend %label;
      font-size: 10px;
      margin-bottom: 10px;
    }

    .header {
      font-family: $font-header;
      font-size: 36px;
      font-weight: bold;

      @include md-down {
        font-size: 28px;
      }
    }
  }
  .your-subscription-offers {
    display: grid;
    padding: 24px 0;
    grid-template-columns: 1fr 1fr;

    @include md-down {
      grid-template-columns: 1fr;
    }

    // space between the two grid columns
    grid-gap: 0px 48px;
  }
}
