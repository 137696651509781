@import '../../../styles/shared.scss';

.onboarding-panel-intro-container {
  @extend %flex-center-vertical;
  height: 100%;
  flex: 1;
  padding-bottom: 80px; // Height of footer

  img {
    width: 100%;
    max-width: 412px;
    object-fit: contain;
    margin: 0 auto 48px;
    margin-top: -48px;

    @include md {
      max-width: calc(500px * 0.8);
      width: calc(500px * 0.8);
      height: calc(225px * 0.8);
      margin: 0 auto 12px;
    }

    @include lg {
      max-width: calc(500px * 0.9);
      width: calc(500px * 0.9);
      height: calc(225px * 0.9);
      margin: 0 auto 12px;
    }

    @include xl {
      max-width: calc(500px * 0.9);
      width: calc(500px * 0.9);
      height: calc(225px * 0.9);
    }
  }
}
