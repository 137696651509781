@import '../../styles/shared.scss';

.bulk-talent-modal-footer {
  @extend %flex-row-middle-spread;
  align-items: stretch;
  width: 100%;
  position: relative;

  .attachments {
    @extend %flex-center;
    .upload-progress {
      @extend %flex-center;
      font-size: 12px;
      color: $dark;
      font-weight: 500;
      width: 30px;
    }

    .add-new {
      @extend %flex-col;
    }

    .uploaded-files {
      .file {
        @extend %flex-center;
        width: 30px;
        height: 36px;
        border-radius: 4px;
        background-color: $lightest;
        border: $border-lighter;
        color: $darker;
        font-size: 12px;
        font-weight: bold;

        svg {
          margin-left: 2px;
        }
      }
    }
  }

  .actions {
    @extend %flex-row;
    gap: 12px;
    justify-content: flex-end;
    flex: 1;

    .outreach-select {
      max-width: 240px;
    }

    .send-btn {
      @extend %btn-darkest;
      @extend %label;
      padding: 8px 24px;

      &.disabled {
        opacity: 0.2;
        pointer-events: none;
      }
    }
  }

  .num-remaining-container {
    @extend %flex-col;
    gap: 4px;
    align-items: flex-end;
    position: absolute;
    right: 0;
    top: calc(100% + 4px);

    .num-remaining {
      font-size: 10px;
      color: $darkest;

      &.warn {
        color: $error;
        font-weight: bold;
      }
    }
  }
}
