@import '../../../../styles/shared.scss';

.lookbook-product-modal-result-outer {
  position: relative;
  width: 100%;

  .lookbook-product-modal-result-inner {
    width: 100%;
    border: $border-lightest;
    border-radius: 16px;
    position: relative;
    padding: 10px 0;
    height: 100%;

    .action-buttons {
      @extend %flex-row-middle;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(18px, -18px);
      gap: 4px;

      .button {
        cursor: pointer;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        z-index: 2;

        &.primary {
          background-color: $darkest;
          color: $lighter;
        }

        &.secondary {
          background-color: $offwhite;
          color: $dark;
        }

        &.active {
          background-color: $primary;
          color: white;
        }
      }
    }

    .image-container {
      width: 100%;
      padding: 20px;

      img {
        width: 100%;
        margin: 0 auto;
        object-fit: cover;
        aspect-ratio: 1;
        border-radius: 10px;
      }

      .no-image {
        width: 100%;
        margin: 0 auto;
        object-fit: cover;
        aspect-ratio: 1;
        border-radius: 10px;
        background-color: $lighter;
        display: grid;
        place-items: center;
        color: $light;
        font-size: 0.7rem;
      }
    }

    .product-text {
      @extend %flex-col;
      text-align: center;
      padding: 0 20px;

      .title-container {
        margin-bottom: 16px;

        .title {
          @extend %flex-row-middle;
          @extend %header-fancy-sm;
          text-decoration: none;
          color: black;
          font-size: 16px;
          line-height: 20px;
          // padding: 6px 0;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .subtitle {
          @extend %flex-col;
          margin-top: 2px;
          align-self: stretch;
          color: $light;
          font-size: 0.7rem;
          justify-content: center;
          text-align: center;
          gap: 6px;
        }
      }

      .sub-content {
        @extend %flex-col;
        align-self: stretch;
        color: $light;
        font-size: 0.7rem;
        justify-content: center;
        text-align: center;
        gap: 6px;

        .shopify-connected-info {
          @extend %flex-row-center;
        }

        .variation-swatches {
          @extend %flex-row-center;
          flex-wrap: wrap;
          gap: 8px;

          .variation-swatch {
            @extend %btn-basic;
            width: 64px;
            border: $border-offwhite;
            border-radius: 4px;
            overflow: hidden;

            &.active {
              border: $border-lighter;
            }

            &.show-more {
              @extend %flex-center;
            }

            img {
              width: 100%;
              aspect-ratio: 1.5;
              object-fit: cover;
            }

            .no-image {
              width: 100%;
              height: 40px;
              background-color: $offwhite;
              z-index: 0;
              font-size: 8px;
            }

            .tag {
              font-size: 9px;
              letter-spacing: 0.05em;
              color: $darkest;
              overflow: hidden;
              font-weight: 500;
              text-overflow: ellipsis;
              padding: 4px 4px;
            }
          }
        }

        .variation-count {
          cursor: pointer;
          transition: color 0.2s ease-out;

          &:hover {
            color: $dark;
          }
        }
      }
    }

    .link-to-site {
      position: absolute;
      bottom: 0;
      right: 0;
      color: $darkest;
      font-weight: bold;
      font-size: 10px;
      padding: 4px;
    }
  }
}
