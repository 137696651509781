@import '../../../styles/shared.scss';

.user-lookbook-products-outer {
  .user-lookbook-products-inner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    place-content: center;
    justify-content: space-around;
    row-gap: 50px;
    column-gap: 30px;

    > div {
      opacity: 0;
      animation: fade-in 0.3s ease-in-out forwards;
    }

    @for $i from 1 through 100 {
      > div:nth-child(#{$i}) {
        animation-delay: #{$i * 0.1}s;
      }
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
