@import '../../../../styles/shared.scss';

.social-sharing-templates-instagram {
  @extend %flex-row;
  flex-wrap: wrap;
  gap: 36px;
  margin-top: 12px;
  position: relative;

  .social-sharing-template {
    position: relative;
  }

  .downloadable-content-container {
    border: $border-lighter;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    transition: transform 0.2s ease-in-out;

    .downloadable-content {
      aspect-ratio: 9/16;
      // padding: 20px;
      position: relative;
      height: 475px;
      overflow: hidden;

      .downloadable-content-inner {
        padding: 20px;
        height: 100%;

        .logo {
          position: absolute;
          bottom: 15px;
          right: 15px;
          object-fit: contain;
        }

        .big-title,
        .medium-title,
        .small-title {
          font-family: $font-header;
          font-weight: bold;
        }

        .big-title {
          font-size: 22px;
        }
        .medium-title {
          font-size: 20px;
        }
        .small-title {
          font-size: 18px;
        }

        .big-subtitle,
        .medium-subtitle,
        .small-subtitle {
          margin: 4px 0;
        }

        .big-subtitle {
          font-size: 16px;
        }
        .medium-subtitle {
          font-size: 14px;
        }
        .small-subtitle {
          font-size: 12px;
        }
      }
    }
  }
}

@include md-down {
  .social-sharing-template {
    .downloadable-content-container {
      width: 100%;
      height: auto;

      .downloadable-content {
        width: 100%;
        height: auto;
      }
    }
  }
}
