@import '../../styles/shared.scss';

.search-media-modal-outer-container {
  @extend %flex-col;
  max-width: 640px !important;

  .input-container {
    margin-right: 12px;
    input {
      @extend %input-shared;
      flex: 1;
      width: 100%;
    }
  }

  .results-header-container {
    margin-bottom: 16px;
    .results-header {
      font-size: 18px;
      font-weight: bold;
    }
    .results-subheader {
      font-size: 13px;
      color: $dark;

      .link {
        @extend %link;
      }
    }
  }

  .results-container {
    .results {
      @extend %flex-row;
      flex-wrap: wrap;

      .result-wrapper {
        width: 33.3%;
        padding: 0 6px 24px;

        @include md-down {
          width: 50%;
          padding: 0 0 24px;

          &:nth-child(2n) {
            padding-left: 12px;
          }
        }

        .result {
          @extend %flex-col-spread;
          border: $border-lighter;
          border-radius: 4px;
          overflow: hidden;
          height: 100%;

          .image-container {
            position: relative;
            overflow: hidden;
            height: 200px;
            width: 100%;
            margin-right: 12px;

            .empty-img {
              height: 60px;
              height: 200px;
              background: $offwhite;
            }

            img {
              object-fit: cover;
              height: 100%;
              width: 100%;
            }
          }

          .main {
            @extend %flex-col;
            color: $darker;
            min-height: 60px;
            padding: 8px;

            .header {
              @extend %flex-row-middle-spread;
              margin-bottom: 2px;

              .type-badge {
                font-size: 11px;
                text-transform: uppercase;
                color: $dark;
              }

              .link {
                @extend %btn-basic;
                color: $dark;
                text-decoration: none;
                margin-top: -1px;

                svg {
                  font-size: 11px;
                }
              }
            }

            .title {
              font-size: 12px;
              line-height: 14px;
              font-weight: bold;
              color: $darker;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
            }
            .date {
              margin-top: 4px;
              font-size: 12px;
              color: $dark;
            }
          }

          .link-actions {
            @extend %flex-row-middle;
            color: $dark;
            font-size: 14px;
            margin: 4px;

            .link-action {
              @extend %btn-dark-new;
              flex: 1;

              &.active {
                @extend %btn-text-new;
                pointer-events: none;
              }

              svg {
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
    .empty-msg {
      margin: 48px 24px;
      text-align: center;
      font-size: 14px;
      // text-transform: uppercase;
      color: $dark;
    }
  }
}
