@import '../../styles/shared.scss';

.discover-user-results-container {
  @extend %flex-row;
  flex-wrap: wrap;
  margin-top: 12px;
  transition: opacity 0.2s ease-in-out;

  &.fetching {
    opacity: 0.3;
  }
  &.fetching-more {
    opacity: 1;
  }
}

.discover-user-results-no-results {
  padding: 84px 16px 44px;
  text-align: center;
  color: $light;
  font-size: 13px;
}
