@import '../../../styles/shared.scss';

.admin-controls-outer-container {
  margin-top: 48px;
  border: $border-offwhite;
  border-radius: 12px;
  padding: 24px;

  .section-title {
    @extend %label;
    font-size: 10px;
    color: $light;
  }

  .controls {
    @extend %flex-col;
    margin-top: 12px;
    gap: 12px;

    .control {
      @extend %flex-col;
      gap: 4px;
      .header {
        @extend %flex-row-middle-spread;
        .label {
          font-size: 10px;
          color: $dark;
          flex: 1;
        }

        .action {
          @extend %btn-darkest-new;
          font-size: 10px;
          padding: 4px 12px;
          cursor: pointer;
        }
      }
    }
    .main {
      .shopmy-select {
        min-width: 120px;
        font-size: 10px;
        padding: 0;
        height: 24px;

        .shopmy-select__control {
          min-height: auto;
          height: 100%;

          .shopmy-select__value-container {
            padding: 0;

            > div {
              height: 24px;
            }
          }
        }
      }
    }
  }
}
