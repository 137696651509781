@import '../../../../styles/shared.scss';

.user-tokens-panel-outer {
  .user-tokens-panel-inner {
    .title {
      @extend %flex-row-middle-spread;
      font-size: 14px;
      font-weight: bold;
      color: $darker;
      text-transform: uppercase;
    }

    .subtitle {
      font-size: 12px;
      margin-top: 4px;
      color: $darker;
    }

    .token-rows-wrapper {
      margin-top: 24px;
      width: 100%;

      .token-row {
        &.editing {
          padding: 16px;
          background: $offwhite;
          border: $border-lightest;
          border-radius: 8px;
        }
      }

      .token-info {
        display: flex;
        justify-content: space-between;

        .info-left {
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
            background: $darkest;
            border-radius: 50%;
            margin-right: 8px;
            padding: 4px;
          }

          .initials {
            @extend %flex-center;
            height: 40px;
            width: 40px;
            object-fit: cover;
            border-radius: 50%;
            background: $darkest;
            color: white;
            font-family: $font-header;
            font-size: 12px;
            font-weight: bold;
            margin-right: 8px;
          }

          .meta {
            .name {
              @extend %header-xs;
              line-height: 1;
            }

            .scopes {
              @extend %paragraph-xs;
              color: $dark;
            }
          }
        }

        .info-right {
          display: flex;
          align-items: center;

          .action {
            @extend %btn-basic;
            text-transform: uppercase;
            font-size: 12px;
            margin-left: 12px;
            font-weight: bold;
            color: $darkest;
          }
        }
      }

      .edit-scopes {
        margin-top: 12px;

        .scope-row {
          @extend %flex-row-middle;
          font-size: 12px;
          font-weight: bold;
          margin-bottom: 4px;
          cursor: pointer;
          opacity: 0.4;
          color: $darkest;
          width: min-content;
          white-space: nowrap;

          &.selected {
            opacity: 1;
          }

          input {
            cursor: pointer;
          }
        }
      }
    }
  }
}
