@import '../../styles/shared.scss';

.spotlight-options-toggle-btn {
  @extend %flex-center;
  position: fixed;
  bottom: 20px;
  left: 16px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: $darker;
  cursor: pointer;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
  pointer-events: auto;
  font-size: 28px;
  color: white;
  z-index: 10001;
  transition: all 0.2s ease-in-out;

  .spotlight-options-toggle-icon-container {
    @extend %flex-row;
    justify-content: center;
    align-items: center;

    font-size: 24px;
  }

  &.first-time {
    animation: bounce 2s infinite;
  }

  a {
    color: white;
    text-decoration: none;
  }

  .tooltip {
    @extend %animation-fade-quick;
  }

  .tooltip {
    @extend %label-xs;
    position: absolute;
    font-size: 10px;
    color: $darkest;
    line-height: 10px;

    bottom: 100%;
    margin-bottom: 8px;
    border-radius: 3px;

  }

  &:hover {
    background: $dark;

    .show-on-hover {
      display: inherit;
    }

    .hide-on-hover {
      display: none;
    }
  }

  .show-on-hover {
    display: none;
  }

  @include md {
    bottom: calc(20px + 10px + 48px);
    right: 20px;
    left: auto;

    &.visible {
      transform: translateX(0);
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-24px);
  }

  40% {
    transform: translateY(-0);
  }

  60% {
    transform: translateY(-18px);
  }

  80% {
    transform: translateY(-0);
  }

  100% {
    transform: translateY(0);
  }
}
