@import '../../../../styles/shared.scss';

.user-managers-panel-outer {
  .user-managers-panel-inner {
    position: relative;

    .settings-button {
      svg {
        margin-right: 8px;
      }
    }

    .rows {
      @extend %flex-col;
      gap: 8px;
    }

    .row {
      &.editing {
        padding: 16px;
        background: $offwhite;
        border: $border-lightest;
        border-radius: 8px;
      }

      .manager-data {
        @extend %flex-row;
        justify-content: space-between;
        align-items: center;

        .main,
        .meta {
          @extend %flex-col;
          justify-content: center;

          .name {
            @extend %flex-row;
            /* @extend %header-xs; */
            align-items: flex-end;
            gap: 6px;
            line-height: 1;
            font-size: 16px;
            margin-bottom: 6px;

            @include md-down {
              align-items: flex-start;
              flex-direction: column;
              margin-bottom: 0px;
              max-width: 120px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            .metadata {
              color: $medium;
              font-size: 9px;
              font-weight: normal;
              margin-bottom: 2px;
            }

            @include md-down {
              align-items: flex-start;
              flex-direction: column;
              margin-bottom: 0px;
            }
          }

          .metadata {
            color: $medium;
            font-size: 9px;
          }

          .display {
            @extend %paragraph-xs;
            color: $dark;
          }
        }

        .actions {
          @extend %flex-row;
          gap: 5px;
        }
      }

      .sections {
        @extend %flex-col;
        margin-top: 24px;
        gap: 8px;

        .section {
          margin: 0 24px;

          @include sm-down {
            margin: 0;
          }

          .section-label {
            @extend %label;
            color: $darker;
            margin-bottom: 8px;
            font-size: 10px;
          }

          .permissions {
            @extend %flex-row;
            flex-wrap: wrap;

            @include sm-down {
              flex-direction: column;
            }

            .permissions-block {
              width: 33.3%;
              padding-right: 12px;

              @include sm-down {
                width: 100%;
              }

              .permission {
                @extend %flex-row-middle;
                font-size: 12px;
                margin-bottom: 4px;
                cursor: pointer;
                opacity: 0.4;
                color: $darkest;

                &.selected {
                  opacity: 1;

                  .checkbox {
                    background: $darkest;

                    svg {
                      color: white;
                      display: inherit;
                    }
                  }
                }

                .checkbox {
                  @extend %flex-center;
                  margin-right: 6px;
                  height: 16px;
                  width: 16px;
                  border-radius: 2px;
                  background: $offwhite;

                  svg {
                    display: none;
                  }
                }
              }
            }
          }

          .payout-configs {
            @extend %flex-col;
            gap: 8px;

            .payout-config {
              @extend %flex-row-middle;
              gap: 12px;

              .label {
                color: $darker;
                font-size: 10px;
              }

              input {
                @extend %input-inline;
                font-size: 14px;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
}
