@import '../../../styles/shared.scss';

.talent-result-stats {
  @extend %flex-row;
  flex: 1;

  .stat-column {
    flex: 1;
    @extend %flex-col;
    width: 160px;
    gap: 4px;

    &:not(:last-child) {
      margin-right: 32px;
    }

    // &.fluid {
    // .stat-container {
    // .stat {
    // font-size: 14px;
    // font-weight: bold;
    // color: $darkest;
    // }
    // .badge {
    // width: 120px;
    // font-size: 11px;
    // padding: 4px 12px;
    // border-radius: 6px;
    // }
    // }
    // }

    .stat-container {
      @extend %flex-row-middle-spread;

      .stat {
        font-size: 10px;
        font-weight: bold;
        color: $darkest;
        white-space: nowrap;
        position: relative;

        .explainer {
          position: absolute;
          top: 0;
          left: -14px;
        }
      }

      .badge {
        font-size: 9.5px;
        font-weight: bold;
        text-transform: uppercase;
        background: $darkest;
        color: white;
        white-space: nowrap;
        width: 80px;
        text-align: center;
        padding: 3px 6px;
        border-radius: 4px;
        cursor: default;

        &.highest {
          background: $darkest;
        }
        &.higher {
          background: $darker;
        }
        &.high {
          background: $dark;
        }
        &.medium {
          background: $medium;
        }
        &.low {
          background: $lightest;
          color: $medium;
        }
        &.lowest {
          background: $offwhite;
          color: $light;
        }
        &.na {
          background: white;
          color: $light;
        }
        &.locked {
          background: white;
          color: $light;
        }
      }
    }
  }
}
