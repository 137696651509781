@import '../../../styles/shared.scss';

.home-quotes-panel-outer-container {
  position: relative;
  overflow: hidden;

  .home-quotes-panel-inner-container {
    margin: 0 auto;
    width: calc(100vw - 40px);
    max-width: 1240px;
    padding-top: 64px;
    height: 640px;

    @include sm {
      padding-top: 120px;
      height: 720px;
    }
    @include md {
      height: 600px;
      width: 90vw;
    }

    @include lg {
      height: 600px;
      height: 800px;
    }

    @include xl {
      max-width: 1440px;
      height: 800px;
    }

    @include xxl {
      max-width: 1440px;
      height: 1000px;
    }

    .quote-container {
      @extend %flex-row;
      align-items: flex-start;

      .quotation-mark {
        margin-right: 32px;
        img {
          width: 32px;
          height: 32px;
          object-fit: contain;
        }
      }
      .quote-and-speaker {
        @extend %flex-col;

        .quote {
          max-width: 600px;
          font-size: 18px;
          line-height: 1.4;
          margin-bottom: 24px;

          @include md {
            font-size: 20px;
          }
        }
        .speaker {
          cursor: pointer;
          font-size: 20px;
          font-weight: bold;
          font-family: $font-social;
          letter-spacing: 0.5px;
          color: $darkest;
          text-decoration: none;

          @include md {
            font-size: 24px;
          }
        }
        .speaker-bio {
          font-size: 12px;
          font-style: italic;
          margin-top: 4px;

          @include md {
            font-size: 14px;
          }
        }
      }
    }
    .quote-selector-dots {
      @extend %flex-row-middle;
      gap: 8px;
      margin-top: 80px;

      @include md {
        margin-top: 40px;
      }
      @include xl {
        margin-top: 80px;
      }
      .quote-selector-dot {
        cursor: pointer;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: $lightest;

        &.active {
          background: $darker;
        }
      }
    }
  }

  .home-quotes-panel-background {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100vw;
    max-height: 1000px;
    pointer-events: none;

    img {
      @extend %animation-fade;
    }

    @include sm {
      width: 90vw;
    }

    @include md {
      width: 80vw;
    }

    @include xl {
      width: 75vw;
    }

    @include xxl {
      width: 70vw;
    }
    img {
      width: 100%;
    }
  }
}
