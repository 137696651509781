@import '../../styles/shared.scss';

.user-collaborations-panel-outer-container {
  @extend %flex-col;
  width: 100%;
  position: relative;

  .view-as-brand-toggle {
    @extend %btn-basic;
    text-align: center;
    padding-bottom: 12px;
    height: 24px;
    font-size: 12px;
    margin: 0;
    text-transform: uppercase;
  }
  .actions-container {
    margin: 48px 0;

    .actions {
      @extend %flex-center;
      text-align: center;

      @include md-down {
        width: 100%;
        padding: 0 8px;
      }

      .action {
        @extend %btn-dark-new;
        text-transform: uppercase;
        font-size: 13px;

        &:not(:first-child) {
          margin-left: 12px;
        }

        &.done {
        }

        &.search {
          @extend %btn-outline-new;

          svg {
            margin-left: 8px;
            color: white;
            font-size: 11px;
            color: $dark;
          }
        }
      }
    }
  }

  .add-custom-container {
    @extend %flex-center-vertical;
    margin-bottom: 96px;
    text-align: center;

    @include md-down {
      width: 100%;
      padding: 0 8px;
    }

    .add-custom-header {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      color: $darkest;
      text-align: center;

      @include md-down {
        font-size: 14px;
      }
    }
    .add-custom-subheader {
      font-size: 11px;
      text-transform: uppercase;
      color: $dark;
      text-align: center;
    }

    .add-custom-button {
      @extend %flex-row-middle-spread;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      padding: 12px 12px;
      margin-top: 24px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: $offwhite;

        .actions {
          svg {
            color: $dark;
          }
        }
      }

      .main {
        @extend %flex-row-middle;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;

        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
          margin-right: 8px;
        }
      }

      .actions {
        @extend %flex-row-middle;
        margin-left: 8px;

        svg {
          transition: all 0.2s ease-in-out;
          margin-left: 8px;
          color: $light;
        }
      }
    }
  }
}
