@import '../../styles/shared.scss';

.career-perk-outer {
  font-family: $font-body-new;

  +.career-perk-outer {
    border-top: $border-lightest;
  }

  .career-perk-inner {
    padding: 32px 0;

    .career-perk-header {
      @extend %flex-row;
      @extend %btn-basic;
      justify-content: space-between;
      align-items: center;
      font-size: 28px;

      svg {
        font-size: 20px;
      }
    }

    .career-perk-body {
      @extend %flex-col;
      gap: 20px;
      margin-top: 32px;

      .career-perk {
        @extend %label;
        font-size: 14px;
        text-wrap: wrap;
      }
    }
  }
}
