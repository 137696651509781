@import '../../styles/shared.scss';

.pro {
  .landing-apply-panel-outer-container {
    overflow: inherit;
    background: $darker;
    .landing-login-container {
      .landing-header-panel-left .build-btn {
        color: $darkest;
      }
    }
  }
}

.landing-apply-panel-outer-container {
  @extend %flex-col-center;
  width: 100%;
  position: relative;
  overflow: hidden;
  font-family: $font-body;
  border-top: 20px solid white;

  .landing-apply-panel-container {
    @extend %flex-center;
    width: 100%;
    max-width: 1240px;
    position: relative;
    height: 100%;
    padding: 40px 24px 120px;
    flex-direction: column;

    @include md {
      padding: 104px 32px 104px;
      flex-direction: row;
    }

    @include xl {
      padding: 120px 0;
    }

    .apply-panel-explainer-container {
      margin: 0 0 24px;

      @include md {
        margin: 0 44px 0 0;
      }

      .explainer {
        font-size: 26px;
        font-family: $font-header-sl;
        font-weight: 700;
        max-width: 540px;
        line-height: 1.1;
        color: white;
        margin: 48px 0;

        @include md {
          font-size: 32px;
        }

        @include lg {
          font-size: 38px;
        }
      }
    }

    .landing-login-container {
      @extend %flex-col-middle;
      width: 100%;

      @include md {
        width: auto;
      }

      .auth-container {
        width: 100%;
        max-width: 480px;
        align-self: center;
        padding: 24px 12px;
        border: $border-lightest;
        box-shadow: $shadow-dark;
        background: rgba(255, 255, 255, 0.85);
        border-radius: 10px;
        font-family: $font-body-new;
        min-height: 440px;

        @include md {
          width: calc(100% - 24px);
          min-width: 440px;
          padding: 24px;
        }

        .auth-header {
          font-size: 14px;
          color: $darkest;
        }

        .register-options {
          @extend %flex-center;

          .register-option {
            text-transform: uppercase;
            padding: 0 12px;
            margin: 0 8px;
            font-size: 17px;
            letter-spacing: 0.5px;
            font-weight: bold;
            opacity: 0.3;
            white-space: nowrap;
            cursor: pointer;

            @include md {
              font-size: 20px;
            }

            &.selected {
              opacity: 1;
            }
          }
        }

        .application-success {
          font-size: 14px;
          margin: 32px 0 16px;
          color: $darker;

          a {
            color: $darker;
          }
        }

        form {
          @extend %flex-col;
          margin-top: 24px;

          .standard-input {
            @extend %input-shared;
            margin-bottom: 12px;
            font-family: $font-body-new;
          }

          .license-select {
            margin-bottom: 12px;
          }

          .login-btn-container {
            position: relative;
            margin-top: 16px;

            .login-btn {
              @extend %btn-darkest;
              width: 100%;
              padding: 12px 12px;
              font-size: 16px;

              &.loading {
                color: transparent;
              }
            }

            .loader {
              position: absolute;
              top: 4px;
              width: 100%;
              background: transparent;
            }
          }

          .forgot-password {
            font-size: 13px;
            text-decoration: underline;
            cursor: pointer;
            margin-top: 4px;
          }

          .upsell-to-pro {
            font-size: 13px;
            margin-top: 12px;

            a {
              font-weight: bold;
              color: $link-blue;
            }
          }
        }
      }
    }
  }
}
