@import '../../styles/shared.scss';

.bonus-modal-outer {
  .bonus-modal-inner {
    width: 90vw !important;
    max-width: 640px !important;
    min-height: 360px !important;
    height: auto !important;

    .bonus-modal-content {
      @extend %flex-col;
      position: relative;
      background-color: white;
      padding: 24px;
      border-radius: 10px;
    }
    .modal-header {
      @extend %flex-col;
      margin-bottom: 30px;
      padding-right: 48px;

      .header {
        font-size: 24px;
        font-weight: 600;
        color: $darkest;
      }
      .subheader {
        font-size: 14px;
        color: $darker;

        .text-btn {
          @extend %link;
          text-decoration: underline;
        }
      }
    }

    .sections {
      @extend %flex-col;
      gap: 24px;

      .section {
        @extend %flex-col;

        .label {
          @extend %label;
          font-size: 13px;
          font-weight: 600;
          color: $darkest;
        }

        .sublabel {
          font-size: 11px;
          color: $dark;
          font-style: italic;

          a {
            @extend %link;
          }
        }

        .value {
          margin-top: 8px;
          font-family: $font-header;
          font-size: 36px;
          font-weight: 600;
          color: $darker;
        }

        input,
        textarea {
          @extend %input-shared;
          margin-bottom: 12px;
          margin-top: 8px;
        }
      }
    }

    .actions-and-warning {
      @extend %flex-row-middle-spread;
      margin-top: 24px;
      gap: 24px;

      .warning {
        @extend %flex-row;
        height: 100%;
        font-size: 12px;
        color: $medium;

        svg {
          margin: 2px 12px 0 0;
          font-size: 14px;
        }
      }

      .actions {
        @extend %flex-row;
        justify-content: flex-end;

        .action {
          margin-left: 12px;

          &.secondary {
            @extend %btn-text-new;
            padding: 12px 24px;
          }

          &.budget {
            @extend %btn-primary-new;
            background-color: $error;
            border-color: $error;
            padding: 12px 24px;
          }

          &.done {
            @extend %btn-darkest-new;
            padding: 12px 24px;
          }

          &.disabled {
            pointer-events: none;
            opacity: 0.3;
          }
        }
      }
    }
  }
}
