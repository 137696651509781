@import '../../../styles/shared.scss';

.opportunity-planner-past-performance {
  .grid-6 {
    grid-template-columns: 2fr repeat(4, 1fr) 120px;
  }
  .grid-7 {
    grid-template-columns: 2fr repeat(5, 1fr) 120px;
  }
  .grid-8 {
    grid-template-columns: 2fr repeat(6, 1fr) 120px;
  }
  .grid-9 {
    grid-template-columns: 2fr repeat(7, 1fr) 120px;
  }
  .grid-10 {
    grid-template-columns: 2fr repeat(8, 1fr) 120px;
  }
  .grid-11 {
    grid-template-columns: 2fr repeat(9, 1fr) 120px;
  }
  .grid-12 {
    grid-template-columns: 2fr repeat(10, 1fr) 120px;
  }

  .results-header {
    display: grid;
    padding: 12px 0;
    background-color: $darkest;
    border-radius: 10px 10px 0 0;
    color: white;

    .header-cell {
      &.clickable {
        @extend %btn-basic;
      }
      .display {
        @extend %flex-center;
        gap: 4px;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        white-space: nowrap;

        svg {
          color: $dark;

          &.active-sort {
            color: white;
          }
        }
      }
    }
  }

  .results-container {
    padding: 0 0;
    margin: 0 0 120px;
    transition: opacity 0.3s;
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    &.fetching-first-page:not(.fetching-results) {
      opacity: 0.3;
    }

    .row {
      display: grid;
      border-bottom: $border-white;
      padding: 4px 0;
      border-radius: 8px;

      &:first-child {
        margin-top: 12px;
      }

      &.loading.row {
        .cell {
          max-height: 36px;
          text-align: center;
          background-color: $whynotusewhite;
        }
      }

      &.in-plan {
        background-color: $offwhite;
      }

      .creator-cell {
        @extend %flex-row-middle;
        flex: 1;

        .image-container {
          margin-right: 12px;
          img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
          }
        }

        .data-container {
          text-align: left;
          .name {
            @extend %btn-basic;
            font-weight: bold;
            font-size: 14px;
            white-space: nowrap;
          }

          .tier {
            font-size: 11px;
            color: $dark;
          }
        }
      }

      .icon-cell {
        .display {
          position: relative;
          @extend %btn-basic;
          padding: 0 !important;

          .icon {
            @extend %flex-center;
            position: absolute;
            right: 100%;
            height: 100%;
            transform: translateX(-4px) translateY(1px);
            color: $lighter;

            &.active {
              color: $darker;
            }

            svg {
              font-size: 10px;
            }
          }
        }
      }

      .cell {
        @extend %flex-center;
        text-align: center;
        white-space: nowrap;
        padding: 0 4px;

        .display {
          font-weight: 500;
          font-size: 15px;
          color: $darkest;

          &.secondary {
            color: $medium;
          }

          &.small {
            font-size: 12px;
          }

          &.with-icon {
            @extend %flex-row-middle;
            gap: 4px;

            svg {
              font-size: 10px;
              color: $dark;
              transform: translateY(-1px);
            }
          }
        }

        .actions {
          .action {
            @extend %btn-darkest-new;
            font-size: 10px;
            padding: 8px 20px;
            position: relative;

            &.loading {
              color: transparent;
            }

            &.remove {
              background-color: white;
              border-color: $light;
              color: $dark;
            }

            .loader {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              margin-top: -3px;
            }
          }
        }
      }
    }
  }
}
