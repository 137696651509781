@import '../../../styles/shared.scss';

.contract-upload {
  @extend %flex-row-middle-spread;

  &:not(:first-child) {
    margin-top: 12px;
  }

  &.template {
    .main {
      @extend %flex-row-middle;
      flex: 1;

      .data {
        @extend %flex-col;
        flex: 1;

        .title {
          font-size: 12px;
          font-weight: bold;
          color: $darker;
        }
        .description {
        }
      }
    }
  }

  .main {
    @extend %flex-row-middle;
    flex: 1;

    .data {
      @extend %flex-col;
      flex: 1;
      color: $dark;

      textarea,
      input {
        @extend %input-inline;
        resize: none;

        &.title {
          font-size: 14px;
        }
        &.url {
          font-size: 12px;
        }
        &.description {
          font-size: 11px;
          font-weight: normal;

          &::placeholder {
            font-style: italic;
          }
        }
      }
      .title {
        font-size: 14px;
        font-weight: bold;
        color: $darker;
      }
      .meta {
        @extend %flex-row-middle;
        font-size: 11px;
        color: $dark;
        .bullet {
          margin: 0 4px;
        }
      }

      a {
        @extend %link;
      }
      .description {
        margin-top: 4px;
        font-size: 11px;
        font-weight: normal;
      }
    }
  }
  .secondary {
    margin-left: 24px;

    .upload-actions {
      @extend %flex-row-middle;
      color: $dark;
      font-size: 14px;

      .upload-action {
        @extend %flex-row;
        @extend %btn-basic;

        &:not(:first-child) {
          margin-left: 8px;
        }
        &.open {
          font-size: 12px;
        }
        &.delete {
        }
      }
    }

    .add-from-template-btn {
      @extend %btn-dark-new;
      font-size: 12px;
      svg {
        margin-right: 4px;
      }
    }
  }
}
