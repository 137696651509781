@import '../../styles/shared.scss';

.list-settings-container {
  padding: 12px 24px;
  background-color: $darker;
  border-radius: 10px;
  color: white;
  z-index: 4;

  .settings-header-container {
    @extend %flex-row-middle-spread;

    .header {
      @extend %label;
      font-size: 12px;
      margin-bottom: 8px;
    }

    .expand-button {
      @extend %btn-basic;
    }
  }

  .all-settings {
    @extend %flex-row-middle;
    gap: 12px;
    flex-wrap: wrap;

    .setting {
      @extend %flex-row-middle;
      gap: 8px;

      &.disabled {
        opacity: 0.15;
      }

      .label {
        @extend %label;
        font-size: 10px;
      }

      input {
        @extend %input-shared;
        background-color: transparent;
        color: white;
        max-width: 60px;
        text-align: center;
        font-size: 12px;
        padding: 4px 6px;
      }
    }
  }
}
