@import '../../styles/shared.scss';

.consult-element-outer-container {
  margin-bottom: 32px;
  position: relative;

  .element-container {
    @extend %flex-col;

    .question {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 12px;
      margin-right: 40px;

      .note {
        font-size: 11px;
        font-weight: normal;
        margin-top: 2px;
        color: $dark;
      }
    }

    input,
    textarea {
      resize: none;

      &.question {
        @extend %input-inline;
        font-size: 16px;
        width: 100%;
        padding-right: 36px;
      }

      &.answer {
        @extend %input-shared;
        font-size: 16px;
        width: 100%;

        &.placeholder {
          color: $light;
        }
      }
    }

    .extra-control-btns {
      @extend %flex-row-middle;

      .control-btn {
        padding-right: 6px;
        margin-right: 6px;

        @include md {
          padding-right: 12px;
          margin-right: 12px;
        }

        &:not(:last-child) {
          border-right: $border-lightest;
        }
        &:last-child {
          padding-right: 0;
          margin-right: 0;
        }
      }
    }
  }

  &:hover {
    .action-els {
      opacity: 1;
      transform: scale(1);
    }
  }

  .action-els {
    @extend %flex-row-middle;
    position: absolute;
    top: 0;
    right: 0;

    @include md {
      opacity: 0;
      transition: all 0.1s ease-in-out;
      transform: scale(0);
    }

    .icn {
      margin-left: 8px;
    }

    .delete-icn {
      color: $light;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
    .reorder-icn {
    }
  }
}
