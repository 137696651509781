@import '../../styles/shared.scss';

.custom-tooltip-outer-container {
  position: relative;

  .custom-tooltip-wrapper {
    position: absolute;
    bottom: 100%;
    right: 100%;
  }
}
