@import '../../styles/shared.scss';

.pro {
  .landing-header-panel-outer-container {
    background: $darker;
    .landing-header-panel-container .landing-header-panel-left {
      h1 {
        font-size: 30px;

        @include md {
          font-size: 34px;
        }

        @include xl {
          font-size: 48px;
        }
      }
      .btn {
        color: $darkest !important;
      }
    }
  }
}

.landing-header-panel-outer-container {
  @extend %flex-col-center;
  width: 100%;
  height: calc(80vh + 240px);
  margin-top: -240px;
  padding-top: 240px;
  position: relative;
  overflow: hidden;
  font-family: $font-body;

  .landing-header-panel-container {
    @extend %flex-row;
    width: calc(100% - 36px);
    max-width: 1240px;
    position: relative;
    height: 100%;

    @include md {
      padding: 24px;
    }

    @include xl {
      padding: 24px 0;
    }

    .landing-header-panel-left {
      @extend %flex-col;
      padding-top: 20vh;
      color: white;
      z-index: 2;
      color: white;

      @include md {
        max-width: 50vw;
      }

      @include xl {
        max-width: 680px;
      }

      h1 {
        font-size: 34px;
        line-height: 1.15;
        font-weight: 700;
        letter-spacing: -0.6px;

        font-family: $font-header-sl;
        margin: 0;

        @include md {
          font-size: 40px;
        }

        @include xl {
          font-size: 54px;
        }
      }

      h2 {
        margin: 16px 0 0;
        line-height: 1.1;
        font-weight: 400;
        font-size: 16px;
        font-family: $font-body-new;

        @include md {
          font-size: 20px;
          max-width: 440px;
        }

        @include xl {
          font-size: 24px;
          max-width: 100%;
        }
      }

      .btns {
        @extend %flex-row-middle;

        .btn {
          @extend %btn-white;
          align-self: flex-start;
          padding: 6px 26px;
          margin-top: 24px;
          font-size: 18px;
          border: 1px solid white;
          color: $darker;
          letter-spacing: 0.9px;
          font-weight: 800;
          font-family: $font-header-sl;

          @include md {
            font-size: 18px;
          }

          @include xl {
            font-size: 18px;
          }
        }

        .how-to-guide-btn {
          @extend %btn-outline;
          margin-left: 12px;
          background: transparent;
          color: white;
        }
      }
    }
  }

  .landing-header-floating-products {
    @extend %flex-row;
    height: calc(80vh + 240px);
    top: 0px;
    position: absolute;
    right: -20vw;
    width: 80vw;
    display: none;

    @include md {
      display: inherit;
      right: -20vw;
      width: 60vw;
    }

    @include lg {
      right: -8vw;
      width: 54vw;
    }

    @include xl {
      right: -5vw;
      width: 46vw;
    }

    .floating-products-col {
      align-self: stretch;
      flex: 1;

      &.left {
        margin-right: 12px;

        @include md {
          margin-right: 24px;
        }

        .floating-product {
          transform: translateY(-240%);

          &.moving {
            transform: translateY(200px);
          }
        }
      }

      &.right {
        .floating-product {
          transform: translateY(200px);

          &.moving {
            transform: translateY(-240%);
          }
        }
      }

      .floating-product {
        @extend %flex-center;
        width: 100%;
        height: calc(80vw / 2);
        width: calc(80vw / 2);
        margin-bottom: 12px;
        background: white;
        border-radius: 10px;
        transition: transform 40s linear;
        box-shadow: $shadow-dark;

        @include md {
          height: calc(60vw / 2);
          width: calc(60vw / 2);
          margin-bottom: 24px;
        }

        @include lg {
          height: calc(54vw / 2);
          width: calc(54vw / 2);
        }

        @include xl {
          height: calc(46vw / 2);
          width: calc(46vw / 2);
        }

        &:nth-child(1) {
          img {
            transition-delay: 0.1s;
          }
        }
        &:nth-child(2) {
          img {
            transition-delay: 0.2s;
          }
        }
        &:nth-child(3) {
          img {
            transition-delay: 0.3s;
          }
        }
        &:nth-child(4) {
          img {
            transition-delay: 0.4s;
          }
        }
        &:nth-child(5) {
          img {
            transition-delay: 0.5s;
          }
        }
        &:nth-child(6) {
          img {
            transition-delay: 0.6s;
          }
        }
        &:nth-child(7) {
          img {
            transition-delay: 0.7s;
          }
        }
        &:nth-child(8) {
          img {
            transition-delay: 0.8s;
          }
        }
        &:nth-child(9) {
          img {
            transition-delay: 0.9s;
          }
        }
        &:nth-child(10) {
          img {
            transition-delay: 1s;
          }
        }

        img {
          width: 64%;
          height: 64%;
          position: absolute;
          left: 18%;
          top: 18%;
          object-fit: cover;
          transition: opacity 1.2s cubic-bezier(0.16, 0.33, 0.24, 1.22);
          opacity: 0;

          &.active {
            opacity: 1;
            transition: opacity 1.2s cubic-bezier(0.53, -0.01, 0.87, -0.01);
          }
        }
      }
    }
  }
}
