@import '../../styles/shared.scss';

.mention-results-outer-container {
  max-width: 90vw; // ENSURE_SAME_MAX_WIDTH
  margin-bottom: 64px;

  @include md {
    max-width: 760px; // ENSURE_SAME_MAX_WIDTH
  }
  @include lg {
    max-width: 680px; // ENSURE_SAME_MAX_WIDTH
  }
  @include xl {
    max-width: 860px; // ENSURE_SAME_MAX_WIDTH
  }
  @include xxl {
    max-width: 960px; // ENSURE_SAME_MAX_WIDTH
  }

  .mention-results-columns {
    @extend %flex-row;
    gap: 8px;

    @include md {
      gap: 8px;
    }
    @include lg {
      gap: 12px;
    }
    @include xl {
      gap: 16px;
    }
    @include xxl {
      gap: 16px;
    }

    .mention-results-column {
      @extend %flex-col;
      gap: 8px;
      width: calc(90vw / 3 - 8px * 2 / 3); // ENSURE_SAME_MAX_WIDTH

      @include md {
        gap: 8px;
        width: calc(600px / 3 - 8px * 2 / 3); // ENSURE_SAME_MAX_WIDTH
      }
      @include lg {
        gap: 12px;
        width: calc(680px / 3 - 12px * 2 / 3); // ENSURE_SAME_MAX_WIDTH
      }
      @include xl {
        gap: 16px;
        width: calc(860px / 3 - 16px * 2 / 3); // ENSURE_SAME_MAX_WIDTH
      }
      @include xxl {
        gap: 16px;
        width: calc(960px / 3 - 16px * 2 / 3); // ENSURE_SAME_MAX_WIDTH
      }
    }
  }

  .mention-results-empty-container {
    background: #ffffff;
    padding: 24px;
    position: relative;

    .mention-results-empty-message {
      font-size: 11px;
      text-align: center;
      font-style: italic;
      color: $medium;
    }
  }
}
