@import '../../../styles/shared.scss';

.opportunity-leaderboard-card {
  @extend %flex-col;
  background-color: white;
  background-color: $white;
  position: relative;
  border-radius: 10px;
  transition: border 0.2s ease;
  border: 2px solid transparent;

  &.rank-1 {
    border-width: 2px;
    border-color: $primary;
  }

  &.rank-2 {
    border-width: 2px;
    border-color: $primaryLight;
  }

  &.rank-3 {
    border-width: 2px;
    border-color: $primaryLighter;
  }

  .collapsed-card {
    @extend %flex-row-middle;
    cursor: pointer;
    gap: 36px;
    padding: 12px 30px;

    &:hover {
      .media-container {
        transform: scale(0.9);
      }
    }

    .ranking-status {
      @extend %flex-row-middle;
      gap: 16px;

      &.rank-1 .rank {
        color: $primary;
        font-size: 28px;
      }

      &.rank-2 .rank {
        color: $primaryLight;
        font-size: 28px;
      }

      &.rank-3 .rank {
        color: $primaryLighter;
        font-size: 28px;
      }

      .rank {
        @extend %flex-center;
        width: 24px;
        font-size: 20px;
        font-weight: bold;

        .st {
          font-size: 12px;
        }
      }
      .user-image-container {
        @extend %flex-center;
        position: relative;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        transition: 0.2s ease;

        &:hover {
          cursor: pointer;
          transform: scale(1.05);
        }

        &.rank-1,
        &.rank-2,
        &.rank-3 {
          .image-badge-wrapper {
            display: flex;
          }
          img {
            border: 3px solid white;
          }
        }

        &.rank-1 {
          .image-badge-wrapper {
            opacity: 1;
          }
        }

        &.rank-2 {
          .image-badge-wrapper {
            background-color: $primaryLight;
          }
        }

        &.rank-3 {
          .image-badge-wrapper {
            background-color: $primaryLighter;
          }
        }

        .image-badge-wrapper {
          position: absolute;
          width: calc(100% + 6px);
          height: calc(100% + 6px);
          background-color: $primary;
          border-radius: 50%;
          top: calc(6px / -2);
          left: calc(6px / -2);
          display: none;
        }

        img,
        .empty-image {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }

        .empty-image {
          @extend %flex-center;
          background-color: $black;

          .initials {
            font-size: 24px;
            color: $white;
            font-family: $font-header;
            font-weight: 500;
          }
        }
      }
    }

    .media-container {
      @extend %flex-row;
      position: relative;
      height: 80px;
      width: calc(80px + 24px * 0); // OFFSET
      margin-right: calc(24px * 2); // OFFSET
      transition: transform 0.25s ease;

      &.featured-card {
        height: 120px;
      }

      .single-image-container {
        @extend %flex-center;
        img {
          width: 60px;
          height: 60px;
          border-radius: 10px;
          object-fit: cover;
        }
      }

      .media-img-container {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
        z-index: 3;
        transition: transform 0.25s ease;
        box-shadow: $shadow-offwhite;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.25s ease;
        }

        &:nth-child(2) {
          left: 24px; // OFFSET
          z-index: 2;
          transform: scale(0.9);

          img {
            opacity: 0.5;
          }
        }

        &:nth-child(3) {
          left: calc(24px * 2); // OFFSET
          z-index: 1;
          transform: scale(0.8);

          img {
            opacity: 0.25;
          }
        }
      }
    }

    .main-container {
      @extend %flex-row-spread;
      flex: 1;
      gap: 24px;

      .section {
        @extend %flex-col-middle;
        &.user-info {
          width: 240px;
        }
        &.spend {
          width: 120px;
        }
        &.results {
          flex: 1;
        }

        .section-title {
          @extend %flex-row-middle;
          @extend %label;
          font-size: 10px;
          color: $dark;
          margin-bottom: 12px;

          svg {
            margin-left: 4px;
            font-size: 10px;
          }
        }

        .section-data {
          @extend %flex-col;
          gap: 4px;

          .name {
            font-size: 18px;
            font-weight: bold;
            color: $darkest;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }

            // Overflow with ellipsis
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .progress-steps {
            @extend %flex-row;
            flex-wrap: wrap;
            gap: 4px;

            .progress-step {
              @extend %btn-basic;
              @extend %flex-row-middle;
              gap: 4px;
              font-size: 10px;
              color: $dark;
              border-radius: 10px;
              border: $border-light;
              font-weight: bold;
              padding: 2px 6px;

              &.complete {
                background-color: $primaryLightest;
                border: $border-primary;
                color: $primary;
                padding: 2px 6px 2px 4px;
              }

              svg {
                font-size: 10px;
                margin-left: 4px;
              }
            }
          }
        }

        .section-stats {
          @extend %flex-row;
          gap: 30px;
          margin-top: 8px;

          .section-stat {
            @extend %flex-col-center;
            gap: 4px;
            flex: 1;
            max-width: 65px;
            position: relative;

            &.featured {
              position: relative;

              .value {
                color: $primary;
                font-weight: bold;
              }
              .label {
                color: $primary;
                font-weight: bold;
                white-space: nowrap;
              }

              .background-highlight {
                left: calc(24px / -2);
                top: calc(24px / -2);
                height: calc(100% + 24px);
                width: calc(100% + 24px);
                background-color: $primaryLightest;
                opacity: 0.5;
                border-radius: 10px;
                position: absolute;
              }
            }

            .debug-tooltip {
              @extend %flex-col;
              background-color: $offwhite;
              border: $border-lightest;
              box-shadow: $shadow-light;
              color: $darker;
              border-radius: 10px;
              padding: 12px;
              font-size: 11px;
              font-weight: 500;
              line-height: 1.4;
              border-bottom-right-radius: 0;
              transform: translateX(4px);
              text-align: left;
              white-space: pre-wrap;
              width: 200px;
              max-width: 200px;
            }

            .value {
              @extend %flex-row;
              font-size: 18px;
              line-height: 18px;
              min-height: 18px;
              color: $darkest;
              position: relative;
              font-weight: 500;

              &.empty {
                color: $light;
                font-size: 18px;
                font-weight: normal;
              }

              .loader {
                @extend %flex-center;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
              }
            }

            .label {
              font-size: 10px;
              color: $dark;
              opacity: 1;
              text-align: center;

              &.empty {
                color: $light;
              }

              &.fetching {
                color: $lighter;
              }
            }

            .ai-result {
              @extend %flex-row-middle;
              position: absolute;
              top: 100%;
              margin-top: 4px;
              background: $medium;
              padding: 2px 12px;
              color: white;
              border-radius: 12px;
              white-space: nowrap;
              z-index: 2000;

              &.highest {
                background-color: $primary;
              }

              &.high {
                background-color: $primaryLight;
              }

              &.low {
                background-color: $errorLight;
              }

              &.lowest {
                background-color: $error;
              }

              &.normal {
                background-color: $light;
              }

              svg {
                margin-right: 4px;
              }

              .ai-label {
                @extend %label;
                width: 100%;
                height: 100%;
                font-size: 8px;
              }
            }
          }
        }
      }
    }
  }
  .expanded-card {
  }

  &:hover {
    .toggle-expanded-overlay {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .toggle-expanded-overlay {
    @extend %label;
    @extend %flex-center;
    opacity: 0;
    width: 100%;
    position: absolute;
    font-weight: normal;
    bottom: 4px;
    left: 0;
    color: $light;
    font-size: 8px;
    pointer-events: none;
    transition: all 0.2s;
    transform: translateY(-6px);

    svg {
      margin-left: 4px;
    }
  }
}
