@import '../../styles/shared.scss';

.opportunity-results-outer-container {
  .opportunity-result-sections {
    @extend %flex-col;
    gap: 54px;

    .opportunity-result-empty {
      @extend %btn-basic;
      @extend %flex-center;
      background-color: $offwhite;
      border: $border-lightest;
      padding: 48px 24px;
      border-radius: 10px;
      margin-top: 12px;
      width: 100%;
      font-size: 30px;
      color: $lighter;
      min-height: 280px;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: $prettymuchwhite;
        border-color: $lighter;
      }
    }

    .search-results-empty {
      @extend %flex-center;
      background-color: $offwhite;
      border: $border-lightest;
      padding: 48px 24px;
      border-radius: 10px;
      margin-top: 12px;
      width: 100%;
      font-size: 18px;
      color: $lighter;
      min-height: 280px;
      transition: background-color 0.2s ease;
    }

    .opportunities-section {
      .section-title-container {
        @extend %flex-center;
        margin-bottom: 24px;
        gap: 12px;
        cursor: default;

        &.show-toggle {
          @extend %btn-basic;
        }

        .section-title {
          text-transform: uppercase;
          color: $light;
          font-weight: 500;
          font-size: 12px;
          text-align: center;
        }

        svg {
          font-size: 11px;
          color: $light;
        }
      }
      .opportunities {
        @extend %flex-col;
        gap: 60px;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  20% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    opacity: 0.4;
    transform: scale(1.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
