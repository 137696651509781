@import '../variables.scss';
@import '../../styles/shared.scss';

.sms .nav-top-old {
  width: 100%;
  z-index: 5;
  position: fixed;
  transition: all 0.15s ease-in-out;
  font-family: $font-body;

  &.scrolled {
    transform: translateY(-20px);

    @include md {
      transform: none;
    }
  }

  .nav-top-outer-container {
    width: 100%;
    background: $darker;
    z-index: 10;
    position: relative;

    &.referral {
      background: transparent;
      border-bottom: $border-transparent;
      transition: background 0.2s ease-in-out;
      padding-bottom: 24px;

      &.scrolled {
        .before {
          opacity: 1;
        }
      }

      .before {
        background: $darker;
        transition: opacity 0.3s;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 64px;
        opacity: 0;
        z-index: 2;
        pointer-events: none;
      }

      .nav-top-inner-container.referral .header-secondary-container.referral {
        .nav-hamburger-wrapper {
          .nav-hamburger {
            &.scrolled {
              transform: none;
            }
          }
        }
      }
    }

    .nav-top-inner-container {
      @extend %flex-row-middle-spread;
      width: 100%;
      max-width: 1024px;
      margin: 0 auto;
      padding: 0 16px;
      height: $desktopNavHeight;
      color: white;
      position: relative;

      @include xl {
        padding: 0 0;
      }

      &.referral {
        max-width: 1240px;

        .header-main-container {
          z-index: 5;
        }

        .header-secondary-container {
          z-index: 3;

          .nav-hamburger-wrapper svg {
            @include md {
              color: $darkest;
            }
          }

          &.scrolled {
            .nav-hamburger-wrapper svg {
              color: $white;
            }
          }
        }

        .more-options-menu-outer-container {
          z-index: 3;
        }
      }

      .mobile {
        min-width: 24px;

        @include md {
          display: none;
        }

        .best-in-beauty-container {
          transition: all 0.1s ease-in-out;
          transform: translateY(2px) translateX(2px);

          .icon {
            font-size: 21px;
            color: white;
          }

          &.scrolled {
            transform: translateY(10px) scale(0.93);
          }
        }

        .search-container {
          @extend %flex-row-middle;
          transition: all 0.1s ease-in-out;

          &.scrolled {
            .search-icon {
              transform: translateY(8px) scale(0.88);
            }
          }

          .search-icon {
            font-size: 18px;
            margin-bottom: -4px;
          }

          &.showing-search {
            width: 100%;

            input {
              display: flex;
            }

            .clear-search-icon {
              display: inherit;
              background: $darker;
            }
          }

          input {
            @extend %input-inline;
            width: 100%;
            display: none;
            font-family: $font-body;
            background: transparent;
            border: none;
            max-width: 1024px;
            font-size: 20px;
            margin-left: 12px;
            flex: 1;
            color: white;
            font-weight: bold;

            &::placeholder {
              color: white;
              font-weight: 300;
            }

            &:focus {
              border: none;
            }
          }

          .clear-search-icon {
            position: absolute;
            right: 0;
            padding: 16px;
            font-size: 22px;
            display: none;
          }
        }
      }
      .desktop {
        display: none;

        @include md {
          display: inherit;
        }

        &.search-container {
          flex: 1;
          margin: 0 20% 0 48px;
          position: relative;

          input {
            @extend %input-inline;
            width: 100%;
            font-family: $font-body;
            background: transparent;
            max-width: 1024px;
            font-size: 16px;
            border-radius: 0;
            padding: 4px 0 4px;
            flex: 1;
            color: white;
            font-weight: bold;
            background: rgba(0, 0, 0, 0.1);
            border: 1px solid rgba(255, 255, 255, 0.4);
            border-radius: 8px;
            padding: 4px 12px;

            &::placeholder {
              color: white;
              font-weight: 300;
            }

            &:focus {
              border: 1px solid rgba(255, 255, 255, 0.8);
            }
          }

          .clear-search-icon {
            position: absolute;
            right: 8px;
            top: 6px;
            cursor: pointer;
          }
        }
      }

      .header-main-container {
        @extend %flex-row;
        .logo-text {
          font-size: 20px;
          font-family: $font-header;
          font-weight: bold;
          cursor: pointer;
          font-size: 22px;
          white-space: nowrap;
          letter-spacing: -0.5px;
          transition: all 0.15s ease-in-out;

          &.showing-search {
            display: none;
          }

          .brand-theme-logo {
            max-height: 40px;
            margin-right: 6px;
            max-width: 160px;
            filter: brightness(0) invert(1);
            object-fit: contain;
          }

          &.scrolled {
            transform: translateY(9px) scale(0.8);

            @include md {
              transform: none;
            }
          }
        }

        .nav-tabs {
          @extend %flex-row;
          align-items: stretch;
          margin-left: 40px;
          display: none;
          margin-bottom: -15px;
          font-family: $font-body;

          @include md {
            display: flex;
          }
          .nav-tab {
            margin-right: 12px;
            color: white;
            font-weight: bold;
            padding: 8px 12px;
            border-bottom: 5px solid transparent;
            text-decoration: none;
            text-transform: uppercase;

            &.active {
              border-color: white;
            }

            @include md {
              display: flex;
              font-size: 13px;
              padding: 8px 4px;
              margin-right: 8px;
            }

            @include lg {
              display: flex;
              font-size: 15px;
              padding: 8px 12px;
            }
          }
        }
      }

      .header-secondary-container {
        @extend %flex-row-middle;
        .action-btn {
          @extend %btn-basic;
          margin-right: 16px;
          text-transform: uppercase;
          padding: 2px 8px;
          border: $border-white;
          border-radius: 4px;
          font-size: 12px;
          font-weight: bold;
          transform: translateY(2px);
          transition: all 0.15s ease-in-out;

          @include md-down {
            display: none;
          }
        }
        .nav-hamburger-wrapper {
          position: relative;

          .nav-hamburger {
            font-size: 20px;
            cursor: pointer;
            transition: all 0.15s ease-in-out;
            margin-bottom: -4px;
            min-width: 24px;

            &.close {
              transform: scale(1.1);
            }

            &.scrolled {
              transform: translateY(9px) scale(0.9);

              @include md {
                transform: none;
              }
            }

            path {
              pointer-events: none; // to enable click away
            }
          }

          .notification-count {
            @extend %flex-center;
            position: absolute;
            top: -6px;
            right: -6px;
            background: $error;
            transition: all 0.15s ease-in-out;
            font-size: 12px;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            font-weight: bold;
            pointer-events: none;

            &.scrolled {
              transform: translateY(9px) scale(0.9);

              @include md {
                transform: none;
              }
            }
          }
        }
      }

      button {
        background: transparent;
        appearance: none;
        font: inherit;
        border: none;
        padding: 0;
        font-size: inherit;
        color: inherit;
        outline: none;
      }
    }
  }
}
