@import '../../../styles/shared.scss';

.lookbook-preview-outer {
  border: $border-lightest;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  aspect-ratio: 2;
  max-height: 400px;
  transition: transform 0.25s ease-in-out;

  &:hover {
    transform: scale(0.99);
  }

  .lookbook-preview-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      transition: transform 0.25s ease-in-out;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }

  .lookbook-preview-inner {
    position: relative;
    height: 100%;
    @extend %flex-col;
    justify-content: flex-end;

    .options-panel {
      @extend %flex-row;
      gap: 20px;
      position: absolute;
      bottom: 15px;
      right: 15px;

      .triple-dot {
        svg {
          color: white;
          height: 20px;
          width: 20px;
        }
      }

      .public-toggle {
        @extend %label;
        @extend %flex-row;
        gap: 5px;
        font-size: 0.75rem;
        align-items: center;
        color: $dark;
      }

      .triple-dot-options {
        @extend %flex-col;
        gap: 10px;
        position: absolute;
        bottom: calc(100% + 10px);
        right: 0;
        background-color: white;
        border: $border-lightest;
        padding: 10px;
        border-radius: 5px;

        .option {
          @extend %btn-basic;
          @extend %label;
          background-color: white;
          border-radius: 5px;
          font-size: 11px;
          cursor: pointer;
          border: 1px solid transparent;

          &:hover {
            color: $dark;
          }

          svg {
            margin-right: 5px;
            color: black;
            height: 10px;
            width: 10px;
          }
        }
      }
    }

    .text-container {
      padding: 64px 16px 16px;
      @extend %flex-col;
      color: white;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);

      .lookbook-data-container {
        @extend %flex-row;
        align-items: center;
        gap: 24px;
        margin-bottom: 10px;

        .lookbook-data-item {
          text-align: center;

          .lookbook-data-item-value {
            font-size: 16px;
            font-weight: 500;
          }

          .lookbook-data-item-label {
            font-size: 11px;
            opacity: 0.75;
          }
        }
      }

      .lookbook-title {
        font-family: $font-header;
        font-weight: normal;
        font-size: 36px;
        font-weight: 500;
        margin-bottom: 4px;
      }

      .lookbook-subtitle {
        @extend %flex-row;
        gap: 5px;
        font-size: 12px;
      }
    }
  }
}
