@import '../../../../styles/shared.scss';

.lookbook-report-product-outer {
  height: 100%;

  .lookbook-report-product-inner {
    @extend %flex-col;
    // justify-content: space-between;
    gap: 15px;
    background-color: white;
    border-radius: 16px;
    height: 100%;

    .product-image {
      @extend %label;
      aspect-ratio: 1;
      width: 100%;
      border-radius: 10px 10px 0 0;
      width: 100%;
      aspect-ratio: 1;
      display: grid;
      place-items: center;
      background-color: $lighter;
      color: $light;
      border-radius: 10px 10px 0 0;
      pointer-events: none;
      font-size: 0.7rem;

      &.original {
        aspect-ratio: unset;
      }

      &.cover {
        object-fit: cover;
      }

      &.contain {
        object-fit: contain;
      }
    }

    .product-text-container {
      @extend %flex-col;
      gap: 3px;
      padding: 8px 16px 12px;

      .product-title {
        font-size: 20px;
        line-height: 24px;
        color: black;
        white-space: normal;
        font-weight: 600;
      }

      .product-quantity {
        font-size: 0.75rem;
        font-weight: normal;
        color: $light;
        text-decoration: none;
      }

      .sibling-selection-breakdown-container {
        @extend %flex-row;
        gap: 10px;
        flex-wrap: wrap;

        .sibling-selection-breakdown {
          @extend %btn-basic;
          font-size: 0.75rem;
          font-weight: normal;
          color: $light;
          text-decoration: none;

          span {
            margin-left: 5px;
            background-color: $dark;
            color: white;
            padding: 2px 7px;
            border-radius: 600px;
            font-size: 0.6rem;
          }
        }
      }
    }

    .lookbook-report-product-price {
      font-size: 1rem;
      font-weight: bold;
    }

    .lookbook-report-product-url {
      font-size: 0.8rem;
      font-weight: normal;
    }
  }
}
