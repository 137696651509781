@import '../../styles/shared.scss';

.opportunity-plans-container {
  .opportunity-plan-header-container {
    @extend %flex-col;
    background-color: white;
    border-radius: 20px 20px 0 0;
    overflow: hidden;

    @include md-down {
      border-radius: 0;
    }
  }
  .opportunity-plan-results-container {
  }
}
