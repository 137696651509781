@import '../../styles/shared.scss';

.collection-outer-header-container {
  @extend %flex-col;

  .collection-header {
    @extend %flex-col;
    width: 100%;
    max-width: 1024px;
    padding: 44px 20px 32px;
    margin-bottom: 12px;

    @include md-down {
      padding: 44px 20px 16px;
      margin-bottom: 0;
    }

    &.longform {
      max-width: 800px;
      padding: 44px 32px 12px;
      margin-bottom: 0;

      .title-container {
        border-bottom: none;
        padding-bottom: 0;

        .description-input-container {
          max-width: 100%;
        }
      }
    }

    &.compact.mobile {
      max-width: 800px;
      padding: 44px 20px 12px;
      margin-bottom: 0;

      .title-container {
        .description-input-container {
          max-width: 100%;
        }
      }
    }

    &.horizontal {
      .title-container {
        .description-input-container {
          max-width: 100%;
        }
      }
    }

    .top-row {
      @extend %flex-row-spread;
      width: 90%;
      position: absolute;

      .back-link {
        &.btn {
          @extend %btn-text;
          svg {
            margin-right: 8px;
            font-size: 10px;
          }
        }

        @extend %label;
        text-decoration-line: none;
        font-family: $font-body;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        color: $dark;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        .back {
          margin-right: 8px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $dark;
        }
      }
    }

    .title-container {
      margin-top: 44px;
      padding-bottom: 12px;
      border-bottom: 2px solid $offwhite;

      .collection-name {
        font-family: $font-header;
        font-size: 40px;
        line-height: 1.15;
        font-weight: bold;
        color: $darker;
        margin: 0;
        letter-spacing: -0.16px;
      }

      .name-input-container {
        @extend %input-inline;
        font-weight: bold;
        margin-bottom: 0px;
        background-color: transparent;
        font-family: $font-header;
        letter-spacing: -0.27px;
        font-size: 40px;
        line-height: 1.15;
        color: $darker;
        width: 100%;
        resize: none;
      }

      .collection-description {
        font-family: $font-body;
        font-size: 14px;
        line-height: 18px;
        font-weight: normal;
        color: $darker;
        margin: 0;
        letter-spacing: -0.16px;
        margin-top: 8px;
      }

      .description-input-container {
        @extend %input-inline;
        margin-top: 8px;
        font-weight: normal;
        margin-bottom: 0px;
        background-color: transparent;
        font-family: $font-body;
        letter-spacing: -0.27px;
        font-size: 14px;
        line-height: 18px;
        color: $darker;
        max-width: 90%;
        width: 100%;
        resize: none;
      }
    }

    .meta-data-container {
      @extend %flex-col;
      font-family: $font-body;
      align-items: flex-start;
      padding-top: 12px;
      flex-wrap: wrap;

      @include md {
        flex-direction: row;
        align-items: center;
      }

      .section {
        @extend %flex-row-middle;
        padding: 0 0 6px;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 1;
        color: $darker;
        min-height: 16px;
        width: 100%;

        @include md {
          padding: 0 12px;
          border-right: $border-lighter;
          width: auto;
        }

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          flex: 1;
          border-right: none;
        }

        &.disclaimer {
          text-transform: none;
          text-align: center;
          flex: 1;
          font-style: italic;
        }

        &.owner {
          @extend %flex-row-middle;
          text-decoration: none;
          color: auto;
          color: $darker;

          img {
            height: 16px;
            width: 16px;
            border-radius: 8px;
            margin-right: 4px;

            @include md {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}
