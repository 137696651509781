@import '../../styles/shared.scss';

.brand-faq-outer-container {
  .section {
    margin-bottom: 72px;

    .section-header {
      margin-bottom: 32px;
    }
    .basic-outer-container {
      .basic-body-container {
        .basic-body-section {
          .basic-body-desc {
            margin-top: 0;
          }
        }
      }
    }

    a {
      font-weight: bold;
      color: $dark;
    }
  }
}
