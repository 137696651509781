@import '../../styles/shared.scss';

.product-social-content-outer-container {
  .social-content-container {
    @extend %flex-row;
    flex-wrap: wrap;

    .social-content {
      width: calc(100% - 48px);
      margin-bottom: 24px;

      @include md {
        width: 50%;
        padding: 12px;

        &.only-one {
          margin: 0 auto;
          width: 100%;

          .youtube-video {
            @include md {
              max-height: 240px;
              min-height: 300px;
            }
          }
        }
      }

      .youtube-video {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        overflow: hidden;
        max-height: 200px;
        min-height: 260px;
        margin-left: -24px;
        width: calc(100% + 48px);

        @include md {
          margin-left: 0;
          width: 100%;
          min-height: 180px;
          max-height: 180px;
        }
      }

      .social-user {
        @extend %flex-col;
        padding: 8px 0;

        @include md {
          padding: 8px;
        }

        .featured-tag {
          font-size: 14px;
          color: $dark;

          @include md {
            font-size: 12px;
          }
        }

        .collection {
          text-decoration: none;
          color: $darker;
          font-size: 16px;
          font-weight: bold;
          &:hover {
            text-decoration: underline;
          }
        }
        .name {
          text-decoration: none;
          color: $darker;
          font-size: 14px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
