@import '../../../styles/shared.scss';

.planning-panel-only-message-container {
  @extend %flex-center;
  gap: 24px;
  margin-bottom: 24px;
  background-color: $darkest;
  padding: 24px 30px;
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;

  .main-message {
    margin: 0 24px;
    padding: 24px;
    background-color: white;
    border-radius: 10px;

    .beta-disclaimer-header {
      font-size: 24px;
      font-weight: bold;
      color: $darkest;
    }

    .beta-disclaimer-body {
      font-size: 14px;
      color: $darker;
      line-height: 1.4;
      margin-top: 8px;
    }

    .actions {
      @extend %flex-row-middle;
      gap: 8px;
      margin-top: 24px;
      .action {
        @extend %btn-outline-new;
        padding: 8px 20px;
        font-size: 12px;

        &.primary {
          @extend %btn-primary;
          font-weight: bold;
          text-transform: uppercase;
          padding: 8px 20px;
          font-size: 12px;
        }
      }
    }
  }

  // .main {
  //   @extend %flex-row-middle;
  //   gap: 24px;

  //   .graphic {
  //     @extend %flex-center;
  //     min-width: 40px;
  //     min-height: 40px;
  //     width: 40px;
  //     height: 40px;
  //     border-radius: 4px;
  //     font-size: 36px;
  //     color: white;
  //   }

  //   .message {
  //     color: white;
  //     font-size: 12px;
  //     line-height: 1.6;

  //     .link {
  //       @extend %btn-basic;
  //       font-weight: bold;
  //       text-decoration: underline;
  //     }
  //   }
  // }
}
