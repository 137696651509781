@import '../../styles/shared.scss';

.talent-outer-container {
  @extend %flex-col;
  width: 100%;
  padding: 100px 24px 0;
  position: relative;
  font-family: $font-body-new;
  background: $offwhite;
  min-height: 100vh;

  .talent-inner-container {
    @extend %flex-row-center;
    min-height: 800px;
    width: 100%;
    margin: 120px auto;
    transform: translateY(-120px);
    position: relative;

    &.with-details {
      @include lg {
        max-width: calc(880px + 280px);
      }
      @include xl {
        max-width: calc(880px + 320px);
      }
    }

    @include lg {
      max-width: calc(600px + 280px + 24px);
    }
    @include xl {
      max-width: calc(720px + 320px + 36px);
    }

    .talent-left-container {
      @include lg-down {
        display: none;
      }

      @include lg {
        max-width: 280px;
        min-width: 260px;
        margin-right: 24px;
      }
      @include xl {
        max-width: 400px;
        min-width: 320px;
        margin-right: 36px;
      }
    }
    .talent-main-container {
      width: 100%;
      position: relative;
      flex: 1;
    }
  }
}
