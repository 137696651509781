@import '../../styles/shared.scss';

.commissions-outer-container {
  @extend %flex-col;
  width: 100%;
  padding-bottom: 120px;
  position: relative;
  font-family: $font-body-new;
  background: $offwhite;

  .commissions-inner-container {
    @extend %flex-col;
    min-height: 800px;
    margin: 0 auto;
    width: 100%;
    padding: 24px;
    max-width: 1440px;

    .commissions-container {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;

      &.isFetchingResults {
        opacity: 0.5;
      }
    }

    .empty-results {
      @extend %flex-center;
      background: white;
      border-radius: 16px;
      padding: 24px;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
      color: $medium;
    }
  }
}
