@import '../../../styles/shared.scss';

.opportunity-planner-monthly-promoters {
  .grid-8 {
    grid-template-columns: 200px repeat(6, 1fr) 120px;
  }
  .grid-9 {
    grid-template-columns: 200px repeat(7, 1fr) 120px;
  }
  .grid-10 {
    grid-template-columns: 200px repeat(8, 1fr) 120px;
  }
  .grid-11 {
    grid-template-columns: 200px repeat(9, 1fr) 120px;
  }
  .grid-12 {
    grid-template-columns: 180px repeat(10, 1fr) 80px;
  }
  .grid-13 {
    grid-template-columns: 180px repeat(11, 1fr) 80px;
  }

  .additional-controls {
    @extend %flex-row-middle-spread;
    justify-content: flex-start;
    transform: translateY(-12px);
    gap: 8px;

    .main {
      @extend %flex-row;
      gap: 16px;
      flex: 1;

      .select-container {
        @extend %flex-col;
        gap: 2px;

        .metric-select-label-container {
          @extend %flex-row-middle;
          gap: 6px;

          .metric-select-label {
            font-size: 12px;
            color: $medium;
          }

          .tooltip-outer-container {
            @extend %flex-center;
            svg {
              color: $light;
              font-size: 10px;
              transform: translateY(-1px);
            }
          }
        }
        .shopmy-select {
          width: 200px;

          .shopmy-select__control {
            border-color: $lightest;
            color: $darkest;
            font-size: 14px;
            padding: 8px 14px;
            height: auto;
          }

          .shopmy-select__value-container {
            height: 20px;
            min-height: 20px;
          }

          .shopmy-select__single-value {
            padding-inline: 0;
            margin-inline: 0;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: revert;
            text-transform: unset;
            top: 60%;
          }

          [class*='-Input'] {
            height: 20px;
            min-height: 20px;
          }
        }
      }
    }

    .secondary {
      .disclaimer {
        text-align: right;
        font-size: 10px;
        color: $medium;
        max-width: 360px;

        svg {
          color: $light;
          margin-right: 4px;
        }
      }
    }
  }

  .results-header-old {
    display: grid;
    padding: 12px 0;
    background-color: $darkest;
    border-radius: 10px 10px 0 0;
    color: white;

    .header-cell {
      &.clickable {
        @extend %btn-basic;
      }
      .display {
        @extend %flex-center;
        gap: 4px;
        font-size: 11px;
        font-weight: bold;
        text-align: center;
        white-space: nowrap;

        svg {
          color: $dark;

          &.active-sort {
            color: white;
          }
        }
      }
    }
  }

  .results-header {
    display: grid;
    padding: 12px 0;
    border-radius: 10px 10px 0 0;
    color: $light;
    border-bottom: $border-lightest;

    .header-cell {
      &.clickable {
        @extend %btn-basic;
      }
      .display {
        @extend %flex-center;
        gap: 4px;
        font-size: 11px;
        text-align: center;
        white-space: nowrap;

        &.active-sort {
          color: $darkest;
          font-weight: bold;
          svg {
            color: $darkest;
            font-weight: bold;
          }
        }

        svg {
          color: $lightest;
        }
      }
    }
  }

  .results-container {
    @extend %flex-col;
    padding: 0 0;
    margin: 0 0 120px;
    transition: opacity 0.3s;
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    gap: 8px;

    &.fetching-first-page:not(.fetching-results) {
      opacity: 0.3;
    }

    .row {
      display: grid;
      border-bottom: $border-white;
      border-radius: 4px;
      padding: 2px 0;

      &:first-child {
        margin-top: 12px;
      }

      &.loading.row {
        .cell {
          max-height: 36px;
          text-align: center;
          background-color: $whynotusewhite;
        }
      }

      &.in-plan {
        background-color: $offwhite;
      }

      .creator-cell {
        @extend %btn-basic;
        @extend %flex-row-middle;
        overflow: hidden;
        align-self: flex-start;
        padding: 0 12px;

        img {
          width: 24px;
          height: 24px;
          min-width: 24px;
          min-height: 24px;
          border-radius: 50%;
          margin-right: 8px;
        }

        .data {
          .name {
            white-space: nowrap;
            font-weight: bold;
            font-size: 12px;
            letter-spacing: -0.2px;
          }

          .metadata {
            font-size: 9px;
            color: $medium;
          }
        }
      }

      .icon-cell {
        .display {
          @extend %flex-row-middle;
          position: relative;
          padding: 0 !important;

          .reasoning {
            svg {
              font-size: 10px;
              color: $lighter;
              margin-right: 4px;
              transform: translateY(-2px);
            }
          }

          .cost {
            @extend %btn-basic;
            @extend %flex-row-middle;
            min-width: 38px;
          }

          .icon {
            @extend %btn-basic;
            @extend %flex-center;
            position: absolute;
            left: 100%;
            top: 0;
            height: 100%;
            transform: translateX(2px) translateY(0px);
            color: $lighter;

            &.active {
              color: $darker;
            }

            svg {
              font-size: 10px;
            }
          }
        }
      }

      .cell {
        @extend %flex-center;
        text-align: center;
        white-space: nowrap;

        .display {
          padding: 6px 4px;
          font-weight: 500;
          border-radius: 24px;
          font-size: 12px;

          &.use-shading {
            width: 100%;
            margin: 0 2px;
          }

          &.use-shading,
          &.small {
            font-size: 10px;

            @include xl {
              font-size: 11px;
            }
          }

          &.disabled {
            filter: blur(4px);
          }

          .per-month {
            font-size: 8px;
            color: $light;
          }
        }

        .actions {
          .action {
            @extend %btn-darkest-new;
            font-size: 10px;
            position: relative;

            &.loading {
              color: transparent;
            }

            &.remove {
              background-color: white;
              border-color: $light;
              color: $dark;
            }

            .loader {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              margin-top: -3px;
            }
          }
        }
      }
    }
  }
}
