@import '../../../styles/shared.scss';

.lookbook-emphasized-note-outer-container {
  @extend %flex-col-center;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  padding-top: 80px;
  z-index: 2;

  @include md-down {
    padding-top: 20px;
  }

  .lookbook-emphasized-note-inner-container {
    @extend %flex-col-center;
    background-color: white;
    gap: 24px;
    border-radius: 10px;
    max-width: 90vw;
    border: $border-lightest;
    padding: 32px;
    position: relative;

    &.extra-short {
      width: 400px;
      padding: 40px;
    }

    &.short {
      width: 480px;
    }

    &.medium {
      width: 480px;
    }

    &.long {
      width: 540px;
      padding: 36px;
    }

    &.extra-long {
      width: 660px;
      padding: 40px;
    }

    .brand-logo {
      @extend %flex-center;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background: $darkest;

      img.icon {
        height: 75%;
        width: 75%;
        object-fit: contain;
        filter: brightness(0) invert(1);
      }
    }

    .note {
      font-size: 16px;
      line-height: 24px;
      color: $darkest;
      text-align: center;
      white-space: pre-wrap;
    }

    .dismiss-button {
      @extend %btn-outline-new;
      padding: 12px 24px;
      font-size: 12px;
    }

    .close-icon {
      @extend %btn-basic;
      position: absolute;
      top: 12px;
      right: 12px;
      font-size: 24px;
    }
  }
}
