@import '../../styles/shared.scss';

.shop-search-outer-container {
  @extend %flex-col;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 48px 16px 0;

  @include xl {
    padding: 24px 0 0;
  }

  .search-input-container {
    position: relative;
    max-width: 440px;

    input {
      @extend %input-shared;
      width: 100%;
      font-family: $font-body-new;
    }
    .has-actions {
    }
  }
}
