@import '../../../styles/shared.scss';

.onboarding-panel-social-container {
  @extend %flex-col-center;
  margin-bottom: 120px;

  @include xl {
    margin-bottom: 64px;
  }

  .social-link-sections {
    @extend %flex-col;
    margin-top: 36px;
    align-self: stretch;

    .social-link-section {
      margin-bottom: 6px;
      padding: 6px 8px;

      .section-header {
        @extend %flex-row-middle-spread;
        position: relative;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 4px;

        @include md-down {
          font-size: 14px;
        }

        .type {
        }
        .value {
        }
      }

      .section-actions {
        @extend %flex-row-spread;

        @include md-down {
          flex-direction: column;
        }

        .input-container {
          flex: 1;

          input {
            @extend %input-shared;
            width: 100%;
          }
        }

        .or {
          @extend %flex-center;
          align-self: stretch;
          padding: 4px 12px;
          font-size: 14px;
          font-weight: bold;
          color: $dark;
        }

        .link-btn {
          @extend %flex-center;
          @extend %btn-basic;
          text-align: center;
          color: white;
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          flex: 1;
          min-height: 40px;

          .check {
            font-size: 12px;
            margin-right: 8px;
          }

          img {
            margin-right: 12px;
            height: 12px;
          }

          &.instagram {
            background: $fb-blue;
          }

          &.youtube {
            background: $error;
          }

          &.tiktok {
            background: $darkest;
          }

          &.other {
            background: $light;
          }

          &.from-input {
            background: $darker;
            margin: 12px 0 0;

            @include md {
              margin: 0 0 0 12px;
              max-width: 120px;
            }

            img {
              display: none;
            }
          }

          &.complete {
            opacity: 0.3;
          }
        }
      }
    }
  }
}
