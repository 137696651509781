@import '../../../styles/shared.scss';

.about-outer-container {
  font-family: $font-body-new;
  width: 100%;

  .rounded-button {
    @extend %flex-center;
    border: $border-dark;
    border-radius: 5px;
    padding: 15px;
    font-size: 0.8rem;
    font-weight: 600;
    color: $dark;
    background: $white;
    width: max-content;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 1px;
    margin: 0 auto;
    font-family: inherit;
  }

  .about-intro-container {
    @extend %flex-col;
    gap: 30px;
    width: clamp(300px, 85%, 800px);
    text-align: center;
    margin: 100px auto;

    h1 {
      @extend %header-fancy-lg;
      margin: 0;
    }

    h2 {
      @extend %paragraph-md;
      font-weight: normal;
      // font-size: 1.25rem;
      margin: 0;
    }
  }

  .about-image-container {
    width: 100%;
    position: relative;
    margin: 0;

    img {
      width: 100%;
      height: clamp(400px, 50vw, 700px);
      aspect-ratio: 9/6;
      object-fit: cover;
    }
  }

  .about-team-container {
    width: 100%;
    background-color: $prettymuchwhite;
    padding-top: 100px;
    margin-top: -10px;
    overflow: hidden;

    .team-header {
      margin-left: 5vw;

      h3 {
        font-family: $font-header;
        font-size: 4rem;
        font-weight: normal;
        margin: 0;
        margin-bottom: 10px;
        text-transform: uppercase;
        position: relative;
        padding: 0;

        &::after {
          content: '';
          position: absolute;
          bottom: 13px;
          left: 370px;
          width: 100%;
          height: 1px;
          background-color: $dark;
          margin-top: 10px;
        }
      }

      p {
        font-size: 0.9rem;
        font-weight: normal;
        margin: 0;
        max-width: 400px;
      }
    }

    .departments-container {
      @extend %flex-col;
      gap: 50px;
      padding: 50px 0;
      width: 90%;
      margin: 0 auto;
    }

    .about-add-member-button {
      @extend %flex-row-middle;
      gap: 2px;
      position: fixed;
      bottom: 40px;
      left: 10px;
      background: white;
      z-index: 1000;
      border-radius: 8px;
      padding: 2px 8px;
      border: $border-dark;
      font-size: 12px;
      font-weight: bold;
      cursor: pointer;
      color: $darkest;
      font-family: $font-body;

      &:hover {
        color: $dark;
      }
    }
  }
}
