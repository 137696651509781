@import '../../styles/shared.scss';

.partner-portal-request-modal-outer {
  .partner-portal-request-modal-inner {
    max-width: 500px;

    @include md-down {
      max-width: 100%;
    }

    .request-modal-header-image-container {
      width: 100%;
      height: 180px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .full-image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      }

      .header-logo-container {
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
        display: grid;
        place-content: center;

        img {
          max-height: 100px;
          width: 100%;
          height: 100%;
          object-fit: contain;
          filter: brightness(0) invert(1);
        }
      }
    }

    .request-modal-text-container {
      padding: 30px;
      background-color: $offwhite;
      flex: 1;

      @include sm-down {
        padding: 10px;
      }

      .request-modal-heading {
        @extend %label;
        color: $dark;
        font-size: 0.7rem;
        margin-bottom: 10px;
        text-wrap: wrap;
        // @extend %header-fancy-md;

        // @include sm-down {
        //   font-size: 1.5rem;
        // }

        // text-transform: uppercase;
        // font-weight: normal;
      }

      .gifting-request-container {
        padding: 20px;
        background-color: white;
        border-radius: 16px;

        .request-still-deciding-message {
          @extend %label;
          color: $dark;
          font-size: 0.7rem;
          margin-bottom: 10px;
          text-wrap: wrap;
        }

        .request-modal-message {
          // padding: 15px;
          // border-radius: 15px;
          // background-color: white;
          font-size: 0.75rem;
          white-space: break-spaces;

          .lookbook-order-status-icon-container {
            @extend %label;
            @extend %flex-row;
            gap: 5px;
            padding: 10px 15px;
            background-color: $offwhite;
            border-radius: 5px;
            width: max-content;
          }

          .request-order-details {
            @extend %flex-row;
            justify-content: space-between;
            margin: 1rem 0;

            .request-order-detail {
              .request-order-detail-header {
                font-weight: bold;
                margin-bottom: 5px;
              }
            }
          }
        }

        .request-modal-button-container {
          @extend %flex-row;
          gap: 10px;
          margin-top: 20px;
          flex-wrap: wrap;

          .request-modal-button {
            @extend %btn-basic;
            @extend %label;
            padding: 10px 20px;
            font-size: 0.7rem;
            color: white;
            background-color: $primary;
            border-radius: 5px;
            border: none;
            outline: none;

            svg {
              height: 12px;
              width: 12px;
              margin-left: 5px;
            }

            &.close,
            &.dismiss-gifting {
              background-color: $offwhite;
              color: black;
            }
          }
        }
      }

      .brand-description-container {
        margin: 30px 0;
        @extend %flex-col;
        gap: 30px;

        .brand-description-section {
          .brand-description-header {
            @extend %label;
            font-size: 0.65rem;
            color: $light;
            margin-bottom: 5px;
          }

          .brand-description-body {
            font-size: 0.85rem;
            color: black;

            &.commission {
              font-family: $font-header;
              font-weight: normal;
              font-size: 2.2rem;
            }

            a {
              color: black;
            }

            .request-modal-chat-with-brand {
              @extend %btn-basic;
              font-size: 0.8rem;
              color: black;

              svg {
                margin-left: 5px;
                height: 10px;
                width: 10px;
              }
            }
          }
        }
      }
    }
  }
}
