@import '../../styles/shared.scss';

.chat-message-container {
  @extend %flex-row;
  margin-bottom: 12px;
  padding: 4px 16px;

  &.brand {
    .image-container {
      img {
        object-fit: contain;
      }
    }
  }

  &.you {
    justify-content: flex-end;
    .image-container {
      order: 2;
      margin-left: 12px;
    }
    .message-block {
      border-top-right-radius: 0;
    }
  }
  &.them {
    justify-content: flex-start;
    .image-container {
      margin-right: 12px;
    }
    .message-block {
      border-top-left-radius: 0;
    }
  }

  .image-container {
    &.clickable img {
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: scale(1.04);
        box-shadow: $shadow-light;
      }
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .message-block {
    @extend %flex-col;
    position: relative;
    background: $prettymuchwhite;
    border: $border-lightest;
    border-radius: 10px;
    padding: 12px 24px;
    max-width: 640px;
    color: $dark;
    font-size: 12px;
    margin-top: 4px;
    white-space: pre-line;

    .loader-outer-container {
      position: absolute;
      top: -20px;
      left: 0;
    }

    .manager-note {
      position: absolute;
      top: calc(100% + 4px);
      right: 0;
      font-size: 8px;
      color: $light;
      font-style: italic;
      white-space: nowrap;

      &.them {
        left: 0;
        right: auto;
      }
    }

    &.is-attachment {
      padding: 0;
      background-color: transparent;
      border: none;
      border-radius: 10px;
      overflow: hidden;
    }

    &:hover .chat-message-action-wrapper .edit-delete-actions {
      opacity: 0.65;
    }

    &.editing {
      width: 100%;
      background: $white;
    }

    .content {
      .chat-message-image {
        max-width: 480px;
        max-height: 300px;
        border-radius: 10px;
      }

      .chat-message-pdf {
        @extend %flex-center;
        gap: 4px;
        width: 120px;
        height: calc(120px * 11 / 8.5);
        background-color: $lightest;
        border: $border-lighter;
        border-radius: 10px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        color: $dark;

        &:hover {
          background-color: $lighter;
          border: $border-light;
          color: $darkest;
        }

        svg {
          font-size: 12px;
        }
      }
    }

    .edit-message-wrapper {
      @extend %flex-col;

      .update-message-btn {
        @extend %btn-primary;
        text-transform: uppercase;
        height: 20px;
        padding: 4px;
        font-size: 12px;
        margin-left: auto;
        margin-top: 4px;
      }
    }

    .edited-at {
      @extend %flex-row-end;
      font-size: 11px;
      font-style: italic;
      color: $light;
      margin-top: 2px;
    }

    .chat-message-action-wrapper {
      @extend %flex-row-middle;
      gap: 10px;
      width: 100%;

      .chat-message-action-items {
        margin-top: 16px;
      }

      .action-item {
        @extend %btn-primary-new;
        @extend %label;
        padding: 6px 12px;
        border: $border-light;
        font-size: 10px;
        color: $white;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;

        &.disabled {
          background-color: $lightest;
          color: $dark;
          pointer-events: none;
        }
      }

      .edit-delete-actions {
        @extend %flex-row-middle;
        gap: 10px;
        position: absolute;
        right: 6px;
        bottom: 6px;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 4px 8px;
        border-radius: 4px;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        border: $border-lighter;

        .icon-btn {
          @extend %btn-basic;
          @extend %flex-row-middle;
          text-transform: uppercase;
          gap: 3px;
          color: $dark;
          font-size: 12px;
          font-weight: bold;

          &.edit {
            svg {
              font-size: 10px;
            }
          }
        }
      }
    }
  }

  .chat-message-edit-area {
    resize: none;
    border: none;
    padding: 0;
    width: 100%;
    font: inherit;
    font-size: 12px;

    &:focus {
      outline: none;
    }
  }
}

.automated-chat-message-container {
  @extend %flex-center-vertical;
  width: 90%;
  max-width: 600px;
  margin: 24px auto;
  border-top: $border-offwhite;

  .automated-chat-message {
    background: white;
    transform: translateY(-8px);
    padding: 0 16px;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: $dark;
    text-align: center;
  }

  .pretty-card {
    width: 100%;
  }

  .automated-chat-message-action-items {
    @extend %flex-row-middle;

    .action-item {
      @extend %label;
      @extend %btn-basic;
      padding: 8px 16px;
      background: $primary;
      font-size: 10px;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;

      &:not(:last-child) {
        margin-right: 8px;
      }

      &.disabled {
        background-color: $lightest;
        color: $dark;
        pointer-events: none;
      }
    }
  }
}
