@import '../../styles/shared.scss';

.profile-image-container {
  width: 140px;
  height: 140px;
  border-radius: 75px;
  overflow: hidden;
  position: relative;
  // background: $tertiary;

  &.badge {
    width: 32px;
    height: 32px;

    .profile-image-background {
      width: 32px;
      height: 32px;
    }
    .profile-image {
      height: 32px;
      width: 32px;
    }
  }

  .profile-image-background {
    position: absolute;
    width: 140px;
    height: 140px;
    border-radius: 75px;
  }

  .loading-fade {
    @extend %skeleton-box-overlay;
  }

  .profile-image {
    position: absolute;
    height: 140px;
    width: 140px;
    border-radius: 75px;
    background: $prettymuchwhite;
    object-fit: cover;

    &.placeholder {
      object-fit: contain;
    }

    &.add {
      @extend %flex-center;
      font-family: $font-body;
      color: $dark;
      font-size: 12px;
      cursor: pointer;

      &:hover {
        left: 0;
      }
    }
  }
}
