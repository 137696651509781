@import '../../../styles/shared.scss';

.opportunity-listing-uploads-section {
  .uploads-container {
    @extend %flex-col;
    gap: 12px;

    &.editing {
      margin-bottom: 24px;
    }
  }

  .opportunity-listing-dropzone {
    @extend %flex-center;
    width: 100%;
    border: $border-offwhite;
    background-color: $prettymuchwhite;
    padding: 48px;
    border-radius: 8px;
    color: $light;
    font-size: 14px;
    min-height: 160px;
    cursor: pointer;
  }
}
