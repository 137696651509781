@import '../../../styles/shared.scss';

.onboarding-panel-tiers-container {
  padding-bottom: 120px;

  @include md {
    padding-bottom: 60px;
  }

  .tier-container {
    max-width: 640px;
    margin: 32px auto 0;

    .meter-container {
      .meter {
        height: 24px;
        width: calc(100% - 24px);
        position: relative;

        .spine {
          background: $offwhite;
          border-radius: 12px;
          height: 100%;
          width: calc(100% + 24px);
          position: absolute;
          left: -12px;

          &.active {
            background: $darker;
            transform: scaleX(1);
            transition: transform 0.5s ease-in-out;
            transform-origin: left;
          }
        }
        .mark-container {
          position: absolute;
          top: 0;

          &.complete {
            .mark {
              opacity: 0.2;
              border: 2px solid $darker;
              color: $dark;
              background: $white;
            }
            .mark-label {
              opacity: 1;
            }
          }

          .mark {
            @extend %flex-center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: $white;
            border: 2px solid $offwhite;
            font-weight: bold;
            color: $lighter;
            font-size: 10px;

            &.current {
              background: $white;
              color: $darkest;
              font-size: 12px;
              border: 2px solid $darker;
              transition: border 0.4s ease-in-out;
            }
          }

          .mark-label {
            position: absolute;
            opacity: 0.4;
            width: 120px;
            top: calc(100% + 6px);
            left: calc(-60px + 12px);
            font-size: 10px;
            line-height: 10px;
            color: $darkest;
            text-transform: uppercase;
            text-align: center;
            white-space: nowrap;
            font-weight: bold;

            &.current {
              color: $darkest;
              opacity: 1;
            }
          }
          .mark-label-pointer {
            background: $lighter;
            width: 2px;
            height: 12px;
            margin-left: 12px;
          }
        }
      }
    }
    .tier-sections {
      @extend %flex-row;
      width: calc(100% - 24px);
      margin-top: 8px;

      .section {
        text-align: center;
        transform: translateX(12px);

        &.active {
          .label {
            color: $darkest;
            font-size: 15px;
          }
          .sublabel {
            color: $darkest;
          }
        }
        .label {
          font-size: 12px;
          text-transform: uppercase;
          font-weight: bold;
          color: $lighter;
        }
        .sublabel {
          font-size: 10px;
          color: $lighter;
          white-space: nowrap;
          text-align: center;
        }
      }
    }
  }
  .improvement-sections {
    @extend %flex-row;
    max-width: 640px;
    margin: 24px auto 0;

    @include md-down {
      flex-direction: column;
    }

    .improvement-section {
      margin-bottom: 8px;

      &:first-child {
        margin: 0 32px 0 0;

        @include md-down {
          margin: 0 0 32px 0;
        }
      }

      .improvement-header {
        font-size: 14px;
        font-weight: bold;
        color: $darkest;
        margin-bottom: 8px;
      }

      .improvement {
        @extend %flex-row;
        font-size: 14px;
        margin-bottom: 6px;
        color: $light;

        &.complete {
          font-weight: bold;
          color: $darkest;
        }

        .checkbox {
          @extend %flex-center;
          background: $offwhite;
          color: white;
          border-radius: 50%;
          min-width: 20px;
          min-height: 20px;
          margin-right: 8px;
          font-size: 12px;

          &.complete {
            background: $primary;
            color: white;
          }
        }
      }
    }
  }

  .perks-sections {
    @extend %flex-row;
    flex-wrap: wrap;
    margin-top: 24px;

    .perks-section {
      padding: 0 8px 16px 0;
      width: 50%;
      align-self: stretch;

      &:nth-child(even) {
        padding: 0 0 16px 8px;
      }

      .perks-section-inner {
        height: 100%;
        padding: 18px;
        border-radius: 4px;
        font-size: 12px;

        &:not(.current) {
          background: $offwhite;
          color: $light;
          border: $border-lighter;
        }

        &.current {
          border: $border-primary;
          color: $darkest;
        }
        .perks-header {
          font-weight: bold;
          font-size: 16px;
        }
        .perks-subheader {
          font-size: 10px;
          font-style: italic;
          margin-bottom: 8px;
        }

        .perks {
          .perk {
            .bullet {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
}
