@import '../../../styles/shared.scss';

.home-blog-panel-outer-container {
  width: 100vw;
  padding: 64px 0;
  background: linear-gradient(0deg, #f7f7f7 0%, #ffffff 100%);

  @include lg {
    padding: 120px 0;
  }
  @include xxl {
    padding: 140px 0;
  }

  .home-blog-panel-inner-container {
    @extend %flex-col;
    width: calc(100% - 20px * 2);
    margin: 120px auto;

    @include md-down {
      gap: 60px;
    }
    @include md {
      gap: 60px;
      width: 90vw;
    }

    @include lg {
      flex-direction: row;
      align-items: flex-start;
      gap: 80px;
    }

    @include xl {
      max-width: 1440px;
      margin: 0 auto;
      gap: 120px;
    }
    @include xxl {
      max-width: 1440px;
      gap: 140px;
    }

    .panel {
      @include lg {
        // width: 50%;
      }
      img {
        object-fit: cover;
      }

      &.left {
        .blog-title {
          font-family: $font-header;
          text-transform: uppercase;
          font-weight: 400;
          font-size: 64px;
          margin-bottom: 40px;

          @include md {
            font-size: 80px;
          }

          @include lg {
            font-size: 96px;
          }

          @include xl {
            font-size: 120px;
          }

          @include xxl {
            font-size: 160px;
          }
        }

        .main-post {
          @extend %flex-col;
          text-decoration: none;
          color: $darkest;
          width: 100%;

          @include lg {
            width: 570px;
          }

          @include xxl {
            width: 700px;
          }

          &:hover {
            .title {
              text-decoration: underline;
            }
          }

          .image-container {
            position: relative;
            width: 100%;
            margin-bottom: 40px;

            .image {
              width: 100%;
              border-radius: 0;
              aspect-ratio: 16/9;
              object-fit: cover;
            }

            &.empty {
              @extend %skeleton-box-overlay;
              background: $lightest;
              position: relative;
            }

            .editors-pick {
              position: absolute;
              top: 16px;
              right: 16px;
              border-radius: 24px;
              border: $border-darkest;
              background: white;
              padding: 8px 12px;
              font-size: 13px;
              line-height: 13px;
              font-family: $font-social;
              letter-spacing: 0.5px;
            }
          }

          .data {
            .badge {
              font-weight: bold;
              text-transform: uppercase;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.1em;

              &.empty {
                @extend %skeleton-box-overlay;
                position: relative;
                background: $lightest;
                width: 80px;
                height: 16px;
                border-radius: 4px;
              }
            }
            .title {
              margin-top: 16px;
              font-size: 24px;
              line-height: 1.4;

              @include md {
                font-size: 24px;
              }

              @include xl {
                font-size: 28px;
              }

              @include xl {
                font-size: 32px;
              }

              &.empty {
                @extend %skeleton-box-overlay;
                position: relative;
                background: $lightest;
                border-radius: 4px;
              }
            }
          }
        }
      }
      &.right {
        @extend %flex-col;
        align-items: flex-start;
        flex: 1;

        a {
          text-decoration: none;
          color: $darker;
        }

        .secondary-posts {
          @extend %flex-col;
          gap: 24px;

          @include xxl {
            gap: 32px;
          }

          .secondary-post {
            border-bottom: $border-lightest;
            color: $darkest;
            text-decoration: none;
            padding-bottom: 24px;

            @include xxl {
              padding-bottom: 32px;
            }

            &:hover {
              .title {
                text-decoration: underline;
              }
            }

            .post {
              @extend %flex-row;

              .image {
                width: 170px;
                min-width: 170px;
                height: 110px;
                min-height: 110px;
                border-radius: 0;
                object-fit: cover;

                @include xxl {
                  width: 210px;
                  min-width: 210px;
                  height: 130px;
                  min-height: 130px;
                }

                &.empty {
                  @extend %skeleton-box-overlay;
                  background: $lightest;
                  position: relative;
                }
              }
              .content {
                margin-left: 24px;

                @include xxl {
                  margin-left: 32px;
                }

                .badge {
                  @extend %label;
                  margin-bottom: 12px;
                  font-size: 12px;
                  color: $dark;

                  @include xxl {
                    font-size: 14px;
                  }

                  &.empty {
                    @extend %skeleton-box-overlay;
                    position: relative;
                    background: $lightest;
                    width: 80px;
                    height: 16px;
                    border-radius: 4px;
                  }
                }

                .title {
                  font-size: 18px;
                  line-height: 1.4;
                  letter-spacing: -0.1px;
                  color: $darkest;

                  @include xxl {
                    font-size: 20px;
                  }

                  &.empty {
                    @extend %skeleton-box-overlay;
                    position: relative;
                    background: $lightest;
                    flex: 1;
                    height: 40px;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }

        .view-all {
          @extend %btn-darkest-new;
          padding: 16px 30px;
          margin-top: 24px;

          @include xxl {
            margin-top: 32px;
          }
        }
      }
    }
  }
}
