@import '../../styles/shared.scss';

.artist-modal-collaboration-task-groups {
  padding: 12px 24px;

  .task-group {
    @extend %flex-row;
    align-items: stretch;
    margin-bottom: 12px;
    border: $border-lightest;
    border-radius: 16px;
    overflow: hidden;
    background: white;

    .cover {
      width: 60px;
      min-width: 60px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        color: $darker;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      .custom {
        @extend %flex-center;
        color: white;
        min-height: 100%;
        min-width: 100%;
        background: $darkest;
        font-size: 44px;
        line-height: 1;
        font-family: $font-header;
        padding-bottom: 12px;
      }
    }

    .body {
      @extend %flex-col;
      padding: 8px 12px;
      .task-type {
        font-size: 11px;
        font-weight: bold;
        color: $dark;
        text-transform: uppercase;
      }
      .task-description {
        font-size: 11px;
        color: $dark;
        white-space: pre-line;
      }

      .tasks {
        @extend %flex-row;
        flex-wrap: wrap;
        margin-top: 4px;

        .task {
          @extend %flex-row-middle;
          position: relative;
          margin-right: 6px;
          margin-bottom: 6px;
          border: 1px solid $dark;
          border-left: 3px solid $dark;
          background: $prettymuchwhite;
          border-radius: 4px;
          padding: 4px 6px 4px 8px;
          font-size: 12px;
          font-weight: bold;
          color: $darker;
          cursor: pointer;

          &:hover {
            background: $lightest;
            .add {
              background: $darkest;
              svg {
                color: $offwhite;
              }
            }
          }

          .bullet {
            margin: 0 4px;
          }

          .add {
            @extend %flex-center;
            background: $dark;
            color: white;
            padding: 2px;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            font-size: 8px;
            margin-left: 8px;
          }
          .remove {
            @extend %btn-basic;
            color: $darkest;
            font-size: 12px;
            padding: 1px 2px 1px 8px;
          }
        }
      }
    }
  }
}
