@import '../../../../styles/shared.scss';

.social-sharing-middle-text-outer {
  .middle-text-content-container {
    height: 100%;
    @extend %flex-col;
    justify-content: center;
    flex: 1;

    .middle-text-title-container {
      margin-top: -40px;
      text-align: center;

      .middle-text-big-title {
        font-family: $font-header;
        font-weight: bold;
        font-size: 1.6rem;
      }
    }

    .middle-text-pin-row {
      position: absolute;
      width: 425px;
      @extend %flex-row;
      gap: 25px;
      left: 50%;
      transform: translateX(-50%);

      img {
        margin: auto;
        aspect-ratio: 1;
        height: 125px;
        width: 125px;
        object-fit: contain;
        border-radius: 10px;
        background-color: white;
      }

      &.top {
        top: 50px;
      }

      &.bottom {
        bottom: 50px;
      }
    }

    .middle-text-logo {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      object-fit: contain;
    }
  }
}
