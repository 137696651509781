@import '../../styles/shared.scss';

.invite-talent-off-platform-modal-outer {
  .invite-talent-off-platform-modal-inner {
    .off-platform-modal-content {
      padding: 30px;

      .off-platform-modal-header {
        font-family: $font-header;
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 25px;
      }

      .off-platform-modal-body {
        .input-container {
          @extend %flex-col;
          gap: 10px;

          input {
            font-family: $font-body-new;
            border: $border-lightest;
            outline: none;
            border-radius: 5px;
            font-size: 0.8rem;
            padding: 8px 12px;
            color: $medium;
            cursor: pointer;
          }
        }
      }

      .invited-talent-container {
        margin: 20px 0;
        padding: 20px 0;
        border-top: $border-lightest;
        border-bottom: $border-lightest;
        @extend %flex-row;
        flex-wrap: wrap;
        gap: 10px;

        .invited-talent {
          @extend %btn-basic;
          padding: 6px 12px;
          background-color: $lightest;
          border-radius: 5px;
          font-size: 0.7rem;

          svg {
            margin-left: 5px;
            height: 10px;
            width: 10px;
          }
        }
      }

      .off-platform-modal-footer {
        position: sticky;
        bottom: 0;
        padding-top: 20px;
        background-color: $white;
        // border-top: $border-lightest;
        @extend %flex-row;
        gap: 10px;
        justify-content: flex-end;

        div {
          @extend %label;
          @extend %btn-basic;
          padding: 10px 15px;
          background-color: black;
          color: white;
          font-size: 0.7rem;
          border-radius: 5px;

          kbd {
            background-color: $lightest;
            color: $dark;
            padding: 2px 4px;
            border-radius: 3px;
            margin-left: 5px;
          }

          &#add {
            background-color: black;
            color: $white;
          }

          &#save {
            background-color: $primary;
            color: white;
          }

          &#cancel {
            background-color: $lightest;
            color: $dark;
          }
        }
      }
    }
  }
}
