@import '../../../styles/shared.scss';

.media-upload-container {
  .media-uploads {
    @extend %flex-center-vertical;
    flex-wrap: wrap;
    margin-top: 12px;

    @include md {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .media-upload,
    .upload-new-media {
      margin: 0 12px 12px 0;
      position: relative;

      @include md {
        max-width: 50%;
      }

      img {
        border-radius: 10px;
        min-height: 240px;
        max-height: 320px;
        max-width: 100%;
        object-fit: contain;
      }

      .video-player-container {
        // max-width: 50%;
        // width: 50%;
        border: $border-dark;
      }

      .remove-icon {
        @extend %flex-center;
        background: $darker;
        color: white;
        padding: 4px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        position: absolute;
        border: $border-white;
        top: -12px;
        right: -12px;
        cursor: pointer;

        &:hover {
          background: $dark;
        }
      }
    }

    .upload-new-media {
      @extend %flex-center-vertical;
      @extend %clickable-subtle;
      font-size: 16px;
      color: $dark;
      text-transform: uppercase;
      min-width: 180px;
      min-height: 180px;
      background: $prettymuchwhite;
      border: $border-lighter;
      border-radius: 10px;
      overflow: hidden;
      margin-top: 24px;

      @include md {
        margin-top: 0;
      }

      .add-new-btn-icn {
        @extend %flex-center;
        font-size: 48px;
        color: $light;
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
        margin-bottom: 12px;
        position: absolute;
        left: 0;
        top: 44px;
        width: 100%;
      }

      .upload-basic-container {
        @extend %flex-center;
        min-width: 180px;
        min-height: 180px;
        padding-top: 24px;
      }

      &.disabled {
        opacity: 0.4;
      }
    }
  }
}
