@import '../../../styles/shared.scss';

.onboarding-panel-tags-container {
  padding-bottom: 120px;
  .tag-sections {
    margin-top: 40px;
    @include xl {
      margin-top: 56px;
    }

    .tag-section {
      margin-bottom: 24px;

      .section-header {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
      }

      .tags {
        @extend %flex-row;
        flex-wrap: wrap;
        width: calc(100% + 16px); // due to margin-right on tags

        .tag {
          @extend %btn-basic;
          margin: 16px 16px 0 0;
          border-radius: 24px;
          padding: 12px 16px;
          font-size: 14px;
          font-weight: bold;
          border: $border-dark;
          text-transform: uppercase;

          &.selected {
            border: $border-darkest;
            color: white;
            background: $darkest;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
