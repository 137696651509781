@import '../../../../styles/shared.scss';

/* NEED TO HAVE THIS OUTSIDE BECAUSE OF "react-sortable-hoc" */
.competitor-brand-settings-list-item {
  @extend %flex-row;
  position: relative;
  border-radius: 500px;
  font-family: $font-body-new;
  gap: 10px;
  border: $border-dark;
  width: max-content;
  padding: 8px 10px;
  font-size: 14px;
  align-items: center;
  user-select: none;

  a.domain {
    font-size: 12px;
    color: $medium;
    font-family: $font-body-new;
  }

  svg {
    @extend %btn-basic;
    height: 10px;
    width: 10px;
  }
}

.competitor-brand-settings-panel-outer {
  .competitor-brand-settings-panel-inner {

    .brand-search-select {
      margin-bottom: 32px;

      .infinite-scroll-select__control {
        padding: 8px;
        border-radius: 500px;
        border: none;
        outline: none;
        background: $offwhite;
        font-size: 14px;
      }
    }


    .manual-create {
      @extend %btn-basic;
      text-decoration: underline;
    }
  }
}
