@import '../../styles/shared.scss';

.collection-product-container {
  position: relative;

  &.classic {
    @extend %flex-row-center;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 12px 24px;

    @include sm {
      width: 50%;
      padding: 12px;
    }

    @include md {
      width: 33%;
    }
  }

  &.grid,
  &.gallery {
    @extend %flex-row-center;
    width: 50%;
    box-sizing: border-box;

    @include sm {
      width: 50%;
    }

    @include md-down {
      &:nth-child(odd) {
        padding: 0px 8px 16px 0px;
      }
      &:nth-child(even) {
        padding: 0px 0px 16px 8px;
      }

      &.gallery {
        padding-bottom: 16px;
      }
    }

    @include md {
      width: 33%;

      &:nth-child(3n + 1) {
        padding: 0px 12px 24px 0px;
      }
      &:nth-child(3n + 2) {
        padding: 0px 12px 24px 12px;
      }
      &:nth-child(3n + 3) {
        padding: 0px 0px 24px 12px;
      }

      &.gallery {
        padding-bottom: 24px;
      }
    }
  }

  &.compact {
    @extend %flex-row-center;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 0;

    @include sm {
      width: 50%;
      padding: 12px;
    }

    @include md {
      width: 33%;
    }
  }

  &.horizontal {
    @extend %flex-col;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 28px 24px 12px;

    @include sm {
      width: 50%;
      padding: 12px;
    }

    @include md {
      width: 33%;
    }
  }

  &.longform {
    width: 100%;
    margin-bottom: 8px;
  }

  &:hover {
    .collection-product {
      .reorder-icn {
        opacity: 1;
      }
      .action-btn-container {
        > svg {
          opacity: 1;
        }
        .toggle-action-panel-visibility-btn {
          opacity: 1;
        }
      }
    }
    .meta-container-horizontal {
      .action-btn-container {
        > svg {
          opacity: 1;
        }
        .toggle-action-panel-visibility-btn {
          opacity: 1;
        }
      }
    }
  }

  .collection-product {
    .reorder-icn {
      @include md {
        opacity: 0;
      }
      cursor: grab;
    }
    .action-btn-container {
      &.fixed {
        @extend %flex-row-middle;
        position: absolute;
        bottom: 0;
        right: 0;
        gap: 6px;
      }
      > svg {
        cursor: pointer;
        opacity: 0.4;

        @include sm-down {
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }

        @include md {
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }

        &:hover {
          opacity: 0.8;
        }
      }
      .toggle-action-panel-visibility-btn {
        opacity: 0.4;
        transition: opacity 0.2s ease-in-out;
      }
    }
  }
  .meta-container-horizontal {
    .action-btn-container {
      > svg {
        @include md {
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }
      }
      .toggle-action-panel-visibility-btn {
        opacity: 0.4;
        transition: opacity 0.2s ease-in-out;
      }
    }
  }

  .actions-panel-container {
    position: relative;
    z-index: inherit;

    &.visible {
      .toggle-action-panel-visibility-btn {
        pointer-events: none;
        opacity: 1 !important;
      }
    }

    .actions-panel {
      @extend %flex-col;
      position: absolute;
      right: 100%;
      bottom: 100%;
      background: white;
      box-shadow: $shadow-dark;
      border-radius: 4px;
      z-index: 10;
      min-width: 130px;
      padding: 6px 0;
      border-radius: 8px;
      border-bottom-right-radius: 0;
      font-weight: bold;

      &.compact {
        @include md-down {
          right: calc(100% - 12px);
          bottom: calc(100% - 12px);
          .actions {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 75vw;

            .action {
              width: 50%;
              overflow: hidden;
            }
          }
        }
      }

      .close-icn-container {
        @extend %btn-basic;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px;
        padding: 8px 16px;
        transform: translateX(8px);
      }

      .action {
        @extend %flex-row-middle;
        @extend %btn-basic;
        color: $dark;
        font-size: 14px;
        white-space: nowrap;

        padding: 4px 12px;

        .loader {
          margin-right: 6px;
          transform: translateY(-1px) scale(2);
          font-size: 10px;
          max-width: 12px;
          max-height: 14px;
        }

        > svg {
          margin-right: 6px;
          transform: translateY(-0.5px);
          font-size: 10px;
          width: 12px;
          max-width: 12px;
          min-width: 12px;
        }

        &:hover  {
          opacity: 0.4;
        }
      }
      .show-more-actions {
        @extend %btn-basic;
        text-align: center;
        font-size: 9px;
        padding-top: 4px;
        font-weight: normal;
        cursor: pointer;
        bottom: 0;
        width: 100%;
      }
    }
    .toggle-action-panel-visibility-btn {
      cursor: pointer;
      z-index: 11;
    }
  }
}
