@import '../../styles/shared.scss';

.gift-guide-preview-outer-container {
  padding: 0px 16px 0;
  text-decoration: none;
  color: $darker;
  width: 100%;

  &.no-edit {
    padding: 0px 16px 24px;
  }

  .gift-guide-preview-container {
    @extend %flex-col;
    width: 100%;
    position: relative;
    // overflow: hidden;
    border-bottom: $border-offwhite;
    border: $border-offwhite;
    border-radius: 6px;

    @include sm {
      &:hover,
      &:active {
        border: $border-lightest;
        .title {
          text-decoration: underline;
        }

        img {
          transform: scale(1.02);
        }
      }
    }

    .gift-guide-preview-inner-container {
      @extend %flex-row-middle;
      // overflow: hidden;
      padding: 16px 16px 16px;
      border-radius: 6px;
      min-height: 124px;
      position: relative;
      text-decoration: none;
      cursor: pointer;
      background: $white;
      color: $dark;

      &.hidden {
        box-shadow: none;

        &:hover {
          box-shadow: none;
        }
        .content {
          opacity: 0.4;
        }

        .cover-image-container {
          opacity: 0.4;
        }
      }

      .content {
        width: calc(100%);
        z-index: 3;

        @include sm {
          &.has-image {
            padding-bottom: 0;
            width: calc(100% - 208px);
          }
        }

        .headline {
          @extend %flex-row-middle;
          font-size: 11px;
          color: $dark;
          text-transform: uppercase;
          margin-bottom: 4px;

          .el {
            margin-right: 4px;
            padding-right: 4px;
            border-right: $border-lighter;

            &:last-child {
              border-right: none;
            }
          }
        }
        .title {
          color: $darker;
          font-family: $font-header;
          font-size: 20px;
          letter-spacing: 0.08px;
          line-height: 27px;
          margin: 0;

          @include md {
            font-size: 24px;
          }
        }
        .subtitle {
          color: $dark;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: -0.04px;
          font-weight: normal;
          margin: 4px 0 0;
          white-space: pre-line;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          font-size: 14px;
          letter-spacing: 0.06px;
          margin-top: 4px;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;

          @include md {
            font-size: 13px;
            line-height: 15px;
            letter-spacing: -0.04px;
          }

          &.empty {
          }
        }
        .get-started-link {
          @extend %label;
          @extend %flex-row-middle;
          font-size: 12px;
          font-weight: bold;
          color: $dark;
          text-transform: uppercase;
          margin-top: 24px;

          @include sm {
            margin-top: 12px;
            font-size: 12px;
          }

          svg {
            margin-left: 4px;
            font-size: 10px;
            color: $medium;
          }
        }
      }

      .cover-image-container {
        @extend %flex-row;
        flex-wrap: wrap;
        position: relative;
        width: 0px;
        height: 0px;
        min-width: 0px;
        min-height: 0px;
        margin-right: 12px;
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
        margin-right: 16px;

        @include sm {
          display: flex;
          width: 140px;
          height: 140px;
          min-width: 140px;
          min-height: 140px;
          margin-right: 16px;
        }

        .cover-image {
          min-height: 100%;
          min-width: 100%;
          max-height: 100%;
          max-width: 100%;
          object-fit: contain;
          transition: all 0.2s ease-in-out;
          border-radius: 10px;

          &.cover {
            object-fit: cover;
          }

          &.public {
            @extend %animation-fade;
          }

          &.empty {
            @extend %flex-center-vertical;
            background: $whynotusewhite;
            border: $border-offwhite;
            border-radius: 10px;
            padding-bottom: 16px;
            color: $lighter;
            font-size: 12px;
            font-weight: bold;

            svg {
              font-size: 32px;
              color: $lightest;
              padding-bottom: 6px;
            }
          }
        }
      }

      .icn-show {
        position: relative;
        cursor: pointer;
        color: $medium;
        position: absolute;
        right: 8px;
        bottom: -6px;
        font-size: 18px;
        z-index: 4;
        font-weight: bold;

        .show-action-items {
          padding: 0 4px 4px;
          font-size: 22px;
          svg {
            path {
              stroke: white;
              stroke-width: 32px;
            }
          }
        }

        .action-items-container {
          @extend %flex-col;
          background: white;
          box-shadow: $shadow-dark;
          border-radius: 4px;
          position: absolute;
          bottom: 100%;
          font-family: $font-body;
          right: 20px;
          z-index: 100;
          min-width: 130px;
          padding: 6px 0;
          border-radius: 8px;
          border-bottom-right-radius: 0;

          .item {
            @extend %flex-row-middle;
            @extend %btn-basic;
            color: $dark;
            font-size: 14px;
            white-space: nowrap;
            padding: 4px 12px;

            .loader {
              margin-right: 6px;
              transform: translateY(-1px) scale(2);
              font-size: 10px;
              max-width: 12px;
              max-height: 14px;
            }

            > svg {
              margin-right: 6px;
              transform: translateY(-0.5px);
              font-size: 10px;
              width: 12px;
              max-width: 12px;
              min-width: 12px;
            }

            &:hover  {
              opacity: 0.4;
            }
          }
        }
      }
    }

    .tag-container {
      @extend %flex-center;
      position: absolute;
      top: 48px;
      width: 100%;
      z-index: 2;
      pointer-events: none;

      .notification-tag {
        text-align: center;
        white-space: nowrap;
        background: white;
        padding: 4px 12px;
        border-radius: 4px;
        font-weight: bold;
        color: $darker;
        box-shadow: $shadow-dark;
      }
    }

    .analytics-preview-container {
      top: 8px;
    }
  }
}
