@import '../../styles/shared.scss';

.budget-changes-container {
  .download-csv-btn {
    @extend %btn-lightest;
    padding: 8px 12px;

    @include md {
      padding: 8px 12px;
      margin-left: 12px;
    }

    svg {
      margin-right: 8px;
    }
  }

  .section-header-container {
    @extend %flex-row;
    justify-content: space-between;
    align-items: end;
  }

  .budget-changes {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-radius: 10px;
    overflow: hidden;

    &.empty {
      border-radius: 10px 10px 0 0;
    }

    .cell {
      @extend %flex-row-middle;
      background-color: white;
      padding: 12px 16px;
      color: $darkest;
      font-size: 12px;
      position: relative;

      &.warning {
        background-color: #f8f49b;
      }

      &.alert {
        background-color: #fd9491;
      }

      &.header {
        background-color: $darkest;
        color: $white;
        padding: 8px 16px;
        font-weight: bold;
      }

      &.clickable {
        @extend %btn-basic;
      }

      &.external-link {
        text-decoration: none;

        svg.external {
          font-size: 11px;
        }
      }

      &.positive {
        color: $primary;
      }

      &.pending {
        color: #e5be00;
      }

      &.fronted {
        color: $link-blue;
      }

      svg {
        margin-left: 4px;
      }

      .action {
        @extend %btn-basic;

        &.disabled {
          cursor: default;
          opacity: 0.3;
        }
      }

      a {
        @extend %link;

        svg {
          margin-left: 4px;
          font-size: 10px;
        }
      }
    }

    .admin-actions {
      position: absolute;
      right: 8px;
      top: 8px;
      opacity: 0.1;

      &:hover {
        opacity: 1;
      }

      .admin-action {
        @extend %btn-basic;
        @extend %flex-center;
        padding: 2px 4px;
        svg {
          margin-left: 0;
          color: $darkest !important;
        }
      }
    }
  }
  .empty-results {
    @extend %flex-center;
    @extend %label;
    background-color: white;
    padding: 36px 0;
    font-size: 12px;
    font-weight: normal;
    color: $light;
    border-radius: 0 0 10px 10px;
  }

  .show-more-container {
    @extend %flex-center;
    gap: 12px;
    width: 100%;
    margin-top: 24px;

    .show-btn {
      @extend %btn-basic;
      font-size: 12px;
      color: $dark;
    }
  }
}
