@import '../../../styles/shared.scss';

.other-features {
  @extend %flex-col;
  gap: 48px;

  .other-feature {
    &:hover {
      .sortable-handle {
        opacity: 1 !important;
      }
    }
  }
}

// Need separate from wrapper in order to rearrange
.other-feature {
  @extend %flex-col;
  position: relative;

  .header-section {
    @extend %flex-row-middle-spread;
    align-items: flex-start;
    color: white;
    gap: 12px;
    margin-bottom: 24px;
    position: relative;

    .title-container {
      @extend %flex-col;

      &.is-lookalike {
        &:hover {
          cursor: pointer;
          opacity: 0.8;

          .title .lookalike-image {
            transform: scale(1.2);
          }
        }
      }
      .title {
        @extend %flex-row-middle;
        @extend %header-fancy;
        font-size: 24px;

        .lookalike-image {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          object-fit: cover;
          margin: -2px 10px 0 0;
          transition: transform 0.2s ease-in-out;
        }

        .hidden-icon {
          margin-left: 12px;
          font-size: 16px;
        }
      }
      .description {
        font-size: 12px;
        margin-top: 4px;
      }
    }

    .view-btn {
      @extend %btn-label;
      color: white;
      font-size: 12px;
      text-decoration: none;
    }
  }

  .preview-users {
    @extend %flex-row;
    position: relative;
    min-height: 100px;
    width: 100%;
    gap: 24px;

    .preview-user {
      @extend %flex-col;

      @include xl {
        width: calc(25% - 20px);
      }

      @include xxl {
        width: calc(20% - 20px);
      }

      @include xxl-down {
        width: calc(33% - 12px);

        &:nth-child(5) {
          display: none;
        }
      }

      @include xl-down {
        width: calc(33% - 12px);

        &:nth-child(4) {
          display: none;
        }
      }

      @include lg-down {
        width: calc(50% - 12px);

        &:nth-child(3) {
          display: none;
        }
      }

      &:hover {
        .action-btns {
          opacity: 1 !important;
          svg {
            opacity: 1 !important;
          }
        }
      }

      .image-container {
        min-width: 100%;
        position: relative;
        aspect-ratio: 1;
        min-height: 100px;
        border-radius: 10px;
        overflow: hidden;

        .placeholder {
          background-color: $darker;
          height: 100%;
          width: 100%;
        }

        img {
          @extend %animation-fade;
          width: 100%;
          height: 100%;
          aspect-ratio: 1;
          object-fit: cover;
        }

        .initials {
          @extend %flex-center;
          width: 100%;
          height: 100%;
          color: white;
          font-family: $font-header;
          font-size: 54px;
          background-color: $darker;
        }

        .action-btns {
          @extend %flex-row;
          gap: 6px;
          justify-content: flex-end;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
          position: absolute;
          bottom: 0;
          width: 100%;
          padding: 24px 8px 8px;
          transition: opacity 0.4s ease-in-out;
          opacity: 0;

          svg {
            @extend %btn-basic;
            font-size: 16px;
            color: white;
            opacity: 0;
          }
        }
      }
      .data {
        margin-top: 8px;
        overflow: hidden;
        width: 100%;

        .name {
          @extend %btn-basic;
          @extend %label;
          white-space: pre-wrap;
          color: white;
          text-overflow: ellipsis;
          font-size: 16px;
          overflow: hidden;
          margin-bottom: 2px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          @include xl {
            font-size: 14px;
          }

          @include xxl {
            font-size: 13px;
          }

          &.placeholder {
            background-color: $darker;
            min-width: 50%;
            color: transparent;
          }
        }

        .description {
          color: $medium;
          font-size: 12px;
          text-overflow: ellipsis;
          position: relative;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;

          &.placeholder {
            background-color: $darker;
            min-width: 80%;
            color: transparent;
          }
        }
      }
    }

    .sortable-handle {
      position: absolute;
      right: 8px;
      top: 8px;
      color: white;
      opacity: 0;
    }
  }
}
