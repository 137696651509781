@import '../../../styles/shared.scss';

.section-template.shelf {
  @extend %flex-col;
  gap: 40px;
  overflow: hidden;

  .collection {
    position: relative;

    .shelf {
      @extend %flex-row;
      justify-content: middle;
      gap: 12px;
      height: 80px;
      padding: 0 20px;

      .product {
        flex: 1;
        background: $offwhite;
        border-radius: 8px;
      }
    }

    .data {
      position: absolute;
      height: 40px;
      bottom: -20px;
      width: 100%;
      background: white;
      box-shadow: 0 -5px 5px 0 rgb(0 0 0 / 3%);
      padding: 12px 20px 0;

      .title {
        height: 10px;
        width: 50%;
        border-radius: 2px;
        background: $offwhite;
      }
      .subtitle {
        height: 8px;
        width: 30%;
        margin-top: 4px;
        border-radius: 2px;
        background: $offwhite;
      }
    }
  }
}
