@import '../../styles/shared.scss';

.bulk-talent-modal-body {
  @extend %flex-col;

  .header-controls {
    @extend %flex-row-middle;
    gap: 8px;
    margin-bottom: 24px;
  }

  .message-template {
    @extend %flex-col;
    gap: 12px;

    textarea,
    input {
      @extend %input-shared;
      font-size: 14px;
    }

    .action-btns {
      @extend %flex-row-middle;
      align-self: flex-end;
      gap: 8px;
      margin-top: 12px;

      .action-btn {
        @extend %btn-darkest-new;
        @extend %label;
        color: white;
        font-size: 12px;
      }
    }
  }

  .attachments {
    @extend %flex-col;
    align-items: flex-end;
    align-self: flex-end;
    gap: 4px;

    .attachments-title {
      @extend %label;
      text-align: right;
      font-size: 10px;
      margin-bottom: 8px;
    }

    .attachments-list {
      @extend %flex-col;
      gap: 4px;
      .attachment {
        @extend %flex-row-middle;
        justify-content: flex-end;
        gap: 8px;
        font-size: 14px;

        .attachment-name {
          @extend %label;
          color: $darker;
          font-size: 12px;
        }

        .attachment-remove {
          @extend %btn-basic;
          @extend %label;
          color: $dark;
          font-size: 12px;
        }
      }
    }
  }
}
