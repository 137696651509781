@import '../../../styles/shared.scss';

.mentions-feed-announcement-outer-container {
  @extend %flex-row-middle;
  align-items: stretch;
  overflow: scroll;
  height: 100%;
  background-color: white;

  @include md-down {
    flex-direction: column-reverse;
  }

  .main-panel {
    @extend %flex-col-middle;
    align-items: flex-start;
    flex: 1;
    padding: 48px;

    .badge {
      @extend %label;
      font-size: 12px;
      margin-bottom: 12px;
    }

    .title {
      @extend %header-fancy;
      margin-bottom: 12px;
      font-size: 36px;
      line-height: 44px;
    }
    .body {
      color: $dark;
    }
    .continue-btn-container {
      @extend %flex-row;
      margin-top: 24px;
      gap: 12px;
    }
  }
  .secondary-panel {
    @extend %flex-center;
    flex: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 10% 50%;
    }
  }
}
