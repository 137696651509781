@import '../../styles/shared.scss';

.opportunity-feed-outer-container {
  @include md-down {
    padding: 0 24px;
  }

  .feed-empty-container {
    background: $white;
    padding: 24px;
    position: relative;

    .feed-empty-message {
      font-size: 11px;
      text-align: center;
      font-style: italic;
      color: $medium;
    }
  }

  .feed-columns {
    @extend %flex-row;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;

    .feed-column {
      @extend %flex-col;
      flex: 1;
      width: 100%;
      gap: 16px;

      @include lg {
        width: calc(33% - 12px);
      }

      .mention-result-outer-container {
        position: relative;

        &.removed {
          .mention-result-container {
            filter: blur(2px);
          }
          .removed-overlay {
            @extend %flex-center;
            pointer-events: none;
            background-color: rgba(0, 0, 0, 0.75);
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 2;
            border-radius: 12px;

            .removed-overlay-text {
              @extend %label;
              color: $white;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
