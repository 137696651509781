@import '../../styles/shared.scss';

.blog-preview-container-outer {
  .blog-preview-container-inner {
    .blog-header {
      margin-bottom: 2rem;
      @extend %flex-row;
      justify-content: space-between;

      @include sm-down {
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }

      .blog-title {
        font-family: $font-social;
        font-weight: 700;
        text-decoration: none;
        color: $darkest;
        font-size: 100px;
        cursor: pointer;

        @include xxl-down {
          font-size: 100px;
        }

        @include xl-down {
          font-size: 72px;
        }

        @include md-down {
          font-size: 52px;
        }
      }

      .blog-button-container {
        @extend %flex-col;
        justify-content: center;
        align-items: flex-end;

        .blog-search-container {
          font-size: 28px;
          @extend %flex-row;
          align-items: center;

          @include xl-down {
            font-size: 24px;
          }

          @include md-down {
            font-size: 20px;
          }

          .blog-search-input {
            @extend %input-shared;
            flex: 1;
            font-size: 12px;
            margin-right: 15px;
            width: 200px;

            @include sm-down {
              font-size: 16px;
            }
          }

          .input-actions-container {
            right: 15px;
            // .icn {
            //   right:
            // }
          }
        }
      }
    }
    .tab-link-container {
      @extend %flex-row;
      width: 100%;
      position: relative;

      .view-all-tabs {
        @extend %label;
        // @extend %btn-basic;
        font-size: 14px;
        cursor: pointer;
        color: $darkest;
        text-decoration: none;
        padding-bottom: 14px;
        position: relative;
        position: absolute;
        right: 0;
        background-color: white;
        padding-left: 40px;
        cursor: pointer;

        svg {
          font-size: 0.8rem;
        }

        @include md-down {
          display: none;
        }

        .view-all-tabs-container {
          position: absolute;
          top: calc(100%);
          right: 0;
          z-index: 2;
          font-size: 12px;

          @extend %flex-col;
          gap: 10px;
          padding: 20px;
          background: white;
          box-shadow: $shadow-dark;
          border-radius: 5px;

          a {
            @extend %btn-basic;
            text-decoration: none;
            color: $darkest;
          }
        }
      }

      .tab-link-container-tabs {
        @extend %flex-row;
        gap: 40px;
        width: 100%;
        // justify-content: space-between;
        margin-bottom: 40px;
        border-bottom: $border-dark;

        @include xxl-down {
          overflow-x: scroll;
        }

        @include xl-down {
        }

        @include lg-down {
        }

        @include md-down {
        }

        @include sm-down {
          overflow-x: auto;
        }

        @include xs-down {
        }

        .tab-link {
          @extend %label;
          @extend %btn-basic;
          font-size: 14px;
          cursor: pointer;
          color: $darkest;
          text-decoration: none;
          padding-bottom: 14px;
          position: relative;

          &.selected {
            border-bottom: 6px solid black;
            border-radius: 0;
          }

          svg {
            font-size: 0.8rem;
          }
        }
      }
    }

    .blog-tab-content-container {
      opacity: 1;
      transition: opacity 0.4s;

      &.isLoading {
        opacity: 0.5;
      }

      .search-results-header {
        font-size: 64px;
        font-family: $font-header;
        font-weight: 300;
        margin: 40px 0;

        @include md-down {
          font-size: 48px;
        }

        @include sm-down {
          font-size: 36px;
        }
      }

      .explore-other-stories {
        font-family: $font-header;
        font-size: 35px;
        margin-bottom: 40px;
        font-weight: 700;
      }

      .blog-post-preview-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;

        @include md-down {
          display: flex;
          flex-direction: column;
        }

        .blog-preview-container-skeleton {
          @extend %flex-row;
          height: 200px;
          width: 100%;
          gap: 20px;
          // background: green;
          position: relative;
          overflow: hidden;

          @include md-down {
            // only show the first 5 items
            &:nth-child(n + 6) {
              display: none;
            }
          }

          animation: skeleton-fade 3s infinite;
          @for $i from 1 through 100 {
            &:nth-child(#{$i}n) {
              animation-delay: #{$i * 0.25}s;
            }
          }

          @include lg-down {
            flex-direction: column;
            height: 320px;
          }

          &.large {
            flex-direction: column;
            grid-row: span 2;
            grid-column: span 1;
            height: 400px;
            // margin-bottom: 50px;

            @include lg-down {
              grid-column: span 2;
              grid-row: 1;
              height: 400px;
            }

            .skeleton-image {
              height: 80%;
            }
          }

          .skeleton-image {
            // height: 200px;
            flex: 1;
            // width: 100%;
            aspect-ratio: 16/11;
            background: $offwhite;

            @include xxl-down {
              aspect-ratio: 16/11;
            }

            @include xl-down {
              aspect-ratio: 1;
            }

            @include lg-down {
              aspect-ratio: 16/9;
            }
          }

          .skeleton-text-content {
            @extend %flex-col;
            gap: 10px;
            width: 100%;

            .skeleton-tag {
              height: 15px;
              width: 85px;
              background: $offwhite;
            }

            .skeleton-title {
              width: 100%;
              height: 70px;
              background: $offwhite;
            }
          }
        }
      }
    }
  }
}

@keyframes skeleton-fade {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
