@import '../../styles/shared.scss';

.announcement-modal-outer-container {
  @extend %flex-center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  transition: opacity 0.2s ease-in-out;
  font-family: $font-body-new;
  background-color: rgba(0, 0, 0, 0.8);

  .announcement-modal-inner-container {
    @extend %flex-center;
    background-color: white;
    height: 100vh;
    overflow: hidden;

    @include md {
      height: auto;
      // width: 90vw;
      max-width: 940px;
      min-height: 320px;
      border-radius: 20px;
      margin: 0 24px;
    }

    .announcement-modal-close-button-mobile {
      @extend %btn-basic;
      position: absolute;
      top: 0;
      right: 0;
      padding: 16px;
      font-size: 24px;

      @include md {
        display: none;
      }
    }
  }
}
