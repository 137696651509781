@import '../../styles/shared.scss';

.displayCard {
  min-height: 360px;
  min-width: 360px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 24px;

  @include md {
    padding: 32px;
  }
}
