@import '../../../styles/shared.scss';

.opportunity-plan-planning-options {
  @extend %flex-col-center;
  background-color: white;
  border-radius: 20px;
  gap: 36px;
  padding: 64px 48px;

  .no-active-plans-message-container {
    @extend %flex-col-center;
    gap: 8px;

    .header {
      font-size: 30px;
      font-family: $font-header;
      font-weight: bold;
      color: $darkest;
      text-align: center;
      max-width: 600px;
    }
    .message {
      font-size: 12px;
      color: $darker;
      text-align: center;
      max-width: 480px;
    }
  }

  .planning-options {
    @extend %flex-row;
    gap: 36px;

    .planning-option {
      @extend %flex-col-spread;
      flex: 1;
      max-width: 240px;

      @include lg {
        max-width: 260px;
      }

      @include xl {
        max-width: 280px;
      }

      @include xxl {
        max-width: 360px;
      }

      .main {
        @extend %flex-col;
        justify-content: flex-end;
        width: 100%;
        aspect-ratio: 440 / 584;
        position: relative;
        overflow: hidden;
        border-radius: 10px;

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: $offwhite;
          border-radius: 10px;
          object-fit: cover;
        }

        .content {
          @extend %flex-col;
          justify-content: flex-end;
          padding: 24px;
          z-index: 2;
          position: relative;
          // background-color: $prettymuchwhite;

          .title {
            font-size: 16px;
            font-weight: bold;
            color: $darkest;
            text-align: center;

            @include xxl {
              font-size: 18px;
            }
          }

          .description {
            font-size: 11px;
            color: $darker;
            text-align: center;
            margin-top: 4px;

            @include xxl {
              font-size: 12px;
            }
          }

          .generate-btn {
            @extend %btn-primary-new;
            padding: 12px 24px;
            font-size: 12px;
            margin: 16px 0 0;

            &.primary {
              padding: 12px 24px;
            }
          }
        }
      }
    }
  }
}
