@import '../../styles/shared.scss';

.image-uploader-outer {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
  z-index: 1000;
  display: grid;
  place-items: center;

  .image-uploader-inner {
    padding: 30px;
    background-color: white;
    border-radius: 16px;
    border: $border-lightest;
    @extend %flex-col;
    gap: 20px;
    color: black;
    width: min(90%, 500px);

    .image-uploader-header {
      font-family: $font-header;
      font-size: 2rem;
      font-weight: 600;
    }

    .image-uploader-sub-header {
      font-size: 0.8rem;
      color: $light;
      margin-bottom: 10px;
    }

    .upload-image-container {
      border: $border-lightest;
      border-radius: 5px;
      font-size: 0.8rem;
      padding: 10px 15px;
      color: $medium;
      cursor: pointer;
    }

    input {
      font-family: $font-body-new;
      outline: none;
      width: 100%;
      padding: 10px 15px;
      border: $border-lightest;
      border-radius: 5px;
      display: block;
    }

    .image-uploader-input {
      margin: 10px 0;
    }

    p {
      font-size: 12px;
      margin: 0;
    }

    img {
      max-height: 300px;
      width: min(100%, 500px);
      object-fit: contain;
      object-position: center center;
      border-radius: 5px;
    }

    .buttons {
      @extend %flex-row;
      margin-top: 10px;
      gap: 10px;

      .clear-button {
        @extend %btn-basic;
        @extend %label;
        height: 40px;
        background-color: $lightest;
        color: $dark;
        font-size: 0.9rem;
        padding: 10px 16px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        outline: none;
        border: none;
      }

      .save-button {
        @extend %btn-basic;
        @extend %label;
        height: 40px;
        background-color: black;
        color: white;
        font-size: 0.9rem;
        padding: 10px 16px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        outline: none;
        border: none;
      }
    }
  }
}
