@import '../../../styles/shared.scss';

.collection-product.longform {
  @extend %flex-col;
  flex: 1;
  position: relative;
  padding-top: 16px;
  background: white;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  width: 100%;

  @include md-down {
    padding-bottom: 48px;
  }

  @include md {
    flex-direction: row;
    align-items: center;
  }

  &.hidden {
    pointer-events: none;
    .collection-product-img-wrapper {
      position: relative;

      .hidden-cover {
        @extend %flex-center;
        width: 100%;
        height: 100%;
        top: 0;
        pointer-events: none;
        left: 0;
        position: absolute;
        background: rgba(255, 255, 255, 0.8);

        .badge {
          text-align: center;
          white-space: nowrap;
          background: white;
          padding: 4px 12px;
          border-radius: 4px;
          font-weight: bold;
          color: $darker;
          box-shadow: $shadow-dark;
        }
      }
    }
    .action-btn-container {
      pointer-events: auto;
    }
  }

  &.odd {
    .collection-product-img-wrapper {
      @include md {
        order: 2;
      }
    }
  }

  &.long-guide {
    .collection-product-img-wrapper {
      @include md {
        width: 260px;
        height: 260px;
        min-height: 260px;
      }
    }
  }

  .collection-product-img-wrapper {
    @extend %flex-col-center;
    width: 100%;
    height: 260px;
    min-height: 260px;
    margin: 0 16px 0 0;

    @include md {
      width: 280px;
      height: 280px;
      min-height: 280px;
      margin: 0 0 12px 0;
    }

    &.smooth {
      .product-image {
        @extend %animation-fade;
        animation-duration: 400ms;
      }
    }

    .product-image {
      align-self: center;
      width: 100%;
      height: 100%;
      transition: all 0.35s ease-in-out;
      cursor: pointer;
      object-fit: contain;

      &.empty {
        background: $prettymuchwhite;
        border-radius: 4px;
      }

      &:hover {
        transform: translateY(-2px);
      }
    }
  }

  .meta-container {
    @extend %flex-col;
    padding: 16px 16px;
    background: white;
    z-index: 2;
    position: relative;
    transition: all 0.25s ease-in-out;
    flex: 1;

    a {
      text-decoration: none;
    }

    textarea {
      resize: none;
      width: 100%;
      background: transparent;
    }

    .title {
      font-size: 24px;
      min-height: 32px;
      font-weight: bold;
      color: $darker;
      letter-spacing: 0.47px;
      margin: 0;
      font-family: $font-header;

      @include md-down {
        font-size: 18px;
        line-height: 20px;
      }
    }

    .title-input-container {
      @extend %input-inline;
      font-size: 24px;
      min-height: 32px;
      font-weight: bold;
      color: $darker;
      letter-spacing: 0.47px;
      margin: 0;
      font-family: $font-header;
      background: transparent;

      @include md-down {
        font-size: 18px;
        line-height: 20px;
      }
    }

    .description {
      font-size: 14px;
      line-height: 18px;
      color: $darker;
      letter-spacing: -0.1px;
      margin: 0;
      font-weight: normal;
      padding-top: 8px;
    }

    .description-input-container {
      @extend %input-inline;
      font-size: 14px;
      line-height: 18px;
      color: $darker;
      letter-spacing: -0.1px;
      margin: 0;
      resize: none;
      padding-top: 8px;
    }

    .code-container {
      @extend %flex-row-middle;
      margin: 8px 0 8px;
      font-size: 12px;
      color: $dark;
      font-weight: bold;

      svg {
        margin-right: 4px;
        font-size: 10px;
      }

      .code {
        .display {
          font-weight: bold;
        }
      }
    }

    a.link-to-product {
      @extend %flex-row-middle;
      font-size: 12px;
      font-weight: bold;
      color: $dark;
      text-transform: uppercase;
      text-decoration: underline;
      margin-top: 8px;

      svg {
        margin-left: 4px;
        font-size: 10px;
        color: $medium;
      }
    }

    .action-btn-container {
      @extend %flex-row;
      position: absolute;
      bottom: 0px;
      right: 8px;
      font-size: 18px;
      color: $medium;
      z-index: 10;
      font-weight: bold;

      > svg,
      > div {
        margin-left: 6px;
      }
    }
  }

  .adjusting-product-loader {
    @extend %flex-center-vertical;
    height: calc(100% + 40px);
    width: 100%;
    margin-top: -40px;
    font-size: 14px;
    font-weight: bold;
    color: $darkest;
    transition: all 0.2s ease-in-out;
    position: absolute;
    z-index: 4;
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.02);
  }

  .reorder-icn {
    position: absolute;
    top: 16px;
    right: 8px;
    color: $light;
    z-index: 3;
    cursor: grab;
  }
}
