@import '../../../../styles/shared.scss';

.lookbook-report-products-outer {
  .lookbook-report-products-inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    position: relative;

    @include md-down {
      grid-template-columns: repeat(2, 1fr);
    }

    .view-more-button {
      @extend %btn-basic;
      font-weight: 400;
      padding: 20px 0;
      grid-column: 1 / -1;
      text-align: center;
      font-size: 0.9rem;
      color: black;
      text-decoration: underline;
      background-color: white;
    }

    .lookbook-report-product-button-container {
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(calc(-100% - 25px));
      @extend %flex-row;
      justify-content: flex-end;
      gap: 10px;

      @include md-down {
        display: none;
      }

      .lookbook-report-download-button {
        @extend %btn-basic;
        @extend %label;
        background-color: black;
        color: white;
        font-size: 0.7rem;
        padding: 14px 16px;
      }
    }
  }
}
