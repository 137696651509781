@import '../../../styles/shared.scss';

.unauthorized-lookbook-outer {
  @extend %flex-col;
  align-items: center;
  min-height: 100vh;

  .unauthorized-lookbook-inner {
    border-radius: 16px;
    padding: 32px;
    text-align: center;
    max-width: 500px;
    margin: 100px;
    background-color: white;

    .unauthorized-lookbook-header {
      font-size: 0.9rem;
      font-weight: 600;
      margin-bottom: 16px;
    }

    .links {
      @extend %flex-row;
      justify-content: center;
      gap: 10px;
      margin-top: 16px;
      flex-wrap: wrap;

      a {
        padding: 10px 16px;
        border-radius: 5px;
        background-color: black;
        color: white;
        @extend %btn-basic;
        @extend %label;
        text-decoration: none;
        font-size: 0.8rem;
      }
    }
  }
}
