@import '../../styles/shared.scss';

.hide-fade {
  position: fixed;
  width: 100%;
  height: 110vh;
  background: black;
  z-index: 8;
  top: 0;
  transition: all 0.2s ease-in-out;
  opacity: 0.4;

  @include md {
    display: none;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.see-lists-header-mobile {
  @extend %flex-col;
  width: 100%;
  text-align: right;
  padding: 12px 24px 0;
  @include md {
    display: none;
  }

  .show-more {
    @extend %btn-dark;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    margin-top: 4px;
    align-self: flex-end;
    padding: 6px 12px;
  }
}

.shop-hierarchy-outer-container {
  min-width: 260px;
  max-width: 260px;
  padding-right: 20px;
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  background: white;
  height: 100vh;
  padding: 44px 0 200px 16px;
  transition: all 0.2s ease-in-out;
  overflow: auto;

  &.hidden {
    transform: translateX(-260px);

    @include md {
      transform: translateX(0);
    }
  }

  &.searching {
    opacity: 0;
  }

  @include md {
    min-width: 200px;
    max-width: 200px;
    padding: 0;
    position: relative;
    z-index: 0;
    height: 100vh;

    &.searching {
      margin-left: -200px;
    }
  }

  @include xxl {
    margin-left: -200px;
  }

  .row {
    @extend %flex-row-middle-spread;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    color: $dark;
    letter-spacing: 0.3px;
    padding: 12px 0 0;
    cursor: pointer;

    a {
      text-decoration: none;
      color: $dark;
    }

    &.header {
      margin-bottom: 6px;
      font-size: 14px;
    }

    &.level-two {
      padding: 4px 0 0 8px;
      font-weight: normal;
    }
    &.level-three {
      padding: 4px 0 4px 16px;
      font-weight: normal;
    }

    &.active {
      font-weight: bold;
      color: $darkest;

      &.level-one {
        margin-bottom: 5px;
      }
      &:not(.level-one) {
        text-decoration: underline;
      }
    }

    &.another-active {
      // opacity: 0.8;
    }

    .main {
      @extend %flex-row-middle-spread;
      width: 100%;

      &:hover {
        text-decoration: underline;
      }

      svg {
        color: $lighter;
        margin-right: 24px;
        @include md {
          margin-right: 48px;
        }
      }
    }
    .secondary {
      @extend %flex-row-middle;
      font-size: 12px;

      svg {
        margin-left: 6px;
      }
    }
  }
}
