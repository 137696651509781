@import '../../styles/shared.scss';

.list-user-container {
  @extend %flex-col;
  height: 100%;

  .show-on-hover {
    opacity: 0;
  }
  &:hover {
    .show-on-hover {
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }
  }

  &.result-0 {
    transform: scale(2);
  }

  &.deleting {
    opacity: 0.2;
    transition: opacity 0.2s ease-in-out;
  }

  .inner-container {
    @extend %flex-col;
    width: 100%;
    background-color: $darker;
    border-radius: 10px;
    flex: 1;
    overflow: hidden;
    position: relative;

    &:hover {
      .sortable-handle {
        opacity: 1;
      }
    }

    .image-container {
      width: 100%;
      aspect-ratio: 9/12;
      position: relative;
      overflow: hidden;

      &:not(.editing) {
        img {
          @extend %animation-fade;
          opacity: 0;
          animation-fill-mode: forwards;
        }

        @for $i from 0 through 12 {
          &.result-#{$i} img {
            animation-delay: #{$i * 50}ms;
          }
        }
      }

      img {
        aspect-ratio: 9/12;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .no-image {
        @extend %flex-center;
        background-color: $darker;
        width: 100%;
        height: 100%;
        font-family: $font-header;
        color: white;
        font-size: 48px;
      }

      .badges {
        @extend %flex-col;
        gap: 4px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        padding: 8px;

        .badge {
          @extend %label;
          background-color: $primary;
          border: $border-primary;
          padding: 4px 7px;
          border-radius: 24px;
          color: $white;
          font-size: 9px;
          pointer-events: none;

          svg {
            margin-left: 4px;
          }

          &.text {
            // padding: 0;
            border: $border-darker;
            background-color: $black;
          }
        }
      }
      .social-counts {
        @extend %flex-col;
        position: absolute;
        top: 12px;
        left: 12px;
        z-index: 2;

        .social-count {
          @extend %btn-basic;
          @extend %flex-row-middle;
          text-decoration: none;

          img {
            width: 12px;
            margin-right: 4px;
            object-fit: contain;
          }

          .count {
            @extend %label;
            text-transform: uppercase;
            font-size: 10px;
            color: white;
            font-weight: 500;
          }
        }
      }

      &:hover {
        .data-overlay {
          height: 100%;

          .hover-fade {
            opacity: 1;
          }
          .data {
            width: 100%;
            .description {
              max-height: 20em;
              transition: max-height 0.75s ease-out;
            }
          }
        }
      }

      .data-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .fade {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.3) 20%,
            transparent 40%,
            transparent 75%,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.4)
          );
        }

        .hover-fade {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.8);
          transition: opacity 0.4s ease-in-out;
          opacity: 0;
        }

        .data {
          position: absolute;
          z-index: 1;
          bottom: 0;
          max-height: 100%;
          overflow: auto;
          padding: 12px;
          width: 100%;
          cursor: pointer;

          .name {
            @extend %header-fancy;
            color: white;
            font-size: 20px;
            margin-bottom: 4px;
          }

          .description {
            color: $offwhite;
            font-size: 12px;
            font-weight: 500;
            transition: max-height 0.4s ease-out;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 4em;
          }

          textarea {
            @extend %input-inline;
            background-color: transparent;
            min-width: 100%;
            resize: none;
          }
        }
      }
    }

    .sortable-handle {
      position: absolute;
      top: 12px;
      right: 12px;
      color: white;
      z-index: 2;
      opacity: 0;
    }

    .reject-recommendation-icon {
      @extend %btn-basic;
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 3;
      color: white;
    }
  }
  .action-btns {
    @extend %flex-row-middle;
    margin-top: 12px;
    gap: 12px;
    flex-wrap: wrap;

    .action-btn {
      @extend %btn-white-outline;
      @extend %label;
      border-color: $medium;
      background-color: transparent;
      color: white;
      padding: 8px 4px;
      font-size: 11px;
      flex: 1;
    }

    .action-icn {
      @extend %btn-basic;
      color: $medium;
      font-size: 18px;

      &.active {
        color: white;
      }
    }
  }
}
