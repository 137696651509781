@import '../../../styles/shared.scss';

.home-product-suite-outer-container {
  width: 100vw;
  padding: 64px 0;
  background-color: $white;

  @include lg {
    padding: 120px 0;
  }
  @include xxl {
    padding: 140px 0;
  }
  .home-product-suite-inner-container {
    @extend %flex-col-middle;
    gap: 24px;

    .home-product-suite-title-container {
      align-self: center;

      // Uses .standard-home-header from Home.scss
    }

    .home-suite-options {
      @extend %flex-center;
      align-items: flex-start;
      gap: 60px;
      padding: 0 20px;
      width: 100vw;
      margin-top: -24px; // Due to image padding

      @include md-down {
        margin-top: -48px; // Due to image padding
      }
      @include md {
        gap: 60px;
        padding: 0;
        margin: -80px auto 0;
        width: 90vw;
      }
      @include lg-down {
        flex-direction: column;
      }
      @include lg {
        gap: 24px;
        padding: 0 40px;
        margin-top: -36px; // Due to image padding
      }

      @include xl {
        gap: 48px;
      }
      @include xxl {
        gap: 60px;
      }

      .home-suite-option {
        @extend %flex-col;
        align-items: flex-start;
        align-self: stretch;
        flex: 1;
        width: 100%;

        @include lg {
          max-width: 480px;
        }

        // animate delay based on index
        @for $i from 1 through 3 {
          &:nth-child(#{$i}) {
            .image-container {
              opacity: 0;
              &.loaded {
                animation: fade-in-up 2s ease-in-out 2s forwards;
                animation-delay: #{$i * 0.3 + 0.2}s;
              }
            }
          }
        }

        .image-container {
          width: 100%;
          aspect-ratio: 1;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: opacity 0.3s ease-in-out;

            @include lg {
              max-width: 480px;
              object-fit: contain;
            }
          }
        }

        .text-container {
          .title {
            font-family: $font-header;
            color: $darkest;
            font-weight: bold;
            margin: 0;
            font-size: 36px;
            margin-bottom: 16px;

            @include md {
              font-size: 40px;
              margin-bottom: 20px;
            }

            @include lg {
              font-size: 32px;
              margin-bottom: 26px;
            }

            @include xl {
              font-size: 44px;
              margin-bottom: 22px;
            }

            @include xxl {
              font-size: 52px;
              margin-bottom: 26px;
            }
          }

          .subtitle {
            font-size: 16px;
            margin-bottom: 24px;
            color: $light;

            @include md {
              font-size: 16px;
              margin-bottom: 30px;
            }

            @include xl {
              font-size: 16px;
              margin-bottom: 36px;
            }
          }
        }

        .action-btn-container {
          @extend %flex-col;
          justify-content: flex-end;
          flex: 1;

          .action-btn {
            @extend %btn-darkest-new;
            text-decoration: none;
            padding: 12px 24px;
            font-size: 14px;

            @include lg {
              padding: 16px 32px;
            }
          }
        }
      }
    }
  }
}

// Keyframe to fade in and move up
@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
