@import '../../../styles/shared.scss';

.user-lookbook-header-outer {
  width: 100%;
  position: relative;

  .cover-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }

  .cover-image-background {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.9) 100%);
  }

  .user-lookbook-header-inner {
    width: 87%;
    max-width: 1240px;
    margin: 0 auto;
    @extend %flex-col;
    justify-content: flex-end;
    padding-bottom: 20px;
    position: relative;
    border-bottom: $border-lightest;
    padding-top: 75px;

    .text-container {
      &.userHasAlreadyOrdered {
        padding-bottom: 50px;
      }

      #backlink-and-title {
        @extend %flex-col;
        gap: 15px;
        justify-content: flex-end;

        .backlink {
          @extend %label;
          @extend %flex-row;
          gap: 5px;
          font-size: 0.7rem;
          color: black;
          @extend %btn-basic;

          svg {
            margin: 0 4px 0 0;
            transform: translateY(1px);
          }

          a {
            text-decoration: none;
            color: black;
          }
        }
      }

      .lookbook-title {
        @include xs {
          min-width: 200px;
        }

        @include sm {
          min-width: 200px;
        }

        @include md {
          min-width: 400px;
        }

        @include lg {
          min-width: 500px;
        }

        @include xl {
          min-width: 700px;
        }

        @include xxl {
          min-width: 700px;
        }

        @extend %input-inline;
        background-color: transparent;
        font-family: $font-header;
        letter-spacing: 0.7px;
        font-weight: 500;
        font-size: 3rem;
        color: black;
        resize: none;
      }
    }

    &.has-cover-image {
      min-height: 400px;
      border-bottom: none;

      .text-container {
        #backlink-and-title {
          @extend %flex-col;
          gap: 15px;
          justify-content: flex-end;

          .backlink {
            color: white;

            a {
              color: white;
            }
          }
        }

        .lookbook-title {
          color: white;
        }
      }
    }
  }
}
