@import '../../../styles/shared.scss';

.results-section {
  .results-container {
    @extend %flex-col;
    gap: 0.5rem;
  }

  .results-section-header {
    @extend %flex-row;
    gap: 10px;
    align-items: center;
    margin-bottom: 25px;

    .text {
      @extend %label;
      @extend %flex-row;
      gap: 0.5rem;
      font-size: 16px;
      align-items: center;

      span {
        background-color: black;
        color: white;
        height: 18px;
        border-radius: 100px;
        padding: 0 10px;
        display: grid;
        place-content: center;
        font-size: 0.6rem;
      }
    }

    .spacer {
      height: 1px;
      flex: 1;
      background-color: $lighter;
    }

    .download-button {
      font-size: 0.8rem;
      cursor: pointer;

      button {
        cursor: pointer;
        border: none;
        outline: none;
        background-color: transparent;
        @extend %label;
        margin-left: 5px;
      }
    }
  }

  .results-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    row-gap: 26px;
    column-gap: 24px;

    .see-more-button-container {
      @extend %flex-row;
      grid-column: 1 / -1;
      gap: 20px;
      justify-content: center;

      .see-more-button,
      .see-all-button {
        @extend %btn-basic;
        @extend %flex-row;
        gap: 10px;
        justify-content: center;
        text-align: center;
        background: $white;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        padding: 8px 16px;
        border-radius: 100px;
        border: $border-lightest;

        .remaining-request-count {
          @extend %label;
          font-size: 8px;
          color: $white;
          background: $darkest;
          padding: 2px 8px;
          border-radius: 100px;
        }
      }

    }

  }
}
