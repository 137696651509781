%animation-shared {
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-duration: 500ms;
}
%animation-fade {
  @extend %animation-shared;
  animation-name: fadeIn;
}

%animation-fade-quick {
  @extend %animation-fade;
  animation-name: fadeIn;
  animation-duration: 100ms;
}

%animation-fade-with-delay {
  @extend %animation-shared;
  animation-name: fadeInDelayed;
  animation-duration: 1000ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInDelayed {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

%animation-slide-up {
  @extend %animation-shared;
  animation-name: slideUp;
}

@keyframes slideUp {
  0% {
    transform: translateY(16px);
  }
  100% {
    transform: translateY(-0);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-16px);
  }
  100% {
    transform: translateY(-0);
  }
}

%animation-bounce-in {
  @extend %animation-shared;
  animation-name: bounceIn;
}

@keyframes bounceIn {
  0% {
    transform: scale(0.97);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

%animation-grow {
  @extend %animation-shared;
  animation-name: grow;
}

@keyframes grow {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes skeleton-fade {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

%animation-skeleton-fade {
  border-radius: 10px;
  animation: skeleton-fade 3s infinite;

  @for $i from 1 through 6 {
    &:nth-child(#{$i}n) {
      animation-delay: #{$i * 0.5}s;
    }
  }
}

@keyframes spinWithPause {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

%animation-spin-with-pause {
  animation: spinWithPause 2s linear infinite;
}
