@import '../../styles/shared.scss';

.nyfw-outer {
  .nyfw-inner {
    font-family: $font-body-new;

    .nyfw-image {
      position: absolute;
      top: 0;
      left: 0;
      /* offset the shopmy header */
      height: calc(100vh + 64px);
      width: 100vw;
      object-fit: cover;
    }

    .nyfw-overlay {
      position: absolute;
      top: 0;
      left: 0;
      /* offset the shopmy header */
      height: calc(100vh + 64px);
      width: 100vw;
      background: rgba(0, 0, 0, 0.5);
    }

    .nyfw-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: $white;
      z-index: 1;

      .nyfw-title {
        font-family: $font-header;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        margin-bottom: 20px;
        white-space: nowrap;

        @include xxl-down {
          font-size: 64px;
          margin-bottom: 20px;
        }

        @include xl-down {
          font-size: 64px;
          margin-bottom: 20px;
        }

        @include lg-down {
          font-size: 64px;
          margin-bottom: 20px;
        }

        @include md-down {
          font-size: 40px;
          margin-bottom: 30px;
        }

        @include sm-down {
          font-size: 40px;
          margin-bottom: 30px;
        }

        @include xs-down {
          font-size: 40px;
          margin-bottom: 30px;
        }
      }

      .nyfw-buttons {
        @extend %flex-row;
        flex-wrap: wrap;
        gap: 30px;
        justify-content: center;

        @include xxl-down {
          gap: 30px;
        }

        @include xl-down {
          gap: 30px;
        }

        @include lg-down {
          gap: 30px;
        }

        @include md-down {
          gap: 20px;
        }

        @include sm-down {
          gap: 20px;
        }

        @include xs-down {
          gap: 20px;
        }

        .nyfw-button {
          @extend %btn-basic;
          padding: 20px 36px;
          border: $border-white;
          border-radius: 6px;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          background: transparent;
          color: white;
          min-width: 200px;
        }
      }
    }
  }

}
