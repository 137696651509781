@import '../../styles/shared.scss';

.partnership-status-actions {
  @extend %flex-row-middle;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-end;

  .result-action-icon {
    @extend %flex-center;
    @extend %btn-basic;
    width: 26px;
    height: 26px;
    position: relative;
    border-radius: 4px;
    border: $border-lightest;
    transition: all 0.2s ease-in-out;

    svg {
      font-size: 12px;
      color: $lighter;
    }

    &:hover {
      background-color: $prettymuchwhite;
      border: $border-medium;

      svg {
        color: $dark;
      }
    }

    &.active {
      background-color: $primaryLightest;
      border-color: transparent;

      svg {
        color: $primary;
        font-style: normal;
      }
    }

    .recommended-label {
      @extend %label;
      @extend %flex-col;
      font-weight: normal;
      align-items: flex-end;
      position: absolute;
      right: 0;
      bottom: calc(100% + 4px);
      text-align: center;
      font-size: 10px;
      color: $dark;
      transition: all 0.2s ease-in-out;

      svg {
        margin-right: 10px;
        font-size: 10px;
      }
    }
  }
}
