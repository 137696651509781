@import '../../../styles/shared.scss';

.opportunity-setup-guide-footer {
  .actions {
    @extend %flex-row;
    gap: 12px;
    justify-content: flex-end;

    .action {
      @extend %flex-row-middle;
      border-radius: 4px;
      color: $darkest;
      border: $border-darkest;
      background-color: white;
      padding: 0;
      opacity: 0.3;
      transition: opacity 0.2s;
      cursor: default;

      &.complete:not(.done) {
        opacity: 1;
        cursor: pointer;

        &:hover {
          background-color: $prettymuchwhite;
          color: $black;
          border: $border-black;

          img {
            transform: translateX(2px) scaleX(0.9);
          }
        }
      }

      &.secondary {
        background-color: $offwhite;
        color: $dark;
        border: $border-dark;
        opacity: 1;
        cursor: pointer;
      }

      &.primary {
        background-color: $primary;
        color: $white;
        border: $border-primary;
        opacity: 1;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        img {
          transform: translateX(2px) scaleX(0.9);
        }
      }

      .action-label {
        @extend %label;
        padding: 18px 24px;
      }

      .action-icon {
        border-left: $border-darkest;
        padding: 18px;

        img {
          width: 24px;
          height: 12px;
          object-fit: contain;
          opacity: 0.5;
          transition: transform 0.2s;
        }
      }
    }
  }

  .disclaimer {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    width: 100%;
    font-size: 9px;
    max-width: 400px;
    color: $dark;
  }
}
