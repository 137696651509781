@import '../../../styles/shared.scss';

.add-pin-modal-image-selection-outer-container {
  max-width: 100%;
  width: 100%;
  position: relative;
  padding-bottom: 120px;

  .image-selection-header {
    background: $dark;
    padding: 8px 16px;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .image-selection-initial-set-container,
  .image-selection-google-set-container {
    flex: 1;
    padding: 16px;
    max-width: 100%;

    .image-set-header-container {
      margin-bottom: 12px;

      .image-set-header {
        font-size: 14px;
        font-weight: bold;
        color: $dark;
        line-height: 1;
      }

      .image-set-subheader {
        font-size: 10px;
        color: $dark;
      }
    }

    .image-set-header-warning {
      @extend %flex-row-middle;
      background: $tertiary;
      padding: 4px 12px;
      border-radius: 4px;
      margin-bottom: 12px;
      font-size: 13px;
      color: $dark;

      svg {
        color: $dark;
        margin-right: 6px;
        font-size: 14px;
      }
    }

    .image-set-google-container {
      @extend %flex-row;
      align-items: stretch;
      margin-bottom: 64px;

      &.enabled {
        @extend %flex-col;
      }

      .start-google-image-search {
        @extend %btn-outline;
        width: 100%;
        height: 32px;
        max-width: 64px;
        margin-left: 12px;
      }
    }

    input {
      width: 100%;
      border-radius: 4px;
      border: $border-lightest;
      padding: 8px 12px;
      outline: none;
      font-family: $font-body;
      letter-spacing: 0.5px;
      transition: all 0.25s ease-in-out;
      margin-bottom: 12px;
    }

    .initial-set-options {
      @extend %flex-row;
      flex-wrap: wrap;

      @include md {
        justify-content: center;
      }

      @include lg {
        justify-content: flex-start;
      }

      &.google {
        min-height: 320px;
        position: relative;
        padding-bottom: 48px;

        .loader {
          position: absolute;
          top: 0;
        }

        .fetch-error-msg {
          padding: 12px 16px 0;
          font-size: 12px;
          color: $dark;
        }
      }

      &.fetching {
        img {
          opacity: 0.5;
        }
      }

      .image-set-option {
        @extend %flex-col-center;
        padding: 4px;
        margin: 0 8px 8px 0;
        border: $border-offwhite;
        border-radius: 4px;
        cursor: pointer;
        width: 130px;

        &.selected {
          border: 1px solid $secondary-md;

          &:hover {
            border: 1px solid $secondary-md;
          }
        }

        img {
          min-width: 120px;
          width: 120px;
          height: 120px;
          min-height: 120px;
          object-fit: contain;
          transition: all 0.15s ease-in-out;
        }

        &:hover {
          border: $border-lightest;

          img {
            transform: scale(1.05);
          }
        }

        .image-tag {
          text-align: center;
          font-size: 12px;
          line-height: 1;
          margin: 12px 4px 8px;
          font-weight: bold;

          &.other-user {
            font-size: 10px;
            color: $dark;
            font-style: italic;
          }
        }
      }
    }
  }
}
