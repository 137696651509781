@import '../../styles/shared.scss';

.result-header-container {
  width: 100%;
  margin-top: 24px;
  padding: 24px;

  @include md {
    margin-top: 24px;
  }

  .results-response {
    .response-header {
      font-size: 24px;
      font-weight: bold;
    }

    .response-subheader {
      font-size: 12px;
      margin-bottom: 16px;
    }

    .response-body {
      @extend %flex-col;
      margin-bottom: 24px;

      .question {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 4px;
      }

      .answer {
        font-size: 14px;
        white-space: pre-line;
      }

      .media-upload {
        @extend %flex-row;
        margin-top: 12px;
        overflow: hidden;
        position: relative;
        align-self: flex-start;

        a {
          width: 100%;
          height: 100%;
          flex: 1;

          &:hover {
            .hint {
              color: $white;
            }
          }

          img {
            border-radius: 10px;
            max-height: 320px;
            max-width: 100%;
            object-fit: contain;
          }

          .hint {
            position: absolute;
            bottom: 8px;
            font-size: 10px;
            font-weight: bold;
            width: 100%;
            text-align: center;
            color: $lightest;
          }
        }

        .video-player-container {
          border: $border-dark;
          max-height: 320px;
          max-width: 100%;
        }
      }
    }

    .response-empty {
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  .complete {
    a {
      font-weight: bold;
      color: $link-blue;
    }
    .clickable {
      color: $darker;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .pending {
    color: $dark;
    padding: 12px 0;

    @include md {
      padding: 24px 24px;
    }

    b {
      color: $darker;
    }

    .save-url {
      @extend %flex-row-middle-spread;
      background: $prettymuchwhite;
      border: $border-lightest;
      border-radius: 4px;
      padding: 6px 12px;
      margin-top: 12px;

      .url {
        font-size: 12px;
      }

      .copy {
        @extend %btn-basic;
        font-weight: bold;
        font-size: 13px;
        margin-left: 12px;
      }
    }
  }
}
