@import '../../styles/shared.scss';

.blog-post-outer {
  .blog-post-inner {
    overflow: hidden;

    .back-to-blog-button {
      @extend %label;
      @extend %flex-row;
      @extend %btn-basic;
      align-items: center;
      gap: 8px;
      font-size: 12px;
      color: $dark;
      margin-top: 20px;

      svg {
        font-size: 12px;
      }
    }

    .post-header {
      @extend %flex-row;
      justify-content: space-between;
      margin: 60px 0;
      gap: 50px;

      @include lg-down {
        flex-direction: column;
        margin: 44px 0 0 0;
        gap: 0;
      }

      @include md-down {
      }

      .post-header-title {
        font-family: $font-header;
        font-weight: 700;
        font-size: 52px;
        width: 860px;

        @include xxl-down {
          width: 860px;
        }

        @include xl-down {
          width: 740px;
          font-size: 40px;
        }

        @include lg-down {
          width: 100%;
          font-size: 40px;
        }

        @include md-down {
          font-size: 28px;
        }
      }

      .post-header-title-skeleton {
        background: $offwhite;
        width: 860px;
        height: 76px;

        animation: skeleton-fade 3s infinite;
        animation-delay: 0s;

        @include xxl-down {
          width: 860px;
        }

        @include xl-down {
          width: 740px;
          height: 60px;
        }

        @include lg-down {
          width: 100%;
          height: 60px;
        }

        @include md-down {
          height: 40px;
        }
      }

      .post-header-description {
        width: 380px;
        font-size: 20px;

        @include xl-down {
          width: 380px;
          font-size: 16px;
        }

        @include lg-down {
          width: 100%;
          margin: 32px 0;
        }

        .description {
          margin-bottom: 10px;
        }

        .post-header-description-skeleton {
          background: $offwhite;
          width: 380px;
          height: 130px;

          animation: skeleton-fade 3s infinite;
          animation-delay: 0.25s;

          @include xl-down {
            width: 380px;
            height: 100px;
          }

          @include lg-down {
            width: 100%;
            height: 80px;
          }

          @include md-down {
            height: 60px;
          }
        }

        .labels {
          margin-top: 20px;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }

    .post-cover-image {
      width: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;
      object-position: center center;
      margin-bottom: 44px;

      @include sm-down {
        aspect-ratio: 1;
      }
    }

    .post-cover-image-skeleton {
      background: $offwhite;
      width: 100%;
      aspect-ratio: 16/9;
      margin-bottom: 44px;

      animation: skeleton-fade 3s infinite;
      animation-delay: 0.5s;
    }

    .blog-post-body {
      display: flex;
      justify-content: space-between;
      gap: 100px;
      position: relative;

      @include lg-down {
        justify-content: center;
      }

      .blog-post-elements-outer-container {
        overflow: hidden;
        width: 860px;

        @include xxl-down {
          width: 860px;
        }

        @include xl-down {
          width: 740px;
          gap: 50px;
        }

        @include lg-down {
          width: 600px;
        }

        @include md-down {
          width: 80%;
        }

        @include sm-down {
          width: 100%;
        }

        @include xs-down {
          width: 100%;
        }
      }

      .blog-post-more-stories-container {
        display: block;
        overflow: hidden;
        position: sticky;
        height: 100%;
        top: 100px;
        width: 460px;

        @include xxl-down {
          width: 460px;
        }

        @include xl-down {
          width: 380px;
        }

        @include lg-down {
          display: none;
        }

        @include md-down {
          display: none;
        }

        @include sm-down {
          display: none;
        }

        @include xs-down {
          display: none;
        }

        .more-stories-header {
          font-family: $font-social;
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 10px;
        }

        .more-stories {
          @extend %flex-col;

          .more-story {
            font-family: $font-body-new;
            padding: 16px 0;
            color: black;
            text-decoration: none;
            font-size: 14px;

            &:hover {
              text-decoration: underline;
            }

            + .more-story {
              border-top: $border-dark;
            }
          }
        }
      }
    }

    .edit-blog-post-button {
      @extend %flex-row-middle;
      gap: 2px;
      position: fixed;
      bottom: 40px;
      left: 10px;
      background: white;
      z-index: 1000;
      border-radius: 8px;
      padding: 2px 8px;
      border: $border-dark;
      font-size: 12px;
      font-weight: bold;
      cursor: pointer;
      color: $darkest;
      font-family: $font-body;

      &:hover {
        color: $dark;
      }
    }
  }
}

@keyframes skeleton-fade {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
