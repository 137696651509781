@import '../../../styles/shared.scss';

.sl {
  .consult-preview-footer-container {
    .result {
      border-color: $lighter !important;
    }
  }
}

.consult-preview-footer-container {
  @extend %flex-col;
  background: $darker;
  padding: 8px 24px;
  border-top: $border-lightest;

  &.example-only {
    background: $prettymuchwhite;
  }

  .banner {
    @extend %flex-row-middle-spread;
    text-transform: uppercase;
    padding: 4px 0;

    .example-link {
      color: $dark;
      font-size: 13px;
      font-weight: bold;
      text-decoration: none;
    }

    .remove-btn {
      color: $medium;
      @extend %btn-basic;
      font-size: 14px;
    }
  }

  .body {
    .result {
      @extend %flex-row-middle-spread;
      padding: 8px 0;
      color: $white;

      &:not(:last-child) {
        border-bottom: $border-dark;
      }

      .data {
        .name {
          font-size: 14px;
          font-weight: bold;
        }
        .date {
          font-size: 11px;
          letter-spacing: 0.27px;
          color: $prettymuchwhite;
        }
      }
      .action-btns {
        @extend %flex-row-middle;
        font-size: 12px;

        .make-example-btn {
          @extend %btn-text;
          color: white;
          text-transform: uppercase;
          margin-right: 12px;
        }
        .action-btn {
          @extend %btn-offwhite;
          background: transparent;
          color: white;
          padding: 4px 12px;

          &.faded {
            @extend %btn-offwhite;
            background: transparent;
            color: white;
          }
        }
      }
    }
  }
  .toggle-show-all-btn {
    @extend %btn-basic;
    align-self: center;
    font-size: 11px;
    color: $white;
  }
}
