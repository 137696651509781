@import '../../styles/shared.scss';

.partnership-status-filters-outer-container {
  position: relative;
  .filter-btn {
    @extend %flex-center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    position: relative;
    background-color: $offwhite;
    color: $light;
    cursor: pointer;

    &.has-filters-set {
      background-color: $primaryLightest;
      color: $primary;
    }

    svg {
      font-size: 10px;
    }

    .count-label {
      @extend %flex-center;
      position: absolute;
      top: -4px;
      right: -4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $primary;
      color: $white;
      font-size: 8px;
    }
  }
}
