@import '../../styles/shared.scss';

.sortable-items {

  >div {
    &:hover {
      .sortable-handle-default {
        opacity: 1;
      }
    }

    .sortable-handle-default {
      opacity: 0;
    }
  }
}

.sortable-handle-default {
  position: absolute;
  top: 8px;
  right: 8px;
}
