@import '../../../styles/shared.scss';

.talent-result-actions-container {
  @extend %flex-col;
  gap: 12px;

  .recommended-actions {
    @extend %flex-row-middle;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 12px; // To leave room for icons

    .result-action {
      &.primary {
        @extend %btn-primary-new;
      }

      &.secondary {
        @extend %btn-prettymuchwhite-new;
      }

      &.tertiary {
        @extend %btn-white-new;
      }

      &.in-progress {
        color: transparent !important;
        opacity: 0.8;
      }

      .loader {
        position: absolute;
      }

      .no-action-status-display {
        font-size: 9px;
        color: $light;
        font-style: italic;
      }
    }
  }
}
