@import '../../../../styles/shared.scss';

.lookbook-order-modal-outer {
  max-width: 900px;
  height: 100%;
  position: relative;
  font-family: $font-body-new;

  .order-success {
    background: $primary;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 102;
    border-radius: 10px;

    display: grid;
    place-items: center;
    color: white;
    text-align: center;
    transition: opacity 0.2s linear;
    opacity: 0;
    pointer-events: none;

    &.show {
      opacity: 1;
      pointer-events: all;
    }

    .order-success-inner {
      .order-success-title {
        font-family: $font-header;
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .order-success-subtitle {
        font-size: 0.9rem;
        width: min(400px, 80%);
        margin: 0 auto;
      }

      .continue-button {
        @extend %btn-basic;
        @extend %label;
        width: max-content;
        margin: 0 auto;
        color: white;
        font-size: 0.9rem;
        display: grid;
        place-items: center;
        margin-top: 20px;
      }

      .animated-check-mark {
        margin: 2rem auto;
        position: relative;

        .checkmark {
          position: absolute;
          height: 20px;
          width: 20px;
          color: $primary;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -45%);
          z-index: 2;
        }

        .spiky-circle {
          margin: 0 auto;
          position: relative;
          width: 50px;
          height: 50px;
          background-color: #3498db; /* Circle color */
          border-radius: 50%;
        }

        /* Create spikes using ::before and ::after pseudo-elements */
        .spiky-circle::before,
        .spiky-circle::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: white; /* Spike color */
          /* Using clip-path to create the spikes */
          border-radius: 20%;
          z-index: 1;
        }

        .spiky-circle::after {
          animation: 5s rotate infinite linear;
        }

        .spiky-circle::before {
          animation: 5s rotate-askew infinite linear;
        }
      }
    }
  }

  .lookbook-order-modal-inner {
    position: relative;
    height: 100%;
    overflow: hidden;

    .body {
      @extend %flex-row;
      height: 100%;
      flex-direction: row;
      position: relative;

      @include md-down {
        display: block;
      }

      .body-header {
        @extend %flex-col;
        justify-content: center;
        padding: 30px;
        border-bottom: $border-lightest;
        background-color: $prettymuchwhite;

        @include md {
          display: none;
        }

        .body-title {
          font-family: $font-header;
          font-size: 2rem;
          margin-bottom: 10px;
          font-weight: 500;
        }

        .steps {
          @extend %flex-row;
          gap: 10px;
          font-size: 0.8rem;
          align-items: center;

          .step {
            @extend %label;
            @extend %btn-basic;
            cursor: pointer;
            border-radius: 6px;
            transition: all 0.2s ease-in-out;
            opacity: 0.3;

            &.active {
              opacity: 1;
            }
          }
        }
      }

      .body-footer {
        @extend %flex-row;
        align-items: center;
        gap: 20px;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        justify-content: flex-end;
        padding: 20px;
        border-top: $border-lightest;
        background-color: $prettymuchwhite;
        z-index: 2;

        @include md-down {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
        }

        .limit-container {
          text-align: center;
          // @extend %flex-row;
          // align-items: center;

          .limit-container-value {
            font-size: 0.8rem;
            font-weight: bold;
            color: rgb(237, 96, 96);
          }

          .limit-container-label {
            font-size: 0.7rem;
            color: black;
            // opacity: 0.7;
          }
        }

        .footer-buttons {
          @extend %flex-row-middle;
          gap: 12px;
        }

        .delete-request-btn,
        .submit-order-btn {
          @extend %btn-basic;
          @extend %label;
          background-color: black;
          color: white;
          font-size: 0.9rem;
          padding: 14px 16px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          outline: none;
          border: none;
          display: grid;
          place-items: center;

          &.submitting {
            color: transparent;
            pointer-events: none;
          }

          &.disabled {
            background-color: $lighter;
            pointer-events: none;
          }

          .loader {
            position: absolute;
          }
        }

        .delete-request-btn {
          background-color: $error;
        }
      }
    }
  }
}

.lookbook-order-modal-quick-view-outer {
  @extend %flex-center;
  position: fixed;
  padding: 200px 0 24px;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
  }

  &.has-items {
    background: transparent;

    .background {
      opacity: 1;
    }

    .lookbook-order-modal-quick-view {
      transform: translateY(0);
    }
  }

  .lookbook-order-modal-quick-view {
    @extend %flex-row-middle-spread;
    gap: 20px;
    background-color: white;
    pointer-events: auto;
    padding: 10px;
    border-radius: 10px;
    transition: transform 0.2s ease-in-out;
    transform: translateY(180px);
    align-items: center;
    box-shadow: $shadow-light;
    min-width: 90vw;

    @include md {
      min-width: 400px;
    }

    .lookbook-order-modal-quick-view-product-previews {
      @extend %flex-row;

      img {
        height: 45px;
        width: 45px;
        object-fit: cover;
        border-radius: 5px;

        + img {
          margin-left: -30px;
          box-shadow: -5px 0 5px -5px black;
        }
      }
    }

    .limit-container {
      text-align: center;
      // @extend %flex-row;
      // align-items: center;

      .limit-container-value {
        font-size: 0.8rem;
        font-weight: bold;
        color: rgb(237, 96, 96);
      }

      .limit-container-label {
        font-size: 0.7rem;
        color: black;
        // opacity: 0.7;
      }
    }

    .open-modal-btn {
      @extend %btn-basic;
      @extend %label;
      background-color: $darkest;
      color: white;
      border-radius: 500px;
      font-size: 0.7rem;
      height: max-content;
      padding: 9px 13.5px;
      transition: all 0.3s ease-out;

      &.error {
        background-color: rgb(237, 96, 96);
        color: white;
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes rotate-askew {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(135deg);
  }
}
