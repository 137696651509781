@import '../../../../styles/shared.scss';

.lookbook-report-controls-outer {
  position: absolute;
  top: 0;
  right: 0;

  .lookbook-report-controls-inner {
    .lookbook-report-date-range-container {
      @extend %flex-row;
      font-family: $font-body-new;
      align-self: stretch;

      @include sm-down {
        display: none;
      }

      .DateRangePicker {
        align-self: stretch;
        border-radius: 4px;
        border: $border-lightest;

        .DateRangePickerInput {
          border-radius: 8px;
          padding-left: 4px;

          .DateInput {
            width: 120px;

            input {
              font-family: $font-body-new;
              padding: 8px 12px;
              font-size: 12px;
              height: 100%;
            }

            @include xl-down {
              width: 100px;

              input {
                padding: 8px 8px;
                font-size: 12px;
              }
            }

            @include lg-down {
              width: 80px;

              input {
                padding: 8px 8px;
                font-size: 10px;
              }
            }
          }

          .DateInput_input__focused {
            border-color: $darker;
          }
        }

        .DateRangePickerInput_arrow_svg {
          width: 16px;
          height: 16px;
          margin-top: -3px;
        }
        .DateRangePickerInput_clearDates {
          padding: 4px;

          svg {
            transform: translateY(-1px);
          }
        }
      }
      // NOTE: the order of these styles DO matter (this comes directly from the react-dates CSS recommendations)
      // Will edit everything selected including everything between a range of dates
      .CalendarDay__selected_span {
        background: $dark;
        color: white;
        border: $border-darker;
      }

      // Will edit selected date or the endpoints of a range of dates
      .CalendarDay__selected {
        background: $darker;
        border-color: $darkest;
        color: white;
      }

      // Will edit when hovered over. _span style also has this property
      .CalendarDay__selected:hover {
        background: $medium;
        color: white;
      }

      // Will edit when the second date (end date) in a range of dates
      // is not yet selected. Edits the dates between your mouse and said date
      .CalendarDay__hovered_span:hover,
      .CalendarDay__hovered_span {
        background: $dark;
        border-color: $dark;
        color: white;
      }
    }
  }
}
