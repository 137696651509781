@import '../../../styles/shared.scss';

.contract-propose-receipt-card {
  .tasks {
    .task {
      @extend %flex-row-middle-spread;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &.total {
        font-weight: bold;
        margin-top: 24px;

        .per-day {
          font-size: 9px;
          font-weight: normal;
          color: $dark;
        }
      }

      .main {
        @extend %flex-col;
        flex: 1;
        .title {
          @extend %flex-row-middle;
          font-size: 14px;
          font-weight: bold;
          color: $darker;

          svg {
            margin-left: 4px;
            cursor: pointer;
            font-size: 12px;
            color: $light;
          }
        }

        .subtitle {
          font-size: 10px;
          color: $dark;
          text-transform: uppercase;
        }
        .description {
          margin-top: 4px;
          font-size: 13px;
          font-weight: bold;
          color: $darker;
        }
        input {
          @extend %input-inline;
          &.title {
          }
          &.description {
            font-size: 11px;
            font-weight: normal;

            &::placeholder {
              font-style: italic;
            }
          }
        }
      }
      .secondary {
        @extend %flex-row-middle;

        .pricing {
          font-size: 14px;
          color: $darker;

          svg {
            font-size: 10px;
            margin-right: 3px;
            color: $light;
          }

          .price-container {
            @extend %flex-col;
            align-items: flex-end;
            input {
              @extend %input-inline;
              max-width: 80px;
              text-align: right;
            }
            .price {
              @extend %flex-row;
              text-align: right;
            }
            .per-day {
              text-align: right;
              font-size: 9px;
            }
          }
        }
        .task-actions {
          margin-right: -8px;
          margin-left: 8px;
          margin-top: 2px;
          font-size: 12px;
          .action {
            @extend %btn-basic;
          }
        }
      }
    }
  }
}

.potential-tasks-container {
  padding: 16px 32px 8px;

  @include md {
    padding: 16px 32px 8px;
  }

  .potential-tasks-container-header-container {
    @extend %flex-row-middle-spread;

    .potential-tasks-container-header-wrapper {
      margin-bottom: 12px;
      margin-right: 4px;
      width: 100%;
      .potential-tasks-container-header {
        color: $darker;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .potential-tasks-container-subheader {
        color: $darker;
        font-size: 11px;
        font-style: italic;

        a {
          @extend %link;
        }
      }
    }
    .header-actions {
      .show-all-switch {
        @extend %flex-row-middle;
        padding: 8px 12px;
        color: $dark;
        font-size: 11px;
        margin-left: 12px;
        white-space: nowrap;
        text-transform: uppercase;

        .switch {
          margin: -1px 0 0 4px;
        }
      }
    }
  }
  .potential-tasks-groups {
    @extend %flex-row;
    flex-wrap: wrap;
    .potential-tasks-group {
      margin-right: 12px;
      margin-bottom: 12px;

      .task-type-header {
        text-transform: uppercase;
        margin-bottom: 4px;
        font-weight: bold;
        font-size: 11px;
        color: $dark;
      }
      .tasks {
        @extend %flex-col;
        .task {
          @extend %flex-row-middle-spread;
          padding: 3px 8px;
          margin: 2px 8px 3px 0;
          border-radius: 4px;
          background: $white;
          border: $border-lightest;
          color: $dark;
          font-size: 12px;
          text-transform: uppercase;
          font-weight: bold;
          cursor: pointer;

          border: $border-dark;
          border-left: 3px solid $dark;

          &.unselected-template {
            border-color: $lighter;
            opacity: 0.6;
          }

          .task-main {
            @extend %flex-row-middle;
            .title {
            }
            .bullet {
              margin: 0 4px;
            }
          }

          .task-actions {
            margin-left: 8px;
            svg {
              font-size: 10px;
              margin-left: 4px;
              color: $darkest;
            }
          }

          &:hover {
            border-color: $light;
            .add {
              svg {
                color: $darkest;
              }
            }
          }
        }
      }
    }
  }
}
