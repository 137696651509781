@import '../../../styles/shared.scss';

.brand-lookbook-settings-modal-outer {
  position: relative;
  height: 100%;
  max-width: 740px !important;
  height: 80vh !important;

  .brand-lookbook-settings-modal-inner {
    position: relative;

    .content {
      position: relative;
      overflow: auto;
      padding: 16px 0 120px;

      .settings-section {
        padding: 24px 36px;

        &:not(:first-child) {
          border-top: $border-offwhite;
        }

        .section-header {
          font-family: $font-body;
          font-size: 1.2rem;
          font-weight: 600;
        }

        .section-sub-heading {
          font-size: 0.85rem;
          color: $medium;
          margin-bottom: 24px;

          &.short {
            margin-bottom: 10px;
          }
        }

        .section-header + .sub-section {
          margin-top: 40px;
        }

        .sub-section {
          @extend %flex-col;
          gap: 7px;

          &:not(:last-child) {
            margin-bottom: 40px;
          }

          .sub-section-header {
            @extend %flex-row;
            @extend %label;
            justify-content: space-between;
            align-items: flex-end;
            font-size: 0.9rem;
            font-weight: 600;

            @include xs {
              align-items: flex-start;
              flex-direction: column;
              gap: 20px;
            }

            @include md {
              flex-direction: row;
              align-items: flex-end;
            }

            .variable-select {
              min-width: 200px;
              font-size: 0.7rem;
            }
          }

          &.nested {
            padding: 0 20px;
            margin-top: -20px;

            .sub-section-header {
              color: $medium;
            }
          }

          .sub-section-subheader {
            font-size: 0.8rem;
            color: $medium;
            margin-top: -5px;
          }

          .test-shopify-button {
            @extend %btn-basic;
            @extend %label;
            width: max-content;
            font-size: 0.8rem;
            padding: 10px 15px;
            border-radius: 5px;
            background-color: black;
            color: white;
          }

          textarea.email {
            padding: 15px;
            border-radius: 10px;
            background-color: $prettymuchwhite;
            border: $border-lightest;
            font-size: 0.8rem;
            font-family: $font-body-new;
          }

          .toggle-container {
            @extend %label;
            font-size: 0.8rem;
            font-weight: 600;
            @extend %flex-row;
            align-items: center;
            gap: 12px;
            justify-content: flex-end;
            position: relative;
            z-index: 0;

            &.beginning {
              justify-content: flex-start;
            }

            svg {
              height: 10px;
              width: 10px;
              position: absolute;
              top: 50%;
              right: -100%;
              z-index: 1000;
              transform: translate(-65%, -50%);
            }
          }

          .country-search-container {
            .country-select__menu-list {
              z-index: 1;
            }
          }

          .search-more-label {
            text-transform: none;
            font-size: 10px;
            color: $medium;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          .limit-input {
            outline: none;
            border: none;
            margin: 0;
            padding: 10px 14px;
            font-family: $font-body-new;
            background-color: $prettymuchwhite;
          }
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      // padding: 15px calc(24px + 30px);
      @extend %flex-row;
      justify-content: flex-end;
      background-color: white;
      padding: 20px;
      background-color: $prettymuchwhite;

      .done-button {
        background-color: black;
        @extend %label;
        @extend %btn-basic;
        border-radius: 5px;
        color: white;
        padding: 12px 18px;
        width: max-content;
      }
    }
  }
}
