@import '../../styles/shared.scss';

.add-pin-modal-empty-outer-container {
  @extend %flex-col-center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 60px 16px 12px;
  position: relative;

  @include sm {
    padding: 60px 24px 12px;
  }

  .loader {
    z-index: 100;
    position: absolute;
    top: 160px;
  }

  .explainer-panel {
    margin-top: 24px;
  }

  input.merchant-input {
    @extend %input-shared;
    width: 100%;
    margin: 12px 0;
  }

  .merchant-results {
    padding: 8px 0;
    width: 100%;
    transition: all 0.2s ease-in-out;

    &.fetching {
      opacity: 0.4;
    }

    .empty-results {
      padding: 48px 32px 24px;
      font-size: 16px;
      color: $dark;
      text-align: center;

      span {
        color: $link-blue;
        cursor: pointer;
      }

      @include md {
        padding: 24px 20%;
      }
    }

    .merchant-result {
      @extend %flex-row-middle-spread;
      width: 100%;
      padding: 8px 0;

      &:not(:last-child) {
        border-bottom: $border-offwhite;
      }

      .merchant-main {
        @extend %flex-row-middle;

        .name-container {
          .name {
            font-weight: bold;
            color: $darker;
            font-size: 18px;
          }

          .domain {
            color: $dark;
            font-size: 14px;
            cursor: pointer;
            text-decoration: none;

            &:hover {
              opacity: 0.5;
            }
          }
        }
      }

      .merchant-secondary {
        @extend %flex-col;
        align-items: flex-end;

        .rate {
          font-weight: bold;
          color: $white;
          background: $darker;
          border-radius: 4px;
          padding: 2px 8px;
        }

        .source {
          font-size: 12px;
          color: $medium;
        }
      }
    }
  }

  .example-retailers {
    @extend %flex-row;
    flex-wrap: wrap;
    padding: 12px 24px;
    width: 100%;

    .example-retailer-badge {
      @extend %flex-col-center;
      width: 33%;
      margin: 8px 0;
      text-decoration: none;
      transition: all 0.2s ease-in-out;

      @include md {
        width: 25%;
      }

      @include lg {
        width: 16.6%;
      }

      &:hover {
        transform: scale(1.05);
      }

      img {
        width: 84px;
        height: 84px;
        border-radius: 42px;
        cursor: pointer;
      }

      .retailer-payout {
        background: $offwhite;
        margin-top: -12px;
        border-radius: 4px;
        padding: 2px 4px;
        font-weight: bold;
        color: $dark;
        font-size: 14px;
      }
    }
  }

  .add-pin-modal-empty-header-left {
    font-family: $font-header;
    color: $darkest;
    font-size: 16px;
    letter-spacing: 0.1;
    font-weight: bold;
    align-self: flex-start;
    padding-bottom: 12px;

    @include sm {
      // padding-left: 28px;
    }
  }
  .featured-brands {
    @extend %flex-row;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    padding-bottom: 48px;

    @include md-down {
      gap: 12px;
    }

    .featured-container {
      @extend %animation-fade;
      width: calc(50% - 10px);

      @include md-down {
        width: 100%;
      }
    }
  }

  .high-rate-merchants {
    @extend %flex-row;
    flex-wrap: wrap;
    width: 100%;

    @include sm {
      padding: 0;
    }

    .high-rate-merchant-container {
      @extend %flex-row;
      width: 100%;
      min-height: 72px;
      padding: 6px 6px;
      position: relative;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      @include sm {
        width: 50%;
        min-height: 72px;
      }

      @include xl {
        min-height: 80px;
      }

      .high-rate-merchant {
        @extend %flex-row-middle-spread;
        border: $border-offwhite;
        background: $offwhite;
        text-decoration: none;
        padding: 4px 16px;
        flex: 1;

        .name {
          font-size: 18px;
          color: $darker;
        }
        .rate {
          font-size: 28px;
          font-weight: bold;
          color: $darker;
        }
      }
    }
  }
}
