@import '../../styles/shared.scss';

.mention-result-container {
  @extend %flex-col;
  justify-content: flex-end;
  background-color: $darkest;
  font-size: 12px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  aspect-ratio: 9 / 16;
  &.square {
    aspect-ratio: 1;
  }
  &.youtube {
    background-color: black;
    .cover-image {
      margin-top: -20px;
      object-fit: contain !important;
      object-position: top !important;
    }
  }

  &.is-blocked {
    border: $border-lightest;
    background-color: $offwhite;

    img,
    video {
      filter: blur(8px);
    }
  }

  &.empty {
    @extend %flex-center;
  }

  .shiftable-item {
    transform: translateX(calc(24px * 2)); /* number of hidden icons */
  }
  .show-on-card-hover {
    opacity: 0;
  }
  &:hover {
    .shiftable-item {
      transform: translateX(0px);
    }
    .show-on-card-hover {
      opacity: 1;
    }
  }

  .cover-video-container,
  .empty-cover,
  .cover-image-container,
  .type-subtype-container {
    @extend %flex-center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .cover-image {
      @extend %animation-fade-with-delay;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .empty-cover {
    @extend %flex-col-center;
    background-color: $darkest;

    img,
    .empty {
      width: 25%;
      aspect-ratio: 1;
      border-radius: 12px;
      object-fit: cover;
    }
    .empty {
      @extend %flex-center;
      font-family: $font-header;
      font-size: 40px;
      font-weight: bold;
      color: $darkest;
      background-color: white;
    }
    .explainer {
      @extend %label;
      color: white;
      font-size: 9px;
      margin-top: 24px;
      max-width: 80%;
      white-space: pre-wrap;
      text-align: center;
    }

    .content-link {
      @extend %label;
      color: white;
      font-size: 9px;
      margin-top: 12px;

      svg {
        font-size: 8px;
        margin: -1px 0 0 4px;
      }
    }
  }

  // So we can see overlay, hide the fade
  &.square:hover {
    .header-section {
      @include lg-down {
        &::before {
          opacity: 0;
        }
      }
    }
  }

  .header-section {
    @extend %flex-row-middle;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px 12px 32px;
    width: 100%;
    z-index: 2;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
      z-index: -1;
      transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
      opacity: 0.6;
      pointer-events: none;
    }
    .icons {
      @extend %flex-center;
      z-index: 2;
      gap: 8px;
      pointer-events: auto;

      &:hover {
        .show-on-hover {
          opacity: 1;
        }
      }
      .remove-icon {
        opacity: 0;
      }

      .shiftable-item {
        transition: all 0.35s ease; /* This animates the shifting of the items */
      }

      .social-icon,
      .user-icon,
      .remove-icon {
        @extend %btn-basic;
        @extend %flex-center;
        cursor: pointer;
        svg {
          color: $offwhite;
          width: 16px;
          height: 16px;
        }
        img {
          width: 16px;
          height: 16px;
          margin-bottom: -4px;
        }
      }

      .social-icon.youtube {
        img {
          height: 20px;
          width: calc(20px * 13 / 9);
          margin-bottom: 0;
        }
      }

      .badge {
        @extend %flex-center;
        @extend %label;
        font-size: 10px;
        color: white;
        border-radius: 12px;
        padding: 2px 8px;
        text-decoration: none;

        &.gifted {
          background: $darkest;
          color: $prettymuchwhite;
        }

        &.story {
          background: linear-gradient(90deg, #ff2b9d 0%, #ff8515 100%), #11835a;
        }

        &.roi {
          background: linear-gradient(90deg, $primary 0%, $primaryDark 100%), #11835a;
        }
      }
    }
  }

  .content {
    @extend %flex-col;
    z-index: 1;
    position: relative;
    width: 100%;
    padding: 32px 16px 12px;
    color: white;
    font-size: 16px;
    justify-self: flex-end;
    pointer-events: none;

    &.video {
      margin-bottom: 6px;
    }
    .content-inner {
      @extend %flex-col;
      align-self: flex-start;
      pointer-events: auto;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
      z-index: -1;
      transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
      opacity: 0.6;
      pointer-events: none;
    }

    .timestamp {
      @extend %label;
      pointer-events: auto;
      font-size: 10px;
      color: white;
      text-decoration: none;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        text-decoration: underline;
        opacity: 0.6;

        svg {
          transform: translateX(1px);
        }
      }

      svg {
        font-size: 8px;
        margin-left: 3px;
        margin-top: -1px;
        transition: transform 0.2s ease-in-out;
      }
    }
    .brand,
    .user {
      @extend %label;
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover {
        text-decoration: underline;
        opacity: 0.8;
        cursor: pointer;
      }
    }

    &:hover {
      .primary-stat {
        opacity: 0;
      }
      .mention-result-stat-details {
        display: flex;
        transform: translateY(-10px);
      }
    }

    .primary-stat {
      @extend %label;
      display: inline;
      font-size: 10px;
      font-weight: bold;
      word-wrap: break-word;
      white-space: pre-wrap;
      pointer-events: auto;
      cursor: default;
    }
    .mention-result-stat-details {
      display: none;
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100px); /* adjust as needed */
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
