@import '../../styles/shared.scss';

.connect-paypal-outer {
  background: $offwhite;

  .connect-paypal-inner {
    @extend %flex-col;
    gap: 60px;
    padding-top: 80px;
    padding-bottom: 350px;
    width: min(80%, 1000px);
    margin: 0 auto;

    .connect-paypal-header {
      @extend %flex-col;
      align-items: center;
      text-align: center;

      .connect-paypal-header-icon-container {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        background: $lighter;
        display: grid;
        place-content: center;
        margin-bottom: 40px;
        position: relative;

        img.connect-paypal-header-icon {
          height: 35px;
          width: 35px;
        }

        .connect-paypal-header-success-icon-container {
          display: grid;
          place-content: center;
          position: absolute;
          top: 0;
          right: 0;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background: $error;

          &.success {
            background: $primary;
          }

          svg {
            height: 10px;
            width: 10px;
            color: white;
          }
        }
      }

      .connect-paypal-header-title {
        font-size: 48px;
        font-family: $font-header;
        line-height: 50px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .connect-paypal-header-subtitle {
        font-size: 14px;
        max-width: 400px;
      }
    }

    .connect-paypal-recommendations {
      @extend %flex-row;
      gap: 30px;
      flex-wrap: wrap;

      .connect-paypal-recommendation {
        @extend %flex-col;
        gap: 20px;
        background: white;
        border-radius: 15px;
        padding: 60px;
        flex: 1;

        .connect-paypal-recommendation-title {
          font-family: $font-header;
          font-weight: 600;
          font-size: 30px;
          color: $darkest;

          @include md-down {
            font-size: 30px;
          }
        }

        .connect-paypal-recommendation-description {
          font-size: 14px;
          line-height: 22px;
        }

        a.connect-paypal-recommendation-link {
          @extend %btn-basic;
          @extend %label;
          color: white;
          font-size: 12px;
          background: $darkest;
          text-decoration: none;
          width: max-content;
          padding: 16px 24px;
        }
      }
    }
  }
}
