@import '../../styles/shared.scss';

.section-selection-modal-outer-container {
  font-family: $font-body-new;
  width: 60vw;
  max-height: 600px;
  max-width: 400px;
  padding: 0;
  overflow: scroll;
  background: white;
  padding: 24px;
  border-radius: 16px;
  position: relative;

  .header-container {
    margin-bottom: 12px;
    .header {
      font-size: 20px;
      font-weight: bold;
      color: $darkest;
    }
    .subheader {
      font-size: 12px;
      color: $dark;
    }
  }

  .sections {
    @extend %flex-col;
    gap: 12px;

    .section {
      @extend %btn-basic;
      @extend %label;
      border-radius: 4px;
      border: $border-lightest;
      padding: 8px 12px;
      font-size: 12px;
      color: $darkest;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .close-icn {
    @extend %btn-basic;
    position: absolute;
    padding: 16px 16px 12px 12px;
    top: 0;
    right: 0;
    font-size: 24px;
  }
}
