@import '../../styles/shared.scss';

.chat-filters-outer-container {
  @extend %flex-col;
  background-color: white;
  position: absolute;
  top: 24px;
  right: 24px;
  width: calc(100% - 48px);
  max-height: calc(100% - 48px);
  border-radius: 10px;
  box-shadow: $shadow-lightest;
  z-index: 12;

  .panel-header {
    @extend %flex-row-middle-spread;
    margin-bottom: 8px;
    padding: 16px 20px 0;
    top: 0;

    .title {
      font-size: 20px;
      font-weight: bold;
    }

    .search-container {
      @extend %flex-row-middle;
      gap: 12px;

      &.with-search {
        font-size: 14px;
      }
      input {
        @extend %input-shared;
        min-width: 120px;
      }

      svg {
        @extend %btn-basic;
        font-size: 14px;
        margin-bottom: -1px;
      }
    }

    .close-btn {
      @extend %btn-primary-new;
      padding: 8px 12px;
      font-size: 12px;
    }
  }

  .chat-filters-inner-container {
    padding: 12px 20px 120px;
    overflow: auto;
    min-height: 120px;
    max-height: 400px;
    max-height: calc(100% - 48px);

    .filter-sections {
      @extend %flex-col;
      gap: 32px;

      .filter-section {
        .filter-section-header {
          @extend %label;
          font-size: 10px;
          margin-bottom: 8px;
        }

        .filters {
          @extend %flex-row;
          flex-wrap: wrap;
          gap: 8px;

          .filter {
            @extend %flex-row-middle-spread;
            @extend %btn-basic;
            padding: 9px 12px;
            border: $border-lighter;
            border-radius: 10px;
            max-width: 100%;
            // ellipsis for overflow

            &.toggle-more-less {
              @extend %label;
              font-size: 10px;
              border-color: transparent;
            }

            &.active {
              background-color: $darkest;
              border: $border-darkest;
              color: white;
              opacity: 1;
            }

            .title {
              flex: 1;
              font-size: 12px;
              font-weight: 500;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .count {
              @extend %flex-center;
              font-size: 10px;
              color: $medium;
              font-weight: bold;
              background-color: $offwhite;
              color: $darkest;
              border-radius: 24px;
              margin-left: 4px;
              padding: 2px 6px;

              &.new {
                background-color: $primary;
                color: white;
                padding: 2px 6px;
                border-radius: 12px;
              }
            }
          }
        }
      }
    }
  }
}
