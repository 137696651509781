@import '../../../styles/shared.scss';

.similar-talent-container {
  padding: 12px 24px;
  position: relative;

  .analytics-toggles {
    @extend %flex-row-middle;
    gap: 8px;
    justify-content: flex-end;

    .analytics-toggle {
      @extend %flex-row-middle;
      font-size: 12px;
      cursor: pointer;
      justify-content: flex-end;
      align-items: center;
      color: $dark;
      gap: 4px;
    }
  }

  .similar-talent-results-container {
    @extend %flex-col;

    .empty-results-msg-container {
      .empty-results-msg {
        @extend %label;
        @extend %flex-center;
        width: 100%;
        font-size: 10px;
        font-weight: 500;
        color: $light;
        padding: 48px 48px;
        background-color: $offwhite;
        border-radius: 12px;
        text-align: center;
      }
    }

    .only-preview-message-container {
      @extend %flex-col;
      border: $border-lightest;
      margin: 12px 0;
      border-radius: 16px;
      background: $darkest;
      padding: 24px;
      color: white;

      .header {
        @extend %label;
        font-size: 15px;
        text-transform: uppercase;
        margin-bottom: 4px;
      }

      .message {
        font-size: 13px;
      }
    }

    .similar-talent-results {
      @extend %flex-col;
      width: 100%;
      margin-top: 10px;
      display: grid;
      gap: 18px;
      grid-template-columns: repeat(1, 1fr);

      &.grid {
        grid-template-columns: repeat(2, 1fr);

        @include md-down {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      .empty-results-msg {
        @extend %flex-center;
        width: 100%;
        font-size: 12px;
        color: $light;
        padding: 48px 48px 12px;
        text-align: center;
      }

      .loading-results {
        @extend %flex-center;
        width: 100%;
        margin: 24px 0;
        grid-column: 1/3;
      }
    }
  }
}
