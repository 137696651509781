@import '../../../../styles/shared.scss';

.lookbook-report-outer {
  .lookbook-report-inner {
    @extend %flex-col;
    gap: 60px;
    position: relative;
    font-family: $font-body-new;

    .lookbook-report-section {
      .lookbook-report-section-title {
        font-family: $font-header;
        font-size: 2.1rem;
        font-weight: bold;
        margin-bottom: 25px;
      }
    }

    .lookbook-report-no-data {
      @extend %flex-col;
      align-items: center;
      gap: 10px;
      font-size: 1.1rem;
      font-weight: 500;
      color: $light;
      text-align: center;
      padding-bottom: 400px;

      button {
        @extend %btn-basic;
        @extend %label;
        font-size: 0.7rem;
        font-weight: 500;
        color: $lightest;
        background-color: $primary;
        border: none;
        border-radius: 4px;
        padding: 10px 20px;
        cursor: pointer;
        width: max-content;
      }
    }
  }
}
