@import '../../styles/shared.scss';

.discover-users-outer-container {
  @extend %flex-col;
  width: 100%;
  padding: 48px 24px 0;
  position: relative;
  font-family: $font-body;
  background: $offwhite;

  .discover-users-inner-container {
    @extend %flex-row;
    min-height: 800px;
    width: 100%;
    max-width: 1440px;
    margin: 120px auto;
    transform: translateY(-120px);

    .discover-users-left-container {
      max-width: 260px;
      margin-right: 48px;

      @include md-down {
        display: none;
      }
    }
    .discover-users-main-container {
      flex: 1;
      width: 100%;
    }
  }
}
