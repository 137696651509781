@import '../../../styles/shared.scss';

.arrow-btn,
.arrow-link {
  @extend %flex-row;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: 0.1em;

  &.standard {
    border: $border-darkest;
    color: $darkest;
  }

  &.inverted {
    border: $border-white;
    color: white;
  }

  &.disabled {
    opacity: 0.4;
    cursor: default;
  }

  &.only-arrow-and-text,
  &.only-arrow,
  &.only-text {
    border: $border-transparent;
  }

  .left {
    padding: 18px;
  }

  .right {
    padding: 18px 20px;

    &.standard {
      border-left: $border-darkest;
    }
    &.inverted {
      border-left: $border-white;
    }
    &.only-text {
      display: none;
    }

    &.only-arrow,
    &.only-arrow-and-text {
      border-left: none;
    }

    &.only-arrow-and-text {
      padding: 18px 0;
    }

    svg {
      transform: scaleX(1.2) scaleY(0.8);
      transition: transform 0.2s ease-in-out;

      &.arrow-left {
        transform: scaleX(1.2) scaleY(0.8) rotateY(180deg);
      }
    }
  }

  &:hover {
    &.standard {
      border: $border-dark;
      color: $dark;
    }
    &.inverted {
      border: $border-offwhite;
      color: $offwhite;
    }
    &.only-arrow,
    &.only-text,
    &.only-arrow-and-text {
      border: $border-transparent;
    }

    .right {
      svg {
        transform: scaleX(1.3) scaleY(0.8) translateX(2px);

        &.arrow-left {
          transform: scaleX(1.3) scaleY(0.8) translateX(-2px) rotateY(180deg);
        }
      }
    }
  }
}
