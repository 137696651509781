@import '../../../styles/shared.scss';

.home-header-creators-outer-container {
  @extend %flex-col;
  justify-content: flex-end;
  background-color: white;
  min-height: min(80vh, 800px);
  padding: 0 0 80px;

  @include sm {
    min-height: min(80vh, 600px);
  }

  @include md {
    min-height: min(80vh, 720px);
  }

  @include lg {
    min-height: min(80vh, 1000px);
  }

  .home-header-creators-inner-container {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;

    .spacer {
      height: 10vh;
      min-height: 240px;
    }

    // Match this to the header
    @include md-down {
      padding: 0 20px;
    }

    @include md {
      padding: 0 60px;
    }

    @include lg {
      padding: 0 40px;
    }

    @include xl {
      padding: 0 12px;
    }

    @include xxl {
      max-width: 1440px;
      padding: 0;
    }

    .main-container {
      .title {
        font-family: $font-header;
        line-height: 1.2;
        color: black;
        font-weight: 300;
        margin: 0;

        @include sm-down {
          font-size: 48px;
        }

        @include sm {
          font-size: 48px;
        }

        @include md {
          font-size: 64px;
        }

        @include lg {
          font-size: 84px;
        }

        @include xl {
          font-size: 84px;
        }

        @include xxl {
          font-size: 100px;
        }
      }
      .subtitle {
        line-height: 1.5;
        font-weight: 300;
        color: $darkest;
        margin: 0;

        @include md-down {
          font-size: 40px;
          margin-top: 24px;
        }

        @include md {
          font-size: 40px;
          margin-top: 24px;
          max-width: 480px;
        }

        @include lg {
          font-size: 22px;
          margin-top: 24px;
          max-width: 640px;
        }
        @include lg-down {
          display: none;
        }

        @include xl {
          font-size: 22px;
          margin-top: 36px;
          max-width: 800px;
        }

        @include xxl {
          margin-top: 24px;
          font-size: 22px;
        }
      }

      .actions {
        @extend %flex-row;
        gap: 12px;
        margin-top: 44px;

        @include md-down {
          flex-direction: column;
          align-items: flex-start;
        }

        .action {
          &.primary {
            @extend %btn-darkest-new;
            @extend %label;
            padding: 12px 32px;
            font-size: 14px;

            @include md {
              padding: 18px 36px;
              font-size: 16px;
            }

            @include xl {
              padding: 20px 44px;
              font-size: 16px;
            }
          }

          &.secondary {
            @extend %flex-row-middle;
            @extend %btn-basic;
            color: $darkest;
            font-weight: bold;
            font-size: 16px;
            padding: 12px 24px;
            text-decoration: none;

            @include md {
              padding: 12px 24px;
              font-size: 16px;
            }

            @include xl {
              padding: 18px 36px;
              font-size: 18px;
            }

            svg {
              margin-left: 12px;
              font-size: 14px;

              @include md {
                font-size: 16px;
              }
              @include xl {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
