%header-fancy {
  font-family: $font-header;
  font-weight: bold;
  letter-spacing: 0.7px;
}

%header-fancy-xl {
  @extend %header-fancy;
  font-size: 48px;
  line-height: 44px;
  text-transform: uppercase;
  font-weight: normal;

  @include xl {
    font-size: 64px;
    line-height: 60px;
  }
}

%header-fancy-lg {
  @extend %header-fancy;
  font-size: 40px;
  line-height: 48px;

  @include xl {
    font-size: 56px;
    line-height: 60px;
  }
}

%header-fancy-md {
  @extend %header-fancy;
  font-size: 28px;
  line-height: 45px;

  @include xl {
    font-size: 36px;
  }
}

%header-fancy-sm {
  @extend %header-fancy;
  font-size: 18px;
  line-height: 26px;

  @include xl {
    font-size: 28px;
    line-height: 36px;
  }
}

%header {
  font-family: $font-body-new;
  font-weight: bold;
}

%header-md {
  @extend %header;
  font-size: 28px;
  line-height: 32px;

  @include xl {
    font-size: 36px;
    line-height: 44px;
  }
}

%header-sm {
  @extend %header;
  font-size: 16px;
  line-height: 24px;

  @include xl {
    font-size: 20px;
    line-height: 28px;
  }
}

%header-xs {
  @extend %header;
  font-size: 14px;
  line-height: 20px;

  @include xl {
    font-size: 16px;
    line-height: 24px;
  }
}

%label {
  font-family: $font-body-new;
  letter-spacing: 0.09em;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
}

%label-md {
  @extend %label;
  font-size: 14px;
  line-height: 20px;

  @include xl {
    font-size: 16px;
    line-height: 24px;
  }
}

%label-sm {
  @extend %label;
  font-size: 14px;
  line-height: 20px;
}

%label-xs {
  @extend %label;
  font-size: 12px;
  line-height: 16px;
}

%label-xxs {
  @extend %label;
  font-size: 10px;
  line-height: 13px;
}

%paragraph {
  font-family: $font-body-new;
}

%paragraph-lg {
  @extend %paragraph;
  font-size: 16px;
  line-height: 24px;

  @include xl {
    font-size: 20px;
    line-height: 28px;
  }
}

%paragraph-md {
  @extend %paragraph;
  font-size: 14px;
  line-height: 20px;

  @include xl {
    font-size: 16px;
    line-height: 24px;
  }
}

%paragraph-sm {
  @extend %paragraph;
  font-size: 14px;
  line-height: 20px;
}

%paragraph-xs {
  @extend %paragraph;
  font-size: 12px;
  line-height: 18px;
}
