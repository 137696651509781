@import '../../styles/shared.scss';

.latest-outer-container {
  @extend %flex-col;
  width: 100%;
  position: relative;
  font-family: $font-body-new;
  background: $prettymuchwhite;
  min-height: 100vh;

  .latest-inner-container {
    @extend %flex-col;
    position: relative;
    max-width: 1240px;
    width: 100vw;
    margin: 80px auto 120px;

    .loading-sections {
    }
  }
}
