@import '../../styles/shared.scss';

.modal-outer-container {
  @extend %flex-center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  opacity: 0;
  z-index: $zindex-modal;
  transition: opacity 0.2s ease-in-out;
  font-family: $font-body;

  &.force-outer {
    z-index: $zindex-outmost-modal;
  }

  &.visible {
    background: rgba(0, 0, 0, 0.5);
    opacity: 1;
    pointer-events: inherit;
  }

  .modal-container {
    @extend %flex-center;
    width: 100%;
    height: 100vh;
    position: absolute;

    @include md {
      width: 90vw;
      height: 70vh;
      top: 15vh;
      max-width: 1024px;
      min-height: 320px;
    }

    &.smallForm {
      width: unset;
      height: unset;
    }

    &.fit-content {
      @include md {
        height: auto;
        top: auto;
        max-height: 70vh;
        overflow-y: scroll;
      }
    }

    .modal-inner-container {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      background: white;

      // &.smallForm {
      //   width: unset;
      //   height: unset;
      // }

      @include md {
        border-radius: 10px;
      }

      .modal-content {
        @extend %flex-col;
        padding: 24px;
        overflow: auto;
        height: 100%;
        position: relative;

        &.noPadding {
          padding: 0;
        }

        .modal-header-section {
          margin: 0 0 12px;

          .modal-header {
            font-size: 24px;
            margin: 0;
          }
          .modal-subheader {
            font-size: 12px;
            font-weight: normal;
            margin: 4px 0 0;
          }
        }
      }

      .close-icn {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 28px;
        cursor: pointer;
        z-index: 2;

        @include md {
          top: 12px;
          right: 12px;
          font-size: 24px;
        }
      }

      .loader-container {
        @extend %flex-center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease-in-out;

        &.saving {
          opacity: 1;
        }
      }
    }
  }
}
