@import '../../styles/shared.scss';

.catalog-variants-container {
  .variants-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    place-content: center;
    place-items: center;
    row-gap: 50px;
    column-gap: 30px;
  }

  .no-results {
    font-size: 14px;
    color: $medium;
    text-align: center;
    width: 100%;
  }
}
