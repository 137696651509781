@import '../../../styles/shared.scss';

.brand-report-announcement-outer-container {
  @extend %flex-row-middle;
  overflow: hidden;
  height: 500px;
  width: 75vw;
  border: 1px solid $darker;
  border-radius: 20px;

  @include md-down {
    flex-direction: column-reverse;
    border: none;
    border-radius: none;
    padding: 0;
    height: 100%;
  }

  .main-panel {
    @extend %flex-col-middle;
    height: 100%;
    align-items: flex-start;
    padding: 64px 0 64px 64px;
    border-radius: 100px;

    a {
      @extend %link;
      color: $darkest;
    }
    .badge {
      @extend %label;
      font-size: 12px;
      margin-bottom: 12px;
    }

    .title {
      @extend %header-fancy;
      margin-bottom: 12px;
      font-size: 56px;
      line-height: 56px;
    }
    .body {
      @extend %paragraph-sm;
      color: $darker;
      max-width: 350px;
    }
    .continue-btn-container {
      @extend %flex-row;
      margin-top: 24px;
      gap: 12px;
    }
  }

  img {
    @extend %animation-fade;
    transform: translateY(5%);
    width: 50%;
    height: 100%;
    object-fit: contain;
    pointer-events: none;

    @include md-down {
      display: none;
    }
  }
}
