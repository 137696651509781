@import '../../../../styles/shared.scss';

$all-phones-height: 1200px; // Height of all three stacked
$phone-device-height: 600px; // Height of a single phone device
$phone-image-height: 645px; // Height of the phone including shadow
$phone-image-width: 820px; // Width of the phone including shadow
$spacing-gap: 280px; // Spacing between the phones

$phone-ratio-sm: 0.6;
$phone-ratio-md: 0.7;
$phone-ratio-lg: 0.8;
$phone-ratio-xl: 0.9;
$phone-ratio-xxl: 1;

.home-creator-animation-phones-outer-container {
  min-width: 100px;

  &.fixed {
    @extend %flex-center;
    position: fixed;
    top: calc((100vh - $phone-device-height * $phone-ratio-sm) / 2);
    transition: top 1s ease-in-out;
    width: 100%;
    opacity: 0;
    // pointer-events: none;

    @include md {
      top: calc((100vh - $phone-device-height * $phone-ratio-md) / 2);
    }

    @include lg {
      top: calc((100vh - $phone-device-height * $phone-ratio-lg) / 2);
    }

    @include xl {
      top: calc((100vh - $phone-device-height * $phone-ratio-xl) / 2);
    }

    @include xxl {
      top: calc((100vh - $phone-device-height * $phone-ratio-xxl) / 2);
    }

    &.zoomed {
      opacity: 1;
    }

    .phones {
      width: 100%;
      .phone {
        background-color: transparent;
        top: 0;
      }
    }
  }

  &:not(.fixed) {
    opacity: 1;
    &.zoomed {
      opacity: 0;
    }
  }

  &.end {
    height: 400px; // Force shrink it because the shading on the image makes it too long
  }

  .phones {
    position: relative;
    height: calc($all-phones-height * $phone-ratio-sm);

    @include md {
      height: calc($all-phones-height * $phone-ratio-md);
    }
    @include lg {
      height: calc($all-phones-height * $phone-ratio-lg);
    }

    @include xl {
      height: calc($all-phones-height * $phone-ratio-xl);
    }

    @include xxl {
      height: calc($all-phones-height * $phone-ratio-xxl);
    }

    .phone {
      width: calc($phone-image-width * $phone-ratio-sm);
      height: calc($phone-image-height * $phone-ratio-sm);
      background-color: transparent;
      position: absolute;
      top: 0;

      @include md {
        width: calc($phone-image-width * $phone-ratio-md);
        height: calc($phone-image-height * $phone-ratio-md);
      }

      @include lg {
        width: calc($phone-image-width * $phone-ratio-lg);
        height: calc($phone-image-height * $phone-ratio-lg);
      }

      @include xl {
        width: calc($phone-image-width * $phone-ratio-xl);
        height: calc($phone-image-height * $phone-ratio-xl);
      }

      @include xxl {
        width: calc($phone-image-width * $phone-ratio-xxl);
        height: calc($phone-image-height * $phone-ratio-xxl);
      }

      &:first-child {
        z-index: 3;
        img {
          transform: translateY(calc(86px * -1));
        }
      }
      &:nth-child(2) {
        z-index: 2;
        img {
          transform: translateY(0px);
        }
      }
      &:nth-child(3) {
        z-index: 1;
        img {
          transform: translateY(calc(86px * 1));
        }
      }
      &.zoomed {
        &:first-child {
          top: 0;
          img {
            transform: translateY(calc((1200px - $phone-image-height) * -1 * $spacing-gap * $phone-ratio-sm / $phone-image-height)) scale(1.2);
            @include md {
              transform: translateY(calc((1200px - $phone-image-height) * -1 * $spacing-gap * $phone-ratio-md / $phone-image-height)) scale(1.2);
            }
            @include lg {
              transform: translateY(calc((1200px - $phone-image-height) * -1 * $spacing-gap * $phone-ratio-lg / $phone-image-height)) scale(1.2);
            }
            @include xl {
              transform: translateY(calc((1200px - $phone-image-height) * -1 * $spacing-gap * $phone-ratio-xl / $phone-image-height)) scale(1.2);
            }
            @include xxl {
              transform: translateY(calc((1200px - $phone-image-height) * -1 * $spacing-gap * $phone-ratio-xxl / $phone-image-height)) scale(1.2);
            }
          }
        }
        &:nth-child(2) {
          top: 0;
          img {
            transform: translateY(0) scale(1.2);
          }
        }
        &:nth-child(3) {
          top: 0;
          img {
            transform: translateY(calc((1200px - $phone-image-height) * $spacing-gap * $phone-ratio-sm / $phone-image-height)) scale(1.2);
            @include md {
              transform: translateY(calc((1200px - $phone-image-height) * $spacing-gap * $phone-ratio-md / $phone-image-height)) scale(1.2);
            }
            @include lg {
              transform: translateY(calc((1200px - $phone-image-height) * $spacing-gap * $phone-ratio-lg / $phone-image-height)) scale(1.2);
            }
            @include xl {
              transform: translateY(calc((1200px - $phone-image-height) * $spacing-gap * $phone-ratio-xl / $phone-image-height)) scale(1.2);
            }
            @include xxl {
              transform: translateY(calc((1200px - $phone-image-height) * $spacing-gap * $phone-ratio-xxl / $phone-image-height)) scale(1.2);
            }
          }
        }
      }

      img {
        width: 100%;
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
        transform-origin: top;
        opacity: 0;

        &:nth-child(2) {
          position: absolute;
          top: 0;
          left: 0;
        }
        &:nth-child(3) {
          position: absolute;
          top: 0;
          left: 0;
        }

        &.visible {
          opacity: 1;
        }
      }
    }
  }
}
