@import '../../../styles/shared.scss';

.onboarding-panel-tasks-container {
  .onboarding-tasks-sections {
    margin-top: 40px;
    padding-bottom: 108px;

    @include md {
      padding-bottom: 32px;
    }

    @include xl {
      margin-top: 56px;
    }

    .tasks-section {
      .section-header {
        font-size: 16px;
        font-weight: bold;
        color: $darkest;
        margin-bottom: 12px;

        @include xl {
          margin-bottom: 24px;
        }
      }

      .task {
        @extend %flex-row-middle;
        padding: 16px 20px;
        margin-bottom: 16px;
        border: $border-offwhite;
        border-radius: 4px;

        @include md {
          margin-bottom: 12px;
          padding: 20px 24px;
        }

        @include xl {
          margin-bottom: 24px;
          padding: 24px 32px;
        }

        &.selected {
          border: $border-primary;
        }

        &.active {
          &:not(.selected) {
            cursor: pointer;
            transition: transform 0.2s ease-in-out;

            &:hover {
              border: $border-light;
              transform: scale(1.01);
            }
          }
        }

        &:not(.active) {
          background: $offwhite;
          opacity: 0.6;
        }

        .radio-btn {
          margin-right: 12px;
          min-width: 20px;
          min-height: 20px;
          border-radius: 50%;
          border: $border-lightest;

          @include md {
            margin-right: 16px;
          }

          &.selected {
            border: $border-primary;

            .selected-mark {
              min-width: 14px;
              min-height: 14px;
              border-radius: 50%;
              margin: 2px;
              background: $primary;
            }
          }
        }
      }

      .icon {
        margin-right: 12px;

        @include md {
          margin-right: 16px;
        }

        img {
          width: 56px;
          height: 56px;
        }

        .fa-icon-wrapper {
          @extend %flex-center;
          width: 56px;
          height: 56px;
          font-size: 28px;
        }
      }
      .text {
        .header {
          @extend %header-sm;
        }
        .subheader {
          @extend %paragraph-sm;
        }
      }
    }
  }
  .close-icn {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 16px;
    font-size: 24px;
  }
}
