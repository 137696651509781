@import '../../styles/shared.scss';

.profile-tabs-outer-container {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  overflow: auto;

  .profile-tabs-inner-container {
    @extend %flex-center;
    flex-grow: 1;
    margin: 12px 0;
    width: max-content;

    @include md-down {
      &.many-tabs {
        padding: 0 20px;
      }
    }

    @include md {
      margin: 12px 0 6px;
    }

    .tab {
      @extend %flex-row-middle;
      position: relative;
      margin: 8px 4px;
      padding: 2px 4px 4px;
      font-size: 12px;
      letter-spacing: 0.1em;
      font-weight: bold;
      color: $darker;
      text-transform: uppercase;
      font-family: $font-body;
      border-bottom: 2px solid transparent;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        color: $medium;
      }

      &.compact {
        svg {
          display: none;
          @include sm {
            display: flex;
          }
        }
      }

      &.very-compact {
        @include sm-down {
          font-size: 11px;
          margin: 8px 1px;
        }
      }

      &.empty {
        display: none;
      }

      .short {
        display: flex;
        @include md {
          display: none;
        }
      }

      .long {
        display: none;
        @include md {
          display: flex;
        }
      }

      @include sm {
        margin: 8px 8px;
        padding: 2px 4px 6px;
        font-size: 13px;
      }

      &.hidden {
        color: $light;
      }

      &.active {
        border-bottom: 2px solid $darker;
        color: $darkest;
      }

      .edit-btn {
        @extend %btn-basic;
        margin-left: 8px;
        font-size: 0.8em;
      }

      .count-badge {
        @extend %flex-center;
        position: absolute;
        top: -4px;
        right: -18px;
        font-size: 11px;
        color: white;
        margin-left: 12px;
        background: $error;
        border-radius: 50%;
        height: 14px;
        width: 14px;
        padding: 0 0 0 1px;
        font-weight: bold;
        transform: translateX(-12px) translateY(-4px);

        @include sm {
          right: -20px;
        }
      }

      .new-badge {
        @extend %flex-center;
        font-size: 10px;
        padding: 2px 6px;
        color: white;
        border-radius: 3px;
        background: $dark;
        margin-left: 4px;

        @include sm {
          right: -12px;
        }
      }
    }
  }

  .tab.add-new-tab {
    @extend %btn-basic;
    @extend %flex-center;
    border-radius: 4px;
    margin-left: 4px;
    min-width: 20px;
    min-height: 20px;
    padding: 0;
    margin-top: 0px;
    border: $border-lightest;

    @include md {
      margin-top: -2px;
      min-width: 24px;
      min-height: 24px;
    }

    svg {
      font-size: 12px;
      color: $dark;
    }
  }
}
