@import '../../styles/shared.scss';

.link-result.row {
  @extend %flex-row;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  padding: 8px 0;
  border-bottom: 2px solid $offwhite;

  &:last-child {
    border-bottom: none !important;
  }

  &.orders {
    flex-direction: row;
    margin: 8px 0;
    padding: 8px 0;
    border-bottom: 1px solid $offwhite;
    .cell {
      font-size: 11px !important;
    }
  }

  &.users {
    flex-direction: row;
    margin: 4px 0;
    padding: 4px 0;
    border-bottom: 1px solid $offwhite;
    .cell {
      font-size: 12px !important;
    }
  }

  @include md-down {
    &.stack-row-on-mobile {
      flex-direction: column;
      margin: 16px 0;
      padding: 16px 0;
      align-items: stretch;
    }
  }

  .empty-img {
    @extend %flex-center;
    background: $darkest;
    border-radius: 8px;
    font-family: $font-header;
    font-size: 36px;
    line-height: 36px;

    .s {
      font-size: 36px;
      line-height: 36px;
      color: $white;
      margin-bottom: -4px;
    }
  }

  .cell {
    flex-shrink: auto !important;
    font-size: 13px;
    font-weight: bold;

    @include md-down {
      font-size: 11px;
    }

    &.created-at {
      text-transform: uppercase;
      font-size: 13px;
      color: $dark;
    }

    &.domain {
      @extend %flex-center;
      cursor: pointer;

      &:hover {
        text-decoration: underline;

        .data .external-badge {
          opacity: 1;
        }
      }

      .data {
        @extend %flex-row-middle;
        position: relative;

        .external-badge,
        .target-badge,
        .partner-badge {
          @extend %flex-center;
          position: absolute;
          top: 0;
          left: calc(100% + 4px);
          height: 100%;
          font-size: 11px;
        }

        .partner-badge {
          svg {
            color: $primary;
            transform: translateY(1px);
          }
        }

        .target-badge {
          svg {
            color: $medium;
            transform: translateY(1px);
          }
        }

        .external-badge {
          opacity: 0;
        }
      }
    }

    &.pin {
      @extend %flex-row-middle;
      align-items: stretch;

      @include md {
        flex-direction: row;
        align-items: center;
      }

      &.has-select {
        img,
        .empty-img {
          margin-left: 18px;
        }
      }

      img,
      .empty-img {
        width: 100px; // ADJUSTING_IMAGE_SIZING -> if updating this, be sure to search for this term
        height: 100px;
        min-width: 100px;
        min-height: 100px;
        max-width: 100px;
        max-height: 100px;
        object-fit: contain;
        margin: 0 12px 0 0;

        @include lg {
          width: 72px;
          height: 72px;
          min-width: 72px;
          min-height: 72px;
          max-width: 72px;
          max-height: 72px;
        }
      }
      .metadata {
        @extend %flex-col;
        flex: 1;
        max-width: calc(100% - 112px);

        @include md {
          max-width: calc(100% - 92px);
        }

        .title {
          font-size: 13px;
          line-height: 15px;
          width: 90%;
          color: $darkest;
        }

        .merchant,
        .link {
          font-size: 11px;
          color: $medium;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .link {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .bullets {
          @extend %flex-row-middle;
          flex-wrap: wrap;

          .stat {
            @extend %flex-row;
            font-size: 11px;
            color: $medium;
            font-weight: bold;
            margin-top: 4px;
            white-space: nowrap;
          }

          .stat-info {
            margin-left: 2px;

            svg {
              color: $light;
              font-size: 9px;
              margin-bottom: -1px;
            }
          }

          .bullet {
            margin: 0 4px;
            color: $light;
            font-weight: normal;
          }
        }

        .action-btns {
          @extend %flex-row-middle;
          margin-top: 6px;
          font-size: 12px;
          color: $dark;

          .action-btn {
            @extend %clickable-subtle;
            margin-right: 8px;
            cursor: pointer;

            &.disabled {
              cursor: default;
              color: $medium;
              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &.user,
    &.product {
      @extend %flex-row-middle;
      align-items: stretch;

      @include md {
        flex-direction: row;
        align-items: center;
      }

      .empty-img {
        @extend %flex-center;
        background: $darkest;
        border-radius: 8px;
        font-family: $font-header;
        font-size: 36px;
        line-height: 36px;

        @include lg {
          .s {
            font-size: 18px;
            line-height: 18px;
          }
        }
      }

      img,
      .empty-img {
        width: 100px;
        height: 100px;
        min-width: 100px;
        min-height: 100px;
        max-width: 100px;
        max-height: 100px;
        border-radius: 50%;
        object-fit: cover;
        margin: 0 12px 0 0;

        @include lg {
          width: 32px;
          height: 32px;
          min-width: 32px;
          min-height: 32px;
          max-width: 32px;
          max-height: 32px;
        }
      }

      .metadata {
        @extend %flex-col;
        flex: 1;
        max-width: calc(100% - 112px);

        @include md {
          max-width: calc(100% - 92px);
        }

        .product-title {
          font-size: 13px;
          width: 90%;
          color: $darker;
        }

        .title {
          @extend %btn-text-underline;
          font-size: 13px;
          width: 90%;
          color: $darker;
        }

        .mentions,
        .brand {
          font-size: 10px;
          color: $medium;
          margin-top: 2px;
          text-transform: uppercase;
        }

        .username {
          font-size: 9px;
          color: $dark;
          margin-top: 2px;
          font-weight: normal;
        }
      }
    }

    &.collection-name,
    &.user-name {
      text-align: center;
    }

    &.views-total,
    &.order-total,
    &.commission-total {
      font-size: 14px;

      @include md-down {
        font-size: 12px;
      }
    }

    &.order-num {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.upgrade {
      font-size: 11px;
      cursor: pointer;
      color: $dark;
    }

    &.clickable {
      @extend %btn-basic;
      text-decoration: underline;
    }

    &.select {
      cursor: pointer;
      padding: 8px 0;

      @include md-down {
        position: absolute;
        left: 0;
        height: 100px; // ADJUSTING_IMAGE_SIZING -> if updating this, be sure to search for this term
        z-index: 1;
      }

      &:hover {
        .checkbox svg {
          opacity: 0.2;
        }
      }

      &.selected {
        .checkbox svg {
          opacity: 1;
        }
      }
      .checkbox {
        @extend %flex-center;
        min-width: 14px;
        width: 14px;
        min-height: 14px;
        height: 14px;
        border: $border-lighter;
        border-radius: 4px;

        svg {
          opacity: 0;
          font-size: 10px;
        }
      }
    }

    &.short-link {
      @extend %flex-row;
      align-items: stretch;
      margin-top: 12px;

      @include md {
        margin-top: 0;
      }

      .link {
        border: 1px solid $light;
        border-right-width: 0;
        padding: 10px 12px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        font-size: 14px;
        white-space: nowrap;
        color: $dark;
        flex: 1;
        text-decoration: none;

        @include md {
          flex: 0;
          font-size: 11px;
          padding: 6px 12px;
        }
      }
      .copy-btn {
        @extend %flex-center;
        padding: 6px 12px;
        background: $darker;
        color: white;
        border-radius: 4px;
        cursor: pointer;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .toolip-icon {
      margin-left: 4px;
    }
  }
}
