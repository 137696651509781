@import '../../styles/shared.scss';

.catalog-variant-rate-card {
  @extend %flex-col;
  align-items: center;
  background-color: $white;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  max-width: 250px;
  cursor: pointer;
  transition: transform 0.25s ease-in-out;

  &:hover {
    transform: scale(0.99);
  }

  .variant-rate {
    font-size: 14px;
    border-radius: 16px;
    padding: 4px 8px;
    background-color: $primaryLightest;
    margin: 10px 0;

    &.skeleton {
      @extend %animation-skeleton-fade;
      background: $prettymuchwhite;
      width: 60%;
      height: 25px;
    }
  }

  .variant-image {
    margin-bottom: 14px;
    height: 200px;
    width: 100%;

    img {
      transition: transform 0.25s ease-in-out;
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1;
    }

    &.skeleton {
      @extend %animation-skeleton-fade;
      background: $prettymuchwhite;
    }
  }

  .variant-meta {
    text-align: center;
    font-size: 12px;
    color: $darkest;
    display: flex;
    flex-direction: column;
    padding: 0 10px 16px;
    align-items: center;
    height: 80px;
    width: 100%;

    .variant-brand.skeleton {
      @extend %animation-skeleton-fade;
      background: $prettymuchwhite;
      width: 40%;
      height: 14px;
    }

    .variant-title {
      margin-top: 4px;
      font-size: 14px;
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      &.skeleton {
        @extend %animation-skeleton-fade;
        background: $prettymuchwhite;
        width: 60%;
        height: 14px;
      }
    }

    .variant-sibling-info {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 4px;

      .sibling-tag .bullet {
        margin: 0 4px;
      }
    }
  }
}
