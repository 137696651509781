@import '../../styles/shared.scss';

.result-outer-container {
  @extend %flex-col;
  width: 100%;
  font-family: $font-body;
  background: $prettymuchwhite;

  .result-inner-container {
    @extend %flex-col;
    width: 100%;
    max-width: 672px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 24px 24px;
    position: relative;

    @include md {
      padding: 44px 16px 0;
    }

    &.config-visible {
      padding-top: 44px;
    }

    .back-link {
      &.btn {
        @extend %btn-text;

        svg {
          margin-right: 12px;
        }
      }

      text-decoration-line: none;
      font-family: $font-body;
      font-size: 14px;
      font-weight: bold;
      color: $dark;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .back {
        width: 16px;
        height: 18px;
        margin-right: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $dark;
      }
    }

    .settings-management-btns {
      @extend %flex-row;
      position: absolute;
      top: 24px;
      right: 16px;

      .view-as-public-switch {
        @extend %flex-row-middle;
        padding: 8px 12px;
        color: $dark;
        font-size: 11px;
        margin-left: 12px;

        .switch {
          margin: -1px 0 0 4px;
        }
      }

      .submit-btn {
        @extend %btn-outline;
        text-transform: uppercase;
        padding: 6px 12px;
      }
    }

    .result-card {
      background: white;
      border-radius: 10px;
      border: $border-lightest;
      color: $dark;
    }
  }

  .result-disclaimer {
    @extend %flex-col;
    width: 100%;
    max-width: 672px;
    margin: 0 auto;
    padding: 64px 24px 128px;
    font-size: 12px;
    color: $medium;
    line-height: 1.4;
    border-top: 2px solid $offwhite;
  }
}
