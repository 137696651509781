@import '../../../styles/shared.scss';

.brand-lookbook-header-outer {
  width: 100%;
  position: relative;

  .cover-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }

  .cover-image-background {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.9) 100%);
  }

  &.has-cover-image {
    .brand-lookbook-header-inner {
      color: white;
      min-height: 400px;
      border-bottom: none;
    }
  }

  .brand-lookbook-header-inner {
    width: 87%;
    max-width: 1240px;
    margin: 0 auto;
    @extend %flex-col;
    justify-content: flex-end;
    padding-bottom: 20px;
    position: relative;
    border-bottom: $border-lightest;
    padding-top: 75px;

    .text-container {
      @extend %flex-row;
      justify-content: space-between;
      margin-top: 50px;

      @include md-down {
        flex-direction: column;
        gap: 20px;
      }

      > div {
        @extend %flex-col;
        gap: 15px;
        justify-content: flex-end;

        &#options-and-configuration {
          @include md-down {
            flex-direction: row-reverse;
            justify-content: space-between;
            gap: 20px;
          }

          @include sm-down {
            display: none;
          }

          .toggles {
            @extend %flex-row;
            gap: 10px;
            justify-content: flex-end;
            align-items: center;

            .toggle {
              @extend %label;
              @extend %flex-row;
              cursor: pointer;
              gap: 5px;
              font-size: 0.85rem;
              align-items: center;
              color: black;

              &.toggle-disabled {
                opacity: 0.5;
                pointer-events: none;
              }

              @include md-down {
                font-size: 0.7rem;
              }
            }
          }

          .configuration {
            @extend %flex-row;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: flex-end;

            .configuration-button {
              @extend %btn-basic;
              @extend %label;
              @extend %flex-row;
              gap: 5px;
              font-size: 14px;
              align-items: center;
              padding: 14px 16px;
              border-radius: 5px;
              background-color: white;
              color: black;
              cursor: pointer;
              justify-content: center;
              width: max-content;

              @include sm {
                font-size: 0.65rem;

                svg {
                  height: 10px;
                  width: 10px;
                }
              }

              @include md {
                font-size: 0.7rem;

                svg {
                  height: 15px;
                  width: 15px;
                }
              }

              @include lg {
                font-size: 0.8rem;

                svg {
                  height: 18px;
                  width: 18px;
                }
              }

              @include xl {
                font-size: 0.85rem;

                svg {
                  height: 20px;
                  width: 20px;
                }
              }

              &#open-lookbook-settings {
                border: $border-dark;
              }

              &#add-product {
                background-color: black;
                color: white;
              }

              &#send-gifting-request {
                border: $border-dark;
              }
            }
          }

          &.has-cover-image {
            .toggles {
              .toggle {
                color: white;
              }
            }

            .configuration {
              .configuration-button {
                &#open-lookbook-settings {
                  border: $border-dark;
                }

                &#add-product {
                  background-color: white;
                  border: $border-dark;
                  color: black;
                }

                &#send-gifting-request {
                  background-color: $primary;
                  color: white;
                }
              }
            }
          }
        }

        &#backlink-and-title {
          .backlink {
            @extend %label;
            @extend %flex-row;
            gap: 5px;
            font-size: 0.7rem;
            color: black;
            @extend %btn-basic;

            svg {
              margin: 0 4px 0 0;
              transform: translateY(1px);
            }

            a {
              text-decoration: none;
              color: black;
            }
          }

          .lookbook-fulfillment-status-container {
            display: flex;
            flex-direction: column;
            width: min-content;
            border-radius: 20px;
            background-color: $white;
            padding: 8px 12px;
            white-space: nowrap;
            color: $darkest;
            text-transform: none;
            font-weight: bold;
            font-size: 12px;

            .display-container {
              @extend %flex-row-middle;

              .display-icon {
                border-radius: 50%;
                background-color: $primary;
                width: 24px;
                height: 24px;
                @extend %flex-center;
                margin-right: 8px;

                &.partially-connected {
                  background-color: $gold;
                }

                &.manual {
                  background-color: $light;
                }

                svg {
                  font-size: 14px;
                  color: $white;
                }
              }

              .reasoning {
                svg {
                  margin-left: 8px;
                  font-size: 16px;
                }
              }
            }
          }

          .integration-inventory-container {
            height: 16px;

            .inventory-status {
              display: flex;
              font-size: 12px;
              color: $darkest;

              .single-location {
                margin-left: 4px;

                span {
                  margin-left: 4px;
                  font-weight: bold;
                }
              }
            }
          }

          .switch-to-manual-container {
            font-size: 12px;
            color: $darkest;

            span {
              font-weight: bold;
              text-decoration: underline;
              cursor: pointer;
            }
          }

          textarea.title {
            @include xs {
              min-width: 200px;
            }

            @include sm {
              min-width: 200px;
            }

            @include md {
              min-width: 400px;
            }

            @include lg {
              min-width: 500px;
            }

            @include xl {
              min-width: 700px;
            }

            @include xxl {
              min-width: 700px;
            }

            @extend %input-inline;
            background-color: transparent;
            font-family: $font-header;
            letter-spacing: 0.7px;
            font-weight: 500;
            font-size: 3rem;
            color: $darkest;
            resize: none;

            &::placeholder {
              color: $lighter;
            }
          }

          &.has-cover-image {
            textarea.title {
              color: white;
            }

            .backlink {
              color: white;
            }

            .lookbook-fulfillment-status svg {
              color: white;
            }

            .integration-inventory-container {
              .inventory-status {
                color: white;
              }
            }

            .switch-to-manual-container {
              color: white;
            }
          }
        }
      }
    }

    .add-image-button {
      @extend %btn-basic;
      @extend %label;
      @extend %flex-row;
      font-size: 0.8rem;
      position: absolute;
      top: 30px;
      left: 0;
      padding: 10px;
      border-radius: 500px;
      gap: 5px;
      background-color: white;
      box-shadow: $shadow-light;
      color: black;
      cursor: pointer;

      img {
        height: 15px;
        width: 15px;
        filter: brightness(0);
      }
    }
  }
}
