@import '../../../styles/shared.scss';

.chat-message-opportunity-preview-card {
  @extend %flex-row;
  background-color: $black;
  border-radius: 10px;
  max-width: 480px;
  width: 100%;
  margin: 12px auto 0;
  overflow: hidden;
  align-items: stretch;

  @include md-down {
    flex-direction: column;
    max-width: 240px;
  }

  .opportunity-image-container {
    height: 160px;
    width: 120px;

    @include md-down {
      height: 240px;
      width: 100%;
    }

    .opportunity-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .opportunity-info {
    @extend %flex-col-spread;
    flex: 1;
    padding: 24px;

    @include md-down {
      gap: 16px;
    }

    .display-data {
      .opportunity-title {
        font-family: $font-header;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 4px;
        color: white;
      }

      .opportunity-metadata {
        font-size: 14px;
        color: $lightest;
        margin-bottom: 12px;
      }
    }

    .actions {
      @extend %flex-row;
      justify-content: flex-end;

      @include md-down {
        justify-content: flex-start;
      }

      .view-button {
        @extend %btn-primary-new;
        font-size: 12px;
        padding: 8px 24px;
        align-self: flex-end;
      }
    }
  }
}
