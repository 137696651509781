@import '../../styles/shared.scss';

.footer-outer-shell {
  font-family: $font-body-new;

  &.home {
    border-top: $border-white;
  }

  .footer-outer-container {
    @extend %flex-row-middle;
    background: $darkest;
    color: white;
    width: 100%;
    z-index: 5;
    padding: 64px 12px;

    @include md {
      padding: 80px 24px;
    }

    @include xl {
      padding: 124px 24px;
    }

    &.public {
      @extend %flex-col-middle;
      margin: 64px 0 108px;
      padding: 24px 24px 64px;
      background: white;

      .footer-home-link {
        @extend %flex-col-center;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        .footer-public-logo-header {
          @extend %label;
          color: $dark;
          font-size: 10px;
        }

        img.logo {
          width: 100px;
        }

        &:hover {
          opacity: 0.6;
        }
      }
    }

    .footer-inner-container {
      @extend %flex-col;
      width: 100%;
      margin: 0 auto;
      max-width: 1024px;
      padding: 0 16px;

      @include sm {
        padding: 0 26px;
      }

      .footer-main-body {
        @extend %flex-col;
        flex-wrap: wrap;

        @include sm {
          flex-direction: row;
          justify-content: space-between;
        }

        .footer-panel {
          @extend %flex-col;
          flex: 1;
          padding: 0 0 64px;

          @include sm {
            padding: 0 0 32px;
          }

          &.left {
            min-width: 50%;
            margin-right: 48px;
          }

          &.with-subpanels {
            @extend %flex-row;
            justify-content: space-between;
            flex-wrap: wrap;
            flex: 1;
            gap: 48px;
            // margin-right: 48px;

            // @include xl {
            //   margin-right: 96px;
            // }

            .footer-subpanel {
              flex: 1;
            }
          }

          .footer-panel-header-main {
            @extend %header-fancy-md;
            color: $white;
          }

          .footer-panel-subheader-main {
            @extend %paragraph-sm;
            color: $white;
            margin-bottom: 24px;
          }

          .footer-panel-header {
            @extend %label-sm;
            color: $white;
            margin-bottom: 32px;
          }

          ul {
            padding: 0;
            margin: 0;

            .group {
              margin: 8px 0 16px;
            }

            li {
              list-style-type: none;
              margin-bottom: 2px;
              white-space: nowrap;

              .footer-panel-link {
                @extend %flex-row-middle;
                font-size: 14px;
                line-height: 20px;
                text-decoration: unset;
                color: $white;
                white-space: nowrap;

                &:focus {
                  outline: none;
                  color: $white;
                  font-weight: bold;
                }

                .new-badge {
                  background: $dark;
                  font-size: 10px;
                  padding: 2px 6px;
                  margin-left: 6px;
                  color: $white;
                  border-radius: 3px;
                  font-weight: bold;
                }
              }
            }
          }

          .email-container {
            margin-top: 12px;

            form {
              @extend %flex-col;

              @include md {
                flex-direction: row;
              }

              input {
                @extend %input-outline-inverted;
                flex: 1;
              }

              button {
                @extend %btn-outline;
                background: transparent;
                color: white;
                border-color: white;
                padding: 8px 24px;
                margin: 8px 0 0 0;

                @include md {
                  margin: 0 0 0 8px;
                }
              }
            }

            .join-again {
              font-weight: bold;
              cursor: pointer;
              font-size: 14px;
            }
          }

          .footer-panel-social-links {
            margin-top: 14px;

            a {
              @extend %btn-basic;
              @extend %flex-row-middle;
              text-decoration: none;

              .follow-label {
                @extend %label-sm;
                color: white;
                text-decoration: none;
              }

              img {
                height: 17px;
                margin-right: 16px;
                color: white;
                border-radius: 2px;
                margin-left: 8px;
              }
            }
          }
        }

        .footer-right-panel {
          min-width: 208px;
        }
      }

      .footer-brand-container {
        text-decoration: unset;

        .footer-logo {
          font-size: 20px;
          font-family: $font-header;
          font-weight: bold;
          color: $white;
          cursor: pointer;
        }
        .footer-copyright {
          font-size: 18px;
          color: $white;
          cursor: pointer;
          margin-top: 4px;
          margin-right: 12px;
          font-size: 12px;
        }

        &:focus {
          outline: none;
          opacity: 0.7;
        }
      }
    }
  }
}
