@import '../../../styles/shared.scss';

.add-pin-modal-geo-link-outer-container {
  @extend %flex-col;
  max-width: 100%;
  width: 100%;
  position: relative;

  .geo-link-header {
    background: $dark;
    padding: 8px 16px;
    border-radius: 4px;
    color: white;
    margin: 0 12px;
    font-weight: bold;
    text-align: center;

    @include md {
      margin: 0 12px 0 0;
    }
  }

  .section {
    .section-header {
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      color: $dark;
      margin-bottom: 8px;
    }
  }

  .current-geo-links {
    padding: 16px;

    &.default {
      background: $offwhite;
      margin: 12px 12px 0;
      border-radius: 4px;
      border-top: $border-offwhite;

      @include md {
        margin: 12px 12px 0 0;
      }
    }

    .current-geo-link {
      @extend %flex-row-spread;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      .data {
        width: calc(100% - 60px);
        overflow: hidden;

        .countries {
          font-weight: bold;
          font-size: 16px;
          color: $darkest;
          margin-top: 4px;
        }
        .link {
          font-weight: bold;
          font-size: 12px;
          color: $medium;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .payout {
          font-weight: bold;
          font-size: 10px;
          color: $medium;
        }
      }

      .actions {
        @extend %flex-row-middle;
        justify-content: flex-end;
        width: 40px;

        .action-icn {
          font-size: 16px;
          cursor: pointer;
          color: $medium;

          &.copy {
            font-size: 15px;
          }

          &:not(:last-child) {
            margin-right: 8px;
          }

          &:hover {
            opacity: 0.4;
          }
        }
      }
    }
  }

  .add-new-link-btn {
    @extend %btn-offwhite;
    padding: 8px 32px;
    align-self: center;
    letter-spacing: 0;
    margin-top: 24px;
  }

  .add-new-link-form {
    border: $border-lightest;
    border-radius: 4px;
    align-items: stretch;
    position: relative;
    padding: 32px 12px 24px;
    margin: 16px;

    .close-new-link-form {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
      color: $dark;

      &:hover {
        opacity: 0.4;
      }
    }

    .url-input {
      @extend %input-shared;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    .country-select {
      margin-bottom: 12px;
    }

    .new-card-preview {
      margin-bottom: 12px;

      .current-countries {
        @extend %flex-row-middle;
        font-size: 14px;
        padding-bottom: 8px;
        font-weight: bold;

        .current-country {
          @extend %flex-row-middle;
          margin-right: 8px;
          background: $offwhite;
          padding: 4px 8px;
          color: $darker;
          border-radius: 4px;
          cursor: pointer;

          svg {
            margin-left: 4px;
            font-size: 12px;
          }

          &:hover {
            opacity: 0.4;
          }
        }
      }
      .payout {
        font-size: 12px;
        padding-left: 2px;
      }

      .empty-countries {
        font-style: italic;
        color: $lighter;
        font-size: 16px;
      }
      .empty-link {
        font-style: italic;
        color: $lighter;
        font-size: 12px;
      }
    }

    .add-new-btn {
      @extend %btn-offwhite;
      padding: 8px;
      width: 100%;

      &.active {
        @extend %btn-dark;
      }
    }
  }

  .alternative-options {
    margin-top: 32px;
    padding: 32px 16px;
    border-top: $border-lightest;

    @include md {
      margin-right: 12px;
    }

    .recommended-urls {
      margin-bottom: 12px;
      .recommended-url {
        @extend %flex-row-middle-spread;
        margin-bottom: 8px;
        @include md {
          padding: 0;
        }

        .data {
          width: calc(100% - 60px);
          overflow: hidden;

          .url {
            font-weight: bold;
            font-size: 12px;
            color: $medium;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .payout {
            font-weight: bold;
            font-size: 10px;
            color: $medium;
          }
        }

        .actions {
          @extend %flex-row-middle;
          justify-content: flex-end;
          width: 40px;

          .action-icn {
            font-size: 14px;
            cursor: pointer;
            color: $medium;
            text-decoration: none;

            &.copy {
              font-size: 15px;
            }

            &:not(:last-child) {
              margin-right: 8px;
            }

            &:hover {
              opacity: 0.4;
            }
          }
        }
      }
    }
    .recommended-geo-links {
      margin-bottom: 24px;
      .recommended-geo-link {
        @extend %flex-row-spread;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        .data {
          width: calc(100% - 60px);
          overflow: hidden;

          .countries {
            font-weight: bold;
            font-size: 16px;
            color: $darkest;
            margin-top: 4px;
          }
          .link {
            font-weight: bold;
            font-size: 12px;
            color: $medium;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .payout {
            font-weight: bold;
            font-size: 10px;
            color: $medium;
          }
        }

        .actions {
          @extend %flex-row-middle;
          justify-content: flex-end;
          width: 40px;

          .action-icn {
            font-size: 15px;
            cursor: pointer;
            color: $medium;

            &.copy {
              font-size: 14px;
            }

            &:not(:last-child) {
              margin-right: 8px;
            }

            &:hover {
              opacity: 0.4;
            }
          }
        }
      }
    }
  }
}
.geo-link-upsell {
  @extend %flex-center;
  padding: 24px;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-weight: bold;
  color: $dark;
}
