@import '../../../../styles/shared.scss';

.sibling {
  @extend %flex-row-middle;
  position: relative;
  z-index: $zindex-modal-sortable-item;

  border: $border-dark;
  border-radius: 24px;
  position: relative;
  padding: 4px 8px 4px 12px;
  align-self: flex-start;
  font-size: 12px;

  &.synced {
    background-color: $offwhite;
    border: $border-lighter;
  }

  &.unavailable {
    //strikethrough
    text-decoration: line-through;
    color: $light;
  }

  &.hidden {
    opacity: 0.4;
  }

  &.disconnected {
    border-color: $error;
    color: $error;
    background-color: rgba(255, 0, 0, 0.1);

    svg {
      color: $error;
    }
  }

  .display {
    @extend %btn-basic;
    @extend %flex-row-middle;
    gap: 2px;
    white-space: pre-line;

    svg {
      font-size: 10px;
    }
  }

  &.is-size .display {
    white-space: nowrap;
  }

  .actions {
    @extend %flex-row;
    margin-left: 6px;
    gap: 10px;

    .action {
      @extend %btn-basic;
      @extend %flex-center;
      gap: 5px;
    }
  }
}

.lookbook-product-modal-siblings-container {
  z-index: 10;

  .back-button {
    @extend %label;
    @extend %btn-basic;
    align-items: center;
    font-size: 0.7rem;
    margin-bottom: 20px;

    svg {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }

  .add-new-sibling-button {
    @extend %btn-basic;
    @extend %label;
    font-size: 0.7rem;
    margin-bottom: 20px;
    cursor: pointer;
    color: $lighter;
  }

  .table {
    border: $border-lightest;
    border-radius: 10px;
    overflow: hidden;

    .row {
      @extend %flex-row-middle;
      padding: 12px 0;
      border-bottom: $border-lightest;

      &.header {
        font-weight: 600;
        color: $white;
        padding: 12px 0;
        background-color: $darkest;
        border-bottom: $border-dark;
        font-size: 12px;
      }

      &:last-child {
        border-bottom: none;
      }

      .cell {
        @extend %flex-row;
        padding: 0 8px;

        &.sm {
          width: 80px; // Images
          min-width: 80px;
        }

        &.md {
          width: 130px;
          min-width: 130px;
        }

        &.lg {
          flex: 1;
        }

        .lookbook-product-modal-sortable-sizes {
          @extend %flex-row;
          flex-wrap: wrap;
          gap: 8px;
        }

        &.loading-variant {
          background-color: $offwhite;
          width: 100px;
          border-radius: 24px;
          height: 24px;

          .loader {
            transform: translateY(-3px);
          }
        }

        &.loading-size {
          background-color: $offwhite;
          align-self: flex-start;
          height: 24px;
          width: 48px;
          border-radius: 24px;

          .loader {
            transform: translateY(-2px);
          }
        }

        .sibling-price {
          input {
            @extend %input-inline;
          }
        }

        .sibling-url {
          @extend %btn-basic;
          @extend %flex-row-middle;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 600;

          a {
            color: $darkest;
            margin-left: 4px;

            svg {
              font-size: 10px;
            }
          }
        }
      }

      form.new-variation-form {
        input {
          border: $border-lightest;
          border-radius: 24px;
          padding: 4px 8px 4px 12px;
          width: 100px;
          outline: none;
          font-size: 12px;

          &:focus {
            border: $border-dark;
          }

          &::placeholder {
            color: $light;
            text-transform: uppercase;
            font-size: 10px;
          }
        }
      }

      form.new-size-form {
        @extend %flex-row;
        gap: 5px;
        align-items: center;

        input {
          border: $border-lightest;
          border-radius: 24px;
          padding: 4px 8px 4px 12px;
          width: 100px;
          outline: none;
          font-size: 12px;
          height: max-content;

          &:focus {
            border: $border-dark;
          }

          &::placeholder {
            color: $light;
            text-transform: uppercase;
            font-size: 10px;
          }
        }

        .standard-sizing-container {
          @extend %flex-row;
          gap: 8px;
          margin-left: 8px;
          align-items: center;

          svg {
            @extend %btn-basic;
            font-size: 12px;
            color: $light;
          }

          .standard-sizing-options {
            @extend %flex-row;
            gap: 8px;
            flex-wrap: wrap;
          }
        }

        button {
          @extend %flex-row;
          @extend %btn-basic;
          @extend %label;
          align-items: center;
          outline: none;
          border: none;
          font-size: 10px;
          background: transparent;
          color: $light;
          font-weight: 400;
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          svg {
            font-size: 8px;
            margin-right: 4px;
          }
        }
      }

      .sibling-image {
        @extend %btn-basic;
        position: relative;

        img {
          height: 64px;
          width: 64px;
          object-fit: cover;
          border-radius: 4px;
        }

        .default-badge-container {
          @extend %flex-center;
          position: absolute;
          bottom: -8px;
          height: 16px;
          left: 0;
          right: 0;

          .default-badge {
            font-size: 10px;
            text-transform: uppercase;
            background-color: $primary;
            padding: 2px 4px;
            border-radius: 8px;
            margin-bottom: 4px;
            color: $white;
          }
        }
      }
    }
  }

  .footer-actions {
    @extend %flex-row;
    justify-content: flex-end;
    margin-top: 24px;

    .footer-action {
      &.primary {
        @extend %btn-primary-new;
        padding: 8px 16px;
      }
    }
  }
}
