@import '../../../styles/shared.scss';

.request-results-outer {
  .request-results-inner {
    @extend %flex-col;
    gap: 100px;
    padding-bottom: 50px;

    .empty-results-message {
      text-align: center;
      font-size: 16px;
      color: $light;
      padding-top: 20px;

      .text {
        @extend %flex-col;
        gap: 1rem;

        .clear-filters-button {
          @extend %btn-basic;
          @extend %label;
          width: max-content;
          padding: 12px 24px;
          font-size: 13px;
          color: white;
          background-color: black;
          border: none;
          outline: none;
          border-radius: 6px;
          margin: 0 auto;
        }
      }
    }

  }
}
