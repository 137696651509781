@import '../../styles/shared.scss';

.contract-outer-container {
  @extend %flex-col;
  width: 100%;
  padding: 48px 24px 80px;
  position: relative;
  font-family: $font-body;
  background: $offwhite;

  @include md-down {
    padding: 32px 16px 80px;
  }

  .contract-inner-container {
    @extend %flex-col;
    min-height: 800px;
    width: 100%;
    max-width: 800px;
    margin: 120px auto;
    transform: translateY(-120px);

    .loading-contract {
      margin-top: 10vh;
    }

    .alert-message {
      @extend %flex-row-middle-spread;
      padding: 12px 16px;
      background: $white;
      border: $border-dark;
      border-radius: 8px;
      margin-top: 36px;
      font-size: 12px;
      font-weight: bold;
      .alert-actions {
        @extend %flex-row;
        .alert-action {
          @extend %btn-dark-new;
          font-size: 12px;
          text-transform: uppercase;

          &:not(:last-child) {
            margin-right: 8px;
          }

          &.basic {
            @extend %btn-text-new;
          }
        }
      }
    }

    .footer-actions {
      margin-top: 24px;
      @extend %flex-row;
      justify-content: flex-end;

      @include md-down {
        flex-direction: column;
        .footer-action {
          text-align: center;
          order: 2;
        }

        .done {
          order: 1;
        }
      }

      .footer-action {
        @extend %btn-new;
        padding: 8px 16px;
        margin-left: 8px;
        font-size: 14px;
      }
      .chat {
        @extend %link;
      }
      .profile {
        @extend %btn-text-new;
      }
      .back {
        @extend %btn-offwhite-new;
      }
      .reject {
        @extend %btn-text-new;
      }
      .next {
        @extend %btn-dark-new;
      }
      .done {
        @extend %btn-darkest-new;
      }
    }

    .card {
      background: white;
      border-radius: 8px;
      margin-top: 24px;
      padding-bottom: 32px;

      @include md-down {
        padding-bottom: 16px;
        margin-top: 16px;
      }

      .card-header-container {
        background: $white;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        color: $darker;
        position: relative;
        padding: 32px 32px 0;

        @include md-down {
          padding: 16px 16px 16px;
          flex-wrap: wrap;
        }

        &.dark {
          @extend %flex-row-middle-spread;
          background: $darker;
          color: white;
          padding: 24px 32px 24px;
          margin-bottom: 12px;

          @include md-down {
            padding: 16px 16px 16px;
            margin-bottom: 8px;
            flex-wrap: wrap;
          }

          .card-subheader {
            color: white;
          }
        }

        .card-header {
          font-weight: bold;
          font-size: 16px;
          text-transform: uppercase;
        }
        .card-subheader {
          font-size: 12px;
          margin-top: -1px;
          color: $dark;

          .link {
            @extend %btn-basic;
            @extend %link;
          }
        }
      }
      .card-body-container {
        padding: 12px 32px 0;

        @include md-down {
          padding: 12px 16px 0;
        }
      }

      .card-footer-container {
        padding: 16px 32px 0;

        @include md-down {
          padding: 12px 16px 0;
        }
      }
    }
  }
}
