@import '../../styles/shared.scss';

.list-header-container {
  @extend %flex-row-spread;
  // align-items: flex-end;
  margin-top: 24px;
  position: relative;
  color: white;

  @include md-down {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .title-container {
    @extend %flex-col;
    color: white;
    flex: 1;

    .back-btn {
      @extend %btn-basic;
      @extend %label;
      font-size: 10px;
      margin-bottom: 24px;

      svg {
        margin-right: 8px;
      }
    }
    .title {
      @extend %header-fancy-lg;
    }

    .description {
      @extend %paragraph-md;
    }

    input,
    textarea {
      @extend %input-inline;
      background-color: transparent;
      color: white;
      resize: none;
    }
  }
}
