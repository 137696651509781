@import '../../../../styles/shared.scss';

.lookbook-product-modal-catalog-outer {
  height: 100%;

  .lookbook-product-modal-catalog-inner {
    position: relative;
    height: 100%;
    @extend %flex-col;
    justify-content: space-between;

    .empty-results-message {
      text-align: center;
      font-size: 1.2rem;
      color: $light;
      padding: 48px 24px;
      background-color: $offwhite;
      margin-top: 20px;
      border-radius: 12px;

      .text {
        @extend %label;
        @extend %flex-col;
        gap: 1rem;
        font-size: 14px;

        .clear-filters-button {
          @extend %btn-basic;
          @extend %label;
          width: max-content;
          padding: 10px 16px;
          color: white;
          background-color: black;
          border: none;
          outline: none;
          border-radius: 6px;
          margin: 0 auto;
        }
      }
    }

    .filters {
      display: flex;
      align-items: center;
      gap: 30px;
      height: 40px;
      width: 100%;

      .search-container {
        border-radius: 500px;
        border: $border-lightest;
        display: flex;
        align-items: center;
        gap: 15px;
        height: 40px;
        flex: 1;
        overflow: hidden;

        svg {
          height: 12px;
          width: 12px;
          margin-left: 20px;
        }

        input {
          font-family: $font-body-new;
          border: none;
          outline: none;
          width: 100%;
        }
      }

      .search-filter-spacer {
        @extend %label;
        font-weight: 600;
        font-size: 0.9rem;
      }

      .filter-container {
        position: relative;

        .filter-label {
          @extend %label;
          height: 100%;
          padding: 10px 15px;
          border: $border-lightest;
          border-radius: 5px;
          color: $darkest;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 600;
          font-size: 0.9rem;
          position: relative;

          .filter-label-spacer {
            background-color: $light;
            width: 1px;
            height: 0.9rem;
          }

          svg {
            height: 12px;
            width: 12px;
          }

          &:hover {
            border: $border-dark;
          }
        }

        .filter-dropdown {
          @extend %flex-col;
          gap: 10px;
          position: absolute;
          top: calc(100% + 10px);
          left: 50%;
          transform: translateX(-50%);
          background-color: $prettymuchwhite;
          border: $border-lightest;
          padding: 10px;
          border-radius: 5px;
          z-index: 5;

          .filter-option {
            @extend %label;
            background-color: white;
            padding: 10px 15px;
            border-radius: 5px;
            font-size: 11px;
            cursor: pointer;
            border: 1px solid transparent;

            &.active {
              border: $border-primary;
            }
          }
        }
      }
    }

    .product-buttons-footer {
      position: sticky;
      padding: 20px;
      padding-bottom: calc(24px + 10px);
      width: calc(100% + 40px);
      transform: translate(-20px, 24px);
      bottom: 0;
      z-index: 10;
      display: flex;
      justify-content: space-between;
      background-color: white;
      align-items: center;

      border-top: border-lightest;

      .create-button {
        @extend %btn-basic;
        @extend %label;
        padding: 14px 16px;
        background-color: black;
        color: white;
        outline: none;
        border: none;
      }

      .save-container {
        display: flex;
        align-items: center;
        gap: 20px;

        .selected-products-count {
          color: $medium;
          font-size: 0.9rem;
        }

        .save-button {
          @extend %btn-basic;
          @extend %label;
          padding: 12px 18px;
          background-color: $primary;
          color: white;
          border-radius: 500px;
          outline: none;
          border: none;
        }
      }
    }

    .product-modal-replace-container {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      color: $medium;
      font-style: italic;

      .product-replace-name {
        color: $darkest;
        font-weight: bold;
        font-style: normal;
      }
    }
  }
}
