@import '../../styles/shared.scss';

.catalog-filters-fade-container {
  position: fixed;
  width: 100%;
  height: 120vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
  z-index: 2;

  &.active {
    opacity: 1;
    pointer-events: auto;
    overflow: hidden;
  }
}

.catalog-filters-container {
  @extend %flex-col;
  position: absolute;
  background: $white;
  box-shadow: $shadow-dark;
  list-style: none;
  overflow: hidden;
  overflow: auto;
  top: calc(100% - 10px);
  right: 0;
  pointer-events: none;
  min-width: 240px;
  border-radius: 10px;
  display: none;
  z-index: 3;
  padding: 16px 12px;

  @include md-down {
    left: 0;
    right: unset;
  }

  &.active {
    pointer-events: auto;
    display: flex;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;

    svg {
      color: $light;
      cursor: pointer;
    }
  }

  .catalog-filters-search {
    @extend %flex-row;
    align-items: center;
    height: 35px;
    font-size: 12px;
    border: $border-lightest;
    border-radius: 20px;
    padding: 2px 8px;

    svg {
      margin: 0 4px;
    }

    input {
      height: 100%;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }

  .filter-section {
    margin-top: 16px;
    font-size: 14px;

    .label {
      color: $darkest;
    }

    .filter-options {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 8px;

      .filter-option {
        @extend %flex-center;
        font-family: $font-body-new;
        letter-spacing: 0.08em;
        white-space: nowrap;
        font-weight: 500;
        border-radius: 8px;
        border: $border-lightest;
        padding: 8px 12px;
        font-size: 10px;
        cursor: pointer;

        span {
          margin-left: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $lightest;
          color: $darkest;
          font-size: 8px;
          font-weight: bold;
          padding: 4px 8px;
          border-radius: 14px;
          min-width: 16px;
          height: 16px;
          white-space: nowrap;
          text-align: center;
        }

        &.active {
          border: $border-darkest;
          background-color: $lightest;

          span {
            background: $darkest;
            color: $white;
          }
        }
      }
    }
  }

  .no-results {
    font-size: 14px;
    color: $medium;
    width: 100%;
  }
}
