@import '../../styles/shared.scss';

.latest-section {
  .latest-section-header-container {
    @extend %flex-row-spread;
    margin-bottom: 24px;

    .title-container {
      .badges {
        @extend %label;
        font-size: 12px;
      }
      .title {
        font-family: $font-header;
        font-size: 32px;
        font-weight: bold;
      }
      .subtitle {
        font-size: 14px;
        color: $dark;
      }
    }

    .paging-button {
      @extend %btn-basic;
      @extend %label;
      font-size: 12px;
      color: $darker;

      svg {
        margin-left: 4px;
      }
    }
  }

  .item-indicators {
    @extend %flex-row-middle;
    position: absolute;
    top: 12px;
    right: 12px;
    gap: 4px;

    .indicator {
      @extend %label;
      background-color: $darkest;
      color: white;
      border-radius: 4px;
      padding: 2px 12px;
      font-size: 12px;
      font-weight: bold;

      &.white {
        background-color: white;
        color: $dark;
      }

      &.primary {
        background-color: $primary;
        color: $white;
      }
      &.yield {
        background-color: $yield;
        color: $darkest;
      }
    }
  }
}
