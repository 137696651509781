@import '../../styles/shared.scss';

.all-products-outer-container {
  .all-products-inner-container {
    @extend %flex-col;
    max-width: 880px;
    width: 80vw;
    margin: 48px auto 0;

    .product {
      @extend %link;
      padding-bottom: 4px;
      font-size: 12px;
    }
  }
}
