@import '../../../styles/shared.scss';

.opportunity-setup-guide-step {
  min-height: 240px;

  .step-title {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 12px;
  }

  .questions {
    @extend %flex-col;
    gap: 48px;

    .question {
      .question-display-container {
        margin-bottom: 30px;

        &.small {
          margin-bottom: 20px;
        }

        .question-display {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 4px;

          &.small {
            font-size: 16px;
          }
        }
        .question-subdisplay {
          font-size: 14px;
          color: $medium;

          &.small {
            font-size: 12px;
          }
        }
      }

      .options {
        &.checkboxes {
          @extend %flex-col;
          gap: 24px;

          &.small {
            gap: 12px;
          }

          .option {
            @extend %flex-row;
            gap: 8px;
            cursor: default;

            &:hover:not(.selected) {
              cursor: pointer;

              .checkbox-container {
                .checkbox-inner {
                  background-color: $primaryLight;
                  opacity: 0.1;
                  transform: scale(1);
                }
              }
            }

            .checkbox-container {
              margin-top: 2px;

              .checkbox {
                @extend %flex-center;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: $border-lighter;

                &.small {
                  width: 14px;
                  height: 14px;
                }

                &.selected {
                  border: $border-darkest;

                  .checkbox-inner {
                    transform: scale(1);
                    opacity: 1;
                  }
                }

                .checkbox-inner {
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  background-color: $primary;
                  transform: scale(0.8);
                  opacity: 0;
                  transition: all 0.2s;

                  &.small {
                    width: 10.5px;
                    height: 10.5px;
                  }
                }
              }
            }

            .option-container {
              @extend %flex-col;
              gap: 10px;

              &.small {
                gap: 4px;
              }

              .display-container {
                @extend %flex-row-middle;
                gap: 8px;

                .display {
                  font-size: 16px;
                  color: $darkest;
                  transition: opacity 0.4s;
                  opacity: 0.5;

                  &.selected {
                    font-weight: bold;
                    opacity: 1;
                  }

                  &.small {
                    font-size: 14px;
                  }
                }
                .recommended-badge {
                  @extend %label;
                  font-size: 10px;
                  color: $dark;
                  margin-top: 2px;
                  opacity: 0.5;

                  &.small {
                    font-size: 8px;
                  }

                  &.selected {
                    opacity: 1;
                  }
                }
              }

              .description {
                font-size: 12px;
                transition: opacity 0.4s;
                color: $medium;
                opacity: 0.5;

                &.small {
                  font-size: 10px;
                }

                &.selected {
                  opacity: 1;
                }
              }
            }
          }
        }

        &.textareas {
          @extend %flex-col;
          gap: 40px;

          .textarea-container {
            @extend %flex-col;

            .label-container {
              @extend %flex-col;
              margin-bottom: 8px;
              // gap: 4px;

              .label {
                font-size: 16px;
                color: $darkest;
                font-weight: bold;
              }
              .sublabel {
                font-size: 12px;
                color: $medium;
              }
            }

            .input-wrapper {
              position: relative;

              textarea {
                @extend %input-shared;
                font-size: 14px;
                width: 100%;
                resize: none;
              }

              .char-count {
                position: absolute;
                right: 8px;
                bottom: 8px;
                font-size: 10px;
                font-weight: 500;
                color: $dark;

                &.warning {
                  color: $error;
                }
              }
            }

            .warning-alert {
              @extend %flex-row-middle;
              margin-top: 12px;
              background-color: $yield;
              border: 1px solid $yield;
              border-radius: 4px;
              padding: 8px 16px;
              gap: 12px;

              .text {
                font-size: 11px;
                color: $darkest;
              }
            }
          }
        }
      }

      .date-select {
        @extend %flex-row;
        gap: 8px;

        .date-display {
          @extend %btn-basic;
          padding: 16px;
          border: $border-lightest;
          border-radius: 10px;
          font-size: 36px;
          font-family: $font-header;
          font-weight: 500;
        }

        .date-select-btn {
          @extend %btn-basic;
          padding: 16px;
          border: $border-lighter;
          border-radius: 10px;
          font-size: 36px;
          background-color: $offwhite;
          font-family: $font-header;
          font-weight: 500;
        }
      }
    }
  }
}
