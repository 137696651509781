@import '../../../styles/shared.scss';

.header-budget-panel-outer-container {
  position: absolute;
  top: 100%;
  right: 0%;
  color: $darkest;
  pointer-events: none;

  &.visible {
    pointer-events: auto;
  }

  .fade-background {
    position: fixed;
    top: -100vh;
    left: -100vw;
    width: 400vw; // Ensure even if navbar shrinks with transform, the background covers the viewport
    height: 400vh;
    background: $darkest;
    z-index: $zindex-outmost-modal;
    transition: opacity 0.4s ease-in-out;
    pointer-events: none;
    opacity: 0;

    &.visible {
      opacity: 0.6;
    }
  }

  .header-budget-panel-inner-container {
    @extend %flex-col;
    background-color: white;
    z-index: $zindex-toast;
    position: relative;
    border-radius: 10px;
    gap: 24px;
    padding: 36px;
    min-width: 440px;
    opacity: 0.1;
    transition: opacity 0.3s ease-in-out;
    visibility: hidden;
    cursor: default;

    &:hover {
      .close-icon,
      .view-details-link {
        opacity: 1;
      }
    }

    .close-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
      font-size: 20px;
      color: $darkest;
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
    }

    .view-details-link {
      @extend %flex-row-middle;
      position: absolute;
      bottom: 12px;
      right: 12px;
      cursor: pointer;
      gap: 4px;
      font-size: 10px;
      font-weight: normal;
      text-decoration: none;
      opacity: 0;
      color: $lighter;
      transition: color 0.15s ease-in-out;

      &:hover {
        opacity: 1;
        color: $dark;
      }

      svg {
        margin-top: -1px;
      }
    }

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    .section {
      opacity: 0;
      transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
      transform: scale(0.9);

      &.visible {
        opacity: 1;
        transform: scale(1);
      }
    }

    .header-section {
      @extend %flex-row-middle-spread;
      gap: 48px;

      .budget-overall {
        .label {
          font-size: 14px;
          color: $darkest;
          margin-bottom: 4px;
        }

        .amount {
          font-size: 28px;
          font-weight: bold;
          color: $darkest;
          white-space: nowrap;

          .of-total {
            font-size: 14px;
            color: $medium;
            font-weight: normal;
            margin-left: 4px;
          }
        }
      }

      .actions {
        @extend %flex-row-middle;
        gap: 8px;

        .action {
          &.primary {
            @extend %btn-darkest-new;
            font-size: 10px;
            padding: 8px 30px;
          }

          &.secondary {
            @extend %btn-outline-new;
          }

          &.negative {
            @extend %btn-error-new;
            font-size: 10px;
            padding: 8px 30px;
          }
        }
      }
    }

    .meter-section {
      width: 100%;
      height: 20px;
      border-radius: 12px;
      background-color: $primary;
      position: relative;
      overflow: hidden;

      .meter {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        min-width: 10px !important;

        &.waiting {
          background-color: $primary;
        }
        &.outstanding {
          background-color: $primaryLighter;
        }
        &.available {
          background-color: $lightest;
        }
        &.overdrafted {
          background-color: $error;
        }
      }
    }

    .budget-sections {
      @extend %flex-col;
      gap: 12px;

      .budget-section {
        @extend %flex-row;
        gap: 12px;

        .color-key {
          @extend %flex-center;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-top: 3px;

          &.waiting {
            background-color: $primary;
          }
          &.outstanding {
            background-color: $primaryLighter;
          }
          &.available {
            background-color: $lightest;
          }

          &.negative {
            background-color: $white;
          }

          svg {
            color: $error;
            font-size: 24px;
          }
        }

        .data {
          @extend %flex-col;
          gap: 4px;
          flex: 2;

          .display {
            font-size: 14px;
            color: $darkest;
            font-weight: 500;

            &.negative {
              color: $error;
            }
          }
          .subdisplay {
            font-size: 10px;
            color: $medium;

            &.negative {
              color: $error;
              opacity: 0.8;
              font-style: italic;
            }
          }
        }

        .amount {
          flex: 1;
          font-size: 16px;
          font-weight: 500;
          text-align: right;

          &.negative {
            color: $error;
          }
        }
      }
    }
  }
}
