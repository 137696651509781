@import '../../../../styles/shared.scss';

.lookbook-product-modal-outer {
  .lookbook-product-modal-inner {
    max-width: 1200px;
    height: 80vh;
    top: 10vh;

    @include lg-down {
      max-width: 900px;
    }

    .lookbook-product-modal {
      flex: 1;
      padding: 20px 20px 0 20px;

      .product-modal-title-container {
        margin-bottom: 14px;
      }

      .product-modal-title {
        font-family: $font-header;
        font-weight: bold;
        letter-spacing: 0.7px;
        // font-size: 28px;
        font-size: 2rem;
        line-height: 36px;

        .product-modal-subtitle {
          font-family: $font-body-new;
          font-weight: normal;
          letter-spacing: normal;
          // font-size: 28px;
          line-height: normal;
          font-size: 0.8rem;
          color: $medium;
        }
      }

      .product-modal-footer {
        @extend %flex-row;
        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        background-color: white;
        padding: 20px 0;
        transform: translateY(24px);

        .product-modal-footer-button {
          @extend %btn-basic;
          @extend %label;
          background-color: black;
          color: white;
          font-size: 0.9rem;
          padding: 14px 16px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          outline: none;
          border: none;
          display: grid;
          place-items: center;
        }
      }
    }
  }
}
