@import '../../styles/shared.scss';

.opportunity-header-container {
  position: relative;

  &.header-visible {
    @include md-down {
      padding: 80px 24px 0;
    }
  }

  &.header-hidden {
    @include md-down {
      .back-to-opportunities {
        color: white;
      }
    }
  }

  .back-to-opportunities {
    @extend %btn-basic;
    @extend %flex-row-middle;
    @extend %label;
    position: absolute;
    left: 0;
    bottom: calc(100% + 24px);
    font-size: 12px;
    gap: 8px;
    z-index: 2;

    @include md-down {
      bottom: calc(100% - 48px);
      left: 24px;
    }
  }

  .title-and-actions-container {
    @extend %flex-row-spread;
    align-items: flex-start;
    gap: 36px;
    margin-bottom: 24px;

    @include md-down {
      flex-direction: column;
      gap: 16px;
    }
  }
}
