@import '../../styles/shared.scss';

.video-player-container {
  width: 100%;
  overflow: hidden;
  background: $prettymuchwhite;
  position: relative;

  @include md {
    border-radius: 6px;
  }

  &.iframe {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    .video-cover {
      opacity: 0;
    }
  }

  .video-cover {
    @extend %flex-center-vertical;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;

    &.playing {
      opacity: 0;
    }

    .video-cover-header {
      @extend %label;
      color: white;
      font-size: 18px;
      margin-bottom: 8px;
    }

    .video-cover-subheader {
      color: white;
      font-size: 16px;
      border-radius: 4px;
      max-width: 360px;
      margin: 0 auto;
      text-align: center;
    }
  }
}
