@import '../../styles/shared.scss';

.discover-lists-container {
  .discover-lists {
    @extend %flex-col;
    gap: 12px;
  }
  .empty-discover-lists-alert {
    @extend %flex-col;
    padding: 48px;
    background-color: $darker;
    padding: 48px;
    border-radius: 10px;
    margin-bottom: 8px;
    color: white;

    .header {
      @extend %label;
      margin-bottom: 4px;
    }
    .subheader {
      @extend %paragraph-sm;
    }
  }
}
