@import '../../../styles/shared.scss';

.collection-social-links-outer-container {
  @extend %flex-row;
  font-family: $font-body;
  text-transform: none;
  width: 100%;

  & > div {
    flex: 1;
  }

  .collection-social-links-icons-container {
    @extend %flex-row-middle;

    .collection-more-label {
      font-size: 12px;
      padding-right: 6px;
      line-height: 1;
      text-transform: uppercase;
    }

    .collection-social-links-icon {
      @extend %flex-row-middle;
      font-size: 12px;
      // color: $medium;
      cursor: pointer;

      img {
        height: 14px;
        object-fit: contain;

        &.has-youtube {
          height: 20px;
        }
      }

      svg {
        font-size: 16px;
        color: $medium;
      }

      img,
      svg {
        margin-right: 8px;
      }

      &.large {
        img {
          height: 20px;
        }

        svg {
          font-size: 20px;
        }
      }

      &.add {
        // font-style: italic;
        margin-top: -1px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .collection-social-links-edit-panel-container {
    width: 100%;

    .collection-social-links-edit-panel {
      @extend %flex-col;
      align-self: flex-start;
      width: 100%;
      min-width: 320px;

      .current-social-link {
        @extend %flex-row-middle-spread;
        margin-bottom: 8px;
        font-size: 14px;
        color: $dark;
        font-weight: bold;
        position: relative;
        overflow: hidden;
        white-space: nowrap;

        .link-container {
          @extend %flex-row-middle;
          flex: 1;

          .link-example {
            height: 14px;
            width: 22px;
            object-fit: contain;
            margin-right: 4px;
            color: $medium;
          }

          form {
            width: 100%;

            input {
              @extend %input-inline;
              font-size: 16px;
              color: $dark;
              width: 100%;
            }
          }

          .current-social-link-url {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 432px;
          }
        }

        .current-social-link-remove-icon,
        .current-social-link-add-icon {
          @extend %flex-center;
          font-size: 14px;
          margin-left: 4px;
          color: $lighter;
          cursor: pointer;
          position: absolute;
          background: white;
          padding-left: 8px;
          right: 0;

          &:hover {
            opacity: 0.8;
          }
        }

        .current-social-link-add-icon {
          color: $success;
        }
      }

      .action-btns {
        @extend %flex-row-middle;
        align-self: flex-start;
        margin-top: 4px;

        .btn {
          @extend %btn-outline;
          padding: 2px 8px;
          font-size: 14px;

          &.disabled {
            opacity: 0.2;
          }

          &.done-btn {
            margin-right: 6px;
          }
          &.add-btn {
            @extend %btn-dark;
          }
        }
      }
    }
  }
}
