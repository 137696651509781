@import '../../../styles/shared.scss';

.team-member-modal-outer {
  .team-member-modal-inner {
    .team-member-modal-content {
      padding: 20px;
      position: relative;
      font-family: $font-body-new;

      .team-member-modal-header {
        font-family: $font-header;
        font-size: 2.2rem;
        font-weight: 600;
      }

      .team-member-modal-body {
        @extend %flex-row;
        gap: 30px;
        margin-top: 20px;

        .team-member-modal-image-container {
          width: 350px;
          height: 350px;
          aspect-ratio: 1;
          border-radius: 12px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .team-member-modal-image-placeholder {
            @extend %flex-col;
            width: 100%;
            height: 100%;
            padding: 30px;
            background-color: $lightest;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.9rem;
            color: $dark;

            svg {
              font-size: 3.5rem;
              color: $medium;
            }
          }
        }

        .team-member-modal-form {
          flex: 1;
          @extend %flex-col;
          gap: 15px;

          .team-member-modal-input-label {
            @extend %label;
            font-size: 0.9rem;
            font-weight: 600;
          }

          input {
            border: none;
            outline: none;
            font-family: $font-body-new;
            width: 100%;
            font-size: 0.9rem;
            padding: 10px 0;
            resize: none;
            display: block;
            margin: 0;
            width: 100%;

            &::placeholder {
              color: $lighter;
            }

            &#title {
              font-family: $font-header;
              font-size: 2rem;
              font-weight: 600;
            }
          }
        }
      }

      .disclaimer {
        margin-top: 10px;
        font-size: 0.8rem;
        color: $light;
        max-width: 350px;
      }
    }

    .team-member-modal-footer {
      @extend %flex-row;
      justify-content: flex-end;
      gap: 10px;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: $prettymuchwhite;
      padding: 20px;
      transform: translateX(-24px);
      z-index: 2;

      .team-member-modal-button {
        @extend %btn-basic;
        @extend %label;
        font-size: 0.9rem;
        padding: 12px 16px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        outline: none;
        border: none;
        display: grid;
        place-items: center;

        &.save {
          background: black;
          color: white;
        }

        &.cancel {
          background: $lightest;
          color: $dark;
        }
      }
    }
  }
}
