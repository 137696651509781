@import '../../../styles/shared.scss';

.opportunity-planner-all-talent {
  .talent-results {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    gap: 24px 8px;
    transition: opacity 0.3s;

    @include xl-down {
      grid-template-columns: repeat(3, 33.33%);
    }

    @include md-down {
      grid-template-columns: repeat(3, 33.33%);
    }

    &.fetching-first-page {
      opacity: 0.3;
    }

    .talent-result {
      min-height: 240px;
      padding: 12px;
      border-radius: 20px;

      &.active {
        background-color: $primaryLightest;
      }

      .image-container {
        width: 100%;
        aspect-ratio: 1;
        overflow: hidden;
        border-radius: 20px;

        img {
          @extend %animation-fade;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .image.empty {
          @extend %flex-center;
          width: 100%;
          height: 100%;
          color: white;
          font-size: 64px;
          font-weight: bold;
          background-color: $darkest;
          font-family: $font-header;
        }
      }

      .data-container {
        @extend %flex-col;
        gap: 12px;
        margin-top: 12px;
        margin-bottom: 16px;

        .user-data {
          .name {
            @extend %btn-basic;
            font-size: 18px;
            font-weight: bold;
            color: $darkest;
            text-align: center;
          }

          .tier {
            font-size: 12px;
            color: $dark;
            text-align: center;
          }
        }

        .cost-and-actions {
          @extend %flex-center;
          gap: 24px;

          .cost-container {
            @extend %flex-col-center;
            cursor: pointer;

            .cost {
              font-size: 18px;
              font-weight: 600;
              color: $primary;
            }
            .label {
              font-size: 10px;
              color: $dark;
              position: relative;

              .edit-icon {
                color: $lighter;
                position: absolute;
                right: calc(100% + 4px);
                top: 2px;
                font-size: 8px;

                &.active {
                  color: $darkest;
                }
              }
            }
          }

          .action {
            @extend %btn-darkest-new;
            padding: 10px 30px;
            position: relative;
            font-size: 12px;

            &.add {
            }

            &.remove {
              background-color: $primary;
              border-color: $primary;
              padding: 10px 20px;
            }

            svg {
              margin-right: 8px;
              font-size: 14px;
            }

            &.loading {
              color: transparent;
              pointer-events: none;

              .loader {
                @extend %flex-center;
                position: absolute;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
