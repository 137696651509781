@import '../../styles/shared.scss';

.brand-apply-header-outer-container {
  max-width: 1240px;
  width: calc(100% - 48px);
  margin: 48px auto;

  @include md {
    width: calc(100% - 96px);
  }

  .brand-apply-header-inner-container {
    @extend %flex-row-middle;

    .panel {
      flex: 1;

      &.left {
        @extend %flex-col;
        margin: 64px 0;
        color: $darker;

        @include md {
          margin: 0;
        }

        h1 {
          margin: 0 0 12px;
          font-size: 44px;
          line-height: 1.1;
          font-weight: 700;
          letter-spacing: -0.57px;
        }

        h2 {
          margin: 0;
          font-weight: normal;
          font-size: 22px;
          letter-spacing: -0.1px;
        }

        .apply-btn {
          @extend %btn-darkest;
          padding: 12px 64px;
          margin-top: 24px;
          align-self: flex-start;
          text-transform: uppercase;
        }
      }

      &.right {
        margin-left: 48px;
        display: none;

        @include md {
          display: block;
        }

        .creator-grid {
          @extend %flex-row;
          flex-wrap: wrap;

          .creator-container {
            // background: white;
            min-width: 33.3%;
            max-width: 33.3%;
            height: 200px;
            transform: scale(1);
            filter: grayscale(1);
            animation: growImg 36s infinite;
            padding: 12px;

            img {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              object-fit: cover;
              border: $border-white;
              border-radius: 4px;

              &:last-child {
                animation: fade 36s infinite;
              }
            }

            &:nth-child(1),
            &:nth-child(1) > img:last-child {
              animation-delay: 2s;
            }
            &:nth-child(2),
            &:nth-child(2) > img:last-child {
              animation-delay: 12s;
            }
            &:nth-child(3),
            &:nth-child(3) > img:last-child {
              animation-delay: 8s;
            }
            &:nth-child(4),
            &:nth-child(4) > img:last-child {
              animation-delay: 6s;
            }
            &:nth-child(5),
            &:nth-child(5) > img:last-child {
              animation-delay: 16s;
            }
            &:nth-child(6),
            &:nth-child(6) > img:last-child {
              animation-delay: 4s;
            }
            &:nth-child(7),
            &:nth-child(7) > img:last-child {
              animation-delay: 14s;
            }
            &:nth-child(8) {
              // animation-delay: 3s;
            }
            &:nth-child(9),
            &:nth-child(9) > img:last-child {
              animation-delay: 10s;
            }
          }
        }
      }
    }
  }
}

@keyframes growImg {
  0% {
  }
  5% {
    transform: scale(1);
    filter: grayscale(1);
    z-index: 1;
  }
  6% {
    z-index: 2;
  }
  8% {
    transform: scale(1.2);
    filter: grayscale(0);
  }
  10% {
    transform: scale(1.2);
    filter: grayscale(0);
  }
  12% {
    z-index: 2;
  }
  13% {
    transform: scale(1);
    filter: grayscale(1);
    z-index: 1;
  }
  25% {
  }
  30% {
  }
  35% {
  }
  40% {
  }
  45% {
  }
  50% {
  }
  55% {
    transform: scale(1);
    filter: grayscale(1);
    z-index: 1;
  }
  56% {
    z-index: 2;
  }
  58% {
    transform: scale(1.2);
    filter: grayscale(0);
  }
  60% {
    transform: scale(1.2);
    filter: grayscale(0);
  }
  62% {
    z-index: 2;
  }
  63% {
    transform: scale(1);
    filter: grayscale(1);
    z-index: 1;
  }
  75% {
  }
  80% {
  }
  85% {
  }
  90% {
  }
  95% {
  }
  100% {
  }
}

@keyframes fade {
  0% {
  }
  5% {
  }
  10% {
  }
  13% {
    opacity: 1;
  }
  17% {
    opacity: 0;
  }
  25% {
  }
  30% {
  }
  35% {
  }
  40% {
  }
  45% {
  }
  50% {
  }
  55% {
  }
  60% {
  }
  63% {
    opacity: 0;
  }
  67% {
    opacity: 1;
  }
  75% {
  }
  80% {
  }
  85% {
  }
  90% {
  }
  95% {
  }
  100% {
  }
}
