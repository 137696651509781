@import '../../../styles/shared.scss';

.latest-section.webinars {
  @extend %flex-row;
  flex-wrap: wrap;
  gap: 24px;

  .webinar {
    @extend %flex-col;
    justify-content: space-between;
    width: calc(100%);
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    padding: 48px;
    position: relative;
    background-color: $darkest;
    color: white;

    @include md {
      width: calc(50% - 24px / 2);
    }

    @include xl {
      // width: calc(33% - 24px / 2);
    }

    &.past {
      background-color: $white;
      color: $darkest;
    }

    .meta {
      .date {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        font-weight: bold;
        margin-bottom: 12px;
      }
      .title {
        font-size: 50px;
        font-weight: 500;
        font-family: $font-header;
        text-transform: uppercase;
      }

      .subtitle {
        margin-top: 20px;
        font-size: 18px;
      }
    }

    .action-display {
      @extend %btn-basic;
      @extend %label;
      align-self: flex-start;
      margin-top: 32px;
      font-size: 12px;
      padding: 12px 24px;
      border: $border-white;
      border-radius: 4px;
      text-decoration: none;
      color: $white;

      &.past {
        border: $border-darkest;
        color: $darkest;
      }

      svg {
        margin-left: 8px;
      }
    }

    .days-from-now {
      position: absolute;
      bottom: 16px;
      right: 16px;
      font-size: 12px;
      background-color: $primary;
      border-radius: 24px;
      padding: 2px 8px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
