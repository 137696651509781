@import '../../../styles/shared.scss';

$logo-standard-size: 120px;
$logo-wide-size: 160px;
$logo-extra-wide-size: 200px;
$logo-square-size: 80px;
$logo-portrait-size: 60px;
$logo-standard-height: 60px;

.home-brand-logos-outer-container {
  background-color: $whynotusewhite;
  @include sm-down {
    padding: 48px 0;
  }

  @include sm {
    padding: 120px 0;
  }

  @include md {
    padding: 120px 0;
  }

  @include lg {
    padding: 120px 0;
  }
  @include xl {
    padding: 120px 0;
  }

  @include xxl {
    padding: 120px 0;
  }

  .home-brand-logos-inner-container {
    @extend %flex-col-center;
    flex-wrap: wrap;
    max-width: 1240px;
    margin: 0 auto;

    @include xl {
      max-width: 1240px;
    }

    @include xxl {
      max-width: 1440px;
    }

    .badge {
      @extend %label;

      @include sm-down {
        font-size: 12px;
        margin: 0 0 24px;
      }

      @include sm {
        font-size: 12px;
        margin: 0 0 24px;
      }

      @include md {
        font-size: 14px;
        margin: 0 0 24px;
      }

      @include lg {
        font-size: 14px;
        margin: 0 0 30px;
      }
      @include xl {
        font-size: 15px;
        margin: 0 0 30px;
      }

      @include xxl {
        font-size: 15px;
        margin: 0 0 30px;
      }
    }

    .logos {
      @extend %flex-center;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 24px;
      gap: 24px;

      @include md {
        gap: 24px;
      }

      @include lg {
        gap: 36px 50px;
      }

      @include xl {
        gap: 44px 64px;
      }

      @include xxl {
        gap: 54px 80px;
      }

      .logo {
        object-fit: contain;
        filter: grayscale(100%) brightness(1);
        width: calc($logo-standard-size * 0.8);
        min-height: calc($logo-standard-height * 0.8);
        transition: transform 0.2s ease-in-out;
        cursor: pointer;

        &:not(.mobile) {
          @include md-down {
            display: none;
          }
        }

        &:hover {
          transform: scale(1.05);
        }

        &.extra-wide {
          width: calc($logo-extra-wide-size * 0.8);
        }

        &.wide {
          width: calc($logo-wide-size * 0.8);
        }

        &.square {
          width: calc($logo-square-size * 0.8);
        }

        &.portrait {
          width: calc($logo-portrait-size * 0.8);
        }

        @include xl {
          gap: 40px;
          width: calc($logo-standard-size * 0.9);
          min-height: calc($logo-standard-height * 0.9);

          &.extra-wide {
            width: calc($logo-extra-wide-size * 0.9);
          }

          &.wide {
            width: calc($logo-wide-size * 0.9);
          }

          &.square {
            width: calc($logo-square-size * 0.9);
          }

          &.portrait {
            width: calc($logo-portrait-size * 0.9);
          }
        }

        @include xxl {
          width: $logo-standard-size;
          min-height: $logo-standard-height;

          &.extra-wide {
            width: $logo-extra-wide-size;
          }

          &.wide {
            width: $logo-wide-size;
          }

          &.square {
            width: $logo-square-size;
          }

          &.portrait {
            width: $logo-portrait-size;
          }
        }
      }
    }
  }
}
