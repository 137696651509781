@import '../../../styles/shared.scss';

.opportunity-setup-guide-tags {
  @extend %flex-col;
  gap: 48px;

  .results-section {
    .results-section-title {
      font-size: 12px;
      color: $darkest;
      font-weight: 500;
      margin-bottom: 12px;
    }
  }

  .empty-results,
  .is-searching-results {
    @extend %flex-col-center;
    gap: 4px;
    width: 100%;
    background-color: $prettymuchwhite;
    padding: 24px;
    border-radius: 10px;

    .main-message {
      font-size: 12px;
      font-weight: 500;
      color: $dark;
    }
    .secondary-message {
      font-size: 10px;
      color: $light;
    }
  }

  .active-results {
    @extend %flex-row;
    flex-wrap: wrap;
    gap: 8px;
  }

  .result-pills {
    @extend %flex-row;
    flex-wrap: wrap;
    gap: 12px;

    .result-pill {
      @extend %btn-basic;
      @extend %flex-row-middle;
      gap: 8px;
      border: $border-lighter;
      color: $darker;
      padding: 6px 12px;
      border-radius: 20px;
      font-size: 10px;

      &.active {
        border: $border-darkest;
        color: $darkest;
        font-size: 12px;
        padding: 8px 16px;
      }
    }
  }
  .input-container {
    @extend %flex-row-middle-spread;
    margin-bottom: 24px;
    width: 100%;
    input {
      @extend %input-shared;
      flex: 1;
      font-size: 14px;
    }
  }
}
