@import '../../../styles/shared.scss';

.discover2-announcement-outer-container {
  @extend %flex-row-middle;
  align-items: stretch;
  overflow: hidden;
  height: 100%;
  border: 1px solid $darker;
  border-radius: 20px;
  max-width: 800px;
  position: relative;
  padding-right: 280px; // FOR IMAGE

  @include md-down {
    flex-direction: column-reverse;
    padding-right: 0;
    border: none;
    border-radius: none;
  }

  .main-panel {
    @extend %flex-col-middle;
    align-items: flex-start;
    flex: 1;
    padding: 64px;
    border-radius: 100px;

    a {
      @extend %link;
      color: $darkest;
    }
    .badge {
      @extend %label;
      font-size: 12px;
      margin-bottom: 12px;
    }

    .title {
      @extend %header-fancy;
      margin-bottom: 12px;
      font-size: 56px;
      line-height: 56px;
    }
    .body {
      @extend %paragraph-sm;
      color: $darker;
    }
    .continue-btn-container {
      @extend %flex-row;
      margin-top: 24px;
      gap: 12px;
    }
  }

  img {
    @extend %animation-fade;
    position: absolute;
    right: 0;
    top: 0%;
    height: 110%;
    top: -2%;
    object-fit: cover;
    object-position: 100% 50%;
    pointer-events: none;

    @include md-down {
      display: none;
    }
  }
}
