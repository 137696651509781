@import '../../../styles/shared.scss';

.permission-scopes-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 16px;

  .permission-scopes-list {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    gap: 20px;

    .permission-scope-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      width: min-content;
      white-space: nowrap;

      .row-left {
        display: flex;
        gap: 10px;

        input {
          accent-color: $primary;
        }
      }
    }
  }

  .permission-scopes-submit-btn {
    @extend %btn-darkest;
    padding: 12px 0;
    font-size: 16px;
    margin-top: 32px;
    background-color: $primary;
    border-color: $primary;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background-color: $darkest;
      border-color: $darkest;
    }
  }
}
