@import '../../styles/shared.scss';

.list-outer-container {
  background-color: $darkest;
  font-family: $font-body-new;
  min-height: calc(100vh + 240px);
  padding-top: 240px; // To get dark background to hold even on pull down
  margin-top: -240px; // To get dark background to hold even on pull down
  padding-bottom: 240px;

  .list-inner-container {
    margin: 0 auto;
    width: 90vw;
    max-width: 1024px;

    .loader-container {
      margin: 120px 0;
    }

    .sections {
      @extend %flex-col;
      gap: 48px;

      .section-header-container {
        margin-bottom: 32px;
        position: relative;

        .section-header {
          @extend %header-fancy-md;
          color: white;
        }
        .section-subheader {
          @extend %paragraph-md;
          color: white;
        }

        .header-actions {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .select {
      min-width: 240px;

      .select__control {
        background-color: transparent;
        height: 24px;
        min-height: 24px;

        .select__multi-value {
          background-color: $dark;
          border: $border-dark;
          border-radius: 2px; // Adjust the pill border radius
          margin: 2px; // Adjust the margin between pills

          .select__multi-value__label {
            @extend %label;
            font-size: 10px;
            padding: 2px 2px;
            color: white;
          }
        }

        .select__value-container {
          @extend %flex-row;
          align-items: flex-start;
          padding: 0;
          height: 24px;
          min-height: 24px;

          .select__placeholder {
            @extend %label;
            font-size: 10px;
            padding: 2px 2px;
            color: $lighter;
          }

          [class*='-Input'] {
            margin: 0;
            padding: 0;
            height: 24px;
            min-height: 24px;

            .select__input input {
              @extend %label;
              text-align: left;
              font-size: 10px !important;
              padding: 2px 2px;
              color: $lighter !important;
            }
          }
        }

        .select__indicators {
          height: 24px;

          .select__indicator-separator {
            display: none;
          }
          .select__dropdown-indicator,
          .select__clear-indicator {
            padding: 4px;
          }
        }
      }

      .select__single-value {
        color: white;
        font-size: 12px;
      }
    }
  }
}
