@import '../../styles/shared.scss';

.brand-apply-panel-outer-container {
  max-width: 880px;
  width: calc(100% - 48px);
  margin: 0 auto 90px;
  color: $darker;

  .apply-panel-header {
    margin: 6vh 0;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  .brand-apply-panel-inner-container {
    background: $offwhite;
    border-radius: 10px;
    padding: 24px 0;

    .brand-apply-panel-already-logged-in {
      text-align: center;
      font-size: 16px;
    }

    .panels {
      @extend %flex-col;

      @include md {
        flex-direction: row;
      }

      .panel {
        @extend %flex-col-center;
        flex: 1;
        margin-bottom: 48px;

        &:first-child {
          border-bottom: 10px solid white;
          padding-bottom: 24px;
          @include md {
            border-bottom: none;
            padding-bottom: 0;
          }
        }

        @include md {
          margin-bottom: 0px;
        }

        .panel-header {
          font-size: 24px;
          text-transform: uppercase;
          margin-bottom: 24px;
        }

        .panel-body {
          @extend %flex-col;
          width: 100%;
          padding: 0 24px;

          input,
          textarea {
            @extend %input-shared;
            width: 100%;
            margin-bottom: 12px;
          }

          .submit-btn {
            @extend %btn-darkest;
            padding: 8px 24px;
            align-self: flex-end;
          }
        }
      }
    }
  }
}
