@import '../../../styles/shared.scss';

.opportunity-listing-upload {
  @extend %flex-row-middle-spread;
  gap: 24px;
  border: $border-offwhite;
  background-color: $prettymuchwhite;
  border-radius: 4px;
  padding: 12px;
  transition: border-color 0.2s;

  &:not(.editing) {
    cursor: pointer;

    &:hover {
      border-color: $light;
    }
  }

  .upload-data {
    @extend %flex-row-middle;
    flex: 1;
    gap: 16px;

    &.editing {
      align-items: flex-start;
    }

    &.has-description {
      align-items: flex-start;
    }

    .preview {
      @extend %label;
      @extend %flex-center;
      width: 36px;
      height: 36px;
      background-color: $darkest;
      border-radius: 4px;
      overflow: hidden;
      font-size: 10px;
      color: white;
      font-weight: bold;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .main {
      @extend %flex-col;
      flex: 1;

      &.editing {
        gap: 4px;

        input,
        textarea {
          @extend %input-inline;
          background-color: transparent;
          resize: none;

          &.title {
            font-size: 16px;
            font-weight: bold;
          }

          &.description {
            font-size: 12px;
          }
        }
      }

      .title {
        font-size: 16px;
        font-weight: bold;
        color: $darkest;
      }

      .description {
        font-size: 12px;
        color: $medium;
      }
    }
  }

  .upload-actions {
    @extend %flex-row-middle;
    gap: 12px;

    .action {
      @extend %btn-basic;
      font-size: 14px;

      &.delete {
        font-size: 16px;
      }
    }
  }
}
