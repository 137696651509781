@import '../../styles/shared.scss';

.product-main {
  @extend %flex-col;
  margin-bottom: 48px;

  @include md {
    flex-direction: row;
  }

  .image-container {
    @extend %flex-col-center;
    margin: 0 auto 24px;

    @include md {
      margin: 0 12px 0 0;
    }

    .primary-img {
      @extend %animation-fade;
    }

    .primary-img,
    .empty-img {
      max-width: 320px;
      max-height: 320px;
      min-width: 320px;
      min-height: 320px;
      width: 90vw;
      align-item: center;
    }

    .empty-img {
      @extend %skeleton-box;
      background: white;
      border-radius: 4px;
    }
    .recommended {
      text-transform: uppercase;
      font-size: 12px;
      color: $medium;
      text-align: center;
      margin-top: 12px;

      .count {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .meta {
    @extend %flex-col;
    color: $darker;
    flex: 1;
    .brand {
      margin: 0;
      text-transform: uppercase;
      font-size: 15px;
      line-height: 1.1;
      font-weight: bold;
    }

    .title {
      margin: 0;
      line-height: 1.1;
      margin-top: 2px;
      font-size: 21px;
      letter-spacing: 0.5px;
      font-weight: normal;
    }

    .rank {
      @extend %flex-row-middle;
      text-transform: uppercase;
      font-size: 12px;
      color: $medium;
      text-decoration: none;
      padding: 0 2px 4px 0;
      color: $dark;

      .num {
        font-family: $font-header;
        font-size: 22px;
        line-height: 18px;
      }
      .name {
        text-decoration: underline;
        margin: 6px 0 0 6px;
        font-weight: bold;
        &:hover {
          color: $darkest;
        }
      }
    }
    .accent {
      width: 40%;
      max-width: 64px;
      // height: 1px;
      background: $offwhite;
      margin: 4px 0 8px;
    }

    .retailer-options {
      .buy-at {
        text-transform: uppercase;
        font-size: 12px;
        color: $medium;
        margin: 24px 0 12px;
      }

      .retailer-option {
        @extend %flex-row-middle-spread;
        padding: 8px 16px;
        // border: $border-lighter;
        background: $offwhite;
        // text-transform: uppercase;
        letter-spacing: 0.6px;
        border-radius: 4px;
        margin-bottom: 8px;
        max-width: 320px;
        min-width: 240px;
        text-decoration: none;
        color: $dark;
        transition: all 0.15s ease-in-out;

        svg {
          transition: all 0.15s ease-in-out;
        }

        .retailer {
          font-weight: bold;
        }

        &:hover {
          background: $darker;
          color: white;
          border-color: $darkest;

          svg {
            transform: scale(1.05) translateX(2px);
          }
        }
      }
    }
  }
}
