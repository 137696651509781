@import '../../styles/shared.scss';

.pro {
  .landing-steps-panel-outer-container {
    background: $darker;
  }
}

.landing-steps-panel-outer-container {
  @extend %flex-center;
  width: 100%;
  padding: 64px 0;
  position: relative;
  border-top: 20px solid white;
  background: rgba(0, 0, 0, 0.35);
  color: white;

  @include md {
    padding: 15vh 0;
    overflow: hidden;
  }

  .landing-steps-panel-container {
    @extend %flex-row;
    justify-content: center;
    width: calc(100% - 36px);
    margin: 0 auto;
    max-width: 1240px;
    position: relative;
    height: 100%;
    flex-direction: column;

    @include md {
      padding: 24px;
      flex-direction: row;
    }

    @include xl {
      padding: 24px 0;
    }

    .landing-steps-step-container {
      margin: 16px 12px;

      @include md {
        max-width: 290px;
        min-width: 240px;
        margin: 0 24px;
      }

      h3 {
        font-size: 24px;
        line-height: 26px;
        letter-spacing: -0.38px;
        font-weight: bold;
        margin: 0;
      }
      h4 {
        font-size: 15px;
        line-height: 17px;
        font-family: $font-body;
        font-weight: normal;
        margin: 12px 0 0;
      }
    }
  }
}
