@import '../../../../styles/shared.scss';

.advanced-split-input {
  @extend %flex-row;
  gap: 5px;
  height: 51px;

  > button {
    flex: 0 0 100px;
  }
}
