@import '../../styles/shared.scss';

.discover-results-container {
  @extend %flex-col;
  transition: 0.25s opacity ease-in-out;
  gap: 48px;

  &.searching-first-page {
    opacity: 0.2;
  }

  .discover-results-section {
    .discover-results-section-title {
      @extend %flex-row;
      align-items: flex-end;
      margin-bottom: 32px;
      width: 100%;

      .title {
        font-family: $font-header;
        color: white;
        text-transform: uppercase;
        font-size: 40px;
        line-height: 24px;
      }

      .line {
        background-color: $light;
        margin-left: 12px;
        flex: 1;
        height: 1px;
        transform: translateY(-4px);
      }
    }

    .discover-results-lists {
      @extend %flex-row;
      flex-wrap: wrap;
      gap: 16px;

      .discover-results-list {
        @extend %flex-center;
        background-color: black;
        border: $border-white;
        border-radius: 4px;
        width: calc(50% - 16px / 2);
        padding: 8px 12px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        position: relative;
        text-decoration: none;

        &.hidden {
          opacity: 0.5;
        }

        &:hover {
          border: $border-light;
        }

        .title {
          @extend %label;
          text-align: center;
          color: white;
          font-size: 16px;
          overflow: hidden;
          text-decoration: none;
          text-align: center;
          width: 100%;
        }
      }
    }

    .discover-results-users {
      @extend %flex-row;
      align-items: stretch;
      gap: 36px;
      flex-wrap: wrap;
      transition: 0.25s opacity ease-in-out;

      .discover-result-container {
        width: calc(33% - ((2 / 3) * 36px));
        .syncing {
          opacity: 0.8;
        }

        @include xl-down {
          width: calc(50% - ((1 / 2) * 36px));
        }
        @include md-down {
          width: calc(100%);
        }
      }
    }
  }
  .loading-next-page-container {
    margin-top: 64px;
  }
}
