@import '../../styles/shared.scss';

.settings-side-panel-outer {
  .settings-side-panel-inner {
    @extend %flex-col;
    justify-content: space-between;
    width: 300px;
    background: white;
    padding: 24px;
    border-radius: 10px 0 0 10px;
    position: sticky;
    top: 60px;

    min-height: 80vh;

    @include md-down {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      top: unset;
      flex-direction: row;
      z-index: 1;
      min-height: unset;
      width: 100%;
      overflow-x: scroll;
    }

    .settings-side-panel-top,
    .settings-side-panel-bottom {
      @extend %flex-col;
      gap: 40px;

      .settings-side-panel-sections {
        @extend %flex-col;
        gap: 0px;

        @include md-down {
          flex-direction: row;
        }

        .settings-side-panel-section {
          @extend %flex-row;
          @extend %btn-basic;
          gap: 16px;
          padding: 12px 16px;
          align-items: center;
          font-size: 14px;
          text-decoration: none;
          color: $darkest;

          &.skeleton {
            opacity: 0.5;

            .settings-side-panel-section-title {
              width: 130px;
              height: 16px;
              border-radius: 8px;
              background: $offwhite;
            }
          }

          img.settings-side-panel-manager-image {
            height: 18px;
            width: 18px;
            border-radius: 50%;
            object-fit: cover;
          }

          .no-image {
            height: 18px;
            width: 18px;
            border-radius: 50%;
          }

          svg {
            height: 18px;
            width: 18px;
          }

          &.isSelected {
            font-weight: 700;
          }
        }
      }

      .settings-side-panel-profile {
        @extend %flex-row;
        gap: 12px;
        transition: opacity 0.3s ease-out;

        @include md-down {
          display: none;
        }

        &.skeleton {
          opacity: 0.5;
        }

        img {
          height: 56px;
          width: 56px;
          border-radius: 50%;
          object-fit: cover;
        }

        .no-image {
          height: 56px;
          width: 56px;
          border-radius: 50%;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .settings-side-panel-profile-info {
          @extend %flex-col;
          gap: 4px;
          font-size: 16px;
          justify-content: center;

          .settings-side-panel-profile-name {
            font-weight: 700;

            &.skeleton {
              width: 100px;
              height: 16px;
              border-radius: 8px;
              background: $offwhite;
            }
          }

          .settings-side-panel-profile-tier {
            font-weight: 400;
            font-size: 14px;

            &.skeleton {
              width: 150px;
              height: 14px;
              border-radius: 8px;
              background: $offwhite;
            }
          }
        }
      }

      .settings-side-panel-search {
        position: relative;

        @include md-down {
          display: none;
        }


        input.settings-side-panel-search-input {
          padding: 16px;
          padding-left: 48px;
          border-radius: 500px;
          background: $offwhite;
          border: none;
          outline: none;
          width: 100%;

          &.skeleton {
            opacity: 0.5;

            &::placeholder {
              color: $offwhite;
            }
          }
        }

        svg {
          position: absolute;
          top: 50%;
          left: 16px;
          transform: translateY(-50%);
          height: 16px;
          width: 16px;
        }

      }

      button.settings-side-panel-logout {
        @extend %flex-row;
        @extend %btn-basic;
        gap: 16px;
        padding: 10px 16px;
        align-items: center;
        font-size: 16px;
        border: none;
        outline: none;
        background: none;

        svg {
          height: 16px;
          width: 16px;
        }
      }

    }
  }
}
