@import '../../styles/shared.scss';

.sms {
  .footer-old-outer-shell {
    margin-top: 120px;
    font-family: $font-body;

    &.has-disclaimer {
      margin-top: 64px;
    }

    &.referral {
      margin-top: 0;
    }

    .footer-disclaimer-container {
      width: 100%;
      margin: 0 auto;
      max-width: 1024px;
      border-top: 2px solid $lightest;

      .footer-disclaimer {
        color: $medium;
        font-size: 12px;
        width: 100%;
        padding: 24px 26px 48px;

        @include sm {
          width: 60%;
          padding: 24px 26px 48px;
        }
      }
    }

    .footer-outer-container {
      @extend %flex-row-middle;
      background: $offwhite;
      width: 100%;
      z-index: 5;
      padding: 24px 12px;

      @include sm {
        padding: 24px 24px;
      }

      &.public {
        @extend %flex-col-middle;
        margin: 64px 0 108px;
        padding: 24px 24px 64px;
        background: white;

        .footer-home-link {
          @extend %flex-col-center;
          text-decoration: none;
          transition: all 0.2s ease-in-out;
          cursor: pointer;

          .footer-public-logo-header {
            color: $dark;
            font-size: 12px;
            margin-bottom: -2px;
          }

          .footer-logo {
            font-size: 20px;
            font-family: $font-header;
            font-weight: bold;
            color: $dark;
          }

          &:hover {
            opacity: 0.6;
          }
        }
      }

      .footer-inner-container {
        @extend %flex-col;
        padding: 12px 8px;
        width: 100%;
        margin: 0 auto;
        max-width: 1024px;
        padding: 48px 16px;

        @include sm {
          padding: 48px 26px;
        }

        .footer-main-body {
          @extend %flex-col;
          flex-wrap: wrap;

          @include sm {
            flex-direction: row;
          }

          .panel {
            @extend %flex-col;
            flex: 1;
            padding: 0 0 32px;

            @include sm {
              padding: 0 0 32px;
            }

            .footer-panel-header {
              font-size: 18px;
              color: $dark;
              font-weight: bold;
              margin-bottom: 8px;
            }

            ul {
              padding: 0;
              margin: 0;

              li {
                list-style-type: none;
                margin-bottom: 3px;

                .footer-panel-link {
                  @extend %flex-row-middle;
                  font-size: 15px;
                  text-decoration: unset;
                  color: $dark;

                  &:focus {
                    outline: none;
                    color: $darkest;
                    font-weight: bold;
                  }

                  .new-badge {
                    background: $dark;
                    font-size: 10px;
                    padding: 2px 4px;
                    margin-left: 6px;
                    color: white;
                    border-radius: 3px;
                    font-weight: bold;
                  }
                }
              }
            }

            .footer-panel-message {
              font-size: 15px;
              color: $dark;
              line-height: 1.1;
              margin-bottom: 8px;
            }

            .email-container {
              margin-top: 12px;

              form {
                @extend %flex-col;

                @include md {
                  flex-direction: row;
                }

                input {
                  @extend %input-shared;
                }

                button {
                  @extend %btn-dark;
                  padding: 8px;
                  margin: 8px 0 0 0;

                  @include md {
                    margin: 0 0 0 8px;
                  }
                }
              }

              .join-again {
                font-weight: bold;
                cursor: pointer;
                font-size: 14px;
              }
            }

            .footer-panel-social-links {
              margin-top: 14px;

              a {
                img {
                  height: 17px;
                  margin-right: 16px;
                  opacity: 0.8;
                  border-radius: 2px;
                }

                &:hover {
                  transform: scale(1.05);
                  outline: none;
                  opacity: 0.7;
                }
              }
            }
          }

          .footer-right-panel {
            min-width: 208px;
          }
        }

        .footer-brand-container {
          text-decoration: unset;

          .footer-logo {
            font-size: 20px;
            font-family: $font-header;
            font-weight: bold;
            color: $dark;
            cursor: pointer;
          }
          .footer-copyright {
            font-size: 18px;
            color: $dark;
            cursor: pointer;
            margin-top: 4px;
            margin-right: 12px;
            font-size: 12px;
          }

          &:focus {
            outline: none;
            opacity: 0.7;
          }
        }
      }
    }
  }
}
