@import '../../styles/shared.scss';

.latest-header-container {
  margin-bottom: 24px;
  padding-left: 20px;

  .header-container {

    &.fetching {
      .badge {
        opacity: 0.3;
      }

      .header {
        opacity: 0.1;
      }
    }

    .badge {
      @extend %label;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 10px;
      transition: opacity 0.3s ease-in-out;
      opacity: 1;
    }

    .header {
      font-size: 48px;
      text-transform: uppercase;
      font-family: $font-header;
      font-weight: 500;
      transition: opacity 0.3s ease-in-out;
      opacity: 1;
    }
  }

  .tabs {
    @extend %flex-row;
    gap: 32px;
    margin-top: 32px;
    overflow: auto;

    .tab-container {
      padding-bottom: 16px;

      &.initial-load:not(.active, .empty) {
        @extend %animation-fade;
        animation: fade-in 0.3s ease-in-out forwards;
        opacity: 0;

        @for $i from 1 through 100 {
          &:nth-child(#{$i}) {
            animation-delay: #{$i * 0.05}s;
          }
        }
      }

      &.empty {
        .tab {
          border: none;
          background-color: $lightest;
          opacity: 0.4;
          color: transparent;
        }
      }

      &.active {
        border-bottom: 4px solid $darkest;
      }

      .tab {
        @extend %label;
        @extend %btn-basic;
        font-size: 14px;
        font-weight: 600;
        border-radius: 4px;
        transition: opacity 0.2s ease-in-out;
        cursor: pointer;

      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $lightest;
  }

  .latest-tag-container {
    @extend %flex-row;
    gap: 16px;
    margin-top: 16px;
    margin-bottom: 24px;
    overflow: auto;

    .tag {
      @extend %label;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 500;
      padding: 10px 15px;
      border-radius: 400px;
      background-color: white;
      border: $border-lightest;
      transition: opacity 0.2s ease-in-out;
      cursor: pointer;

      &:hover:not(.active) {
        opacity: 0.8;
      }

      &.active {
        background-color: $darkest;
        color: white;
        border: $border-darkest;
      }
    }
  }


  .reload-btn {
    @extend %btn-basic;
    @extend %label;
    @extend %flex-row-middle;
    position: absolute;
    top: 0;
    right: 0;
    gap: 4px;
    font-size: 10px;
    color: $medium;
    padding: 4px;

    svg {
      font-size: 8px;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
