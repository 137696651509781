@import '../../../styles/shared.scss';

.home-creator-animations-outer-container {
  overflow: hidden;

  .home-creator-animations-inner-container {
    @extend %flex-row;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto 30vh;

    // Match this to the header
    @include md-down {
      padding: 0 20px;
      margin-bottom: 0px;
    }

    @include md {
      padding: 0 60px;
      margin-bottom: 240px;
    }

    @include lg {
      padding: 0 40px;
      margin-bottom: 360px;
    }

    @include xl {
      padding: 0 12px;
      margin-bottom: 400px;
    }

    @include xxl {
      max-width: 1440px;
      padding: 0;
      margin-bottom: 480px;
    }

    .home-creator-animations-steps {
      flex: 1;
      min-height: 100vh;
      border-left: $border-darkest;
    }
    .home-creator-animations-phones {
      @extend %flex-col;
      justify-content: space-between;
      width: 50%;
      align-self: stretch;
      pointer-events: none;

      @include md-down {
        display: none;
      }
    }
  }
}
