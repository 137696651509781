@import '../../styles/shared.scss';

.chat-messages-outer-container {
  height: 100%;
  overflow: hidden;

  .chat-messages {
    overflow: auto;
    padding: 32px 0 160px;
    height: 100%;

    &.dismissed {
      opacity: 0.2;
    }

    .message-timestamp {
      text-align: center;
      font-size: 10px;
      color: $medium;
    }

    .chat-typing-wrapper {
      visibility: hidden;
      opacity: 0;
      height: 0;
      transition: opacity 0.5s; /* Apply opacity transition for both adding and removing the class */
    }

    .chat-typing-wrapper.active {
      visibility: visible;
      opacity: 1;
      height: 60px;
      transition: opacity 0.5s; /* Maintain opacity transition when adding 'active' class */
    }

    /* Apply height transition only when removing the 'active' class */
    .chat-typing-wrapper:not(.active) {
      transition: height 0.5s, opacity 0.5s;
    }

    .chat-typing-wrapper {
      @include md-down {
        margin-bottom: 120px;
      }
    }
  }
  .drop-form-wrapper {
    position: absolute;
    top: 0;
  }

  form {
    @extend %flex-row-middle;
    position: absolute;
    bottom: 0;
    background-color: white;
    border-top: $border-offwhite;
    width: 100%;
    border-radius: 0 0 10px 10px;
    padding: 16px;
    gap: 12px;

    .search-result-input {
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 10px;

      .on-mobile {
        display: none;
        padding-bottom: 40px;

        @include md-down {
          display: inherit;
        }
      }
      .on-computer {
        display: none;
        @include md {
          display: inherit;
        }
      }

      .fake-input {
        @extend %input-shared;
        background: white;
        color: $medium;
        border-radius: 10px;
      }
      input {
        padding-right: 28px;
      }

      textarea,
      input {
        @extend %input-shared;
        width: 100%;
        resize: none;
        border-radius: 10px;
        font-size: 14px;
        padding: 12px 16px;

        @include md-down {
          font-size: 16px;
        }

        &[disabled] {
          opacity: 0.4;
        }
      }

      .upload-progress-bar {
        height: 2px;
        background-color: $primary;
        position: absolute;
        bottom: 0;
        transition: width 0.1s linear;
      }
    }

    .uploaded-files {
      @extend %flex-row-middle;
      gap: 8px;

      .file {
        width: 36px;
        height: 36px;
        background-color: $lightest;
        border-radius: 4px;
        position: relative;
        border: $border-offwhite;

        img {
          @extend %animation-fade;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }

        .file-label {
          @extend %flex-center;
          width: 100%;
          height: 100%;
          color: $medium;
          font-size: 12px;
          font-weight: bold;
        }

        &:hover {
          .remove {
            opacity: 1;
          }
        }

        .remove {
          @extend %flex-center;
          cursor: pointer;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          position: absolute;
          right: -8px;
          top: -8px;
          background-color: $dark;
          border: $border-white;
          opacity: 0.8;

          svg {
            color: white;
            font-size: 10px;
          }
        }
      }
    }

    .upload-basic-container {
      @extend %btn-basic;

      @include md-down {
        display: none;
      }

      .upload-icn {
        color: $dark;
      }

      input {
        display: none;
      }
    }

    .submit-btn {
      @extend %btn-darkest-new;
      font-size: 10px;
      min-width: 72px;
      min-height: 36px;
    }
  }
}
