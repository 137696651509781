@import '../../../styles/shared.scss';

.collection-media-outer-container {
  padding: 16px 0 0;
  margin: 0 -32px;

  @include sm {
    margin: 0;
  }

  &.longform {
    margin-bottom: 24px;
  }

  .youtube-video {
    @extend %skeleton-box;
    margin-top: 40px;
    min-height: 280px;
    height: 100%;

    @include sm {
      min-height: 320px;
      border-radius: 6px;
    }
    @include md {
      min-height: 400px;
    }
    @include lg {
      min-height: 440px;
    }
    @include xl {
      min-height: 480px;
    }
  }

  .social-post-image-container {
    @extend %flex-center;
    margin: 8px 0 0;
    width: 100%;

    @include sm {
      margin: 24px 0 0;
    }

    @include md {
      &:hover {
        .remove-icon {
          display: flex;
        }
      }
    }

    .video-container {
      position: relative;
      min-width: 100%;

      video {
        min-width: 100%;
        max-height: 600px;
      }
    }

    .image-container {
      position: relative;
      img {
        width: 100%;

        @include sm {
          width: 400px;
          border-radius: 4px;
        }
      }
    }

    .remove-icon {
      @extend %flex-center;
      background: $darker;
      color: white;
      padding: 4px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      position: absolute;
      border: $border-white;
      top: -12px;
      right: -12px;
      cursor: pointer;

      @include md {
        display: none;
      }

      &:hover {
        background: $dark;
      }
    }
  }
}
