@import '../../styles/shared.scss';

.discover-users-sidebar-container {
  .search-container {
    position: relative;
    input {
      @extend %input-shared;
      width: 100%;
      background: transparent;
      padding-right: 28px;
    }

    svg {
      @extend %btn-basic;
      position: absolute;
      right: 8px;
      top: 12px;
      font-size: 14px;
      color: $medium;
      pointer-events: none;
      z-index: 2;

      &.clear {
        pointer-events: auto;
      }
    }
  }

  .tag-sections-container {
    margin-top: 24px;
    color: $darker;
    padding-left: 12px; // to match input padding

    .section {
      margin-bottom: 12px;
      text-transform: uppercase;

      .section-header {
        @extend %flex-row-middle-spread;
        font-size: 14px;
        font-weight: bold;

        &.selectable {
          @extend %btn-basic;
        }

        .toggle-section {
          @extend %btn-basic;
          color: $lighter;
        }
      }
      .section-tags {
        margin-top: 4px;

        .toggle-more {
          @extend %btn-basic;
          font-size: 10px;
          margin-top: 4px;
          margin-left: 4px;
          text-decoration: underline;
        }
      }
      .checkbox {
        height: 12px;
        width: 12px;
        color: $dark;
        margin-top: -2px;
        padding-left: 4px;

        svg {
          opacity: 0;
        }

        &.selected {
          svg {
            opacity: 1;
          }
        }
      }
    }
  }
}

// Need to pull this out because it is sortable and the dragged sort object gets hoisted to the outermost level on the DOM
.discover-users-sidebar-tag {
  @extend %flex-row-middle-spread;
  @extend %btn-basic;
  font-size: 12px;
  margin-left: 4px;
  margin-bottom: 3px;
  font-family: $font-body;
  text-transform: uppercase;
  color: $darker;

  &.selected {
    color: $darkest;
    font-weight: bold;
  }
  .value {
    position: relative;

    &:hover {
      .actions {
        opacity: 1;
      }
    }

    .actions {
      @extend %flex-row-middle-spread;
      position: absolute;
      right: 100%;
      padding-right: 5px;
      color: $light;
      font-size: 10px;
      transition: opacity 0.1s ease-in-out;
      opacity: 0;

      .action {
        transform: translateY(2px);
        &:not(:last-child) {
          margin-right: 2px;
        }
      }
    }

    .count {
      margin-left: 4px;
      font-size: 10px;
      color: $medium;
      transition: opacity 0.2s ease-in-out;

      &.fetching {
        opacity: 0.3;
      }
    }
  }

  .hidden {
    color: $light;
    font-size: 10px;
    margin-left: 4px;
  }
}
