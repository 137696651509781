@import '../../styles/shared.scss';

.blog-tab-modal-tab {
  @extend %flex-row;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: $border-lightest;
  cursor: pointer;

  > div {
    @extend %flex-row;
    align-items: center;
    gap: 10px;

    svg {
      height: 15px;
      width: 15px;

      &.isHidden {
        opacity: 0.3;
      }
    }

    .post-count {
      font-size: 12px;
      color: $dark;
      margin-right: 20px;
    }

    input {
      width: 400px;
      font-family: $font-body-new;
      border: none;
      outline: none;
    }
  }
}
