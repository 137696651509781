@import '../../../styles/shared.scss';

.listing-advanced-settings-container {
  &.collapsed {
    .opportunity-listing-section-title-container {
      @extend %btn-basic;
      margin-bottom: 0 !important;
    }
  }

  .opportunity-listing-section-title-container {
    @extend %flex-row-middle-spread;
    position: relative;
  }

  .settings-sections {
    @extend %flex-col;
    gap: 40px;

    .settings-section {
      .settings-section-title-container {
        .settings-section-title {
          font-size: 18px;
          font-weight: bold;
          color: $darkest;
        }
      }

      .settings-section-description {
        font-size: 12px;
        font-weight: 400;
        margin-top: 4px;
        color: $medium;
        margin-bottom: 16px;
      }

      .settings-section-input {
        margin-top: 8px;
        position: relative;

        input {
          @extend %input-shared;
          color: $darkest;
          min-width: 240px;
          font-size: 14px;

          &.wide {
            width: 100%;
          }

          &::placeholder {
            font-size: 14px;
            color: $light;
          }

          &.error {
            @extend %input-error;
          }
        }

        .error-message {
          position: absolute;
          bottom: -20px;
          font-size: 10px;
          left: 0;
          color: $dark;
        }
      }

      .select-options {
        @extend %flex-col;
        gap: 8px;

        .option-container {
          @extend %flex-row-middle;
          cursor: pointer;
          gap: 10px;

          &.selected {
            .checkbox {
              background-color: $darkest;
              border-color: $darkest;
              svg {
                color: $white;
              }
            }

            .display {
              color: $darkest;
              font-weight: bold;
            }
          }

          .checkbox {
            @extend %flex-center;
            width: 18px;
            height: 18px;
            border: $border-lighter;
            background-color: $prettymuchwhite;
            color: transparent;
            border-radius: 4px;

            svg {
              font-size: 10px;
            }
          }

          .display {
            color: $medium;
            font-size: 14px;
          }
        }
      }
    }
  }
}
