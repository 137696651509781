@import '../../../styles/shared.scss';

.user-lookbook-body-outer {
  .user-lookbook-body-inner {
    width: 87%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 50px 0 180px;

    &.showing-emphasized-note-overlay {
      pointer-events: none;

      &::before {
        @extend %animation-fade-quick;
        width: 100%;
        height: 100%;
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.7) 100%);
        z-index: 1;
      }
    }

    .header {
      @extend %flex-row;
      justify-content: space-between;
      margin-bottom: 50px;

      @include sm-down {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }

      @include md {
        flex-direction: row;
      }

      #done-selecting-buttons {
        .done-selecting {
          @extend %btn-basic;
          @extend %label;
          font-size: 14px;
          padding: 14px 16px;
          border-radius: 5px;
          background-color: black;
          color: white;
          border: none;
          outline: none;
        }
      }

      #note-from-brand {
        @extend %flex-row;
        gap: 20px;

        .image-container {
          @extend %flex-center;
          height: 30px;
          width: 30px;
          border-radius: 50%;
          background: $darkest;

          @include md-down {
            display: none;
          }

          img {
            height: 75%;
            width: 75%;
            object-fit: contain;
            filter: brightness(0) invert(1);
          }
        }

        .text {
          @include sm-down {
            text-align: center;
          }

          @include md {
            text-align: left;
          }

          .intro {
            @extend %label;
            font-size: 12px;
          }

          .brand-note {
            background-color: transparent;
            font-family: $font-body;
            font-weight: normal;
            font-size: 0.8rem;
            color: black;
            resize: none;
            white-space: pre-wrap;
            width: 600px;
            margin-top: 5px;

            @include lg-down {
              width: 400px;
            }

            @include md-down {
              width: 300px;
            }
          }
        }
      }
    }
  }
}
