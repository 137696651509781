@import '../../styles/shared.scss';

.opportunity-result-container {
  cursor: pointer;
  text-decoration: none;
  color: black;

  &.nothing-done {
    .opportunity-result-body {
      background-color: $whynotusewhite;
      border: $border-offwhite;

      img {
        filter: grayscale(100%);
      }
    }
  }
  .opportunity-result-header {
    @extend %flex-row-spread;
    margin-bottom: 16px;
    gap: 24px;

    @include md-down {
      flex-direction: column;
      gap: 12px;
    }

    .title-container {
      @extend %flex-row-middle;
      gap: 24px;

      @include md-down {
        gap: 12px;
      }

      .title {
        font-size: 28px;
        font-weight: bold;
        color: $darkest;
        font-family: $font-header;

        @include md-down {
          font-size: 24px;
        }
      }

      .status-badge-container {
        @extend %flex-row-middle;
        gap: 8px;

        .badge-outer {
          width: 11px;
          height: 11px;
          min-width: 11px;
          min-height: 11px;
          background-color: $primary;
          border-radius: 50%;
          position: relative;
          animation: pulse 2.5s infinite;

          .badge-inner {
            width: 3px;
            left: 4px;
            height: 3px;
            top: 4px;
            background-color: $white;
            border-radius: 50%;
            position: absolute;
          }
        }

        .status {
          font-size: 12px;
          border-radius: 24px;
          color: $primary;
          font-weight: bold;
          white-space: nowrap;
        }
      }
    }

    .timeline-container {
      @extend %flex-row-middle;
      flex: 1;
      gap: 12px;

      .timeline-spine-container {
        flex: 1;
        height: 2px;
        position: relative;

        .timeline-spine {
          background-color: $darkest;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;

          &.pending {
            width: 100%;
            background-color: $lightest;

            &.not-started {
              background-color: $offwhite;
            }
          }
        }
      }

      .timeline-label {
        color: $dark;
        font-size: 12px;

        &.active {
          color: $darkest;
        }

        &.not-started {
          color: $light;
        }
      }
    }
  }

  .opportunity-result-body {
    @extend %flex-row;
    gap: 36px;
    background-color: $white;
    border-radius: 10px;
    min-height: 280px;
    transition: border 0.2s ease;
    border: $border-transparent;
    padding: 24px;

    @include md-down {
      flex-direction: column;
      gap: 24px;
    }

    &:hover {
      border: $border-lighter;

      .show-on-hover {
        opacity: 1 !important;
      }

      .media-container {
        .media-img-container {
          &:nth-child(1) {
            transform: translateX(1 * calc(36px * 0));
          }

          &:nth-child(2) {
            transform: translateX(-1 * calc(36px * 0.5)) scale(0.95);
          }

          &:nth-child(3) {
            transform: translateX(-1 * calc(36px * 1)) scale(0.9);
          }
          img {
            transform: scale(1.13);
          }

          .timestamp {
            opacity: 0;
          }
        }
      }
      .view-opportunity-btn {
        opacity: 1;
      }
    }

    .view-opportunity-btn {
      @extend %flex-row-middle;
      @extend %label;
      gap: 4px;
      opacity: 0;
      position: absolute;
      top: 16px;
      right: 16px;
      transition: opacity 0.2s ease;
      font-size: 10px;

      svg {
        font-size: 9px;
      }
    }

    .media-container {
      @extend %flex-row;
      position: relative;
      min-width: 120px;

      @include lg-down {
        max-height: 200px;
      }

      @include md-down {
        display: none;
      }

      &.image-count-1 {
        width: calc(180px + 36px * 2); // OFFSET
        margin-right: calc(36px * 0); // OFFSET
      }

      &.image-count-2 {
        width: calc(180px + 36px * 1); // OFFSET
        margin-right: calc(36px * 1); // OFFSET
      }

      &.image-count-3 {
        width: calc(180px + 36px * 0);
        margin-right: calc(36px * 2); // OFFSET
      }

      .media-img-container {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
        z-index: 3;
        transition: transform 0.25s ease;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.25s ease;
        }

        .timestamp {
          @extend %label;
          position: absolute;
          bottom: 8px;
          right: 8px;
          color: white;
          font-size: 10px;
          transition: opacity 0.2s ease;
        }

        &:nth-child(2) {
          left: 36px; // OFFSET
          z-index: 2;
          transform: scale(0.9);

          img {
            opacity: 0.5;
          }
          .timestamp {
            opacity: 0;
          }
        }

        &:nth-child(3) {
          left: 72px; // OFFSET
          z-index: 1;
          transform: scale(0.8);

          img {
            opacity: 0.25;
          }
          .timestamp {
            opacity: 0;
          }
        }
      }
    }

    .main-container {
      flex: 1;
      padding: 48px 24px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 36px;
      column-gap: 12px;
      position: relative;

      @include lg-down {
        grid-template-columns: 1fr;
        padding: 0;
      }

      .section {
        .section-title {
          @extend %flex-row-middle;
          @extend %label;
          font-size: 12px;
          color: $dark;
          margin-bottom: 12px;

          svg {
            margin-left: 4px;
            font-size: 10px;
          }
        }

        .section-stats {
          @extend %flex-row;
          gap: 30px;
          margin-top: 8px;

          .section-stat {
            @extend %flex-col;
            gap: 4px;

            .value {
              @extend %flex-row;
              font-size: 24px;
              line-height: 24px;
              min-height: 24px;
              color: $darkest;
              position: relative;
              font-weight: bold;

              &.empty {
                color: $light;
                font-size: 18px;
                font-weight: normal;
              }

              .loader {
                @extend %flex-row;
                position: absolute;
                top: 0;
                height: 100%;
                background-color: #fdfdfd;
                border-radius: 4px;
              }
            }

            .label {
              font-size: 12px;
              color: $dark;
              opacity: 1;

              &.empty {
                color: $light;
              }

              &.fetching {
                color: $lighter;
              }
            }
          }
        }

        .section-stats-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 8px 2px;

          .section-stat {
            @extend %flex-row-middle;
            gap: 8px;

            .value {
              @extend %flex-center;
              font-size: 12px;
              font-weight: bold;
              width: 36px;
              height: 24px;
              border-radius: 24px;
              border: $border-darkest;

              &.accepted {
                background-color: $white;
                border-color: $primary;
                color: $primary;
              }

              &.pending {
                background-color: $white;
                border-color: $darkest;
                color: $darkest;
              }

              &.completed {
                background-color: $primaryLighter;
                border-color: $primaryLighter;
                color: $primary;
              }

              &.declined {
                background-color: $offwhite;
                border-color: $offwhite;
                color: $lighter;
              }

              &.empty {
                background-color: $prettymuchwhite;
                border-color: $prettymuchwhite;
                color: $lighter;
              }

              &.fetching {
                background-color: $white;
                border-color: $offwhite;
                color: $lighter;
              }
            }

            .label {
              font-size: 12px;
              color: $darkest;

              &.empty {
                color: $light;
              }

              &.fetching {
                color: $lighter;
              }
            }
          }
        }

        .section-activity {
          @extend %flex-col;
          gap: 6px;

          .fetching-activities {
            font-size: 10px;
            color: $lighter;
          }

          .activity-item {
            @extend %flex-row-middle;
            gap: 8px;

            .icon-or-image-container {
              @extend %flex-center;
              width: 18px;
              height: 18px;
              background-color: $offwhite;
              border-radius: 50%;
              color: $darkest;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }

              svg {
                font-size: 10px;
                transform: translateY(-2px) translateX(-1px);
              }
            }

            .activity-text {
              font-size: 12px;
              color: $medium;
            }
          }
        }
      }

      .footer-actions {
        @extend %flex-row-middle;
        position: absolute;
        bottom: 12px;
        right: 12px;
        gap: 8px;

        .action {
          cursor: pointer;

          &.primary {
            @extend %btn-darkest-new;
            font-size: 10px;
          }

          &.show-on-hover {
            opacity: 0;
          }
        }
      }
    }
  }
}
