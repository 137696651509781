@import '../../../styles/shared.scss';

.opportunity-header-actions-container {
  @extend %flex-row-middle;
  gap: 8px;
  .action {
    @extend %btn-darkest-new;
    padding: 12px 24px;
    font-size: 14px;

    &.primary {
    }

    &.secondary {
      background-color: white;
      color: $darkest;
      border: $border-darkest;
    }

    &.disabled {
      cursor: default;

      &.primary {
        background-color: $lighter;
        border-color: $lighter;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}
