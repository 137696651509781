@import '../../../../styles/shared.scss';

.user-tags-panel-outer {
  .user-tags-panel-inner {
    position: relative;

    .tags-container {
      .tag-sections {
        @extend %flex-col;
        gap: 20px;

        .tag-section {
          .section-header {
            font-size: 12px;
            color: $darkest;
            font-weight: bold;
            margin-bottom: 8px;
          }

          .tags {
            @extend %flex-row;
            flex-wrap: wrap;
            gap: 8px;

            .tag {
              @extend %btn-basic;
              border-radius: 20px;
              padding: 4px 8px;
              font-size: 14px;
              font-weight: bold;
              border: $border-dark;
              text-transform: uppercase;
              color: $darkest;

              &.selected.toggleable {
                border: $border-darkest;
                color: white;
                background: $darkest;

                &:hover {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      .no-tags-selected {
        @extend %label;
        @extend %flex-center;
        background-color: $prettymuchwhite;
        color: $light;
        padding: 12px 12px;
        border-radius: 4px;
        margin-top: 10px;
        font-size: 10px;
      }
    }
  }
}
