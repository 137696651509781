@import '../../styles/shared.scss';

.brand-pitch-section-outer-container {
  margin-top: 10vh;

  .section {
    padding: 12vh auto;
    overflow: hidden;

    &:nth-child(2n + 1) {
      background: $offwhite;
      background: linear-gradient(-30deg, $offwhite, $prettymuchwhite);
    }

    .section-inner {
      @extend %flex-row-middle;
      flex-direction: column;
      max-width: 1240px;
      width: calc(100% - 48px);
      margin: 0 auto;
      padding: 15vh 0;

      @include md {
        flex-direction: row;
        padding: 20vh 0;
      }

      .section-panel {
        @extend %flex-col;
        width: 100%;
        min-height: 2px;
        order: 2;

        @include md {
          max-width: 50%;
          width: 50%;
        }

        &:last-child {
          margin-bottom: 24px;

          &.pitch {
            order: 1;
            @include md {
              order: 2;
            }
          }

          @include md {
            margin-left: 24px;
          }
        }

        // Panel 1
        .elite-talent-containers {
          @extend %flex-col;
          margin: 80px 0 0;

          @include md {
            margin: -8vh 0;
          }

          .elite-talent-container {
            @extend %flex-col-center;
            background: white;
            border-radius: 4px;
            padding: 24px;
            text-decoration: none;
            color: $darker;
            min-height: 200px;

            @include md {
              flex-direction: row;
            }

            &:not(:last-child) {
              margin-bottom: 12px;
            }

            img {
              width: calc(100% - 12px);
              height: 200px;
              width: 200px;
              object-fit: contain;
              margin-bottom: 24px;
              border-radius: 4px;

              @include md {
                flex-direction: row;
                margin-bottom: 0;
                margin-right: 24px;
                height: 140px;
                width: 140px;
              }
            }

            .elite-talent-meta {
              .elite-talent-name {
                @extend %btn-text-underline;
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
                color: $darker;
              }
              .elite-talent-desc {
                font-size: 14px;
                margin-top: 6px;
              }
            }
          }
          .see-all-btn {
            @extend %btn-offwhite;
            margin-bottom: 0 auto;
            align-self: center;
            padding: 8px 24px;
          }
        }

        // Panel 2
        img.mac {
          width: 100%;
          margin: 10vh 0 -17vh;

          @include md {
            display: inherit;
            transform: scale(3) translateY(12%) translateX(-4%);
            width: 50%;
            margin: 0;
          }

          @include lg {
            transform: scale(3) translateY(12%) translateX(0);
          }

          @include xl {
            transform: scale(2.4) translateY(14%) translateX(2%);
          }

          @include xxl {
            transform: scale(2.8) translateY(14%) translateX(-2%);
          }
        }

        // Panel 3
        .upsells-container {
          @extend %flex-col;
          margin-top: 88px;
          width: 100%;

          @include md {
            margin-top: 0px;
            align-items: center;
          }

          .upsells {
            .upsell {
              @extend %flex-row-middle;
              font-size: 18px;
              font-weight: bold;
              padding: 8px 0;

              img {
                width: 24px;
                height: 24px;
                margin-top: -2px;
                margin-right: 12px;
                object-fit: contain;
              }
            }
          }
        }

        .section-header {
          font-size: 28px;
          margin: 0 0 12px;
          font-weight: normal;
          letter-spacing: -0.57px;

          .important {
            font-weight: bold;
            text-decoration: underline;
          }
        }

        .section-body {
          font-size: 17px;
          letter-spacing: -0.1px;
        }
      }
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0.3;
  }
  10% {
    opacity: 1;
  }
}
