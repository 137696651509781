@import '../../styles/shared.scss';

.latest-sections-container {
  padding: 0 20px;

  .latest-sections {
    @extend %flex-col;
    gap: 48px;

    @include xl {
      gap: 64px;
    }
  }
}
