@import '../../styles/shared.scss';

.brand-lists-overlay-outer-container {
  position: relative;

  .brand-lists-overlay-fade {
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    opacity: 0;
    transition: opacity 0.28s ease-in-out;
    overflow: auto;

    &.other-modal-open {
      z-index: 51;
    }

    &.visible {
      opacity: 1;
    }
  }

  .brand-lists-container {
    position: fixed;
    background: white;
    border-radius: 16px;
    max-height: 320px;
    min-height: 240px;
    opacity: 0;
    transition: opacity 0.18s ease-in-out, transform 0.12s ease-in-out;
    z-index: 102;
    transform: scale(0.9);
    transform-origin: top right;
    overflow: auto;
    box-shadow: $shadow-dark;

    .toggle-discover-vs-brand-lists {
      @extend %flex-center;
      gap: 12px;
      margin-top: 8px;

      .toggle {
        @extend %btn-basic;
        @extend %label;
        font-size: 10px;
        color: $lightest;
        &.active {
          color: $darkest;
        }
      }
    }

    &.other-modal-open {
      z-index: 52;
    }

    &.visible {
      opacity: 1;
      transform: scale(1);
    }

    .brand-list {
      @extend %flex-row-middle-spread;
      padding: 6px 16px;

      &:first-child {
        padding-top: 22px;
      }
      &:last-child {
        padding-bottom: 22px;
      }

      &.empty {
        background-color: $offwhite;
        box-shadow: inset 0px 0px 10px $lightest;
      }

      &.adding-other {
        opacity: 0.2;
      }

      .main {
        @extend %flex-row-middle;
        .image-container {
          height: 40px;
          width: 40px;
          min-height: 40px;
          min-width: 40px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &.add {
            @extend %flex-center;
            background: $prettymuchwhite;

            svg {
              color: $light;
              font-size: 18px;
              display: none;
            }
          }

          .loader {
            background: $offwhite;
          }
        }
        .data {
          margin-left: 8px;

          .title {
            font-size: 12px;
            color: $darker;
            font-weight: bold;
          }

          input {
            @extend %input-inline;
            background-color: transparent;

            &.title {
              font-size: 14px;
              color: $darker;

              &::placeholder {
                @extend %label;
                font-size: 12px;
                font-weight: normal;
              }
            }
          }

          .meta {
            font-size: 10px;
            color: $dark;

            a {
              color: $darkest;
              font-weight: bold;

              svg {
                margin-left: 2px;
                font-size: 8px;
              }
            }

            .count {
            }
            .bullet {
              margin: 0 2px;
            }
            .last-updated {
              font-size: 8px;
              color: $dark;
            }
            .edit,
            .delete {
              @extend %btn-basic;
            }
          }
        }
      }

      .secondary {
        .action {
          @extend %btn-basic;
          color: $dark;
          padding: 8px 12px;
          border-radius: 4px;
          transition: background 0.1s ease-in-out;

          &:hover {
            background: $lightest;
            color: $darkest;
          }

          &.added {
            padding: 8px 10px 8px 10px;

            &:hover {
              background: $prettymuchwhite;
              color: $darkest;
            }
          }

          &.inactive {
            cursor: default;
          }

          &:not(.inactive, .added) {
            &:hover {
              background: $lightest;
              background: $offwhite;
              color: $darkest;
            }
          }
        }
      }
    }
  }
}
