@import '../../styles/shared.scss';

.catalog-outer-container {
  min-height: 100vh;
  font-family: $font-body-new;
  padding: 75px 0 120px;
  background-color: $prettymuchwhite;

  .coming-soon-text {
    font-size: 16px;
    color: $medium;
    text-align: center;
  }

  .catalog-inner-container {
    width: min(85%, 1240px);
    margin: 0 auto;
    position: relative;

    .catalog-header {
      font-size: 36px;
      font-weight: 600;
      font-family: $font-header;
      margin: 1.5rem 0;
      text-align: center;
    }

    .catalog-sync-container {
      position: absolute;
      top: 0;
      right: 0;

      @include md-down {
        position: relative;
      }

      .catalog-sync-btn {
        font-size: 14px;
        opacity: 1;
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;

        &.disabled {
          cursor: not-allowed;
          opacity: 0.3;
        }

        &.syncing {
          cursor: not-allowed;
          opacity: 0.3;

          svg {
            @extend %animation-spin-with-pause;
          }
        }
      }
    }
  }
}
