@import '../../styles/shared.scss';

.collaborations-potential-tasks-container {
  margin-bottom: 96px;

  @include md-down {
    padding: 0 16px;
  }

  .potential-header-container {
    @extend %flex-center-vertical;
    margin: 24px 0 24px;
    text-align: center;

    @include md-down {
      width: 100%;
      padding: 0 8px;
    }

    .potential-header {
      font-size: 22px;
      text-transform: uppercase;
      font-weight: bold;
      color: $darkest;

      @include md-down {
        font-size: 16px;
      }
    }
    .potential-subheader {
      font-size: 13px;
      color: $dark;
      max-width: 600px;

      @include md-down {
        font-size: 12px;
      }
    }
    .potential-action {
      @extend %btn-basic;
      font-size: 12px;
      margin-top: 8px;
      text-transform: uppercase;
      color: $dark;

      svg {
        margin-right: 4px;
        font-size: 10px;
      }
    }
  }
  .collaborations-potential-tasks {
    @extend %flex-row;
    flex-wrap: wrap;

    .collaborations-potential-task-container {
      width: 50%;
      padding: 4px 4px;

      @include md-down {
        width: 100%;
        padding: 4px 4px 8px;
      }

      .collaborations-potential-task {
        @extend %flex-row-middle-spread;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        padding: 12px 12px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &.inactive {
          &:hover {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.7);
            background: $prettymuchwhite;

            svg {
              color: $darkest;
            }
          }
        }
        &.active {
          &:hover {
            svg {
              color: $darkest;
            }
          }
        }

        .main {
          @extend %flex-row-middle;
          font-size: 16px;
          text-transform: uppercase;
          font-weight: bold;

          .badge {
            font-weight: bold;
            font-size: 10px;
            background: $darker;
            padding: 1px 3px;
            border-radius: 2px;
            color: white;
            margin-left: 8px;
          }

          img {
            width: 20px;
            height: 20px;
            object-fit: contain;
            margin-right: 8px;

            &.youtube {
              width: auto;
              height: 20px;
            }
          }
        }

        .actions {
          @extend %flex-row-middle;
          margin-left: 8px;

          svg {
            transition: all 0.2s ease-in-out;
            margin-left: 8px;
            color: $light;
          }
        }
      }
    }
  }
}
