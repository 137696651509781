@import '../../../styles/shared.scss';

.latest-section.brands {
  @extend %flex-row;
  flex-wrap: wrap;
  gap: 24px;

  .brand {
    @extend %flex-col;
    width: 100%;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    background-color: white;
    cursor: pointer;

    &:hover {
      transform: scale(0.98);
    }

    @include md {
      width: calc(50% - 24px / 2);
    }

    @include xl {
      width: calc(33% - 24px / 2);
    }

    .header-section {
      aspect-ratio: 2;

      img {
        width: 100%;
        height: 260px;
        object-fit: cover;
      }

      .empty {
        width: 100%;
        min-height: 100%;
        background-color: $darkest;
      }
    }

    .body-section {
      @extend %flex-col-spread;
      padding: 16px 24px 24px;
      flex: 1;

      .main {
        .title {
          font-size: 24px;
          font-family: $font-header;
          font-weight: bold;
          color: $darkest;
        }
        .domain {
          font-size: 14px;
          font-weight: bold;
          color: $darkest;
        }
        .description {
          margin-top: 8px;
          font-size: 14px;
          line-height: 20px;
          color: $dark;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
      .action {
        @extend %flex-row-middle;
        @extend %label;
        margin-top: 16px;
        font-size: 12px;

        svg {
          margin-left: 4px;
          font-size: 11px;
        }
      }
    }
  }
}
