@import '../../styles/shared.scss';

.instagram-extension-outer-container {
  .instagram-extension-inner-container {
    box-sizing: border-box;
    padding: 2rem;

    .panel {
      box-sizing: border-box;
      color: black;
      font-size: large;
      font-weight: bold;
      border-radius: 10px;
      background-color: rgb(235, 157, 157);
      padding: 1rem 2rem;
      margin-bottom: 2rem;
      text-align: center;

      &.open {
        background-color: lightgreen;
      }
    }

    .count {
      margin: 1rem 0;
    }

    .users {
      @extend %flex-col;
      gap: 1rem;

      font-size: 12px;
      font-weight: normal;

      .user {
        box-sizing: border-box;
        padding: 0.5rem;
        background-color: $offwhite;
        border-radius: 100px;
        cursor: pointer;
        text-align: center;

        box-shadow: 0 0 0 3px rgba(141, 35, 35, 0.2);
        color: #000;
        text-decoration: none;
        transition: box-shadow;

        &.completed {
          box-shadow: 0 0 0 3px rgba(35, 141, 74, 0.2);
        }
      }
    }

    .buttons {
      margin: 1rem 0;
      box-sizing: border-box;
      padding: 0.5rem;
      background-color: $offwhite;
      border-radius: 100px;
      cursor: pointer;
      text-align: center;
    }
  }
}
