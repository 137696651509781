@import '../../styles/shared.scss';

.brand-promoters-outer-container {
  .brand-promoters {
    @extend %flex-col-center;
    max-width: 360px;

    .badges {
      @extend %flex-row-middle;
      margin-bottom: 4px;
      .badge {
        @extend %animation-fade;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: $offwhite;
        overflow: hidden;

        &:nth-child(1) {
          transform: translateX(8px);
        }
        &:nth-child(2) {
          transform: translateX(0);
        }
        &:nth-child(3) {
          transform: translateX(-16px);
        }
        &:nth-child(4) {
          transform: translateX(-24px);
        }

        &.additional {
          @extend %flex-center;
          background-color: $darkest;
          color: white;
          font-size: 9px;
          font-weight: bold;
          text-transform: uppercase;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .labels {
      @extend %flex-row-middle;
      gap: 4px;
      font-size: 13px;
      color: $dark;
      text-align: center;
      display: inline-block;

      span.promoter {
        cursor: pointer;
        margin-right: 2px;
        font-weight: bold;

        &:hover {
          color: $black;
        }
      }
    }
  }
}
