@import '../../styles/shared.scss';

.earnings-portal-commissions-outer {
  .earnings-portal-commissions-inner {

    .image-container {
      display: grid;
      grid-template-columns: 60px auto;
      align-items: center;
      gap: 7px;
      flex: 1;

      a {
        text-decoration: none;
        color: $darkest;
      }

      .commission-image {
        height: 60px;
        width: 60px;
        object-fit: cover;
        text-align: center;
        background: $darkest;
        border-radius: 5px;
        color: $white;
        font-size: 30px;
        font-family: $font-header;
      }
    }
  }
}
