@import '../../styles/shared.scss';

.collection-post-preview-container.add-new {
  @extend %flex-col;
  background: $darker;
  border: $border-lightest;
  flex: 1;
  cursor: default;
  border-radius: 4px;
  height: 100%;
  margin-bottom: 0;

  &.hovered {
    .add-new-header-cover {
      opacity: 0;
    }
  }

  .add-new-header-cover {
    @extend %flex-center-vertical;
    position: absolute;
    width: 100%;
    height: 100%;
    background: $darker;
    border-radius: 8px;
    top: 0;
    left: 0;
    color: white;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;

    svg {
      font-size: 44px;
      margin-bottom: 12px;
    }

    .label {
      font-size: 16px;
      font-weight: bold;
    }

    @include md-down {
      display: none;
    }
  }

  .add-new-header {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-top: 16px;
    color: $white;

    @include md-down {
      font-size: 12px;
      margin-top: 12px;
    }
  }
  .add-new-types {
    @extend %flex-row;
    flex: 1;
    flex-wrap: wrap;
    padding: 4px 12px 12px;

    @include md-down {
      padding: 4px 6px 6px;
    }

    .add-new-type {
      @extend %flex-center-vertical;
      background: $darker;
      border: $border-white;
      padding: 4px;
      border-radius: 4px;
      margin: 6px;
      width: calc(50% - 12px);
      cursor: pointer;

      @include md-down {
        margin: 4px;
        width: calc(50% - 8px);
      }

      &:hover {
        background: $dark;
      }

      .social-icn,
      svg {
        height: 30px;
        width: 30px;
        margin-bottom: 6px;

        @include md-down {
          height: 26px;
          width: 26px;
          margin-bottom: 2px;
        }
      }
      .social-icn {
        object-fit: contain;
        filter: brightness(0) invert(1);
      }

      svg {
        transform: scale(0.8);
        filter: brightness(0) invert(1);
      }

      .label {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: bold;
        color: white;

        @include md-down {
          font-size: 10px;
        }
      }
    }
  }
}
