@import '../../styles/shared.scss';

.artist-modal-social-mentions-container {
  .mention-results-columns {
    @extend %flex-row;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;

    .mention-results-column {
      @extend %flex-col;
      flex: 1;
      width: calc(25% - 12px);
      gap: 16px;
    }
  }
}
