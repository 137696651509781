@import '../../styles/shared.scss';

.component {
  @extend %flex-col;
  gap: 6px;
  width: 100%;
  align-items: center;

  &:hover .score-bar-footer .marks {
    opacity: 1;
  }

  .component-name {
    font-size: 14px;
    transition: text-decoration 0.2s ease-in-out;
    font-weight: bold;
    margin-right: 4px;
  }

  .chart-footer {
    @extend %flex-col-center;
    width: 100%;

    .chart-wrapper {
      height: 200px;
      width: 80%;
    }
    .chart-filter-options {
      @extend %flex-row-middle;
      gap: 4px;

      .filter-option {
        @extend %btn-new;
        cursor: pointer;
        font-size: 12px;
        border-radius: 8px;
        background-color: $secondary;
        color: $primary;

        &.selected {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }

  .score {
    font-size: 12px;
  }

  .score-icon-container {
    @extend %flex-row-middle;
    gap: 8px;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: $light;
    margin-top: 8px;

    svg {
      font-size: 16px;
    }

    &.completed {
      color: $primary;
    }

    &.highlight {
      color: $error;
    }
  }

  .explainer-container {
    @extend %flex-row-middle-spread;
    width: 100%;
    font-size: 12px;

    .explainer {
      color: $light;
      width: 80%;
    }

    .graph-action {
      color: $primary;
      cursor: pointer;
    }

    @include md-down {
      .explainer {
        display: none;
      }
    }
  }

  .score-bar-footer {
    height: 20px;
    width: 100%;
    margin-top: 24px;

    .marks {
      @extend %flex-row-middle-spread;
      width: 100%;
      position: relative;
      margin-top: 3px;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 1;
      }

      .mark {
        position: relative;
        font-size: 10px;
        line-height: 1;
        color: $light;
        font-weight: bold;

        &.current {
          position: absolute;
          transform: translateX(calc(-50% - 2px));
          bottom: calc(100% + 8px + 8px); // HEIGHT_OF_MARK_LINE_REACHED
          background-color: rgba(255, 255, 255, 0.8);
          color: $white;
          background-color: $primary;
          border-radius: 4px;
          padding: 6px 8px;
          font-size: 12px;

          .mark-line {
            position: absolute;
            height: 6px;
            width: 1px;
            background-color: $primary;
            left: calc(50% - 1px);
            top: 100%;
          }

          &.declined {
            background-color: $error;

            .mark-line {
              background-color: $error;
            }
          }
        }
      }
    }
  }

  .score-bar-container {
    width: 100%;
    margin-left: auto;
    height: 8px;
    background-color: $lightest;
    border-radius: 4px;
    position: relative;

    .score-bar {
      height: 100%;
      background-color: $primary;
      border-radius: 4px;
      position: absolute;
      z-index: 1;

      &.declined {
        background-color: $darkest;
      }

      &.empty {
        background-color: $light;
      }

      &.improved:not(.compare) {
        background-color: $primary;
        z-index: 0;
      }

      &.compare {
        background-color: $error;
        z-index: 0;
      }

      &.improved.compare {
        background-color: $darkest;
        z-index: 1;
      }
    }
  }
}
