@import '../../styles/shared.scss';

.manage-budget-panel-container {
  @extend %flex-row-spread;
  background-color: white;
  width: 100%;
  padding: 48px;
  border-radius: 10px;

  .main {
    @extend %flex-row-middle;
    gap: 24px;

    .data-point {
      .badge {
        @extend %label;
        font-size: 10px;
        margin-bottom: 10px;
      }
      .value {
        font-size: 24px;
        color: $darkest;
        font-weight: 500;

        &.negative {
          color: $error;
        }
      }
    }
  }

  .add-btn {
    @extend %label;
    @extend %btn-primary;
    font-size: 12px;
    padding: 12px 20px;
    margin-top: 20px;
  }
}
