@import '../../../styles/shared.scss';

.recommendation-results-container {
  @extend %flex-col;
  gap: 16px;
  padding-bottom: 120px;

  .recommendation-results-skeleton {
    @extend %skeleton-box;
    height: 240px;
    border-radius: 10px;
  }

  .empty-results {
    @extend %flex-row-center;
  }
}
