@import '../../styles/shared.scss';

.opportunity-outer-container {
  font-family: $font-body-new;
  padding: 96px 0 120px;
  width: 100%;
  min-height: 100vh;
  background-color: $prettymuchwhite;

  @include md-down {
    padding: 0 0 120px;
  }

  .opportunity-inner-container {
    width: min(85%, 1024px);
    margin: 0 auto;

    @include md-down {
      width: 100%;
    }

    .no-permission-container {
      @extend %flex-center;
      background-color: white;
      padding: 48px;
      border-radius: 8px;
      color: $light;
      font-size: 14px;
      min-height: 200px;
    }
  }
}
