@import '../../../styles/shared.scss';

.request-result-outer {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-color: white;

  &.talentIsNotInterested {
    opacity: 0.5;
  }

  &.cannot-access {
    pointer-events: none;
    filter: blur(2px);
  }

  .request-result-inner {
    position: relative;
    height: 100%;

    &:hover.large-form {
      .action-icons {
        background-color: rgba(0, 0, 0, 0.07);
      }
    }

    &.small-form {
      @extend %flex-col;
      padding: 16px;
      gap: 8px;
      border: 1px solid white;

      &:hover {
        border: 1px solid $lightest;
        border-radius: 10px;
      }

      .main-information {
        @extend %flex-row;
        gap: 10px;

        transition: all 0.3s ease-out;
        cursor: pointer;

        .small-form-image-container {
          position: relative;
          @extend %flex-col;
          align-items: center;

          img {
            width: 70px;
            height: 70px;
            object-fit: cover;
            border-radius: 10px;
          }

          .no-image {
            width: 70px;
            height: 70px;
            border-radius: 10px;
            display: grid;
            place-content: center;
            font-family: $font-header;
            font-size: 1.2rem;
            font-weight: 600;
            color: white;
            background-color: $dark;
          }
        }

        .small-form-text-container {
          @extend %flex-col;
          padding: 10px;
          justify-content: center;
          width: 100%;
          overflow: hidden;

          .request-result-header {
            font-family: $font-header;
            font-size: 1.1rem;
            font-weight: 600;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .request-result-subheader {
            @extend %flex-row;
            gap: 5px;
            font-size: 0.7rem;
            text-wrap: wrap;
            color: $medium;

            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;

            a {
              color: $light;
            }
          }
        }
      }

      .small-form-tracking-information {
        @extend %btn-basic;
        @extend %flex-row;
        justify-content: space-between;
        gap: 10px;
        text-decoration: underline;
        font-size: 0.8rem;
        position: relative;

        span {
          max-width: 40%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        svg {
          height: 10px;
          width: 10px;
          margin-left: 5px;
        }

        a {
          @extend %label;
          color: $medium;
          font-size: 0.7rem;
        }
      }

      &:hover {
        .action-icons .action-icon {
          opacity: 1 !important;
        }
      }

      .action-icons {
        @extend %flex-row-middle;
        gap: 8px;
        position: absolute;
        top: 4px;
        right: 4px;
        padding: 8px;
        border-radius: 4px;

        .tooltip-outer-container {
          @extend %flex-center;
        }

        .action-icon {
          @extend %btn-basic;
          font-size: 15px;
          color: $darkest;
          transition: opacity 0.2s ease-in-out;
          opacity: 0.15;

          &.expiration {
            color: $darkest;
            font-size: 13px;
            transform: translateY(-1px);

            &.active {
              color: $darkest;
              opacity: 1;
            }
          }
        }
      }
    }

    &.large-form {
      @extend %flex-col;

      .large-form-image-container {
        position: relative;
        width: 100%;

        img {
          width: 100%;
          height: 250px;
          object-fit: cover;
        }

        .no-image {
          width: 100%;
          height: 250px;
          display: grid;
          place-content: center;
          font-family: $font-header;
          font-size: 3rem;
          font-weight: 600;
          color: white;
          background-color: $dark;
        }

        .action-icons {
          @extend %flex-row;
          gap: 8px;
          position: absolute;
          top: 4px;
          right: 4px;
          padding: 8px;
          transition: all 0.2s ease-in-out;
          border-radius: 4px;

          svg {
            @extend %btn-basic;
            font-size: 15px;
            color: white;

            &:hover {
              color: $lightest;
            }
          }
        }

        .large-form-image-container-text {
          cursor: pointer;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 64px 16px 16px;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
          color: white;

          .request-result-header {
            font-family: $font-header;
            font-size: 26px;
            font-weight: 600;
          }

          .request-result-subheader {
            @extend %flex-row;
            gap: 4px;
            font-size: 12px;

            a {
              color: white;
            }
          }
        }
      }

      .large-form-order-details-container {
        position: relative;
        @extend %flex-col;
        gap: 20px;
        padding: 20px;
        flex: 1;
        justify-content: space-between;

        > div {
          @extend %flex-col;
          gap: 20px;
        }

        .address-container {
          @extend %flex-row;
          gap: 20px;
          position: relative;
          padding: 20px;
          border-radius: 10px;
          border: $border-lightest;
          font-size: 0.8rem;

          .address-header {
            color: $light;
          }

          svg {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 12px;
            height: 12px;
            color: $light;
            transition: color 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
              color: $dark;
            }
          }
        }

        .general-gifting-request-message {
          display: block;
          font-size: 0.8rem;
          color: $light;
          font-style: italic;
        }

        .lookbook-order-items-container {
          @extend %flex-col;
          gap: 16px;

          .see-all-items-button {
            @extend %label;
            @extend %btn-basic;
            width: 100%;
            display: grid;
            place-content: center;
            color: $light;
            padding: 10px 0;
            font-size: 0.7rem;
          }

          .lookbook-order-item {
            @extend %flex-row;
            gap: 15px;

            img {
              height: 50px;
              width: 50px;
              border-radius: 5px;
            }

            .no-image {
              @extend %label;
              width: 50px;
              height: 50px;
              display: grid;
              place-content: center;
              background-color: $lightest;
              color: $light;
              border-radius: 5px;
              pointer-events: none;
            }

            .lookbook-order-item-text {
              @extend %flex-col;
              justify-content: center;

              .lookbook-order-item-title {
                @extend %flex-row;
                font-size: 14px;
                line-height: 16px;
                font-weight: 500;
                margin-bottom: 2px;

                a {
                  @extend %flex-row;
                  align-items: center;

                  svg {
                    @extend %btn-basic;
                    width: 8px;
                    height: 8px;
                    color: $light;
                    margin-left: 5px;
                  }
                }
              }

              .lookbook-order-item-subtitle {
                font-size: 10px;
                color: $light;
              }
            }
          }
        }

        .order-details-button {
          @extend %btn-basic;
          @extend %label;
          width: 100%;
          padding: 14px 16px;
          border-radius: 5px;
          background-color: $primary;
          color: white;
          border: none;
          outline: none;
          font-size: 0.7rem;
          position: sticky;
          bottom: 0;
          cursor: not-allowed;

          &.disabled {
            background-color: $lightest;
            color: $medium;
          }

          &.clickable {
            cursor: pointer;
          }
        }
      }
    }
  }
}
