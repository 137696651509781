@import '../../styles/shared.scss';

.date-selector {
  input {
    border: none;
  }

  // Styles for SingleDatePicker
  .SingleDatePicker,
  .DateRangePicker {
    align-self: stretch;
    border-radius: 4px;

    .SingleDatePickerInput__withBorder,
    .DateRangePickerInput__withBorder {
      border: none;
    }

    .SingleDatePickerInput,
    .DateRangePickerInput {
      padding-left: 4px;

      .DateInput {
        width: 164px;

        input {
          @extend %input-shared;
          font-family: $font-body;
          font-size: 14px;
          height: 100%;
          margin-top: 0;
          border-radius: 8px;
          padding: 16px 12px;
          text-align: center;
        }
      }

      .DateInput_input__focused {
        border-color: $darker;
      }
    }

    .SingleDatePickerInput_arrow_svg,
    .DateRangePickerInput_arrow_svg {
      width: 16px;
      height: 16px;
      margin-top: -3px;
    }
  }

  .DateRangePickerInput_arrow {
    margin: 0 8px;
  }

  // Shared calendar day styles for SingleDatePicker and DateRangePicker
  .CalendarDay__selected_span,
  .CalendarDay__selected {
    background: $darkest;
    color: white;
    border: $border-darkest;
  }

  .CalendarDay__selected {
    background: $darker;
    border-color: $darkest;
    color: white;
  }

  .CalendarDay__selected:hover,
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: $medium;
    color: white;
  }

  // Additional hovered styles for DateRangePicker
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: $darker;
    border-color: $darker;
    color: white;
  }
}
