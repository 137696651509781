@import '../../styles/shared.scss';

.catalog-product-modal {
  width: min-content !important;

  &.loading {
    pointer-events: none;

    .modal-inner-container {
      @extend %flex-center;
      background: transparent !important;

      .loader {
        height: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }

  .catalog-product-modal-content {
    padding-top: 40px;
    display: flex;
    gap: 40px;
    height: 100%;
  }

  .variant-info-container {
    width: 275px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;

    .variant-image {
      height: 220px;
      width: 220px;
      border-radius: 10px;

      img {
        border-radius: 10px;
        transition: transform 0.25s ease-in-out;
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1;
      }
    }

    .variant-meta {
      text-align: center;
      font-size: 12px;
      color: $darkest;
      display: flex;
      flex-direction: column;
      width: 100%;

      .variant-title {
        margin-top: 6px;
        font-size: 14px;
        font-weight: bold;
      }

      .variant-info {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 6px;

        .variant-external-link-container {
          margin-left: 4px;

          .variant-domain {
            text-decoration: underline;
            color: $primary;
          }

          .variant-external-link {
            color: $darkest;
            font-size: 8px;
          }
        }
      }
    }
  }

  .rate-display-container span {
    margin-left: 4px;
    font-family: $font-header;
    font-size: 22px;
  }

  .rate-explainer {
    margin-top: 14px;
    font-size: 10px;
    justify-self: center;
    text-align: center;
    width: 220px;

    span {
      font-weight: bold;
      margin: 0 2px;
    }
  }

  .variant-rate-container-outer {
    width: 100%;
  }

  .variant-rate-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;

    .rate-display-container {
      display: flex;
      align-items: center;
      cursor: default;
      height: 50px;
      text-wrap: nowrap;
      border: 2px solid $lighter;
      border-radius: 10px;
      padding: 4px 8px;

      &.active {
        border: 2px solid $link-blue;
      }

      input {
        @extend %input-shared;
        font-family: $font-header;
        text-align: right;
        border: none;
        padding-left: 0;
        padding-right: 0;
        max-width: 48px;
        height: 100%;
        font-size: 22px;
        color: $black;

        &:focus {
          border: none;
        }

        &::placeholder {
          color: $lighter;
        }

        &:disabled {
          background-color: $white;
        }
      }
    }

    .clear-rate {
      cursor: pointer;
      letter-spacing: 1px;
      font-weight: bold;
      font-size: 12px;
      text-decoration: underline;
      text-transform: uppercase;

      &.disabled {
        cursor: default;
        color: $lighter;
      }
    }
  }

  .sibling-rate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
