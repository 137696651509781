@import '../../styles/shared.scss';

.home-outer-container {
  font-family: $font-body;

  .inner-section {
    max-width: 1024px;
  }

  .standard-home-header {
    font-family: $font-header;
    line-height: 1.3;
    margin: 0;
    font-weight: 300;
    text-align: center;
    transition: all 0.2s ease-in-out;
    margin-bottom: 60px;
    max-width: calc(100vw - 48px);

    @include sm-down {
      font-size: 32px;
    }

    @include sm {
      font-size: 40px;
    }

    @include md {
      font-size: 48px;
    }

    @include lg {
      font-size: 50px;
    }
    @include xl {
      font-size: 56px;
      max-width: 940px;
      margin-bottom: 80px;
    }

    @include xxl {
      font-size: 64px;
      max-width: 1024px;
    }
  }
}
