@import '../../styles/shared.scss';

.task-edit-outer-modal {
  max-width: 640px !important;

  .task-edit-picker-modal {
    padding: 24px;
    .title-container {
      color: $dark;
      .title {
        font-weight: bold;
        font-size: 20px;
        .primary {
        }
        .secondary {
        }
        .badge {
          font-style: italic;
          font-weight: normal;
          color: $lighter;
          &.set {
            font-weight: bold;
            font-style: normal;
            color: $dark;
          }
        }

        .bullet {
          margin: 0 8px;
          color: $lightest;
        }
      }
      .subtitle {
        margin-top: 4px;
        font-size: 13px;
      }
    }
  }

  .section {
    @extend %flex-col;
    margin-top: 24px;
    .header {
      margin-bottom: 4px;
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
    }
    .subheader {
      font-size: 12px;
      margin-bottom: 4px;
      letter-spacing: 0.1px;
    }
    input.price-input {
      align-self: flex-start;
      @extend %input-shared;
    }

    .toggle {
      @extend %flex-row-middle;
      align-self: flex-start;
      border-radius: 4px;
      background: $offwhite;
      color: $dark;
      border-radius: 4px;
      font-weight: bold;
      font-size: 14px;
      margin-top: 4px;
      overflow: hidden;
      color: $lighter;

      .option {
        padding: 4px 12px;
        flex: 1;
        cursor: pointer;

        &:hover {
          color: $medium;
        }

        &.selected {
          background: $dark;
          color: white;
          pointer-events: none;
        }
      }
    }

    .table {
      background: $offwhite;
      border-radius: 8px;
      overflow: hidden;
      margin-top: 12px;

      .row {
        @extend %flex-row-middle;
        padding: 8px 16px;
        border-bottom: $border-lightest;

        &:last-child {
          padding-bottom: 16px;
          border-bottom: none;
        }

        &.header-row {
          background: $lightest;
          font-weight: bold;
        }
        .cell {
          flex: 1;
          font-size: 14px;
        }
      }
    }
  }

  .footer {
    @extend %flex-row-middle;
    padding: 8px 16px;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .footer-action {
      position: relative;
      &.cancel {
        @extend %btn-text-new;
      }

      &.save {
        @extend %btn-lightest-new;

        &.update-needed {
          @extend %btn-darker-new;
        }
      }

      &.saving {
        color: transparent;
      }

      .loader {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translateY(-4px);
        pointer-events: none;
      }
    }
  }
}
