@import '../../styles/shared.scss';

.modal-outer-container .modal-container {
  &.opportunity-setup-guide-inner {
    align-items: flex-start;
    max-width: 1024px;
    max-height: 80vh;
  }
  .modal-inner-container {
    .modal-content.opportunity-setup-guide-content {
      @extend %flex-row;
      padding: 36px;
      min-height: 80vh;
      max-height: 80vh;

      @include md-down {
        padding: 24px;
        min-height: 100vh;
        max-height: 100vh;
      }

      .sidebar-container {
        width: 240px;
        min-width: 240px;
        min-height: 100%;
        overflow-y: auto;
        padding: 18px 24px 18px 18px;
        border-right: $border-lightest;
      }

      .main-container {
        position: relative;
        flex: 1;
        min-height: 100%;
        min-width: 200px;

        .body-container {
          overflow-y: auto;
          padding: 54px 54px 120px 70px;
          max-height: 100%;
        }

        .footer-container {
          @extend %flex-row;
          width: 100%;
          position: absolute;
          justify-content: flex-end;
          bottom: 0;
          right: 0;
        }
      }

      .close-icn {
        @extend %btn-basic;
        position: absolute;
        top: 36px;
        right: 36px;
        cursor: pointer;

        svg {
          font-size: 24px;
        }
        z-index: 2;
      }
    }
  }
}
