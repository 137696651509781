@import '../../styles/shared.scss';

.bulk-talent-modal {
  max-width: 1024px !important;
  top: auto !important;
  height: 80vh !important;
  max-height: 800px !important;

  @include md-down {
    flex-direction: column;
    height: 100vh !important;
    max-height: 100vh !important;
  }

  .bulk-talent-inner-modal {
    @extend %flex-row;
    padding: 0 !important;
    flex-direction: row !important;
    overflow: hidden !important;
    border-radius: 10px;

    @include md-down {
      flex-direction: column !important;
      overflow: auto !important;
    }

    .sidebar-container {
      flex: 1;
      overflow: auto;
      max-width: 420px;
      min-width: 420px;
      width: 420px;
      background: $offwhite;
      padding: 24px;

      @include md-down {
        overflow: visible;
        flex: auto;
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        max-height: 50vh;
      }
    }

    .main-container {
      @extend %flex-col;
      position: relative;
      flex: 1;

      .main-body {
        overflow-y: auto;
        padding: 24px;
        flex: 1;
      }

      .main-footer {
        @extend %flex-row-middle;
        justify-content: flex-end;
        padding: 24px 16px;
      }
    }
  }

  .search-select,
  .list-select,
  .template-select,
  .variable-select,
  .outreach-select {
    flex: 1;

    [class*='select__control'] {
      box-shadow: none;
      border-color: $offwhite !important;

      &:hover {
        border-color: $darkest !important;
      }

      &:active {
        border-color: $darkest !important;
      }
    }

    [class*='select__placeholder'] {
      @extend %label;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
    }

    [class*='select__single-value'] {
      @extend %label;
      text-transform: uppercase;
      font-weight: bold;
      color: $darkest;
      font-size: 12px;
    }
    .search-select__indicators {
      display: none;
    }
  }
}
