@import '../../../styles/shared.scss';

.home-header-step-outer-container {
  @extend %flex-col;
  justify-content: center;
  background-color: $whynotuseblack;
  min-height: 100vh;
  padding-top: 140px; // PADDINGTOP

  &.white-background {
    background-color: $white;
    color: $darkest;
  }

  .home-header-step-inner-container {
    width: 100%;

    .main-content {
      @extend %flex-col-center;
      justify-content: center;
      max-width: 1240px;
      margin: 0 auto;
      position: relative;
      min-height: calc(60vh - 140px); // RATIO PADDINGTOP

      // Match this to the header
      @include md-down {
      }

      @include md {
      }

      @include lg {
      }

      @include xl {
      }

      @include xxl {
      }

      .step-numbers {
        @extend %flex-row-middle;

        @include md-down {
          margin-bottom: 40px;
          gap: 16px;
        }

        @include md {
          margin-bottom: 40px;
          gap: 24px;
        }

        @include lg {
          margin-bottom: 54px;
          gap: 24px;
        }

        @include xl {
          margin-bottom: 64px;
          gap: 32px;
        }

        @include xxl {
          margin-bottom: 80px;
          gap: 32px;
        }

        .step-number {
          @extend %flex-center;
          height: 28px;
          width: 28px;
          border-radius: 50%;
          border: $border-white;
          color: $white;
          opacity: 0.35;
          transition: opacity 0.2s ease-in-out;
          text-decoration: none;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }

          &.active {
            opacity: 1;
          }

          &.white-background {
            border: $border-darkest;
            color: $darkest;
          }

          .number {
            font-size: 14px;
            font-weight: bold;
            text-decoration: none;
          }
        }
      }

      .step-arrows {
        @extend %flex-row-middle-spread;
        position: absolute;
        width: 100vw;
        height: 100%;
        padding: 0 24px;
        top: 0;
        left: 0;
        pointer-events: none;

        @include xl-down {
          display: none;
        }

        @include xl {
          transform: translateX(calc(1240px / 2 - 100vw / 2));
          padding: 0 24px;
        }
        @include xxl {
          transform: translateX(calc(1240px / 2 - 100vw / 2));
          padding: 0 48px;
        }

        .step-arrow {
          .link {
            @extend %flex-row-middle;
            gap: 8px;
            pointer-events: auto;
            text-decoration: none;
            color: $white;

            &.white-background {
              color: $darkest;
            }

            &.left {
              img {
                transform: rotate(180deg);
              }
            }

            img {
              width: 24px;
              height: 24px;
              object-fit: contain;
            }

            .text {
              @extend %label;
              font-size: 16px;
              pointer-events: none;
            }
          }
        }
      }
    }

    .header-container {
      @extend %flex-col-center;
      color: white;
      width: 100vw;
      padding: 0 24px;

      &.white-background {
        color: $darkest;
      }

      &.header-longform {
        .header {
          text-transform: none;
          font-weight: 500;
          text-align: center;

          @include md-down {
            font-size: 40px;
            margin-bottom: 20px;
          }

          @include md {
            font-size: 50px;
            margin-bottom: 20px;
          }

          @include lg {
            font-size: 72px;
            margin-bottom: 20px;
          }
          @include xl {
            font-size: 80px;
            margin-bottom: 24px;
          }
          @include xxl {
            font-size: 96px;
            margin-bottom: 24px;
          }
        }

        .subheader {
          max-width: 1024px;
          @include md-down {
            font-size: 20px;
          }

          @include md {
            font-size: 20px;
          }

          @include lg {
            font-size: 28px;
          }
          @include xl {
            font-size: 28px;
          }
          @include xxl {
            font-size: 32px;
          }
        }
      }

      .header {
        margin: 0;
        font-family: $font-header;
        font-weight: 400;
        text-transform: uppercase;

        @include md-down {
          font-size: 40px;
          margin-bottom: 12px;
        }

        @include md {
          font-size: 80px;
          margin-bottom: 12px;
        }

        @include lg {
          font-size: 92px;
          margin-bottom: 12px;
        }
        @include xl {
          font-size: 112px;
          margin-bottom: 12px;
        }
        @include xxl {
          font-size: 128px;
          margin-bottom: 12px;
        }
      }

      .subheader {
        margin: 0;
        font-weight: 300;
        text-align: center;

        @include md-down {
          font-size: 18px;
          max-width: 80vw;
        }

        @include md {
          font-size: 24px;
        }

        @include lg {
          font-size: 28px;
        }
        @include xl {
          font-size: 28px;
        }
        @include xxl {
          font-size: 32px;
        }
      }
    }

    .hero-container {
      width: 100vw;
      overflow: hidden;

      .hero-image-container {
        .hero-image {
          @extend %animation-fade;
          object-fit: cover;
          width: 100%;
          min-width: 640px;
          min-height: 40vh; // RATIO

          @include md-down {
            // min-height: 0px;
          }
        }
      }
    }
  }
}
