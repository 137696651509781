@import '../../styles/shared.scss';

.discover-sidebar {
  @extend %flex-col;
  align-items: flex-end;
  color: white;
  max-width: 320px;
  min-width: 240px;
  position: relative;

  .discover-groups {
    @extend %flex-col;
    align-items: flex-end;
  }

  .go-to-search-mode-btn {
    @extend %flex-row-middle-spread;
    @extend %btn-basic;
    padding: 8px 12px;
    margin-bottom: 8px;
    margin-right: 12px;
    background: $darker;
    border-radius: 4px;
    min-width: 220px;

    .label {
      // @extend %label;
      font-size: 14px;
      color: $light;
    }

    .icon {
      font-size: 13px;
      margin-left: 8px;
    }
  }

  .back-from-search-mode-icn {
    @extend %flex-row-middle;
    @extend %btn-basic;
    @extend %label;
    font-size: 10px;
    position: absolute;
    bottom: 100%;
    left: 0;
    margin-bottom: 20px;

    svg {
      margin-right: 4px;
    }
  }

  .search-container {
    @extend %flex-row-middle-spread;
    width: 100%;
    margin-bottom: 24px;
    position: relative;

    input {
      @extend %input-inline;
      flex: 1;
      color: white;
      background-color: transparent;
      background-color: $darker;
      padding: 8px 12px;
      border: $border-darker;

      &:focus {
        border: $border-white;
      }
    }

    .search-options {
      @extend %flex-center;
      position: absolute;
      padding: 8px;
      right: 0;
      top: 0;
      height: 100%;
      font-size: 12px;

      &.searching {
        .icon {
          opacity: 0.3;
        }
      }

      .icon {
        @extend %btn-basic;
        transition: opacity 0.2s ease-in-out;
      }
    }
  }

  .admin-section {
    @extend %flex-col;
    align-items: flex-end;
    margin: 64px 12px 0;
    padding: 8px 20px;
    background-color: $darker;
    border-radius: 8px;
    gap: 12px;

    .add-new {
      @extend %btn-basic;
      @extend %label;
      font-size: 10px;
    }

    .create-new {
      @extend %btn-label;
      border: $border-white;
      color: white;
      padding: 4px 12px;
    }

    .edit-toggle {
      @extend %flex-row-middle;

      .label {
        @extend %label;
        font-size: 10px;
        margin-right: 8px;
      }
    }
  }
}
