@import '../../styles/shared.scss';

.mention-filters-outer-container {
  display: none;
  position: relative;

  @include md {
    margin-right: 16px;
    width: 240px;
    display: flex;
  }
  @include lg {
    margin-right: 24px;
    width: 260px;
  }
  @include xl {
    margin-right: 32px;
    width: 280px;
  }
  @include xxl {
    margin-right: 32px;
    width: 300px;
  }

  .mentions-filter-search-input-container {
    @extend %flex-row;
    position: absolute;
    width: 100%;
    bottom: 100%;
    right: 0;
    margin-bottom: 18px; // Keep this in sync with MentionControls.sccs

    .search-input {
      @extend %input-shared;
      flex: 1;
      font-size: 12px;
    }
  }

  .mention-filters-inner-container {
    width: 100%;

    .sections {
      @extend %flex-col;
      gap: 12px;

      .section {
        background: #ffffff;
        padding: 24px;
        border-radius: 12px;
        position: relative;

        &.empty {
          font-size: 11px;
          text-align: center;
          font-style: italic;
          color: $medium;
        }

        .section-title-container {
          margin-bottom: 4px;
          .section-title {
            @extend %flex-row-middle;
            @extend %label;
            font-size: 12px;

            .edit-btn {
              @extend %btn-basic;
              padding-left: 4px;
              svg {
                font-size: 11px;
              }
            }
          }

          .section-subtitle {
            margin-bottom: 8px;
            font-size: 9px;
            color: $dark;

            .open-modal-btn {
              @extend %btn-basic;
              font-weight: bold;
              text-decoration: underline;
            }
          }
        }

        .values {
          @extend %flex-col;
          gap: 2px;

          .value {
            @extend %flex-row-middle-spread;
            @extend %btn-basic;

            .display {
              font-weight: normal;
              font-size: 12px;
              color: $dark;
              margin-right: 12px;
              overflow: hidden;
              text-overflow: ellipsis;

              &.selected {
                color: $darkest;
                font-weight: bold;
              }
              &.another-selected {
                color: $lighter;
              }
              svg {
                margin-left: 4px;
              }
            }

            .count {
              padding: 2px 8px;
              border-radius: 24px;
              font-size: 10px;
              font-weight: bold;
              background: $offwhite;
              color: $darker;
              position: relative;

              &.selected {
                background: $darkest;
                color: $white;
              }

              &.alert {
                background: $primary;
                color: white;
              }

              &.another-selected {
                background: $offwhite;
                color: $lighter;
              }

              &.fetching {
                color: transparent;
                background-color: transparent;
                max-width: 12px;

                .loader {
                  position: absolute;
                  top: 0;
                  right: 0;
                  transform: translateY(-3px);
                }
              }
            }
          }
        }

        .toggle-num-visible {
          @extend %flex-center;
          gap: 12px;
          margin-top: 8px;

          .toggle {
            @extend %btn-basic;
            font-size: 10px;
            text-align: center;
            color: $light;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
