@import '../../styles/shared.scss';

.inline-rate-container {
  .input-rate-container {
    display: flex;
    cursor: default;
    align-items: center;
    border-radius: 10px;

    input {
      @extend %input-shared;
      font-family: $font-header;
      text-align: right;
      border: none;
      padding-left: 0;
      padding-right: 0;
      max-width: 48px;
      height: 100%;
      font-size: 22px;
      color: $black;
      border-radius: 10px;

      &:focus {
        border: none;
      }

      &::placeholder {
        color: $lighter;
      }

      &:disabled {
        background-color: $white;
      }
    }
  }

  .input-rate-container span {
    margin-left: 2px;
    font-family: $font-header;
    font-size: 22px;
  }

  .input-rate-action {
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 12px;
    text-decoration: underline;
    text-transform: uppercase;

    &.disabled {
      cursor: default;
      color: $lighter;
    }
  }
}
