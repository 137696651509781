@import '../../styles/shared.scss';

.profile-header-alert-outer-container {
  position: relative;
  background: $darkest;
  padding: 64px 0;
  color: white;

  .profile-header-alert-inner-container {
    @extend %flex-col-center;
    width: 90%;
    margin: 0 auto;
    max-width: 600px;

    .header {
      @extend %header-fancy-md;
      margin-bottom: 4px;
      text-align: center;
    }

    .subheader {
      @extend %paragraph-md;
      text-align: center;
    }

    .login-btn {
      @extend %btn-white-new;
      background: transparent;
      color: white;
      margin-top: 24px;
      text-decoration: none;
      padding: 12px 32px;
      text-transform: uppercase;
      border-radius: 4px;
    }

    .close-btn {
      @extend %btn-basic;
      position: absolute;
      top: 12px;
      right: 12px;
      font-size: 24px;
    }
  }
}
