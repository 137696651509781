@import '../../styles/shared.scss';

.result-pin-outer-container {
  position: relative;
  padding-bottom: 24px;

  &.empty {
    .result-pin-container {
      .product-container {
        .image-container {
          cursor: pointer;
          &:hover {
            svg {
              opacity: 0.4 !important;
              transform: scale(1.1);
            }
          }
        }
      }
      .data-container {
        color: $lighter;
        opacity: 0.3;
      }
    }
  }

  &.odd {
    .result-pin-container {
      @include md {
        .product-container {
          order: 2;
          align-items: flex-end;
        }
        .data-container {
          order: 1;
        }
      }
    }
  }

  .result-pin-container {
    @extend %flex-row-middle-spread;
    padding-bottom: 12px;
    flex-direction: column;
    background: white;
    border-radius: 10px;
    border: $border-lightest;

    @include md {
      flex-direction: row;
      background: transparent;
      border: $border-transparent;
    }

    &:hover {
      .control {
        svg {
          opacity: 0.8 !important;
          transform: scale(1) !important;
        }
      }
    }

    .product-container {
      @extend %flex-col;
      flex: 1;
      align-items: flex-start;
      position: relative;

      .image-container {
        @extend %flex-center;
        width: 220px;
        height: 220px;
        border-radius: 10px;
        background: white;
        position: relative;
        border: $border-transparent;

        @include md {
          border: $border-lightest;
        }

        a {
          @extend %flex-center;
          width: 100%;
          height: 100%;
        }

        &:hover {
          img {
            transform: translateY(-2px);
          }
          .empty-image {
            svg {
              transform: translateY(-16px) scale(1.2);
            }
          }
          .add-product-msg {
            transform: translateY(32px) scale(1.2);
          }
        }

        .empty-image,
        img {
          @extend %flex-center;
          width: calc(100% - 16px);
          height: calc(100% - 16px);
          padding: 8px;
          margin: 0 auto;

          object-fit: contain;
          transition: all 0.2s ease-in-out;

          svg {
            font-size: 64px;
            color: $lighter;
            opacity: 0.15;
            transform: translateY(-12px);
            transition: all 0.2s ease-in-out;
          }
        }

        .add-product-msg {
          position: absolute;
          width: 100%;
          text-align: center;
          text-transform: uppercase;
          font-size: 12px;
          color: $lighter;
          transform: translateY(32px);
          transition: transform 0.2s ease-in-out;
        }
      }

      .controls {
        @extend %flex-row;
        position: absolute;
        bottom: 4px;
        right: 4px;

        .control {
          margin-left: 4px;

          svg {
            margin-left: 6px;
            font-size: 18px;
            color: $medium;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            @include md {
              opacity: 0;
              transform: scale(0);
            }
          }
        }
      }
    }

    .data-container {
      @extend %flex-col-middle;
      flex: 1;
      padding: 24px;
      border-radius: 10px;
      min-height: 80px;
      background: white;
      width: 100%;
      border: $border-transparent;

      @include md {
        border: $border-lightest;
        min-width: 400px;
      }

      &:hover {
        a {
          text-decoration: underline;
        }
      }

      a {
        text-decoration: none;
        color: $darker;
      }

      .title {
        font-size: 18px;
        font-weight: bold;
        margin: 0 0 12px;
        line-height: 1;
      }

      .description {
        font-size: 13px;
        margin: 0;
        font-weight: normal;
        white-space: pre-line;
      }

      .shop-now-btn {
        @extend %btn-offwhite;
        align-self: stretch;
        margin: 32px 0 0;
        padding: 10px 0;
        z-index: 2;
        font-weight: bold;
        border-top: $border-offwhite;

        @include sm {
          display: none;
        }
      }

      textarea {
        @extend %input-inline;
        resize: none;
        width: 100%;
        padding: 0;
        width: 100%;
      }
    }
  }

  &:hover {
    .action-els {
      opacity: 1;
      transform: scale(1);
    }
  }

  .action-els {
    @extend %flex-row-middle;
    position: absolute;
    top: 6px;
    right: 6px;
    transition: all 0.2s ease-in-out;

    @include md {
      opacity: 0;
      transform: scale(0);
    }
  }
}
