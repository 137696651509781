@import '../../styles/shared.scss';

.bulk-request-modal-search-results-outer {
  @extend %flex-col;
  gap: 20px;
  opacity: 0;
  height: 0;
  // transition-delay: 0.3s;
  padding: 0;
  pointer-events: none;

  &.isVisible {
    opacity: 1;
    height: max-content;
    padding: 14px 16px;
    transition: opacity 0.3s ease-out, height 0.3s ease-out, padding 0.3s ease-out;
    pointer-events: all;
  }

  &.isSearching {
    .bulk-request-modal-search-results-container {
      opacity: 0.5;
    }
  }

  .bulk-request-modal-search-results-container {
    transition: opacity 0.3s ease-out;

    .bulk-request-modal-search-results-title {
      margin-bottom: 15px;
      @extend %label;
      font-size: 0.8rem;
    }

    .bulk-request-modal-search-results {
      @extend %flex-row;
      gap: 10px;
      flex-wrap: wrap;

      .bulk-request-modal-search-list-result {
        @extend %flex-row;
        gap: 12px;
        padding: 0 10px;
        border-radius: 5px;
        overflow: hidden;
        height: 45px;
        width: max-content;
        align-items: center;
        background-color: white;
        color: black;
        cursor: pointer;

        .list-result-no-image {
          display: grid;
          place-items: center;
          height: 100%;
          aspect-ratio: 1;
          background-color: $dark;
          font-family: $font-header;
          font-weight: bold;
          font-size: 1.2rem;
          color: white;
        }

        .list-result-title {
          @extend %label;
          font-size: 0.8rem;
        }

        .list-result-item-count {
          font-size: 0.6rem;
          color: $medium;
        }
      }

      .bulk-request-modal-search-talent-result {
        // @extend %btn-basic;
        cursor: pointer;
        @extend %flex-row;
        gap: 12px;
        padding-right: 10px;
        border-radius: 5px;
        overflow: hidden;
        height: 60px;
        width: max-content;
        align-items: center;
        background-color: white;
        color: black;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        &.off-platform {
          padding-left: 10px;
        }

        img {
          height: 100%;
          aspect-ratio: 1;
          object-fit: cover;
        }

        .talent-result-no-image {
          display: grid;
          place-items: center;
          height: 100%;
          aspect-ratio: 1;
          background-color: $dark;
          font-family: $font-header;
          font-weight: bold;
          font-size: 1.2rem;
          color: white;
        }

        .talent-result-name {
          @extend %label;
          font-size: 0.8rem;
        }

        .talent-result-subtitle {
          font-size: 0.7rem;
          color: $light;
          transition: color 0.3s ease-out;

          &:hover {
            color: black;
          }
        }

        .view-talent-card {
          @extend %btn-basic;
          text-transform: uppercase;
          padding: 8px 0 0;
          font-size: 10px;
        }
      }

      .result-add-button {
        @extend %btn-basic;
        @extend %label;
        background-color: black;
        color: white;
        padding: 3px;
        border-radius: 50%;
        display: grid;
        place-items: center;

        &.userIsAlreadySelected {
          background-color: $primary;
          color: white;
        }

        svg {
          height: 8px;
          width: 8px;
        }
      }
    }
  }
}
