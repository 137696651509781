@import '../../styles/shared.scss';

.discover-users-controls-container {
  @extend %flex-row-middle-spread;

  .tags {
    @extend %flex-row-middle;
    position: relative;
    max-width: 960px;
    overflow-x: hidden;
    padding-top: 18px;
    margin-top: -18px; // To show recommended filter label

    @include md {
      max-width: 40vw;
    }
    @include lg {
      max-width: 50vw;
    }
    @include xl {
      max-width: 60vw;
    }
    @include xxl {
      max-width: 960px;
    }

    .tag {
      @extend %flex-row-middle;
      @extend %btn-basic;
      padding: 2px 8px;
      text-transform: uppercase;
      color: $darker;
      border: $border-darker;
      font-weight: bold;
      font-size: 12px;
      border-radius: 24px;
      line-height: 1;
      margin-right: 6px;

      svg {
        margin-left: 4px;
        font-size: 14px;
      }

      &.inactive {
        color: $light;
        border: $border-lighter;
        font-weight: normal;
      }
    }
    .empty-msg {
      font-size: 12px;
      text-transform: uppercase;
      color: $light;
    }

    .recommended-msg {
      position: absolute;
      top: 0px;
      left: 0;
      font-size: 12px;
      text-transform: uppercase;
      color: $light;
      white-space: nowrap;
    }
  }

  .toggle-promoters-container {
    @extend %flex-row-middle;
    padding: 8px 12px;
    color: $dark;
    font-size: 11px;
    margin-left: 12px;
    white-space: nowrap;
    margin-top: calc(-18px / 2); // To show recommended filter label

    .switch {
      margin: -1px 0 0 4px;
    }
  }
}
