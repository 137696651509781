@import '../../styles/shared.scss';

.invite-talent-modal-outer-container {
  max-width: 960px !important;

  .link {
    @extend %btn-basic;
    @extend %link;
    text-decoration: underline;
  }

  .header-container {
    .header {
      font-weight: bold;
      font-size: 24px;
      text-transform: uppercase;
    }
    .subheader {
      font-size: 13px;
      margin-top: 8px;
      color: $dark;

      .page-and-code {
        padding-left: 10px;
        border-left: 2px solid $lighter;
        .page {
        }
        .code {
        }
      }
    }
  }

  .body-container {
    @extend %flex-row;
    margin-top: 24px;
    padding-top: 24px;

    @include md-down {
      flex-direction: column;
      margin-top: 12px;
    }

    .example-email {
      padding: 12px;
      background: $offwhite;
      margin: 12px 0 24px;
      border-radius: 4px;
      font-size: 13px;

      @include md {
        margin: 0 24px 0 0;
      }

      .subject-header {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .form {
      @include md {
        min-width: 400px;
      }
      textarea {
        @extend %input-shared;
        width: 100%;
      }

      .send-btn {
        @extend %btn-offwhite-new;
        margin-top: 12px;
        align-self: flex-start;
        padding: 4px 12px;
        text-transform: uppercase;

        &.active {
          @extend %btn-dark-new;
        }
      }
    }
  }
}
