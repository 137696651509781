@import '../../../styles/shared.scss';

.edit-mention-tags-modal {
  .edit-mention-tags-modal-inner {
    max-width: 480px;
    max-height: 640px;

    .edit-mention-tags-modal-content {
      .tag-containers {
        @extend %flex-col;
        padding-bottom: 80px;
        overflow: auto;
        gap: 4px;

        .tag-container {
          @extend %flex-row-middle-spread;
          padding: 6px 12px;
          border-radius: 12px;
          border: $border-lighter;

          .display-container {
            .display {
              @extend %label;
              font-size: 12px;
            }
            .subdisplay {
              font-size: 9px;
              margin-top: 3px;
            }
          }

          form {
            flex: 1;
            input {
              @extend %input-inline;
              text-transform: uppercase;
              font-size: 12px;
              width: 100%;
              font-weight: bold;
            }
          }

          .actions {
            .action {
              svg {
                @extend %btn-basic;
                font-size: 16px;
              }
            }
          }
        }
      }

      .edit-btns {
        margin-top: 12px;
        background-color: $white;
        border-top: $border-lighter;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 12px;
        .save-btn {
          @extend %btn-darkest-new;
        }
      }
    }
  }
}
