@import '../../styles/shared.scss';

.talent-sidebar-outer-container {
  .section {
    margin-bottom: 12px;
    border-radius: 16px;
    background: $white;
    padding: 24px;

    &.action-required {
      background: $darkest;
      .section-header {
        color: white;
      }
      .section-body {
        .item-container {
          .item-text {
            color: $lightest;

            &.selected {
              color: white;
              font-weight: bold;
            }
            &.another-selected {
              color: $medium;
            }
          }
          .item-count {
            background: $dark;

            &.another-selected {
              background: $darker;
              color: $light;
            }
          }

          .bulk-action {
            background-color: $dark;
            color: $white;
          }
        }
      }
    }

    &.no-action-required {
      background: $prettymuchwhite;

      .section-header {
        color: $darkest;
      }

      .section-subheader {
        color: $dark;
        font-size: 12px;

        a {
          color: $dark;
          font-weight: bold;
        }
      }
    }

    .section-header {
      @extend %label;
      letter-spacing: 1px;
      font-size: 14px;
      color: $darkest;

      &.another-selected {
        color: $lighter;
      }
    }
    .section-subheader {
      font-size: 10px;
      color: $dark;
      font-style: italic;

      &.another-selected {
        color: $lighter;
      }
    }
    .section-body {
      margin-top: 8px;
      font-size: 10px;

      .item-container {
        @extend %btn-basic;
        @extend %flex-row-middle-spread;
        position: relative;
        font-size: 13px;

        &:not(:last-child) {
          margin-bottom: 4px;
        }

        .edit-btn {
          opacity: 0;
          position: absolute;
          font-size: 10px;
          right: 100%;
          padding: 2px 4px;
          color: $light;
          &:hover {
            opacity: 1;
          }
        }

        &:hover {
          .edit-btn {
            opacity: 1;
          }

          &.has-actions {
            .item-count {
              opacity: 0;
            }

            .bulk-actions {
              opacity: 1;
              pointer-events: auto;
            }
          }
        }

        .item-text {
          flex: 1;
          @extend %flex-row-middle;
          color: $dark;
          margin-right: 24px;

          &.selected {
            color: $darkest;
            font-weight: bold;
          }
          &.another-selected {
            color: $lighter;
          }

          svg {
            margin-left: 4px;
          }
        }
        .item-count {
          padding: 2px 8px;
          border-radius: 24px;
          font-size: 10px;
          font-weight: bold;
          background: $offwhite;
          color: $darker;

          &.selected {
            background: $darkest;
            color: $white;
          }

          &.alert {
            background: $primary;
            color: white;
          }

          &.cannot-view {
            filter: blur(2px);
          }

          &.another-selected {
            background: $offwhite;
            color: $lighter;
          }
        }

        .bulk-actions {
          @extend %flex-row-middle;
          position: absolute;
          right: 0;
          pointer-events: none;
          transition: opacity 0.2s ease-in-out;
          opacity: 0;
          gap: 4px;

          .bulk-action {
            @extend %label;
            font-weight: bold;
            background-color: $darkest;
            color: $white;
            padding: 2px 8px;
            border-radius: 4px;
            font-size: 9px;
          }
        }
      }
    }
  }
}
