@import '../../../styles/shared.scss';

.latest-section.articles {
  @extend %flex-row;
  flex-wrap: wrap;
  gap: 24px;
  .article {
    width: calc(100%);
    aspect-ratio: 1;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(0.98);
    }

    @include md {
      width: calc(50% - 24px / 2);
    }

    @include xl {
      // width: calc(33% - 24px / 2);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    .background {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), transparent);
    }

    .meta {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      z-index: 1;
      padding: 48px;
      color: white;

      .title-container {
        .title {
          font-size: 32px;
          line-height: 40px;
          font-family: $font-header;
          font-weight: bold;
          margin-bottom: 12px;
        }

        .subtitle {
          font-size: 16px;
        }

        .action-display {
          margin-top: 24px;
          @extend %label;
          font-size: 14px;

          svg {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
